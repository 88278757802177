import { AppCard } from "@sk/uis";
import { Modal } from "react-bootstrap";
import { BtnLink } from "@sk/uis";
import { NavService } from "@sk/services";

const GrnPoModal = ({ show, callback, poids, grnId }) => {
  const handleClose = () => {
    callback({ show: false });
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">
          PO - ID:{" "}
          <BtnLink
            className="fs-val-md"
            onClick={(e) => {
              e.stopPropagation();
              NavService.openInNewTab("/purchase-order/grn/view", {
                id: grnId,
              });
            }}
          >
            {grnId}
          </BtnLink>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        <AppCard title={`Total PO: ${poids.length}`}>
          <div className="row">
            {poids.map((po, index) => (
              <div className="col-4" key={index}>
                <BtnLink
                  className="fs-val-md fw-semibold mb-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    NavService.openInNewTab("/purchase-order/view", { id: po });
                  }}
                >
                  {po}
                </BtnLink>
              </div>
            ))}
          </div>
        </AppCard>
      </Modal.Body>
    </Modal>
  );
};

export default GrnPoModal;
