import { TabContent, Tabs } from "@sk/uis";
import { useState } from "react";
import {
  BarcodeMaster,
  BasicDetails,
  Category,
  Deal,
  Description,
  Images,
  MasterBarcode,
} from "../../features";

const tabs = [
  { key: "basic", tabName: "Basic Details" },
  { key: "description", tabName: "Description" },
  { key: "deal", tabName: "Linked Deals" },
  { key: "images", tabName: "Images" },
  { key: "barcodeMaster", tabName: "Barcode Mater" },
  { key: "masterBarcode", tabName: "Mater Barcode" },
  { key: "categories", tabName: "Categories/Attributes" },
];

const ProductInformation = ({ data }) => {
  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  // Tab Callback to change Tab
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  return (
    <>
      <div className="mb-3">
        <Tabs data={tabs} activeTab={activeTab} callback={tabCb} template={3} />
      </div>
      {activeTab.key == "basic" && <BasicDetails data={data} />}
      {activeTab.key == "description" && (
        <Description
          shortDescription={data.shortDescription}
          fullDescription={data.fullDescription}
          highlights={data.highlights}
        />
      )}
      {activeTab.key == "deal" && <Deal pId={data._id} />}
      {activeTab.key == "images" && <Images images={data.images} />}
      {activeTab.key == "barcodeMaster" && <BarcodeMaster pId={data._id} />}
      {activeTab.key == "masterBarcode" && <MasterBarcode pId={data._id} />}
      {activeTab.key == "categories" && (
        <Category data={data.csa} category={data._category} />
      )}
    </>
  );
};

export default ProductInformation;
