import { FileUpload, ImgRender, InputErrorMsg } from "@sk/uis";
import { memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const Images = () => {
  const {
    formState: { errors },
    getValues,
    setValue,
    trigger,
    control,
  } = useFormContext();

  const [images, iconImages, kingclubImages, kingclubMainImages] = useWatch({
    name: [
      "mainImages",
      "iconFileImage",
      "kingclubImages",
      "kingclubMainImages",
    ],
    control,
  });

  const mainImageCb = async (e) => {
    setValue("mainImages", [{ asset: e.asset }]);
    await trigger("mainImages");
  };

  const iconImageCb = async (e) => {
    setValue("iconFileImage", [{ asset: e.asset }]);
    await trigger(["iconFileImage", "iconImages"]);
  };

  const kingclubMainImagesCb = async (e) => {
    setValue("kingclubMainImages", [{ asset: e.asset }]);
    await trigger("kingclubMainImages");
  };

  const kingclubImagesCb = async (e) => {
    setValue("kingclubImages", [{ asset: e.asset }]);
    await trigger("kingclubImages");
  };

  const removeImage = useCallback(
    async (key, index) => {
      let v = getValues(key);
      v.splice(index, 1);
      setValue(key, v);
      await trigger(key);
    },
    [getValues, setValue, trigger]
  );

  const removeImg = useCallback(
    (key) => {
      removeImage(key, 0);
    },
    [removeImage]
  );
  return (
    <div className="my-4 row">
      {/* Main Image */}
      <div className="col-3">
        <div className=" fs-val-lg fw-semibold mb-4">Category Images</div>
        <div className="row">
          {/* Image rendering */}
          <div className="col-auto">
            {!images.length ? (
              <div className="upload-placeholder-t1">
                <i className="bi bi-image"></i>
              </div>
            ) : (
              <div className="uploaded-t1-img-cnt">
                <i
                  tabIndex={-1}
                  role="button"
                  className="bi bi-x-circle close"
                  onClick={() => {
                    removeImg("mainImages");
                  }}
                ></i>
                <ImgRender
                  assetId={images.length ? images[0]["asset"] : ""}
                  width="110"
                  height="110"
                />
              </div>
            )}
          </div>

          {/* Action button  */}
          <div className="col col-ms-auto align-self-end">
            <FileUpload template={2} callback={mainImageCb} />
          </div>
        </div>

        {errors?.image?.message && (
          <div className="col-12">
            <InputErrorMsg msg={errors?.mainImages?.message} />
          </div>
        )}
      </div>

      {/* Icon Image */}
      <div className="col-3">
        <div className=" fs-val-lg fw-semibold mb-4">Category Icon</div>
        <div className="row">
          {/* Image rendering */}
          <div className="col-auto">
            {!iconImages.length ? (
              <div className="upload-placeholder-t1">
                <i className="bi bi-image"></i>
              </div>
            ) : (
              <div className="uploaded-t1-img-cnt">
                <i
                  tabIndex={-1}
                  role="button"
                  className="bi bi-x-circle close"
                  onClick={() => {
                    removeImg("iconFileImage");
                  }}
                ></i>

                <ImgRender
                  assetId={iconImages.length ? iconImages[0]["asset"] : ""}
                  width="110"
                  height="110"
                />
              </div>
            )}
          </div>

          {/* Action button  */}
          <div className="col col-ms-auto align-self-end">
            <FileUpload template={2} callback={iconImageCb} />
          </div>
        </div>

        {errors?.image?.message && (
          <div className="col-12">
            <InputErrorMsg msg={errors?.iconFileImage?.message} />
          </div>
        )}
      </div>

      {/* Kingclub Main Image */}
      <div className="col-3">
        <div className=" fs-val-lg fw-semibold mb-4">KingClub Main Image</div>
        <div className="row">
          {/* Image rendering */}
          <div className="col-auto">
            {!kingclubMainImages.length ? (
              <div className="upload-placeholder-t1">
                <i className="bi bi-image"></i>
              </div>
            ) : (
              <div className="uploaded-t1-img-cnt">
                <i
                  tabIndex={-1}
                  role="button"
                  className="bi bi-x-circle close"
                  onClick={() => {
                    removeImg("kingclubMainImages");
                  }}
                ></i>
                <ImgRender
                  assetId={
                    kingclubMainImages.length
                      ? kingclubMainImages[0]["asset"]
                      : ""
                  }
                  width="110"
                  height="110"
                />
              </div>
            )}
          </div>

          {/* Action button  */}
          <div className="col col-ms-auto align-self-end">
            <FileUpload template={2} callback={kingclubMainImagesCb} />
          </div>
        </div>

        {errors?.image?.message && (
          <div className="col-12">
            <InputErrorMsg msg={errors?.kingclubMainImages?.message} />
          </div>
        )}
      </div>

      {/* Kingclub Icon Image */}
      <div className="col-3">
        <div className=" fs-val-lg fw-semibold mb-4">Kingclub Icon Image</div>
        <div className="row">
          {/* Image rendering */}
          <div className="col-auto">
            {!kingclubImages.length ? (
              <div className="upload-placeholder-t1">
                <i className="bi bi-image"></i>
              </div>
            ) : (
              <div className="uploaded-t1-img-cnt">
                <i
                  tabIndex={-1}
                  role="button"
                  className="bi bi-x-circle close"
                  onClick={() => {
                    removeImg("kingclubImages");
                  }}
                ></i>

                <ImgRender
                  assetId={
                    kingclubImages.length ? kingclubImages[0]["asset"] : ""
                  }
                  width="110"
                  height="110"
                />
              </div>
            )}
          </div>

          {/* Action button  */}
          <div className="col col-ms-auto align-self-end">
            <FileUpload template={2} callback={kingclubImagesCb} />
          </div>
        </div>

        {errors?.image?.message && (
          <div className="col-12">
            <InputErrorMsg msg={errors?.kingclubImages?.message} />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Images);
