import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";
import notLinkedImg from "../../assets/imgs/not-linked.svg";

const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Category List",
    },
  ],

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active Count",
      value: 0,
      loading: true,
      filter: { status: "Active" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Inactive Count",
      value: 0,
      loading: true,
      filter: { status: "Inactive" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Product Not Linked",
      value: 0,
      loading: true,
      filter: {},
      color: "danger",
      img: notLinkedImg,
      type: "productNotLinked",
    },
  ],

  moreTableColumns: [
    {
      label: "Linked Product",
      key: "linkedProduct",
      checked: false,
      priority: 7,
      isCentered: true,
    },
    {
      label: "Is Focused Category",
      key: "isFocusedCategory",
      checked: false,
      priority: 8,
      isCentered: true,
    },
    // {
    //   label: "Is Daily Needs",
    //   key: "isDailyNeeds",
    //   checked: false,
    //   priority: 9,
    //   isCentered: true,
    // },
    {
      label: "Display Deal MRP",
      key: "displayDealMrp",
      checked: false,
      priority: 10,
      isCentered: true,
    },
    {
      label: "Returns Allowed",
      key: "noReturnsAllowed",
      checked: false,
      priority: 11,
      isCentered: true,
    },
    {
      label: "Created At",
      key: "createdAt",
      checked: false,
      priority: 12,
      isCentered: true,
    },
  ],

  additionalTableDataConfig: [
    {
      key: "parent",
      api: "category",
      loadingKey: "parentCategoryLoading",
      dataKey: "_parent",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },

  formLabels: {
    isExclusiveType: {
      label: "Is Exclusive Type",
    },
    invoiceOnOrderAmount: {
      label: "Invoice On Order Amount",
    },
    classGroup: {
      label: "Class Group",
      valuePath: "[0].label",
    },
    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },
    status: {
      label: "Status",
    },
    displayDealMrp: {
      label: "Display Deal MRP",
    },
    isFocusedCategory: {
      label: "Is Focused Category",
    },
    noReturnsAllowed: {
      label: "Returns Allowed",
    },
    isDailyNeeds: {
      label: "Is Daily Needs",
    },
    hideUnlinkedCategories: {
      label: "Product Not Linked",
    },
  },
};

// FOR DETAIL VIEW START
export const detailsView = {
  tabs: {
    linkedDeals: {
      selectOptions: {
        catalogTypeOptions: [
          { label: "All", value: "" },
          { label: "SK", value: "sk" },
          { label: "Seller", value: "seller" },
        ],
        statusOptions: [
          { label: "All", value: "" },
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
      },
      // SelectOptions Ends
      filterFromData: {
        deal: "",
        category: [],
        status: "",
        catalogType: "",
      },
      // Filter Ends Here
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Deal ID" },
        { label: "Name", width: "30%" },
        { label: "Products" },
        { label: "Brand/ID" },
        { label: "Catalog Type", isCentered: true },
        { label: "Status" },
        { label: "MRP" },
      ],
      // TableHeader Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      additionalTableDataConfig: [
        {
          key: "brand",
          api: "brand",
          loadingKey: "categoryLoading",
          dataKey: "_brand",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // additional Table Data
    },
    // Linked Deals End
    linkedProducts: {
      selectOptions: {
        statusOptions: [
          { label: "All", value: "" },
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
      },
      // SelectOptions Ends
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Product ID" },
        { label: "Name" },
        { label: "Brand/ID" },
        { label: "Status" },
        { label: "Barcode" },
        { label: "MRP" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        product: "",
        brand: [],
        status: "",
      },
      // Filter Form Data ends Here
      additionalTableDataConfig: [
        {
          key: "brand",
          api: "brand",
          loadingKey: "brandLoading",
          dataKey: "_brand",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // additional Table Data
    },
    // Linked Products Ends Here
    linkedVendors: {
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Vendor ID" },
        { label: "Vendor Name" },
        { label: "Location" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        vendor: "",
      },
    },
    // Linked Vendors Here

    linkedBrands: {
      selectOptions: {
        statusOptions: [
          { label: "All", value: "" },
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
      },
      // SelectOptions Ends
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Brand ID" },
        { label: "Name" },
        { label: "Status" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        brand: [],
        status: "",
      },
      // Filter Form Data ends Here
      // additionalTableDataConfig: [
      //   {
      //     key: "brand",
      //     api: "brand",
      //     loadingKey: "brandLoading",
      //     dataKey: "_brand",
      //     filter: (ids) => ({
      //       page: 1,
      //       count: ids.length,
      //       filter: { _id: { $in: ids } },
      //       select: "name",
      //     }),
      //   },
      // ],
      // additional Table Data
    },
    // Linked Brands Ends Here
  },
};
// DETAILS VIEW END

export default listView;
