import ProductLedger from "../../../../../components/product-ledger/ProductLedger";
import { useForm } from "react-hook-form";
import { TextInput, SelectInput, DateInput } from "@sk/uis";
import { Controller } from "react-hook-form";
import React, { useCallback } from "react";
import { debounce } from "lodash";
import { AppCard } from "@sk/uis";

const tblContainerStyle = {
  height: "calc(100vh - 300px)",
};

const ledgerTblStyle = {
  minWidth: "1700px",
};

const CmsOverviewStockLedger = ({ productId }) => {
  const { control, register, handleSubmit, getValues } = useForm();
  const [filters, setFilters] = React.useState({
    search: "",
    type: "",
    warehouse: "",
    reference: "",
    createdAt: null,
  });

  const handleInpChange = useCallback(
    debounce(() => {
      setFilters({ ...getValues() });
    }, 300),
    [getValues]
  );

  const handleFilterChange = () => {
    const values = getValues();
    setFilters((prevFilters) => ({
      ...prevFilters,
      warehouse: values.warehouse,
      reference: values.reference,
      type: values.type,
      createdAt: values.createdAt,
    }));
  };

  const onDateChange = (chngFn) => (val) => {
    chngFn(val);
    handleFilterChange();
  };

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-4">
            <TextInput
              register={register}
              name="reference"
              type="text"
              label="Reference"
              placeholder="Enter reference"
              gap={1}
              size="sm"
              callback={handleInpChange}
            />
          </div>
          <div className="col-3">
            <SelectInput
              label="Type"
              register={register}
              name="type"
              options={typeOptions}
              gap={1}
              size="sm"
              callback={handleFilterChange}
            />
          </div>
          <div className="col-5">
            <Controller
              name="createdAt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DateInput
                  label="Date Range"
                  placeholder="Choose date range"
                  value={value}
                  callback={onDateChange(onChange)}
                  gap={1}
                  size="sm"
                  template={2}
                />
              )}
            />
          </div>
        </div>
      </AppCard>

      <AppCard>
        <ProductLedger
          pId={productId}
          tblHeaderSticky={true}
          tblContainerStyle={tblContainerStyle}
          tblStyle={ledgerTblStyle}
          filter={filters}
        />
      </AppCard>
    </>
  );
};

const typeOptions = [
  { label: "All Types", value: "" },
  { label: "In", value: "IN" },
  { label: "Out", value: "OUT" },
];

export default CmsOverviewStockLedger;
