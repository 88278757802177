import { MarketingDashboardNavComponent } from "@sk/features";
import { useAttachAdditionalData } from "@sk/hooks";
import {
  CommonService,
  DealService,
  OmsService,
  appConfigs,
} from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  BusyLoader,
  PageInfo,
  Rbac,
  Toaster,
} from "@sk/uis";
import { set } from "date-fns";
import { produce } from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Filter from "./components/Filter";
import SummaryCardBox from "./components/SummaryCardBox";
import Table from "./components/Table";
import listView from "./constantService";
import AdvanceFilterModal from "./modals/AdvanceFilterModal";
import ManageKingSlabModal from "./modals/ManageKingSlabModal";
import ViewKingSlabModal from "./modals/ViewKingSlabModal";

const rbac = {
  addButton: ["AddKingSalb", 'POSAddKingSlab'],
};

const defaultSummaryData = listView.summaryData;

const breadcrumbs = listView.breadcrumb;

const defaultPaginationOpt = listView.pagination;

const formLabelsConfig = listView.formLabels;

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const attachAdditionalDataConfig = listView.additionalTableDataConfig;

const defaultFormData = listView.filterFromData;

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};

  if (d.deal.length) {
    p["filter"]["dealId"] = d.deal[0].value._id;
  }
  if (d.status && d.status != "All") {
    p["filter"]["isActive"] = d.status == "Active";
  }
  if (d?.franchise?.length) {
    p.filter.fids = d.franchise[0].value._id;
  }

  if (d?.isFixedPrice) {
    p.filter.isFixedPrice = d?.isFixedPrice == "Yes" ? true : false;
  }

  if (d.groupType) {
    if (d.groupType == "both") {
      p["filter"]["$and"] = [{ groupType: "FOFO" }, { groupType: "COCO" }];
    } else {
      p["filter"]["groupType"] = {
        $in: [d.groupType],
      };
    }
  }
  if (d?.createdAt?.length > 0) {
    p.filter.createdAt = {
      $gte: set(d?.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(d?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? "-" + [sort.key] : [sort.key];
  }
  return p;
};

const getData = async (params) => {
  const r = await DealService.getPosKingSlabList(params);
  if (params.download) {
    return r;
  }
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.userLoading = true;
        x.dealLoading = true;
        x.franchiseLoading = true;
        return x;
      })
    : [];
};

const ListKingSlab = () => {
  const [data, setData] = useState([]);

  // const isFirstRender = useIsFirstRender();

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [summaryCard, setSummaryCard] = useState(() =>
    [...defaultSummaryData].map((x) => {
      let p = prepareFilterParams(
        defaultPaginationOpt,
        defaultFormData,
        defaultFormData
      );
      if (p.filter) {
        delete p.filter.isActive;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.defaultFilterParams },
      };
    })
  );

  const [showSlabViewModal, setShowSlabViewModal] = useState(false);

  const [showManageKingSlabModal, setShowManageKingSlabModal] = useState(false);

  const [modalData, setModalData] = useState({});

  const [busyLoader, setBusyLoader] = useState(false);

  const [manageKingSlabMode, setManageKingSlabMode] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const paginationRef = useRef({ ...defaultPaginationOpt });

  const sortRef = useRef({ ...defaultSortOpt });

  const advanceFilterRef = useRef({});

  const filterDataRef = useRef({ ...defaultFormData });

  const methods = useForm({
    defaultValues: defaultFormData,
  });
  const navigate = useNavigate();

  const applyFilter = useCallback(
    async (isSummaryRequired = false) => {
      paginationRef.current = { ...defaultPaginationOpt };
      // const params = getFilterParams();
      // setting summary card filterParams

      prepareFilterLabels();

      loadList();
      if (isSummaryRequired) {
        loadSummary();
      }

      loadCount();
    },
    [loadList, loadSummary, loadCount]
  );

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();

    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, []);

  const loadSummary = useCallback(() => {
    const params = getFilterParams();
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.filterParams = { ...params.filter, ...x.defaultFilterParams };
          if (x.key == "overall") {
            delete x.filterParams.isActive;
          }
        });
      })
    );
  }, []);

  const init = useCallback(async () => {
    applyFilter(true);
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    loadAnalyticsData(d);
    setData(d);
    setLoadingData(false);
  }, [attachAllData]);

  const loadAnalyticsData = useCallback(async (d) => {
    let ids = d.map((e) => {
      return e._id;
    });
    let r = await OmsService.getStoreOfferAnalyticsDetails({ offerIds: ids });

    if (Array.isArray(r.resp) && r?.resp?.length) {
      let k = [...d];
      k.forEach((x) => {
        let a = (r?.resp || []).filter((k) => {
          return k?._id?.configId == x?._id;
        });
        if (a?.length) {
          x._anaylitcs = { ...a[0] };
          x._anaylitcsLoading = false;
        } else {
          x._anaylitcs = { ...a[0] };
          x._anaylitcsLoading = false;
        }
      });
      setData([...k]);
      attachAdditionalData([...k]);
    } else {
      let k = [...d];
      k.forEach((x) => {
        x._anaylitcs = {};
        x._anaylitcsLoading = false;
      });
      setData([...k]);
      attachAdditionalData([...k]);
    }
  }, []);

  const attachAdditionalData = useCallback(
    (d) => {
      // To Attach Additional Data
      let tmp = [];
      setAdditionalData(d, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setData([...attachAllData(d, tmp)]);
        }
      });
    },
    [setData]
  );

  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelsConfig,
      filterDataRef.current || {}
    );
    setFilterLabels(l);
  };

  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = {
        ...data.formData,
        ...advanceFilterRef.current,
      };

      applyFilter();
    },
    [applyFilter]
  );

  const onAdvanceFilterClickCb = useCallback(() => {
    setShowAdvanceFilter(true);
  }, []);

  const onAdvanceFilterCb = useCallback(
    (r) => {
      const status = r.status;
      if (status == "applied" || status == "reset") {
        advanceFilterRef.current = { ...r.formData };
        filterDataRef.current = { ...filterDataRef.current, ...r.formData };
        setShowAdvanceFilter(false);
        applyFilter();
      }
      if (status == "closed") {
        setShowAdvanceFilter(false);
      }
    },
    [applyFilter]
  );

  const getCount = async (params) => {
    delete params.count;
    delete params.page;

    try {
      const r = await DealService.getPosKingSlabCount(params);
      return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  };

  const closeSlabViewModalCb = useCallback(() => {
    setShowSlabViewModal(false);
    setModalData({});
  }, []);

  const viewSlabViewModalCb = useCallback((x) => {
    setModalData(x);
    setShowSlabViewModal(true);
  }, []);

  const closeManageKingSlabModal = useCallback(
    (d) => {
      setShowManageKingSlabModal(false);
      setModalData({});
      if (d?.status == "submit") {
        init();
      }
    },
    [init]
  );

  const editManageKingSlabModalCb = useCallback((x) => {
    setManageKingSlabMode("edit");
    setShowManageKingSlabModal(true);
    setModalData(x);
  }, []);

  const addKingSlab = () => {
    setManageKingSlabMode("add");
    setShowManageKingSlabModal(true);
  };

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const downloadReport = async () => {
    setBusyLoader(true);
    const p = getFilterParams();
    p.download = true;
    const r = await getData(p);

    if (r.statusCode != 200) {
      Toaster.error(
        r.resp?.message || "Unable to Download Report,Please try again "
      );
      setBusyLoader(false);
      return;
    }

    const url = appConfigs.ASSET + "/" + r.resp.downloadLink;
    window.open(url, "_blank");

    setBusyLoader(false);
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Dashboard - King Slab"
            navigate={navigate}
            breadcrumbs={breadcrumbs}
          />
        </div>
      </div>

      <MarketingDashboardNavComponent
        activeTab="bogo"
        router={navigate}
        subTypeKey="kingSlab"
      />

      {/* SUMMARY OVERVIEW */}
      <div className="row mb-3">
        {summaryCard.map((card) => (
          <div className="col-2" key={card.key}>
            <SummaryCardBox
              label={card.label}
              color={card.color}
              filterParams={card.filterParams}
              img={card.img}
            />
          </div>
        ))}
      </div>

      <AppCard>
        <div className="row justify-content-between">
          <div className="col-9">
            <FormProvider {...methods}>
              <Filter
                callback={filterFormCb}
                openAdvFilterModal={onAdvanceFilterClickCb}
              />
            </FormProvider>
          </div>
          <div className="col-auto">
            <Rbac roles={rbac.addButton}>
              <button className="btn btn-primary me-2" onClick={addKingSlab}>
                <span className="bi bi-plus-square me-1"></span>
                Create Slab
              </button>
            </Rbac>
            <button className="btn btn-success" onClick={downloadReport}>
              Download Report
            </button>
          </div>
        </div>
      </AppCard>

      <AppCard>
        {filterLabels.length > 0 ? (
          <div className="mb-2">
            <AppliedFilterLabel labels={filterLabels} />
          </div>
        ) : null}

        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          viewCb={viewSlabViewModalCb}
          editCb={editManageKingSlabModalCb}
          sort={sortRef.current}
          sortCb={sortCb}
        />
      </AppCard>

      {/* <BusyLoader show={loadingData} /> */}

      <ViewKingSlabModal
        data={modalData}
        show={showSlabViewModal}
        closeModalCb={closeSlabViewModalCb}
        id={modalData._id}
      />

      <ManageKingSlabModal
        closeModal={closeManageKingSlabModal}
        show={showManageKingSlabModal}
        mode={manageKingSlabMode}
        modalData={modalData}
      />

      {/* ADVANCE FILTER */}
      <AdvanceFilterModal
        show={showAdvanceFilter}
        callback={onAdvanceFilterCb}
        formData={advanceFilterRef.current}
      />

      <BusyLoader show={busyLoader} />
    </>
  );
};

export default ListKingSlab;
