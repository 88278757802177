import { AuthService, WarehouseService } from "@sk/services";
import { PaginationSummary } from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const defaultFormData = {
  status: "",
  order: "",
  whId: AuthService.getDefaultWhId(),
};

const defaultSortOpt = {};

const getCount = async (id, params) => {
  delete params.count;
  delete params.page;

  try {
    const r = await WarehouseService.getImeiWiseListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Prepare Filter Prams
const prepareFilterParams = (
  pagination = {},
  filter = {},
  additionalData = {}
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: { productId: additionalData.id },
  };

  if (filter.status) {
    p.filter.status = filter.status;
  }
  const order = filter.order.trim();

  if (order) {
    p.filter.$or = [
      { imeiNo: order },
      { "orderDetails.invoiceNo": order },
      { "orderDetails.orderId": order },
    ];
  }

  if (filter.whId) {
    p.filter.whId = { $in: [filter.whId] };
  }

  return p;
};

// To Get Data
const getData = async (id, params) => {
  const r = await WarehouseService.getImeiWiseList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const ImeiWise = ({ pId }) => {
  // It Will have all List Data
  const [data, setData] = useState([]);

  // To Store Pagination State
  const paginationRef = useRef({ ...defaultPageOpt });

  // To store Sorting Data
  const sortRef = useRef({ ...defaultSortOpt });

  // To Store Filter Data Ref
  const filterDataRef = useRef({ ...defaultFormData });

  // Loading State While Fetching List
  const [loadingData, setLoadingData] = useState(true);

  // Loading State While Fetching count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  useEffect(() => {
    if (pId) {
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  //  To Get Filter Params Which We are Preparing
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(paginationRef.current, filterDataRef.current, {
      id: pId,
    });
  }, [pId]);

  // To load the list
  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(pId, params);
    const Ids = getAllStockIds(d);

    const resp = await attachStockIdsDetails(Ids, d);

    setData(resp);

    setLoadingData(false);
  }, [getFilterParams, pId, attachStockIdsDetails]);

  //  To Apply Filter according to Which Filter We Will Select
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };

    loadList();

    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();

    const c = await getCount(pId, p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, pId, loadList]);

  //  To Change Page And Load List
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // To Get Filter Form Data and Apply Filter
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = data.formData;
      applyFilter();
    },
    [applyFilter]
  );

  // To Sort the Table
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const getAllStockIds = (data) => {
    const ids = Array.from(new Set(data.map((x) => x.stockId)));
    return ids;
  };

  const attachStockIdsDetails = useCallback(
    async (Ids, data) => {
      // getting Filter
      const params = getFilterParams();
      params.page = 1;

      params.select = "location,rackId,binId,whId,orderDetails";

      params.filter._id = {
        $in: Ids,
      };
      const r = await WarehouseService.getImeiListSnapshot(params);
      const additionalData = Array.isArray(r.resp) ? r.resp : [];

      if (additionalData.length) {
        data.forEach((x) => {
          // finding current Data id is same as stock Id
          const attachData = additionalData.find((d) => x.stockId == d._id);
          if (attachData._id) {
            x.location = attachData.location;
            x.rackId = attachData.rackId;
            x.binId = attachData.binId;
          }
        });
      }
      return data;
    },
    [getFilterParams]
  );

  return (
    <>
      <Filter callback={filterFormCb} id={pId} />

      {/* Applied Filter label */}

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      {/* Table  */}
      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sort={sortRef.current}
        sortCb={sortCb}
      />
    </>
  );
};

export default memo(ImeiWise);
