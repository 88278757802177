import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo } from "react";

const headers = [
  { label: "Sl.No." },
  { label: "Warehouse" },
  { label: "Config Id" },
  { label: "Group Code" },
  { label: "Reserve qty", width: "10%" },
  { label: "Open Order", width: "10%" },
  { label: "Remaining qty", width: "10%" },
  { label: "Valid from", width: "15%" },
  { label: "Valid to", width: "15%" },
  { label: "Status" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const viewConfig = (id) => {
    NavService.openInNewTab("/dashboard/reservation", {
      s: id,
    });
  };

  return (
    <>
      <table className="table table-bordered  table-responsive-lg">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {!loading &&
            data.map((x, i) => (
              <tr key={i} className="fs-val-sm">
                <td>{paginationConfig.startSlNo + i}</td>
                <td>
                  <div className="fw-semibold">
                    {x.warehouseLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x._warehouse.name
                    )}
                  </div>
                </td>
                <td>
                  <button
                    className="btn btn-link p-0 text-dark fs-val-sm text-start"
                    onClick={() => viewConfig(x._id)}
                  >
                    {x._id}
                  </button>
                </td>
                <td>{x.groupCode}</td>
                <td>{x.preOrderLimit}</td>
                <td>{x.totalOrderQuantity ?? 0}</td>
                <td>{x.remainingQuantity}</td>
                <td>
                  <DateFormatter date={x.applicableFromDate} />
                </td>
                <td>
                  <DateFormatter date={x.endDate} />
                </td>
                <td>
                  <HighlightText type={x._statusType} template={2}>
                    {x._displayStatus}
                  </HighlightText>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
