import {
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
  BtnLink,
} from "@sk/uis";

import { memo } from "react";
import { NavService } from "@sk/services";

const headers = [
  { label: "Sl.No.", width: "5%" },
  { label: "ID", width: "8%" },
  { label: "Name", width: "20%" },
  { label: "State", width: "10%" },
  { label: "District", width: "10%" },
  { label: "Town", width: "10%" },
  { label: "Status", width: "10%" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const viewVendorDetails = (vendorId) => {
    NavService.openInNewTab("/vendor/view", {
      id: vendorId,
    });
  };

  return (
    <>
      <table className="table table-sm">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Vendors Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}
          {!loading &&
            data.map((x, i) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + i}</td>
                <td>{x._id}</td>
                <td>
                  <BtnLink
                    className="fs-val-md"
                    onClick={(e) => {
                      e.stopPropagation();
                      viewVendorDetails(x._id);
                    }}
                  >
                    {x.name}
                  </BtnLink>
                </td>
                <td>{x.state}</td>
                <td>{x.district}</td>
                <td>{x.city}</td>
                <td>
                  <HighlightText
                    type={x.status == "Active" ? "success" : "danger"}
                    template={2}
                  >
                    {x.status}
                  </HighlightText>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
