import { ProductService } from "@sk/services";
import { Spinner } from "@sk/uis";
import { memo, useEffect, useState } from "react";

const RenderProductLinkedCount = ({ cid }) => {
  const [data, setData] = useState({ loading: true, count: 0 });

  useEffect(() => {
    const loadProduct = async () => {
      const p = { filter: { category: { $elemMatch: { $eq: cid } } } };
      const s = await ProductService.getCount(p);
      const d = s.resp || 0;
      setData({ loading: false, count: d });
    };
    if (cid) {
      loadProduct();
    }
  }, [cid]);

  return <>{data.loading ? <Spinner type="dots" /> : data.count}</>;
};

export default memo(RenderProductLinkedCount);
