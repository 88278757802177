import { memo } from "react";
import {
  Amount,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import RspConfigEdit from "./RspConfigEdit";
import { AuthService } from "@sk/services";

const canViewSkLandingCost = AuthService.isValidUserRole(["ViewSkLandingCost"]);

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  // { label: "Config On", width: "6%", isCentered: true },
  { label: "Name", width: "10%" },
  { label: "Smart Store", width: "12%" }, // Old Value = Group Type
  // { label: "Has Offer", isCentered: true },

  // { label: "Fixed Price Enabled", width: "10%", isCentered: true },
  // { label: "Discount/Price", width: "10%", isCentered: true },
  // { label: "MRP" },
  // { label: "B2B" },
  // { label: "RSP" },
  { label: "Price Details" },
  { label: "Store Profit", isCentered: true },
  { label: "SK Profit", isCentered: true },
  {
    label: "Last Modified At",
    width: "8%",
    key: "modifiedAt",
    enableSort: true,
  },
  { label: "Status", width: "8%", isCentered: true },
  { label: "Actions", width: "4%" },
];

const rbac = {
  editButton: ["EditRSPConfig"],
  viewButton: ["ViewRSPConfig"],
};

const navigate = (type, id) => {
  const path = `/cms/${type}/view`;
  // NavService.openInNewTab(path, { id });
};

let rowBg = {
  backgroundColor: "#e8fff1",
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  openEditModal,
  openViewModal,
  sort = {},
  sortCb,
  markAsActive,
  markAsInActive,
  onClickEditCb,
  onRspConfigCb,
  onClickCancelEditCb,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {loading ? (
            <TableSkeletonLoader
              cols={headers.length}
              rows={paginationConfig.rowsPerPage}
            />
          ) : null}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound> No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {!loading &&
            data.map((x, index) => (
              <tr
                key={x._id}
                className="fs-val-md"
                style={x?._updated ? rowBg : {}}
              >
                {/* serial no. */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* CONFIG
                <td className="text-center">
                  <span className="fs-val-sm badge badge-soft-primary text-uppercase">
                    {x.configOnType}
                  </span>
                </td> */}

                {/* PRODUCT NAME */}
                <td>
                  <span
                    role="button"
                    tabIndex={-1}
                    onClick={() => {
                      const type = x.configOnType.toLowerCase();
                      navigate(type, x[type].id);
                    }}
                  >
                    <div className="mb-1">
                      {x[x.configOnType.toLowerCase()].name}
                    </div>
                    <small className="mb-1 fs-val-sm text-muted">
                      ID :{x[x.configOnType.toLowerCase()].id}
                    </small>
                  </span>
                </td>

                {/* GROUP TYPE */}
                <td>
                  {/* {x.groupType && (
                    <div className="mb-1 badges fs-val-sms badges-soft-warning text-uppercase fw-bolds">
                      {x.groupType}
                    </div>
                  )} */}
                  {x.franchiseInfo?.id && (
                    <>
                      <div className="mt-2 fs-val-md">
                        {x.franchiseInfo.name} ( {x.franchiseInfo.type} )
                      </div>
                      <div className="fs-val-sm text-muted">
                        ID: {x.franchiseInfo.id}
                      </div>
                    </>
                  )}
                  {/* HAS OFFER */}
                  <div className="mt-3">
                    <div className="mb-2 fs-val-sm">
                      Has Offer :
                      {!x.hasOffer ? (
                        <span className="ms-2 fs-val-xs badge badge-soft-danger text-uppercase ">
                          No
                        </span>
                      ) : (
                        <span className="ms-2 fs-val-xs badge badge-soft-success text-uppercase ">
                          Yes
                        </span>
                      )}
                    </div>

                    {x.hasOffer ? (
                      <div className="mt-3">
                        <div className="badges badges-soft-success">
                          <span className="fs-val-sm">Offer Discount : </span>

                          <span className="fw-bold text-success fs-val-md">
                            {x.specialDiscount}%
                          </span>
                        </div>
                      </div>
                    ) : null}

                    {/* OFFER DATES */}
                    {x.hasOffer && x.validityPeriod && (
                      <div className="fs-val-sm mt-2">
                        <div className="key-lbl mb-0">Start Date</div>
                        <div className="mb-2">
                          <div className="mb-0 text-primarys">
                            <span>
                              <DateFormatter
                                date={x.validityPeriod?.startDate}
                                format="dd MMM yyyy"
                              />
                              ,
                            </span>
                            <span className="mx-1">
                              <DateFormatter
                                date={x.validityPeriod?.startDate}
                                format="hh:mm a"
                              />
                            </span>
                          </div>
                        </div>

                        <div className="key-lbl mb-0">End Date</div>
                        <div className="mb-2">
                          <div className="text-dangers mb-0">
                            <span>
                              <DateFormatter
                                date={x.validityPeriod?.endDate}
                                format="dd MMM yyyy"
                              />
                              ,
                            </span>
                            <span className="mx-1">
                              <DateFormatter
                                date={x.validityPeriod?.endDate}
                                format="hh:mm a"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </td>

                {/* Price Details */}
                <td>
                  <div className="row">
                    <div className="col-2 mb-3">
                      <KeyVal label="MRP" template="col">
                        <Amount value={x?.mrp} decimalPlace={2} />
                      </KeyVal>
                    </div>
                    <div className="col-2 mb-3">
                      <KeyVal label="Store Landing" template="col">
                        <Amount value={x?.b2bPrice} decimalPlace={2} />
                      </KeyVal>
                    </div>
                    <div className="col-3 mb-3">
                      <KeyVal label="RSP" template="col">
                        <div
                          title="Fixed Price / mrp - mrp * (discount (%) / 100)"
                          className="cursor-pointer"
                        >
                          <Amount value={x?._rspPrice || 0} decimalPlace={2} />
                          {!x?.isFixedPrice ? (
                            <span className="me-2 text-primary">
                              ({x?.discount}%)
                            </span>
                          ) : null}
                        </div>
                      </KeyVal>
                    </div>
                    {canViewSkLandingCost ? (
                      <div className="col-auto mb-3">
                        <KeyVal label="Landing Cost" template="col">
                          <Amount value={x?.slc || 0} decimalPlace={2} />
                        </KeyVal>
                      </div>
                    ) : null}
                    <div className="col-auto mb-3 ms-3">
                      <KeyVal label="Is RSP Stationary" template="col">
                        <HighlightText
                          status={x.isFixedPrice ? "Yes" : "No"}
                          isBadge={true}
                        />
                      </KeyVal>
                    </div>
                    {!x?._edit && x?.createdByType != "Franchise" ? (
                      <Rbac roles={rbac.editButton}>
                        <div className="col-auto mt-3 me-3 ms-auto">
                          <button
                            className="btn btn-sm btn-outline-warning"
                            onClick={() => {
                              onClickEditCb(index);
                            }}
                          >
                            Edit Discount
                          </button>
                        </div>
                      </Rbac>
                    ) : null}

                    {x?._edit ? (
                      <>
                        <div className="col-12">
                          <RspConfigEdit
                            details={x}
                            callback={onRspConfigCb}
                            onClickCancelEditCb={onClickCancelEditCb}
                            index={index}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </td>

                {/* Is RSP  Fixed Price */}
                {/* <td className="text-center">
                  <div
                    className={classNames({
                      "fs-val-xs badge text-uppercase": true,
                      "badge-soft-danger": !x.isFixedPrice,
                      "badge-soft-success": x.isFixedPrice,
                    })}
                  ></div>
                </td> */}

                {/* DISCOUNT */}
                {/* <td className="text-center">
                  {x.isFixedPrice ? (
                    <div>
                      <Amount value={x?.fixedPrice} decimalPlace="3" />
                    </div>
                  ) : (
                    <div>{x.discount + "%"}</div>
                  )}
                </td> */}
                {/* MRP
                <td className="text-center">
                  <Amount value={x?.mrp} decimalPlace={2} />
              </td>*/}

                {/* B2B */}
                {/* <td className="text-center">
                  <Amount value={x?.b2bPrice} decimalPlace={2} />
                </td> */}

                {/* RSP Price */}
                {/* <td className="text-center">
                  <Amount value={x?._rspPrice || 0} decimalPlace={2} />
                </td> } */}

                {/* Store Profit */}
                <td
                  className={classNames({
                    "text-center": true,
                    "text-success": x?._storeProfit > 0,
                    "text-danger": x?._storeProfit < 0,
                    "text-primary": x?._storeProfit == 0,
                  })}
                >
                  <Amount value={x?._storeProfit} decimalPlace={2} />
                  <div className="pt-2">({x?._storeProfitPerc || 0}%)</div>
                </td>

                {/* Sk Profit */}
                <td className="text-center">
                  {canViewSkLandingCost ? (
                    <div
                      className={classNames({
                        "text-success": x?._skProfit > 0,
                        "text-danger": x?._skProfit < 0,
                        "text-primary": x?._skProfit == 0,
                      })}
                    >
                      <Amount value={x?._skProfit} decimalPlace={2} />
                      <div className="pt-2">({x?._skProfitPerc || 0}%)</div>
                    </div>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* Modified BY DATE */}
                <td>
                  <div className="mb-0">
                    <span>
                      <DateFormatter
                        date={x?.modifiedAt}
                        format="dd MMM yyyy"
                      />
                      ,
                    </span>
                    <span className="fs-val-md mx-1">
                      <DateFormatter date={x?.modifiedAt} format="hh:mm a" />
                    </span>
                  </div>
                  <div className="text-muted fs-val-sm">
                    <span>by </span>
                    <span>
                      {x._modifiedBy?.name ? x._modifiedBy?.name : null}
                    </span>
                  </div>
                  {x.createdByType ? (
                    <div className="text-muted badge badge-soft-secondary">
                      {x.createdByType}
                    </div>
                  ) : null}
                </td>

                {/* STATUS */}
                <td>
                  <div className="mb-1 text-center">
                    <HighlightText status={x.status} isBadge={true} />
                  </div>

                  {/* FOR MARK AS IN-ACTIVE BUTTON  */}
                  <Rbac roles={rbac.editButton}>
                    {x.status == "Active" ? (
                      <div className="text-center">
                        <button
                          className="btn btn-link text-danger fs-val-xs p-0"
                          onClick={() => markAsInActive(x)}
                        >
                          Mark as Inactive
                        </button>
                      </div>
                    ) : null}

                    {/* FOR MARK AS ACTIVE BUTTON  */}
                    {x.status == "Inactive" ? (
                      <div className="text-center">
                        <button
                          className="btn btn-link text-success fs-val-xs p-0"
                          onClick={() => markAsActive(x)}
                        >
                          Mark as Active
                        </button>
                      </div>
                    ) : null}
                  </Rbac>
                </td>

                {/* FOR ACTION BUTTONS */}
                <td>
                  <Rbac roles={rbac.viewButton}>
                    <div>
                      {" "}
                      <button
                        className="btn btn-sm fs-val-xs btn-outline-primary me-2"
                        onClick={() => openViewModal(x)}
                      >
                        View
                      </button>
                    </div>
                  </Rbac>
                  <Rbac roles={rbac.editButton}>
                    {x.createdByType != "Franchise" && (
                      <div className="mt-3">
                        <button
                          className="btn fs-val-xs btn-sm btn-outline-warning"
                          onClick={() => openEditModal(x._id)}
                        >
                          Edit
                        </button>
                      </div>
                    )}
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* pagination block */}
      {!loading && data.length > 0 && (
        <PaginationBlock
          paginationConfig={paginationConfig}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      )}
    </>
  );
};

export default memo(Table);
