import { CommonService, FranchiseService } from "@sk/services";
import { DatePickerInput, EntitySearchInput } from "@sk/uis";
import { merge } from "lodash";
import { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

const canvasStyle = { width: "30%" };

const defaultFormValues = {
  deal: [],
  customer: [],
  franchise: [],
  createdAt: [],
};
const storeFilterParams = merge({}, FranchiseService.getDarkstoreFilter(), {
  filter: {
    groupType: {
      $in: ["COCO", "FOFO"],
    },
  },
});

const OutOfStockAdvanceFilterModal = ({ show, callback, formData = {} }) => {
  const { handleSubmit, reset, control, setValue } = useForm({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    // for auto filling the previous filtered values when the modal open
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset({ ...defaultFormValues });
      }
    }
  }, [formData, setValue, show, reset]);

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset({ ...defaultFormValues });
    callback({ formData: defaultFormValues, status: "reset" });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row">
              <div className="col-12">
                <Controller
                  control={control}
                  name="deal"
                  key="deal"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="deal"
                      label="Search for Deal"
                      name="deal"
                      placeholder="Search By Name/ID"
                      isMandatory={false}
                      value={value}
                      callback={onChange}
                      uid="deal-search"
                      isMultiple={false}
                    />
                  )}
                />
              </div>
              {/* Franchise */}
              <div className="col-12">
                <Controller
                  control={control}
                  name="franchise"
                  key="franchise"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="franchise"
                      label="Search for Store"
                      placeholder="Search By Name/ID"
                      isMandatory={false}
                      value={value}
                      callback={onChange}
                      uid="franchise-search"
                      isMultiple={false}
                      filterParams={storeFilterParams}
                    />
                  )}
                />
              </div>
              <div className="col-12">
                <Controller
                  control={control}
                  name="customer"
                  key="customer"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="customer"
                      label="Search for Customer"
                      placeholder="Search By Name/ID"
                      isMandatory={false}
                      value={value}
                      callback={onChange}
                      uid="customer-search"
                      isMultiple={false}
                    />
                  )}
                />
              </div>
              <div className="col-12">
                <label className="mb-1 fs-val-md">Created At</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      type="dateRange"
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>
            </div>

            {/*  Action Button */}
            <div className="text-end w-100 mt-4">
              <button
                className="btn me-1 btn-warning"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OutOfStockAdvanceFilterModal;
