import { AuthService, OmsService } from "@sk/services";
import { DatePickerInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

const defaultFromValues = {
  status: "",
  orderId: "",
  createdAt: [],
  warehouse: AuthService.getDefaultWhId(),
};

const statusOptions = OmsService.getOmsOrderStatus();

const Filter = ({ callback }) => {
  const { control, register, getValues } = useForm({
    defaultValues: defaultFromValues,
  });

  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  const onSearch = useCallback(debounce(onFilterChange, 700), [onFilterChange]);

  const onControlledInputChange = useCallback(
    (chngFn) => (e) => {
      chngFn(e);
      onFilterChange();
    },
    [onFilterChange]
  );

  return (
    <>
      <form autoComplete="off">
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name="orderId"
              callback={onSearch}
              register={register}
              label="Order ID"
              placeholder="Enter Order Id"
            />
          </div>

          <div className="col-3">
            <SelectInput
              label="Warehouse"
              register={register}
              name="warehouse"
              callback={onFilterChange}
              isMandatory={false}
              options={warehouseOptions}
            />
          </div>

          {/* CREATED ON DATE FILTER */}
          <div className="col-3">
            <label className="fw-semibold mb-1 fs-val-md"> Created On </label>
            <Controller
              name="createdAt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerInput
                  placeholder="Choose"
                  value={value}
                  inpChange={onControlledInputChange(onChange)}
                  config={createdOnDateConfig}
                />
              )}
            />
          </div>

          <div className="col-3">
            <SelectInput
              label="Status"
              register={register}
              name="status"
              callback={onFilterChange}
              isMandatory={false}
              options={statusOptions}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
