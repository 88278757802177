import { OmsService } from "@sk/services";
import { DateInput, EntitySearchInput, SelectInput, TextInput } from "@sk/uis";
import { add, sub } from "date-fns";
import { debounce } from "lodash";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const dateConfig = {
  mode: "range",
  maxDate: add(new Date(), {
    years: 3,
  }),
};

const defaultFormData = {
  orderedOn: [sub(new Date(), { days: 90 }), new Date()],
};

const statusOptions = OmsService.getOmsOrderStatus();

const ViewDealOrderTableFilter = ({ callback }) => {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const triggerCb = (data = {}) => {
    if (callback) {
      callback({ formData: { ...getValues(), ...data } });
    }
  };

  const onSearch = useCallback(
    debounce(() => {
      const searchValue = getValues("openSearch");

      // Clear date when search has value
      if (searchValue) {
        setValue("orderedOn", []);
      }
      // Reset date to default when search is cleared
      else {
        setValue("orderedOn", [
          sub(new Date(), { days: 90 }),
          add(new Date(), { days: 30 }),
        ]);
      }

      triggerCb();
    }, 800),
    [triggerCb, setValue, getValues]
  );

  return (
    <>
      <div className="row g-2">
        {/* First row - essential filters */}
        <div className="col-3">
          <TextInput
            label="Search"
            placeholder="Search by Order ID/Name"
            name="openSearch"
            register={register}
            callback={onSearch}
            gap={0}
          />
        </div>

        <div className="col-2">
          <Controller
            control={control}
            name="orderedOn"
            render={({ field }) => (
              <DateInput
                label="Ordered On"
                config={dateConfig}
                callback={(value) => {
                  field.onChange(value);
                  triggerCb();
                }}
                value={field.value}
                template={2}
                hideClose={true}
                gap={0}
              />
            )}
          />
        </div>

        <div className="col-3">
          <Controller
            control={control}
            name="franchise"
            render={({ field }) => (
              <EntitySearchInput
                label="Franchise"
                callback={(value) => {
                  field.onChange(value);
                  triggerCb();
                }}
                value={field.value}
                placeholder="Search by Franchise Name/ID"
                type="franchise"
                uid="orderFranchise"
                gap={0}
              />
            )}
          />
        </div>

        <div className="col-2">
          <SelectInput
            label="Status"
            name="status"
            register={register}
            callback={triggerCb}
            options={statusOptions}
            defaultValue=""
            gap={0}
          />
        </div>
      </div>
    </>
  );
};

export default ViewDealOrderTableFilter;
