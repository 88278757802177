import { TabContent, Tabs } from "@sk/uis";
import { useState } from "react";
import { Orders } from "../../features";

const tabs = [{ key: "order", tabName: "Orders" }];

const OrderManagement = ({ data }) => {
  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  // Tab Callback to change Tab
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  return (
    <>
      {/* <Tabs data={tabs} activeTab={activeTab} callback={tabCb} template={2} /> */}
      <TabContent>
        <div className="p-4">
          {activeTab.key == "order" && <Orders pId={data._id} />}
        </div>
      </TabContent>
    </>
  );
};

export default OrderManagement;

//
