import { yupResolver } from "@hookform/resolvers/yup";
import {
  AuthService,
  CommonService,
  ProductService,
  WarehouseService,
} from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  DatePickerInput,
  InputErrorMsg,
  KeyVal,
  Spinner,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

const style = {
  modalHeaderStyle: {
    backgroundColor: "#e4edff",
  },
};

const defaultFormData = {
  // manufactureDate: [],
  // shelfLife: [],
  remarks: "",
};

let defaultDateConfig = {
  enableTime: false,
  dateFormat: "d M Y ",
};

const validationSchema = yup.object({
  manufactureDate: yup.date().required(),
  shelfLife: yup.date().required(),
  remarks: yup.string().required(),
});

const UpdateExpiryModal = ({ show, data, closeModal }) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormData,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!show) {
      resetFormData();
      return;
    }
    if (show) {
      setValue("manufactureDate", data?.manufactureDate);
      setValue("shelfLife", data?.shelfLife);
    }
  }, [show]);

  const onSubmit = async (d) => {
    setLoading(true);
    const p = {
      manufactureDate: d.manufactureDate,
      referenceType: "Batch Correction",
      remarks: d.remarks,
      shelfLife: d.shelfLife,
    };

    let r = {};
    // let whId = AuthService.getDefaultWhId();
    r = await WarehouseService.updateStockMrpShelfLife(data?._id, p);

    setLoading(false);

    if (r.statusCode !== 200) {
      Toaster.error(r.resp.message || "Something Went Wrong");
      return;
    }

    Toaster.success("Manufacture Date Updated Successfully");

    triggerCloseModal("submit");
  };

  const resetFormData = useCallback(() => {
    reset(defaultFormData);
  }, [reset]);

  const onInputChange = useCallback(
    debounce((name) => {
      let value = getValues(name) * 1;

      //    If It is MRP we want it to be In decimal
      if (name == "mrp") {
        value = CommonService.roundedByDecimalPlace(value, 2);
      }
      if (value < 0) {
        value = 0;
      }

      setValue(name, value);
    }, 700),
    [setValue, getValues]
  );

  const triggerCloseModal = useCallback(
    (status) => {
      closeModal({ status });
    },
    [closeModal]
  );

  const loadFormValues = useCallback(() => {
    Object.keys(defaultFormData).forEach((key) => {
      setValue(key, data[key]);
    });
  }, [data, setValue]);

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      if (!val.length) {
        return;
      }
      chngFn(val);

      setValue(key, val[0]);
      trigger();
    };
  };

  return (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={triggerCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={style.modalHeaderStyle}
      >
        <Modal.Title className="fs-val-lg text-dark px-2">
          Modify Manufacture Date/Expiry Date
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-2">
            <div className="col-3 mb-3">
              <KeyVal label="Stock" template="col">
                {data?.quantity}
              </KeyVal>
            </div>
            <div className="col-3 mb-3">
              <KeyVal label="On Hold" template="col">
                {data?.onHold}
              </KeyVal>
            </div>
            <div className="col-3 mb-3">
              <KeyVal label="MRP" template="col">
                <Amount value={data.mrp} decimalPlace="2" />
              </KeyVal>
            </div>
            <div className="col-3 mb-3">
              <KeyVal label="Cost" template="col">
                <Amount value={data.purchasePrice} decimalPlace="2" />
              </KeyVal>
            </div>
            <div className="col-3 mb-3">
              <KeyVal label="Offer" template="col">
                {data?.Offer || "N/A"}
              </KeyVal>
            </div>
            <div className="col-3 mb-3">
              <KeyVal label="Reference Type" template="col">
                {data?.ref?._grn ||
                  data?.ref?._correction ||
                  data?.ref?._movement ||
                  "N/A"}
              </KeyVal>
            </div>

            <div className="col-3 mb-3">
              <KeyVal label="Reference Id" template="col">
                {data?.ref?.grn || data?.ref?.correction || data?.ref?.movement}
              </KeyVal>
            </div>

            <div className="col-3 mb-3">
              <KeyVal label="Created On" template="col">
                <DateFormatter date={data?.createdAt} />
              </KeyVal>
            </div>

            <div className="col-3 mb-3">
              <KeyVal label="Manufacture Date" template="col">
                <DateFormatter
                  date={data?.manufactureDate}
                  format="dd MMM yyyy"
                />
              </KeyVal>
            </div>

            <div className="col-3 mb-3">
              <KeyVal label="Expiry Date" template="col">
                <DateFormatter date={data?.shelfLife} format="dd MMM yyyy" />
              </KeyVal>
            </div>

            <div className="col-3 mb-3">
              <KeyVal label="Location" template="col">
                {data?.location}
              </KeyVal>
            </div>

            <div className="col-3 mb-3">
              <KeyVal label="Area" template="col">
                {data?.area}
              </KeyVal>
            </div>

            <div className="col-3 mb-3">
              <KeyVal label="Rack" template="col">
                {data?.rackId}
              </KeyVal>
            </div>

            <div className="col-3 mb-3">
              <KeyVal label="Bin" template="col">
                {data?.binId}
              </KeyVal>
            </div>

            <div className="col-3 mb-3">
              <KeyVal label="IMEI/Serial No." template="col">
                {data?.barcode?.length ? data?.barcode[0] : "N/A"}
              </KeyVal>
            </div>
          </div>
          <AppCard title="Update Manufacture details">
            <div className="row">
              <div className="col-6 mb-2">
                <label className=" mb-0 fs-val-md">
                  Cheque Date
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  control={control}
                  name="manufactureDate"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePickerInput
                        placeholder="Choose"
                        value={[value]}
                        inpChange={dateChangeCb(onChange, "manufactureDate")}
                        config={defaultDateConfig}
                      />
                    );
                  }}
                />
                <InputErrorMsg msg={errors?.manufactureDate?.message} />
              </div>

              <div className="col-6 mb-2">
                <label className="mb-0 fs-val-md">
                  Expiry Date
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  control={control}
                  name="shelfLife"
                  render={({ field: { onChange, value } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={[value]}
                      inpChange={dateChangeCb(onChange, "shelfLife")}
                      config={defaultDateConfig}
                    />
                  )}
                />
                <InputErrorMsg msg={errors?.defaultDateConfig?.message} />
              </div>
              <div className="col-12 py-3">
                <TextareaInput
                  name="remarks"
                  placeholder=""
                  error={errors?.remarks?.message}
                  label="Enter Remarks"
                  register={register}
                  isMandatory={true}
                  maxLength="300"
                  rows="2"
                  note="Maximum allowed Character 300"
                />
              </div>

              <div className="col-12 text-end">
                <button
                  className="btn btn-primary fs-val-md"
                  disabled={loading}
                >
                  Submit {loading && <Spinner isSmall={loading} />}
                </button>
              </div>
            </div>
          </AppCard>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default memo(UpdateExpiryModal);
