import { CommonService, DealService } from "@sk/services";
import { AppCard, AppliedFilterLabel, BusyLoader, PageInfo } from "@sk/uis";
import { produce } from "immer";
import { get, merge } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import FilterForm from "./components/FilterForm";
import SummaryCard from "./components/SummaryCard";
import Table from "./components/Table";
import ManageGenerateBarcodeModal from "./modals/ManageGenerateBarcodeModal";

const defaultSummaryData = [
  {
    label: "Total Count",
    value: 0,
    loading: true,
    filter: {},
    color: "primary",
  },
  {
    label: "Active Count",
    value: 0,
    loading: true,
    filter: { status: "Active" },
    color: "success",
  },
  {
    label: "Inactive Count",
    value: 0,
    loading: true,
    filter: { status: "Inactive" },
    color: "danger",
  },
];

const formLabelsConfig = {
  productName: {
    label: "Product",
    valuePath: "[0].value.name",
  },
  code: {
    label: "Barcode",
  },
  dealId: {
    label: "Deal Id",
  },
};

const BarcodeList = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [summaryCard, setSummaryCard] = useState([...defaultSummaryData]);
  const [filterLabels, setFilterLabels] = useState([]);
  const [showBarcodeGenerateModal, setShowBarcodeGenerateModal] =
    useState(false);
  const filterData = useRef();
  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 15,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 15,
  });

  const applyFilter = useCallback(async () => {
    loadList();
    loadSummary();
    prepareFilterLabels();
    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(getFilterParams());
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList, loadSummary, prepareFilterLabels]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    CommonService.updateAppTitle("Generate Barcode");
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(getFilterParams());
    const d = Array.isArray(r.resp) ? r.resp : [];
    const u = await CommonService.attachUserInfo(d, ["createdBy"]);
    setData(u);
    setLoadingData(false);
  }, []);

  const prepareFilterLabels = useCallback(() => {
    const v = filterData.current || {};
    const l = CommonService.prepareAppliedFilterLabels(formLabelsConfig, v);
    setFilterLabels(l);
  }, []);

  const getFilterParams = () => {
    let d = filterData.current || {};
    let p = {
      page: paginationRef.current.activePage,
      count: paginationRef.current.rowsPerPage,
      filter: {},
    };
    if (d.dealId) {
      p["filter"]["dealId"] = d.dealId;
    }
    if (d.code) {
      p["filter"]["code"] = d.code;
    }
    if (d?.productName && d?.productName?.length) {
      p["filter"]["productName"] = {
        $regex: get(d, "productName[0].value", ""),
        $options: "i",
      };
    }
    return p;
  };

  const getData = (params) => {
    return DealService.getBarcodeList(params);
  };

  const getCount = async (params) => {
    try {
      const r = await DealService.getBarcodeCount(params);
      return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = (data) => {
    filterData.current = data.formData;
    paginationRef.current.startSlNo = 1;
    paginationRef.current.endSlNo = 15;
    paginationRef.current.activePage = 1;
    paginationRef.current.totalRecords = 0;
    init();
  };

  const loadSummary = useCallback(async () => {
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    const filterParams = getFilterParams();

    let promises = [];
    summaryCard.forEach((e) => {
      promises.push(getCount(merge({}, filterParams, { filter: e.filter })));
    });
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [summaryCard]);

  const onClickGenerateBarcode = useCallback(async () => {
    setShowBarcodeGenerateModal(true);
  }, []);

  const barcodeGenerateHideCb = useCallback(() => {
    setShowBarcodeGenerateModal(false);
  }, []);

  const closeBarcodeGenerateModal = useCallback(() => {
    setShowBarcodeGenerateModal(false);
    init();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <PageInfo title="Generate Barcode List" />
        </div>
        <div className="col-auto text-end">
          <button
            className="btn mt-4 btn-primary"
            onClick={onClickGenerateBarcode}
          >
            <i className="bi bi-plus-square me-1"></i> Generate Barcode
          </button>
        </div>
      </div>
      <div className="row align-self-center mb-3">
        {summaryCard.map((e, k) => (
          <div className="col-2" key={k}>
            <SummaryCard
              value={e.value}
              label={e.label}
              loading={e.loading}
              color={e.color}
            />
          </div>
        ))}
      </div>

      <AppCard>
        <FilterForm callback={filterFormCb} />
        {filterLabels.length > 0 ? (
          <div className="mb-2">
            <AppliedFilterLabel labels={filterLabels} />
          </div>
        ) : null}
      </AppCard>

      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <BusyLoader show={loadingData} />

      <ManageGenerateBarcodeModal
        show={showBarcodeGenerateModal}
        barcodeGenerateHideCb={barcodeGenerateHideCb}
        closeModal={closeBarcodeGenerateModal}
      ></ManageGenerateBarcodeModal>
    </>
  );
};

export default BarcodeList;
