import { NavService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl.No.", width: "3%" },
  { label: "Warehouse", width: "15%" },
  { label: "Ordered on", width: "10%" },
  { label: "Order ID", width: "10%" },
  { label: "Franchise", width: "15%" },
  { label: "Order Amount", width: "10%" },
  { label: "Location", width: "10%" },
  { label: "Payment Details", width: "8%" },
  { label: "Status", width: "10%" },
];

const containerStyle = {
  maxHeight: "600px",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "1400px",
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const viewOrder = (id) => {
    NavService.openInNewTab("/oms/orders/details", {
      id,
    });
  };

  return (
    <>
      <PaginationSummary
        loadingTotalRecords={loadingTotalRecords}
        paginationConfig={paginationConfig}
        className="mb-3"
      />
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3 fixed-width-table border rounded"
        style={containerStyle}
      >
        <table
          className="table table-sm bg-white table-hover"
          style={tableStyle}
        >
          <TableHeader
            data={headers}
            sort={sort}
            sortCb={sortCb}
            isSticky={true}
          />
          <tbody className="fs-val-md">
            {loading ? (
              <TableSkeletonLoader rows={10} cols={headers.length} />
            ) : null}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Orders Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {!loading &&
              data.map((x, i) => (
                <tr key={x._id} className="fs-val-md">
                  <td>{paginationConfig.startSlNo + i}</td>
                  <td>{x.warehouseAddress.name}</td>
                  <td>
                    <DateFormatter date={x.date} format="dd MMM yyyy" />
                    <span className="fs-val-sm text-muted ms-1">
                      <DateFormatter date={x.date} format="hh:mm a" />
                    </span>
                  </td>
                  <td>
                    <div className="mb-1">
                      <button
                        className="btn btn-link p-0 text-start text-dark fs-val-md"
                        onClick={() => viewOrder(x._id)}
                      >
                        {x._id}
                      </button>
                    </div>
                    <div className="fs-val-sm text-muted">
                      Type : {x.typeOfOrder}
                    </div>
                  </td>
                  <td>
                    <div className="mb-1 text-wrap">{x.franchise.name}</div>
                    <div className="fs-val-sm text-muted">
                      ID : {x.franchise.id}
                    </div>
                  </td>
                  <td>
                    <div className="mb-1">
                      <Amount value={x.orderAmount} />
                    </div>
                    <div className="text-muted fs-val-sm">{x.paymentMode}</div>
                  </td>
                  <td>
                    <div className="text-wrap">
                      {x.franchise?.district}, {x.franchise?.state}
                    </div>
                  </td>
                  <td>
                    <HighlightText status={x.paymentStatus} />
                  </td>
                  <td>
                    <HighlightText type={x.statusType} template={2}>
                      {x.status}
                    </HighlightText>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default memo(Table);
