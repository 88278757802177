import { TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import AdvanceFilterModal from "../modals/AdvanceFilterModal";

const FilterForm = ({ callback }) => {
  const { handleSubmit, getValues, register, setValue } = useForm({
    defaultValues: {
      category: "",
    },
  });

  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

  const onSubmit = (data) => {
    callback({ formData: data });
  };

  const viewAdvanceFilter = () => {
    setShowAdvancedFilter(true);
  };

  const closeModalCb = (d) => {
    if (d?.status && (d.status == "submit" || d.status == "reset")) {
      if (d.status == "reset") {
        setValue("category", "");
      }
      onSubmit({ ...getValues(), ...d.formData });
    }
    setShowAdvancedFilter(false);
  };

  const onSearch = useCallback(
    debounce((ev) => {
      callback({ formData: getValues() });
    }, 700),
    []
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          {/* Category */}
          <div className="col-8">
            <TextInput
              register={register}
              placeholder="Search For Category by Name/ID"
              isMandatory={false}
              name="category"
              callback={onSearch}
            />
          </div>

          {/* Filter Button */}
          <div className="col-auto">
            <button
              className="btn app-filter-btn"
              type="button"
              onClick={viewAdvanceFilter}
            >
              <i className="bi bi-funnel"></i> FILTER
            </button>
          </div>
        </div>
      </form>

      <AdvanceFilterModal show={showAdvancedFilter} closeModal={closeModalCb} />
    </>
  );
};

export default memo(FilterForm);
