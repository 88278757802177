import { Tabs } from "@sk/uis";
import { useState } from "react";
import ViewDealTier from "./tier/ViewDealTier";
import ViewDealWarehousePriceConfig from "./pricing/ViewDealWarehousePriceConfig";

const ViewDealPricingTier = ({ tierData, dealId }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const tabCb = (data) => {
    setActiveTab(data.value);
  };

  return (
    <>
      <div className="mb-3">
        <Tabs activeTab={activeTab} callback={tabCb} data={tabs} template={3} />
      </div>
      {activeTab?.key == "tier" && <ViewDealTier data={tierData} />}
      {activeTab?.key == "warehousePricing" && (
        <ViewDealWarehousePriceConfig dealId={dealId} />
      )}
    </>
  );
};

const tabs = [
  {
    key: "warehousePricing",
    tabName: "Warehouse Pricing",
  },
  {
    key: "tier",
    tabName: "Tier Details",
  },
];

export default ViewDealPricingTier;
