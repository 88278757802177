import { CommonService, NavService } from "@sk/services";
import {
  Alert,
  Amount,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  SelectInput,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { memo, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object({
  configData: yup.array(
    yup.object({
      rsp: yup
        .number()
        .nullable()
        .transform((v) => (isFinite(v) ? v : null))
        .min(0)
        .max(100),
      sk: yup
        .number()
        .nullable()
        .transform((v) => (isFinite(v) ? v : null)),
      store: yup
        .number()
        .nullable()
        .transform((v) => (isFinite(v) ? v : null)),
    })
  ),
});

const rspRefTypeOptions = [
  { label: "Bulk RSP set By", value: "" },
  { label: "Set MRP as RSP", value: "mrp" },
  { label: "Set B2B as RSP", value: "b2bprice" },
];

const headers = [
  { label: "Sl No", width: "3%", isCentered: true },
  { label: "Deal Name", key: "name", width: "20%", enableSort: true },
  { label: "Brand", width: "15%", key: "brandName", enableSort: true },
  { label: "Category", width: "15%", key: "categoryName", enableSort: true },
  {
    label: "MRP",
    width: "auto",
    isCentered: true,
    key: "mrp",
    enableSort: true,
    width: "7%",
  },
  {
    label: "Store Landing",
    width: "auto",
    isCentered: true,
    key: "b2bprice",
    enableSort: true,
    width: "7%",
  },
  { label: "SK Landing", width: "6%", isCentered: true },
  { label: "RSP (%)", width: "7%", isCentered: true },
  { label: "RSP (Rs)", width: "7%", isCentered: true },
  { label: "Store Profit", width: "7%", isCentered: true },
  { label: "Sk Profit", isCentered: true, width: "7%" },
];

const navigate = (type, id) => {
  const path = `/cms/${type}/view`;
  // NavService.openInNewTab(path, { id });
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort = {},
  sortCb,
  callback,
  busyLoader,
  brand,
  category,
}) => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: { configData: [], rspRefType: "" },
  });

  const [configData, rspRefType] = useWatch({
    control,
    name: ["configData", "rspRefType"],
  });

  useEffect(() => {
    if (data) {
      [...data].forEach((e, index) => {
        setValue(`configData.${index}.rsp`, null);
        setValue(`configData.${index}.sk`, null);
        setValue(`configData.${index}.store`, null);
      });
      onBulkRspChange();
    }
  }, [data, onBulkRspChange]);

  const onSubmit = async () => {
    if (!rspRefType) {
      let cn = configData.filter((e) => {
        return e.rsp;
      });

      if (!cn.length) {
        Toaster.error("Please Provide RSP");
        return;
      }
    }

    let list = [];
    configData.forEach((e, index) => {
      let d = data.filter((e, i) => {
        return index == i;
      });
      if (Number(e.rsp) && d?.length) {
        list.push({ id: d[0]._id, rspDisc: e.rsp, mrp: d[0].mrp });
      }
    });
    let isRspMoreThanMrp = false;
    let isNegativeValue = false;
    let isDiscMore = false;

    list.forEach((e) => {
      // if (e.rspDisc > e.mrp) {
      //   isRspMoreThanMrp = true;
      // }
      if (e.rspDisc < 0) {
        isNegativeValue = true;
      }
      if (e.rspDisc > 100) {
        isDiscMore == true;
      }
    });

    if (!rspRefType && isRspMoreThanMrp) {
      Toaster.error("RSP Should not be More than MRP");
      return;
    }
    if (!rspRefType && isNegativeValue) {
      Toaster.error("RSP Should not be less than 0%");
      return;
    }

    if (!rspRefType && isDiscMore) {
      Toaster.error("RSP Should be less than 100%");
      return;
    }
    let cnt = rspRefType?.length ? paginationConfig.totalRecords : list.length;
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed with " + cnt + " Records",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (res.isDismissed) {
      return;
    }

    let p = {};

    if (rspRefType?.length) {
      p = {
        rule: rspRefType == "mrp" ? "SET-RSP-AS-MRP" : "SET-RSP-AS-B2B",
        noOfRows: paginationConfig.totalRecords,
      };
    } else {
      p = {
        rule: "BYDEAL",
        deals: list.map((e) => {
          delete e.mrp;
          return e;
        }),
      };
    }

    callback({ status: "submit", payload: p });
  };

  const onBulkRspChange = (v) => {
    let val = getValues("rspRefType");
    if (val.length) {
      [...data].forEach((e, index) => {
        if (val == "mrp") {
          // setValue(`configData.${index}.rsp`, 0);
          let d = e;
          let v = 0;
          let mrp = d?.mrp;

          let rspPrice = mrp;

          let sk = rspPrice - (d?.slc || 0);
          let store = rspPrice - (d?.b2bprice || 0);
          let skPerc = CommonService.roundedByDecimalPlace(
            (sk / d.slc) * 100,
            2
          );
          let storePerc = CommonService.roundedByDecimalPlace(
            (store / d.b2bprice) * 100,
            2
          );
          setValue(`configData.${index}.sk`, sk, { shouldValidate: true });
          setValue(`configData.${index}.store`, store, {
            shouldValidate: true,
          });
          setValue(`configData.${index}.skPerc`, skPerc);
          setValue(`configData.${index}.storePerc`, storePerc);
          setValue(`configData.${index}.rsp`, 0);
          setValue(
            `configData.${index}.rspPrice`,
            CommonService.roundedByDecimalPlace(rspPrice, 2)
          );
        } else if (val == "b2bprice") {
          let d = e;
          let v = CommonService.roundedByDecimalPlace(
            ((e.mrp - e.b2bprice) / e.mrp) * 100,
            2
          );
          let mrp = d?.mrp;

          let rspPrice = mrp - mrp * (v / 100);

          let sk = rspPrice - (d?.slc || 0);
          let store = rspPrice - (d?.b2bprice || 0);
          let skPerc = CommonService.roundedByDecimalPlace(
            (sk / d.slc) * 100,
            2
          );
          let storePerc = CommonService.roundedByDecimalPlace(
            (store / d.b2bprice) * 100,
            2
          );
          setValue(`configData.${index}.sk`, sk, { shouldValidate: true });
          setValue(`configData.${index}.store`, store, {
            shouldValidate: true,
          });
          setValue(`configData.${index}.skPerc`, skPerc);
          setValue(`configData.${index}.storePerc`, storePerc);
          setValue(`configData.${index}.rsp`, v);
          setValue(
            `configData.${index}.rspPrice`,
            CommonService.roundedByDecimalPlace(rspPrice, 2)
          );
        } else {
          setValue(`configData.${index}.sk`, 0, { shouldValidate: true });
          setValue(`configData.${index}.store`, 0, {
            shouldValidate: true,
          });
          setValue(`configData.${index}.skPerc`, 0);
          setValue(`configData.${index}.storePerc`, 0);
          setValue(`configData.${index}.rsp`, 0);
          setValue(`configData.${index}.rspPrice`, 0);
        }
      });
    } else {
      resetInputData();
    }
  };

  const resetInputData = () => {
    [...data].forEach((e, index) => {
      setValue(`configData.${index}.sk`, null, { shouldValidate: true });
      setValue(`configData.${index}.store`, null, {
        shouldValidate: true,
      });
      setValue(`configData.${index}.skPerc`, 0);
      setValue(`configData.${index}.storePerc`, 0);
      setValue(`configData.${index}.rsp`, null);
      setValue(`configData.${index}.rspPrice`, null);
    });
    setValue("rspRefType", "");
  };

  const discountCb = (e, index, dId) => {
    const v = getValidDiscountVal(e.target.value);
    setValue(`configData.${index}.rsp`, v, { shouldValidate: true });
    let d = [...data].find((e) => {
      return e._id == dId;
    });
    // b2b = 9 mrp =20 slc = 6.89
    let mrp = d?.mrp;

    let rspPrice = mrp - mrp * (v / 100); // v = 15 rsp =17 sk = 10.11

    let sk = rspPrice - (d?.slc || 0);
    let store = rspPrice - (d?.b2bprice || 0);
    let skPerc = CommonService.roundedByDecimalPlace((sk / d.slc) * 100, 2);
    let storePerc = CommonService.roundedByDecimalPlace(
      (store / d.b2bprice) * 100,
      2
    );
    setValue(`configData.${index}.sk`, sk, { shouldValidate: true });
    setValue(`configData.${index}.store`, store, { shouldValidate: true });
    setValue(`configData.${index}.skPerc`, skPerc);
    setValue(`configData.${index}.storePerc`, storePerc);
    setValue(
      `configData.${index}.rspPrice`,
      CommonService.roundedByDecimalPlace(rspPrice, 2)
    );
  };

  const getValidDiscountVal = (val) => {
    if (val > 100) {
      return 100;
    }
    return val ? CommonService.roundedByDecimalPlace(val, 6) : val;
  };

  const NavToBrand = (id) => {
    return NavService.openInNewTab("/cms/brand/view", {
      id,
    });
  };

  const NavToCategory = (id) => {
    return NavService.openInNewTab("/cms/category/view", {
      id,
    });
  };

  const NavToDeal = (id) => {
    return NavService.openInNewTab("/cms/deal/view", {
      id,
    });
  };

  return (
    <>
      <div className="row mb-2 mt-3">
        <div className="col pt-4">
          <PaginationSummary
            paginationConfig={paginationConfig}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
        {brand?.length || category?.length ? (
          <>
            {rspRefType ? (
              <div className="col-auto text-danger fs-val-sm pt-3 fw-semibold">
                Note : total {paginationConfig.totalRecords} records will be
                updated
              </div>
            ) : null}
            <div className="col-auto ms-auto">
              <SelectInput
                register={register}
                options={rspRefTypeOptions}
                name="rspRefType"
                callback={onBulkRspChange}
              />
            </div>
            {data.length && rspRefType ? (
              <div className="col-auto ms-auto ps-3">
                <button
                  className="btn  btn-outline-danger"
                  onClick={resetInputData}
                >
                  Reset All RSP
                </button>
              </div>
            ) : null}
          </>
        ) : (
          <div className="col-auto text-primary">
            Note : To Set Bulk MRP or RSP , please select Brand or Category
          </div>
        )}
      </div>
      <table className="table table-bordered bg-white fs-val-sm">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {loading ? (
            <TableSkeletonLoader
              cols={headers.length}
              rows={paginationConfig.rowsPerPage}
            />
          ) : null}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound> No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {!loading &&
            data.map((x, index) => (
              <tr key={index} className="fs-val-md">
                {/* serial no. */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                <td>
                  <div
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      NavToDeal(x?._id);
                    }}
                  >
                    {x?.name} - {x?._id}
                  </div>
                </td>

                <td>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      NavToBrand(x?.brandId);
                    }}
                  >
                    {x?.brandName} - {x?.brandId}
                  </div>
                </td>

                <td>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      NavToCategory(x?.categoryId);
                    }}
                  >
                    {x?.categoryName} - {x?.categoryId}
                  </div>
                </td>

                <td className="text-center">
                  <Amount value={x.mrp} decimalPlace={2} />
                </td>

                <td className="text-center">
                  <Amount value={x.b2bprice} decimalPlace={2} />
                </td>

                <td className="text-center">
                  <Amount value={x.slc} decimalPlace={2} />
                </td>

                <td className="text-center">
                  <TextInput
                    type="number"
                    error={errors?.[`configData.${index}.rsp`]?.message}
                    register={register}
                    placeholder="RSP (%)"
                    name={`configData.${index}.rsp`}
                    min={0}
                    disabled={rspRefType}
                    callback={(e) => {
                      discountCb(e, index, x?._id);
                    }}
                  />
                </td>

                <td className="text-center">
                  <TextInput
                    type="number"
                    register={register}
                    placeholder="RSP (Rs)"
                    name={`configData.${index}.rspPrice`}
                    min={0}
                    disabled={true}
                  />
                </td>

                <td
                  className={classNames({
                    "text-center": true,
                    "text-success": getValues(`configData.${index}.store`) > 0,
                    "text-danger": getValues(`configData.${index}.store`) < 0,
                    "text-primary": getValues(`configData.${index}.store`) == 0,
                  })}
                >
                  <Amount
                    value={getValues(`configData.${index}.store`) || 0}
                    decimalPlace={2}
                  />
                </td>

                <td
                  className={classNames({
                    "text-center": true,
                    "text-success": getValues(`configData.${index}.sk`) > 0,
                    "text-danger": getValues(`configData.${index}.sk`) < 0,
                    "text-primary": getValues(`configData.${index}.sk`) == 0,
                  })}
                >
                  <Amount
                    value={getValues(`configData.${index}.sk`) || 0}
                    decimalPlace={2}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* pagination block */}
      {!loading && data.length > 0 && (
        <PaginationBlock
          paginationConfig={paginationConfig}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      )}
      <div className="text-end">
        {data?.length ? (
          <button
            className="btn btn-primary"
            onClick={onSubmit}
            disabled={busyLoader}
          >
            Submit {busyLoader ? <Spinner isSmall={true} /> : null}
          </button>
        ) : null}
      </div>
    </>
  );
};

export default memo(Table);
