import React, { useEffect, useState } from "react";
import { GrnService, PoService, OmsService } from "@sk/services";
import { AppCard, BtnLink, DateFormatter } from "@sk/uis";
import { NavService } from "@sk/services";

const CmsOverviewLastesInfo = ({ pid }) => {
  const [lastPO, setLastPO] = useState({
    loading: true,
    id: "",
    date: "",
    units: 0,
  });
  const [lastGRN, setLastGRN] = useState({
    loading: true,
    id: "",
    date: "",
    units: 0,
  });
  const [lastOrder, setLastOrder] = useState({
    loading: true,
    id: "",
    date: "",
    units: 0,
  });

  useEffect(() => {
    const fetchLastData = async () => {
      try {
        const poDetails = await PoService.getLastPoDetailsByProductId(pid);
        setLastPO({
          loading: false,
          id: poDetails.id,
          date: poDetails.date,
          units: poDetails.requestedQty,
        });

        const grnDetails = await GrnService.getLastGrnDetailsByProductId(pid);
        setLastGRN({
          loading: false,
          id: grnDetails.id,
          date: grnDetails.date,
          units: grnDetails.qty,
        });

        const orderDetails = await OmsService.getLatestOrderDetailsByProductId(
          pid
        );
        setLastOrder({
          loading: false,
          id: orderDetails.id,
          date: orderDetails.date,
          units: orderDetails.qty,
        });
      } catch (error) {
        setLastPO({ loading: false, id: "", date: "", units: 0 });
        setLastGRN({ loading: false, id: "", date: "", units: 0 });
        setLastOrder({ loading: false, id: "", date: "", units: 0 });
      }
    };

    if (pid) {
      fetchLastData();
    }
  }, [pid]);

  const InfoCard = ({ title, data, type }) => (
    <AppCard noPad={true} className="h-100">
      <div className="p-2">
        <div className="mb-1 fs-val-sm text-black-50">{title}</div>
        {data.loading ? (
          <div className="fs-val-xs">Loading...</div>
        ) : data.id ? (
          <>
            <div className="mb-1">
              <BtnLink
                className="fw-semibold fs-val-sm"
                onClick={() => {
                  if (type === "po") {
                    NavService.openInNewTab(
                      `/purchase-order/view?id=${data.id}`
                    );
                  } else if (type === "grn") {
                    NavService.openInNewTab(
                      `/purchase-order/grn/view?id=${data.id}`
                    );
                  } else if (type === "order") {
                    NavService.openInNewTab(
                      `/oms/orders/details?id=${data.id}`
                    );
                  }
                }}
              >
                {data.id}
              </BtnLink>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <span className="text-muted fw-medium fs-val-sm">
                <DateFormatter date={data.date} format="dd MMM yyyy" />
              </span>
              <span className="text-muted fw-medium fs-val-sm">
                {data.units} units
              </span>
            </div>
          </>
        ) : (
          <div className="text-muted fs-val-xs">No data found</div>
        )}
      </div>
    </AppCard>
  );

  return (
    <div className="row row-cols-1 row-cols-md-3 mb-3">
      <div className="col">
        <InfoCard title="Last PO" data={lastPO} type="po" />
      </div>
      <div className="col">
        <InfoCard title="Last GRN" data={lastGRN} type="grn" />
      </div>
      <div className="col">
        <InfoCard title="Last Order" data={lastOrder} type="order" />
      </div>
    </div>
  );
};

export default CmsOverviewLastesInfo;
