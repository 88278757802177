import { NavService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo, useEffect, useState } from "react";

import FetchDisplayDealCount from "../../../../components/FetchDisplayDealCount";

import FetchDisplayProductAvailableStock from "../../../../components/FetchDisplayProductAvailableStock";

import orderBy from "lodash/orderBy";

import each from "lodash/each";

const defaultHeaders = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "ID", width: "10%", key: "id", enableSort: true },
  {
    label: "Name",
    width: "25%",
    key: "name",
    isSticky: true,
    stickyPosition: "left",
  },
  { label: "MRP", width: "10%", isCentered: true },
  { label: "HSN", width: "8%", key: "hsn", enableSort: true },
  { label: "Tax", width: "5%", key: "tax", enableSort: true },
  { label: "Case Qty", width: "6%", key: "caseQty", isCentered: true },
  { label: "Inner Case Qty", width: "10%", key: "innerCase", isCentered: true },
  { label: "Available Stock", width: "10%", isCentered: true },
  { label: "Reserve Enabled", width: "10%", isCentered: true },
  { label: "Status", width: "10%", isCentered: true },
  { label: "Brand", width: "15%", key: "brand" },
  { label: "Category", width: "15%", key: "category" },
  { label: "Deals", width: "5%", isCentered: true },
  { label: "Actions", width: "10%", isCentered: true },
];

const rbac = {
  viewButton: ["ViewProduct"],
  editButton: ["EditProduct"],
};

const tableStyle = {
  minWidth: "2000px",
};

const tableContainerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const Table = ({
  data,
  loading,
  sort,
  sortCb,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  moreColumns = [],
}) => {
  const viewDetails = (id) => {
    NavService.openInNewTab("/cms/product/view", { id: id });
  };
  const editDetails = (id) => {
    NavService.openInNewTab("/cms/product/manage", { id: id });
  };

  const [headers, setHeaders] = useState([...defaultHeaders]);

  useEffect(() => {
    let t = [];
    each(moreColumns || {}, (x) => {
      t.push(x);
    });
    setHeaders(orderBy([...defaultHeaders, ...t], ["priority"], ["asc"]));
  }, [moreColumns]);

  const NavToBrand = (id) => {
    return NavService.openInNewTab("/cms/brand/view", {
      id,
    });
  };

  const NavToCategory = (id) => {
    return NavService.openInNewTab("/cms/category/view", {
      id,
    });
  };

  return (
    <>
      <div
        className="tbl-scroll-container custom-scrollbar fixed-width-table mb-2"
        style={tableContainerStyle}
      >
        <table className="table table-hover table-sm" style={tableStyle}>
          <TableHeader
            data={headers}
            sort={sort}
            sortCb={sortCb}
            noBg
            isSticky
          />
          <tbody className="fs-val-md">
            {/*  When Table is Loading  */}
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound> No Data Found </NoDataFound>
                </td>
              </tr>
            ) : null}
            {!loading &&
              (data || []).map((x, index) => (
                <tr key={x?._id} className="fs-val-md">
                  {/* Sl.No :  priority 1  */}
                  <td className="text-center">
                    {paginationConfig.startSlNo + index}
                  </td>

                  <td>{x?._id || "N/A"}</td>

                  {/* Product Name  :  priority 2  */}
                  <td
                    style={{ position: "sticky", left: 0, zIndex: 1 }}
                    className="bg-white"
                  >
                    <button
                      className="btn btn-link text-dark text-start p-0 fs-val-md text-wrap"
                      style={{ maxWidth: "300px" }}
                      onClick={() =>
                        NavService.openInNewTab(`/cms/product/view`, {
                          id: x._id,
                        })
                      }
                    >
                      {x.name}
                    </button>
                  </td>

                  {/* MRP : Priority 8 */}
                  <td className="text-center">{<Amount value={x?.mrp} />}</td>

                  {/* HSN */}
                  <td>{x?.HSNNumber || "N/A"}</td>

                  <td>{x?.tax + "%" || "N/A"}</td>

                  {/* Case Qty : Priority 3 */}
                  <td className="text-center">{x.caseQty || "N/A"}</td>

                  {/* Inner Pack Qty : Priority   4 */}
                  <td className="text-center">{x.innerPackQty || "N/A"}</td>

                  {/* Available Stock : Priority 10 */}
                  <td className="text-primary text-center">
                    <FetchDisplayProductAvailableStock
                      pid={x?._id}
                      pName={x?.name}
                      uom={x?.uomType || ""}
                    />
                  </td>

                  {/*  Allowed Reserve Order  Priority 11 */}
                  <td className="text-center">
                    <HighlightText
                      type={x.allowReserveOrder ? "success" : "danger"}
                      template={2}
                    >
                      {x.allowReserveOrder ? "Yes" : "No"}
                    </HighlightText>
                  </td>

                  {/* Status : Priority 13 */}
                  <td className="text-center">
                    <HighlightText
                      status={x.is_active ? "Active" : "Inactive"}
                      isBadge={true}
                    />
                  </td>

                  {/* Brand :  Priority 5 */}
                  <td>
                    <button
                      className="btn btn-link text-dark text-start p-0 fs-val-md text-wrap"
                      onClick={() => NavToBrand(x._brand[0]._id)}
                      style={{ maxWidth: "200px" }}
                    >
                      {x.brandLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        <>
                          {x?._brand?.[0]?.name || "N/A"} -{" "}
                          {x?._brand?.[0]?._id || "N/A"}
                        </>
                      )}
                    </button>
                  </td>

                  {/*Category :  Priority 6 */}
                  <td>
                    <button
                      className="btn btn-link text-dark text-start p-0 fs-val-md text-wrap"
                      onClick={() => NavToCategory(x?._category?.[0]?._id)}
                      style={{ maxWidth: "200px" }}
                    >
                      {x.categoryLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        <>
                          {x?._category?.[0]?.name || "N/A"} -{" "}
                          {x?._category?.[0]?._id || "N/A"}
                        </>
                      )}
                    </button>
                  </td>

                  {/* SKU Code  Priority 7  */}
                  {moreColumns?.skuCode?.checked && (
                    <td className="text-center">{x?.skuCode || "N/A"}</td>
                  )}

                  {/* Total Deal Linked  : Priority 9 */}
                  <td className="text-primary text-center">
                    <FetchDisplayDealCount
                      params={{
                        filter: { product: { $elemMatch: { id: x?._id } } },
                      }}
                      pid={x?._id}
                    />
                  </td>

                  {/* Created At Priority 12 */}
                  {moreColumns?.createdAt?.checked && (
                    <td>
                      <div className="mb-1 ms-2">
                        {<DateFormatter date={x?.createdAt} />}
                      </div>
                      <div className="ms-2 text-primary">
                        by{" "}
                        {x.userLoading ? (
                          <Spinner type="dots" />
                        ) : (
                          x?._createdBy?.name || "N/A"
                        )}
                      </div>
                    </td>
                  )}

                  {/* Action : Priority 14 */}
                  <td className="text-center">
                    <Rbac roles={rbac.viewButton}>
                      <button
                        className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                        onClick={() => {
                          viewDetails(x?._id);
                        }}
                      >
                        View
                      </button>
                    </Rbac>

                    <Rbac roles={rbac.editButton}>
                      <button
                        className="btn btn-sm btn-outline-warning me-1 fs-val-xs"
                        onClick={() => {
                          editDetails(x?._id);
                        }}
                      >
                        Edit
                      </button>
                    </Rbac>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default memo(Table);
