import { UtilityService } from "@sk/services";
import {
  BooleanCheckboxInput,
  NoDataFound,
  SelectInput,
  TableHeader,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { each } from "lodash";
import { memo, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup";

const attributeValidationSchema = yup.object({
  attributeForm: yup.object({
    name: yup.string().trim().required("Attribute is required"),
    others: yup
      .string()
      .trim()
      .when("name", {
        is: (name) => name == "others",
        then: (scheme) => scheme.label("Others").required(),
      }),
    mandatory: yup.boolean(true),
    type: yup.string().trim().required("Type is required"),
    pattern: yup.string().trim(),
    // .when("type", {
    //   is: (type) => ["Text", "Number"].indexOf(type) != -1,
    //   then: (scheme) => scheme.required().label("Pattern"),
    // })
    // .when("type", {
    //   is: (type) => ["Radio", "Select"].indexOf(type) != -1,
    //   then: (scheme) => scheme.required().label("Comma Separated Values"),
    // }),
    min: yup
      .number()
      .nullable()
      .transform((v) => (isNaN(v) ? null : v))
      .when("type", {
        is: "Number",
        then: (scheme) => scheme.required().label("Min Value").max(10).min(1),
      }),
    max: yup
      .number()
      .nullable()
      .transform((v) => (isNaN(v) ? null : v))
      .when("type", {
        is: "Number",
        then: (scheme) =>
          scheme
            .required()
            .label("Max Value")
            .max(10)
            .min(1)
            .min(yup.ref("min"), "max must be greater than min")
            .test(
              "is-greater",
              "max must be greater than min",
              function (value) {
                return value > this.resolve(yup.ref("min"));
              }
            ),
      }),

    _id: yup.string(),
    editIndex: yup
      .number()
      .nullable()
      .transform((v) => (isNaN(v) ? null : v)),
  }),
});

const defaultAttributeFormData = {
  editIndex: -1,
  type: "",
  name: "",
  mandatory: false,
  others: "",
  pattern: "",
  min: 0,
  max: 0,
  _id: "",
};

const tableHeaders = [
  { label: "SL.No", isCentered: true },
  { label: "Name" },
  { label: "Type" },
  { label: "Value" },
  { label: "Mandatory", isCentered: true },
  { label: "Actions", isCentered: true },
];

const attributeTypeOption = [
  { label: "Select  Type*", value: "" },
  { label: "Text", value: "Text" },
  { label: "TextArea", value: "TextArea" },
  { label: "Radio", value: "Radio" },
  { label: "Select", value: "Select" },
  { label: "Number", value: "Number" },
];

// const isMandatoryAttributeOption = [
//   { label: "No", value: false },
//   { label: "Yes", value: true },
// ];

// const attributeTypeOptionsOnTypName = [
//   {
//     type: "Text",
//     value: "Text",
//     attribute: [
//       {
//         name: "Pattern",
//         key: "pattern",
//       },
//     ],
//   },
//   {
//     type: "TextArea",
//     value: "Textarea",
//   },
//   {
//     type: "Number",
//     value: "Number",
//     attribute: [
//       {
//         name: "pattern",
//         key: "pattern",
//       },
//       {
//         name: "Min Value",
//         key: "min",
//       },
//       {
//         name: "Max Value",
//         key: "max",
//       },
//     ],
//   },
//   {
//     type: "Select",
//     value: "Select",
//     attribute: [
//       {
//         name: "Comma separated Values",
//         key: "pattern",
//       },
//     ],
//   },
//   {
//     type: "Radio",
//     value: "Radio",
//     attribute: [
//       {
//         name: "Comma separated Values",
//         key: "pattern",
//       },
//     ],
//   },
// ];

const Attribute = () => {
  const {
    register,
    getValues,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useFormContext();

  const [attributeList, isAttributeMandatory, selectedType, editIndex] =
    useWatch({
      control,
      name: [
        "attributes",
        "attributeForm.mandatory",
        "attributeForm.type",
        "attributeForm.editIndex",
      ],
    });

  const [attributeNameOptions, setAttributeNameOptions] = useState([]);

  useEffect(() => {
    // fetching  Name options from API
    const fetchAttributeList = async () => {
      const r = await UtilityService.getConfigCsaList({});
      if (Array.isArray(r?.resp)) {
        let k = r.resp.map((e) => ({
          label: e.name,
          value: e.name,
        }));
        k.push({ label: "Others", value: "others" });
        k.unshift({ label: "Select Attributes*", value: "" });
        setAttributeNameOptions(k);
        setValue("attributeForm.name", k[0].value);
      } else {
        setAttributeNameOptions([]);
      }
    };
    fetchAttributeList();
  }, [setValue]);

  const onNameOptionChange = async () => {
    setValue("attributeForm.type", "");
    await trigger("attributeName");
  };

  const onAttributeTypeChange = (value) => {
    setValue("attributeForm.type", value);
    setValue("attributeForm.pattern", "");
    setValue("attributeForm.min", 0);
    setValue("attributeForm.max", 0);
  };

  // ----------------------ADD FORM------------------------
  const addAttribute = async () => {
    // attribute form validation
    let errMsg = "";
    try {
      await attributeValidationSchema.validate({
        attributeForm: getValues("attributeForm"),
      });
      errMsg = "";
    } catch (error) {
      errMsg = error.message || "";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    let f = getValues("attributeForm");

    const data = attributeList.slice();
    // checking duplicate data in list
    let duplicate = data.find((k) => {
      let m = { o: k?.others?.toLowerCase(), n: k?.name?.toLowerCase() };
      let fm = { o: f?.others?.toLowerCase(), n: f?.name?.toLowerCase() };
      return f.name == "others" ? m.o == fm.o || fm.o == m.n : m.n == fm.n;
    });
    const formData = Object.assign({}, f);
    if (f.editIndex >= 0) {
      data.splice(f.editIndex, 1, formData);
    } else {
      if (duplicate?.name) {
        Toaster.error("Item already exists in List ");
        return;
      }
      data.push(formData);
    }
    setValue("attributes", data);
    resetInputValue();
  };

  // --------------------Edit FORM---------------------------------
  const editAttribute = (index) => {
    const d = attributeList[index] || {};
    each(defaultAttributeFormData, (x, k) => {
      if (k == "editIndex") {
        setValue("attributeForm.editIndex", index);
      } else {
        setValue("attributeForm." + k, d[k]);
      }
    });
  };

  // --------------------Delete FORM---------------------------------
  const deleteAttribute = (index) => {
    const k = [...attributeList];
    k.splice(index, 1);
    setValue("attributes", k);
  };

  // ------------------------RESET FORM--------------------------------------
  const resetInputValue = () => {
    each(defaultAttributeFormData, (x, k) => {
      setValue("attributeForm." + k, x);
    });
    setValue("attributeForm.mandatory", false);
  };

  return (
    <div>
      <div className=" fs-val-lg fw-semibold mb-4">Attributes</div>

      <div className="row">
        <div className="col-4">
          {/* ATTRIBUTE  FORM FIELDS  */}
          <div className="row">
            {/* Name */}
            <div className="col-12">
              <Controller
                name="attributeForm.mandatory"
                render={({ field: { onChange, value } }) => (
                  <BooleanCheckboxInput
                    label="is Mandatory"
                    name="attributeForm.mandatory"
                    error={errors?.attributeForm?.mandatory?.message}
                    isMandatory={false}
                    callback={onChange}
                    value={value}
                  />
                )}
              />
            </div>

            {isAttributeMandatory ? (
              <>
                <div className="col-12">
                  <SelectInput
                    register={register}
                    name="attributeForm.name"
                    isMandatory={true}
                    options={attributeNameOptions}
                    callback={onNameOptionChange}
                    error={errors?.attributeForm?.name?.message}
                  />
                </div>

                {getValues("attributeForm.name") == "others" ? (
                  <div className="col-12">
                    <TextInput
                      type="text"
                      placeholder="Enter New Attribute Name*"
                      register={register}
                      name="attributeForm.others"
                      error={errors?.attributeForm?.others?.message}
                      isMandatory={true}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="col-12">
                  <SelectInput
                    register={register}
                    name="attributeForm.type"
                    isMandatory={true}
                    options={attributeTypeOption}
                    callback={onAttributeTypeChange}
                    error={errors?.attributeForm?.type?.message}
                  />
                </div>

                {selectedType && selectedType != "TextArea" && (
                  <div className="col-12">
                    <TextInput
                      type="text"
                      placeholder={
                        ["Text", "Number"].indexOf(selectedType) != -1
                          ? "Pattern"
                          : "Comma separated Values"
                      }
                      register={register}
                      name="attributeForm.pattern"
                      isMandatory={false}
                      error={
                        errors?.attributeForm &&
                        errors?.attributeForm.pattern &&
                        errors?.attributeForm.pattern?.message
                      }
                    />
                  </div>
                )}

                {/* number type related inputs */}
                {selectedType == "Number" && (
                  <>
                    <div className="col-6">
                      <TextInput
                        type="number"
                        // label="Min"
                        placeholder="Min Value*"
                        register={register}
                        name="attributeForm.min"
                        isMandatory={true}
                        error={
                          errors?.attributeForm &&
                          errors?.attributeForm.min &&
                          errors?.attributeForm.min?.message
                        }
                      />
                    </div>
                    <div className="col-6">
                      <TextInput
                        type="number"
                        // label="Max"
                        placeholder="Max Value*"
                        register={register}
                        name="attributeForm.max"
                        isMandatory={true}
                        error={
                          errors?.attributeForm &&
                          errors?.attributeForm.max &&
                          errors?.attributeForm.max?.message
                        }
                      />
                    </div>
                  </>
                )}

                <div className="col-auto ms-auto text-end">
                  {/* Button */}
                  <button
                    type="button"
                    onClick={addAttribute}
                    className={classNames({
                      btn: true,
                      "mt-2 mb-3 btn-sm": true,
                      "btn-primary": !editIndex || editIndex == -1,
                      "btn-success": editIndex >= 0,
                    })}
                  >
                    {editIndex >= 0 ? null : (
                      <i className="bi bi-plus me-2 "></i>
                    )}
                    {editIndex >= 0 ? "Update" : "Add"}
                  </button>
                  {editIndex >= 0 && (
                    <button
                      type="button"
                      onClick={resetInputValue}
                      className="btn-danger btn ms-2 mt-2 mb-3 btn-sm"
                    >
                      <i className="bi bi-close me-2 "></i>
                      Cancel
                    </button>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="col-8">
          {/* ATTRIBUTE TABLE */}

          <table className="table table-bordered table-sm">
            <TableHeader data={tableHeaders} />
            {/* TABLE BODY */}
            <tbody className="table-group-divider">
              {!attributeList?.length ? (
                <tr>
                  <td colSpan={tableHeaders.length}>
                    <div className="px-3">
                      <NoDataFound>No Attributes Added</NoDataFound>
                    </div>
                  </td>
                </tr>
              ) : null}
              {attributeList.map((item, index) => (
                <tr key={index} className="fs-val-md">
                  <td className="text-center p-2">{index + 1}</td>
                  <td className="p-2">
                    {item?.name == "others" ? item.others : item?.name}
                  </td>
                  <td className="p-2">{item?.type}</td>
                  <td className="p-2">
                    <div>{item?.pattern || "N/A"}</div>
                    {item.min ? (
                      <div className="fs-10">
                        Min : {item?.min}
                        <span className="px-2">Max : {item?.max}</span>
                      </div>
                    ) : null}
                  </td>
                  <td className="text-center p-2">
                    {item.mandatory ? (
                      <i className="bi bi-check2 text-success"></i>
                    ) : (
                      <i className="bi bi-x text-danger"></i>
                    )}
                  </td>
                  <td className="text-center  p-2">
                    <span
                      className="shadow-sm p-0 px-1 bg-white rounded  me-3"
                      onClick={() => deleteAttribute(index)}
                      role="button"
                      tabIndex={0}
                    >
                      <i className="bi bi-x  text-danger cursor-pointer"></i>
                    </span>
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() => editAttribute(index)}
                    >
                      <i className="bi bi-pencil-square  text-primary cursor-pointer"></i>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default memo(Attribute);
