import { Amount, AppCard, HighlightText, KeyVal } from "@sk/uis";
import Inventories from "./Inventories";
import Keywords from "./Keywords";

const Basic = ({ data }) => {
  const priceDetails = [
    {
      label: "MRP",
      value: <Amount decimalPlace="2" value={data?.mrp} />,
    },
    {
      label: "B2B Price",
      value: (
        <>
          <Amount decimalPlace="2" value={data?.b2bPrice} />
          {data?.discountMargin ? (
            <span className="text-muted fs-val-sm">
              {data?.discountMargin}%
            </span>
          ) : null}
        </>
      ),
    },
    {
      label: "CSC Price",
      value: (
        <>
          <Amount decimalPlace="2" value={data?.cscPrice} />
          {data?.cscDiscount ? (
            <span className="text-muted fs-val-sm">{data?.cscDiscount}%</span>
          ) : null}
        </>
      ),
    },
    {
      label: "Agent Price",
      value: (
        <>
          <Amount decimalPlace="2" value={data?.agentPrice} />
          {data?.agentDiscount ? (
            <span className="text-muted fs-val-sm">{data?.agentDiscount}%</span>
          ) : null}
        </>
      ),
    },
  ];

  const b2bConfig = [
    { label: "MAX B2B Quantity", value: data?.maxB2BQty },
    { label: "MAX B2B Period", value: data?.maxB2BPeriod },
    { label: "Period Type", value: data?.maxB2BPeriodType },
    { label: "Increment Quantity", value: data?.incrementQuantity },
    { label: "Minimum Quantity", value: data?.minimumQuantity },
    { label: "B2B/Day Order Limit", value: data?.perOrderLimit || "NA" },
  ];

  const features = [
    { label: "Deal of the day", value: data?.dealOfDay },
    { label: "King sale", value: data?.kingsaleEnabled },
    { label: "Show Case Deal", value: data?.isShowCaseDeal },
    { label: "KPB Deal", value: data?.isKpb },
    { label: "Seller Scheme", value: data?.allowSchemesforSellers },
    { label: "Group Buying", value: data?.isGroupBuyingEnabled },
    { label: "KC Store", value: data?.isKCStoreEnabled },
    { label: "Content Validated", value: data?.isContentValidated },
    { label: "Backend Billing", value: data?.isBackendBillingEnabled },
  ];

  return (
    <div className="row g-3">
      {/* Price Information */}
      <div className="col-md-3">
        <AppCard title="Price Information" className="h-100">
          {priceDetails.map((item, index) => (
            <div key={index}>
              <KeyVal
                label={item.label}
                labelCol="col-5"
                contentCol="col-7"
                noBottomSpace={index === priceDetails.length - 1}
              >
                : {item.value} {item.subValue}
              </KeyVal>
            </div>
          ))}
        </AppCard>
      </div>

      {/* B2B Configuration and Serviceable Partners */}
      <div className="col-md-6">
        <AppCard title="B2B Configuration" className="h-100">
          <div className="row">
            {b2bConfig.map((item, index) => (
              <div key={index} className="col-md-6">
                <KeyVal label={item.label} labelCol="col-6" contentCol="col-6">
                  : {item.value || 0}
                </KeyVal>
              </div>
            ))}
          </div>
        </AppCard>
      </div>

      <div className="col-md-3">
        {/* New column for Serviceable Partners */}
        <AppCard title="Serviceable Partners" className="h-100">
          <div className="d-flex flex-wrap">
            {(data?.serviceablePartners || []).map((partner, idx) => (
              <HighlightText
                key={idx}
                type="info"
                template={2}
                className="me-2"
              >
                {partner}
              </HighlightText>
            ))}
          </div>
        </AppCard>
      </div>

      {/* Features & Settings */}
      <div className="col-md-12">
        <AppCard title="Features & Settings" className="h-100">
          <div className="row">
            {features.map((feature, index) => (
              <div key={index} className="col-md-3">
                <KeyVal
                  label={feature.label}
                  labelCol="col-6"
                  contentCol="col-6"
                >
                  :{" "}
                  <HighlightText
                    type={feature.value ? "success" : "danger"}
                    template={2}
                  >
                    {feature.value ? "Yes" : "No"}
                  </HighlightText>
                </KeyVal>
              </div>
            ))}
          </div>
        </AppCard>
      </div>

      {/* Additional Components */}
      <div className="row g-3">
        <div className="col-5">
          <Keywords keywords={data.keywords} />
        </div>
        <div className="col-7">
          <Inventories inventories={data.inventory} />
        </div>
        {/* <div className="col-4">
          <Categories category={data.category} brand={data.brand} />
        </div> */}
      </div>
    </div>
  );
};

export default Basic;
