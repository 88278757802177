import { DealService, FranchiseService } from "@sk/services";
import {
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import produce from "immer";
import { merge } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";

const headers = [
  {
    label: "",
    template: (cb, tdata) => (
      <input
        type="checkbox"
        onChange={(ev) => (cb ? cb({ action: "checkbox", ev }) : null)}
        checked={tdata?.selectAll ? true : false}
        defaultValue={false}
        className="mt-1"
      />
    ),
    hasCustomTemplate: true,
    isCentered: true,
  },
  { label: "Sl No", width: "5%" },
  { label: "ID", width: "8%" },
  { label: "Name", width: "30%" },
  { label: "Pincode", width: "10%" },
  { label: "Town", width: "15%" },
  { label: "District", width: "15%" },
  { label: "State" },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const getData = async (params, dataCheckerFilter) => {
  const r = await FranchiseService.getFranchises(params);
  const d = Array.isArray(r.resp) ? r.resp : [];

  const fids = d.map((e) => e._id);
  if (fids.length > 0) {
    const cr = await DealService.getStoreComboDeals(
      merge(
        {},
        {
          page: 1,
          count: fids.length,
          filter: { franchiseId: { $in: fids } },
        },
        dataCheckerFilter || {}
      )
    );

    const crData = Array.isArray(cr.resp) ? cr.resp : [];
    d.forEach((e) => {
      e._disabled = crData.findIndex((x) => x.franchiseId == e._id) != -1;
    });
  }

  return { data: d };
};

const getCount = async (params) => {
  const p = { ...params };
  delete p.page;
  delete p.count;
  const r = await FranchiseService.getCount(p);
  return { count: r.resp || 0 };
};

const prepareFilterParams = (filter, pagination) => {
  const darkStore = FranchiseService.getDarkstoreFilter();
  let p = merge(
    {},
    {
      page: pagination.activePage,
      count: pagination.rowsPerPage,
      filter: {},
      select: "name,town,district,state,pincode",
      sort: "name",
    },
    darkStore
  );

  const s = (filter?.search || "").trim();

  if (s) {
    p.filter.$or = [
      {
        _id: { $regex: s, $options: "gi" },
      },
      {
        name: { $regex: s, $options: "gi" },
      },
    ];
  }

  return p;
};

const StoreCloneTable = ({ selectedIds, callback, dataCheckerFilter }) => {
  const [loading, setLoading] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [data, setData] = useState([]);

  const [theadTmplData, setTheadTmplData] = useState({ selectAll: false });

  const filterDataRef = useRef({ search: "" });

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const selectedIdsRef = useRef([]);

  const applyFilter = useCallback(async () => {
    loadList();

    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    selectedIdsRef.current = selectedIds || [];
  }, [selectedIds]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const len = data.filter((e) => !e._disabled).length;
    const checked = data.filter((e) => !e._disabled && e._checked).length;
    setTheadTmplData({ selectAll: len == checked && len > 0 ? true : false });
  }, [data]);

  const loadList = useCallback(async () => {
    // for list
    setLoading(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current),
      dataCheckerFilter
    );
    const d = (r.data || []).map((e) => {
      e._checked = selectedIdsRef.current.indexOf(e._id) != -1 ? true : false;
      return { ...e };
    });
    setData(d);
    setLoading(false);
  }, [dataCheckerFilter]);

  const onCheckboxChange = (e, index) => {
    e.stopPropagation();
    updateCheckedStatus(e.target.checked, index);
  };

  const onRowClick = (index) => {
    updateCheckedStatus(!data[index]._checked, index);
  };

  const updateCheckedStatus = (value, index) => {
    if (data[index]._disabled) {
      return;
    }
    const t = produce(data, (draft) => {
      draft[index]._checked = value;
    });

    handleSelectedRefData(value, t[index]._id);

    setData(t);

    callback({
      action: "selected",
      data: { selected: selectedIdsRef.current },
    });
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const theadCb = (e) => {
    if (e.action == "checkbox") {
      const v = e.ev.target.checked;

      setTheadTmplData({ selectAll: v });

      const t = produce(data, (draft) => {
        draft.forEach((e) => {
          if (!e._disabled) {
            e._checked = v;
          }
        });
      });

      t.filter((e) => !e._disabled).forEach((e) => {
        handleSelectedRefData(v, e._id);
      });

      setData(t);

      callback({
        action: "selected",
        data: { selected: selectedIdsRef.current },
      });
    }
  };

  const handleSelectedRefData = (val, id) => {
    const i = selectedIdsRef.current.indexOf(id);
    if (!val && i != -1) {
      selectedIdsRef.current.splice(i, 1);
    }
    if (val && i == -1) {
      selectedIdsRef.current.push(id);
    }
  };

  return (
    <>
      <table className="table table-sm table-bordered">
        <TableHeader
          data={headers}
          fwSize="sm"
          callback={theadCb}
          templateData={theadTmplData}
        />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {loading ? (
            <TableSkeletonLoader cols={headers.length} rows={10} height={40} />
          ) : null}

          {data.map((e, k) => (
            <tr
              key={e._id}
              className="fs-val-sm cursor-pointer"
              onClick={() => onRowClick(k)}
            >
              <td className="text-center">
                <input
                  type="checkbox"
                  className="mt-1"
                  checked={e._checked}
                  onChange={(ev) => onCheckboxChange(ev, k)}
                  disabled={e._disabled}
                  title={e._disabled ? "Already configured" : ""}
                />
              </td>
              <td>{paginationRef.current.startSlNo + k}</td>
              <td>{e._id}</td>
              <td>{e.name}</td>
              <td>{e.pincode}</td>
              <td>{e.town}</td>
              <td>{e.district}</td>
              <td>{e.state}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default StoreCloneTable;
