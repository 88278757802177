import { ProductService } from "@sk/services";
import { AutoCompleteInput, TextInput } from "@sk/uis";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const FilterForm = ({ callback }) => {
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      dealId: "",
      code: "",
      productName: [],
    },
  });
  const onSubmit = (data) => {
    callback({ formData: data });
  };
  const resetFormData = () => {
    reset();
    callback({ formData: "" });
  };

  const onProductSearch = useCallback(async (val, callback) => {
    let filter = {};

    if (val) {
      let v = "/" + val + "/";
      filter = { $or: [{ name: v }] };
    }

    let p = {
      page: 1,
      count: 10,
      select: "name,_id",
      filter: filter,
    };

    const r = await ProductService.getList(p);
    callback((r?.resp || []).map((x) => ({ label: x.name, value: x.name })));
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          <div className="col-3">
            <TextInput
              label="Search by Deal ID"
              isMandatory={false}
              placeholder="Enter Deal ID here..."
              register={register}
              name="dealId"
              type="text"
            />
          </div>
          <div className="col-3">
            <TextInput
              label="Search by Barcode"
              isMandatory={false}
              register={register}
              name="code"
              placeholder="Enter Code here..."
              type="text"
            />
          </div>

          <div className="col-3">
            <Controller
              control={control}
              name="productName"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteInput
                  label="Search by Product Name"
                  placeholder="Enter Product Name here..."
                  value={value}
                  onSearch={onProductSearch}
                  callback={onChange}
                  uid="assign-group"
                  isMultiple={false}
                />
              )}
            />
          </div>

          <div className="col-auto  align-self-center">
            <button className="btn  mt-1 btn-primary " type="submit">
              Apply
            </button>
          </div>
          <div className="col-auto align-self-center">
            <button
              className="btn mt-1 btn-outline-warning"
              type="button"
              onClick={resetFormData}
            >
              <i className="bi bi-arrow-counterclockwise"></i>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(FilterForm);
