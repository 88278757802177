import { CommonService } from "@sk/services";
import { Amount, DisplayUnit, InfoPopover, KeyVal, TableHeader } from "@sk/uis";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

const tableHeaders = [
  {
    label: "Sl No",
    width: "6%",
  },
  {
    label: "Deal Details",
    width: "25%",
  },
  {
    label: "Combo Qty",
  },
  {
    label: "Stock In Store",
  },
  {
    label: "Pricing Details",
    width: "22%",
  },
  {
    label: "Combo Discount(%)",
    width: "10%",
  },
  {
    label: "Final RSP",
    width: "10%",
  },
  {
    label: "Total Value",
    width: "10%",
  },
];

const summaryHeaders = [
  { label: "Total MRP", key: "totalMrp" },
  { label: "Total RSP", key: "totalRsp" },
  {
    label: "Combo Final Price",
    key: "comboFinalPrice",
  },
  {
    label: "Combo Discount",
    key: "comboDiscount",
  },
  { label: "StoreKing PNL", key: "skPnl" },
  { label: "Store PNL", key: "pnl" },
];

const StoreComboLinkedDealTable = ({ deals = [] }) => {
  const [summary, setSummary] = useState({
    totalMrp: 0,
    totalVal: 0,
  });

  useEffect(() => {
    // Calculate total MRP and total final value (finalRsp * qty)
    const { totalMrp, totalVal, totalPurchasePrice, totalSlc, totalRsp } =
      deals.reduce(
        (totals, deal) => {
          const { _mrp, _finalRsp, _purchasePrice, _slc, _qty, _rsp } = deal;
          totals.totalMrp += _mrp * _qty;
          totals.totalVal += _finalRsp * _qty;
          totals.totalPurchasePrice += (_purchasePrice || 0) * _qty;
          totals.totalSlc += (_slc || 0) * _qty;
          totals.totalRsp += (_rsp || 0) * _qty;
          return totals;
        },
        {
          totalMrp: 0,
          totalVal: 0,
          totalPurchasePrice: 0,
          totalSlc: 0,
          totalRsp: 0,
        }
      );

    const pnl = totalVal - totalPurchasePrice;

    const skPnl = totalVal - totalSlc;

    const discount = 100 - (totalVal / totalRsp) * 100;

    setSummary({
      totalMrp,
      totalVal,
      pnl,
      skPnl,
      totalRsp,
      discount,
    });
  }, [deals]);

  return (
    <>
      <table className="table table-bordered table-sm">
        <TableHeader noBg={true} noBorder={true} data={summaryHeaders} />
        <tbody className="fs-val-md">
          <tr>
            <td className="fw-semibold text-danger">
              <Amount value={summary.totalMrp} decimalPlace={2} />
            </td>
            <td className="fw-semibold text-info">
              <Amount value={summary.totalRsp} decimalPlace={2} />
            </td>
            <td className="fw-semibold text-success">
              <Amount value={summary.totalVal} decimalPlace={2} />
            </td>
            <td>{CommonService.roundedByDecimalPlace(summary.discount, 2)}%</td>
            <td
              className={classNames({
                "text-danger": summary.skPnl < 0,
                "text-success": summary.skPnl >= 0,
              })}
            >
              <Amount value={summary.skPnl} decimalPlace={2} />
            </td>
            <td
              className={classNames({
                "text-danger": summary.pnl < 0,
                "text-success": summary.pnl >= 0,
              })}
            >
              <Amount value={summary.pnl} decimalPlace={2} />
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table table-bordered">
        <TableHeader data={tableHeaders} />
        <tbody className="fs-val-md">
          {deals.map((e, k) => (
            <tr key={e._id}>
              <td>{k + 1}</td>
              <td>
                <div className="mb-2">{e.name}</div>
                <div className="fs-val-sm text-muted">
                  <KeyVal
                    label="Deal ID"
                    labelCol="col-6"
                    contentCol="col-6"
                    noBottomSpace={true}
                    fwSize="sm"
                  >
                    : {e._id}
                  </KeyVal>
                  <KeyVal
                    label="Product ID"
                    labelCol="col-6"
                    contentCol="col-6"
                    fwSize="sm"
                  >
                    : {e._pid}
                  </KeyVal>
                </div>
              </td>
              <td>
                <DisplayUnit
                  value={e._qty}
                  isLoose={e.sellInLooseQty}
                  noConv={true}
                />
              </td>
              <td>
                {e._hasPurchased ? (
                  <DisplayUnit value={e._stock} isLoose={e.sellInLooseQty} />
                ) : (
                  <div className="text-danger">Not Purchased</div>
                )}
              </td>
              <td>
                <KeyVal
                  label="MRP"
                  labelCol="col-7"
                  contentCol="col-5"
                  noBottomSpace={true}
                >
                  : <Amount value={e._mrp} decimalPlace={2} />
                </KeyVal>

                <KeyVal
                  label="RSP"
                  labelCol="col-7"
                  contentCol="col-5"
                  noBottomSpace={true}
                >
                  : <Amount value={e._rsp} decimalPlace={2} />
                </KeyVal>

                {e._hasPurchased ? (
                  <>
                    <KeyVal
                      label="SK Landing Price"
                      labelCol="col-7"
                      contentCol="col-5"
                      noBottomSpace={true}
                    >
                      : <Amount value={e._slc} decimalPlace={2} />
                    </KeyVal>
                    <KeyVal
                      label="Store Pur. Price"
                      labelCol="col-7"
                      contentCol="col-5 align-self-center"
                      noBottomSpace={true}
                    >
                      : <Amount value={e._purchasePrice} />
                      <span className="fs-val-xs align-middle -mt-1">
                        <InfoPopover content="Store Purchased from SK price" />
                      </span>
                    </KeyVal>
                  </>
                ) : null}
              </td>
              {/* <td>
                <Amount value={e._rsp} decimalPlace={2} />
                {e.sellInLooseQty ? (
                  <div className="fs-val-xs text-muted">per kg</div>
                ) : null}
              </td> */}
              <td>{CommonService.roundedByDecimalPlace(e._discount, 3)}%</td>
              <td>
                <Amount value={e._finalRsp} decimalPlace={2} />
                <span className="fs-val-xs align-middle -mt-1">
                  <InfoPopover content="Price is calculated on RSP based on Combo discount" />
                </span>
              </td>
              <td>
                <Amount value={e._totalRsp} decimalPlace={2} />
                <span className="fs-val-xs align-middle -mt-1">
                  <InfoPopover content="= Combo Qty x Final RSP" />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default StoreComboLinkedDealTable;
