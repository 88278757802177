import { useAttachAdditionalData } from "@sk/hooks";
import {
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
const tableHeader = [
  { label: "Sl.no", width: "10%", isCentered: true },
  { label: "Key", width: "10%" },
  { label: "Old Value", width: "15%" },
  { label: "New Value", width: "15%" },
  {
    label: "Created On",
    width: "15%",
  },
  {
    label: "Remarks",
    width: "20%",
  },
];

const defaultPaginationData = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const attachAdditionalDataConfig = [
  {
    key: "loggedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_user",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const KingSlabAuditLog = ({ auditLogs = [] }) => {
  const [auditLogList, setAuditLogList] = useState(() =>
    auditLogs.slice(0, 10)
  );

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const paginationRef = useRef({
    ...defaultPaginationData,
    totalRecords: auditLogs.length,
  });

  useEffect(() => {
    let tmp = [];
    setAdditionalData(auditLogList, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setAuditLogList([...attachAllData(auditLogList, tmp)]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      const d = auditLogs.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      );
      setAuditLogList(d);
      let tmp = [];
      setAdditionalData(d, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          setAuditLogList([...attachAllData(d, tmp)]);
        }
      });
    },

    [attachAllData, auditLogs, setAdditionalData]
  );

  return (
    <>
      <div className="fw-semibold fs-val-lg px-4 py-3">Audit Logs</div>
      <table className="table table-sm table-bordered table-sm">
        <TableHeader data={tableHeader} />
        {auditLogList.length == 0 && (
          <tbody>
            <tr>
              <td colSpan={tableHeader.length}>
                <NoDataFound>No Audit Log Found</NoDataFound>
              </td>
            </tr>
          </tbody>
        )}
        {auditLogList.length > 0 && (
          <tbody>
            {auditLogList.map((x, i) => (
              <tr key={x._id || i} className="fs-val-md">
                <td className=" text-center">
                  {paginationRef.current.startSlNo + i}
                </td>
                <td className=" text-uppercase">{x.paramName}</td>
                <td className=" text-break">{x.oldData}</td>
                <td className=" text-break">{x.newData}</td>
                <td className=" text-break">
                  <DateFormatter date={x.loggedAt} />
                  <div>
                    by {x._user?.name || x.loggedBy?.name || x.loggedBy}
                  </div>
                </td>
                <td className="text-break">{x.message}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={false}
      />
    </>
  );
};

export default KingSlabAuditLog;
