import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import { CategoryService, NavService } from "@sk/services";
import {
  AppCard,
  AuditLogTable,
  BusyLoader,
  DateFormatter,
  HighlightText,
  ImgPreviewModal,
  ImgRender,
  KeyVal,
  NoDataFound,
  PageInfo,
  Rbac,
  Spinner,
  Tabs,
} from "@sk/uis";
import { memo, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BasicDetails from "./components/BasicDetails";
import Images from "./components/Images";
import {
  LinkedBrands,
  LinkedDeals,
  LinkedProducts,
  LinkedVendors,
} from "./features/index.js";

const breadcrumbs = [
  {
    name: "Home",
    link: "/cms/category/list",
  },
  {
    name: "Category List",
    link: "/cms/category/list",
  },
  {
    name: "Category Details",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "category",
    api: "category",
    loadingKey: "categoryLoading",
    dataKey: "_category",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "parent",
    api: "category",
    loadingKey: "parentLoading",
    dataKey: "_parent",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "masterData.masterID",
    api: "brand",
    loadingKey: "categoryLoading",
    dataKey: "_brand",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "createdBy",
    api: "user",
    loadingKey: "categoryLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedByLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const tabs = [
  { key: "basic", tabName: "Basic Information" },
  { key: "deals", tabName: "Linked Deals" },
  { key: "products", tabName: "Linked Products" },
  { key: "brands", tabName: "Linked Brands" },
  // { key: "vendors", tabName: "Linked Vendors" },
  { key: "images", tabName: "Images" },
  { key: "audit", tabName: "Audit Logs" },
];

const rbac = {
  editCategory: ["EditCategory"],
};

const ViewCategory = () => {
  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  // To save Category details
  const [data, setData] = useState({});

  // Page Loader
  const [loading, setLoading] = useState(true);

  const router = useNavigate();

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [showImgPreviewModal, setShowImgPreviewModal] = useState(false);

  const [imgPreviewData, setImgPreviewData] = useState([]);

  useEffect(() => {
    const loadBrandDetails = async (id) => {
      setLoading(true);
      let m = await CategoryService.getCategory(id);
      const d = m?.resp?._id ? m.resp : {};
      if (d._id) {
        let tmp = [];
        setAdditionalData([d], attachAdditionalDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length == attachAdditionalDataConfig.length) {
            const a = [...attachAllData([d], tmp)][0];
            setData((v) => ({ ...v, ...a }));
          }
        });
        setData(d);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    if (query.id) {
      loadBrandDetails(query.id);
    } else {
      setData({});
      setLoading(false);
    }
  }, [attachAllData, query.id, setAdditionalData]);

  // Tab Callback
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  // on Click Edit Category
  const editCategory = () => {
    NavService.to(router, "/cms/category/manage", { id: query.id });
  };

  const viewCategory = (id) => {
    NavService.openInNewTab("/cms/category/view", { id: id });
  };

  const closeImgPreviewModal = () => {
    setShowImgPreviewModal(false);
  };

  const viewImgPreviewModal = (key) => {
    let d;
    if (key == "mainImages") {
      d = data.mainImages.map((e) => {
        return { id: e };
      });
    } else {
      d = data[key].map((e) => {
        return { id: e.image };
      });
    }
    setImgPreviewData(d);
    setShowImgPreviewModal(true);
  };

  return (
    <div className="bg-light">
      <PageInfo
        title="Category Details"
        breadcrumbs={breadcrumbs}
        navigate={router}
      />

      {/* No Data Found */}
      {!loading && !data?._id ? (
        <AppCard>
          <NoDataFound> No Data Found </NoDataFound>
        </AppCard>
      ) : null}

      {/* Details start here */}
      {data?._id ? (
        <div>
          {/* Main Card Details Starts */}
          <div>
            <AppCard>
              <div className="row border-bottom pb-2 ">
                <div className="col">
                  {/* Category Name */}
                  <div className="fs-val-normal text-primary mb-3 fw-semibold">
                    {data.name}
                  </div>

                  <div className="row">
                    {/* Details Sections  COL - 1  */}
                    <div className="col-3  border-end">
                      {/* Category ID */}
                      <KeyVal
                        label="Category ID :"
                        labelCol="col-7"
                        contentCol="col-5"
                        className="text-primary"
                      >
                        <span className=" fw-semibold">{data._id}</span>
                      </KeyVal>
                      {/* Classification */}
                      {/* <KeyVal
                        label="Classification :"
                        labelCol="col-7"
                        contentCol="col-5"
                        className="text-primary"
                      >
                        <span className=" fw-semibold">
                          {data?.classification || "NA"}
                        </span>
                      </KeyVal> */}

                      {/* Category Type */}
                      <KeyVal
                        label="Category Type :"
                        labelCol="col-6"
                        contentCol="col-6"
                        className="text-primary"
                      >
                        <span className=" fw-semibold">
                          {data?._categoryType}
                        </span>
                      </KeyVal>

                      {/* Status */}
                      <KeyVal
                        label="Status :"
                        labelCol="col-7"
                        contentCol="col-5"
                        className="text-primary"
                      >
                        <span className=" fw-semibold">
                          <HighlightText status={data?.status} />
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-4 ps-3 border-end">
                      {/* Category Sub Type */}
                      <KeyVal
                        label="Category Subtype :"
                        labelCol="col-6"
                        contentCol="col-6"
                        className="text-primary"
                      >
                        <span className=" fw-semibold">
                          {data?.categorySubtype || "NA"}
                        </span>
                      </KeyVal>

                      {/* Fulfillment Policy */}
                      <KeyVal
                        label="Fulfillment Policy:"
                        labelCol="col-6"
                        contentCol="col-6"
                        className="text-primary"
                      >
                        <span className=" fw-semibold">
                          {data?.fulfillmentPolicys?.length
                            ? (data?.fulfillmentPolicys || []).map((x, i) => (
                                <span key={x + String(i)} className="me-1">
                                  {x}
                                  {i == data?.fulfillmentPolicys?.length - 1
                                    ? ""
                                    : ","}
                                </span>
                              ))
                            : "NA"}
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-4 ps-3   ">
                      {/* Has Parent Category */}
                      <KeyVal
                        label="Has Parent Category :"
                        labelCol="col-7"
                        contentCol="col-5"
                        className="text-primary"
                      >
                        <span className="fw-semibold">
                          {data?.hasParentCategory ? "Yes" : "No"}
                        </span>
                      </KeyVal>

                      {/* parent Category hasParentCategory */}
                      {data?._parent?.name ? (
                        <KeyVal
                          label="Parent Category :"
                          labelCol="col-7"
                          contentCol="col-5"
                          className="text-primary"
                        >
                          <span className=" fw-semibold">
                            {data?._parent?.name ? (
                              <span
                                role="button"
                                tabIndex={-1}
                                onClick={() => {
                                  viewCategory(data?.parent);
                                }}
                              >
                                {data?._parent?.name}
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </KeyVal>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* Image Start Here */}
                {/* Icon Section */}
                <div className="col-auto me-5 text-center">
                  <div className="text-black-50 fs-val-sm  fw-semibold">
                    Icon
                  </div>
                  <div
                    className="border mt-1"
                    onClick={() => {
                      viewImgPreviewModal("images");
                    }}
                    role="button"
                    tabIndex={-1}
                  >
                    <ImgRender
                      assetId={data?.images?.[0]?.image}
                      width="80"
                      height="80"
                    />
                  </div>
                </div>

                {/* Image Section */}
                <div className="col-auto ms-auto text-center">
                  <div className="text-black-50 fs-val-sm  fw-semibold">
                    Image
                  </div>
                  <div
                    className="border mt-1"
                    onClick={() => {
                      viewImgPreviewModal("mainImages");
                    }}
                    role="button"
                    tabIndex={-1}
                  >
                    <ImgRender
                      assetId={data?.mainImages?.[0]}
                      width="80"
                      height="80"
                    />
                  </div>
                </div>
              </div>

              <div className="row align-items-center mt-3">
                <div className="col-auto me-4">
                  <KeyVal
                    label="Created By:"
                    labelCol="col-auto"
                    contentCol="col-auto"
                    className="fw-semibold"
                    noBottomSpace={true}
                  >
                    {data.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      <span className=" fw-semibold">
                        {data?._createdBy?.name || "NA"}
                      </span>
                    )}
                  </KeyVal>
                </div>

                <div className="col-auto pe-5 border-end">
                  <KeyVal
                    label="Created At:"
                    labelCol="col-auto"
                    contentCol="col-auto"
                    className="fw-semibold "
                    noBottomSpace={true}
                  >
                    <DateFormatter date={data.createdAt} />
                  </KeyVal>
                </div>

                <div className="col-auto ps-4 pe-3">
                  <KeyVal
                    label="Modified By:"
                    labelCol="col-auto"
                    contentCol="col-auto"
                    className="fw-semibold"
                    noBottomSpace={true}
                  >
                    {data.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      <span className=" fw-semibold">
                        {data?._modifiedBy?.name || "NA"}
                      </span>
                    )}
                  </KeyVal>
                </div>

                <div className="col-auto me-auto">
                  <KeyVal
                    label="Modified At:"
                    labelCol="col-auto"
                    contentCol="col-auto"
                    className="fw-semibold"
                    noBottomSpace={true}
                  >
                    <DateFormatter date={data.lastUpdated} />
                  </KeyVal>
                </div>

                <div className="col-auto">
                  <Rbac roles={rbac.editCategory}>
                    <button
                      className="btn btn-primary fs-val-sm"
                      onClick={editCategory}
                    >
                      Edit Category
                    </button>
                  </Rbac>
                </div>
              </div>
            </AppCard>
          </div>
          {/* Main card Details Ends */}

          {/* Tabs start */}
          <div className="card">
            <div className="card-body p-0">
              <Tabs data={tabs} callback={tabCb} activeTab={activeTab} />
              <div className="p-4">
                {activeTab.key === "basic" && <BasicDetails data={data} />}
                {activeTab.key === "brands" && (
                  <LinkedBrands categoryId={data._id} />
                )}
                {activeTab.key === "products" && (
                  <LinkedProducts categoryId={data._id} />
                )}
                {activeTab.key === "deals" && (
                  <LinkedDeals categoryId={data._id} />
                )}
                {activeTab.key === "vendors" && (
                  <LinkedVendors categoryId={data._id} />
                )}
                {activeTab.key === "images" && (
                  <Images
                    data={data}
                    viewImgPreviewModalCb={viewImgPreviewModal}
                  />
                )}

                {activeTab.key === "audit" && (
                  <AuditLogTable entityType="CATEGORY" entityId={data._id} />
                )}
              </div>
            </div>
          </div>

          {/* Tabs end */}
        </div>
      ) : null}

      <BusyLoader show={loading} />
      <ImgPreviewModal
        show={showImgPreviewModal}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewData}
      />
    </div>
  );
};

export default memo(ViewCategory);
