import { CategoryService } from "@sk/services";
import { AutoCompleteInput, SelectInput, TextInput } from "@sk/uis";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const statusOption = [
  { value: "", label: "All" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const FilterForm = ({ callback }) => {
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      hsn: "",
      hsndesc: "",
      linkedCategories: [],
    },
  });
  const onSubmit = (data) => {
    callback({ formData: data });
  };
  const resetFormData = () => {
    reset();
    callback({ formData: "" });
  };

  const onCategorySearch = useCallback(async (val, callback) => {
    let filter = {};

    if (val) {
      let v = "/" + val + "/";
      filter = { $or: [{ name: v }] };
    }

    let p = {
      page: 1,
      count: 10,
      select: "name,_id",
      filter: filter,
    };

    const r = await CategoryService.getList(p);
    callback((r?.resp || []).map((x) => ({ label: x.name, value: x._id })));
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="row">
        <div className="col-2">
          <TextInput
            label="Search by HSN"
            isMandatory={false}
            placeholder="Enter HSN here..."
            register={register}
            name="hsn"
            type="text"
          />
        </div>
        <div className="col-3">
          <TextInput
            label="Search by HSN Description"
            isMandatory={false}
            register={register}
            name="hsndesc"
            placeholder="Search by HSN Description"
            type="text"
          />
        </div>

        <div className="col-3">
          <Controller
            control={control}
            name="linkedCategories"
            render={({ field: { onChange, value } }) => (
              <AutoCompleteInput
                label="Search by Category"
                placeholder="Search By Category Name here.."
                value={value}
                onSearch={onCategorySearch}
                callback={onChange}
                uid="assign-group"
                isMultiple={false}
              />
            )}
          />
        </div>

        <div className="col-2">
          <SelectInput
            label="Status"
            register={register}
            name="status"
            isMandatory={false}
            options={statusOption}
          />
        </div>

        <div className="col-auto  align-self-center">
          <button className="btn btn-primary mt-1" type="submit">
            Apply
          </button>
        </div>
        <div className="col-auto align-self-center">
          <button
            className="btn  btn-outline-warning mt-1"
            type="button"
            onClick={resetFormData}
          >
            <i className="bi bi-arrow-counterclockwise"></i>
          </button>
        </div>
      </div>
    </form>
  );
};

export default memo(FilterForm);
