import { FranchiseService, SellerService } from "@sk/services";
import {
  AppCard,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { orderBy } from "lodash";
import { useEffect, useRef, useState } from "react";

const Inventories = ({ inventories = [] }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const paginationRef = useRef({
    ...defaultPagination,
    totalRecords: inventories.length,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const d = await getData(inventories, paginationRef.current);
      setData(d.inventories);
      setLoading(false);
    };
    fetchData();
  }, [inventories]);

  const loadList = async () => {
    setLoading(true);
    setData([]);
    const d = await getData(inventories, paginationRef.current);
    console.log("d", d);
    setData(d.inventories);
    paginationRef.current.totalRecords = d.totalRecords;
    setLoading(false);
  };

  const paginationCb = async (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  return (
    <AppCard title="Inventories" className="h-100">
      <div
        className="tbl-scroll-container customr-scrollbar thin-scrollbar mb-2"
        style={containerStyle}
      >
        <table className="table table-sm" style={tableStyle}>
          <TableHeader data={tableHeader} noBg={true} isSticky={true} />
          {!loading && data.length == 0 && (
            <tbody>
              <tr>
                <td colSpan={tableHeader.length}>
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            </tbody>
          )}

          {loading && (
            <tbody>
              <TableSkeletonLoader cols={tableHeader.length} rows={10} />
            </tbody>
          )}

          {data.length > 0 && (
            <tbody>
              {data.map((inventory, i) => (
                <tr key={inventory._id || i} className="fs-val-sm">
                  <td>{paginationRef.current.startSlNo + i}</td>
                  <td>{inventory.whId}</td>
                  <td>
                    {inventory._warehouse?.name ||
                      inventory._seller?.name ||
                      "--"}
                  </td>
                  <td>{inventory.availableStock}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={false}
      />
    </AppCard>
  );
};

const tableHeader = [
  { label: "#", width: "5%" },
  { label: "Inventory Id", width: "15%" },
  { label: "Name", width: "25%" },
  { label: "Available Stock" },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const containerStyle = {
  height: "calc(100vh - 300px)",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "100%",
};

const getData = async (inventories, pagination) => {
  const sortedData = orderBy(inventories, ["whId"], ["asc"]);
  const finalData = sortedData.slice(
    pagination.startSlNo - 1,
    pagination.endSlNo
  );

  const ids = finalData.map((x) => x.whId);
  if (ids.length > 0) {
    const whPatt = /^WMF/;
    const sellerPatt = /^SEL/;

    const whIds = ids.filter((x) => whPatt.test(x));
    const sellerIds = ids.filter((x) => sellerPatt.test(x));

    const whs = await FranchiseService.getList({
      page: 1,
      count: whIds.length,
      filter: {
        _id: { $in: whIds },
      },
      select: "name",
    });
    finalData.forEach((x) => {
      const wh = whs?.resp?.find((y) => y._id == x.whId);
      if (wh) {
        x._warehouse = wh;
      }
    });

    if (sellerIds.length > 0) {
      const sellers = await SellerService.getList({
        page: 1,
        count: sellerIds.length,
        filter: { _id: { $in: sellerIds } },
        select: "name",
      });
      finalData.forEach((x) => {
        const seller = sellers?.resp?.sellers?.find((y) => y._id == x.whId);
        if (seller) {
          x._seller = seller;
        }
      });
    }
  }

  return {
    inventories: finalData,
    totalRecords: sortedData.length,
  };
};

export default Inventories;
