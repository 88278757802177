import { TextareaInput } from "@sk/uis";
import { useFormContext } from "react-hook-form";

const Remarks = () => {
  const {
    register,
    getValues,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <div>
      <TextareaInput
        label="Remarks"
        error={errors?.remarks?.message}
        name="remarks"
        errors={errors}
        isMandatory={false}
        register={register}
        note="maximum 300 charter allowed"
        maxLength="300"
      />
    </div>
  );
};

export default Remarks;
