import { AppCard, NoDataFound, TableHeader } from "@sk/uis";
import React from "react";

const tableHeader = [
  { label: "#", width: "10%" },
  { label: "Name", width: "80%" },
];

const productDetailsTableHeader = [
  { label: "#", width: "3%" },
  { label: "Name", width: "20%" },
  { label: "Value" },
];

const MoreAboutProduct = ({ data }) => {
  return (
    <div>
      <div className="row">
        <div className="col-6">
          <AppCard noShadow={true}>
            <table className="table table-bordered my-3 table-sm">
              <TableHeader data={productDetailsTableHeader} />
              <tbody className="fs-val-sm">
                <tr>
                  <td>1</td>
                  <td>Key Features</td>
                  <td>{data?.keyFeatures || "N/A"}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Ingredients</td>
                  <td>{data?.ingredients || "N/A"}</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Manufacturer Details</td>
                  <td>{data?.manufacturerDetails || "N/A"}</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Marketed By</td>
                  <td>{data?.marketedBy || "N/A"}</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Expiry Date</td>
                  <td>{data?.expiryDate || "N/A"}</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Disclaimer</td>
                  <td>{data?.disclaimer || "N/A"}</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Allergen Information</td>
                  <td>{data?.allergenInformation || "N/A"}</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>How to Use</td>
                  <td>{data?.howToUse || "N/A"}</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Item Form</td>
                  <td>{data?.itemForm || "N/A"}</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Nutrition Information</td>
                  <td>{data?.nutritionInformation || "N/A"}</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Storage Instruction</td>
                  <td>{data?.storageInstruction || "N/A"}</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>Product Size</td>
                  <td>{data?.productSize || "N/A"}</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>Is Vegetarian</td>
                  <td>{data?.isVegetarian ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>Is Non-Vegetarian</td>
                  <td>{data?.isNonVegetarian ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>Is Organic</td>
                  <td>{data?.isOrganic ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </table>
          </AppCard>
        </div>
        <div className="col-6">
          <AppCard title="Keys" noShadow={true}>
            <table
              className="table table-bordered my-3 table-sm"
              style={{
                overflowY: "scroll",
                maxHeight: "30vh",
              }}
            >
              <TableHeader data={tableHeader} />
              <tbody className="fs-val-sm">
                {data?.keyFeatures?.length > 0 &&
                  data?.keyFeatures?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item}</td>
                    </tr>
                  ))}
                {!data?.keyFeatures?.length ? (
                  <tr>
                    <td colSpan={tableHeader?.length}>
                      <NoDataFound> No Data Found </NoDataFound>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </AppCard>
        </div>
      </div>
    </div>
  );
};

export default MoreAboutProduct;
