// import { useAttachAdditionalData } from "@sk/hooks";
import { OmsService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  DatePickerInput,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import debounce from "lodash/debounce";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { detailsView } from "../../../constantService";
const filterFormData = detailsView.tabs.linkedOrders.filterFormData;

// Default Table Header
const tableHeaders = detailsView.tabs.linkedOrders.tableHeaders;

// default  pagination

const pagination = detailsView.tabs.linkedOrders.pagination;

const getData = async (params) => {
  const r = await OmsService.getOrderList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.categoryLoading = true;
        return x;
      })
    : [];
};

const prepareFilterParams = (
  pagination = {},
  filter = {},
  additionalData = {}
) => {
  let p = {
    count: pagination.rowsPerPage,
    page: pagination.activePage,
    filter: { "subOrders.brand.id": additionalData.id },
    select: "name,orderAmount,franchise,status,date,subOrders",
  };

  const order = filter.order.trim();

  const orderRegex = { $regex: order, $options: "gi" };

  if (order) {
    p.filter["$or"] = [{ name: orderRegex }, { _id: orderRegex }];
  }

  if (filter?.createdAt?.length > 1) {
    let s = filter.createdAt[0];
    let e = filter.createdAt[1];
    p.filter.createdAt = {
      $gte: set(s, { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(e, {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  return p;
};

const LinkedOrders = ({ brandId }) => {
  // For Filter
  const { register, getValues, control } = useForm({
    defaultValues: filterFormData,
  });

  // Loading State when We are calling API
  const [loading, setLoading] = useState(true);

  // Loading State Form Total Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Data From The API
  const [list, setList] = useState([]);

  // To Show Advance Filer

  // Pagination Config
  const paginationConfigRef = useRef({
    ...detailsView.tabs.linkedOrders.pagination,
  });

  // filer Data Ref
  const filterDataRef = useRef({ ...filterFormData });

  // saving category id from data to idRef
  // const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (brandId) {
      applyFilter();
    } else {
      setLoading(false);
      setList([]);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, brandId]);

  const applyFilter = useCallback(() => {
    filterDataRef.current = { ...getValues() };

    // Pagination
    paginationConfigRef.current = { ...pagination };

    // To Get Count
    loadCount();

    // To loadList
    loadList();
  }, [loadCount, loadList, getValues]);

  // To Get the Count of All
  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    let p = getFilterParams();
    delete p.count;
    delete p.page;
    delete p.select;
    delete p.filter.dealsFor;

    let m = await OmsService.getOrderCount(p);

    // Setting total Count
    paginationConfigRef.current.totalRecords = m.resp ? m.resp : 0;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  // To get List of All Lined Deals
  const loadList = useCallback(async () => {
    setLoading(true);

    let p = getFilterParams();

    let d = await getData(p);
    setList(d);

    setLoading(false);
  }, [getFilterParams]);

  // Pagination Cb
  const paginationCb = useCallback(
    (d) => {
      paginationConfigRef.current.activePage = d.activePage;
      paginationConfigRef.current.startSlNo = d.startSlNo;
      paginationConfigRef.current.endSlNo = d.endSlNo;
      loadList();
    },
    [loadList]
  );

  // SearchCb for Order
  const searchCb = debounce(() => {
    applyFilter();
  }, 700);

  // const resetFromData = () => {
  //   // Filter For Data
  //   reset(filterFormData);
  //   applyFilter();
  // };

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationConfigRef.current,
      filterDataRef.current,
      {
        id: brandId,
      }
    );
  }, [brandId]);

  const onDateChange = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e);
        applyFilter();
      };
    },
    [applyFilter]
  );

  return (
    <>
      <div className="row">
        {/* Deal Name */}
        <div className="col-3">
          <TextInput
            type="text"
            name="order"
            label="Search for Order"
            register={register}
            callback={searchCb}
            placeholder="Search By  Order ID"
          />
        </div>

        <div className="col-auto">
          <label className="mb-1 fs-val-md"> Ordered On</label>

          <Controller
            name="createdAt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DatePickerInput
                placeholder="Select Date Range"
                value={value}
                inpChange={onDateChange(onChange)} // send value to hook form
                config={{ mode: "range" }}
              />
            )}
          />
        </div>

        {/* Action Button */}
        {/* <div className="col-auto ms-auto align-self-center">
          <button
            className="btn btn-sm btn-primary me-1 fs-val-md"
            onClick={applyFilter}
          >
            Apply
          </button>
          <button
            className="btn btn-sm btn-outline-warning fs-val-md"
            onClick={resetFromData}
          >
            Reset
          </button>
        </div> */}
      </div>
      <div>
        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationConfigRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>

        <table className="table table-bordered">
          <TableHeader data={tableHeaders} />
          <tbody>
            {/* When Page Loading */}

            {loading && (
              <TableSkeletonLoader
                rows={5}
                cols={tableHeaders.length}
                height={40}
              />
            )}

            {/*  When There is No Data */}
            {!loading && !list.length ? (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {/* When there is Data */}
            {!loading &&
              (list || []).map((x, index) => (
                <tr key={index + x._id} className="fs-val-md">
                  <td>{paginationConfigRef.current.startSlNo + index}</td>

                  {/* ID  */}
                  <td>{x?._id}</td>

                  {/* Date */}
                  <td>
                    <DateFormatter date={x.date} format="dd MMM yyyy hh:mm a" />
                  </td>

                  {/* Order Amount */}
                  <td className="text-center">
                    <Amount value={x?.orderAmount} />
                  </td>

                  {/* Franchise Name */}
                  <td>{x?.franchise.id}</td>

                  <td>{x?.franchise?.name}</td>

                  {/* Status */}
                  <td>
                    <HighlightText status={x?.status} isBadge={false} />
                  </td>
                  {/* Total Orders */}
                  <td>
                    {x?.subOrders?.length > 0 ? x?.subOrders[0].status : "NA"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfigRef.current}
        loadingTotalRecords={loadingTotalRecords}
        paginationCb={paginationCb}
      />
    </>
  );
};

export default memo(LinkedOrders);
