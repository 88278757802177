import { AuthService } from "@sk/services";
import {
  Alert,
  AppCard,
  DisplayWarehouseName,
  NoDataFound,
  SelectInput,
  TableHeader,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import * as yup from "yup";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const headers = [
  { label: "Sl.No" },
  { label: "Name" },
  { label: "Warehouse" },
  { label: "Actions" },
];

warehouseOptions.unshift({ label: "Select Warehouse", value: "" });

const validationSchema = yup.object({
  whClassification: yup.string().trim().required("Classification is required"),
  warehouse: yup.string().trim().required("Warehouse is Required"),
});

const WhClassificationGrouping = ({ classifications }) => {
  const { register, getValues, setValue, control } = useFormContext();
  const classificationsOptions = useMemo(
    () =>
      classifications.map((x) => ({
        label: x.name,
        value: x._id,
      })),
    [classifications]
  );

  const validate = async (values) => {
    const list = getValues("classClassifications") ?? [];

    let msg = "";
    try {
      await validationSchema.validate(values);
      const isExist = list.find(
        (x) => x.whId == values.warehouse && x.id == values.whClassification
      );

      if (isExist) {
        msg = "Warehouse Already exists in List";
      }
    } catch (error) {
      msg = error.message;
    }

    return {
      msg,
      isValid: !msg,
    };
  };

  const addClassClassifications = async () => {
    const list = getValues("classClassifications");
    const [warehouse, whClassification] = getValues([
      "warehouse",
      "whClassification",
    ]);
    const validation = await validate({
      warehouse,
      whClassification,
    });
    if (!validation.isValid) {
      Toaster.error(validation.msg);
      return;
    }
    list.push({
      name: classificationsOptions.find((x) => whClassification == x.value)
        .label,
      id: whClassification,
      whId: warehouse,
      isDefault: false,
    });
    setValue("classClassifications", list);
    setValue("whClassification", "");
    setValue("warehouse", "");
    Toaster.success("Added Successfully");
  };

  const defaultClassifications = useWatch({
    control,
    name: "defaultClassifications",
  });

  const onClassificationClick = (x) => {
    let obj = {};
    if (x.label !== defaultClassifications?.name) {
      obj = {
        name: x.label,
        id: x.value,
        isDefault: true,
        value: "",
      };
    }
    setValue("defaultClassifications", obj);
  };

  const deleteClassClassifications = async (i) => {
    const r = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to remove?",
    });

    if (r.isConfirmed) {
      const list = getValues("classClassifications");

      list.splice(i, 1);

      setValue("classClassifications", list);
    }
  };

  const classClassifications = useWatch({
    control,
    name: "classClassifications",
  });

  return (
    <AppCard noShadow={true}>
      <div className="row">
        <div className="col-12 pb-3 border-bottom">
          {classificationsOptions.length > 0 && (
            <>
              <div className="fs-val-md fw-semibold mb-1">
                Default Classification
              </div>
              {classificationsOptions.map((x, i) => (
                <div
                  role="button"
                  tabIndex={-1}
                  onClick={() => onClassificationClick(x)}
                  key={"block-" + i}
                  className={classNames(
                    " d-inline-block text-primary border border-blue py-1 px-2 me-2 fs-val-md",
                    {
                      "text-light bg-primary":
                        defaultClassifications?.name == x.label,
                    }
                  )}
                >
                  {x.label}
                </div>
              ))}
            </>
          )}
        </div>
        <div className="col-12">
          <div className="fs-val-md fw-semibold pt-2 mb-1">
            Warehouse Wise Classification Grouping
          </div>
        </div>
        <div className="col-5">
          <SelectInput
            name="whClassification"
            options={classificationsOptions}
            register={register}
          />
        </div>
        <div className="col-5">
          <SelectInput
            name="warehouse"
            options={warehouseOptions}
            register={register}
          />
        </div>
        <div className="col-2">
          <button
            className="btn btn-primary fs-val-md"
            type="button"
            onClick={addClassClassifications}
          >
            Add
          </button>
        </div>
        <div className="col-12 ">
          <table className="table table-bordered">
            <TableHeader data={headers} />
            <tbody>
              {classClassifications.length == 0 && (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound> No Classification Data </NoDataFound>
                  </td>
                </tr>
              )}
              {classClassifications.length > 0 &&
                classClassifications.map((x, i) => (
                  <tr key={"class" + i} className="fs-val-md">
                    <td>{i + 1}</td>
                    <td>{x.name}</td>
                    <td>
                      <DisplayWarehouseName whId={x.whId} />
                    </td>
                    <td>
                      <button
                        className="btn btn-sm text-danger p-1 shadow rounded"
                        onClick={() => deleteClassClassifications(i)}
                        type="button"
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </AppCard>
  );
};

export default WhClassificationGrouping;
