import { SelectInput, SwitchInput, TextInput } from "@sk/uis";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { manageView } from "../../../constantService";
import { AuthService } from "@sk/services";

const taxOptions = [
  { value: "0", label: "0" },
  { value: "5", label: "5" },
  { value: "12", label: "12" },
  { value: "18", label: "18" },
  { value: "28", label: "28" },
  { value: "33", label: "33" },
  { value: "40", label: "40" },
  { value: "64", label: "64" },
];

const rbac = {
  editHsnRoles: ["hsnAndTaxEditAccess"],
};

const isEditHsnEnabled = AuthService.isValidUserRole(rbac.editHsnRoles);

// const cessValuesOption = [
//   { value: "40", label: "40" },
//   { value: "64", label: "64" },
// ];

const ceesSelectOptions = [
  { label: "Percentage", value: "percentage" },
  { label: "value", value: "value" },
];

const Taxes = ({ mode }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const isTaxable = useWatch({
    control,
    name: "isTaxable",
  });

  return (
    <div className="row p-1">
      {/* MRP */}
      <div className="col-2">
        <TextInput
          type="number"
          label="MRP"
          error={errors?.mrp?.message}
          name="mrp"
          isMandatory={true}
          register={register}
          info={manageView.infoContext.mrp}
        />
      </div>

      {/* PTR by Brand */}
      <div className="col-2">
        <TextInput
          type="number"
          label="PTR by Brand"
          register={register}
          name="ptrByBrands"
          error={errors.ptrByBrands?.message}
          placeholder="Discount on Brand"
          isMandatory={false}
          info={manageView.infoContext.ptrByBrands}
        />
      </div>

      {/* Is Taxable */}
      <div className="col-auto">
        <Controller
          control={control}
          name="isTaxable"
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label="Is Taxable"
              isMandatory={false}
              value={value}
              callback={onChange}
              disabled={mode == "Edit" ? !isEditHsnEnabled : true}
            />
          )}
        />
      </div>

      {isTaxable && (
        <>
          {/* Tax */}
          <div className="col-2">
            <SelectInput
              label="GST(%)"
              register={register}
              name="tax"
              error={errors?.tax?.message}
              isMandatory={true}
              options={taxOptions}
              info={manageView.infoContext.gstTax}
              disabled={mode == "Edit" ? !isEditHsnEnabled : true}
            />
          </div>
          {/* Cess Value  Type */}
          <div className="col-2">
            <SelectInput
              type="number"
              label="Cess Value Type"
              error={errors?.additionalCessType?.message}
              name="additionalCessType"
              options={ceesSelectOptions}
              isMandatory={false}
              register={register}
              info={manageView.infoContext.additionalCessType}
              disabled={mode == "Edit" ? !isEditHsnEnabled : true}
            />
          </div>

          {/* Additional Cess */}
          <div className="col-2">
            <TextInput
              type="number"
              label="Additional Cess"
              error={errors?.additionalCessValue?.message}
              name="additionalCessValue"
              isMandatory={false}
              disabled={mode == "Edit" ? !isEditHsnEnabled : true}
              register={register}
              info={manageView.infoContext.additionalCessValue}
            />
          </div>
        </>
      )}

      <div className="col-3">
        <TextInput
          label="HSN Number"
          error={errors?.HSNNumber?.message}
          name="HSNNumber"
          register={register}
          info={manageView.infoContext.hsnNumber}
          disabled={mode == "Edit" ? !isEditHsnEnabled : false}
          isMandatory={true}
        />
      </div>
    </div>
  );
};

export default Taxes;
