import { useAttachAdditionalData } from "@sk/hooks";
import { CommissionService, CommonService, UtilityService } from "@sk/services";
import {
  Alert,
  AppliedFilterLabel,
  BusyLoader,
  PageInfo,
  PaginationSummary,
  SelectInput,
  SummaryCard,
  Rbac,
  TextInput,
  Toaster,
} from "@sk/uis";

import { produce } from "immer";

import merge from "lodash/merge";

import { memo, useCallback, useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import Table from "./components/Table";

import ManagePosSmartCoinsModal from "./modals/ManagePosSmartCoinsModal";
import ViewPosSmartCoinsModal from "./modals/ViewPosSmartCoinsModal";

import sortBy from "lodash/sortBy";
import debounce from "lodash/debounce";

import listView from "../constantService";

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];
const extraRewardOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

// To Get The Brand List Data
const getData = async (params) => {
  const r = await CommissionService.getPosRewardList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getClassificationList = async () => {
  const r = await UtilityService.getClassificationList({ select: "name" });
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.userLoading = true;
        return x;
      })
    : [];
};

// To Get Count
const getCount = async (params) => {
  try {
    const r = await CommissionService.getPosRewardListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  let d = filter || {};

  if (d.status) {
    p["filter"]["status"] = d.status;
  }
  if (d.refType) {
    p.filter["refType"] = d.refType;
  }
  if (d.extraReward) {
    p.filter["extraReward"] = d.extraReward == "yes" ? true : false;
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }

  if (d._id.trim()) {
    p.filter._id = { $regex: d._id, $options: "gi" };
  }
  return p;
};

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const rbac = {
  addButton: ["ADDSCCONFIG"],
};

const ListPosSmartCoins = () => {
  const [data, setData] = useState([]);

  // loading state for List data
  const [loadingData, setLoadingData] = useState(true);

  const [classificationOpt, setClassificationOpt] = useState([]);

  // loading State for total count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // To Show Manage Modal
  const [showManageModal, setShowManageModal] = useState(false);

  // Setting  Summary Card Data
  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  // For Applied Filter Label
  const [filterLabels, setFilterLabels] = useState([]);

  // To Attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // To Store Filter Data
  const filterDataRef = useRef({ ...listView.filterFromData });

  // To Store Pagination Data
  const paginationRef = useRef({ ...listView.pagination });

  //   Show View Modal
  const [showViewModal, setShowViewModal] = useState(false);

  // To Store ManageBrand Modal Data
  const manageModalDataRef = useRef({ id: "", mode: "add" });

  // To Store Sort Ref
  const sortRef = useRef({ ...defaultSortOpt });

  const viewModalDataRef = useRef({});

  // To Download Report
  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  // React Hook From For Handling Filter Form Data
  const { register, getValues } = useForm({
    defaultValues: listView.filterFromData,
  });

  // Navigation Object For Routing
  const router = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const applyFilter = useCallback(async () => {
    // Resetting pagination
    paginationRef.current = { ...listView.pagination };

    loadList();

    loadSummary();

    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);

    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList, loadSummary, prepareFilterLabels]);

  // Init
  const init = useCallback(async () => {
    applyFilter();
    // Adding Classification List
    let list = await getClassificationList();
    list = list.map((x) => ({ label: x.name, value: x.name, id: x._id }));
    list = sortBy(list, ["label"]);
    list.unshift({ label: "All", value: "" });
    setClassificationOpt(list);
  }, [applyFilter]);

  // Fetching List Data
  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    // To Attach Additional Data
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // Preparing Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      {
        classificationOpt,
      }
    );
  }, [classificationOpt]);

  const closeViewModal = useCallback(() => {
    viewModalDataRef.current = {};
    setShowViewModal(false);
  }, []);

  // Summary Count card
  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    // getting Filter Params
    const filterParams = getFilterParams();
    let promises = [];

    // Calling  Count API To Summary Card
    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter }); // preparing params for Count
      // Removing unnecessary
      delete params.count;
      delete params.page;
      delete params.select;

      // when type of summary Card is Product Not Link then only we are calling productNot Linked Api

      promises.push(getCount(params));
    });
    // Get Result of all Api and Setting count Value for Each Card
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // For Searching via Brand
  const filterCb = useCallback(() => {
    const values = getValues();
    filterDataRef.current = { ...values };
    applyFilter();
  }, [applyFilter, getValues]);

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.brand;

    if (!v.hideUnlinkedBrands) {
      delete v.hideUnlinkedBrands;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // To Close Manage Modal
  const closeManageModal = useCallback(
    (r) => {
      setShowManageModal(false);
      if (r.status == "submit") {
        applyFilter();
      }
    },
    [applyFilter]
  );

  // To Open Create Modal
  const openCreateModal = useCallback(() => {
    manageModalDataRef.current.mode = "add";
    manageModalDataRef.current.id = "";
    setShowManageModal(true);
  }, []);

  // To Open Edit Modal
  const openEditModal = useCallback((id) => {
    manageModalDataRef.current.mode = "edit";
    manageModalDataRef.current.id = id;
    setShowManageModal(true);
  }, []);

  //

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const onSearch = debounce(() => {
    filterCb();
  }, 700);

  const openViewPosSmartCoinsModal = useCallback((x) => {
    viewModalDataRef.current = x;
    setShowViewModal(true);
  }, []);

  const markAsActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Please confirm",
      text: `Do you want to mark Id :${item._id} as Active?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = { ...item };
      p.status = "Active";
      const r = await CommissionService.updatePosRewards(item._id, p);
      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "Active";
          }
        })
      );

      Toaster.success("Updated Successfully");
    }
  }, []);

  const markAsInActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Please confirm",
      text: `Do you want to mark Id :${item._id}  as In-Active?`,
      okText: "Yes",
      cancelText: "No",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = { ...item };
      p.status = "Inactive";
      const r = await CommissionService.updatePosRewards(item._id, p);

      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "Inactive";
          }
        })
      );
      Toaster.success("Updated Successfully");
    }
  }, []);

  return (
    <>
      {/* header with add Brand Button */}
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Smart Coins (SC) Config List - POS"
            breadcrumbs={listView.breadcrumb}
            navigate={router}
          />
        </div>
      </div>

      {/* Summary card */}
      <div className="row align-self-center mb-3">
        {summaryCard.map((e, k) => (
          <div className="col-3" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={2}
            />
          </div>
        ))}
      </div>

      {/* Filter Block  */}
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="row">
            <div className="col-3">
              <TextInput
                name="_id"
                register={register}
                callback={onSearch}
                label="Config ID"
                placeholder="Search by ID"
              />
            </div>

            <div className="col-3">
              <SelectInput
                name="refType"
                label="Classification"
                register={register}
                callback={filterCb}
                options={classificationOpt}
              />
            </div>
            <div className="col-3">
              <SelectInput
                name="status"
                label="Status"
                register={register}
                callback={filterCb}
                options={statusOptions}
              />
            </div>
            <div className="col-3">
              <SelectInput
                name="extraReward"
                label="Extra Rewards"
                register={register}
                callback={filterCb}
                options={extraRewardOptions}
              />
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="col-auto ms-auto align-self-center">
          {/* Download button is Not Required yet  */}

          {/* <button
            className="btn app-download-btn me-2"
            onClick={downloadReport}
          >
            Download Report
          </button> */}
          <Rbac roles={rbac.addButton}>
            <button
              className="btn btn-primary btn-sm fs-val-md me-1"
              onClick={openCreateModal}
            >
              Add Config
            </button>
          </Rbac>
        </div>
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* Table */}

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <Table
        data={data}
        sort={sortRef.current}
        openViewModal={openViewPosSmartCoinsModal}
        sortCb={sortCb}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        markAsActive={markAsActive}
        markAsInActive={markAsInActive}
        loadingTotalRecords={loadingTotalRecords}
        editCb={openEditModal}
      />

      {/* FORM */}
      <ManagePosSmartCoinsModal
        show={showManageModal}
        closeModal={closeManageModal}
        classificationOpt={classificationOpt}
        id={manageModalDataRef.current.id}
        mode={manageModalDataRef.current.mode}
      />

      <ViewPosSmartCoinsModal
        show={showViewModal}
        closeModal={closeViewModal}
        modalData={viewModalDataRef.current}
      />

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default memo(ListPosSmartCoins);
