import { NavService } from "@sk/services";
import React, { useState } from "react";

const CategoryMenuRender = ({ items }) => {
  const [open, setOpen] = useState({});

  const handleClick = (key, item) => {
    const newOpen = { ...open, [key]: !open[key] };
    if (!newOpen[key]) {
      closeSubItems(item, key, newOpen);
    }
    setOpen(newOpen);
  };

  const closeSubItems = (item, parentKey, openState) => {
    if (item.children) {
      item.children.forEach((subItem, j) => {
        const key = `${parentKey}-${j}`;
        openState[key] = false;
        closeSubItems(subItem, key, openState);
      });
    }
  };

  // navigate To View  Page
  const viewDetails = (i) => {
    NavService.openInNewTab("/cms/category/view", { id: i });
  };

  const renderSubItems = (subItems, parentKey) => {
    return (
      <ul>
        {subItems.map((subItem, j) => {
          const key = `${parentKey}-${j}`;
          return (
            <li key={key}>
              <div className="row mt-2">
                <div
                  className="col text-primary"
                  onClick={() => viewDetails(subItem._id)}
                  role="button"
                  tabIndex={-1}
                >
                  {subItem.name}
                </div>
                <div className="col-auto ms-auto">
                  {subItem?.childrensCount > 0 ? (
                    <span
                      className="badge bg-secondary pt-1"
                      onClick={() => handleClick(key, subItem)}
                      role="button"
                      tabIndex={-1}
                    >
                      {subItem?.childrensCount}
                    </span>
                  ) : null}
                </div>
              </div>
              {open[key] &&
                subItem.children &&
                renderSubItems(subItem.children, key)}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <ul>
      {items.map((item, i) => {
        const key = `item-${i}`;
        return (
          <li key={key}>
            <div className="row mt-2">
              <div
                className="col text-primary"
                onClick={() => viewDetails(item._id)}
                role="button"
                tabIndex={-1}
              >
                {item.name}
              </div>
              <div className="col-auto ms-auto">
                {item?.childrensCount > 0 ? (
                  <span
                    className="badge bg-secondary pt-1"
                    onClick={() => handleClick(key, item)}
                    role="button"
                    tabIndex={-1}
                  >
                    {item?.childrensCount}
                  </span>
                ) : null}
              </div>
            </div>
            {open[key] && item.children && renderSubItems(item?.children, key)}
          </li>
        );
      })}
    </ul>
  );
};

export default CategoryMenuRender;
