import { AppCard, NoDataFound } from "@sk/uis";

const ViewDealDescription = ({
  shortDescription,
  fullDescription,
  highlights,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <AppCard title="Short Description" className="h-100">
            {!shortDescription ? (
              <NoDataFound>No Short Description Found</NoDataFound>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: shortDescription,
                }}
              ></div>
            )}
          </AppCard>
        </div>
        <div className="col-6">
          <AppCard title="Highlights" className="h-100">
            <ul className="row list-unstyled border-0">
              {!highlights.length ? (
                <NoDataFound>No Highlights Found</NoDataFound>
              ) : (
                highlights.map((x, i) => (
                  <li className="col-6" key={i}>
                    <i className="bi bi-check-circle text-success me-1"></i>
                    {x}
                  </li>
                ))
              )}
            </ul>
          </AppCard>
        </div>
        <div className="col-12 mt-3">
          <AppCard title="Full Description">
            {!fullDescription ? (
              <NoDataFound>No Full Description Found</NoDataFound>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: fullDescription,
                }}
              ></div>
            )}
          </AppCard>
        </div>
      </div>
    </>
  );
};

export default ViewDealDescription;
