import {
  BooleanCheckboxInput,
  EntitySearchInput,
  SelectInput,
  SwitchInput,
  TextInput,
} from "@sk/uis";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const categoryTypeOptions = [
  { label: "Select Category type", value: "" },
  { label: "Asset", value: "Asset" },
  { label: "Branding", value: "Branding" },
  { value: "Electronics", label: "Electronics" },
  { label: "Operating on Discount", value: "Operating on Discount" },
  { label: "MOP", value: "MOP" },
  { label: "Service", value: "Service" },
];

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const categorySubTypeOptions = [
  { label: "Select Subtype", value: "" },
  { label: "Food", value: "Food" },
  { label: "Non Food", value: "Non Food" },
  { label: "No Shelf Life", value: "No Shelf Life" },
];

const fulfillmentPolicyOptions = [
  { value: "", label: "Select Fulfillment Policy" },
  { value: "Latest MRP", label: "Latest MRP", group: "latest" },
  { label: "FIFO", value: "FIFO", group: "output" },
  { label: "LILO", value: "LILO", group: "output" },
  { label: "Expiry First", value: "Expiry First", group: "expiry" },
  { label: "Expiry Last", value: "Expiry Last", group: "expiry" },
  { label: "Highest MRP", value: "Highest MRP", group: "MRP" },
  { label: "Lowest MRP", value: "Lowest MRP", group: "MRP" },
  { label: "Offer First", value: "Offer First", group: "offer" },
  { label: "Offer Last", value: "Offer Last", group: "offer" },
  { label: "Highest Cost", value: "Highest Cost", group: "cost" },
  { label: "Lowest Cost", value: "Lowest Cost", group: "cost" },
];

const BasicDetails = ({ title }) => {
  const {
    register,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleCheckBox = (chngFn, key) => async (val) => {
    chngFn(val);
    if (!val) {
      setValue("parent", []);
    }
    await trigger(key);
  };

  const onCategoryChange = (v) => {
    let features = [
      "displayDealMrp",
      "debitOnMrpChange",
      "stickyOrderPrice",
      "invoiceOnOrderAmount",
      "collectSerialNumber",
      "marginDealerPrice",
      "noReturnsAllowed",
      "refundOnMrpChange",
    ];
    if (v == "MOP") {
      // MOP
      features.forEach((e) => {
        setValue(e, false);
      });
      setValue("stickyOrderPrice", true);
      setValue("invoiceOnOrderAmount", true);
    } else if (v == "Operating on Discount") {
      // Operating on Discount
      features.forEach((e) => {
        setValue(e, false);
      });
      setValue("refundOnMrpChange", true);
      setValue("displayDealMrp", true);
      setValue("refundOnMrpChange", true);
      setValue("debitOnMrpChange", true);
    } else if (v == "Electronics") {
      // Electronics
      features.forEach((e) => {
        setValue(e, false);
      });
      setValue("marginDealerPrice", true);
      setValue("displayDealMrp", true);
      setValue("invoiceOnOrderAmount", true);
      setValue("collectSerialNumber", true);
    } else {
      // Services
      features.forEach((e) => {
        setValue(e, false);
      });
    }
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-3 mb-3">
          {/* Add Category Form */}
          <TextInput
            type="text"
            label="Category Name"
            register={register}
            name="name"
            error={errors.name?.message}
            placeholder="Category Name"
            isMandatory={true}
          />
        </div>

        {/* Auto Compelete  Parent Category */}
        <div className="col-3 mb-3">
          <Controller
            name="hasParentCategory"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                label="Has Parent Category"
                name="hasParentCategory"
                error={errors?.hasParentCategory?.message}
                isMandatory={false}
                callback={handleCheckBox(onChange, "hasParentCategory")}
                value={value}
                gap={0}
              />
            )}
          />
          <div>
            <Controller
              control={control}
              name="parent"
              key="parent"
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type="category"
                  placeholder="Search for Parent Category Name"
                  name="parent"
                  value={value}
                  isMandatory={true}
                  callback={onChange}
                  uid="category"
                  emptyLabel="No Category Found"
                  disabled={!getValues("hasParentCategory")}
                  error={errors?.parent?.message}
                />
              )}
            />
          </div>
        </div>

        {/* Category Type */}
        <div className="col-3 mb-3">
          <SelectInput
            label="Category Type"
            register={register}
            placeholder="Select Category Type"
            name="categoryType"
            error={errors.categoryType?.message}
            isMandatory={true}
            options={categoryTypeOptions}
            callback={onCategoryChange}
          />
        </div>
        {/* Category Subtype */}
        <div className="col-3 mb-3">
          <SelectInput
            label="Category Subtype"
            register={register}
            name="categorySubtype"
            error={errors.categorySubtype?.message}
            isMandatory={false}
            options={categorySubTypeOptions}
          />
        </div>

        {/* Fulfillment Policy */}
        <div className="col-3 mb-3">
          <SelectInput
            label="Fulfillment Policy"
            register={register}
            name="fulfillmentPolicyData"
            error={errors.fulfillmentPolicyData?.message}
            isMandatory={true}
            options={fulfillmentPolicyOptions}
          />
        </div>

        {/* Status */}
        <div className="col-2 mb-3">
          <SelectInput
            label="Status"
            register={register}
            name="status"
            isMandatory={false}
            options={statusOptions}
          />
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="isFmcgClassGroup"
            render={({ field: { onChange, value } }) => (
              <SwitchInput
                label="Is FMCG Classgroup"
                isMandatory={false}
                value={value}
                callback={onChange}
              />
            )}
          />
        </div>
      </div>
    </>
    // ENDING
  );
};

export default memo(BasicDetails);
