import { TableHeader } from "@sk/uis";
import { memo, useCallback } from "react";
import Modal from "react-bootstrap/Modal";

const style = {
  modalHeaderStyle: {
    backgroundColor: "#e4edff",
  },
};

const defaultHeaders = [
  { label: "Sl.No.", width: "5%", priority: 1 },
  { label: "IMEI No", priority: 2 },
];

const ViewImeiNoModal = ({ show, data, closeModal }) => {
  const triggerCloseModal = useCallback(
    (status) => {
      closeModal({ status });
    },
    [closeModal]
  );

  return (
    <Modal
      backdrop="static"
      size="sm"
      show={show}
      onHide={triggerCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={style.modalHeaderStyle}
      >
        <Modal.Title className="fs-val-lg text-dark px-2">
          View IMEI Numbers
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered bg-white ">
          <TableHeader data={defaultHeaders} />
          <tbody>
            {data?.serialNo?.length &&
              data.serialNo.map((e, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{e}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ViewImeiNoModal);
