/* eslint-disable jsx-a11y/label-has-associated-control */
import { AppTitle, DatePickerInput, SelectInput } from "@sk/uis";
import { memo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";

const statusOption = [
  { label: "Any", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const groupOption = [
  { label: "ALL", value: "" },
  { label: "Both", value: "both" },
  { label: "COCO", value: "COCO" },
  { label: "FOFO", value: "FOFO" },
];

const isFixedPriceOption = [
  { label: "ALL", value: "" },
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const defaultFormValues = {
  status: "Active",
  groupType: "",
  createdAt: [],
};

const AdvanceFilterModal = ({ show, callback, formData = {} }) => {
  const { register, control, setValue, getValues, reset } = useForm({
    defaultValues: defaultFormValues,
  });

  const showOfferDate = useWatch({ control, name: "hasOffer" });

  useEffect(() => {
    // for autofilling the previous filtered values when the modal is openened
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset();
      }
    }
  }, [formData, setValue, show, reset]);

  const onReset = () => {
    reset(defaultFormValues);
    callback({ status: "reset", formData: getValues() });
  };

  const onApply = () => {
    callback({ status: "applied", formData: getValues() });
  };

  const onHide = () => {
    callback({ status: "closed" });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <AppTitle title="Advance Filter" className="text-white" />
      </Modal.Header>
      <Modal.Body>
        {show ? (
          <>
            <div className="row">
              <div className="col-6">
                <SelectInput
                  label="Group Type"
                  register={register}
                  name="groupType"
                  isMandatory={false}
                  options={groupOption}
                />
              </div>

              <div className="col-6">
                <SelectInput
                  label="Status"
                  register={register}
                  name="status"
                  isMandatory={false}
                  options={statusOption}
                />
              </div>

              <div className="col-6">
                <SelectInput
                  label="Is Fixed Price"
                  register={register}
                  name="isFixedPrice"
                  isMandatory={false}
                  options={isFixedPriceOption}
                />
              </div>

              <div className="col-12 mb-2">
                <label className="mb-1   fs-val-md"> Created on</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Select Date Range"
                      value={value}
                      inpChange={onChange} // send value to hook form
                      config={{ mode: "range" }}
                    />
                  )}
                />
              </div>
            </div>
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={onReset}>
          Reset
        </button>
        <button className="btn btn-primary" onClick={onApply}>
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(AdvanceFilterModal);
