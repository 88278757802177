import {
  AppCard,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
} from "@sk/uis";
import { useState } from "react";

const Keywords = ({ keywords }) => {
  const [pagination, setPagination] = useState({
    ...defaultPaginationConfig,
    totalRecords: keywords.length,
  });

  const handlePagination = (page) => {
    setPagination({
      ...pagination,
      activePage: page.activePage,
      startSlNo: page.startSlNo,
      endSlNo: page.endSlNo,
    });
  };

  return (
    <AppCard title="Keywords" className="h-100">
      <PaginationSummary
        paginationConfig={pagination}
        fwSize="sm"
        className="mb-1"
      />
      <table className="table table-sm">
        <TableHeader data={keywordHeaders} noBg />
        <tbody className="fs-val-sm">
          {!keywords ? (
            <tr>
              <td colSpan={keywordHeaders.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {keywords
            .slice(pagination.startSlNo - 1, pagination.endSlNo)
            .map((x, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{x}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="mt-3">
        <PaginationBlock
          paginationConfig={pagination}
          paginationCb={handlePagination}
          showSummary={false}
          size="sm"
        />
      </div>
    </AppCard>
  );
};

const keywordHeaders = [
  { label: "#", key: "sno", width: "5%" },
  { label: "Keywords", key: "keywords" },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

export default Keywords;
