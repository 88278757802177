import { NavService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  DisplayWarehouseName,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  BtnLink,
} from "@sk/uis";
import classNames from "classnames";
import { useEffect, useState } from "react";

import { memo } from "react";

const headers = [
  { label: "Sl.No.", width: "3%" },
  { label: "PO ID", width: "8%" },
  {
    label: "Vendor",
    width: "20%",
    enableSort: true,
    key: "contact.vendorName",
  },
  { label: "Vendor ID", width: "8%" },
  { label: "Warehouse", width: "15%" },
  { label: "Value", width: "8%" },
  { label: "Status", width: "10%" },
  { label: "Created On", width: "10%", enableSort: true, key: "createdAt" },
  { label: "Expected on", width: "10%", enableSort: true, key: "expectedAt" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const viewVendorDetails = (vendorId) => {
    NavService.openInNewTab("/vendor/view", {
      id: vendorId,
    });
  };

  return (
    <>
      <div className="tbl-scroll-container custom-scrollbar thin-scrollbar">
        <table
          className="table table-sm mb-0 bg-white table-hover"
          style={{ minWidth: "100%" }}
        >
          <TableHeader
            data={headers}
            sort={sort}
            sortCb={sortCb}
            isSticky={true}
          />
          <tbody>
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}
            {!loading && !localData.length ? (
              <tr>
                <td colSpan={headers.length} className="p-1">
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : (
              localData.map((x, i) => (
                <tr
                  key={x._id}
                  className={classNames("fs-val-sm", {
                    "danger-row":
                      x._isDelayed || x.status === "Partially Inwarded",
                  })}
                >
                  <td>{paginationConfig.startSlNo + i}</td>
                  <td>{x._id}</td>
                  <td>
                    <BtnLink
                      className="fs-val-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        viewVendorDetails(x.contact.vendorId);
                      }}
                    >
                      {x.contact.vendorName}
                    </BtnLink>
                  </td>
                  <td>{x.contact.vendorId}</td>
                  <td>
                    <DisplayWarehouseName whId={x.whId} />
                  </td>
                  <td>
                    <Amount value={x.poValue} />
                  </td>
                  <td>
                    <HighlightText type={x._statusColor} template={2}>
                      {x.status}
                    </HighlightText>
                  </td>
                  <td>
                    <DateFormatter date={x.createdAt} />
                    <div>
                      {x.userLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        <span className="text-muted fs-val-sm">
                          by {x._createdBy.name || x.createdBy}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <DateFormatter date={x.expectedAt} format="dd MMM yyyy" />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default memo(Table);
