import { FranchiseService } from "@sk/services";
import { AppCard, EntitySearchInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const defaultFormData = {
  search: "",
  store: [],
  status: "",
};

const statusOptions = [
  { value: "", label: "All" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

const storeFilter = FranchiseService.getDarkstoreFilter();

function FixedComboFilter({ callback }) {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const onSearch = useCallback(
    debounce(() => {
      triggerCb();
    }, 800),
    []
  );

  const onStoreChange = (e) => {
    setValue("store", e);
    triggerCb();
  };

  const onStatusChange = (e) => {
    setValue("status", e);
    triggerCb();
  };

  const triggerCb = () => {
    callback({ action: "apply", formData: getValues() });
  };

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-3">
            <TextInput
              label="Search by ID"
              placeholder="Search by ID..."
              register={register}
              name="search"
              callback={onSearch}
            />
          </div>
          <div className="col-3">
            <Controller
              name="store"
              control={control}
              render={({ field: { value } }) => (
                <EntitySearchInput
                  callback={onStoreChange}
                  value={value}
                  label="Search by Store"
                  type="franchise"
                  uid="sr-store"
                  filterParams={storeFilter}
                />
              )}
            />
          </div>
          <div className="col-3">
            <SelectInput
              label="Status"
              options={statusOptions}
              callback={onStatusChange}
              register={register}
              name="status"
            />
          </div>
        </div>
      </AppCard>
    </>
  );
}

export default FixedComboFilter;
