import totalImg from "../../../../assets/imgs/total.svg";
import activeImg from "../../../../assets/imgs/active.svg";
import inactiveImg from "../../../../assets/imgs/inactive.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Kingslab-POS List",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      key: "overall",
      loading: true,
      filter: {},
      filterParams: {},
      defaultFilterParams: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active Count",
      value: 0,
      key: "active",
      loading: true,
      filter: { isActive: true },
      filterParams: {},
      defaultFilterParams: { isActive: true },
      color: "success",
      img: activeImg,
    },
    {
      label: "Inactive Count",
      value: 0,
      loading: true,
      key: "inactive",
      filter: { isActive: false },
      filterParams: {},
      defaultFilterParams: { isActive: false },
      color: "danger",
      img: inactiveImg,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "dealId",
      api: "deal",
      loadingKey: "dealLoading",
      dataKey: "_deal",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "fids",
      api: "franchise",
      loadingKey: "franchiseLoading",
      dataKey: "_franchise",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },

    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    createdAt: {
      label: "Created At",
      type: "dateRange",
    },
    deal: {
      label: "Deal",
      valuePath: "[0]['label']",
    },
    franchise: {
      label: "Smart Store",
      valuePath: "[0]['label']",
    },
    status: {
      label: "Status",
    },
    groupType: {
      label: "Group Type",
    },
    isFixedPrice: {
      label: "Is Fixed Price",
    },
  },
  // Form Labels Ends Here

  filterFromData: {
    status: "Active",
    deal: [],
    groupType: "",
    createdAt: [],
    franchise: [],
  },
  // Filter From Data Ends Here

  //
};

export default listView;
