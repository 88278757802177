import { useAttachAdditionalData } from "@sk/hooks";
import {
  AuthService,
  CommonService,
  DealService,
  FranchiseService,
} from "@sk/services";
import {
  Amount,
  AppCard,
  AppTitle,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageLoader,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  Tabs,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import orderBy from "lodash/orderBy";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import RspConfigAuditLog from "../components/RspConfigAuditLog";
import Orders from "./Orders/Orders";

const tabs = [
  {
    tabName: "Price Details",
    key: "price",
  },
  // {
  //   tabName: "Orders",
  //   key: "orders",
  // },
  {
    tabName: "Audit Log",
    key: "audit",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_lastUpdatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const canViewSkLandingCost = AuthService.isValidUserRole(["ViewSkLandingCost"])
  ? true
  : false;

const tableHeaders = [{ label: "Sl.no" }, { label: "MRP" }, { label: "Stock" }];

const ViewRspConfigModal = ({ show, closeModal, modalData }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({});

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [mrpTable, setMrpTable] = useState({
    loading: false,
    data: [],
  });

  const [profit, setProfit] = useState({
    sk: 0,
    store: 0,
    rspPrice: 0,
    skProfitPerc: 0,
    storeProfitPerc: 0,
  });

  const [dealStockDetails, setDealStockDetails] = useState({
    loading: false,
    data: {},
  });

  useEffect(() => {
    if (show) {
      if (modalData._id) {
        setMrpTable({ loading: true, data: [] });
        const fetchDetails = async () => {
          setLoading(true);

          const r = await DealService.getCustomerCatalogPriceConfig({
            filter: { _id: modalData._id },
            slc: canViewSkLandingCost,
          });

          let d =
            r?.resp && Array.isArray(r?.resp) && r.resp.length > 0
              ? r?.resp[0]
              : {};

          // for sorting the auditlogs based on date
          if (d._id) {
            d.auditLog = orderBy(d.auditLog, ["loggedAt"], ["desc"]);
          }
          loadStockDetails(d);

          if (d?.isFixedPrice) {
            let rspPrice = d.fixedPrice;
            setProfit({
              sk: rspPrice - d.slc,
              store: rspPrice - d.b2bPrice,
              rspPrice: rspPrice,
              skProfitPerc: CommonService.roundedByDecimalPlace(
                ((rspPrice - d.slc) / d.slc) * 100,
                2
              ),
              storeProfitPerc: CommonService.roundedByDecimalPlace(
                ((rspPrice - d.b2bPrice) / d.b2bPrice) * 100,
                2
              ),
            });
          } else {
            const v = d.hasOffer ? d?.specialDiscount || 0 : d?.discount || 0;
            let mrp = d?.mrp;
            let rspPrice = mrp - mrp * (v / 100);
            setProfit({
              sk: rspPrice - d.slc,
              store: rspPrice - d.b2bPrice,
              rspPrice: rspPrice,
              skProfitPerc: CommonService.roundedByDecimalPlace(
                ((rspPrice - d.slc) / d.slc) * 100,
                2
              ),
              storeProfitPerc: CommonService.roundedByDecimalPlace(
                ((rspPrice - d.b2bPrice) / d.b2bPrice) * 100,
                2
              ),
            });
          }

          setDetails(d);
          setLoading(false);

          if (d._id) {
            let tmp = [];
            // Round discount to 3 decimal
            d.discount = CommonService.roundedByDecimalPlace(d.discount, 3);

            if (d.specialDiscount) {
              d.specialDiscount = CommonService.roundedByDecimalPlace(
                d.specialDiscount,
                3
              );
            }

            // Attach User Info
            setAdditionalData([d], attachAdditionalDataConfig, (x) => {
              tmp.push(x);
              if (tmp.length == attachAdditionalDataConfig.length) {
                const t = [...attachAllData([d], tmp)][0];
                setDetails((v) => ({ ...v, ...t }));
              }
            });

            if (d?.franchiseInfo?.id && d?.deal?.id) {
              loadFranchiseData(d?.franchiseInfo?.id, d?.deal?.id);
              loadStockDetails(d.id, d?.franchiseInfo?.id);
            }
          } else {
            Toaster.error("Failed to fetch data, please try again");
            closeModal();
          }
        };
        fetchDetails();
      }
    }
  }, [
    modalData._id,
    show,
    closeModal,
    setAdditionalData,
    attachAllData,
    loadFranchiseData,
    loadStockDetails,
  ]);

  const loadStockDetails = useCallback(async (dealId, fid) => {
    setDealStockDetails({ loading: true, data: {} });
    let p = {
      franchiseId: fid,
      excludeOutOfStock: false,
      groupbycondName: "deal",
      groupbycond: "deal",
      filter: { _id: fid },
      dealFilter: { _id: { $in: [dealId] } },
      slc: canViewSkLandingCost,
    };
    let r = await FranchiseService.getFranchiseInventorySummary(p);
    let d = Array.isArray(r.resp) && r.resp?.length ? r.resp[0] : {};
    setDealStockDetails({ loading: false, data: d });
  }, []);

  const loadFranchiseData = useCallback(
    async (id, dealId) => {
      const r = await FranchiseService.getFranchise(id);

      if (r?.resp?._id && r?.resp?.sk_franchise_details?.linkedSellerId) {
        loadMrpTableData(r.resp.sk_franchise_details?.linkedSellerId, dealId);
      } else {
        setMrpTable({ loading: false, data: [] });
      }
    },
    [loadMrpTableData]
  );

  const loadMrpTableData = useCallback(
    async (id, dealId) => {
      let params = {
        page: 1,
        count: 100,
      };
      const r = await DealService.getSellerDealStockDetails(id, dealId, params);
      let d = (Array.isArray(r.resp) ? r.resp : []).filter((e) => {
        return e?.location?.name != "Non-Sellable";
      });
      if (d.length) {
        let res = Object.values(
          d.reduce((acc, item) => {
            if (!acc[item.mrp]) {
              acc[item.mrp] = { mrp: item.mrp, quantity: 0 };
            }
            let q =
              item.uom.length && item.uom[0] == "gm"
                ? item.quantity / 1000
                : item.quantity;
            let u = item.uom.length
              ? item.uom[0] == "gm"
                ? "kg"
                : item.uom[0]
              : "Units";

            acc[item.mrp].quantity += q;
            acc[item.mrp].unit = u;
            return acc;
          }, {})
        );

        setMrpTable({
          loading: false,
          data: res,
        });
      } else {
        updateDealMrp(dealId);
      }
    },
    [updateDealMrp]
  );

  const updateDealMrp = useCallback(async (id) => {
    const r = await DealService.getDeals({ filter: { _id: id } });
    if (Array.isArray(r.resp) && r.resp[0]?._id) {
      let f = r.resp[0];
      setMrpTable({
        loading: false,
        data: [
          {
            mrp: f.mrp || 0,
            quantity: 0,
            unit: f?.sellInLooseQty ? "gm" : "Units",
          },
        ],
      });
    }
  }, []);

  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={{ width: "65%" }}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <AppTitle
            title={" Retailer Selling Price" + " - " + modalData?._id}
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  <div className="px-4 py-3">
                    <AppCard title="Basic Details">
                      <div className="row ">
                        <div className="col-3 mb-4">
                          <KeyVal label="Config Type" template="col">
                            <span className="badge badge-soft-primary text-uppercase fw-bold">
                              {details.configOnType}
                            </span>
                          </KeyVal>
                        </div>

                        <div className="col-3 mb-4">
                          <KeyVal
                            label={details.configOnType + " Name"}
                            template="col"
                          >
                            {
                              details?.[details?.configOnType?.toLowerCase()]
                                ?.name
                            }
                          </KeyVal>
                        </div>

                        <div className="col-3 mb-4">
                          <KeyVal
                            label={details.configOnType + " ID"}
                            template="col"
                          >
                            {details?.id}
                          </KeyVal>
                        </div>

                        <div className="col-3 mb-4">
                          <KeyVal label="Status" template="col">
                            <HighlightText
                              status={details.status}
                              isBadge={true}
                            />
                          </KeyVal>
                        </div>

                        <div className="col-3 mb-4">
                          <KeyVal label="Is Fixed Price" template="col">
                            <HighlightText
                              status={details.isFixedPrice ? "Yes" : "No"}
                            />
                          </KeyVal>
                        </div>

                        {details.franchiseInfo?.id ? (
                          <div className="col-9 mb-4">
                            <KeyVal label="Franchise Name" template="col">
                              <span>{details?.franchiseInfo?.name} </span>
                              <span>- {details.franchiseInfo.id} </span>
                            </KeyVal>
                          </div>
                        ) : null}
                      </div>
                    </AppCard>
                  </div>

                  <Tabs data={tabs} callback={tabCb} activeTab={activeTab} />

                  {activeTab.key == "price" && (
                    <>
                      <div className=" px-4 py-3 bg-light g-0">
                        {/* Fixed Price and has offer details */}
                        <div className="row">
                          <div className="col-3 mb-4">
                            <KeyVal label="Is RSP Stationary" template="col">
                              <HighlightText
                                status={details.isFixedPrice ? "Yes" : "No"}
                              />
                            </KeyVal>
                          </div>

                          {!details.isFixedPrice ? (
                            <div className="col-3 mb-4">
                              <KeyVal label="Discount" template="col">
                                {details.discount}%
                              </KeyVal>
                            </div>
                          ) : null}

                          {details.isFixedPrice && (
                            <>
                              {/* <div className="col-3 mb-4">
                              <KeyVal label="MRP" template="col">
                                <div className="text-primary fw-semibold">
                                  <Amount value={details.fixedMrp} />
                                </div>
                              </KeyVal>
                            </div> */}

                              <div className="col-3 mb-4">
                                <KeyVal label="Price" template="col">
                                  <div className="text-primary fw-semibold">
                                    <Amount value={details.fixedPrice} />
                                  </div>
                                </KeyVal>
                              </div>
                            </>
                          )}

                          <div className="col-3 mb-4">
                            <KeyVal label="Has Offer" template="col">
                              <HighlightText
                                status={details.hasOffer ? "Yes" : "No"}
                              />
                            </KeyVal>
                          </div>
                          {details.hasOffer ? (
                            <>
                              <div className="col-3 mb-4">
                                <KeyVal
                                  label="Additional Discount"
                                  template="col"
                                >
                                  <span className="text-success fw-semibold">
                                    {details.specialDiscount}%
                                  </span>
                                </KeyVal>
                              </div>
                              <div className="col-3 mb-4">
                                <KeyVal label="Start Date" template="col">
                                  <div className="d-inline-block">
                                    <span>
                                      <DateFormatter
                                        date={details.validityPeriod?.startDate}
                                        format="dd MMM yyyy"
                                      />
                                      ,
                                    </span>
                                    <span className="mx-1">
                                      <DateFormatter
                                        date={details.validityPeriod?.startDate}
                                        format="hh:mm a"
                                      />
                                    </span>
                                  </div>
                                </KeyVal>
                              </div>
                              <div className="col-3 mb-4">
                                <KeyVal label="End Date" template="col">
                                  <div className="d-inline-block">
                                    <span>
                                      <DateFormatter
                                        date={details.validityPeriod?.endDate}
                                        format="dd MMM yyyy"
                                      />
                                      ,
                                    </span>
                                    <span className="mx-1">
                                      <DateFormatter
                                        date={details.validityPeriod?.endDate}
                                        format="hh:mm a"
                                      />
                                    </span>
                                  </div>
                                </KeyVal>
                              </div>
                            </>
                          ) : null}
                        </div>

                        <div className="row my-2">
                          <div className="col-3">
                            <KeyVal label="MRP" template="col">
                              <span className="text-primary">
                                <Amount
                                  value={details?.mrp || 0}
                                  decimalPlace={2}
                                />
                              </span>
                            </KeyVal>
                          </div>
                          <div className="col-3">
                            <KeyVal label="Store Landing" template="col">
                              <span className="text-primary">
                                <Amount
                                  value={
                                    details?.b2bPrice || details?.b2bprice || 0
                                  }
                                  decimalPlace={2}
                                />
                              </span>
                            </KeyVal>
                          </div>
                          <div className="col-3">
                            <KeyVal label="RSP Price" template="col">
                              <span
                                className={classNames({
                                  "fs-val-md": true,
                                })}
                              >
                                <Amount
                                  value={profit.rspPrice || 0}
                                  decimalPlace={2}
                                />
                              </span>
                            </KeyVal>
                          </div>
                          {canViewSkLandingCost ? (
                            <div className="col-3">
                              <KeyVal label="Sk Landing Cost" template="col">
                                <span className="text-primary">
                                  <Amount
                                    value={details?.slc || 0}
                                    decimalPlace={2}
                                  />
                                </span>
                              </KeyVal>
                            </div>
                          ) : null}
                          <div className="col-3 mt-3">
                            <KeyVal label="Stock" template="col">
                              {dealStockDetails.loading ? (
                                <Spinner />
                              ) : (
                                <span className="text-primary">
                                  {dealStockDetails?.data?._qty || 0}{" "}
                                  {dealStockDetails?.data?._displayUnitKey}
                                </span>
                              )}
                            </KeyVal>
                          </div>
                          <div className="col-3 mt-3">
                            <KeyVal label="Store Profit" template="col">
                              <span
                                className={classNames({
                                  "fs-val-md": true,
                                  "text-success": profit.store > 0,
                                  "text-danger": profit.store < 0,
                                  "text-primary": profit.store == 0,
                                })}
                              >
                                <Amount
                                  value={profit.store || 0}
                                  decimalPlace={2}
                                />
                                ({profit.storeProfitPerc || 0}%)
                              </span>
                            </KeyVal>
                          </div>
                          {canViewSkLandingCost ? (
                            <div className="col-3 mt-3">
                              <KeyVal label="Sk Profit" template="col">
                                <span
                                  className={classNames({
                                    "fs-val-md": true,
                                    "text-success": profit.sk > 0,
                                    "text-danger": profit.sk < 0,
                                    "text-primary": profit.sk == 0,
                                  })}
                                >
                                  <Amount
                                    value={profit.sk || 0}
                                    decimalPlace={2}
                                  />{" "}
                                  ({profit.skProfitPerc || 0}%)
                                </span>
                              </KeyVal>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="px-4 py-3">
                        <div className="mb-2 fw-semibold fs-val-lg">
                          Available MRP Table
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <table className="table table-bordered bg-white">
                              <TableHeader data={tableHeaders} />
                              <tbody>
                                {mrpTable.loading ? (
                                  <TableSkeletonLoader
                                    rows={3}
                                    cols={tableHeaders.length}
                                    height={40}
                                  />
                                ) : null}
                                {!mrpTable.loading && !mrpTable.data.length ? (
                                  <tr>
                                    <td colSpan={tableHeaders.length}>
                                      <NoDataFound>No Data Found</NoDataFound>
                                    </td>
                                  </tr>
                                ) : null}
                                {(mrpTable.data || []).map((x, index) => (
                                  <tr key={index}>
                                    <td>{1 + index}</td>
                                    <td>{x?.mrp}</td>
                                    <td>
                                      {x?.quantity || 0} {x?.unit}
                                      {!x?.unit && x?.quantity > 0
                                        ? "Units"
                                        : null}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {activeTab.key == "audit" && (
                    <>
                      <div className="row p-4 pb-3">
                        <div className="col-3 mb-4">
                          <KeyVal label="Created At" template="col">
                            <DateFormatter date={details.createdAt} />
                          </KeyVal>
                        </div>

                        <div className="col mb-4">
                          <KeyVal label="Created By" template="col">
                            {details._createdBy?.name ?? "N/A"}
                          </KeyVal>
                        </div>

                        <div className="col-3 mb-4">
                          <KeyVal label="Last Updated At" template="col">
                            <DateFormatter date={details.lastUpdated} />
                          </KeyVal>
                        </div>
                        <div className="col-3 mb-4">
                          <KeyVal label="Last Updated By" template="col">
                            {details._lastUpdatedBy?.name ?? "N/A"}
                          </KeyVal>
                        </div>
                      </div>
                      <RspConfigAuditLog auditLogs={details.auditLog} />
                    </>
                  )}

                  {activeTab.key == "orders" && (
                    <Orders dId={details?.deal?.id} />
                    // {activeTab.key == "priceLogs" && (
                    //   <PriceLogsList data={details} />
                  )}
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewRspConfigModal);
