import { DealService, NavService } from "@sk/services";
import {
  Amount,
  BtnLink,
  HighlightText,
  NoDataFound,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";

const LinkedDeals = ({ pId, tableSize = "md" }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const paginationRef = useRef({
    activePage: 1,
    rowsPerPage: 50,
  });
  const sortRef = useRef({});
  const filterDataRef = useRef({});

  useEffect(() => {
    if (pId) {
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      {
        id: pId,
      }
    );
  }, [pId]);

  const loadList = useCallback(async () => {
    const params = getFilterParams();
    setLoadingData(true);
    const d = await getData(params);
    setData(d);
    setLoadingData(false);
  }, [getFilterParams]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = {};

    loadList();

    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <PaginationSummary
        paginationConfig={paginationRef.current}
        loadingTotalRecords={loadingTotalRecords}
      />
      <div className="tbl-scroll-container custom-scrollbar thin-scrollbar">
        <table className="table table-bordered table-sm bg-white">
          <TableHeader
            data={headers}
            sort={sortRef.current}
            sortCb={sortCb}
            isSticky={true}
          />
          <tbody>
            {/* Table Loader */}
            {loadingData && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {/* No Data Found */}
            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length} className="p-1">
                  <NoDataFound>No Linked Deal Data Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {/* List */}
            {data.map((x, index) => (
              <tr
                key={x._id}
                className={tableSize === "md" ? "fs-val-md" : "fs-val-sm"}
              >
                <td>{index + 1}</td>
                <td>
                  <BtnLink
                    className={`${
                      tableSize === "md" ? "fs-val-md" : "fs-val-sm"
                    }`}
                    onClick={() => {
                      NavService.openInNewTab("/cms/deal/view", {
                        id: x._id,
                      });
                    }}
                  >
                    {x.name}
                  </BtnLink>
                </td>
                <td>{x._id}</td>
                <td className="text-center">
                  <Amount value={x.mrp} />
                </td>
                <td className="text-center">
                  <Amount value={x.b2bPrice} />
                </td>
                <td className="text-center">
                  {x.product &&
                    x.product.reduce((acc, x) => x.quantity + acc, 0)}
                </td>
                <td className="text-center">{x.product.length}</td>
                <td className="text-center">
                  <HighlightText
                    status={x.is_active ? "Yes" : "No"}
                    isBadge={true}
                  />
                </td>
                <td className="text-center">
                  <HighlightText status={x.status} isBadge={true} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const headers = [
  { label: "Sl.No.", width: "5%" },
  { label: "Deal Name", width: "25%" },
  { label: "Deal ID", width: "10%" },
  { label: "MRP", isCentered: true, key: "mrp", enableSort: true },
  { label: "B2B Price ", isCentered: true, key: "b2bPrice", enableSort: true },
  { label: "Qty Linked", isCentered: true },
  { label: "Products Linked", isCentered: true },
  { label: "Is Active", isCentered: true },
  { label: "Status", isCentered: true },
];

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await DealService.getDealsCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (
  pagination = {},
  filter = {},
  sort = {},
  additionalData = {}
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      product: { $elemMatch: { id: additionalData.id } },
    },
  };

  if (filter.status) {
    p.filter.status = filter.status;
  }
  if (filter.is_active) {
    p.filter.is_active = filter.is_active;
  }
  const deal = filter?.deal?.trim();
  const dealRegex = { $regex: deal, $options: "gi" };

  if (deal) {
    p.filter.$or = [{ _id: dealRegex }, { name: dealRegex }];
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? "-" + [sort.key] : [sort.key];
  }
  return p;
};

const getData = async (params) => {
  const r = await DealService.getDeals(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

export default memo(LinkedDeals);
