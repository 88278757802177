import { CommonService } from "@sk/services";
import {
  Alert,
  AppCard,
  NoDataFound,
  SelectInput,
  TableHeader,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { manageView } from "../../../constantService";

const tableHeader = [
  { label: "Sl.no", width: "auto" },
  { label: "Name" },
  { label: "Min" },
  { label: "Max" },
  { label: "Action", width: "15%" },
];

const tierOption = CommonService.getTierData().map((x) => ({
  label: x,
  value: x,
}));

const rfHeader = [{ label: "Sl.no" }, { label: "Key" }, { label: "Formula" }];

const rfTableData = [
  { key: "Min", value: "Tier Min * Inner Case Qty" },
  { key: "Per Day", value: "Max / 2" },
  { key: "Per Month (Max)", value: "TierMax * CaseQty" },
];

const sfTableData = [
  { key: "Min", value: "Tier Min * Inner Case Qty", remarks: [] },
  { key: "Per Day", value: "Max / 2", remarks: [] },
  {
    key: "Per Month (Max)",
    value: "TierMax * CaseQty * 5",
    remarks: [
      "Active Retailer count is taken as 5 if SF has More than 5 Retailers",
      "If SF has lower than 5, then available retailer count will be taken ",
    ],
  },
];

const tierConfigValidation = yup.object().shape({
  min: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(0, "Please Enter Valid min Value")
    .required("Min Value Is Required"),
  max: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(yup.ref("min"), "Max Value cannot be less or equal to Min Value")
    .required("Max Value Is Required"),
  tierName: yup.string().trim().required("tier name  is required"),
});
const TierConfig = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [tierList, setTierList] = useState(() => getValues("tierData"));
  const [edit, setEdit] = useState({ isEditing: false, index: -1 });

  // ------------ADD-------------------
  const submitTierData = async () => {
    const [tierName, max, min] = getValues(["tierName", "max", "min"]);

    const validation = await validate();
    if (!validation.isValid) {
      Toaster.error(validation.msg);
      return;
    }

    // Return Array of Values

    const tierDataInputObj = {
      min: min,
      max: max,
      name: tierName,
    };

    const list = getValues("tierData");

    if (edit.isEditing) {
      let newList = list.filter((item, index) => {
        if (index === edit.index) {
          return false;
        }
        return item.name == tierDataInputObj.name;
      });
      if (!newList.length) {
        list.splice(edit.index, 1, tierDataInputObj);
        setEdit({ isEditing: false, index: -1 });
      } else {
        Toaster.error("Item already exists in List");
      }
    } else {
      let isExist = list.find((item) => item.name === tierDataInputObj.name);
      if (!isExist) {
        list.push(tierDataInputObj);
      } else {
        Toaster.error("Item already exists in List ");
      }
    }
    setValue("tierData", list);
    setTierList(getValues("tierData"));
    resetFormData();
    Toaster.success(
      "Details" + !edit.isEditing ? "Added" : "Updated" + "successfully"
    );
  };

  // -------------DELETE----------------
  const deleteTierData = async (index) => {
    if (edit.isEditing) {
      Toaster.error("Cannot delete while editing...");
      return;
    }

    const r = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to remove?",
    });
    if (r.isConfirmed) {
      const list = getValues("tierData");
      list.splice(index, 1);
      setValue("tierData", list);
      setTierList(getValues("tierData"));
    }
  };

  // -----------EDIT----------------------
  const editTierData = (index) => {
    setEdit({ isEditing: true, index: index });
    const val = tierList.find((x, i) => i === index);
    Object.keys(val).forEach((key) => {
      key === "name" ? setValue("tierName", val[key]) : setValue(key, val[key]);
    });
  };

  const onCancel = () => {
    setEdit({ isEditing: false, index: -1 });
    resetFormData();
  };

  const validate = async () => {
    const [tierName, max, min] = getValues(["tierName", "max", "min"]);
    let errMsg = "";
    try {
      const e = await tierConfigValidation.validate({
        tierName,
        min,
        max,
      });
    } catch (error) {
      errMsg = error.message;
    }

    return {
      msg: errMsg,
      isValid: !errMsg,
    };
  };

  // --------------CRUD FOR TIER DATA------------------

  const applyAll = async () => {
    const list = getValues("tierData");

    const tierData = CommonService.getTierData();

    const [tierName, max, min] = getValues(["tierName", "max", "min"]);

    const validation = await validate();

    if (!validation.isValid) {
      Toaster.error(validation.msg);
      return;
    }

    if (!tierList.length) {
      tierData.forEach((x) => {
        list.push({
          name: x,
          min,
          max,
        });
      });
    } else {
      const isExists = list.some((x) => x.name == tierName);
      if (isExists) {
        Toaster.error("Item Already Exists");
        return;
      }
      tierData
        .filter((x) => !tierList.find((d) => d.name == x))
        .forEach((x) => {
          list.push({
            name: x,
            min,
            max,
          });
        });
    }
    setValue("tierData", list);
    setTierList(getValues("tierData"));
    resetFormData();
    Toaster.success("Tier config added successfully");
  };

  const resetFormData = () => {
    ["tierName", "min", "max"].forEach((item) => setValue(item, ""));
  };

  return (
    <>
      <div className=" border-bottom ">
        <div className="row ">
          <div className="col-2">
            <SelectInput
              label="Tier Name"
              register={register}
              name="tierName"
              isMandatory={true}
              options={tierOption}
              info={manageView.infoContext.tierName}
            />
          </div>
          <div className="col-1">
            <TextInput
              type="number"
              label="MIN"
              register={register}
              name="min"
              error={errors.min?.message}
              placeholder="Min"
              isMandatory={true}
            />
          </div>
          <div className="col-1">
            <TextInput
              type="number"
              label="MAX"
              register={register}
              name="max"
              error={errors.MAX?.message}
              placeholder="Max"
              isMandatory={true}
            />
          </div>
          <div className="col-auto align-self-center mt-2">
            <button
              type="button"
              onClick={submitTierData}
              className={classNames("fs-val-md btn", {
                "btn-primary": !edit.isEditing,
                "btn-success": edit.isEditing,
              })}
            >
              {!edit.isEditing ? "Add" : "Update"}
            </button>
            {edit.isEditing && (
              <button
                className="btn btn-outline-primary ms-1"
                onClick={onCancel}
              >
                cancel
              </button>
            )}
            {!edit.isEditing && (
              <button
                type="button"
                className="btn btn-outline-primary fs-val-md ms-2"
                onClick={applyAll}
              >
                {tierList.length ? "Apply for Remaining" : "Apply For All"}
              </button>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-7">
            <table className="table table-bordered my-3">
              <TableHeader data={tableHeader} />
              <tbody>
                {/* When No loading */}
                {!tierList.length ? (
                  <tr>
                    <td colSpan={tableHeader.length}>
                      <NoDataFound>No Data Found </NoDataFound>
                    </td>
                  </tr>
                ) : null}

                {tierList?.length > 0 &&
                  tierList.map((item, index) => (
                    <tr key={index} className="fs-val-md">
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.min}</td>
                      <td>{item.max}</td>
                      <td className>
                        <span
                          className="me-3 p-1 shadow rounded"
                          role="button"
                          tabIndex={0}
                          onClick={() => editTierData(index)}
                        >
                          <i className="bi bi-pencil-square text-primary cursor-pointer"></i>
                        </span>
                        <span
                          className="me-5 p-1 shadow rounded"
                          onClick={() => deleteTierData(index)}
                          role="button"
                          tabIndex={0}
                        >
                          <i className="bi bi-trash  text-danger cursor-pointer"></i>{" "}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="col-5 ms-auto">
            {/* RF Calculation */}
            <AppCard title="RF Calculation mt-3">
              <table className="table table-bordered">
                <TableHeader data={rfHeader} />
                <tbody>
                  {rfTableData.map((x, i) => (
                    <tr key={i} className="fs-val-md">
                      <td>{x.key}</td>
                      <td>{x.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AppCard>

            {/* SF Calculation */}
            <AppCard title="SF Calculation">
              <table className="table table-bordered">
                <TableHeader data={rfHeader} />
                <tbody>
                  {sfTableData.map((x, i) => (
                    <tr key={i} className="fs-val-md">
                      <td>{x.key}</td>
                      <td>{x.value}</td>
                      <td>
                        {x.remarks.length
                          ? x.remarks.map((r, i) => (
                              <div key={i}>
                                {i + 1}. {r}
                              </div>
                            ))
                          : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AppCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default TierConfig;
