import { appConfigs } from "@sk/services";
import { EntitySearchInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

// const searchTypeDropdownOptions = [
//   {
//     label: "Deal",
//     value: "deal",
//     inputLabel: "Search by Deal Name/ID ",
//   },
//   {
//     label: "Category",
//     value: "category",
//     inputLabel: "Search by Category Name/ID",
//   },
//   { label: "Brand", value: "brand", inputLabel: "Search by Brand Name/ID" },
// ];

const franchiseSearchFilter = {
  filter: {
    groupType: "COCO",
    "sk_franchise_details.franchise_sub_type": {
      $in: appConfigs.DARKSTORE_SUB_TYPES,
    },
  },
};

const FilterForm = ({ callback, openAdvFilterModal }) => {
  const { register, setValue, getValues, control } = useFormContext();

  // const [searchType, setSearchType] = useState(searchTypeDropdownOptions[0]);

  const onSearch = useCallback(
    debounce(() => {
      const formValues = getValues();
      if (formValues.franchise?.length) {
        setValue("groupType", "");
      }
      callback({ formData: getValues() });
    }, 700),
    [getValues, callback]
  );

  // const getSearchType = (x) => {
  //   setSearchType(x);
  //   ["brand", "category", "franchise", "deal"].forEach((x) => {
  //     setValue(x, "");
  //   });
  // };

  const onFranchiseSearch = useCallback(
    (chngFn) => async (val) => {
      chngFn(val);
      callback({ formData: getValues() });
    },
    [callback]
  );

  // const getSearchByGroupContent = () => {
  //   return (
  //     <Dropdown>
  //       <Dropdown.Toggle size="sm" variant="none">
  //         {searchType.label} <i className="bi bi-chevron-down"></i>
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu>
  //         {searchTypeDropdownOptions.map((x) => (
  //           <Dropdown.Item key={x.value} onClick={() => getSearchType(x)}>
  //             {x.label}
  //           </Dropdown.Item>
  //         ))}
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  return (
    <div className="row">
      <div className="col-4">
        <TextInput
          name="deal"
          register={register}
          callback={onSearch}
          placeholder="Search by Deal Name/ID"
          gap={0}
          // groupContent={getSearchByGroupContent()}
        />
      </div>
      <div className="col-4">
        <Controller
          control={control}
          name="franchise"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              type="franchise"
              placeholder="Search by Smart Store by Name/ID"
              value={value}
              callback={onFranchiseSearch(onChange)}
              uid="franchise"
              filterParams={franchiseSearchFilter}
              emptyLabel="No Smart Stores found"
              gap={0}
            />
          )}
        />
      </div>
      {/* Filter Button */}
      <div className="col-auto">
        <button
          className="btn app-filter-btn"
          type="button"
          onClick={openAdvFilterModal}
        >
          <i className="bi bi-funnel"></i> FILTER
        </button>
      </div>
    </div>
  );
};

export default memo(FilterForm, (prev, next) => {
  return prev.isDirty === next.isDirty;
});
