import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService, DealService, ProductService } from "@sk/services";
import {
  AutoCompleteInput,
  BusyLoader,
  SelectInput,
  TextInput,
  Toaster,
} from "@sk/uis";
import { get } from "lodash";
import { memo, useCallback, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

const uomOptions = [
  { label: "Choose", value: "" },
  { label: "kg", value: "kg" },
  { label: "gram", value: "gram" },
];

const validationSchema = yup.object({
  mrp: yup
    .number()
    .required()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .label("MRP")
    .max(9999999, "Max Digits should be 7")
    .min(yup.ref("price"), "MRP Should be greater than than Price")
    .required(),
  price: yup
    .number()
    .required()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .label("Price")
    .required()
    .max(yup.ref("mrp"), "Price Should be Less than MRP")
    .min(0.5),
  unit: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .label("Unit")
    .required()
    .min(0)
    .max(100000),
  uom: yup.string().label("Quantity").required(),
  deal: yup.array().min(1, "Deal is Required").required(),
  productId: yup.string(),
  dealId: yup.string(),
  productName: yup.string(),
  whId: yup.string(),
  warehouse: yup.string(),
  code: yup
    .string()
    .min(4, "Min Barcode Length should be 4")
    .label("Barcode")
    .required(),
});
const BarcodeGenerateModal = ({ show, barcodeGenerateHideCb, closeModal }) => {
  const {
    register,
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mrp: null,
      price: null,
      deal: [],
    },
    resolver: yupResolver(validationSchema),
  });

  // Warehouse select option from local data
  let emp = AuthService.getLoggedInEmp();
  const warehouseOptions = emp.warehouses.map((e) => {
    return { label: e.name, value: e._id };
  });

  const [busyLoader, setBusyLoader] = useState({ message: "", loading: false });
  const onDealSearch = useCallback(async (val, callback) => {
    let filter = {};

    if (val) {
      let v = "/" + val + "/";
      filter = { $or: [{ name: v }, { _id: v }] };
    }
    let wh = getValues("warehouse") || "";
    if (wh) {
      filter["whIds"] = { $in: [wh] };
    }

    let p = {
      page: 1,
      count: 10,
      filter: filter,
    };

    const r = await DealService.getDeals(p);
    callback((r?.resp || []).map((x) => ({ label: x.name, value: x })));
  }, []);

  const onDealChange = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      if (val.length) {
        let d = get(val, "[0].value");
        let p = get(d, "product[0]");
        setValue("mrp", d.mrp);
        setValue("price", d.b2bPrice.toFixed(2));
        setValue("productId", p.id);
        setValue("dealId", d._id);
        setValue("productName", p.name);
        setValue("deals", val);
        setValue("code", "");
        setValue("unit", "");
        setValue("uom", "");
      }
    },
    []
  );

  const onWarehouseChange = (val) => {
    reset({
      mrp: null,
      price: null,
      deal: [],
      warehouse: val,
      uom: null,
      unit: "",
      code: "",
    });
  };

  const onSubmit = async (d) => {
    let params = prepareParams(d);
    setBusyLoader({ message: "Loading", loading: true });
    let r = await DealService.generateBarcode(params);
    setBusyLoader({ message: "", loading: false });

    if (r.statusCode == 200) {
      Toaster.success("Updated Successfully");
      reset();
      closeModal();
    } else {
      Toaster.error(r.resp.message);
    }
  };

  const prepareParams = (d) => {
    return {
      dealId: d.dealId,
      productId: d.productId,
      productName: d.productName,
      price: d.price,
      mrp: d.mrp,
      unit: d.unit,
      unitOfMeasurement: d.uom,
      code: d.code,
    };
  };

  const generateBarcode = async () => {
    setBusyLoader({ message: "Loading", loading: true });
    let r = await ProductService.getBarcode();
    setBusyLoader({ message: "", loading: false });
    if (r.statusCode == 200) {
      setValue("code", get(r, "resp._id"));
      Toaster.success("Barcode Generated Successfully");
    } else {
      Toaster.error(r.resp.message);
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={barcodeGenerateHideCb}
        placement="end"
        style={{ width: "40%" }}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <Offcanvas.Title>
            <div className="page-title">Generate Barcode</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3 border-top">
          <div className="mx-2 my-2">
            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="false"
              noValidate
            >
              <div className="row">
                <div className="col-12">
                  <SelectInput
                    register={register}
                    label="Warehouse"
                    error={errors?.warehouse?.message}
                    isMandatory={true}
                    options={warehouseOptions}
                    callback={onWarehouseChange}
                    name="warehouse"
                  />
                </div>
                <div className="col-12">
                  <Controller
                    control={control}
                    name="deal"
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        label="Search Deal"
                        error={errors?.deal?.message}
                        placeholder="Search  Deal here "
                        value={value}
                        isMandatory={true}
                        onSearch={onDealSearch}
                        callback={onDealChange(onChange)}
                        uid="deal"
                        isMultiple={false}
                      />
                    )}
                  />
                </div>
                <div className="col-6">
                  <TextInput
                    register={register}
                    label="MRP"
                    error={errors?.mrp?.message}
                    isMandatory={true}
                    name="mrp"
                    type="number"
                  />
                </div>
                <div className="col-6">
                  <TextInput
                    register={register}
                    label="Price"
                    error={errors?.price?.message}
                    isMandatory={true}
                    name="price"
                    type="number"
                  />
                </div>
                <div className="col-6">
                  <TextInput
                    register={register}
                    label="Quantity"
                    error={errors?.unit?.message}
                    isMandatory={true}
                    name="unit"
                    type="number"
                  />
                </div>
                <div className="col-6">
                  <SelectInput
                    register={register}
                    label="Unit Of Measurement"
                    error={errors?.uom?.message}
                    options={uomOptions}
                    name="uom"
                    isMandatory={true}
                    s
                  />
                </div>

                <div className="col-12">
                  <div className="input-group">
                    <TextInput
                      register={register}
                      label="Barcode"
                      error={errors?.code?.message}
                      isMandatory={true}
                      name="code"
                      type="text"
                      groupContent={
                        <button
                          className="btn  btn-link  btn-sm"
                          style={{ height: "25px" }}
                          type="button"
                          onClick={generateBarcode}
                        >
                          Generate Barcode
                        </button>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row  mt-4">
                <div className="col-12 text-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Offcanvas.Body>

        <BusyLoader
          message={busyLoader.message}
          show={busyLoader.loading}
        ></BusyLoader>
      </Offcanvas>
    </>
  );
};

export default memo(BarcodeGenerateModal);
