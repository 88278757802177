import { AppCard, Chip, NoDataFound, TableHeader } from "@sk/uis";

const tableHeader = [
  { label: "Sl.no" },
  { label: "Attribute" },
  { label: "Value" },
];

const Category = ({ data = [], category = [] }) => {
  return (
    <div className="row">
      <div className="col-6">
        <AppCard title="Categories">
          {category.length == 0 && (
            <NoDataFound> No Linked Category Found </NoDataFound>
          )}
          {category.length > 0 &&
            category.map((x, i) => <Chip key={i} label={x.name} />)}
        </AppCard>
      </div>
      <div className="col-6">
        <AppCard title="Attribute Values">
          <table className="table table-sm table-bordered">
            <TableHeader data={tableHeader} />
            <tbody>
              {data.length == 0 && (
                <tr>
                  <td colSpan={tableHeader.length}>
                    <NoDataFound>No Data Found</NoDataFound>
                  </td>
                </tr>
              )}
              {data.length > 0 &&
                data.map((x, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{x.name || "N/A"}</td>
                    <td>{x.value || "N/A"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </AppCard>
      </div>
    </div>
  );
};

export default Category;
