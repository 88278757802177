import { AuthService, PoService } from "@sk/services";
import { SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";

const warehouseOptions = [
  { label: "All Warehouses", value: "" },
  ...AuthService.getLoggedInEmpWh().map((x) => ({
    label: x.name,
    value: x._id,
  })),
];

const defaultFromValues = {
  po: "",
  warehouse: "",
};

const statusOptions = PoService.getPoStatuses().map((x) => ({
  label: x.label,
  value: x.value,
}));

const Filter = ({ callback }) => {
  const { register, getValues } = useForm({
    defaultValues: defaultFromValues,
  });

  const onSearch = useCallback(
    debounce(() => {
      const data = getValues();
      callback({ formData: data });
    }, 700),
    [callback, getValues]
  );

  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  return (
    <>
      <form autoComplete="off">
        <div className="row">
          <div className="col-4">
            <TextInput
              register={register}
              name="po"
              callback={onSearch}
              label="PO ID"
              placeholder="Enter PO ID"
              gap={1}
              size="sm"
            />
          </div>

          <div className="col-auto">
            <SelectInput
              label="Warehouse"
              register={register}
              name="warehouse"
              callback={onFilterChange}
              isMandatory={false}
              options={warehouseOptions}
              size="sm"
              gap={1}
            />
          </div>

          <div className="col-auto me-auto">
            <SelectInput
              label="Status"
              register={register}
              name="status"
              callback={onFilterChange}
              isMandatory={false}
              options={statusOptions}
              size="sm"
              gap={1}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
