import { AuthService, FranchiseService, WarehouseService } from "@sk/services";
import {
  HighlightText,
  NoDataFound,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { produce } from "immer";
import { orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import ProductOverview from "../../components/overview/ProductOverview";

const headers = [
  { label: "Sl.No." },
  { label: "WH ID" },
  { label: "Warehouse Name" },
  { label: "Sellable Stock", isCentered: true },
  { label: "Non-Sellable Stock", isCentered: true },
  { label: "On-Hold Stock", isCentered: true },
  { label: "Damaged Stock", isCentered: true },
  { label: "Total Stock", isCentered: true },
  { label: "WH Status", isCentered: true },
  // { label: "MSF Wise Reserved", isCentered: true },
  // { label: "Reserve Order limit", isCentered: true },
  // { label: "Reserve order Limit Enabled", isCentered: true },
];

const StockSummaryList = ({ pId }) => {
  const [loading, setLoading] = useState(true);
  const [whList, setWhList] = useState([]);

  useEffect(() => {
    loadWhList();
  }, [loadWhList]);

  const loadWhList = useCallback(async () => {
    let whIds = (AuthService.getLoggedInEmpWh() || []).map((e) => {
      return e._id;
    });

    let params = {
      page: 1,
      count: 25,
      select: "name,_id,status",
      filter: { _id: { $in: whIds } },
    };
    const r = await FranchiseService.getFranchises(params);
    const d = r.resp || [];

    if (d.length) {
      let r = d.map((e) => {
        return { ...e, stockLoading: true };
      });

      setWhList(orderBy(r, "_id", "asc"));
      setLoading(false);

      // loadOrderLimit
      // loadOrderLimit();

      // Loading MDf Reserve Data
      // loadMsfReserveData();

      // Batch Count Data showing within hold Qty
      // loadBatchCount(whIds);

      // Fetching Stock info
      let promises = [];
      r.forEach((e) => {
        promises.push(WarehouseService.getStockInfo(e._id, pId, {}));
      });
      const w = await Promise.all(promises);
      setWhList(
        produce((draft) => {
          draft.forEach((x, k) => {
            x.stockInfo = w[k]["resp"] || {};
            x.stockLoading = false;
          });
        })
      );
    } else {
      setWhList([]);
      setLoading(false);
    }
  }, [pId]);

  // const loadMsfReserveData = useCallback(async () => {
  //   const r = await WarehouseService.getRfReservationStockSummary(pId);
  //   const d = r.resp || [];

  //   if (d.length) {
  //     setWhList(
  //       produce((draft) => {
  //         draft.forEach((x) => {
  //           d.forEach((e) => {
  //             if (e._id === x._id) {
  //               x.msfReserveQty = e.totalQuantity || 0;
  //               x.msfReserveOnHold = e.totalOnHold || 0;
  //             }
  //           });
  //         });
  //       })
  //     );
  //   }
  // }, [pId]);

  // const loadBatchCount = useCallback(
  //   async (whIds) => {
  //     const r = await OmsService.getProductBatchedCount(pId, {
  //       whIds: whIds.toString(),
  //     });
  //     const d = r.resp || [];
  //     if (d.length) {
  //       setWhList(
  //         produce((draft) => {
  //           draft.forEach((x) => {
  //             d.forEach((e) => {
  //               if (e.whId === x._id) {
  //                 x.batchCount = e.count || 0;
  //               }
  //             });
  //           });
  //         })
  //       );
  //     }
  //   },
  //   [pId]
  // );

  // const loadOrderLimit = useCallback(() => {
  //   // Taking Reserve Order Limit from Product API Response

  //   if (preorderWhList.length) {
  //     setWhList(
  //       produce((draft) => {
  //         draft.forEach((x) => {
  //           preorderWhList.forEach((e) => {
  //             if (e.whId === x._id) {
  //               x.reserveOrderLimit = e.preOrderLimit || 0;
  //             }
  //           });
  //         });
  //       })
  //     );
  //   }
  // }, [preorderWhList]);

  return (
    <>
      <div className="rounded mb-3">
        <ProductOverview pId={pId} />
      </div>

      <div className="mb-2 text-black-50 fs-val-sm">
        Showing stock summary for {whList.length} warehouses
      </div>

      <table className="table table-bordered table-sm bg-white">
        <TableHeader data={headers} />
        <tbody>
          {/* Table Loader */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* No Data Found */}
          {!loading && !whList.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* List */}
          {whList.map((x, i) => (
            <tr key={x._id} className="fs-val-md">
              <td>{i + 1}</td>
              <td>{x._id}</td>
              <td>{x.name}</td>

              {/* Sellable Stock */}
              <td className="text-center">
                {x.stockLoading ? (
                  <Spinner type="dots" />
                ) : (
                  <span
                    className={!x.stockInfo?.avaiableQty ? "text-muted" : ""}
                  >
                    {x.stockInfo?.avaiableQty}
                  </span>
                )}
              </td>

              {/* Non Sellable Stock */}
              <td className="text-center">
                {x.stockLoading ? (
                  <Spinner type="dots" />
                ) : (
                  <span
                    className={!x.stockInfo?.nonAvaiableQty ? "text-muted" : ""}
                  >
                    {x.stockInfo?.nonAvaiableQty}
                  </span>
                )}
              </td>

              {/* On Hold Stock */}
              <td className="text-center">
                {x.stockLoading ? (
                  <Spinner type="dots" />
                ) : (
                  <span className={!x.stockInfo?.onHoldQty ? "text-muted" : ""}>
                    {x.stockInfo?.onHoldQty}
                    {x.batchCount ? (
                      <span className="text-muted"> ({x.batchCount})</span>
                    ) : null}
                  </span>
                )}
              </td>

              {/* Damaged Stock */}
              <td className="text-center">
                {x.stockLoading ? (
                  <Spinner type="dots" />
                ) : (
                  <span
                    className={!x.stockInfo?.damagedQty ? "text-muted" : ""}
                  >
                    {x.stockInfo?.damagedQty}
                  </span>
                )}
              </td>

              {/* Total Stock */}
              <td className="text-center">
                {x.stockLoading ? (
                  <Spinner type="dots" />
                ) : (
                  <span>
                    {x.stockInfo?.avaiableQty +
                      x.stockInfo?.onHoldQty +
                      x.stockInfo?.damagedQty +
                      x.stockInfo?.nonAvaiableQty}
                  </span>
                )}
              </td>

              {/* Status */}
              <td className="text-center">
                <HighlightText
                  status={x.status == "Suspended" ? "Inactive" : "Active"}
                />
              </td>

              {/* MSF Wise Reserved */}
              {/* <td className="text-center"></td> */}

              {/* Reserve Order limit */}
              {/* <td className="text-center">{x?.reserveOrderLimit || "N/A"}</td> */}

              {/* Reserve order Limit Enabled */}
              {/* <td className="text-center"></td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default StockSummaryList;
