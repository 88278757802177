import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import { BrandService } from "@sk/services";
import {
  AppCard,
  AuditLogTable,
  BusyLoader,
  DateFormatter,
  HighlightText,
  ImgPreviewModal,
  ImgRender,
  KeyVal,
  NoDataFound,
  PageInfo,
  Rbac,
  Spinner,
  TabContent,
  Tabs,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ManageBrandModal from "../modals/ManageBrandModal";
import {
  BrandMargin,
  LinkedDeals,
  LinkedOrders,
  LinkedProducts,
  LinkedVendors,
} from "./features/index";

const breadcrumbs = [
  {
    name: "Home",
    link: "/cms/brand/list",
  },

  {
    name: "Manage Brands",
    link: "/cms/brand/list",
  },
  {
    name: "Brand Details",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "category",
    api: "category",
    loadingKey: "categoryLoading",
    dataKey: "_category",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "masterData.masterId",
    useKeyPath: true,
    api: "brand",
    loadingKey: "masterBrandLoading",
    dataKey: "_masterBrand",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const tabs = [
  { key: "deals", tabName: "Linked Deals" },
  { key: "products", tabName: "Linked Products" },
  { key: "vendors", tabName: "Linked Vendors" },
  { key: "orders", tabName: "Orders" },
  { key: "brandMargin", tabName: "Brand Margin" },
  { key: "audit", tabName: "Audit Logs" },
];

const rbac = {
  editButton: ["EditBrand"],
};

const ViewBrand = () => {
  // Active tabs
  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  // data for View Brand
  const [data, setData] = useState({});

  // Loading State For Data
  const [loading, setLoading] = useState(true);

  // To Show Manage Modal
  const [showManageModal, setShowManageModal] = useState(false);

  const [imgPreviewModal, setImgPreviewModal] = useState({
    show: false,
    data: [],
  });

  // To Save Manage Data modal
  const manageModalDataRef = useRef({ id: "", mode: "add" });

  // To Get all Search Params
  const [searchParams] = useSearchParams();

  // NAvigation Object
  const router = useNavigate();

  // To get Query Params
  const query = useFetchUrlQueryString(searchParams);

  // Custom Hook To attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Use Effect To Load Brand Details
  useEffect(() => {
    if (query.id) {
      loadBrandDetails(query.id);
    } else {
      setLoading(false);
    }
  }, [attachAllData, query.id, setAdditionalData, loadBrandDetails]);

  // To Load Brand Data
  const loadBrandDetails = useCallback(
    async (id) => {
      setLoading(true);

      let m = await BrandService.getList({ filter: { _id: id } });
      const d = Array.isArray(m.resp) && m.resp.length > 0 ? m.resp[0] : {};

      // adding attachUser loadingKey
      d.userLoading = true;
      d.categoryLoading = true;
      d.masterBrandLoading = true;
      d.modifiedLoading = true;

      // Attach additional Info
      if (d._id) {
        let tmp = [];
        setAdditionalData([d], attachAdditionalDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length == attachAdditionalDataConfig.length) {
            const a = [...attachAllData([d], tmp)][0];
            setData((v) => ({ ...v, ...a }));
          }
        });
        // Setting Data
        setData(d);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
    [attachAllData, setAdditionalData]
  );

  // Tab callback
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  // To Close Manage Modal
  const closeManageModal = useCallback(
    (r) => {
      setShowManageModal(false);
      if (r.status == "submit") {
        loadBrandDetails(data._id);
      }
    },
    [data._id, loadBrandDetails]
  );

  // To Open Edit Modal
  const openEditModal = useCallback((id) => {
    manageModalDataRef.current.mode = "edit";
    manageModalDataRef.current.id = id;
    setShowManageModal(true);
  }, []);

  const closeImgPreviewModal = () => {
    setImgPreviewModal({ show: false, data: [] });
  };

  const viewImgPreviewModal = (d) => {
    setImgPreviewModal({ show: true, data: [{ id: d }] });
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Brand Details"
            breadcrumbs={breadcrumbs}
            navigate={router}
          />
        </div>
      </div>

      {/* No Data Found */}

      {!loading && !data?._id ? (
        <AppCard>
          <NoDataFound> No Data Found </NoDataFound>
        </AppCard>
      ) : null}

      {data?._id ? (
        <>
          {/* Main Details starts (left side) */}
          <AppCard>
            <div className="row   border-bottom pb-2 ">
              <div className="col-8">
                <div className="row">
                  <div className="col-12">
                    <div className="fs-val-lg text-primary mb-3 fw-semibold">
                      {data.name}
                    </div>
                  </div>

                  {/* Details Sections  COL - 1  */}
                  <div className="col-4   border-end">
                    {/* Brand ID */}
                    <KeyVal
                      label="Brand ID :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">{data._id}</span>
                    </KeyVal>

                    {/* Category  */}
                    <KeyVal
                      label="Category :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className="fw-semibold cursor-pointer">
                        {data.categoryLoading && <Spinner type="dots" />}

                        {!data.categoryLoading &&
                          (data._category?.[0]?.name || "N/A")}

                        {data._category?.length - 1 > 0 && (
                          <> +{data._category?.length - 1} </>
                        )}
                      </span>
                    </KeyVal>

                    <KeyVal
                      label="Brand Type :"
                      labelCol="col-4"
                      contentCol="col-8"
                      className="text-primary"
                    >
                      <span className="fw-semibold">
                        {" "}
                        {data.brandType || "N/A"}{" "}
                      </span>
                    </KeyVal>
                  </div>
                  {/* Details section COL 2 */}
                  <div className="col-4   border-end ps-2">
                    {/* Status */}
                    <KeyVal
                      label="Is Master Brand? :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        <HighlightText
                          status={data?.masterData?.isMaster ? "Yes" : "No"}
                        />
                      </span>
                    </KeyVal>

                    {/* Status */}
                    <KeyVal
                      label="Status :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        <HighlightText status={data?.status} />
                      </span>
                    </KeyVal>

                    {/* Master Brand ID*/}
                    <KeyVal
                      label="Master Brand ID:"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data.masterBrandLoading ? (
                          <Spinner type="dots" />
                        ) : (
                          data._masterBrand._id || "N/A"
                        )}
                      </span>
                    </KeyVal>

                    {/* Is Value Brand */}
                    {/* <KeyVal
                      label="Classification :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary "
                    >
                      <span className="fw-semibold">{data.classification}</span>
                    </KeyVal> */}
                  </div>

                  {/* Details section COL 3 */}
                  <div className="col-4 ps-3   ">
                    {/* Master Brand */}
                    <KeyVal
                      label="Master Brand :"
                      labelCol="col-5"
                      contentCol="col-7"
                      className="text-primary"
                    >
                      <span className=" fw-semibold">
                        {data.masterBrandLoading ? (
                          <Spinner type="dots" />
                        ) : (
                          data._masterBrand.name || "N/A"
                        )}
                      </span>
                    </KeyVal>
                  </div>
                </div>
              </div>

              {/* Image Section */}
              <div className="col-4  algin-self-end">
                <div className="row align-items-start justify-content-end">
                  {/* Logo */}
                  <div className="col-auto">
                    <div className="text-black-50 fs-val-sm fw-semibold">
                      Brand Logo
                    </div>
                    <div
                      className="border rounded"
                      onClick={() => {
                        viewImgPreviewModal(data?.image?.[0]);
                      }}
                    >
                      <ImgRender
                        assetId={data?.image?.[0]}
                        width="80"
                        height="80"
                      />
                    </div>
                  </div>
                  {/* Desktop */}
                  <div className="col-auto">
                    <div className="text-black-50 fs-val-sm fw-semibold">
                      Desktop Image
                    </div>
                    <div
                      className="border rounded"
                      onClick={() => {
                        viewImgPreviewModal(data?.mainDesktopImage?.[0]);
                      }}
                    >
                      <ImgRender
                        assetId={data?.mainDesktopImage?.[0]}
                        width="80"
                        height="80"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="text-black-50 fs-val-sm fw-semibold">
                      Mobile Image
                    </div>
                    <div
                      className="border rounded"
                      onClick={() => {
                        viewImgPreviewModal(data?.mainMobileImage?.[0]);
                      }}
                    >
                      <ImgRender
                        assetId={data?.mainMobileImage?.[0]}
                        width="80"
                        height="80"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center mt-3">
              <div className="col-auto me-4">
                <KeyVal
                  label="Created By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  {data.userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className=" fw-semibold">
                      {data?._createdBy?.name || "N/A"}
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto pe-5 border-end">
                <KeyVal
                  label="Created Date: "
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold "
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.createdAt} />
                </KeyVal>
              </div>

              <div className="col-auto ps-4 pe-3">
                <KeyVal
                  label="Modified By:"
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  {data.userLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    <span className=" fw-semibold">
                      {data?._modifiedBy?.name || "N/A"}
                    </span>
                  )}
                </KeyVal>
              </div>

              <div className="col-auto me-auto">
                <KeyVal
                  label="Modified Date: "
                  labelCol="col-auto"
                  contentCol="col-auto"
                  className="fw-semibold"
                  noBottomSpace={true}
                >
                  <DateFormatter date={data.lastUpdated} />
                </KeyVal>
              </div>

              <div className="col-auto">
                <Rbac roles={rbac.editButton}>
                  <button
                    className="btn btn-primary fs-val-sm"
                    onClick={openEditModal}
                  >
                    Edit Brand
                  </button>
                </Rbac>
              </div>
            </div>
          </AppCard>

          {/* tabs start */}
          <div className="card">
            <div className="card-body p-0">
              <Tabs data={tabs} callback={tabCb} activeTab={activeTab} />
              <TabContent>
                <div className="p-4">
                  {activeTab.key == "deals" && (
                    <LinkedDeals brandId={data._id} />
                  )}

                  {activeTab.key == "products" && (
                    <LinkedProducts brandId={data._id} />
                  )}

                  {activeTab.key == "vendors" && (
                    <LinkedVendors brandId={data._id} />
                  )}

                  {activeTab.key == "orders" && (
                    <LinkedOrders brandId={data._id} />
                  )}

                  {activeTab.key === "audit" && (
                    <AuditLogTable entityType="BRAND" entityId={data._id} />
                  )}

                  {activeTab.key == "brandMargin" && (
                    <BrandMargin brandId={data._id} />
                  )}
                </div>
              </TabContent>
            </div>
          </div>
        </>
      ) : null}

      {/* Manage Modal Page */}

      <ManageBrandModal
        id={data._id}
        show={showManageModal}
        closeModal={closeManageModal}
        mode={manageModalDataRef.current.mode}
      />

      <BusyLoader show={loading} />
      <ImgPreviewModal
        show={imgPreviewModal.show}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewModal.data}
      />
    </>
  );
};

export default ViewBrand;
