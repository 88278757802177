import { useAttachAdditionalData } from "@sk/hooks";
import { DealService, NavService } from "@sk/services";
import {
  Amount,
  EntitySearchInput,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  SelectInput,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import debounce from "lodash/debounce";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { detailsView } from "../../../constantService";
import ViewLinkedProductModal from "./modal/ViewLinkedProductModal";

// Default Form Data
const filterFormData = detailsView.tabs.linkedDeals.filterFromData;

// Default Attach Additional Config
const attachAdditionalDataConfig =
  detailsView.tabs.linkedDeals.additionalTableDataConfig;

// Default Status Options
const statusOptions = detailsView.tabs.linkedDeals.selectOptions.statusOptions;

// Default Status Options
const catalogOptions =
  detailsView.tabs.linkedDeals.selectOptions.catalogTypeOptions;

// Default Table Header
const tableHeaders = detailsView.tabs.linkedDeals.tableHeaders;

const getData = async (params) => {
  const r = await DealService.getDeals(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.categoryLoading = true;
        return x;
      })
    : [];
};

const modalDataOptions = [
  {
    key: "id",
    dealKey: "_id",
  },
  { key: "dealName", dealKey: "name" },
  { key: "product", dealKey: "product" },
];

const defaultViewModalData = { id: "", dealName: "", product: [] };

const prepareFilterParams = (
  pagination = {},
  filter = {},
  additionalData = {}
) => {
  let p = {
    count: pagination.rowsPerPage,
    page: pagination.activePage,
    filter: {
      category: { $elemMatch: { $eq: additionalData.id } },
      dealsFor: "erp",
    },
    select:
      "name,mrp,status,is_active,memberPrice,brand,brandDetails,brands,isOwnedByThirdparty",
  };

  const deal = filter.deal.trim();

  const dealRegex = { $regex: deal, $options: "gi" };

  if (deal) {
    p.filter["$or"] = [{ name: dealRegex }, { _id: dealRegex }];
  }

  if (filter.status) {
    p.filter.is_active = filter.status == "Active";
  }

  if (filter.catalogType) {
    p.filter.isOwnedByThirdparty = filter.catalogType == "sk" ? false : true;
  }

  if (filter.brand?.length) {
    p.filter.brand = filter.brand[0].value._id;
  }
  return p;
};

const LinkedDeals = ({ categoryId }) => {
  // For Filter
  const { register, getValues, control } = useForm({
    defaultValues: filterFormData,
  });

  // Loading State when We are calling API
  const [loading, setLoading] = useState(true);

  // Loading State Form Total Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Data From The API
  const [list, setList] = useState([]);

  // To Show Advance Filer

  // Pagination Config
  const paginationConfigRef = useRef({
    ...detailsView.tabs.linkedDeals.pagination,
  });

  // filer Data Ref
  const filterDataRef = useRef({ ...filterFormData });

  // saving category id from data to idRef
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (categoryId) {
      applyFilter();
    } else {
      setLoading(false);
      setList([]);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, categoryId]);

  const applyFilter = useCallback(() => {
    filterDataRef.current = { ...getValues() };

    // To Get Count
    loadCount();

    // To loadList
    loadDealList();
  }, [loadCount, loadDealList, getValues]);

  // To Get the Count of All
  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    let p = getFilterParams();
    delete p.count;
    delete p.page;
    delete p.select;
    delete p.filter.dealsFor;
    let m = await DealService.getDealsCount(p);

    // Setting total Count
    paginationConfigRef.current.totalRecords = m.resp ? m.resp : 0;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  // To get List of All Lined Deals
  const loadDealList = useCallback(async () => {
    setLoading(true);

    let p = getFilterParams();

    let d = await getData(p);
    setList(d);

    // To Attach Additional Data
    let tmp = [];

    // Attach Additional Config Data
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setList([...attachAllData(d, tmp)]);
      }
    });

    setLoading(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // Pagination Cb
  const paginationCb = useCallback(
    (d) => {
      paginationConfigRef.current.activePage = d.activePage;
      paginationConfigRef.current.startSlNo = d.startSlNo;
      paginationConfigRef.current.endSlNo = d.endSlNo;
      loadDealList();
    },
    [loadDealList]
  );

  // navigate To View Deal Page
  const viewDeal = (i) => {
    NavService.openInNewTab("/cms/deal/view", { id: i });
  };

  const viewProduct = (id) => {
    NavService.openInNewTab("/cms/product/view", { id: id });
  };

  const viewBrand = (id) => {
    NavService.openInNewTab("/cms/brand/view", { id: id });
  };

  // To Open Linked Product Modal
  const [showLinkedProductModal, setShowLinkedProductModal] = useState(false);

  // To store View Modal Data
  const viewModalRef = useRef({ ...defaultViewModalData });

  // SearchCb for Deal
  const searchCb = debounce(() => {
    applyFilter();
  }, 700);

  // const resetFromData = () => {
  //   // Filter For Data
  //   reset(filterFormData);
  //   applyFilter();
  // };

  // SearchCb for Brands
  const onBrandChange = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e);
        applyFilter();
      };
    },
    [applyFilter]
  );

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationConfigRef.current,
      filterDataRef.current,
      {
        id: categoryId,
      }
    );
  }, [categoryId]);

  const closeViewLinkedProductModal = useCallback(() => {
    viewModalRef.current = { ...defaultViewModalData };
    setShowLinkedProductModal(false);
  }, []);

  const openViewLinkedProductModal = useCallback((x) => {
    modalDataOptions.forEach((conf) => {
      viewModalRef.current[conf.key] = x[conf.dealKey];
    });

    setShowLinkedProductModal(true);
  }, []);

  return (
    <>
      <div className="row">
        {/* Deal Name */}
        <div className="col-3">
          <TextInput
            type="text"
            name="deal"
            label="Search for Deal"
            register={register}
            callback={searchCb}
            placeholder="Search By Deal Name /ID"
          />
        </div>
        {/* Category */}
        <div className="col-3">
          <Controller
            control={control}
            name="brand"
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type={"brand"}
                label="Search for Brand"
                placeholder="Search By Name/ID"
                isMandatory={false}
                value={value}
                callback={onBrandChange(onChange)}
                uid="category-search"
                isMultiple={false}
              />
            )}
          />
        </div>
        {/* Status */}
        <div className="col-auto">
          <SelectInput
            label="Status"
            register={register}
            name="status"
            options={statusOptions}
            callback={applyFilter}
          />
        </div>
        {/* Catalog Type */}
        <div className="col-auto">
          <SelectInput
            label="Catalog Type"
            register={register}
            name="catalogType"
            options={catalogOptions}
            callback={applyFilter}
          />
        </div>
        {/* <div className="col-auto ms-auto align-self-center">
          <button
            className="btn btn-sm btn-primary me-1 fs-val-md"
            onClick={applyFilter}
          >
            Apply
          </button>
          <button
            className="btn btn-sm btn-outline-warning fs-val-md"
            onClick={resetFromData}
          >
            Reset
          </button>
        </div> */}
      </div>
      <div>
        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationConfigRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
        <table className="table table-bordered">
          <TableHeader data={tableHeaders} />
          <tbody>
            {/* When Page Loading */}
            {loading && (
              <TableSkeletonLoader rows={5} cols={tableHeaders.length} />
            )}

            {/*  When There is No Data */}
            {!loading && !list.length ? (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {/* When there is Data */}
            {!loading &&
              (list || []).map((x, index) => (
                <tr key={index + x._id} className="fs-val-md">
                  <td>{paginationConfigRef.current.startSlNo + index}</td>
                  {/* ID  */}
                  <td>{x?._id}</td>
                  {/* Deal Name */}
                  <td>
                    <div
                      className="text-primary"
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        viewDeal(x._id);
                      }}
                    >
                      {x?.name}
                    </div>
                  </td>
                  {/* Product Id */}
                  <td className="text-primary">
                    <span
                      onClick={() => {
                        viewProduct(x.product[0].id);
                      }}
                      role="button"
                      tabIndex={-1}
                    >
                      {x.product[0].name}
                    </span>
                    {x.product.length > 1 ? (
                      <span
                        onClick={() => openViewLinkedProductModal(x)}
                        role="button"
                        tabIndex={-1}
                      >
                        (+{x.product.length - 1} more )
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                  {/* Brand */}
                  <td>
                    {x.categoryLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      <span
                        role="button"
                        className="text-primary"
                        tabIndex={-1}
                        onClick={() => {
                          viewBrand(x._brand?.[0]["_id"]);
                        }}
                      >
                        {x._brand?.[0]?.name + " - " + x._brand?.[0]["_id"] ||
                          "N/A"}
                      </span>
                    )}
                  </td>

                  {/* Catalog Type */}
                  <td className="text-center">
                    {x?.isOwnedByThirdparty ? "Seller" : "SK"}
                  </td>

                  {/* Status */}
                  <td>
                    <HighlightText
                      status={x?.is_active ? "Active" : "Inactive"}
                      isBadge={false}
                    />
                  </td>

                  {/* Amount */}
                  <td>
                    <Amount value={x?.mrp} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfigRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      {/* View Linked Product Modal */}
      <ViewLinkedProductModal
        show={showLinkedProductModal}
        closeModal={closeViewLinkedProductModal}
        modalData={viewModalRef.current}
      />
    </>
  );
};

export default memo(LinkedDeals);
