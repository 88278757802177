import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchUrlQueryString } from "@sk/hooks";
import {
  AjaxService,
  BrandService,
  CategoryService,
  NavService,
  ProductService,
} from "@sk/services";
import {
  AppCard,
  BusyLoader,
  NoDataFound,
  PageInfo,
  PageLoader,
  TabContent,
  Tabs,
  Toaster,
} from "@sk/uis";
import cloneDeep from "lodash/cloneDeep";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  BasicDetails,
  Categories,
  HighlightsDescriptions,
  ProductImages,
  Setting,
  Taxes,
  TierConfig,
  UnitDimension,
} from "./components";

import MoreAboutProduct from "./components/tabs/MoreAboutProduct";
import Remarks from "./components/tabs/Remarks";
import validationSchema from "./manageProductValidationSchema";

const tabs = [
  { key: "taxes", tabName: "Price Details & Taxes" },
  { key: "dimensions", tabName: "Dimensions & Units" },
  { key: "categories", tabName: "Attributes & Classification" },
  { key: "description", tabName: "Highlight & Description" },
  { key: "images", tabName: "Product Images" },
  { key: "tierConfig", tabName: "Tier Configs" },
  { key: "setting", tabName: "Additional Details" },
  { key: "remarks", tabName: "Remarks" },
  { key: "more", tabName: "More About Product" },
];

const defaultBreadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Manage Products",
    link: "/cms/product/list",
  },
  {
    name: "Add Product",
  },
];

const getEntitySearchResult = async (type, id) => {
  const c = type.toLowerCase();
  if (c === "category") {
    const r = await CategoryService.getCategory(id);

    return ([r.resp] || []).map((x) => ({
      label: x.name + " (" + x._id + ") ",
      value: x,
    }));
  }

  if (c == "brand") {
    const r = await BrandService.getDetailsById(id);
    return ([r.resp] || []).map((x) => ({
      label: x.name + " (" + x._id + ") ",
      value: x,
    }));
  }

  if (c == "product") {
    const r = await ProductService.getProduct(id);
    return ([r.resp] || []).map((x) => ({
      label: x.name + " (" + x._id + ") ",
      value: x,
    }));
  }
};

const loadHSNList = async (ids) => {
  const r = await ProductService.getHsnMasters({
    select: "hsn",
    filter: {
      ["linkedCategories.id"]: {
        $in: [ids],
      },
    },
  });
  const d =
    Array.isArray(r.resp) && r.resp.length
      ? r.resp.map((x) => ({ label: x.hsn, value: x.hsn }))
      : [{ label: "No HSN Found", value: "" }];

  d.unshift({ label: "Please Select HSN", value: "" });
  return d;
};

const defaultFormValue = {
  masterProduct: [],
  brand: [],
  isTaxable: true,
  attributesList: [],
  category: [],
  images: [],
  classClassifications: [],
  tierData: [],
  highlights: [],
  name: "",
  barcode: "",
  is_active: true,
  originCountry: "",
  tax: 0,
  skuCode: "",
  keyFeatures: [],
  ingredients: "",
  manufacturerDetails: "",
  marketedBy: "",
  expiryDate: "",
  disclaimer: "",
  remarks: "",
};

const prepareParams = (data) => {
  const p = {};
  const ignoreKeys = [
    "tierName", // This is To Store Tire Name in TierConfig Tab
    "min", // This is to Store Min value in TierConfig Tab
    "max", // Same to Store Max Value
    "highlightInput", // To Store HighList input Text Value
    "attributesList", // To have all attributes list under category
    "brand",
    "category",
    "ptrByBrands",
    "masterProduct",
    "defaultClassifications", // this need to push in class Classification
    "warehouse",
    "weight", // This we Will send in measure
    "hsnList",
    "whClassification",
    "length",
    "height",
    "breadth",
    "packSize",
  ];

  //  Added Direct Values to Form
  Object.keys(data).forEach((key) => {
    if (ignoreKeys.indexOf(key) == -1) {
      p[key] = data[key];
    }
  });

  if (data.brand.length) {
    p.brand = [data.brand?.[0]?.value._id];
  }

  if (data.category.length) {
    p.category = [data.category?.[0]?.value._id];
  }

  if (data.masterProduct.length) {
    p.masterProductId = data.masterProduct?.[0]?.value._id;
  }

  if (data.images.length) {
    p.images = data.images.map((x) => x.asset);
  }

  if (data?.defaultClassifications?.name) {
    p.classClassifications = cloneDeep(data.classClassifications);
    p.classClassifications.push(data.defaultClassifications);
  }

  if (data.weight) {
    p.measure = {
      weight: data.weight,
      length: data.length,
      height: data.height,
      breadth: data.breadth,
    };
  }

  if (data.attributesList.length) {
    p.csa = data.attributesList
      .map((x) => x.attributes)
      .flat()
      .map((x) => ({ name: x.name, attributeId: x._id, value: x.value }));
  }

  p.ptrByBrands = data.ptrByBrands != null ? data.ptrByBrands : 0;
  p.packSize = data?.packSize || 0;
  if (!data.isTaxable) {
    ["tax", "additionalCessType", "additionalCessValue"].forEach((x) => {
      p[x] = 0;
    });
  }

  return p;
};

const ManageProduct = () => {
  const [activeTab, setActiveTab] = useState({
    key: "taxes",
  });

  const [breadcrumbs, setBreadcrumbs] = useState([...defaultBreadcrumbs]);

  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [display, setDisplay] = useState("loading");

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValue,
  });

  const tabCb = (data) => {
    methods.setValue("activeTab", data.value.key);
    setActiveTab({ ...data.value });
  };

  const onSubmit = async (data) => {
    let errMsg = "";
    for (let t of tabs) {
      try {
        await validationSchema.validate({
          ...data,
          activeTab: t.key,
        });
        errMsg = "";
      } catch (error) {
        errMsg = error.message || "";
      }

      if (errMsg) {
        break;
      }
    }
    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }
    setBusyLoader({
      msg: "please,wait submitting your response",
      loading: true,
    });
    const params = prepareParams(data);
    let r;
    let msg = "";
    if (query.id) {
      r = await ProductService.update(query.id, params);
      msg = "Product Updated Successfully";
    } else {
      msg = "Product Added Successfully";
      r = await ProductService.create(params);
    }

    setBusyLoader({ msg: "", loading: false });

    if (r.statusCode == 200) {
      Toaster.success(msg);
      NavService.to(navigate, "/cms/product/list");
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0]);
    }
  };

  useEffect(() => {
    if (query.id) {
      setDisplay("loading");
      loadProductDetails(query.id);

      setBreadcrumbs((prev) => {
        prev[prev.length - 1].name = "Edit Product";
        return prev;
      });
    } else {
      setDisplay("form");
      setBreadcrumbs((prev) => {
        prev[prev.length - 1].name = "Add Product";
        return prev;
      });
      resetFormData();
    }
  }, [query.id, methods, loadProductDetails, resetFormData]);

  const loadProductDetails = useCallback(
    async (id) => {
      let m = await ProductService.getProduct(id);

      if (m?.resp?._id) {
        const r = m?.resp || {};

        if (r.brand) {
          const brand = await getEntitySearchResult("brand", r.brand?.[0]);
          methods.setValue("brand", brand);
        }

        if (r.masterProductId) {
          const masterProduct = await getEntitySearchResult(
            "product",
            r.masterProductId
          );
          methods.setValue("masterProduct", masterProduct);
        }
        if (r.category.length) {
          const category = await getEntitySearchResult(
            "category",
            r.category?.[0]
          );
          methods.setValue("category", category);

          const d = await loadHSNList(category[0].value._id);
          methods.setValue("hsnList", d);
          methods.setValue("HSNNumber", r.HSNNumber);
        }
        if (r?.measure?.weight) {
          methods.setValue("weight", r.measure.weight);
        }
        if (r?.measure?.length) {
          methods.setValue("length", r.measure.length);
        }
        if (r?.measure?.height) {
          methods.setValue("height", r.measure.height);
        }
        if (r?.measure?.breadth) {
          methods.setValue("breadth", r.measure.breadth);
        }

        if (r.classClassifications) {
          methods.setValue(
            "classClassifications",
            r.classClassifications.filter((x) => !x.isDefault)
          );

          const defaultClassifications = r.classClassifications.find(
            (x) => x.isDefault
          );

          if (defaultClassifications) {
            methods.setValue("defaultClassifications", defaultClassifications);
          }
        }

        methods.setValue("isTaxable", r.tax > 0);

        //  To have CSA Values
        let attributesList = methods
          .getValues("category")
          .map((x) => ({ name: x.label, attributes: x.value.attributes }));

        //  setting csa Value ...
        attributesList.forEach((item) => {
          item.attributes.forEach((i) => {
            let attr = r?.csa?.find((x) => {
              return x.attributeId == i._id;
            });
            if (attr?.attributeId) {
              i.name = attr.name;
              i.value = attr.value;
            }
          });
        });

        methods.setValue("attributesList", attributesList);

        const ignoreFormKeys = [
          "measure",
          "brand",
          "category",
          "masterProduct",
          "csa",
          "measure",
          "classClassifications",
        ];

        Object.keys(r).forEach((key) => {
          if (ignoreFormKeys.indexOf(key) == -1) {
            if (key === "images") {
              methods.setValue(
                key,
                r[key].map((img) => ({ asset: img }))
              );
            } else {
              methods.setValue(key, r[key]);
            }
          }
          methods.setValue("activeTab", "basic");
          setDisplay("form");
        });
      }
      // If there is No response
      else {
        setDisplay("notFound");
      }
    },
    [methods]
  );

  const resetFormData = useCallback(() => {
    methods.reset(defaultFormValue);
  }, [methods]);

  return (
    <>
      {display === "loading" && <PageLoader animation="border" />}

      {display === "form" && (
        <>
          <PageInfo
            title={query?.id ? "Edit Product" : "Add Product"}
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
              <div className="row">
                {/* Basic Details */}
                <div className="col-12">
                  <AppCard>
                    <BasicDetails setBusyLoader={setBusyLoader} />
                  </AppCard>
                </div>

                <div className="col-12">
                  <div className="border-bottom mb-1">
                    <Tabs
                      data={tabs}
                      callback={tabCb}
                      activeTab={activeTab}
                      template={4}
                    />
                  </div>

                  <TabContent>
                    <div className="p-4">
                      {activeTab.key === "categories" && (
                        <Categories mode={query.id ? "Edit" : "Add"} />
                      )}

                      {activeTab.key === "description" && (
                        <HighlightsDescriptions />
                      )}

                      {activeTab.key === "images" && <ProductImages />}

                      {activeTab.key === "tierConfig" && <TierConfig />}

                      {activeTab.key === "dimensions" && <UnitDimension />}

                      {activeTab.key === "setting" && <Setting />}

                      {activeTab.key === "taxes" && <Taxes />}

                      {activeTab.key === "more" && <MoreAboutProduct />}

                      {activeTab.key === "remarks" && <Remarks />}
                    </div>
                  </TabContent>
                </div>
              </div>

              {/* Submit Button */}
              <div className="text-end py-3">
                <button className="btn btn-lg btn-primary">Submit</button>
              </div>
            </form>
          </FormProvider>
        </>
      )}
      {display === "notFound" && <NoDataFound> No Data Found </NoDataFound>}
      <BusyLoader
        message={busyLoader.message}
        show={busyLoader.loading}
      ></BusyLoader>
    </>
  );
};

export default ManageProduct;
