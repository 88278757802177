import { WarehouseService } from "@sk/services";
import { NoDataFound, TableHeader, TableSkeletonLoader } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";

const tableHeader = [{ label: "Sl.no" }, { label: "Barcode" }];

const getData = async (params) => {
  const r = await WarehouseService.getBarcodeMasterList(params);
  return Array.isArray(r.resp) ? r.resp[0]?.barcodes || [] : [];
};

const BarcodeMaster = ({ pId }) => {
  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (pId) {
      loadBarcodeData();
    } else {
      setLoadingData(false);
    }
  }, [pId, loadBarcodeData]);

  const loadBarcodeData = useCallback(async () => {
    setLoadingData(true);

    const p = {
      count: 10,
      page: 1,
      filter: { productId: pId },
    };
    const r = await getData(p);
    setData(r);
    setLoadingData(false);
  }, [pId]);

  return (
    <>
      <div className="row">
        <div className="col-3">
          <table className="table table-bordered bg-white">
            <TableHeader data={tableHeader} />
            <tbody>
              {/* When Data is Loading  */}
              {loadingData && (
                <TableSkeletonLoader
                  rows={10}
                  cols={tableHeader.length}
                  height={40}
                />
              )}
              {!loadingData && !data.length ? (
                <tr>
                  <td colSpan={tableHeader.length}>
                    <NoDataFound> Barcode Details Not Found </NoDataFound>
                  </td>
                </tr>
              ) : null}

              {!loadingData &&
                data.map((x, i) => (
                  <tr key={i} className="fs-val-md">
                    <td>{i + 1}</td>
                    <td>{x || "N/A"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default memo(BarcodeMaster);
