import { OmsService } from "@sk/services";
import { Amount, AppCard, HighlightText, ImgRender, KeyVal } from "@sk/uis";

const imgStyle = {
  width: "120px",
  height: "120px",
  objectFit: "contain",
};

import { endOfDay, startOfDay, sub } from "date-fns";
import { useEffect, useState } from "react";

const getOrderCount = async (days, id) => {
  const startDate = startOfDay(sub(new Date(), { days }));
  const endDate = endOfDay(new Date());

  const r = await OmsService.getOrderCount({
    filter: {
      "subOrders.products.id": id,
      createdAt: {
        $gte: startDate,
        $lte: endDate,
      },
    },
  });
  return r.resp || 0;
};

const ProductBasic = ({ data }) => {
  const [soldInfo, setSoldInfo] = useState({
    loading: true,
    last30Days: 0,
    last90Days: 0,
    last180Days: 0,
  });

  useEffect(() => {
    const fetchSoldInfo = async () => {
      setSoldInfo((prev) => ({ ...prev, loading: true }));

      const [last30Days, last90Days, last180Days] = await Promise.all([
        getOrderCount(30, data._id),
        getOrderCount(90, data._id),
        getOrderCount(180, data._id),
      ]);

      setSoldInfo({
        loading: false,
        last30Days,
        last90Days,
        last180Days,
      });
    };
    fetchSoldInfo();
  }, [data._id]);

  return (
    <AppCard>
      <div className="row">
        <div className="col-auto align-self-center">
          <div style={imgStyle}>
            <ImgRender
              assetId={data.images[0]}
              width="120"
              height="120"
              className="object-contain"
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex gap-2 mb-3">
            <div className="fs-val-normal fw-bold">{data.name}</div>
            <div>
              <span className="border rounded px-1 px-2 fs-val-sm">
                {data._id}
              </span>
            </div>
            <div>
              <HighlightText
                type={data.status == "Active" ? "success" : "danger"}
                template={2}
              >
                {data.status}
              </HighlightText>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-4">
              <KeyVal label="Category">
                : {data._category?.length ? data._category[0].name : "N/A"}
              </KeyVal>
              <KeyVal label="Brand">
                : {data._brand?.length ? data._brand[0].name : "N/A"}
              </KeyVal>
              <KeyVal label="MRP">
                : <Amount value={data.mrp} />
              </KeyVal>
              {/* <KeyVal label="B2B Price">
                : <Amount value={data.b2bPrice} />
              </KeyVal> */}
            </div>
            <div className="col-4">
              <KeyVal label="Case Qty" labelCol="col-5" contentCol="col-7">
                : {data.caseQty || "N/A"}
              </KeyVal>
              <KeyVal
                label="Inner Case Qty"
                labelCol="col-5"
                contentCol="col-7"
              >
                : {data.innerPackQty || "N/A"}
              </KeyVal>
              <KeyVal label="Shelf Life" labelCol="col-5" contentCol="col-7">
                : {data.shelf_life || "0"} days
              </KeyVal>
            </div>
            <div className="col-4">
              <KeyVal
                label="Sold in Last 30 Days"
                labelCol="col-5"
                contentCol="col-7"
              >
                : {soldInfo.last30Days}{" "}
                <span className="fs-val-sm"> orders</span>
              </KeyVal>
              <KeyVal
                label="Sold in Last 90 Days"
                labelCol="col-5"
                contentCol="col-7"
              >
                : {soldInfo.last90Days}
                <span className="fs-val-sm"> orders</span>
              </KeyVal>
              <KeyVal
                label="Sold in Last 180 Days"
                labelCol="col-5"
                contentCol="col-7"
              >
                : {soldInfo.last180Days}
                <span className="fs-val-sm"> orders</span>
              </KeyVal>
            </div>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default ProductBasic;
