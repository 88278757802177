import React, { useState } from "react";
import CmsOverviewMenus from "./components/CmsOverviewMenus";
import CmsOverviewBrands from "./components/CmsOverviewBrands";
import CmsOverviewCategories from "./components/CmsOverviewCategories";
import { PageInfo, Tabs } from "@sk/uis";
import CmsOverviewProducts from "./components/CmsOverviewProducts";
import CmsOverviewProductDetail from "./components/product-detail/CmsOverviewProductDetail";
import { useNavigate } from "react-router-dom";

const tabData = [
  { key: "menus", tabName: "Menus" },
  { key: "categories", tabName: "Categories" },
  { key: "brands", tabName: "Brands" },
];

const styles = {
  stickyTop: {
    top: "60px",
  },
};

const breadcrumbs = [
  { name: "Home", link: "/" },
  { name: "CMS - Manage Catalog" },
];

const CmsOverview = () => {
  const [activeTab, setActiveTab] = useState(tabData[0]);

  const [selectedMenu, setSelectedMenu] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedBrand, setSelectedBrand] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});

  const navigate = useNavigate();

  const onTabCb = (data) => {
    setActiveTab(data.value);
  };

  const onMenuCb = (data) => {
    if (data.action === "select") {
      setSelectedMenu(data.value);
      setSelectedCategory({});
      setSelectedBrand({});
    }
  };

  const onCategoryCb = (data) => {
    if (data.action === "select") {
      setSelectedCategory(data.value);
      setSelectedMenu({});
      setSelectedBrand({});
    }
  };

  const onBrandCb = (data) => {
    if (data.action === "select") {
      setSelectedBrand(data.value);
      setSelectedMenu({});
      setSelectedCategory({});
    }
  };

  const onProductCb = (data) => {
    if (data.action === "select") {
      setSelectedProduct(data.value);
    }
  };

  return (
    <>
      <PageInfo
        title="CMS - Manage Catalog"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      <div className="row">
        <div
          className="col-3 position-sticky align-self-start"
          style={styles.stickyTop}
        >
          <div className="bg-white rounded">
            <div className="border-bottom">
              <Tabs
                data={tabData}
                activeTab={activeTab}
                callback={onTabCb}
                template={4}
              />
            </div>

            {activeTab?.key === "menus" && (
              <CmsOverviewMenus callback={onMenuCb} />
            )}
            {activeTab?.key === "categories" && (
              <CmsOverviewCategories callback={onCategoryCb} />
            )}
            {activeTab?.key === "brands" && (
              <CmsOverviewBrands callback={onBrandCb} />
            )}
          </div>
        </div>
        <div
          className="col-3 position-sticky align-self-start"
          style={styles.stickyTop}
        >
          <div className="bg-white rounded">
            <div className="d-flex justify-content-between align-items-center p-2 border-bottom">
              <div className="fs-val-md fw-semibold">
                {selectedBrand?.name ? (
                  <div className="d-flex align-items-center">
                    <span>Products under &quot;{selectedBrand.name}&quot;</span>
                    <button
                      onClick={() => setSelectedBrand({})}
                      className="btn btn-sm btn-link text-danger ms-2 p-0"
                    >
                      <i className="bi bi-x-circle"></i>
                    </button>
                  </div>
                ) : selectedCategory?.name ? (
                  <div className="d-flex align-items-center">
                    <span>
                      Products under &quot;{selectedCategory.name}&quot;
                    </span>
                    <button
                      onClick={() => setSelectedCategory({})}
                      className="btn btn-sm btn-link text-danger ms-2 p-0"
                    >
                      <i className="bi bi-x-circle"></i>
                    </button>
                  </div>
                ) : selectedMenu?.name ? (
                  <div className="d-flex align-items-center">
                    <span>Products under &quot;{selectedMenu.name}&quot;</span>
                    <button
                      onClick={() => setSelectedMenu({})}
                      className="btn btn-sm btn-link text-danger ms-2 p-0"
                    >
                      <i className="bi bi-x-circle"></i>
                    </button>
                  </div>
                ) : (
                  "All Products"
                )}
              </div>
            </div>
            <CmsOverviewProducts
              menuId={selectedMenu._id}
              categoryId={selectedCategory._id}
              brandId={selectedBrand._id}
              menuName={selectedMenu?.name}
              categoryName={selectedCategory?.name}
              brandName={selectedBrand?.name}
              callback={onProductCb}
            />
          </div>
        </div>
        <div className="col-6">
          <CmsOverviewProductDetail productId={selectedProduct?._id} />
        </div>
      </div>
    </>
  );
};

export default CmsOverview;
