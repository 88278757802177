import { PosService } from "@sk/services";
import {
  Alert,
  Amount,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import produce from "immer";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const tableStyle = {
  overflowY: "auto",
  maxHeight: "300px",
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

// Function to get product data
const getData = async (params) => {
  try {
    const res = await PosService.getDealsStock(params);

    const data = Array.isArray(res.resp) ? res.resp : [];
    let sortedData = [];

    if (!params.dealsort) {
      const dealIds = params.dealFilter._id.$in;
      dealIds.forEach((id) => {
        const index = data.findIndex((e) => e._id === id);
        if (index !== -1) {
          sortedData.push(data[index]);
        }
      });
    } else {
      sortedData = data;
    }

    return {
      data: sortedData,
    };
  } catch (error) {
    return { data: [] };
  }
};

// Function to get product count
const getCount = async (params) => {
  try {
    const res = await PosService.getDealsStock({
      ...params,
      showCount: true,
    });
    return { count: res.resp?.[0]?.totalSku || 0 };
  } catch (error) {
    return { count: 0 };
  }
};

// Prepare filter parameters
const prepareFilterParams = (filter, sort) => {
  const p = {
    page: 1,
    count: filter.ids.length,
    filter: {
      _id: filter.storeId,
    },
    dealFilter: {
      _id: {
        $in: filter.ids.map((p) => p._id),
      },
    },
  };

  if (sort.key) {
    p.dealsort = {
      [sort.key]: sort.value === "asc" ? 1 : -1,
    };
  }

  if (filter.categoryId) {
    p.categoryFilter = {
      categoryId: filter.categoryId,
    };
  }

  return p;
};

const mapData = (products, response) => {
  return products.map((e) => {
    const t = response.find((f) => f._id === e._id);
    return {
      ...e,
      _resp: t,
    };
  });
};

const FixedComboProductTable = ({
  categoryId,
  categoryName,
  callback,
  products,
  storeId,
  mode,
  action,
}) => {
  const [search, setSearch] = useState("");
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  // Refs for pagination and filter
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const filterDataRef = useRef({});
  const sortRef = useRef({ key: "name", value: "asc" });

  const productsLoadedRef = useRef(false);

  const headers = useMemo(() => {
    const h = [
      { label: "#", width: "3%" },
      { label: "ID", width: "10%" },
      { label: "Name", width: "25%", enableSort: true, key: "name" },
      { label: "Combo Qty", width: "10%", isCentered: true },
      { label: "MRP", width: "10%", isCentered: true },
      { label: "Price", width: "10%", isCentered: true },
      { label: "SK Landing Price", width: "10%", isCentered: true },
      { label: "Store Landing Price", width: "10%", isCentered: true },
      { label: "Stock in Store", width: "10%", isCentered: true },
      { label: "Action", width: "10%" },
    ];

    if (mode === "view") {
      h.pop();
    }

    return h;
  }, [mode]);

  // Debounced search handler
  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      filterDataRef.current.search = value; // Save search value in filterDataRef
      applyFilter(); // Call applyFilter to refresh the product list
    }, 800),
    []
  );

  // Load product list
  const loadList = useCallback(async () => {
    setLoading(true);
    setProductList([]);

    const products = filterDataRef.current.products.slice(
      (paginationRef.current.activePage - 1) *
        paginationRef.current.rowsPerPage,
      paginationRef.current.activePage * paginationRef.current.rowsPerPage
    );

    const r = await getData(
      prepareFilterParams(
        { ...filterDataRef.current, ids: products },
        sortRef.current
      )
    );

    setProductList(mapData(products, r.data));
    setHasMoreData(r.data.length < filterDataRef.current.products.length);
    setLoading(false);
  }, []);

  // Apply filter and update total records
  const applyFilter = useCallback(async () => {
    // Reset pagination
    paginationRef.current = { ...defaultPaginationConfig };

    const params = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current,
      true
    );
    const countResult = await getCount(params);

    // Update total records
    paginationRef.current.totalRecords = countResult.count;

    // Load list
    await loadList();
  }, [loadList]);

  const loadMore = async () => {
    setLoadingMore(true);
    paginationRef.current.activePage++;
    try {
      const params = prepareFilterParams(
        {
          ...filterDataRef.current,
          ids: filterDataRef.current.products.slice(
            (paginationRef.current.activePage - 1) *
              paginationRef.current.rowsPerPage,
            paginationRef.current.activePage * paginationRef.current.rowsPerPage
          ),
        },
        sortRef.current
      );
      const result = await getData(params);
      const data = mapData(
        filterDataRef.current.products.slice(
          (paginationRef.current.activePage - 1) *
            paginationRef.current.rowsPerPage,
          paginationRef.current.activePage * paginationRef.current.rowsPerPage
        ),
        result.data
      );
      setProductList(
        produce((draft) => {
          data.forEach((e) => {
            draft.push(e);
          });
        })
      );
      setHasMoreData(result.data.length >= paginationRef.current.rowsPerPage);
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    if (action === "productUpdate") {
      productsLoadedRef.current = false;
      sortRef.current = { key: "", value: "" };
    }
  }, [action]);

  // Initial load
  useEffect(() => {
    filterDataRef.current = {
      ...filterDataRef.current,
      categoryId: categoryId,
      products: products || [],
      ids: (products || []).map((p) => ({
        _id: p._id,
        comboQty: p.comboQty,
      })),
      storeId: storeId,
    };

    if (productsLoadedRef.current) {
      return;
    }

    if (categoryId && products?.length) {
      applyFilter();
      productsLoadedRef.current = true;
    } else {
      setLoading(false);
      setProductList([]);
    }
  }, [applyFilter, categoryId, products, storeId, loadList]);

  useEffect(() => {
    setProductList(
      produce((draft) => {
        draft.forEach((e) => {
          const t = products.find((p) => p._id === e._id);
          if (t) {
            e.comboQty = t.comboQty;
          }
        });
      })
    );
  }, [products]);

  const onRemoveProduct = async (index, item) => {
    const r = await Alert.confirm({
      title: "Confirm removal of product?",
      text: `This action will remove the product "${item.name}" from the combo.`,
      okText: "Remove",
      cancelText: "Cancel",
    });
    if (r.isConfirmed) {
      setProductList((prevList) => prevList.filter((_, idx) => idx !== index));
      callback({ action: "removeProduct", categoryId, productId: item._id });
    }
  };

  const handleEditClick = (product) => {
    callback({ action: "editQty", data: { product, categoryId } });
  };

  const sortCb = useCallback(
    (data) => {
      console.log(data);
      sortRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <div className="mb-3 row align-items-center">
        <div className="col fs-val-sm text-muted">
          Showing{" "}
          <span className="fw-semibold text-dark">
            {paginationRef.current.totalRecords}
          </span>{" "}
          products
        </div>
        <div className="col-4 ms-auto">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search by Name/ID"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="col-auto">
          {mode === "view" ? null : (
            <button
              className="btn btn-sm btn-outline-primary fs-val-sm"
              onClick={() =>
                callback({ action: "selectProduct", categoryId, categoryName })
              }
            >
              <i className="bi bi-plus"></i> Add Product
            </button>
          )}
        </div>
      </div>

      <div
        className="tbl-container-scroll custom-scrollbar thin-scrollbar mb-2 border rounded-3"
        style={tableStyle}
      >
        <table className="table table-border table-sm">
          <TableHeader
            data={headers}
            isSticky
            sortCb={sortCb}
            sort={sortRef.current}
          />
          <tbody className="fs-val-sm">
            {!loading && !productList.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Products Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loading ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {productList.map((item, index) => (
              <tr key={item._id}>
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>{item._id}</td>
                <td>{item.name}</td>
                <td className="text-center">
                  {item.comboQty} {item.sellInLooseQty ? "kg" : "units"}
                  {mode === "view" ? null : (
                    <button
                      className="btn btn-sm btn-link text-primary p-0 ms-2"
                      onClick={() => handleEditClick(item)}
                    >
                      <i className="bi bi-pencil"></i>
                    </button>
                  )}
                </td>
                <td className="text-center">
                  <Amount value={item._resp?.mrp || 0} />
                </td>
                <td className="text-center">
                  <Amount value={item._resp?.retailerSellingPrice || 0} />
                </td>
                <td className="text-center">
                  <Amount value={item._resp?.slc || 0} />
                </td>
                <td className="text-center">
                  <Amount value={item._resp?.b2bPrice || 0} />
                </td>
                <td className="text-center">
                  {item._resp?._qty || 0}{" "}
                  {item._resp?.sellInLooseQty ? "kg" : "units"}
                </td>
                {mode === "view" ? null : (
                  <td>
                    <button
                      className="btn btn-sm btn-link text-danger p-0"
                      onClick={() => onRemoveProduct(index, item)}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="text-center mt-3">
        {hasMoreData && !loading ? (
          <button
            onClick={loadMore}
            disabled={loadingMore}
            className="btn btn-sm btn-light fs-val-sm"
          >
            {loadingMore
              ? "Loading..."
              : `Load More (${productList.length} / ${paginationRef.current.totalRecords})`}
          </button>
        ) : null}
      </div>
    </>
  );
};

export default FixedComboProductTable;
