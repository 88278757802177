import { DealService, PosService } from "@sk/services";
import { Amount, AppCard, KeyVal } from "@sk/uis";
import { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import FixedComboCloneTable from "../../components/FixedComboCloneTable";

const canvasStyle = {
  width: "60%", // Set width to 60%
};

const FixedComboCloneModal = ({ show = false, callback, configId }) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});

  const selectedStoreId = useRef("");

  useEffect(() => {
    if (show && configId) {
      const fetchData = async () => {
        setLoading(true);
        const res = await DealService.getFixedCombo({
          filter: {
            _id: configId,
          },
        });
        if (res.statusCode === 200) {
          const d = res.resp?.[0] || {};
          setDetails(d);
        } else {
          setDetails({});
        }
        setLoading(false);
      };
      fetchData();
    }
  }, [show, configId]);

  const closeModal = () => {
    callback({ action: "close" });
  };

  const extractDealIdsByCategory = () => {
    return details.cartDetails.map((cart) => ({
      category: cart.category,
      dealIds: cart.dealsList.map((deal) => deal.dealId),
    }));
  };

  const prepareFilterParams = (dealIds) => {
    return {
      dealId: { $in: dealIds },
      page: 1,
      count: dealIds.length,
      filter: {
        _id: selectedStoreId.current,
      },
    };
  };

  const checkDealsExistRecursive = async (dealIds, index = 0, results = []) => {
    if (index >= dealIds.length) return results;

    const batch = dealIds.slice(index, index + 20);
    const params = prepareFilterParams(batch);
    const response = await PosService.getDealsStock(params);

    if (response.statusCode === 200) {
      results.push(...response.resp);
    }

    return checkDealsExistRecursive(dealIds, index + 20, results);
  };

  const handleCloneTableCallback = async (e) => {
    selectedStoreId.current = e.data._id;

    const categories = extractDealIdsByCategory();

    for (const category of categories) {
      const results = await checkDealsExistRecursive(category.dealIds);
      const missingDeals = category.dealIds.filter(
        (id) => !results.some((result) => result.dealId === id)
      );

      // Handle missing deals for this category
      console.log(
        `Missing deals for category ${category.category.name}:`,
        missingDeals
      );
    }

    // callback({ action: "checkDeals", success: true });
  };

  return (
    <Offcanvas
      show={show}
      onHide={closeModal}
      placement="end"
      style={canvasStyle}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Clone Fixed Combo</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <AppCard>
            <div className="row">
              <div className="col">
                <KeyVal label="Store" template="col">
                  {details.franchiseName}
                </KeyVal>
              </div>
              <div className="col">
                <KeyVal label="Title" template="col">
                  {details.title}
                </KeyVal>
              </div>
              <div className="col-2">
                <KeyVal label="Combo Price (₹)" template="col">
                  <Amount value={details.offerAmount} />
                </KeyVal>
              </div>
            </div>
            <FixedComboCloneTable callback={handleCloneTableCallback} />
          </AppCard>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FixedComboCloneModal;
