import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, CommonService, WarehouseService } from "@sk/services";
import { AppCard, PaginationSummary } from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { detailsView } from "../../../constantService";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = detailsView.tabs.stockLedger.pagination;

const formLabelsConfig = detailsView.tabs.stockLedger.formLabels;

const attachAdditionalDataConfig =
  detailsView.tabs.stockLedger.additionalTableDataConfig;

const defaultFormData = {
  reference: "",
  warehouse: AuthService.getDefaultWhId(),
  createdAt: [],
  referenceType: "",
  type: "",
};

const defaultSortOpt = { key: "mrp", value: "desc" };

const prepareFilterParams = (
  pagination = {},
  filter = {},
  sort = {},
  additionalData = {}
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      productId: additionalData.id,
    },
  };

  if (filter.warehouse) {
    p.filter.whId = filter.warehouse;
  }

  if (filter.reference.trim()) {
    p.filter.reference = filter.reference;
  }

  if (filter.referenceType) {
    p.filter.referenceType = filter.referenceType;
  }
  if (filter.type) {
    p.filter.type = filter.type;
  }

  if (filter.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter?.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  return p;
};

const getData = async (params) => {
  const r = await WarehouseService.stockTransactionSummaryList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.warehouseLoading = true;
        return x;
      })
    : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await WarehouseService.stockTransactionSummaryCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const StockLedger = ({ pId }) => {
  //  It will Have Stock Ledger Data
  const [data, setData] = useState([]);

  //  To Store Pagination Data
  const paginationRef = useRef({ ...defaultPageOpt });

  //  To Store sort Data
  const sortRef = useRef({ ...defaultSortOpt });

  // To Store Filter Data
  const filterDataRef = useRef({ ...defaultFormData });

  //  To Store Applied Filter Data
  const [filterLabels, setFilterLabels] = useState([]);

  //  Loading State While Fetching Data
  const [loadingData, setLoadingData] = useState(true);

  //  Loading State While Fetching Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  //  Custom Hook To attach Additional Info
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (pId) {
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  //  To Get Prepared Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      { id: pId }
    );
  }, [pId]);

  // To Fetch And Update Stock Ledger
  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(params);
    // To attach Additional Info
    // d.forEach((e) => {
    //   e.createdBy = "621f9a63e12f2834da1eb424";
    // });
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  //  To apply Filter and Fetch Stock Ledger
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };
    if (pId) {
      loadList();
      prepareFilterLabels();

      // for total records
      setLoadingTotalRecords(true);
      const p = getFilterParams();

      const c = await getCount(p);
      paginationRef.current.totalRecords = c.count;
      setLoadingTotalRecords(false);
    }
  }, [getFilterParams, loadList, pId]);

  //  To Change Pagination Count and Loading List
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  //  To get Filter Form Data and Applying Filter
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = { ...data.formData };
      applyFilter();
    },
    [applyFilter]
  );

  //  To Update Sorting Data and Applying Filter
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelsConfig,
      filterDataRef.current || {}
    );
    setFilterLabels(l);
  };

  return (
    <>
      {/* Filter Form */}
      <AppCard>
        <Filter callback={filterFormCb} />
      </AppCard>
      {/* 
      {filterLabels.length > 0 ? (
        <div className="mb-2">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null} */}

      {/* PAGINATION SUMMARY */}
      <AppCard>
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>

        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          sort={sortRef.current}
          sortCb={sortCb}
        />
      </AppCard>
    </>
  );
};

export default StockLedger;
