import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  BtnLink,
} from "@sk/uis";
import { NavService } from "@sk/services";
import { memo, useState } from "react";
import GrnPoModal from "../modals/GrnPoModal";

const headers = [
  { label: "Sl.No.", width: "3%" },
  { label: "GRN ID", width: "10%" },
  { label: "PO ID", width: "8%" },
  { label: "Vendor", width: "20%" },
  { label: "Warehouse", width: "15%" },
  { label: "Received Qty", width: "6%", isCentered: true },
  { label: "Invoice Qty", width: "6%", isCentered: true },
  { label: "Invoice Value", width: "8%", isCentered: true },
  { label: "Status", width: "10%" },
  { label: "Created On", width: "10%" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const [grnModal, setGrnModal] = useState({
    show: false,
    poIds: [],
    grnId: "",
  });

  const handleGrnPoModalCallback = () => {
    setGrnModal({ show: false, poIds: [], grnId: "" });
  };

  const viewVendorDetails = (vendorId) => {
    NavService.openInNewTab("/vendor/view", {
      id: vendorId,
    });
  };

  return (
    <>
      <table className="table table-sm">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody className="fs-val-md">
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {data.map((x, index) => (
            <tr key={x._id}>
              <td>{paginationConfig.startSlNo + index}</td>
              <td>
                <BtnLink
                  className="fs-val-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    NavService.openInNewTab("/purchase-order/grn/view", {
                      id: x._id,
                    });
                  }}
                >
                  {x._id}
                </BtnLink>
              </td>
              <td>
                {x._poIds?.length > 0 ? (
                  <>
                    <div>{x._poIds[0]}</div>
                    {x._poIds.length > 1 ? (
                      <button
                        className="btn btn-link p-0 text-start fs-val-xs"
                        onClick={() =>
                          setGrnModal({
                            show: true,
                            poIds: x._poIds,
                            grnId: x._id,
                          })
                        }
                      >
                        View{" "}
                        {x._poIds.length > 1 ? `+${x._poIds.length - 1}` : null}
                      </button>
                    ) : null}
                  </>
                ) : null}
              </td>
              <td>
                <BtnLink
                  className="fs-val-md mb-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    viewVendorDetails(x.vendorId);
                  }}
                >
                  {x.vendor?.name}
                </BtnLink>
                <div className="text-muted fs-val-sm">ID: {x.vendorId}</div>
              </td>

              <td>{x.whAddress.name}</td>
              <td className="text-center">{x.receivedQuantity}</td>
              <td className="text-center">{x.invoiceQuantity}</td>
              <td className="text-center">
                <Amount value={x.invoiceValue} />
              </td>
              <td>
                <HighlightText template={2} type={x.statusColor}>
                  {x.status}
                </HighlightText>
              </td>
              <td>
                <DateFormatter date={x.createdAt} />
                {x.userLoading ? (
                  <Spinner type="dots" />
                ) : (
                  <div className="fs-val-sm text-primary">
                    by {x?._createdBy?.name || x?.createdBy}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />

      <GrnPoModal
        callback={handleGrnPoModalCallback}
        grnId={grnModal.grnId}
        poids={grnModal.poIds}
        show={grnModal.show}
      />
    </>
  );
};

export default memo(Table);
