import { DealService } from "@sk/services";
import { Amount, BusyLoader, SelectInput, TextInput, Toaster } from "@sk/uis";
import produce from "immer";
import { memo, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";

// const formKeys = ["mrp", "price"];
const labelSizeOptions = [{ label: "3*8 (24 label in A4 sheet)", value: "" }];
const defaultValues = {
  productName: "",
  unitOfMeasurement: "kg",
  unit: 1,
  _id: "",
  mrp: "",
  price: "",
  barcode: 12456789,
};
const PrintLabelModal = ({
  show,
  closePrintModal,
  id,
  printHideCb,
  barcodeVal,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      labelCount: 1,
    },
  });
  const [busyLoader, setBusyLoader] = useState({ message: "", loading: false });
  const [details, setDetails] = useState(defaultValues);

  useEffect(() => {
    if (id) {
      getBarcodeDetails();
    }
    setValue("labelCount", 1);
  }, [id, show]);

  // const closeModal = () => {
  //   setValue("labelCount", 1);
  //   closePrintModal();
  // };

  const getBarcodeDetails = async () => {
    const r = await DealService.getBarcodeDetails(id);
    if (r.statusCode !== 200) {
      Toaster.error("Something went wrong Please Try again");
      return;
    }
    setDetails(
      produce((draft) => {
        for (let key in draft) {
          draft[key] = r.resp[key];
        }
      })
    );
  };

  const onSubmit = (data) => {
    let f = getValues("labelCount");
    let msg = "";
    if (!f) {
      msg = "Please Enter No. of Label Count";
    } else if (f < 1) {
      msg = "Minimum No. of Label Count should be 1";
    } else if (f > 100) {
      msg = "Maximum No. of Label Count should be 100";
    }
    if (msg) {
      Toaster.error(msg);
      return;
    }

    doPrint(data.labelCount);
  };

  const doPrint = (labelCount) => {
    let body = "";
    const d = { ...details, labelCount };

    //print input render cells

    for (let i = 0; i < d.labelCount; i++) {
      body += `
            <div class="label">
            <div style="text-align: center; font-weight: 600; font-size: 16px; margin-top:10px ;text-overflow:ellipsis;">
              ${d.productName}
            </div>
            <div style="text-align: center; font-size: 12px">
               ${d.unit} ${d.unitOfMeasurement} | MRP : ${d.mrp} /-
            </div>
            <div style="text-align: center; font-weight: 600; font-size: 18px">
              Offer Price : ${d.price}/-
            </div>
            <div style="text-align: center">
              <svg class="barcode"></svg>
            </div>
            </div>
        `;
    }

    let t = `<!DOCTYPE html>
    <html>
    <head>
        <style>
            @page {
                size: A4;
                margin: 0;
                padding-top: 0;          
            }
            
            body {
                margin: 0;
                padding: 6.5mm 6.5mm;
                /* padding-top: 3mm; */
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 2.5mm 0;
            }
            
            .label {
                width: 64mm;
                height: 33.9mm;
                border: 1px solid black;
                border-radius: 1.5mm;
                box-sizing: border-box;
            }
          
          @media print {
            button {
              display: none;
            }
          }
            
          
        </style>
    </head>
    <body>
    ${body}
    <script
      src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.5/dist/JsBarcode.all.min.js"
    ></script>
    <script>
        window.onload = () => {
            JsBarcode(".barcode", '${barcodeVal}', {                
                displayValue: false,
                height: 20,
            });
            const x = setTimeout(() => {
                window.print();
                clearTimeout(x);
            }, 1000);
        };
    </script>
    </body>
    </html>`;

    const w = window.open("", "Label_Print");
    w.document.write(t);
    w.document.close();
    const timer = setTimeout(() => {
      clearTimeout(timer);
      w.window.close();
    }, 1000);
  };

  if (!id) {
    return <></>;
  }

  const labelCountCb = () => {
    let f = getValues("labelCount");
    if (f) {
      setValue("labelCount", Math.round(getValues("labelCount")));
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={printHideCb}
        placement="end"
        style={{ width: "40%" }}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <Offcanvas.Title>
            <div className="page-title">Print Label</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3 border-top">
          <div>
            <div className="row">
              <div className="col-12">
                <div className="text-muted">Deal Name</div>
                <div className="fw-bold fs-5">{details.productName}</div>
              </div>
            </div>
            <div className="row align-items-center mt-3 mb-4">
              <div className="col-3">
                Quantity :
                <span className="text-primary font-14 ps-2">
                  {details.unit}
                  {details.unitOfMeasurement}
                </span>
              </div>
              <div className="col-3 ps-2">
                MRP :
                <span className="text-primary font-14 ps-2">
                  <Amount value={details.mrp} decimalPlace="2" />
                </span>
              </div>
              <div className="col-3 ps-2">
                Price :
                <span className="text-primary font-14 ps-2">
                  <Amount value={details.price} decimalPlace="2" />
                </span>
              </div>
            </div>
          </div>
          <div className="pt-4 border-top">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-3">
                  <TextInput
                    label="No of Labels to print"
                    error={errors?.labelCount?.message}
                    isMandatory={true}
                    register={register}
                    name="labelCount"
                    type="number"
                    callback={labelCountCb}
                  />
                </div>
                <div className="col-9">
                  <SelectInput
                    label="Label Size"
                    error={errors?.labelSize?.message}
                    isMandatory={true}
                    register={register}
                    name="labelSize"
                    callback={labelCountCb}
                    options={labelSizeOptions}
                  />
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-auto me-2">
                  <button type="submit" className="btn  btn-primary">
                    Print
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <BusyLoader
        message={busyLoader.message}
        show={busyLoader.loading}
      ></BusyLoader>
    </>
  );
};

export default memo(PrintLabelModal);
