import { ProductService, CategoryService, BrandService } from "@sk/services";
import {
  Amount,
  AppCard,
  HighlightText,
  NoDataFound,
  Spinner,
  TableHeader,
  BtnLink,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { NavService } from "@sk/services";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "SKU Code", width: "15%" },
  { label: "Name", width: "25%" },
  { label: "Product ID", width: "10%" },
  { label: "Status", width: "10%" },
  { label: "Brand", width: "10%" },
  { label: "Category", width: "10%" },
  { label: "Quantity", width: "8%" },
  { label: "MRP", width: "8%" },
  { label: "Dealer Price", width: "8%" },
  { label: "Length", width: "6%" },
  { label: "Width", width: "6%" },
  { label: "Height", width: "6%" },
  { label: "Weight", width: "6%" },
  { label: "Country of Origin", width: "15%" },
  { label: "Allow ReserveOrder", width: "15%" },
];

const tableStyle = {
  minWidth: "2000px",
};

const ViewDealProducts = ({ products }) => {
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const ids = (products || []).map((e) => e.id);
    const loadList = async (ids) => {
      const p = {
        id: ids.join(),
        select:
          "name,skuCode,is_sourcable,measure,mrp,quantity,dealer_price,is_active,allowReserveOrder,brand,category,status",
      };

      setLoader(true);
      const m = await ProductService.getBulkProduct(p);
      const d = (Array.isArray(m.resp) ? m.resp : []).map((e) => {
        const details = (products || []).find((k) => k.id === e._id);
        return { ...e, _raw: details };
      });

      // Collect category and brand IDs
      const categoryIds = [
        ...new Set(d.flatMap((product) => product.category)),
      ];
      const brandIds = [...new Set(d.flatMap((product) => product.brand))];

      // Fetch category and brand data
      const [categoryData, brandData] = await Promise.all([
        CategoryService.getList({
          page: 1,
          count: categoryIds.length,
          filter: { _id: { $in: categoryIds } },
        }),
        BrandService.getList({
          page: 1,
          count: brandIds.length,
          filter: { _id: { $in: brandIds } },
        }),
      ]);

      // Attach category and brand details to products
      const categoryMap = Object.fromEntries(
        categoryData.resp.map((cat) => [cat._id, cat.name])
      );
      const brandMap = Object.fromEntries(
        brandData.resp.map((brand) => [brand._id, brand.name])
      );

      const updatedList = d.map((product) => ({
        ...product,
        categoryName: categoryMap[product.category[0]] || "--",
        brandName: brandMap[product.brand[0]] || "--",
      }));

      setList(updatedList);
      setLoader(false);
    };

    if (ids.length) {
      loadList(ids);
    }
  }, [products]);

  return (
    <AppCard>
      <div className="fs-val-md mb-2">
        Showing <b>{list.length}</b> products
      </div>

      <div className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table">
        <table
          className="table table-sm mb-0 bg-white table-hover"
          style={tableStyle}
        >
          <TableHeader data={headers} />
          <tbody>
            {loader && (
              <tr>
                <td colSpan={headers.length}>
                  <div className="text-center">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
            {!loader && !list.length && (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            )}
            {list.map((x, index) => (
              <tr key={x._id} className="bg-white fs-val-md">
                <td>{index + 1}</td>
                <td>{x?.skuCode}</td>
                <td>
                  <BtnLink
                    onClick={() =>
                      NavService.openInNewTab(`/cms/product/view`, {
                        id: x._id,
                      })
                    }
                  >
                    <div className="text-wrap">{x?.name}</div>
                  </BtnLink>
                </td>
                <td>{x._id}</td>
                <td>
                  <HighlightText status={x.active ? "Active" : "Inactive"} />
                </td>
                <td>{x.brandName}</td>
                <td>{x.categoryName}</td>
                <td>{x._raw?.quantity || "0"}</td>
                <td>
                  <Amount value={x?.mrp} decimalPlace="2" />
                </td>
                <td>
                  <Amount value={x?.dealer_price} decimalPlace="2" />
                </td>
                <td>{x?.measure?.length || "NA"}</td>
                <td>{x?.measure?.width || "NA"}</td>
                <td>{x?.measure?.height || "NA"}</td>
                <td>{x?.measure?.weight || "NA"}</td>
                <td>{x?._raw?.originCountry || "NA"}</td>
                <td>
                  <HighlightText status={x.allowReserveOrder ? "Yes" : "No"} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

export default ViewDealProducts;
