import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import each from "lodash/each";
import orderBy from "lodash/orderBy";
import { memo, useCallback, useEffect, useState } from "react";
import ViewVendorModal from "../modals/ViewVendorModal";
import ProductCount from "./ProductCount";

const rbac = {
  viewButton: ["ViewBrand"],
  editButton: ["EditBrand"],
};

const defaultHeaders = [
  { label: "Sl No", width: "5%", isCentered: true, priority: 1 },
  { label: "Brand ID", priority: 2 },
  { label: "Brand Name", priority: 2, width: "17%" },
  { label: "Linked Product", isCentered: true, priority: 3 },
  { label: "Master Brand ID", priority: 8, width: "17%" },
  { label: "Master Brand Name", priority: 8, width: "17%" },
  { label: "Active Vendors", isCentered: true, priority: 9 },
  { label: "Status", isCentered: true, priority: 10 },
  { label: "Actions", isCentered: true, priority: 12, width: "12%" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editCb,
  sort,
  sortCb,
  moreColumns = {},
}) => {
  // To Show Vendor Details
  const [showViewVendorModal, setShowViewVendorModal] = useState(false);

  // To Set Brand Id for Vendor details
  const [brandId, setBrandId] = useState("");

  //Headers
  const [headers, setHeaders] = useState([...defaultHeaders]);

  // To Open View Vendor Modal
  const openViewVendorModal = useCallback((id) => {
    setShowViewVendorModal(true);
    setBrandId(id);
  }, []);

  // To Close View Vendor Modal
  const closeViewVendorModal = useCallback(() => {
    setShowViewVendorModal(false);
    setBrandId("");
  }, []);

  // To Navigate to View Page
  const viewBrand = (id) => {
    NavService.openInNewTab("/cms/brand/view", { id: id });
  };

  useEffect(() => {
    let t = [];
    each(moreColumns || {}, (x) => {
      t.push(x);
    });
    setHeaders(orderBy([...defaultHeaders, ...t], ["priority"], ["asc"]));
  }, [moreColumns]);

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Brand Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {/* When Not Loading and Data is there  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* Serial Number */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* Name */}
                <td>
                  <div
                    className="text-primary"
                    onClick={() => {
                      viewBrand(x?._id);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {x?._id}
                  </div>
                </td>

                {/* Name */}
                <td>
                  <div
                    className="text-primary"
                    onClick={() => {
                      viewBrand(x?._id);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {x?.name || "N/A"}
                  </div>
                </td>

                {/* Linked Products */}
                <td className="text-center  text-primary cursor-pointer ">
                  <ProductCount brandId={x._id} />
                </td>

                {/* Active Product */}
                {moreColumns?.activeProduct?.checked && (
                  <td className="text-center text-success">
                    <ProductCount brandId={x._id} type="active" />
                  </td>
                )}

                {/* InActive Product */}
                {moreColumns?.inactiveProduct?.checked && (
                  <td className="text-center text-danger">
                    <ProductCount brandId={x._id} type="inactive" />
                  </td>
                )}

                {/* Is Value Brand */}
                {moreColumns?.isValueBrand?.checked && (
                  <td className="text-center">
                    <HighlightText
                      status={x.isValueBrand ? "YES" : "NO"}
                      isBadge={false}
                    />
                  </td>
                )}
                {/* Is Master */}
                {moreColumns?.isMasterBrand?.checked && (
                  <td className="text-center">
                    <HighlightText
                      status={x.masterData?.isMaster ? "YES" : "NO"}
                      isBadge={false}
                    />
                  </td>
                )}

                {/* Master Brand ID*/}
                <td>
                  <div>
                    {x?.masterData?.masterId ? (
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          viewBrand(x?.masterData?.masterId);
                        }}
                      >
                        {x?.masterData?.masterId}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </td>

                {/* Master Brand Name*/}
                <td>
                  <div>
                    {x.masterBrandLoading ? (
                      <Spinner type="dots" />
                    ) : x?._masterData?.name ? (
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          viewBrand(x?._masterData?._id);
                        }}
                      >
                        {" "}
                        {x?._masterData?.name}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </td>

                {/* Active Vendors */}
                <td className="text-center">
                  <button
                    className="btn btn-link fs-val-sm"
                    type="button"
                    onClick={() => openViewVendorModal(x._id)}
                  >
                    View Vendors
                  </button>
                </td>

                {/* Status */}
                <td className="text-center">
                  <HighlightText status={x.status} isBadge={false} />
                </td>

                {/* Created At */}

                {moreColumns?.createdAt?.checked && (
                  <td>
                    <DateFormatter date={x.createdAt} />
                    <div>
                      By :{" "}
                      {x.userLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        x._createdBy.name || x.createdBy || "N/A"
                      )}
                    </div>
                  </td>
                )}

                {/* Action Buttons */}
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => viewBrand(x._id)}
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.editButton}>
                    <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-xs"
                      onClick={() => editCb(x?._id)}
                    >
                      Edit
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      {/* View Vendor Modal */}
      <ViewVendorModal
        show={showViewVendorModal}
        closeModal={closeViewVendorModal}
        brandId={brandId}
      />
    </>
  );
};

export default memo(Table);
