import { SelectInput, TextInput } from "@sk/uis";
import { useFormContext } from "react-hook-form";
import { manageView } from "../../../constantService";

const packagingTypeOptions = [
  { label: "Select", value: "" },
  { label: "Jar", value: "Jar" },
  { label: "Pouch", value: "Pouch" },
  { label: "Standy Pouch", value: "Standy Pouch" },
  { label: "Carton", value: "Carton" },
  { label: "Tin", value: "Tin" },
  { label: "PET Bottle", value: "Pet Bottle" },
];

const UnitDimension = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="row  p-1">
      <div className="col-3">
        <TextInput
          type="number"
          label="Length(cm)"
          error={errors?.length?.message}
          name="length"
          isMandatory={false}
          register={register}
          info={manageView.infoContext.length}
        />
      </div>
      <div className="col-3">
        <TextInput
          type="number"
          label="Breadth (cm)"
          error={errors?.breadth?.message}
          name="breadth"
          isMandatory={false}
          register={register}
          info={manageView.infoContext.breadth}
        />
      </div>
      <div className="col-3">
        <TextInput
          type="number"
          label="Height(cm)"
          error={errors?.height?.message}
          name="height"
          isMandatory={false}
          register={register}
          info={manageView.infoContext.height}
        />
      </div>
      <div className="col-3">
        <TextInput
          type="number"
          label="Weight(gm)"
          error={errors?.weight?.message}
          name="weight"
          // isMandatory={true}
          register={register}
          info={manageView.infoContext.weight}
        />
      </div>

      <div className="col-3">
        <TextInput
          type="number"
          label="Case Quantity"
          register={register}
          name="caseQty"
          error={errors.caseQty?.message}
          isMandatory={true}
          info={manageView.infoContext.caseQty}
        />
      </div>
      <div className="col-3">
        <TextInput
          type="number"
          label="Inner Pack Quantity"
          register={register}
          name="innerPackQty"
          error={errors.innerPackQty?.message}
          isMandatory={true}
          info={manageView.infoContext.innerPackQty}
        />
      </div>
      {/* Packaging Type */}
      <div className="col-3">
        <SelectInput
          label="Packaging Type"
          error={errors?.packagingType?.message}
          placeholder="Enter Here"
          name="packagingType"
          isMandatory={false}
          options={packagingTypeOptions}
          register={register}
          info={manageView.infoContext.packagingType}
        />
      </div>

      {/* UOM */}
      <div className="col-3">
        <TextInput
          type="text"
          label="UOM"
          error={errors?.UoM?.message}
          name="UoM"
          placeholder="Enter Unit of Measurement Here "
          // isMandatory={true}
          register={register}
          info={manageView.infoContext.uom}
        />
      </div>

      {/* Shelf Life AKA Expiry Days */}
      <div className="col-3">
        <TextInput
          type="number"
          label="Shelf Life (days)"
          error={errors?.shelf_life?.message}
          name="shelf_life"
          isMandatory={true}
          placeholder="Enter Shelf Life here in Days"
          register={register}
          info={manageView.infoContext.shelfLife}
        />
      </div>

      {/* PackSize  */}
      <div className="col-3">
        <TextInput
          label="Pack Size"
          register={register}
          name="packSize"
          isMandatory={false}
          error={errors?.packSize?.message}
          placeholder="Enter Pack size Here"
          type="number"
        />
      </div>
      {/* KVI Status */}
      <div className="col-3">
        <TextInput
          type="text"
          label="KVI Status"
          name="brandKVIStatus"
          placeholder="Enter KVI Status Here"
          isMandatory={false}
          register={register}
          info={manageView.infoContext.brandKVIStatus}
        />
      </div>
    </div>
  );
};

export default UnitDimension;
