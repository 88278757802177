import { FranchiseService } from "@sk/services";
import { EntitySearchInput } from "@sk/uis";
import { memo } from "react";
import { Controller, useForm } from "react-hook-form";
const defaultFormValues = {
  deal: "",
  franchise: [],
};

const franchiseSearchFilter = FranchiseService.getDarkstoreFilter();

const Filter = ({ callback, openAdvFilterModal }) => {
  const { getValues, control } = useForm({
    defaultValues: defaultFormValues,
  });
  const onSearch = (changFn) => (val) => {
    changFn(val);
    callback({ formData: getValues() });
  };

  return (
    <div className="row">
      <div className="col-4 me-1">
        <Controller
          control={control}
          name={"deal"}
          key="deal"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              type={"deal"}
              placeholder="Search By Deal Name / ID"
              isMandatory={true}
              value={value}
              callback={onSearch(onChange)}
              uid="deal-search"
              isMultiple={false}
              emptyLabel="No Deal Found"
              gap={0}
            />
          )}
        />
      </div>
      <div className="col-4 ">
        <Controller
          control={control}
          name="franchise"
          render={({ field: { onChange, value } }) => (
            <EntitySearchInput
              type="franchise"
              placeholder="Search by Smart Store By Name/ID"
              value={value}
              callback={onSearch(onChange)}
              uid="franchise"
              isMultiple={false}
              isMandatory={true}
              filterParams={franchiseSearchFilter}
              emptyLabel="No Smart Stores found"
              gap={0}
            />
          )}
        />
      </div>
      <div className="col-auto">
        <div className="col-auto">
          <button
            className="btn app-filter-btn"
            type="button"
            onClick={openAdvFilterModal}
          >
            <i className="bi bi-funnel"></i> FILTER
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(Filter);
