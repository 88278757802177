import { ProductService } from "@sk/services";
import {
  Amount,
  HighlightText,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import ManageBarcodeModal from "./modal/ManageBarcodeModal";

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const tableHeader = [
  { label: "Sl.No" },
  { label: "ID" },
  { label: "Barcode" },
  { label: "MRP" },
  { label: "Status" },
  { label: "Action" },
];

const getData = async (params) => {
  const r = await ProductService.getMasterBarcodeList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const MasterBarcode = ({ pId }) => {
  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const manageBarcodeModalRef = useRef({ data: "", mode: "" });

  const [showMangeBarcodeModal, setShowManageBarcodeModal] = useState(false);

  useEffect(() => {
    if (pId) {
      loadBarcodeData();
    } else {
      setLoadingData(false);
    }
  }, [pId, loadBarcodeData]);

  const loadBarcodeData = useCallback(async () => {
    setLoadingData(true);

    const p = {
      count: 10,
      page: 1,
      filter: { productId: pId },
    };
    const r = await getData(p);
    setData(r);
    setLoadingData(false);
  }, [pId]);

  const openManageBarcodeModal = useCallback((mode, data) => {
    manageBarcodeModalRef.current.mode = mode;

    if (mode == "edit") {
      manageBarcodeModalRef.current.data = data;
    }
    setShowManageBarcodeModal(true);
  }, []);

  const closeManageBarcodeModal = useCallback(
    ({ status }) => {
      if (status == "submit") {
        loadBarcodeData();
      }
      manageBarcodeModalRef.current = { mode: "", data: "" };
      setShowManageBarcodeModal(false);
    },
    [loadBarcodeData]
  );

  return (
    <>
      <div className="text-end">
        <button
          className="btn btn-primary btn-sm fs-val-md my-2"
          onClick={() => {
            openManageBarcodeModal("add");
          }}
        >
          Add Barcode
        </button>
      </div>
      <table className="table table-bordered bg-white">
        <TableHeader data={tableHeader} />
        <tbody>
          {/* When Data is Loading  */}
          {loadingData && (
            <TableSkeletonLoader
              row={defaultPageOpt.rowsPerPage}
              cols={tableHeader.length}
            />
          )}

          {!loadingData && !data.length ? (
            <tr>
              <td colSpan={tableHeader.length}>
                <NoDataFound> Barcode Details Not Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loadingData &&
            data.map((x, i) => (
              <tr key={i} className="fs-val-md">
                <td>{i + 1}</td>
                <td>{x._id}</td>
                <td>{x.productBarcode}</td>
                <td>
                  <Amount value={x.mrp} decimalPlace={2} />
                </td>
                <td>
                  <HighlightText status={x.status} isBadge={false} />
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-warning fs-val-md"
                    onClick={() => openManageBarcodeModal("edit", x)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <ManageBarcodeModal
        closeModal={closeManageBarcodeModal}
        mode={manageBarcodeModalRef.current.mode}
        data={manageBarcodeModalRef.current.data}
        pId={pId}
        show={showMangeBarcodeModal}
      />
    </>
  );
};

export default memo(MasterBarcode);
