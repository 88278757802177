import { BrandService, CategoryService, NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  BtnLink,
  HighlightText,
  ImgRender,
  KeyVal,
} from "@sk/uis";
import { useEffect, useState } from "react";

const ViewDealMainInfo = ({ data }) => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const d = await getData(data?.category || [], data?.brand || []);
      setCategories(d.categories);
      setBrands(d.brands);
      setLoading(false);
    };

    if (data.category?.length > 0 || data.brand?.length > 0) {
      fetchData();
    }
  }, [data.category, data.brand]);

  const viewCategory = (id) => {
    NavService.openInNewTab("/cms/category/view", {
      id,
    });
  };

  const viewBrand = (id) => {
    NavService.openInNewTab("/cms/brand/view", {
      id,
    });
  };

  return (
    <AppCard>
      <div className="row">
        <div className="col-auto align-self-center">
          <ImgRender
            assetId={data.images[0]}
            width="120"
            height="120"
            className="border rounded-3"
          />
        </div>
        <div className="col">
          <div className="d-flex gap-2 mb-3 align-items-center">
            <div className="fs-val-lg fw-bold">{data.name}</div>
            <div>
              <span className="border rounded px-1 px-2 fs-val-sm">
                {data._id}
              </span>
            </div>
            <div>
              <HighlightText
                template={2}
                type={data.status === "Publish" ? "success" : "danger"}
              >
                {data.status}
              </HighlightText>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <KeyVal label="MRP" labelCol="col-4" contentCol="col-8">
                : <Amount value={data.mrp} />
              </KeyVal>
              <KeyVal label="B2B Price" labelCol="col-4" contentCol="col-8">
                : <Amount value={data.b2bPrice} />
              </KeyVal>
              <KeyVal label="Is Active" labelCol="col-4" contentCol="col-8">
                :{" "}
                <HighlightText
                  template={2}
                  type={data.is_active ? "success" : "danger"}
                >
                  {data.is_active ? "Active" : "Inactive"}
                </HighlightText>
              </KeyVal>
            </div>
            <div className="col-4">
              <KeyVal label="Category" labelCol="col-4" contentCol="col-8">
                :{" "}
                {categories.length
                  ? categories.map((c) => (
                      <BtnLink key={c._id} onClick={() => viewCategory(c._id)}>
                        {c.name}
                      </BtnLink>
                    ))
                  : "NA"}
              </KeyVal>
              <KeyVal label="Brand" labelCol="col-4" contentCol="col-8">
                :{" "}
                {brands.length
                  ? brands.map((b) => (
                      <BtnLink key={b._id} onClick={() => viewBrand(b._id)}>
                        {b.name}
                      </BtnLink>
                    ))
                  : "NA"}
              </KeyVal>
              <KeyVal
                label="Marketplace Seller"
                labelCol="col-4"
                contentCol="col-8"
              >
                : {data?.sourcingPartner || "NA"}
              </KeyVal>
            </div>
            <div className="col-4">
              <KeyVal
                label="Is Content Valid :"
                labelCol="col-5"
                contentCol="col-7"
              >
                : {data.isContentValidated ? "Yes" : "No"}
              </KeyVal>
              <KeyVal
                label="Display Deal on MRP :"
                labelCol="col-5"
                contentCol="col-7"
              >
                : {data.display ? "Yes" : "No"}
              </KeyVal>
            </div>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

const getData = async (category, brand) => {
  let brands = [];
  let categories = [];

  if (category?.length > 0) {
    const catResp = await CategoryService.getList({
      page: 1,
      count: 100,
      filter: {
        _id: { $in: [category] },
      },
      select: "name",
    });
    categories = catResp.resp;
  }

  if (brand?.length > 0) {
    const brandResp = await BrandService.getList({
      page: 1,
      count: 100,
      filter: {
        _id: { $in: [brand] },
      },
      select: "name",
    });
    brands = brandResp.resp;
  }

  return { categories, brands };
};

export default ViewDealMainInfo;
