import { DealService } from "@sk/services";
import { BusyLoader, Spinner, TableHeader } from "@sk/uis";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const modalHeaders = [
  { label: "Sl No", width: "5%" },
  { label: "Name" },
  { label: "Linked Quantity", width: "20%" },
  { label: "status" },
];

const FetchDisplayDealCount = ({ params, pid }) => {
  const [data, setData] = useState({ count: 0, loading: true });
  const [dealList, setDealList] = useState([]);

  const [showDealListModal, setShowDealListModal] = useState(false);
  const [showModalLoader, setShowModalLoader] = useState(false);

  const closeDealListModal = () => {
    setShowDealListModal(false);
  };

  useEffect(() => {
    const loadDealCount = async () => {
      let m = await DealService.getDealsCount(params);
      setData({ count: m.statusCode == 200 ? m.resp : 0, loading: false });
    };
    loadDealCount();
  }, []);

  const onClickViewDealList = async () => {
    if (data.count > 0) {
      let p = {
        select: "name,memberPrice,product,status",
        filter: { product: { $elemMatch: { id: pid } }, dealsFor: "erp" },
      };
      setShowModalLoader(true);
      let m = await DealService.getDeals(p);
      setShowModalLoader(false);
      let s = await (m.resp || []).map((e) => {
        let d = e.product.filter((k) => {
          return k.id == pid;
        });

        return {
          ...e,
          _linkedQty: d.length > 0 ? get(d, "[0].quantity", 0) : 0,
        };
      });
      setDealList(m.statusCode == 200 ? s : []);
      setShowDealListModal(true);
    }
  };

  return (
    <>
      <div>
        {data.loading ? (
          <Spinner type="dots" />
        ) : (
          <div role="button" tabIndex="0" onClick={onClickViewDealList}>
            {data.count}
          </div>
        )}
      </div>

      {/* Modal */}
      <Modal show={showDealListModal} onHide={closeDealListModal}>
        <Modal.Header closeButton>
          <Modal.Title>View Area List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <TableHeader data={modalHeaders} />
            <tbody>
              {dealList.map((x, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{x?.name}</td>
                  <td>{x?._linkedQty || 0}</td>
                  <td>{x?.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDealListModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <BusyLoader show={showModalLoader} />
    </>
  );
};

export default FetchDisplayDealCount;
