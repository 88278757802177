import { FranchiseService } from "@sk/services";
import { NoDataFound, TableHeader, TableSkeletonLoader } from "@sk/uis";
import produce from "immer";
import { useEffect, useRef, useState } from "react";

const headers = [
  { label: "ID", width: "20%" },
  { label: "Name", width: "40%" },
  { label: "Pincode", width: "20%" },
  { label: "Clone", width: "20%" },
];

const containerStyle = {
  maxHeight: "300px",
  overflowY: "auto",
};

const getData = async (params) => {
  const r = await FranchiseService.getFranchises(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const prepareFilterParams = (paginationParams) => {
  const { activePage, rowsPerPage } = paginationParams;
  return {
    page: activePage,
    count: rowsPerPage,
    filter: FranchiseService.getDarkstoreFilter().filter,
  };
};

const FixedComboCloneTable = ({ callback }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 15,
    activePage: 1,
  });
  const [loadingMore, setLoadingMore] = useState(false);

  const loadList = async () => {
    setLoading(true);
    const params = prepareFilterParams(paginationRef.current);
    const newData = await getData(params);
    setData((prevData) => [...prevData, ...newData]);
    setLoading(false);
  };

  const handleClone = (id) => {
    callback({ action: "clone", data: { _id: id } });
  };

  const loadMore = async () => {
    setLoadingMore(true);
    paginationRef.current.activePage += 1; // Increment active page
    const params = prepareFilterParams(paginationRef.current);
    const newData = await getData(params);
    setData(
      produce((draft) => {
        draft.push(...newData);
      })
    );
    setLoadingMore(false);
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <>
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar"
        style={containerStyle}
      >
        <table className="table table-sm table-bordered">
          <TableHeader data={headers} fwSize="sm" />
          <tbody>
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loading ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((e) => (
              <tr key={e._id}>
                <td>{e._id}</td>
                <td>{e.name}</td>
                <td>{e.pincode}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleClone(e._id)}
                  >
                    Clone
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-center">
        <button
          className="btn btn-light"
          onClick={loadMore}
          disabled={loadingMore}
        >
          {loadingMore ? "Loading..." : "Load More"}
        </button>
      </div>
    </>
  );
};

export default FixedComboCloneTable;
