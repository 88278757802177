import { AuthService, WarehouseService } from "@sk/services";
import { DateInput, DatePickerInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const createdOnDateConfig = { mode: "range" };

const defaultFromValues = {
  snapshotId: "",
  warehouse: AuthService.getDefaultWhId(),
  createdAt: [],
  referenceType: "",
  status: "",
};

const referenceTypeOptions = [
  { label: "Select", value: "" },
  { label: "GRN", value: "GRN" },
  { label: "GRN Dock Stock Correction", value: "GRN Dock Stock Correction" },
  { label: "GRN Reservation", value: "GRN Reservation" },
  { label: "HPB Release", value: "HPB Release" },
  { label: "HPB Reservation", value: "HPB Reservation" },
  { label: "HPB UnReservation", value: "HPB UnReservation" },
  { label: "Invoice Cancellation", value: "Invoice Cancellation" },
  { label: "Invoice cancel reservation", value: "Invoice cancel reservation" },
  {
    label: "Invoice cancel unreservation",
    value: "Invoice cancel unreservation",
  },
  { label: "Invoice reserved", value: "Invoice reserved" },
  { label: "Invoice unreserved", value: "Invoice unreserved" },
  { label: "Order Cancellation", value: "Order Cancellation" },
  { label: "Order Reservation", value: "Order Reservation" },
  { label: "Release", value: "Release" },
  { label: "Return", value: "Return" },
  { label: "Stock Correction", value: "Stock Correction" },
  { label: "Stock Movement", value: "Stock Movement" },
  { label: "Stock Reservation", value: "Stock Reservation" },
  { label: "Stock Unreservation", value: "Stock Unreservation" },
];

const statusOptions = [
  { label: "Select", value: "" },
  ...WarehouseService.getPrdSnapStatus(),
];

const Filter = ({ callback }) => {
  const { control, register, handleSubmit, reset, getValues } = useForm({
    defaultValues: defaultFromValues,
  });

  const debouncedCallback = useCallback(
    debounce((data) => callback({ formData: data }), 700),
    [callback]
  );

  const onSubmit = (data) => {
    callback({ formData: data });
  };

  const doReset = () => {
    reset({ ...defaultFromValues });
    callback({ formData: defaultFromValues });
  };

  const onInpChange = () => {
    callback({ formData: { ...getValues() } });
  };

  const onDateChange = (chngFn) => (e) => {
    chngFn(e);
    callback({ formData: { ...getValues() } });
  };

  const onSearch = () => {
    debouncedCallback(getValues());
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          <div className="col-3">
            <TextInput
              register={register}
              name="snapshotId"
              type="text"
              label="Snapshot Id"
              placeholder="Enter Snapshot Id  here"
              callback={onSearch}
              size="sm"
              gap={1}
            />
          </div>

          <div className="col-auto ">
            <SelectInput
              label="Warehouse"
              register={register}
              name="warehouse"
              isMandatory={false}
              options={warehouseOptions}
              callback={onInpChange}
              size="sm"
              gap={1}
            />
          </div>

          <div className="col-auto">
            <SelectInput
              label="Reference Type"
              register={register}
              name="referenceType"
              isMandatory={false}
              options={referenceTypeOptions}
              callback={onInpChange}
              size="sm"
              gap={1}
            />
          </div>

          <div className="col">
            <SelectInput
              label="Status"
              register={register}
              name="status"
              isMandatory={false}
              options={statusOptions}
              callback={onInpChange}
              size="sm"
              gap={1}
            />
          </div>

          <div className="col-auto">
            <label className="fw-semibold mb-1 fs-val-md"> Created On </label>
            <Controller
              name="createdAt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DateInput
                  placeholder="Choose"
                  value={value}
                  inpChange={onDateChange(onChange)}
                  config={createdOnDateConfig}
                  size="sm"
                  gap={1}
                />
              )}
            />
          </div>

          <div className="ms-auto col-auto  align-self-center">
            <button
              className="btn mt-1 btn-outline-warning btn-sm"
              type="button"
              onClick={doReset}
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
