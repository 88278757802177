import { AppCard, NoDataFound, TableHeader } from "@sk/uis";

const ViewDealTier = ({ tierData }) => {
  return (
    <AppCard>
      <div className="row">
        <div className="col-6">
          <div className="mb-2 fs-val-md fw-bold">Tier Details</div>
          <table className="table table-sm mb-2">
            <TableHeader data={tierHeaders} noBg={true} />
            <tbody>
              {!tierData?.length ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    <NoDataFound>No Tier Data Found</NoDataFound>
                  </td>
                </tr>
              ) : (
                tierData.map((d, i) => (
                  <tr key={d._id}>
                    <td>{i + 1}</td>
                    <td>{d.name}</td>
                    <td>{d.min}</td>
                    <td>{d.max}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="col-6">
          <div className="mb-2 fs-val-md fw-bold">RF Formula</div>
          <table className="table table-sm mb-2 table-striped">
            <TableHeader data={headers1} noBg={true} />
            <tbody>
              <tr>
                <td>Min</td>
                <td>Tier Min * Inner Case Qty</td>
              </tr>
              <tr>
                <td>Per Day</td>
                <td>Max / 2</td>
              </tr>
              <tr>
                <td>Per Month (Max)</td>
                <td>Tier Max * Case Qty</td>
              </tr>
            </tbody>
          </table>

          <div className="mb-2 fs-val-md fw-bold">SF Formula</div>
          <table className="table table-sm mb-2 table-striped">
            <TableHeader data={headers2} noBg={true} />
            <tbody>
              <tr>
                <td>Min</td>
                <td>Tier Min * Case qty</td>
                <td></td>
              </tr>
              <tr>
                <td>Per Day</td>
                <td>Max / 2</td>
                <td></td>
              </tr>
              <tr>
                <td>Per Month (Max)</td>
                <td>Tier Max * Case Qty * 5</td>
                <td>
                  <div>
                    1. Active retailer count is taken as 5 if SF has more than 5
                    retailers.
                  </div>
                  <div>
                    2. If SF has lower than 5 then available retailer count will
                    be taken.
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </AppCard>
  );
};

const tierHeaders = [
  {
    label: "#",
  },
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Min",
    key: "min",
  },
  {
    label: "Max",
    key: "max",
  },
];

const headers1 = [
  {
    label: "RF",
    key: "rf",
    width: "20%",
  },
  {
    label: "Formula",
    key: "formula",
  },
];

const headers2 = [
  {
    label: "SF",
    key: "sf",
    width: "20%",
  },
  {
    label: "Formula",
    key: "formula",
  },
  {
    label: "Remarks",
    key: "remarks",
  },
];

export default ViewDealTier;
