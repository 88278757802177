import {
  BrandService,
  CategoryService,
  NavService,
  ProductService,
} from "@sk/services";
import {
  Amount,
  AppCard,
  BtnLink,
  HighlightText,
  ImgRender,
  Tabs,
} from "@sk/uis";
import { useEffect, useState } from "react";
import LinkedDeals from "../../../../components/linked-deals/LinkedDeals";
import ProductStockSummary from "../../../../components/product-stock-summary/ProductStockSummary";
import CmsOverviewLastesInfo from "./CmsOverviewLastesInfo";
import CmsOverviewPrdSummary from "./CmsOverviewPrdSummary";
import CmsOverviewStockLedger from "./stock-ledger/CmsOverviewStockLedger";

const imgStyle = {
  width: "100px",
  height: "100px",
};

const CmsOverviewProductDetail = ({ productId }) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [activeTab, setActiveTab] = useState({ key: "overview" });

  const tabs = [
    { key: "overview", tabName: "Overview" },
    { key: "ledger", tabName: "Ledger" },
    { key: "linkedDeals", tabName: "Linked Deals" },
  ];

  useEffect(() => {
    const fetchProductDetail = async () => {
      setLoading(true);
      try {
        const response = await ProductService.getProduct(productId);

        const categoryId = response.resp.category;
        const brandId = response.resp.brand;

        const category = categoryId
          ? await CategoryService.getCategory(categoryId, { select: "name" })
          : { resp: { name: "N/A" } };
        const brand = brandId
          ? await BrandService.getDetailsById(brandId, { select: "name" })
          : { resp: { name: "N/A" } };

        setProduct({
          ...response.resp,
          categoryName: category.resp.name,
          brandName: brand.resp.name,
        });
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (productId) {
      fetchProductDetail();
    }
  }, [productId]);

  return (
    <div>
      {loading ? (
        <div
          className="text-center d-flex align-items-center justify-content-center"
          style={{ height: "calc(100vh - 300px)" }}
        >
          Loading...
        </div>
      ) : productId ? (
        product ? (
          <div>
            <AppCard>
              <div className="row">
                <div className="col-auto align-self-center">
                  <div style={imgStyle} className="border rounded p-1">
                    <ImgRender
                      assetId={product.images?.[0]}
                      width="90"
                      height="90"
                    />
                  </div>
                </div>
                <div className="col align-self-center">
                  <div className="mb-3">
                    <div className="fs-val-normal fw-bold mb-2">
                      {product.name}
                    </div>
                    <div className="row mb-2">
                      <div className="col d-flex align-items-center">
                        <span className="border rounded px-1 px-2 fs-val-sm me-2">
                          {product._id}
                        </span>
                        <HighlightText
                          type={
                            product.status === "Active" ? "success" : "danger"
                          }
                          template={2}
                        >
                          {product.status}
                        </HighlightText>
                        <span className="ms-2">
                          MRP: <Amount value={product.mrp} />
                        </span>
                      </div>
                      <div className="col-auto">
                        <BtnLink
                          className="fs-val-sm"
                          onClick={() =>
                            NavService.openInNewTab(`/cms/product/view`, {
                              id: productId,
                            })
                          }
                        >
                          View More Details
                        </BtnLink>
                      </div>
                    </div>

                    <div className="row mb-2 fs-val-sm">
                      <div className="col-auto">
                        <span className="text-black-50 align-middle">
                          Category:
                        </span>
                        {product.categoryName ? (
                          <BtnLink
                            className="fs-val-sm"
                            onClick={() =>
                              NavService.openInNewTab(`/cms/category/view`, {
                                id: product.category,
                              })
                            }
                          >
                            {product.categoryName}
                          </BtnLink>
                        ) : (
                          "N/A"
                        )}
                      </div>
                      <div className="col-auto">
                        <span className="text-black-50 align-middle">
                          Brand:{" "}
                        </span>
                        {product.brandName ? (
                          <BtnLink
                            className="fs-val-sm"
                            onClick={() =>
                              NavService.openInNewTab(`/cms/brand/view`, {
                                id: product.brand,
                              })
                            }
                          >
                            {product.brandName}
                          </BtnLink>
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AppCard>

            <CmsOverviewPrdSummary pid={productId} />

            <CmsOverviewLastesInfo pid={productId} />

            <div className="border-bottom mb-3">
              <Tabs
                data={tabs}
                activeTab={activeTab}
                callback={(data) => setActiveTab(data.value)}
                template={4}
              />
            </div>
            <div className="mb-3">
              {/* Tab Content */}
              {activeTab.key === "overview" && (
                <AppCard>
                  <ProductStockSummary pId={productId} tableSize="sm" />
                </AppCard>
              )}
              {activeTab.key === "ledger" && (
                <CmsOverviewStockLedger productId={productId} />
              )}

              {activeTab.key === "linkedDeals" && (
                <AppCard>
                  <LinkedDeals pId={productId} tableSize="sm" />
                </AppCard>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center">
            No data found for Product ID: {productId}
          </div>
        )
      ) : (
        <div className="text-center">Please choose a product.</div>
      )}
    </div>
  );
};

export default CmsOverviewProductDetail;
