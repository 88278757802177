import { DealService } from "@sk/services";
import { SummaryCard } from "@sk/uis";
import { memo, useEffect, useState } from "react";

const SummaryCardBox = ({ label, filterParams, color, icon, img }) => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);

  useEffect(() => {
    loadCount(filterParams);
  }, [filterParams]);

  const loadCount = async (filter) => {
    setLoading(true);
    const r = await DealService.getCustomerCatalogPriceConfig({
      filter,
      outputType: "count",
    });
    setValue(!isNaN(Number(r?.resp)) ? r?.resp : 0);
    setLoading(false);
  };

  return (
    <SummaryCard
      value={value}
      title={label}
      loading={loading}
      valueColor={color}
      img={img}
      template={2}
    />
  );
};

export default memo(SummaryCardBox);
