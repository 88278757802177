import { CategoryService, NavService } from "@sk/services";
import { AppCard, NoDataFound, Spinner, TableHeader } from "@sk/uis";
import { produce } from "immer";
import { memo, useEffect, useState } from "react";
import CategoryMenuRender from "./CategoryMenuRender";

const categoryFlags = [
  {
    label: "Collect Serial Number",
    value: false,
    key: "collectSerialNumber",
  },
  { label: "Sticky Order Price", value: false, key: "stickyOrderPrice" },
  {
    label: "Margin on Dealer Price",
    value: false,
    key: "marginDealerPrice",
  },
  {
    label: "Invoice on Order Amount",
    value: false,
    key: "invoiceOnOrderAmount",
  },
  {
    label: "Refund on MRP Change",
    value: false,
    key: "refundOnMrpChange",
  },
  { label: "Debit on MRP Change", value: false, key: "debitOnMrpChange" },
  { label: "Display Deal MRP", value: false, key: "displayDealMrp" },
  {
    label: "Is Focused Category",
    value: false,
    key: "focusedCategoryInfo.isFocusedCategory",
  },
];

const attributeHeader = [
  { label: "Sl No", width: "10%" },
  { label: "Name" },
  { label: "Type" },
  { label: "Attributes" },
  { label: "Mandatory", isCentered: true },
];

// const dummyDataForCategory = [
//   {
//     name: "Masala Powders",
//     count: 5,
//     types: [
//       { name: "Food", count: 3 },
//       {
//         name: "salt",
//         count: 3,
//         types: [{ name: "kk", count: 44 }],
//       },
//     ],
//   },
//   {
//     name: "Mixtures",
//     count: 3,
//     types: [
//       { name: "Ginger", count: 3 },
//       {
//         name: "turmeric",
//         count: 3,
//         types: [
//           { name: "kk", count: 44, types: [{ name: "ginger", count: 5 }] },
//         ],
//       },
//     ],
//   },
// ];

const BasicDetails = ({ data }) => {
  const [childCatLoading, setChildCatLoading] = useState(true);

  const [childCategories, setChildCategories] = useState([]);

  const [similarLoading, setSimilarLoading] = useState(true);

  const [similarCategories, setSimilarCategories] = useState([]);

  const [categoryFlagList, setCategoryFlagList] = useState([...categoryFlags]);

  useEffect(() => {
    // child category
    const loadChildCategory = async (id) => {
      setChildCatLoading(true);
      let m = await CategoryService.getSimilarCategoryList({
        filter: { parent: { $eq: id } },
        select: "name,_id",
      });
      let d = Array.isArray(m.resp) ? m.resp : [];
      setChildCategories(d || []);
      setChildCatLoading(false);
      setCategoryFlagList(
        produce((draft) => {
          draft.forEach((x) => {
            x.value = data[x.key] || false;
          });
        })
      );
    };

    // Similar Category
    const loadSimilarCategory = async (id) => {
      if (id) {
        setSimilarLoading(true);
        let m = await CategoryService.getSimilarCategoryList({
          filter: { _id: id },
          select: "name,_id",
        });
        let d = Array.isArray(m.resp) ? m.resp : [];
        d.forEach((e) => {
          e.children = (e.children || []).filter((k) => {
            if (k._id != data._id) {
              return k;
            }
          });
          e.childrensCount = e.childrensCount - 1;
        });
        setSimilarLoading(false);
        setSimilarCategories(d || []);
      } else {
        setSimilarLoading(false);
        setSimilarCategories([]);
      }
    };

    if (data._id) {
      loadChildCategory(data._id);
      if (data.hasParentCategory && data.parent) {
        loadSimilarCategory(data.parent);
      } else {
        setSimilarLoading(false);
        setSimilarCategories([]);
      }
    } else {
      setChildCategories([]);
      setSimilarCategories([]);
    }
    // eslint-disable-next-line no-undef
  }, [data, data._id, data.parent]);

  // const viewCategory = (id) => {
  //   NavService.openInNewTab("/cms/category/view", { id: id });
  // };

  return (
    <>
      <div className="m-2 mb-3">
        <div className="row g-0">
          {/* Category Flags */}
          <div className="col-9">
            <AppCard noShadow={true}>
              <div className="row mb-3">
                <div className="col">
                  <div className="fs-val-lg fw-semibold">Category Flags</div>
                </div>
                <div className="col-auto ms-auto">
                  {/* <div>Category Type: {data?._categoryType}</div> */}
                </div>
              </div>

              <div className="row">
                {(categoryFlagList || []).map((x) => (
                  <div className="col-3 mb-3" key={x.key}>
                    {x.value ? (
                      <i className="bi bi-check-square-fill text-primary align-middle me-2 fs-val-md"></i>
                    ) : (
                      <i className="bi bi-app  align-middle me-2 fs-val-md opacity-25"></i>
                    )}
                    {x.label}
                  </div>
                ))}

                <div className="col-3">
                  <div className="row">
                    <div className="col-auto mb-3">
                      {data?.noReturnsAllowed ? (
                        <i className="bi bi-app  align-middle me-2 fs-val-md opacity-25"></i>
                      ) : (
                        <i className="bi bi-check-square-fill text-primary align-middle me-2 fs-val-md"></i>
                      )}
                      Returns Allowed
                    </div>

                    {!data?.noReturnsAllowed ? (
                      <div className="col-4">
                        <div className="badge border text-dark fw-semibold p-2 px-3 fs-val-md">
                          {data?.returnDays || "N/A"} &nbsp;
                          {data?.maxB2BPeriodType || ""}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                {data?.focusedCategoryInfo?.isFocusedCategory ? (
                  <div className="col-6">
                    <div className="fw-semibold pb-1">
                      Focused Category Tagline :{" "}
                    </div>
                    <div className="badge border text-dark d-block fw-semibold p-4 fs-val-md">
                      {data?.focusedCategoryInfo?.tagLine || "N/A"}
                    </div>
                  </div>
                ) : null}
              </div>
            </AppCard>
          </div>

          {/* Attributes &&  Similar Category */}
          <div className="col-5">
            {/*  Attributes  */}
            <div className="border rounded-2">
              <div className="fs-val-lg fw-semibold border-bottom p-3">
                Attributes
              </div>
              {/* Table  */}
              <table className="table table-bordered mb-0">
                <TableHeader data={attributeHeader} />
                <tbody>
                  {!data.attributes.length ? (
                    <tr>
                      <td colSpan={attributeHeader.length}>
                        <NoDataFound>No Attributes Added </NoDataFound>
                      </td>
                    </tr>
                  ) : null}
                  {/* List start */}
                  {(data.attributes || []).map((x, index) => (
                    <tr key={x?._id}>
                      <td>{1 + index}</td>

                      {/* Name */}
                      <td>{x.name}</td>

                      {/* Type */}
                      <td>{x.type}</td>

                      {/* Pattern */}
                      <td>{x.pattern || "N/A"}</td>

                      {/* Mandatory */}
                      <td className="py-1 fs-3 text-center">
                        {x.mandatory ? (
                          <i className="bi bi-check2 text-success"></i>
                        ) : (
                          <i className="bi bi-x text-danger"></i>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Similar Category */}
            <div className="border rounded-2 mt-4">
              <div className="fs-val-lg fw-semibold border-bottom p-3">
                Similar Categories
              </div>
              <div className="p-3">
                {/* Loader */}
                {similarLoading ? (
                  <div className="text-center">
                    <Spinner />
                  </div>
                ) : null}

                {/* No Data Found */}
                {!similarLoading && !similarCategories.length ? (
                  <NoDataFound>No Similar Categories Found</NoDataFound>
                ) : null}

                {/* List */}
                {!similarLoading && similarCategories.length ? (
                  <CategoryMenuRender items={similarCategories} />
                ) : null}
              </div>
            </div>
          </div>

          {/* Child Category */}
          <div className="col-7 ps-3">
            <div className="border rounded-2">
              <div className="fs-val-lg fw-semibold border-bottom p-3">
                Child Categories
              </div>
              <div className="p-3">
                {/* Loader */}
                {childCatLoading ? (
                  <div className="text-center">
                    <Spinner />
                  </div>
                ) : null}

                {/* List */}
                {!childCatLoading && !childCategories.length ? (
                  <NoDataFound>No Child Categories Found</NoDataFound>
                ) : null}

                {/* No Data Found */}
                {!childCatLoading && childCategories.length ? (
                  <CategoryMenuRender items={childCategories} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(BasicDetails);
