import * as yup from "yup";

const validationSchema = yup.object({
  activeTab: yup.string().default("taxes"),

  /*----------------Basic Details Validation-----------------*/

  name: yup.string().trim().required("Product Name Is Required"),

  masterProduct: yup.array(),

  is_active: yup.boolean().default(true),

  brand: yup.array().min(1, "Please Add Brand"),

  originCountry: yup.string().trim(),

  category: yup.array().min(1, "Please Add Category"),

  skuCode: yup.string().trim(),

  barcode: yup.string().trim().required("Bar code Is required"),

  companyName: yup.string(),

  /*------------Basic Details Ends Here -----------------------*/

  /*-------------------Taxes and Pricing ---------------------- */
  mrp: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("activeTab", {
      is: (activeTab) => activeTab == "taxes",
      then: (schema) =>
        schema
          .label("MRP")
          .min(0, "Please Enter Valid MRP")
          .max(10000000, "MRP cannot be to much high")
          .required(),
    }),

  tax: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when(["activeTab", "isTaxable"], {
      is: (activeTab, isTaxable) => activeTab == "taxes" && !isTaxable,
      then: (schema) => schema.label("GST").required(),
    }),

  additionalCessType: yup.string().trim(),

  additionalCessValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(0, "Please Enter Valid Cess Value"),

  isTaxable: yup.boolean().default(false),

  ptrByBrands: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(0, "Please Enter Valid Discount")
    .max(100, "cannot be greater than 100"),

  HSNNumber: yup.string().label("HSN Number").required(),
  /*--------------------Taxes and Pricing Ends Here-----------------------*/

  /*------------------------Dimensions ---------------------------*/

  weight: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(0, "Please Enter Valid Weight"),
  // .when("activeTab", {
  //   is: (activeTab) => activeTab === "dimensions",
  //   then: (schema) =>
  //     schema
  //       .label("Weight")
  //       .min(0, "Please Enter Valid Weight")
  //       .max(1000, "Please Enter Valid Weight")
  //       .required(),
  // }),

  height: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("activeTab", {
      is: (activeTab) => activeTab === "dimensions",
      then: (schema) =>
        schema
          .label("Height")
          .min(0, "Please Enter Valid Height")
          .max(1000, "Please Enter Valid Height"),
    }),

  length: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("activeTab", {
      is: (activeTab) => activeTab === "dimensions",
      then: (schema) =>
        schema
          .label("Length")
          .min(0, "Please Enter Valid Length")
          .max(1000, "Please Enter Valid Length"),
    }),

  breadth: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("activeTab", {
      is: (activeTab) => activeTab === "dimensions",
      then: (schema) =>
        schema
          .label("Breadth")
          .min(0, "Please Enter Valid Breadth")
          .max(1000, "Please Enter Valid Breadth"),
    }),

  caseQty: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("activeTab", {
      is: (activeTab) => activeTab === "dimensions",
      then: (schema) =>
        schema
          .label("Case Qty")
          .min(0, "Please Enter Valid Case Qty")
          .required(),
    }),

  innerPackQty: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("activeTab", {
      is: (activeTab) => activeTab === "dimensions",
      then: (schema) =>
        schema
          .label("Inner Case Qty")
          .min(0, "Please Enter Valid Inner Case Qty")
          .required(),
    }),

  packagingType: yup.string(),
  // .trim()
  // .when("activeTab", {
  //   is: (activeTab) => activeTab == "dimensions",
  //   then: (schema) => schema.label("Packaging Type").required(),
  // }),

  UoM: yup.string().trim(),
  // .when("activeTab", {
  //   is: (activeTab) => activeTab == "dimensions",
  //   then: (schema) =>
  //     schema.label("Unit of Measurement is Required").required(),
  // }),

  shelf_life: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("activeTab", {
      is: (activeTab) => activeTab === "dimensions",
      then: (schema) =>
        schema
          .label("Shelf Life")
          .min(0, "Please Enter Valid Shelf Life")
          .required(),
    }),

  packSize: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v)),
  // .when("activeTab", {
  //   is: (activeTab) => activeTab === "dimensions",
  //   then: (schema) =>
  //     schema
  //       .label("Pack Size")
  //       .min(0, "Please Enter Valid Pack Size")
  //       .required(),
  // }),

  brandKVIStatus: yup.string().trim(),

  /*---------------------------Dimension-----------------------------*/

  /*--------------------------Categories-----------------------------*/

  dealer_price: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when(["activeTab", "category"], {
      is: (activeTab, category) => {
        return (
          activeTab == "categories" &&
          category?.[0]?.value?.categoryType == "Electronics"
        );
      },
      then: (schema) =>
        schema
          .label("Dealer Price")
          .min(0, "Please Enter Valid Dealer Price")
          .max(10000000, "It cannot be to much high")
          .required(),
    }),

  classClassifications: yup.array().default([]),

  /*---------------------Categories Ends Here---------------------------*/

  /*------------------------Highlight & description------------------------*/

  highlights: yup.array(),
  shortDescription: yup.string().trim(),

  fullDescription: yup.string(),
  // .trim()
  // .when("activeTab", {
  //   is: (activeTab) => activeTab === "description",
  //   then: (schema) => schema.label("Description").required(),
  // }),
  /*------------------------------------Ends Here------------------------*/

  /*----------------------------Product Image----------------------------*/

  images: yup.array().when("activeTab", {
    is: (activeTab) => activeTab === "images",
    then: (schema) => schema.min(1, "Please Add At least one Image").required(),
  }),
  /*---------------------Product Image---------------------------------- */

  /*-----------------------Tier Config-----------------------------------*/

  tierData: yup.array().when("activeTab", {
    is: (activeTab) => activeTab == "tierConfig",
    then: (schema) => schema.min(1, "Tier Config Must have At Least one Data"),
  }),

  /*-----------------------Tier Config Ends-----------------------------------*/

  /*------------------------Additional Boolean Values----------------*/

  disableForPo: yup.boolean().default(false),

  disableForReserve: yup.boolean().default(false),

  isAutoBarcode: yup.boolean().default(false),

  invoice_seperately: yup.boolean().default(false),

  delivery_chalan: yup.boolean().default(false),

  permanently_deactivate: yup.boolean().default(false),

  /*----------------------Utility---------------------------- */
  highlightInput: yup.string().trim(),

  tierName: yup.string().trim(),

  defaultClassifications: yup.object().default({}),

  // More about Product

  ingredients: yup.string().trim(),
  manufacturerDetails: yup.string().trim(),
  marketedBy: yup.string().trim(),
  expiryDate: yup.string().trim(),
  disclaimer: yup.string().trim(),

  // Remarks

  remarks: yup.string().trim(),
});
export default validationSchema;
