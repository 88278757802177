import { NavService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo } from "react";

const rbac = {
  editButton: ["EditKingSlab"],
  viewButton: ["ViewKingSlab"],
};

const headers = [
  { label: "Sl.No." },
  { label: "ID" },
  { label: "Group Type" },
  { label: "Deal Name/ ID" },
  { label: "No. of Slabs", isCentered: true },
  { label: "Is Fixed Price", isCentered: true },
  { label: "Created On", key: "createdAt", enableSort: true },
  { label: "Status /Actions", isCentered: true },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  viewCb,
  sort,
  sortCb,
  editCb,
}) => {
  const viewDeal = (id) => {
    NavService.openInNewTab("/cms/deal/view", { id });
  };
  return (
    <>
      <PaginationSummary
        paginationConfig={paginationConfig}
        loadingTotalRecords={loadingTotalRecords}
      />
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {loading && (
            <TableSkeletonLoader
              rows={paginationConfig.rowsPerPage}
              cols={headers.length}
            />
          )}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + index}</td>
                <td>
                  <span
                    role="button"
                    tabIndex={-1}
                    onClick={() => {
                      viewCb(x);
                    }}
                  >
                    {x?._id}
                  </span>
                </td>
                <td>
                  {Array.isArray(x?.groupType) ? (
                    <div>
                      {(x?.groupType || []).map((r, index) => (
                        <div
                          key={x.name + String(index)}
                          className="badge badge-soft-primary fw-semibold me-1"
                        >
                          {r}
                        </div>
                      ))}
                    </div>
                  ) : null}

                  {x?.fids?.length ? (
                    <>
                      <div className="mb-1">
                        {x.franchiseLoading ? (
                          <Spinner type="dots" />
                        ) : (
                          <div className="fs-val-md text-primary">
                            {x._franchise[0].name} - {x._franchise[0]._id}
                          </div>
                        )}

                        <div
                          key={x.name + String(index)}
                          className="badge badge-soft-primary fw-semibold me-1"
                        >
                          Smart Store
                        </div>
                      </div>
                    </>
                  ) : null}
                </td>
                <td>
                  <span
                    role="button"
                    tabIndex={-1}
                    onClick={() => {
                      viewDeal(x.dealId);
                    }}
                  >
                    <div className="mb-1 key-val fw-semibold">
                      {x.dealLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        x?._deal?.["name"]
                      )}
                    </div>
                    <div className="fs-val-sm text-muted">ID: {x.dealId}</div>
                  </span>
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-sm btn-link fs-val-md"
                    onClick={() => {
                      viewCb(x);
                    }}
                  >
                    {x?.slab?.length}
                  </button>
                </td>

                <td className="text-center">
                  <HighlightText
                    status={x?.isFixedPrice ? "Yes" : "No"}
                    isBadge={true}
                  />
                </td>

                <td>
                  <div className="mb-1 ms-2">
                    {<DateFormatter date={x?.createdAt} />}
                  </div>
                  <div className="ms-2 text-primary">
                    by{" "}
                    {x.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x?._createdBy?.name
                    )}
                  </div>
                </td>

                {/* Status / Actions */}
                <td className="text-center">
                  <div className="text-center mb-3">
                    <div className="mb-2">
                      <HighlightText
                        status={x.isActive ? "Active" : "Inactive"}
                        isBadge={true}
                      />
                    </div>
                  </div>
                  <Rbac roles={rbac.viewButton}>
                    <button
                      onClick={() => viewCb(x)}
                      className="btn btn-sm fs-val-xs btn-outline-primary me-1"
                    >
                      View
                    </button>
                  </Rbac>

                  <Rbac roles={rbac.editButton}>
                    <button
                      className="btn btn-sm btn-outline-warning fs-val-xs"
                      onClick={() => editCb(x)}
                    >
                      Edit
                    </button>
                  </Rbac>
                </td>

                {/* <td className="text-center">
                  {
                    <HighlightText
                      status={x.isActive ? "Active" : "Inactive"}
                    />
                  }
                </td>
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-md"
                      onClick={() => {
                        viewCb(x);
                      }}
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.editButton}>
                    <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-md"
                      onClick={() => {
                        editCb(x);
                      }}
                    >
                      Edit
                    </button>
                  </Rbac>
                </td> */}
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
