import { useAttachAdditionalData } from "@sk/hooks";
import { OmsService } from "@sk/services";
import { PaginationSummary } from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { detailsView } from "../../../constantService";
import Table from "./components/Table";

const defaultPageOpt = detailsView.tabs.reserveOrderQty.pagination;

const attachAdditionalDataConfig =
  detailsView.tabs.reserveOrderQty.additionalTableDataConfig;

const getData = async (params) => {
  const r = await OmsService.getReserveOrderQtyList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.warehouseLoading = true;
        return x;
      })
    : [];
};

const ReserveOrderQty = ({ pId }) => {
  // It will Store Reserve Order Quantity Data
  const [data, setData] = useState([]);

  //  To Store Pagination Data
  const paginationRef = useRef({ ...defaultPageOpt });

  //  Loading State While Fetch Data
  const [loadingData, setLoadingData] = useState(true);

  //  Loading State for Total count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const dataRef = useRef([]);

  useEffect(() => {
    if (pId) {
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  const loadList = useCallback(async () => {
    const params = {
      productIds: [pId],
    };

    setLoadingData(true);
    setLoadingTotalRecords(true);

    const d = await getData(params);

    // attaching additional Info
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    dataRef.current = d;
    paginationRef.current.totalRecords = d.length;

    setData(
      dataRef.current.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      )
    );
    setLoadingData(false);
    setLoadingTotalRecords(false);
  }, [attachAllData, pId, setAdditionalData]);

  const applyFilter = useCallback(async () => {
    loadList();
  }, [loadList]);

  //  To Change Page Data
  const paginationCb = useCallback((data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;

    //  This is Static Pagination
    setData(
      dataRef.current.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      )
    );
  }, []);

  return (
    <>
      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      {/* Table */}
      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default ReserveOrderQty;
