import { PageLoader } from "@sk/uis";
import { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import FixedComboProductTable from "./components/FixedComboProductTable";

const canvasStyle = {
  width: "90%",
};

const FixedComboProductSelectModal = ({
  show = false,
  callback,
  filterData,
}) => {
  const [loading, setLoading] = useState(false);

  const filterRef = useRef(filterData);

  useEffect(() => {
    if (show) {
      filterRef.current = {
        ...filterData,
      };
    }
  }, [filterData, show]);

  const closeModal = () => {
    callback({ action: "close" });
  };

  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={closeModal}
      style={canvasStyle}
    >
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg">
            Showing "{filterData?.categoryName}" deals
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <FixedComboProductTable
              filterData={filterData}
              callback={callback}
            />
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FixedComboProductSelectModal;
