import { AuthService } from "@sk/services";
import { SelectInput, TextInput } from "@sk/uis";
import debounce from "lodash/debounce";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";

// const createdOnDateConfig = { mode: "range" };

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const statusOptions = [
  { label: "Select ", value: "" },
  { label: "Available", value: "Available" },
  { label: "Non Available", value: "NonAvailable" },
  { label: "Used", value: "Used" },
];

const Filter = ({ callback }) => {
  const { register, getValues } = useForm({});
  const onSearch = useCallback(
    debounce(() => {
      onFilterChange();
    }, 700),
    [getValues, callback]
  );

  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  return (
    <>
      <form>
        <div className="row">
          <div className="col-3">
            <TextInput
              name="order"
              type="Text"
              register={register}
              label="Search By Order/Invoice/SerialNo  "
              placeholder="Search here"
              callback={onSearch}
            />
          </div>

          <div className="col-2">
            <SelectInput
              label="Warehouse"
              register={register}
              name="whId"
              callback={onFilterChange}
              isMandatory={false}
              options={warehouseOptions}
            />
          </div>

          <div className="col-2">
            <SelectInput
              label="Status"
              register={register}
              name="status"
              callback={onFilterChange}
              isMandatory={false}
              options={statusOptions}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
