import { ProductService } from "@sk/services";
import { AppCard } from "@sk/uis";
import React, { useEffect, useState } from "react";

const CmsOverviewPrdSummary = ({ pid }) => {
  const [loading, setLoading] = useState(true);
  const [salesData, setSalesData] = useState({
    days15: null,
    days30: null,
    days60: null,
    days90: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await ProductService.getProductSalesSummary(pid);
        setSalesData({
          days15: data?.resp?.[0]?.TotalQtyLast15Days || null,
          days30: data?.resp?.[0]?.TotalQtyLast30Days || null,
          days60: data?.resp?.[0]?.TotalQtyLast60Days || null,
          days90: data?.resp?.[0]?.TotalQtyLast90Days || null,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setSalesData({
          days15: null,
          days30: null,
          days60: null,
          days90: null,
        });
      } finally {
        setLoading(false);
      }
    };

    if (pid) {
      fetchData();
    }
  }, [pid]);

  return (
    <div className="row">
      <div className="col">
        <AppCard>
          <div className="d-flex flex-column">
            <span className="text-black-50">Sold in 15 Days</span>
            <div>
              <span>{loading ? "Loading..." : salesData.days15 || 0}</span>
              <span className="text-muted fs-val-xs mx-1">units</span>
            </div>
          </div>
        </AppCard>
      </div>
      <div className="col">
        <AppCard>
          <div className="d-flex flex-column">
            <span className="text-black-50">Sold in 30 Days</span>
            <div>
              <span>{loading ? "Loading..." : salesData.days30 || 0}</span>
              <span className="text-muted fs-val-xs mx-1">units</span>
            </div>
          </div>
        </AppCard>
      </div>
      <div className="col">
        <AppCard>
          <div className="d-flex flex-column">
            <span className="text-black-50">Sold in 60 Days</span>
            <div>
              <span>{loading ? "Loading..." : salesData.days60 || 0}</span>
              <span className="text-muted fs-val-xs mx-1">units</span>
            </div>
          </div>
        </AppCard>
      </div>
      <div className="col">
        <AppCard>
          <div className="d-flex flex-column">
            <span className="text-black-50">Sold in 90 Days</span>
            <div>
              <span>{loading ? "Loading..." : salesData.days90 || 0}</span>
              <span className="text-muted fs-val-xs mx-1">units</span>
            </div>
          </div>
        </AppCard>
      </div>
    </div>
  );
};

export default CmsOverviewPrdSummary;
