import { TableHeader, TextInput, TextareaInput, Toaster } from "@sk/uis";
import classNames from "classnames";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { manageView } from "../../../constantService";
const tableHeader = [{ label: "Name", width: "80%" }, { label: "Action" }];

const keyFeaturesInputValidation = yup.object().shape({
  keyFeatureInput: yup.string().trim().required("must be valid keyword"),
});

const MoreAboutProduct = () => {
  const {
    register,
    getValues,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [list, setList] = useState(() => getValues("keyFeatures"));

  const submitHighlight = async () => {
    let keyFeatureInput = getValues("keyFeatureInput");
    let errMsg = "";
    try {
      const e = await keyFeaturesInputValidation.validate({
        keyFeatureInput,
      });
    } catch (error) {
      errMsg = error.message;
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    const list = getValues("keyFeatures");

    let isExist = list.find((item) => item === keyFeatureInput);
    if (!isExist) {
      list.push(keyFeatureInput);
    } else {
      Toaster.error(keyFeatureInput + " already exists in List ");
    }

    setValue("keyFeatures", list);
    setList(getValues("keyFeatures"));
    setValue("keyFeatureInput", "");
  };

  const deleteHighlight = (index) => {
    const list = getValues("keyFeatures");
    list.splice(index, 1);
    setValue("keyFeatures", list);
    setList(getValues("keyFeatures"));
  };

  return (
    <div>
      <div className="row">
        <div className="col-7">
          <div className="mb-4">
            <TextareaInput
              label="Ingredients"
              error={errors?.ingredients?.message}
              name="ingredients"
              errors={errors}
              isMandatory={false}
              register={register}
              note="maximum 400 charter allowed"
              maxLength="400"
            />
          </div>

          <div className="mb-4">
            <TextareaInput
              label="Manufacturer Details"
              error={errors?.manufacturerDetails?.message}
              name="manufacturerDetails"
              errors={errors}
              isMandatory={false}
              register={register}
              note="maximum 400 charter allowed"
              maxLength="400"
            />
          </div>

          <div className="mb-4">
            <TextInput
              label="Expiry Date"
              error={errors?.expiryDate?.message}
              name="expiryDate"
              errors={errors}
              isMandatory={false}
              register={register}
            />
          </div>

          <div className="mb-4">
            <TextareaInput
              label="Marketed By"
              error={errors?.marketedBy?.message}
              name="marketedBy"
              errors={errors}
              isMandatory={false}
              register={register}
              note="maximum 400 charter allowed"
              maxLength="400"
            />
          </div>

          <div className="mb-4">
            <TextareaInput
              label="disclaimer"
              error={errors?.disclaimer?.message}
              name="disclaimer"
              errors={errors}
              isMandatory={false}
              register={register}
              note="maximum 400 charter allowed"
              maxLength="400"
            />
          </div>
        </div>
        <div className="col-5">
          <div className=" my-3 py-4">
            <div className="row">
              <div className="col">
                <TextInput
                  label="Keys"
                  register={register}
                  name="keyFeatureInput"
                  type="text"
                  error={errors?.keyFeatureInput?.message}
                  placeholder="Enter highlight here... "
                  info={manageView.infoContext.keyFeatureInput}
                />
              </div>
              {/* Add keyFeatures */}
              <div className="col-auto align-self-center mt-2">
                <button
                  onClick={submitHighlight}
                  type="button"
                  className={classNames({
                    btn: true,
                    "btn-success": true,
                  })}
                >
                  Add
                </button>
              </div>
            </div>

            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {list?.length > 0 && (
                <table
                  className="table table-bordered my-3"
                  style={{
                    overflowY: "scroll",
                    maxHeight: "30vh",
                  }}
                >
                  <TableHeader data={tableHeader} />
                  <tbody>
                    {list.map((item, index) => (
                      <tr key={index} className="fs-val-md">
                        <td>{item}</td>
                        <td>
                          <span
                            className="me-5 shadow p-1 rounded"
                            onClick={() => deleteHighlight(index)}
                            role="button"
                            tabIndex={0}
                          >
                            <i className="bi bi-trash text-danger"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreAboutProduct;
