import { Tabs } from "@sk/uis";
import { useState } from "react";
import ViewDealWarehouseVisibility from "./components/visiblity/ViewDealWarehouseVisibility";

const ViewDealWarehouse = ({ visibilityByWarehouse = [] }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const tabCb = (data) => {
    setActiveTab(data.value);
  };

  return (
    <>
      <div className="mb-3">
        <Tabs data={tabs} activeTab={activeTab} callback={tabCb} template={3} />
      </div>

      {activeTab.key === "warehouseVisibility" && (
        <ViewDealWarehouseVisibility
          visibilityByWarehouse={visibilityByWarehouse}
        />
      )}
    </>
  );
};

const tabs = [{ key: "warehouseVisibility", tabName: "Warehouse Visibility" }];

export default ViewDealWarehouse;
