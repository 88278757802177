import { AuthService, WarehouseService } from "@sk/services";
import { BusyLoader, NoDataFound, Spinner, TableHeader } from "@sk/uis";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
const modalHeaders = [
  { label: "Sl No", width: "5%" },
  { label: "Warehouse ID" },
  { label: "Warehouse Name", width: "25%" },
  { label: "Sellable Stock" },
  { label: "Non-Sellable Stock" },
  { label: "On-Hold Stock	" },
  { label: "Damaged Stock" },
  { label: "Total Stock" },
];

const FetchDisplayProductAvailableStock = ({ pid, pName, uom }) => {
  const [data, setData] = useState({ count: 0, loading: true });
  const [whList, setWhList] = useState([]);
  const [showWhListModal, setShowWhListModal] = useState(false);
  const [showModalLoader, setShowModalLoader] = useState(false);

  useEffect(() => {
    const loadDealCount = async () => {
      let m = await WarehouseService.getStockInfo(
        AuthService.getDefaultWhId(),
        pid
      );
      setData({
        count: m.statusCode == 200 ? m.resp?.avaiableQty || 0 : 0,
        loading: false,
      });
    };
    loadDealCount();
  }, []);

  const viewWhList = async () => {
    if (data.count > 0) {
      let whData = AuthService.getLoggedInEmpWh().map((e) => {
        return { id: e._id, name: e.name, loading: true };
      });
      setWhList(whData);
      setShowModalLoader(true);
      let promises = [];
      whData.forEach((e) => {
        promises.push(WarehouseService.getStockInfo(e.id, pid, {}));
      });
      const r = await Promise.all(promises);
      setWhList(
        produce((draft) => {
          draft.forEach((x, k) => {
            x.loading = false;
            x.data = r[k]["resp"] || 0;
          });
        })
      );
      setShowModalLoader(false);
      setShowWhListModal(true);
    }
  };

  const closeWhListModal = () => {
    setShowWhListModal(false);
  };

  return (
    <>
      {data.loading ? (
        <Spinner type="dots" />
      ) : (
        <div role="button" tabIndex="0" onClick={viewWhList}>
          {data.count} {uom}
        </div>
      )}
      {/* Offcanvas */}
      <Offcanvas
        show={showWhListModal}
        onHide={closeWhListModal}
        placement="end"
        style={{ width: "45%" }}
      >
        <div>
          <Offcanvas.Header closeButton className="bg-white">
            <Offcanvas.Title>
              <div className="page-title">View Stock Summary</div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-3 border-top">
            <div className="mb-1 fw-bold">Product Id : {pid}</div>
            <div className="mb-2 fw-bold">Product Name : {pName}</div>
            <table className="table table-bordered">
              <TableHeader data={modalHeaders} />
              <tbody>
                {whList.map((x, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{x?.id}</td>
                    <td>{x?.name}</td>
                    <td>{x?.data?.avaiableQty || 0}</td>
                    <td>{x?.data?.nonAvaiableQty}</td>
                    <td>{x?.data?.onHoldQty || 0}</td>
                    <td>{x?.data?.damagedQty}</td>
                    <td>
                      {x?.data?.avaiableQty +
                        x?.data?.nonAvaiableQty +
                        x?.data?.onHoldQty +
                        x?.data?.damagedQty}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Offcanvas.Body>
        </div>
        {!whList.length && <NoDataFound> No Data Found </NoDataFound>}
      </Offcanvas>
      <BusyLoader show={showModalLoader} />
    </>
  );
};

export default FetchDisplayProductAvailableStock;
