import { yupResolver } from "@hookform/resolvers/yup";
import { CommonService, DealService, FranchiseService } from "@sk/services";
import {
  Alert,
  AppCard,
  BusyLoader,
  EntitySearchInput,
  PageLoader,
  SwitchInput,
  TextInput,
  Toaster,
} from "@sk/uis";
import produce from "immer";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import StoreComboLinkedDealTable from "../components/StoreComboLinkedDealTable";

const validationSchema = yup.object({
  franchise: yup.array().min(1, "Please choose Store").label("Store"),
  deal: yup.array().min(1, "Please choose Combo Deal").label("Combo Deal"),
  status: yup.boolean(),
  discountValue: yup
    .number()
    .required()
    .nullable()
    .min(0)
    .transform((v) => (isNaN(v) ? null : v))
    .label("Combo Price"),
  discountType: yup.string(),
});

const canvasStyle = { width: "70%" };

const defaultFormData = {
  deal: [],
  franchise: [],
  status: true,
  discountValue: null,
  discountType: "price",
};

const storeFilterParams = FranchiseService.getDarkstoreFilter();

const dealFilterParams = {
  filter: {
    is_active: true,
    active: true,
    dealType: "Combo",
  },
  select: "name,product",
};

const attachStoreDealData = async (fid, deals) => {
  let data = [...deals];

  const dIds = deals.map((e) => e._id);

  if (dIds.length > 0) {
    const sr = await FranchiseService.getFranchiseInventorySummary({
      page: 1,
      count: dIds.length,
      franchiseId: fid,
      groupbycondName: "name",
      groupbycond: "deal",
      dealFilter: { _id: { $in: dIds } },
      filter: { _id: fid },
    });

    const srDeals = Array.isArray(sr.resp) ? sr.resp : [];

    data = produce(data, (draft) => {
      draft.forEach((e) => {
        const t = srDeals.find((x) => x._id == e._id) || {};
        e._hasPurchased = t._id ? true : false;
        e._mrp = t.mrp || e.mrp;
        e._rsp = t._finalSellingPrice || e._mrp;
        e._stock = t.qty || 0;
        e._purchasePrice = t?._recentPurcahsePrice || 0;
        e._slc = t?.slc || 0;
      });
    });
  }

  return { data };
};

const StoreComboManageModal = ({ show = false, callback, editId = "" }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    register,
    getValues,
  } = useForm({
    defaultValues: { ...defaultFormData },
    resolver: yupResolver(validationSchema),
  });

  const [loading, setLoading] = useState(false);

  const [busyloader, setBusyloader] = useState({ show: false });

  const [deals, setDeals] = useState([]);

  const [fran, discountType] = useWatch({
    control,
    name: ["franchise", "discountType"],
  });

  useEffect(() => {
    if (show) {
      reset({ ...defaultFormData });
      setDeals([]);

      if (editId) {
        loadEditData(editId);
      }
    }
  }, [show, editId, reset, loadEditData]);

  const loadEditData = useCallback(
    async (id) => {
      setLoading(true);
      const r = await DealService.getStoreComboDeals({
        filter: { _id: id },
      });
      const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};

      // fetch the franchise
      if (d.franchiseId) {
        const f = await FranchiseService.getFranchise(d.franchiseId, {
          select: "name, sk_franchise_details",
        });
        if (f.resp?._id) {
          setValue("franchise", [
            {
              label: f.resp.name + " (" + f.resp._id + ")",
              value: {
                ...f.resp,
                _sellerId: get(
                  f.resp,
                  "sk_franchise_details.linkedSellerId",
                  ""
                ),
              },
            },
          ]);
        }
      }

      // get the combo deal info
      if (d.comboDealId) {
        const dr = await DealService.getDeals({
          filter: { _id: d.comboDealId },
          select: "name,images,mrp,product,sellInLooseQty",
        });
        if (Array.isArray(dr.resp) && dr.resp.length > 0) {
          setValue("deal", [
            {
              label: dr.resp[0].name + " (" + dr.resp[0]._id + ")",
              value: dr.resp[0],
            },
          ]);
        }
      }

      // prepare the deals and attach the rsp in store
      const deals = (d.deals || []).map((e) => ({
        _id: e.dealId,
        name: e.name,
        _pid: e.productId,
        mrp: e.mrp,
        _discount: e.discountValue,
        _qty: e.qty,
        sellInLooseQty: e.sellInLooseQty,
      }));

      const rs = await attachStoreDealData(d.franchiseId, deals);

      const discType = d.discountType == "Percentage" ? "percentage" : "price";

      setDeals(
        FranchiseService.calculateStoreComboPerc(
          rs.data,
          d.discountValue,
          discType
        )
      );

      setValue("discountValue", d.discountValue);

      setValue("discountType", discType);

      setValue("status", d.isActive ? true : false);

      setLoading(false);
    },
    [setValue]
  );

  const closeModal = () => {
    callback({ action: "close" });
  };

  const onFranChange = (chgfn) => (val) => {
    let v = [...val];
    if (v.length > 0) {
      v[0].value._sellerId = get(
        v[0].value,
        "sk_franchise_details.linkedSellerId",
        ""
      );
    }
    chgfn(v);
    setValue("deal", []);
    setDeals([]);
  };

  const onDealChange = (chgfn) => async (val) => {
    chgfn(val);

    if (val.length > 0) {
      const d = val[0].value || {};

      if (d._id) {
        const pids = d.product.map((e) => e.id);

        const params = {
          page: 1,
          count: pids.length,
          select: "name,mrp,product,sellInLooseQty",
          filter: {
            is_active: true,
            active: true,
            dealType: { $ne: "Combo" },
            product: {
              $elemMatch: {
                id: { $in: pids },
                quantity: 1,
              },
            },
          },
        };

        setBusyloader({ show: true });

        const r = await DealService.getDeals(params);

        const deals = (Array.isArray(r.resp) ? r.resp : []).map((e) => {
          e._pid = e.product[0]?.id;
          e._qty = d.product.find((x) => x.id == e._pid)?.quantity || 0;
          return { ...e };
        });

        const rs = await attachStoreDealData(fran[0].value._id, deals);

        setDeals(
          FranchiseService.calculateStoreComboPerc(
            rs.data,
            getValues("discountValue") || 0,
            discountType
          )
        );

        setBusyloader({ show: false });
      }
    } else {
      setDeals([]);
    }
  };

  // const onDiscountChange = (e, index) => {
  //   let v = e.target.value || "";

  //   if (v < 0) {
  //     v = "";
  //   }

  //   if (v > 100) {
  //     v = 100;
  //   }

  //   if (v) {
  //     v = CommonService.roundedByDecimalPlace(v, 2);
  //   }

  //   setDeals(
  //     produce((draft) => {
  //       const rsp = draft[index]._rsp;
  //       draft[index]._discount = v;
  //       draft[index]._finalRsp = calcFinalRsp(rsp, v);
  //     })
  //   );
  // };

  const onSubmit = async (data) => {
    const hasNotPurchased = deals.filter((e) => !e._hasPurchased);

    if (hasNotPurchased.length) {
      Toaster.error("Cannot submit, Store has not purchased some deals");
      return;
    }

    let msg = "";

    if (data.discountValue <= 0 || !data.discountValue) {
      msg = "Please provide Combo price";
    }

    // each(deals, (v) => {
    //   if (typeof v._discount != "number") {
    //     msg = "Please provide discount for " + v.name;
    //   }

    //   return msg ? false : true;
    // });

    if (msg) {
      Toaster.error(msg);
      return;
    }

    const cnrf = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to submit?",
    });

    if (cnrf.isConfirmed) {
      let payload = {
        discountValue: data.discountValue,
        discountType: data.discountType == "price" ? "Fixed" : "Percentage",
        franchiseId: data.franchise[0].value._id,
        sellerId: data.franchise[0].value._sellerId,
        title: "",
        franchiseName: data.franchise[0].value.name,
        comboDealId: data.deal[0].value._id,
        comboDealName: data.deal[0].value.name,
        isActive: data.status,
        deals: deals.map((e) => ({
          dealId: e._id,
          productId: e._pid,
          name: e.name,
          discountValue: e._discount,
          discountType: "Percentage",
          mrp: e._mrp,
          price: e._rsp,
          qty: e._qty,
          sellInLooseQty: e.sellInLooseQty,
        })),
      };

      let r;

      setBusyloader({ show: true });
      if (editId) {
        r = await DealService.updateStoreComboDeals(editId, payload);
      } else {
        r = await DealService.createStoreComboDeals(payload);
      }

      setBusyloader({ show: false });
      if (r.statusCode == 200) {
        Toaster.success("Config submitted successfully");
        callback({ action: "submitted" });
      } else {
        Toaster.error(r.resp.message || "Failed to submit, please try again");
      }
    }
  };

  const onComboPriceChange = (e) => {
    let v = getValues("discountValue") || 0;

    let tot = 0;
    if (discountType == "price") {
      tot = deals.reduce((s, i) => (s += i._rsp * i._qty), 0);
    } else {
      tot = 100;
    }

    if (v < 0) {
      v = "";
      e.target.value = "";
    }

    if (v > tot) {
      v = tot;
      e.target.value = v;
    }

    if (v) {
      v =
        discountType == "percentage"
          ? CommonService.roundedByDecimalPlace(v, 6)
          : Math.floor(v);
      e.target.value = v;
    }

    setDeals(FranchiseService.calculateStoreComboPerc(deals, v, discountType));
  };

  const onDiscountTypeToggle = () => {
    const type = discountType == "price" ? "percentage" : "price";
    setValue("discountType", type);

    let v = getValues("discountValue");

    if (type == "percentage") {
      if (v >= 100) {
        setValue("discountValue", 0);
        v = 0;
      }
    }

    setDeals(FranchiseService.calculateStoreComboPerc(deals, v, type));
  };

  return (
    <>
      <Offcanvas
        show={show}
        placement="end"
        onHide={closeModal}
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          <Offcanvas.Title>
            <div className="fs-val-lg">
              Manage Combo Deal Config {editId ? " #" + editId : ""}{" "}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {loading ? (
            <PageLoader />
          ) : (
            <AppCard>
              <div className="row">
                <div className="col-6">
                  <Controller
                    control={control}
                    name="franchise"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type="franchise"
                        callback={onFranChange(onChange)}
                        value={value}
                        isMandatory={true}
                        label="Search for Store"
                        uid="sc-fran"
                        filterParams={storeFilterParams}
                        error={errors.franchise?.message}
                        disabled={editId}
                      />
                    )}
                  />
                </div>

                <div className="col"></div>

                <div className="col-auto">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SwitchInput
                        callback={onChange}
                        value={value}
                        label="Is Active"
                      />
                    )}
                  />
                </div>
              </div>

              {fran?.length > 0 ? (
                <div className="row mb-3">
                  <div className="col">
                    <Controller
                      control={control}
                      name="deal"
                      render={({ field: { onChange, value } }) => (
                        <EntitySearchInput
                          type="deal"
                          callback={onDealChange(onChange)}
                          value={value}
                          isMandatory={true}
                          label="Search for Combo Deal"
                          uid="sc-deal"
                          filterParams={dealFilterParams}
                          error={errors.deal?.message}
                          disabled={editId}
                        />
                      )}
                    />
                  </div>
                  <div className="col-6">
                    <TextInput
                      name="discountValue"
                      register={register}
                      label={
                        "Combo Price (" +
                        (discountType == "price" ? "Rs." : "%") +
                        ")"
                      }
                      type="number"
                      callback={onComboPriceChange}
                      error={errors.discountValue?.message}
                      isMandatory={true}
                      groupContent={
                        <button
                          onClick={onDiscountTypeToggle}
                          className="btn fs-val-md px-2"
                        >
                          {discountType == "price" ? "Rs." : "%"}
                        </button>
                      }
                    />
                  </div>
                </div>
              ) : null}

              {deals.length > 0 ? (
                <>
                  <div className="fs-val-md mb-1 text-muted">
                    P&L and discount summary
                  </div>
                  <StoreComboLinkedDealTable deals={deals} />
                </>
              ) : null}
            </AppCard>
          )}
        </Offcanvas.Body>
        {!loading ? (
          <Offcanvas.Header>
            <div className="text-end w-100">
              <button
                className="btn btn-primary"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </button>
            </div>
          </Offcanvas.Header>
        ) : null}
      </Offcanvas>
      <BusyLoader show={busyloader.show} />
    </>
  );
};

export default StoreComboManageModal;
