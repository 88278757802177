import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const rbac = {
  editFixedCombo: ["EditFixedCombo"],
  viewFixedCombo: ["ViewFixedCombo"],
  cloneFixedCombo: ["CloneFixedCombo"],
};

const tableStyle = {
  minWidth: "1200px",
};

const headers = [
  { label: "Sl No", width: "3%" },
  { label: "ID", width: "5%" },
  { label: "Title", width: "15%" },
  { label: "Store", width: "20%" },
  { label: "Combo Price", width: "10%", isCentered: true },
  { label: "Total Categories", width: "10%", isCentered: true },
  { label: "Total Products", width: "10%", isCentered: true },
  { label: "Status", width: "8%" },
  { label: "Last Updated", width: "10%" },
  { label: "Action" },
];

function FixedComboListTable({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  callback,
}) {
  const view = (id) => {
    callback({ action: "view", id });
  };

  const edit = (id) => {
    callback({ action: "edit", id });
  };

  const clone = (id) => {
    callback({ action: "clone", id });
  };

  return (
    <>
      <div className="mb-1">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <div className="tbl-scroll-container thin-scrollbar">
        <table className="table table-sm" style={tableStyle}>
          <TableHeader data={headers} noBg />
          <tbody>
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loading ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + index}</td>
                <td>{x._id}</td>
                <td>{x.title}</td>
                <td>
                  {x.franchiseName} - {x.franchiseId}
                </td>
                <td className="text-danger text-center">
                  <Amount value={x.offerAmount || 0} decimalPlace={2} />
                </td>
                <td className="text-center">{x._totalCategories || 0}</td>
                <td className="text-center">{x._totalProducts || 0}</td>
                <td>
                  <HighlightText
                    type={x.isActive ? "success" : "danger"}
                    template={2}
                  >
                    {x.isActive ? "Active" : "Inactive"}
                  </HighlightText>
                </td>
                <td>
                  <DateFormatter date={x.lastUpdated} />
                </td>
                <td>
                  <button
                    className="btn btn-outline-danger text-uppercase btn-sm me-2 fs-val-sm"
                    onClick={() => edit(x._id)}
                  >
                    Edit
                  </button>

                  <button
                    className="btn btn-outline-primary text-uppercase btn-sm me-2 fs-val-sm"
                    onClick={() => view(x._id)}
                  >
                    View
                  </button>

                  <button
                    className="btn btn-outline-info text-uppercase btn-sm fs-val-sm"
                    onClick={() => clone(x._id)}
                  >
                    Clone
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
}

export default FixedComboListTable;
