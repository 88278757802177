import { useAttachAdditionalData } from "@sk/hooks";
import { DealService } from "@sk/services";
import {
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
} from "@sk/uis";
import React, { useCallback, useEffect, useRef, useState } from "react";

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const tableHeaders = [
  {
    label: "Sl No",
    width: "5%",
    key: "slNo",
    isCentered: true,
  },
  {
    label: "Key",
    width: "10%",
    key: "key",
  },
  {
    label: "Old Value",
    width: "10%",
    key: "oldValue",
  },
  {
    label: "New Value",
    width: "10%",
    key: "newValue",
  },
  {
    label: "Remarks",
    width: "10%",
    key: "remarks",
  },
  {
    label: "Created On",
    width: "10%",
    key: "createdOn",
  },
];

const additionalTableDataConfig = [
  {
    key: "loggedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_user",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const StoreComboAuditLog = ({ configId = "" }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const origDataRef = useRef([]);

  useEffect(() => {
    if (configId) {
      paginationRef.current = { ...defaultPaginationConfig };
      loadData(configId);
    }
  }, [configId, loadData]);

  const loadData = useCallback(
    async (id) => {
      setLoading(true);
      const r = await DealService.getStoreComboDeals({
        filter: { _id: id },
      });
      const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};

      const auditData = d?.auditLog || [];

      origDataRef.current = [...auditData];

      paginationRef.current.totalRecords = d?.auditLog?.length || 0;

      const data = auditData.slice(0, paginationRef.current.rowsPerPage);

      let tmp = [];
      // Attach User Info
      setAdditionalData(data, additionalTableDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == additionalTableDataConfig.length) {
          setData([...attachAllData(data, tmp)]);
        }
      });

      setData(data);

      setLoading(false);
    },
    [attachAllData, setAdditionalData]
  );

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;

      const d = origDataRef.current.slice(data.startSlNo - 1, data.endSlNo);

      let tmp = [];
      // Attach User Info
      setAdditionalData(d, additionalTableDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == additionalTableDataConfig.length) {
          setData([...attachAllData(d, tmp)]);
        }
      });

      setData(d);
    },
    [attachAllData, setAdditionalData]
  );

  return (
    <>
      <table className="table table-bordered">
        <TableHeader data={tableHeaders} />
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={tableHeaders.length}>
                <div className="text-center">
                  <Spinner />
                </div>
              </td>
            </tr>
          ) : null}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={tableHeaders.length}>
                <NoDataFound>No audit logs found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {data.map((x, k) => (
            <tr key={x._id} className="fs-val-md">
              <td className="text-center">
                {paginationRef.current.startSlNo + k}
              </td>
              <td>{x.paramName}</td>
              <td>{x.oldData}</td>
              <td>{x.newData}</td>
              <td>{x.message}</td>
              <td>
                <DateFormatter date={x.loggedAt} />
                <div>by {x._user?.name || x.loggedBy}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={false}
      />
    </>
  );
};

export default StoreComboAuditLog;
