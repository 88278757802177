import { CommonService, ProductService } from "@sk/services";
import { AppliedFilterLabel, BusyLoader, PageInfo, SummaryCard } from "@sk/uis";
import { produce } from "immer";
import { merge } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import FilterForm from "./components/FilterForm";
import Table from "./components/Table";
import ManageHsnMasterModal from "./modals/ManageHsnMasterModal";
import ViewHsnMasterModal from "./modals/ViewHsnMasterModal";

const defaultSummaryData = [
  {
    label: "Total Count",
    value: 0,
    loading: true,
    filter: {},
    color: "primary",
    icon: "bi-check-all",
  },
  {
    label: "Active Count",
    value: 0,
    loading: true,
    filter: { status: "Active" },
    color: "success",
    icon: "bi-check-circle-fill",
  },
  {
    label: "Inactive Count",
    value: 0,
    loading: true,
    filter: { status: "Inactive" },
    color: "danger",
    icon: "bi-x-circle-fill",
  },
];

const paginationDefaultOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const formLabelsConfig = {
  linkedCategories: {
    label: "Category",
    valuePath: "[0].value.name",
  },
  hsn: {
    label: "HSN",
  },
  hsndesc: {
    label: "HSN Description",
  },
  status: {
    label: "Status",
  },
};

const HsnMasterList = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [showHsnFormModal, setShowHsnFormModal] = useState(false);
  const [showHsnDetailsModal, setShowHsnDetailsModal] = useState(false);
  const [summaryCard, setSummaryCard] = useState([...defaultSummaryData]);
  const [modalMode, setModalMode] = useState("add");
  const filterData = useRef();
  const actionSelectedId = useRef();
  const [filterLabels, setFilterLabels] = useState([]);

  const paginationRef = useRef({ ...paginationDefaultOpt });

  const applyFilter = useCallback(async () => {
    loadList();
    loadSummary();
    prepareFilterLabels();
    loadCount();
  }, [loadCount, loadList, loadSummary, prepareFilterLabels]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    CommonService.updateAppTitle("HSN Master Code List");
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(getFilterParams());
    paginationRef.current.totalRecords = c?.count || 0;
    setLoadingTotalRecords(false);
  }, []);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(getFilterParams());
    const d = Array.isArray(r.resp) ? r.resp : [];
    const u = await CommonService.attachUserInfo(d, [
      "createdBy",
      "modifiedBy",
    ]);
    setData(u);
    setLoadingData(false);
  }, []);

  const getFilterParams = () => {
    let d = filterData.current || {};
    let p = {
      page: paginationRef.current.activePage,
      count: paginationRef.current.rowsPerPage,
      filter: {},
    };
    if (d?.hsn) {
      p["filter"]["hsn"] = { $regex: d.hsn, $options: "gi" };
    }
    if (d?.hsndesc) {
      p["filter"]["hsndesc"] = { $regex: d.hsndesc, $options: "gi" };
    }
    if (d?.linkedCategories?.length) {
      p["filter"]["linkedCategories.id"] = {
        $in: d.linkedCategories.map((e) => {
          return e.value;
        }),
      };
    }
    if (d?.status) {
      p["filter"]["status"] = d.status;
    }
    return p;
  };

  const loadSummary = useCallback(async () => {
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const filterParams = getFilterParams();
    delete filterParams.count;
    delete filterParams.page;
    let promises = [];
    summaryCard.forEach((e) => {
      promises.push(getCount(merge({}, filterParams, { filter: e.filter })));
    });
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [summaryCard]);

  const getCount = async (params) => {
    try {
      const r = await ProductService.getHsnMastersCount(params);
      return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getData = (params) => {
    return ProductService.getHsnMasters(params);
  };

  const filterFormCb = useCallback(
    (data) => {
      filterData.current = data.formData;
      paginationRef.current = { ...paginationDefaultOpt };
      applyFilter();
    },
    [applyFilter]
  );

  const prepareFilterLabels = useCallback(() => {
    const v = filterData.current || {};
    const l = CommonService.prepareAppliedFilterLabels(formLabelsConfig, v);
    setFilterLabels(l);
  }, []);

  //  form Modal Actions  Functions & Call back
  const onClickAddHSn = () => {
    actionSelectedId.current = "";
    setShowHsnFormModal(true);
  };

  const closeHsnFormModalCb = ({ status }) => {
    setModalMode("add");
    setShowHsnFormModal(false);
    if (status == "submit") {
      init();
    }
  };

  const hideHsnModalCb = () => {
    setModalMode("add");
    setShowHsnFormModal(false);
  };

  const editCb = useCallback((id) => {
    actionSelectedId.current = id;
    setModalMode("edit");
    setShowHsnFormModal(true);
  }, []);

  //HSN Details Modal Actions
  const closeHsnDetailsModalCb = () => {
    setShowHsnDetailsModal(false);
  };

  const viewCb = useCallback((id) => {
    actionSelectedId.current = id;
    setShowHsnDetailsModal(true);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <PageInfo title="HSN Master Code List" />
        </div>
        <div className="col-auto text-end">
          <button className="btn mt-4 btn-primary" onClick={onClickAddHSn}>
            <i className="bi bi-plus-square me-1"></i> Add HSN Code
          </button>
        </div>
      </div>

      {/* SUMMARY CARDS */}
      <div className="row">
        {summaryCard.map((e, k) => (
          <div className="col-2" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              color={e.color}
              icon={e.icon}
            />
          </div>
        ))}
      </div>

      <FilterForm callback={filterFormCb} />
      {filterLabels.length > 0 ? (
        <div className="mb-2">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null}

      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        editCb={editCb}
        viewCb={viewCb}
      />

      <BusyLoader show={loadingData} />

      <ManageHsnMasterModal
        show={showHsnFormModal}
        hideModalCb={hideHsnModalCb}
        closeModalCb={closeHsnFormModalCb}
        hsnId={actionSelectedId.current}
        mode={modalMode}
      />

      <ViewHsnMasterModal
        show={showHsnDetailsModal}
        closeModal={closeHsnDetailsModalCb}
        hsnId={actionSelectedId.current}
      />
    </>
  );
};

export default memo(HsnMasterList);
