import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchUrlQueryString } from "@sk/hooks";
import {
  AjaxService,
  AuthService,
  CategoryService,
  NavService,
  UtilityService,
} from "@sk/services";
import {
  Alert,
  AppCard,
  BusyLoader,
  NoDataFound,
  PageInfo,
  PageLoader,
  Toaster,
} from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import PrintLabelModal from "../../settings/generate-barcode/list/modals/PrintLabelModal";
import Attribute from "./components/Attribute";
import BasicDetails from "./components/BasicDetails";
import Features from "./components/Features";
import Images from "./components/Images";

const validationSchema = yup.object({
  activeTab: yup.string().default("description"),
  name: yup
    .string()
    .trim()
    .label("Category Name")
    .matches(/^[\w&(),. ]+$/, "Invalid characters found in Catgeory Name")
    .required(),
  invoiceOnOrderAmount: yup.boolean().default(false),
  debitOnMrpChange: yup.boolean().default(false),
  displayDealMrp: yup.boolean().default(true),
  isExclusiveType: yup.boolean().default(false),
  refundOnMrpChange: yup.boolean().default(false),
  stickyOrderPrice: yup.boolean().default(false),
  isFocusedCategory: yup.boolean().default(false),
  collectSerialNumber: yup.boolean().default(false),
  categoryType: yup.string().label("Category Type").required(),
  status: yup.string().label("Status").required(),
  description: yup.string(),
  maxB2BQuantity: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .when("activeTab", {
      is: (activeTab) => activeTab == "feature",
      then: (schema) => schema.max(100000).min(1).label("Max B2B Quantity"),
    }),
  maxB2BPeriod: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .when("activeTab", {
      is: (activeTab) => activeTab == "feature",
      then: (schema) => schema.label("Max B2B period"),
    }),
  mainImages: yup.array(),
  iconFileImage: yup.array(),
  hasParentCategory: yup.boolean(),
  marginDealerPrice: yup.boolean(),
  fulfillmentPolicyData: yup.string().label("Fulfillment Policy").required(),
  masterCategory: yup.array(),
  assignedGroups: yup.array(),
  parent: yup.array().when("hasParentCategory", {
    is: true,
    then: (scheme) =>
      scheme.min(1, "Please Provide Parent Category details").required(),
  }),
  focusedCategoryTagLine: yup
    .string()
    .trim()
    .when(["activeTab", "isFocusedCategory"], {
      is: (activeTab, isFocusedCategory) =>
        activeTab == "feature" && isFocusedCategory,
      then: (scheme) => scheme.label("Focused Category Tagline").required(),
    }),
  isDailyNeeds: yup.boolean(),
  dailyNeedsTagline: yup
    .string()
    .trim()
    .when(["activeTab", "isDailyNeeds"], {
      is: (activeTab, isDailyNeeds) => activeTab == "feature" && isDailyNeeds,
      then: (scheme) => scheme.label("Daily needs Tagline").required(),
    }),
  returnDays: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : 0))
    .when("noReturnsAllowed", {
      is: (noReturnsAllowed) => (noReturnsAllowed ? false : true),
      then: (scheme) =>
        scheme
          .max(999, "Allows 3 Max Character")
          .min(1, "Minimum 1 Return days")
          .label("Return days")
          .required(),
    }),
  isFmcgClassGroup: yup.boolean(false),
  noReturnsAllowed: yup.boolean().default(false),
  kingclubImages: yup.array(),
  kingclubMainImages: yup.array(),
});

const prepareParams = (data) => {
  let p = {
    assignedGroups: (data.assignedGroups || []).map((e) => e.value._id),
    attributes: data.attributes.map((e) => {
      delete e._id;
      return { ...e, name: e.name == "others" ? e.others : e.name };
    }),
    categorySubtype: data.categorySubtype,
    categoryType: data.categoryType,
    classGroup: data.classGroup.length ? data.classGroup[0] : {},
    classification: "B2B",
    collectSerialNumber: data.collectSerialNumber,
    description: data.description,
    debitOnMrpChange: data.debitOnMrpChange,
    displayDealMrp: data.displayDealMrp,
    hasParentCategory: data.hasParentCategory,
    exclusiveType: data.exclusiveType,
    fulfillmentPolicys: data.fulfillmentPolicyData
      ? [data.fulfillmentPolicyData]
      : [],
    images: (data.iconFileImage || []).map((img) => ({ image: img.asset })),
    invoiceOnOrderAmount: data.invoiceOnOrderAmount,
    isExclusiveType: data.isExclusiveType,
    mainImages: (data.mainImages || []).map((img) => img.asset),
    parent:
      data.hasParentCategory && data.parent.length
        ? data.parent[0].value._id
        : "",
    maxB2BPeriod: data.maxB2BPeriod,
    maxB2BPeriodType: data.maxB2BPeriodType,
    maxB2BQuantity: data.maxB2BQuantity,
    name: data.name,
    marginDealerPrice: data.marginDealerPrice,
    noReturnsAllowed: data.noReturnsAllowed,
    refundOnMrpChange: data.refundOnMrpChange,
    returnDays: data.returnDays,
    status: data.status,
    stickyOrderPrice: data.stickyOrderPrice,
    focusedCategoryInfo: {
      isFocusedCategory: data.isFocusedCategory,
      tagLine: data.focusedCategoryTagLine || "",
    },
    dailyNeedsInfo: {
      tagLine: data.dailyNeedsTagline,
      isDailyNeeds: data.isDailyNeeds,
    },
    whIds: [AuthService.getDefaultWhId()],
    isFmcgClassGroup: data.isFmcgClassGroup,
    kingclubImages: (data.kingclubImages || []).map((img) => ({
      image: img.asset,
    })),
    kingclubMainImages: (data.kingclubMainImages || []).map((img) => ({
      image: img.asset,
    })),
  };
  return p;
};

// const statusOptions = [
//   { value: "Active", label: "Active" },
//   { value: "Inactive", label: "Inactive" },
// ];

const tabs = [
  { key: "description", tabName: "Description" },
  { key: "attribute", tabName: "Attributes" },
  { key: "images", tabName: "Images" },
  { key: "feature", tabName: "Additional Config" },
  { key: "fulfillmentPolicy", tabName: "Fulfillment Policy" },
];

const defaultBreadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Category List",
    link: "/cms/category/list",
  },
  {
    name: "Add Category",
  },
];

const ManageCategory = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      activeTab: "description",
      attributes: [],
      masterCategory: [],
      iconFileImage: [],
      images: [],
      mainImages: [],
      classGroup: [],
      parent: [],
      attributeForm: {
        pattern: "",
      },
      displayDealMrp: true,
      kingclubImages: [],
      kingclubMainImages: [],
    },
  });
  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  const navigate = useNavigate();

  const [busyLoader, setBusyLoader] = useState({ msg: "", loading: false });

  const [breadcrumbs, setBreadCrumbs] = useState([...defaultBreadcrumbs]);

  const [display, setDisplay] = useState("loading");

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const tabCb = (data) => {
    methods.setValue("activeTab", data.value.key);
    setActiveTab({ ...data.value });
  };

  const onSubmit = async (data) => {
    let errMsg = "";
    const formValues = methods.getValues();
    for (let t of tabs) {
      try {
        await validationSchema.validate({
          ...formValues,
          activeTab: t.key,
        });
        errMsg = "";
      } catch (error) {
        errMsg = error.message || "";
      }

      if (errMsg) {
        break;
      }
    }
    if (data?.attributeForm?.mandatory && !data.attributes?.length) {
      errMsg = "Please Add Attribute Details";
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    setBusyLoader({
      msg: "please,wait submitting your response",
      loading: true,
    });
    const params = prepareParams(data);
    let r = "";
    let msg = "Category Details has been Created Successfully";
    if (query.id) {
      r = await CategoryService.update(query.id, params);
      msg = "Category Details has been Updated Successfully";
      setBusyLoader({ msg: "", loading: false });
    } else {
      r = await CategoryService.create(params);
    }
    setBusyLoader({ msg: "", loading: false });

    if (r.statusCode == 200) {
      Toaster.success(msg);
      NavService.to(navigate, "/cms/category/list");
    } else {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0]);
    }
  };

  useEffect(() => {
    const getCategoryDetails = async (id) => {
      let m = await CategoryService.getCategory(id);

      if (m?.resp?._id) {
        const r = m?.resp || {};
        // fetching classGroupList For auto Filling the classGroup
        if (r.classGroup && r.classGroup["_id"]) {
          const cr = await UtilityService.getClassGroupList({
            select: "name",
            filter: { _id: r.classGroup["_id"] },
          });
          // Setting Value
          methods.setValue(
            "classGroup",
            (cr?.resp || []).map((x) => ({
              label: x.name,
              value: x,
            }))
          );
        }

        // Form Auto fill
        const formKeys = validationSchema.getDefault();
        const ignoreFormKeys = [
          "classGroup",
          "parent",
          "assignedGroups",
          "categoryType",
        ];
        Object.keys(formKeys).forEach((key) => {
          if (ignoreFormKeys.indexOf(key) === -1) {
            if (["mainImages"].indexOf(key) != -1) {
              methods.setValue(
                key,
                r[key].map((img) => ({ asset: img }))
              );
            } else if (key === "iconFileImage") {
              methods.setValue(
                key,
                r?.images?.map((img) => ({ asset: img.image }))
              );
            } else if (
              ["kingclubImages", "kingclubMainImages"].indexOf(key) != -1
            ) {
              methods.setValue(
                key,
                r[key]?.map((img) => ({ asset: img.image }))
              );
            } else {
              methods.setValue(key, r[key]);
            }
          }
        });

        loadParentCategoryName(r.parent);
        loadAssignedGroups(r.assignedGroups);
        methods.setValue("categoryType", r._categoryType);
        methods.setValue("activeTab", "description");
        methods.setValue(
          "isFocusedCategory",
          r.focusedCategoryInfo.isFocusedCategory || false
        );
        methods.setValue(
          "isDailyNeeds",
          r.dailyNeedsInfo.isDailyNeeds || false
        );
        methods.setValue("dailyNeedsTagline", r.dailyNeedsInfo.tagLine || "");
        methods.setValue(
          "focusedCategoryTagLine",
          r.focusedCategoryInfo.tagLine || ""
        );
        methods.setValue(
          "fulfillmentPolicyData",
          r.fulfillmentPolicys.length ? r.fulfillmentPolicys[0] : ""
        );

        const l = (r.attributes || []).map((r) => {
          return {
            mandatory: r.mandatory,
            min: r.min || 0,
            max: r.max || 0,
            name: r.predefined ? r.name : "others",
            others:
              r.predefined != undefined ? (r.predefined ? "" : r.name) : "",
            pattern: r.pattern || r.csd,
            type: r.type,
            // _id: r._id,
          };
        });
        methods.setValue("attributes", l);
        setDisplay("form");
      } else {
        setDisplay("notFound");
      }
    };

    if (query.id) {
      setDisplay("loading");
      setBreadCrumbs((prevBreadcrumb) => {
        prevBreadcrumb.at(-1).name = "Edit Category";
        return prevBreadcrumb;
      });
      getCategoryDetails(query.id);
    } else {
      setDisplay("form");
    }
  }, [
    breadcrumbs,
    loadAssignedGroups,
    loadParentCategoryName,
    methods,
    query.id,
  ]);

  const loadParentCategoryName = useCallback(
    async (id) => {
      if (id) {
        let m = await CategoryService.getCategory(id);
        if (m?.resp?._id) {
          let j = [{ label: m.resp.name, value: m.resp._id }];
          methods.setValue("parent", j);
        } else {
          methods.setValue("parent", []);
        }
      } else {
        methods.setValue("parent", []);
      }
    },
    [methods]
  );

  const loadAssignedGroups = useCallback(
    async (ids) => {
      if (ids.length) {
        let p = {
          select: "name,_id",
          filter: {
            _id: { $in: ids },
          },
        };
        let m = await CategoryService.getCategoryGroup(p);

        let j = (m.resp || []).map((e) => {
          return { label: e.name, value: e };
        });
        methods.setValue("assignedGroups", j);
      } else {
        methods.setValue("assignedGroups", []);
      }
    },
    [methods]
  );

  return (
    <>
      <PrintLabelModal />
      {display == "loading" ? <PageLoader animation="border" /> : null}
      {display === "form" ? (
        <>
          <PageInfo
            title={query?.id ? "Edit Category" : "Add Category"}
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />
          <AppCard>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div>
                  <BasicDetails
                    title={query?._id ? "Edit Category" : "Add Category"}
                  />

                  <div className="px-1 border-top">
                    <div className="row mt-4">
                      <div className="col-5">
                        <Features />
                      </div>
                      <div className="col-7 ps-5">
                        <Attribute />
                      </div>
                    </div>
                  </div>

                  {/* Images */}
                  <div className="px-1 border-top">
                    <Images />
                  </div>
                  <div className="text-end py-3">
                    <button className="btn btn-lg btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </AppCard>
        </>
      ) : null}

      {display === "notFound" && <NoDataFound>No Data Found</NoDataFound>}
      <BusyLoader
        message={busyLoader.message}
        show={busyLoader.loading}
      ></BusyLoader>
    </>
  );
};

export default memo(ManageCategory);
