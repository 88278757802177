import { useCallback, useEffect } from "react";
import * as yup from "yup";
/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthService, CommonService, DealService } from "@sk/services";
import {
  Alert,
  Amount,
  BooleanCheckboxInput,
  DatePickerInput,
  InputErrorMsg,
  KeyVal,
  Spinner,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

const canViewSkLandingCost = AuthService.isValidUserRole(["ViewSkLandingCost"])
  ? true
  : false;

let startDateConfig = {
  minDate: new Date(),
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

let endDateConfig = {
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

let formBg = {
  backgroundColor: "#eff7fd",
};

const formValidation = yup.object().shape({
  hasOffer: yup.boolean(),

  validFrom: yup.date().when("hasOffer", {
    is: (hasOffer) => {
      return hasOffer;
    },
    then: (schema) =>
      schema
        .label("Start Date")
        .typeError("Valid From Date is Required")
        .required("Valid From Date is Required "),
  }),

  validTo: yup.date().when("hasOffer", {
    is: (hasOffer) => {
      return hasOffer;
    },
    then: (schema) =>
      schema
        .label("End Date")
        .typeError("Valid to Date is Required")
        .min(yup.ref("validFrom"), "Must be greater than Valid to Date")
        .required("Valid to Date is Required"),
  }),

  additionalDiscount: yup
    .number()
    .transform((val) => (!isNaN(val) ? val : 0))
    .when("hasOffer", {
      is: (hasOffer) => hasOffer,
      then: (schema) =>
        schema
          .label("Offer Discount")
          .typeError("Offer Discount is Required")
          .max(99.99)
          .required()
          .moreThan(0),
    }),

  isFixedPrice: yup.boolean(),

  discount: yup
    .number()
    .transform((val) => (!isNaN(val) ? val : null))
    .when("isFixedPrice", {
      is: (isFixedPrice) => !isFixedPrice,
      then: (schema) => schema.required().min(0),
    })
    .test(
      "max-decimals",
      "Discount cannot have more than 6 decimal places",
      (value) => {
        if (value !== undefined && value !== null) {
          const valueString = value.toString();
          const decimalPart = valueString.split(".")[1];
          if (decimalPart && decimalPart.length > 6) {
            return false;
          }
        }
        return true;
      }
    )
    .max(99.99)
    .label("Discount"),

  fixedPrice: yup
    .number()
    .nullable()
    .transform((val) => (!isNaN(val) ? val : 0))
    .test(
      "max-decimals",
      "Price cannot have more than 2 decimal places",
      (value) => {
        if (value !== undefined && value !== null) {
          const valueString = value.toString();
          const decimalPart = valueString.split(".")[1];
          if (decimalPart && decimalPart.length > 2) {
            return false;
          }
        }
        return true;
      }
    )
    .when("isFixedPrice", {
      is: (isFixedPrice) => isFixedPrice,
      then: (schema) =>
        schema
          .max(999999, "Max Price Limit Crossed")
          .min(0.1, "Please Enter Price")
          .required(),
    })
    .label("Price"),
});

const RspConfigEdit = ({ details, callback, onClickCancelEditCb, index }) => {
  // form configuration
  const {
    reset,
    getValues,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
    control,
    trigger,
  } = useForm({
    defaultValues: {
      fixedPrice: null,
      isFixedPrice: false,
      discount: null,
    },
    resolver: yupResolver(formValidation),
  });

  const [submitting, setSubmitting] = useState(false);

  const [profit, setProfit] = useState({ sk: 0, store: 0, rsp: 0 });

  const [isFixedPrice, fixedPrice, discount, hasOffer] = useWatch({
    control,
    name: ["isFixedPrice", "fixedPrice", "discount", "hasOffer"],
  });

  useEffect(() => {
    if (details?._edit) {
      autoFillForm();
    }
  }, []);

  const autoFillForm = () => {
    setValue("isFixedPrice", details?.isFixedPrice);
    setValue("fixedPrice", details?.fixedPrice);
    setValue("discount", details?.discount);
    setValue("hasOffer", details?.hasOffer);
    if (details?.hasOffer) {
      setValue("additionalDiscount", details?.specialDiscount || 0);
      setValue("validFrom", details?.validityPeriod?.startDate);
      setValue("validTo", details?.validityPeriod?.endDate);
    }
    setProfit({
      sk: details?._skProfit,
      store: details?._storeProfit,
      rsp: details?._rspPrice,
    });
  };

  const onSubmit = async (data) => {
    let f = data;
    if (!f.isFixedPrice && f.discount < 0) {
      Toaster.error("Please Enter Discount");
      return;
    }

    // check if mrptable length > 0, then match fixed price with higest mrp , if greater than throw the error.
    if (f.fixedPrice > details.mrp) {
      Toaster.error("Price should not be greater than MRP Rs." + details.mrp);
      return;
    }

    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (res.isDismissed) {
      return;
    }

    const p = preparePayload(data, details);

    setSubmitting(true);

    let r = await DealService.updateCustomerCatalogPriceConfig(p);

    setSubmitting(false);

    if (r.statusCode !== 200) {
      Toaster.error(r.resp.message || "Failed to update");
      return;
    }

    reset();

    Toaster.success("Updated Successfully");
    callback({
      status: "submit",
      data: {
        ...r.resp,
        mrp: details.mrp,
        slc: details.slc,
        b2bPrice: details.b2bPrice,
      },
      index: index,
    });
  };

  const preparePayload = (f, data) => {
    let p = {
      isFixedPrice: f.isFixedPrice,
      _id: data._id,
      id: data.id,
      franchiseId: data.franchiseInfo.id,
      configOnType: data.configOnType,
      updateData: true,
    };

    if (f.isFixedPrice) {
      p.fixedPrice = f.fixedPrice;
      p.hasOffer = false;
      p.specialDiscount = 0;
    } else {
      p.discount = f.discount;
    }
    if (f.hasOffer) {
      p.hasOffer = f.hasOffer;
      p["startDate"] = f.validFrom;
      p["endDate"] = f.validTo;
      p.specialDiscount = f.additionalDiscount;
    }

    return p;
  };

  const discountCb = useCallback(
    (e) => {
      const v = getValidDiscountVal(e.target.value);
      setValue("discount", v, { shouldValidate: true });
      let disc = getValues("hasOffer") ? getValues("additionalDiscount") : v;
      let mrp = details?.mrp;

      let rspPrice = mrp - mrp * (disc / 100);
      setProfit({
        sk: rspPrice - (details?.slc || 0),
        store: rspPrice - (details?.b2bPrice || 0),
        rsp: rspPrice,
      });
    },
    [details]
  );

  const getValidDiscountVal = (val) => {
    if (val > 100) {
      return 100;
    }
    return val ? CommonService.roundedByDecimalPlace(val, 6) : val;
  };

  const priceCb = useCallback(
    (e) => {
      const v = e.target.value;
      setValue("fixedPrice", v);
      let rspPrice = v;
      setProfit({
        sk: rspPrice - (details?.slc || 0),
        store: rspPrice - (details?.b2bPrice || 0),
        rsp: rspPrice,
      });
      // let mrp = deal.length ? deal[0]?.value?.mrp || 0 : 0;
      // let d = Math.round(((mrp - v) / mrp) * 100, 2);
      // setValue("discount", getValidDiscountVal(d));
    },
    [details, setValue, setProfit]
  );

  const onFixedPriceChange = (chngFn) => {
    return async (val) => {
      chngFn(val);
      setValue("fixedPrice", null);
      // setValue("fixedMrp", null);
      setValue("discount", 0);
      setProfit({ sk: 0, store: 0, rsp: 0 });
      await trigger("isFixedPrice");
    };
  };

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      chngFn(val);
      setValue(key, val[0]);
      if (key == "validFrom") {
        endDateConfig = { ...endDateConfig, minDate: val[0] };
      }
      trigger("validTo");
    };
  };

  const additionalDiscountCb = useCallback(
    (e) => {
      const v = getValidDiscountVal(e.target.value);
      setValue("additionalDiscount", v, { shouldValidate: true });

      let mrp = details?.mrp;

      let rspPrice = mrp - mrp * (v / 100);
      setProfit({
        sk: rspPrice - (details?.slc || 0),
        store: rspPrice - (details?.b2bPrice || 0),
        rsp: rspPrice,
      });
    },
    [details]
  );

  const onHasOfferchange = (chnfFn) => {
    return (val) => {
      chnfFn(val);

      let mrp = details?.mrp;
      let disc = getValues("hasOffer")
        ? getValues("additionalDiscount") | 0
        : getValues("discount");
      let rspPrice = mrp - mrp * (disc / 100);
      setProfit({
        sk: rspPrice - (details?.slc || 0),
        store: rspPrice - (details?.b2bPrice || 0),
        rsp: rspPrice,
      });
    };
  };

  return (
    <form className="p-2">
      <div className="row mt-3 border border-rounded-2 p-2 " style={formBg}>
        <div className="col-3">
          {/* Is RSP Fixed Price */}
          <div className="align-self-center">
            <Controller
              name="isFixedPrice"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BooleanCheckboxInput
                  label="Is RSP Stationary"
                  name="isFixedPrice"
                  error={errors?.isFixedPrice?.message}
                  layout="verticals"
                  isMandatory={false}
                  callback={onFixedPriceChange(onChange)}
                  value={value}
                />
              )}
            />
          </div>
        </div>
        <div className="col-3">
          {/* Price */}
          {isFixedPrice ? (
            <div>
              <TextInput
                type="number"
                register={register}
                name="fixedPrice"
                error={errors?.fixedPrice?.message}
                placeholder="Price (Rs.)"
                isMandatory={true}
                callback={priceCb}
              />
            </div>
          ) : null}

          {/* DISCOUNT */}
          {!isFixedPrice ? (
            <div>
              <TextInput
                type="number"
                register={register}
                name="discount"
                error={errors?.discount?.message}
                placeholder="Discount (%)"
                isMandatory={true}
                callback={discountCb}
                disabled={isFixedPrice}
              />
            </div>
          ) : null}
        </div>

        {/* HAS OFFER CHECKBOX */}
        {!isFixedPrice ? (
          <div className="col-3">
            <Controller
              name="hasOffer"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BooleanCheckboxInput
                  label="Has Offer ?"
                  name="hasOffer"
                  error={errors?.hasOffer?.message}
                  layout="verticals"
                  isMandatory={false}
                  callback={onHasOfferchange(onChange)}
                  value={value}
                />
              )}
            />
          </div>
        ) : null}

        {/* OFFER DATE BLOCK */}
        {hasOffer && !isFixedPrice && (
          <>
            {/* ADDITIONAL DISCOUNT */}
            <div className="col-3">
              <TextInput
                type="number"
                register={register}
                name="additionalDiscount"
                error={errors?.additionalDiscount?.message}
                placeholder="Offer Discount(%)"
                isMandatory={true}
                callback={additionalDiscountCb}
              />
            </div>

            <div className="col-4 pb-3">
              {/* <label className="form-label mb-0">
                Offer Start Date
                <span className="text-danger">*</span>
              </label> */}
              <Controller
                control={control}
                name="validFrom"
                render={({ field: { onChange, value } }) => {
                  return (
                    <DatePickerInput
                      placeholder="Start Date"
                      value={[value]}
                      inpChange={dateChangeCb(onChange, "validFrom")}
                      config={startDateConfig}
                    />
                  );
                }}
              />
              <InputErrorMsg msg={errors?.validFrom?.message} />
            </div>
            <div className="col-4 pb-3">
              {/* <label className="form-label mb-0">
                Offer End Date
                <span className="text-danger">*</span>
              </label> */}
              <Controller
                control={control}
                name="validTo"
                render={({ field: { onChange, value } }) => (
                  <DatePickerInput
                    placeholder="End Date"
                    value={[value]}
                    inpChange={dateChangeCb(onChange, "validTo")}
                    config={endDateConfig}
                  />
                )}
              />
              <InputErrorMsg msg={errors?.validTo?.message} />
            </div>
          </>
        )}
        <div className="col-6 p-0 m-0">
          <div className="row p-0 m-0">
            <div className="col-3 ps-3">
              <KeyVal label="RSP" template="col">
                <span
                  className={classNames({
                    "text-center": true,
                  })}
                >
                  <Amount value={profit.rsp} decimalPlace={2} />
                </span>
              </KeyVal>
            </div>

            <div className="col-5 ps-3">
              <KeyVal label="Store Profit" template="col">
                <span
                  className={classNames({
                    "text-center": true,
                    "text-success": profit.store > 0,
                    "text-danger": profit.store < 0,
                    "text-primary": profit.store == 0,
                  })}
                >
                  <Amount value={profit.store} decimalPlace={2} />
                </span>
              </KeyVal>
            </div>
            <div className="col-4">
              {canViewSkLandingCost ? (
                <KeyVal label="SK Profit" template="col">
                  <span
                    className={classNames({
                      "text-center": true,
                      "text-success": profit.sk > 0,
                      "text-danger": profit.sk < 0,
                      "text-primary": profit.sk == 0,
                    })}
                  >
                    <Amount value={profit.sk} decimalPlace={2} />
                  </span>
                </KeyVal>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-auto ms-auto text-end me-3 py-3">
          <button
            className="btn btn-sm me-3 btn-outline-danger"
            onClick={() => {
              onClickCancelEditCb(index);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={handleSubmit(onSubmit)}
            disabled={submitting}
          >
            Save {submitting ? <Spinner isSmall={true} /> : null}
          </button>
        </div>
      </div>
    </form>
  );
};

export default RspConfigEdit;
