import { yupResolver } from "@hookform/resolvers/yup";
import { AjaxService, CategoryService, ProductService } from "@sk/services";
import {
  AutoCompleteInput,
  BusyLoader,
  PageLoader,
  SelectInput,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

// const cessValueTypeOptions = [
//   { label: "%", value: "%" },
//   { label: "Rs", value: "Rs" },
// ];

const gstOptions = [
  { label: "0%", value: "0" },
  { label: "3%", value: "3" },
  { label: "5%", value: "5" },
  { label: "12%", value: "12" },
  { label: "18%", value: "18" },
  { label: "28%", value: "28" },
  { label: "36%", value: "36" },
  { label: "40%", value: "40" },
];

const statusOption = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
];

const validationSchema = yup.object({
  hsn: yup
    .string()
    .trim()
    .label("HSN")
    .min(4, "HSN Min length should be 4")
    .max(8, "HSN Max length should be 8")
    .required(),
  hsndesc: yup.string().trim().label("Description").required(),
  gst: yup.string().label("GST").required(),
  cess: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .max(100, "Cess should not be greater than 100"),
  addCessValue: yup
    .number()
    .nullable()
    .transform((v) => (isFinite(v) ? v : null))
    .min(0, "Additional Cess Value should not be less than 0")
    .label("Additonal Cess Value ")
    .required()
    .when("addCessValueType", {
      is: (addCessValueType) => addCessValueType == "%",
      then: (scheme) =>
        scheme.max(100, "Additional Cess Value should not be greater than 100"),
    })
    .when("addCessValueType", {
      is: (addCessValueType) => addCessValueType == "Rs",
      then: (scheme) =>
        scheme.max(
          999999,
          "Additional Cess Value should not be greater than 6 Digits"
        ),
    }),
  addCessValueType: yup.string().label("Cess Value Type").required(),
  linkedCategories: yup.array(),
  status: yup.string(),
});

const ManageHsnMasterModal = ({
  show,
  hideModalCb,
  closeModalCb,
  hsnId,
  mode,
}) => {
  const {
    register,
    control,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [display, setDisplay] = useState("loading");
  const [busyLoader, setBusyLoader] = useState({ message: "", loading: false });
  useEffect(() => {
    const getHsnDetails = async (id) => {
      let m = await ProductService.getHsnById(id);
      if (m?.resp && m?.resp?._id) {
        const r = m?.resp || {};
        // Form Auto fill
        const formKeys = validationSchema.getDefault();
        const ignoreFormKeys = ["linkedCategories"];
        Object.keys(formKeys).forEach((key) => {
          if (ignoreFormKeys.indexOf(key) === -1) {
            setValue(key, r[key]);
          }
        });
        // linkedCategories
        r?.linkedCategories?.length
          ? setValue(
              "linkedCategories",
              (r.linkedCategories || [])
                .filter((i) => i.id && i.name)
                .map((k) => {
                  return { value: k.id, label: k.name };
                })
            )
          : null;
        setDisplay("form");
      } else {
        setDisplay("notFound");
      }
    };
    if (hsnId) {
      setDisplay("loading");
      getHsnDetails(hsnId);
    } else {
      setDisplay("form");
    }
    if (show) {
      reset();
      setValue("addCessValueType", "%");
    }
  }, [hsnId, mode, show]);

  const onSubmit = async (d) => {
    let params = prepareParams(d);
    let p = "";
    setBusyLoader({ message: "Please Wait", loading: true });
    if (hsnId) {
      p = await ProductService.updateHsnById(hsnId, params);
    } else {
      p = await ProductService.createHsnMasters(params);
    }
    setBusyLoader({ message: "Please Wait", loading: false });
    if (p.statusCode == 200) {
      let t = mode == "Add" ? "Created" : "Updated" + " Successfully";
      let text = "HSN Code has been " + t;
      Toaster.success(text);
      resetCloseModal("submit");
    } else {
      let error = AjaxService.parseError(p.resp);
      Toaster.error(error.msg);
    }
  };

  const resetHideModal = () => {
    reset();
    hideModalCb();
  };
  const resetCloseModal = (status = "cancel") => {
    reset();
    closeModalCb({ status });
  };

  const prepareParams = (d) => {
    let p = {
      hsn: d.hsn.trim(),
      hsndesc: d.hsndesc.trim(),
      gst: d.gst * 1,
      cess: d.cess || 0,
      addCessValueType: d.addCessValueType,
      addCessValue: d.addCessValue || 0,
      linkedCategories: (d.linkedCategories || []).map((e) => {
        return { id: e.value, name: e.label };
      }),
      status: d.status,
    };
    return p;
  };

  const onCategorySearch = useCallback(async (val, callback) => {
    let filter = {};

    if (val) {
      let v = { $regex: val, $options: "i" };
      filter = { $or: [{ name: v }, { _id: v }] };
    }
    let p = {
      page: 1,
      count: 10,
      filter: filter,
    };

    const r = await CategoryService.getList(p);
    callback((r?.resp || []).map((x) => ({ label: x.name, value: x._id })));
  }, []);

  const onCategoryChanges = useCallback(
    (chngFn) => (val) => {
      chngFn(val);
      setValue("linkedCategories", val);
    },
    []
  );

  const onChangeCessValueType = () => {
    let v = getValues("addCessValueType");
    setValue("addCessValueType", v == "%" ? "Rs" : "%", {
      shouldValidate: true,
    });
  };

  const hsnInputCb = (val) => {
    let d = val.target.value.replace(/[a-zA-Z\s]/g, "");
    setValue("hsn", d);
  };

  if (!show) {
    return <></>;
  }

  return (
    <>
      <Offcanvas
        show={show}
        onHide={resetHideModal}
        placement="end"
        style={{ width: "45%" }}
      >
        {display == "loading" ? <PageLoader animation="border" /> : null}
        {display === "form" ? (
          <div>
            <Offcanvas.Header closeButton className="bg-primary">
              <Offcanvas.Title>
                <div className="page-title">
                  {mode == "add" ? "Add" : "Edit"} HSN Code
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-3 border-top">
              <div className="mx-2 my-2">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                  noValidate
                >
                  <div className="row mt-3">
                    <div className="col-6">
                      <TextInput
                        register={register}
                        label="HSN"
                        error={errors?.hsn?.message}
                        isMandatory={true}
                        name="hsn"
                        type="text"
                        callback={hsnInputCb}
                        note="Min:4 digits, max: 8 digits, no space , allow leading zeros (ex:00034345)"
                      />
                    </div>
                    <div className="col-12">
                      <TextareaInput
                        register={register}
                        label="HSN Description"
                        error={errors?.hsndesc?.message}
                        isMandatory={true}
                        name="hsndesc"
                        rows="5"
                        cols="10"
                      />
                    </div>
                    <div className="col-3">
                      <SelectInput
                        register={register}
                        label="GST (%)"
                        error={errors?.gst?.message}
                        isMandatory={false}
                        options={gstOptions}
                        name="gst"
                      />
                    </div>
                    <div className="col-3">
                      <TextInput
                        register={register}
                        label="Cess (%)"
                        error={errors?.cess?.message}
                        isMandatory={false}
                        name="cess"
                        type="number"
                      />
                    </div>
                    <div className="col-3">
                      <div className="input-group">
                        <TextInput
                          register={register}
                          label="Additional Cess Value"
                          error={errors?.addCessValue?.message}
                          isMandatory={false}
                          name="addCessValue"
                          type="number"
                          groupContent={
                            <button
                              className="btn  btn-link  fs-5 pt-0"
                              style={{ height: "25px" }}
                              type="button"
                              onClick={onChangeCessValueType}
                            >
                              {getValues("addCessValueType")}
                            </button>
                          }
                        />
                      </div>
                    </div>

                    <div className="col-3">
                      <SelectInput
                        register={register}
                        label="Status"
                        error={errors?.status?.message}
                        isMandatory={false}
                        options={statusOption}
                        name="status"
                      />
                    </div>
                    <div className="col-12">
                      <Controller
                        control={control}
                        name="linkedCategories"
                        render={({ field: { onChange, value } }) => (
                          <AutoCompleteInput
                            label="Link  Category"
                            error={errors?.linkedCategories?.message}
                            placeholder="Search by Category name"
                            value={value}
                            isMandatory={false}
                            onSearch={onCategorySearch}
                            callback={onCategoryChanges(onChange)}
                            uid="linkedCategories"
                            isMultiple={true}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row  mt-4">
                    <div className="col-12 text-end">
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Offcanvas.Body>
          </div>
        ) : null}
      </Offcanvas>
      <BusyLoader
        message={busyLoader.message}
        show={busyLoader.loading}
      ></BusyLoader>
    </>
  );
};

export default ManageHsnMasterModal;
