import { AppCard, PageInfo } from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import FixedComboManageModal from "../modals/manage-modal/FixedComboManageModal";
import { DealService } from "@sk/services";
import FixedComboListTable from "./components/FixedComboListTable";
import FixedComboViewModal from "../modals/view-modal/FixedComboViewModal";
import FixedComboCloneModal from "../modals/clone-modal/FixedComboCloneModal";
import FixedComboFilter from "./components/FixedComboFilter";

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Fixed Combos",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const getData = async (params) => {
  const res = await DealService.getFixedCombo(params);
  return { data: Array.isArray(res.resp) ? res.resp : [] };
};

const getCount = async (params) => {
  const res = await DealService.getFixedComboCount(params);
  return { count: res.resp };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  if (filter.search) {
    p.filter.$or = [
      { _id: filter.search },
      { title: { $regex: filter.search, $options: "i" } },
    ];
  }

  if (filter.store?.length > 0) {
    p.filter.franchiseId = filter.store?.[0]?.value?._id;
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (Object.keys(p.filter).length === 0) {
    delete p.filter;
  }

  return p;
};

const FixedComboList = () => {
  const [manageModal, setManageModal] = useState({
    show: false,
  });

  const [viewModal, setViewModal] = useState({
    show: false,
    id: null,
  });

  const [cloneModal, setCloneModal] = useState({
    show: false,
    id: null,
  });

  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const filterDataRef = useRef({});

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const d = r.data || [];
    setData(d);
    setLoadingData(false);
  }, []);

  const handleManageModalCallback = () => {
    setManageModal({ show: false });
  };

  const tableCb = (e) => {
    if (e.action === "edit") {
      setManageModal({ show: true, id: e.id });
    }
    if (e.action === "view") {
      setViewModal({ show: true, id: e.id });
    }
    if (e.action === "clone") {
      setCloneModal({ show: true, id: e.id });
    }
  };

  const handleViewModalCallback = () => {
    setViewModal({ show: false, id: null });
  };

  const handleCloneModalCallback = () => {
    setCloneModal({ show: false, id: null });
  };

  const onFilterCb = (e) => {
    if (e.action === "apply" || e.action === "reset") {
      filterDataRef.current = { ...filterDataRef.current, ...e.formData };
      applyFilter();
    }
  };

  return (
    <>
      <div className="row my-3">
        <div className="col align-self-center">
          <PageInfo title="Fixed Combos" breadcrumbs={breadcrumbs} noMargin />
        </div>
        <div className="col text-end">
          <button
            className="btn btn-primary"
            onClick={() => setManageModal({ show: true })}
          >
            <i className="bi bi-plus-square bg-lg"></i>
            <span> Create Combo </span>
          </button>
        </div>
      </div>

      <FixedComboFilter callback={onFilterCb} />

      <AppCard>
        <FixedComboListTable
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={loadList}
          loadingTotalRecords={loadingTotalRecords}
          callback={tableCb}
        />
      </AppCard>

      <FixedComboManageModal
        show={manageModal.show}
        callback={handleManageModalCallback}
        selectedProducts={manageModal.selectedProducts}
        editId={manageModal.id}
      />

      <FixedComboViewModal
        show={viewModal.show}
        callback={handleViewModalCallback}
        configId={viewModal.id}
      />

      <FixedComboCloneModal
        show={cloneModal.show}
        onHide={handleCloneModalCallback}
        configId={cloneModal.id}
      />
    </>
  );
};

export default FixedComboList;
