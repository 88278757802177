import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import each from "lodash/each";
import orderBy from "lodash/orderBy";
import { memo, useEffect, useState } from "react";
import RenderProductLinkedCount from "./RenderProductLinkedCount";

const defaultHeaders = [
  { label: "Sl No", width: "auto", isCentered: true, priority: 1 },
  { label: "Category Name/ID", priority: 2 },
  { label: "Parent Category Name/ID", isCentered: true, priority: 3 },
  { label: "Department Name/ID", isCentered: true, priority: 3 },
  {
    label: "Is FMCG Group",
    isCentered: true,
    width: "auto",
    priority: 4,
  },
  { label: "Invoice On Order Amount", isCentered: true, priority: 5 },
  { label: "Status", isCentered: true, priority: 6 },
  { label: "Actions", isCentered: true, priority: 14 },
];

const rbac = {
  viewCategory: ["ViewCategory"],
  editCategory: ["EditCategory"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editCb,
  moreColumns = [],
}) => {
  const viewDetails = (id) => {
    NavService.openInNewTab("/cms/category/view", { id: id });
  };

  const [headers, setHeaders] = useState([...defaultHeaders]);

  useEffect(() => {
    let t = [];
    each(moreColumns || {}, (x) => {
      t.push(x);
    });
    setHeaders(orderBy([...defaultHeaders, ...t], ["priority"], ["asc"]));
  }, [moreColumns]);

  return (
    <>
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody className="fs-val-md">
          {/* Table Loader */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* No Data */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Category Found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {/* List  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id}>
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* Category Name */}
                <td>
                  <div
                    className="text-primary"
                    onClick={() => {
                      viewDetails(x?._id);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {x?.name || "N/A"} - {x?._id}
                  </div>
                </td>

                {/* Parent Category */}
                <td className="text-center">
                  {x?._parent?.name ? (
                    <span
                      className="text-primary"
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        viewDetails(x?._parent?._id);
                      }}
                    >
                      {x?._parent?.name} -{x?._parent?._id}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* Department */}
                <td className="text-center">
                  {x?.department?._id ? (
                    <span
                      className="text-primary"
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        viewDetails(x?.department?._id);
                      }}
                    >
                      {x?.department.name} - {x?.department?._id}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </td>

                {/* Class Group */}
                <td className="text-center">
                  <HighlightText
                    status={x?.isFmcgClassGroup ? "YES" : "NO"}
                    isBadge={false}
                  />
                </td>

                {/* Invoice On Order Amount */}
                <td className="text-center">
                  <HighlightText
                    status={x?.invoiceOnOrderAmount ? "YES" : "NO"}
                    isBadge={false}
                  />
                </td>

                {/* Status */}
                <td className="text-center">
                  <div
                    className={classNames("text-uppercase", {
                      "text-danger": x?.status != "Active",
                      "text-success": x?.status == "Active",
                    })}
                  >
                    {x?.status}
                  </div>
                </td>

                {/*Linked Products */}
                {moreColumns?.linkedProduct?.checked ? (
                  <td className="text-center">
                    <RenderProductLinkedCount cid={x?._id} />
                  </td>
                ) : null}

                {/* Is Focused Category  */}
                {moreColumns?.isFocusedCategory?.checked ? (
                  <td className="text-center">
                    <HighlightText
                      status={
                        x?.focusedCategoryInfo?.isFocusedCategory ? "YES" : "NO"
                      }
                      isBadge={false}
                    />
                  </td>
                ) : null}

                {/* Is Daily Needs */}
                {moreColumns?.isDailyNeeds?.checked ? (
                  <td className="text-center">
                    <HighlightText
                      status={x?.dailyNeedsInfo?.isDailyNeeds ? "YES" : "NO"}
                      isBadge={false}
                    />
                  </td>
                ) : null}

                {/*  Display Deal MRP */}
                {moreColumns?.displayDealMrp?.checked ? (
                  <td className="text-center">
                    <HighlightText
                      status={x?.displayDealMrp ? "YES" : "NO"}
                      isBadge={false}
                    />
                  </td>
                ) : null}

                {/* Return Allowed */}
                {moreColumns?.noReturnsAllowed?.checked ? (
                  <td className="text-center">
                    <HighlightText
                      status={x?.noReturnsAllowed ? "YES" : "NO"}
                      isBadge={false}
                    />
                  </td>
                ) : null}

                {/*Linked Products */}
                {moreColumns?.createdAt?.checked ? (
                  <td className="text-center">
                    <DateFormatter date={x.createdAt} />
                  </td>
                ) : null}

                {/* Action block */}
                <td className="text-center">
                  <Rbac roles={rbac.viewCategory}>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => {
                        viewDetails(x?._id);
                      }}
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.editCategory}>
                    <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-xs"
                      onClick={() => {
                        editCb(x?._id);
                      }}
                    >
                      Edit
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        showSummary={false}
      />
    </>
  );
};

export default memo(Table);
