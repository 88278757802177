import { AuthService } from "@sk/services";
import { EntitySearchInput, SelectInput } from "@sk/uis";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const defaultFromValues = {
  warehouse: AuthService.getDefaultWhId(),
  vendor: [],
};

const Filter = ({ callback }) => {
  const { control, register, getValues } = useForm({
    defaultValues: defaultFromValues,
  });
  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  const onControlledInputChange = useCallback(
    (chngFn) => (e) => {
      chngFn(e);
      onFilterChange();
    },
    [onFilterChange]
  );
  return (
    <>
      <form autoComplete="off">
        <div className="row">
          <div className="col-3">
            <Controller
              control={control}
              name="vendor"
              key="vendor"
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type="vendor"
                  label="Search For Vendor"
                  placeholder="Search by Id/Name"
                  value={value}
                  callback={onControlledInputChange(onChange)}
                  uid="vendor"
                  emptyLabel="vendor"
                />
              )}
            />
          </div>

          <div className="col-auto ">
            <SelectInput
              label="Warehouse"
              callback={onFilterChange}
              register={register}
              name="warehouse"
              isMandatory={false}
              options={warehouseOptions}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
