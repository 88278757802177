import { Offcanvas } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
  Alert,
  AppCard,
  BusyLoader,
  EntitySearchInput,
  HighlightText,
  PageLoader,
  Spinner,
  TextInput,
  Toaster,
} from "@sk/uis";
import FixedComboProductTable from "./components/FixedComboProductTable";
import { Controller, useForm } from "react-hook-form";
import { DealService, FranchiseService } from "@sk/services";
import produce from "immer";
import FixedComboProductSelectModal from "../product-selection/FixedComboProductSelectModal";
import FixedComboQtyEditModal from "../qty-edit-modal/FixedComboQtyEditModal";

const canvasStyle = {
  width: "90%",
};

const franchiseFilterParams = FranchiseService.getDarkstoreFilter();

const FixedComboManageModal = ({ show = false, callback, editId }) => {
  const { register, handleSubmit, control, getValues, setValue } = useForm();

  const [loading, setLoading] = useState(false);
  const [categoryFilterParams, setCategoryFilterParams] = useState({});

  const [productSelectModal, setProductSelectModal] = useState({
    show: false,
    filterData: null,
  });
  const [qtyEditModal, setQtyEditModal] = useState({
    show: false,
    product: null,
    categoryId: null,
  });

  const [submitting, setSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [busyLoader, setBusyLoader] = useState({ show: false, message: "" });

  useEffect(() => {
    if (show && editId) {
      const fetchData = async () => {
        setLoading(true);
        const res = await DealService.getFixedCombo({
          filter: {
            _id: editId,
          },
        });
        if (res.statusCode === 200) {
          const d = res.resp?.[0] || {};
          setValue("title", d.title);
          setValue("combo_price", d.offerAmount);
          setValue("store", [
            {
              label: d.franchiseName,
              value: { _id: d.franchiseId, name: d.franchiseName },
            },
          ]);
          setCategories(
            (d.cartDetails || []).map((e) => ({
              _id: e.category.id,
              name: e.category.name,
              products: (e.dealsList || []).map((deal) => ({
                _id: deal.dealId,
                name: deal.name,
                mrp: deal.mrp,
                comboQty: deal.qty,
                allowedUnitTypes: deal.allowedUnitTypes,
                sellInLooseQty: deal.sellInLooseQty,
                images: [deal.image],
              })),
            }))
          );
        } else {
          setCategories([]);
        }
        setLoading(false);
      };
      fetchData();
    }
  }, [show, editId, setValue]);

  const closeModal = () => {
    callback({ action: "close" });
  };

  const onCategoryChange = (change) => (value) => {
    change([]);
    if (value?.length > 0) {
      const isDuplicate = categories.some(
        (cat) => cat._id === value[0]?.value?._id
      );
      if (!isDuplicate) {
        setCategories(
          produce((draft) => {
            draft.unshift({ ...value[0]?.value, products: [] });
          })
        );
        Toaster.success(value[0]?.value?.name + " selected successfully");
      } else {
        Toaster.error("Category already selected");
      }
    }
  };

  const productCb = async (data) => {
    if (data.action === "selectProduct") {
      const storeId = getValues("store")?.[0]?.value?._id || "";
      setCategories(
        produce((draft) => {
          const category = draft.find((e) => e._id === data.categoryId);
          if (category) {
            category.productAction = "";
          }
        })
      );
      setProductSelectModal({
        show: true,
        filterData: {
          storeId: storeId,
          categoryId: data.categoryId,
          categoryName: data.categoryName,
          pids:
            categories
              .find((e) => e._id === data.categoryId)
              ?.products.map((e) => ({ id: e._id, qty: e.comboQty })) || [],
        },
      });
    }

    if (data.action === "editQty") {
      setQtyEditModal({
        show: true,
        product: data.data.product,
        categoryId: data.data.categoryId,
      });
    }

    if (data.action === "removeProduct") {
      setCategories(
        produce((draft) => {
          const category = draft.find((e) => e._id === data.categoryId);
          if (category) {
            category.products = category.products.filter(
              (p) => p._id !== data.productId
            );
          }
        })
      );
    }
  };

  const handleProductSelectModalCallback = (data) => {
    if (data.action === "proceed") {
      const categoryId = productSelectModal.filterData.categoryId;
      setCategories(
        produce((draft) => {
          const category = draft.find((e) => e._id === categoryId);
          if (category) {
            category.productAction = "productUpdate";
            category.products = [...data.data, ...(category.products || [])];
          }
        })
      );
    }
    setProductSelectModal({ show: false, filterData: null });
  };

  const onSubmit = async () => {
    const f = getValues();
    const payload = {
      title: f.title,
      offerAmount: f.combo_price,
      franchiseId: f.store?.[0]?.value?._id,
      franchiseName: f.store?.[0]?.value?.name,
      cartDetails: categories.map((category) => ({
        category: {
          id: category._id,
          name: category.name,
        },
        dealsList: category.products.map((product) => ({
          dealId: product._id,
          name: product.name,
          qty: 1 * product.comboQty,
          image: product.images?.[0] || "",
          mrp: product.mrp,
          sellInLooseQty: product.sellInLooseQty,
          allowedUnitTypes: product.allowedUnitTypes,
        })),
      })),
    };

    setSubmitting(true);

    let res;
    if (editId) {
      res = await DealService.updateFixedCombo(editId, payload);
    } else {
      res = await DealService.createFixedCombo(payload);
    }
    setSubmitting(false);
    if (res.statusCode === 200) {
      Toaster.success("Fixed combo created successfully");
      closeModal();
    } else {
      Toaster.error(res.message || "Something went wrong");
    }
  };

  const onRemoveCategory = async (categoryId, categoryName) => {
    const r = await Alert.confirm({
      title: "Confirm removal of category?",
      text: `This action will remove the category "${categoryName}" from the combo.`,
      okText: "Remove",
      cancelText: "Cancel",
    });
    if (r.isConfirmed) {
      setCategories(
        produce((draft) => {
          const index = draft.findIndex((e) => e._id === categoryId);
          if (index > -1) {
            draft.splice(index, 1);
          }
        })
      );
    }
  };

  const handleQtyEditModalCallback = ({
    action,
    data: { product, quantity },
  }) => {
    if (action === "qtyUpdate") {
      setCategories(
        produce((draft) => {
          const categoryIndex = draft.findIndex(
            (e) => e._id === qtyEditModal.categoryId
          );
          if (categoryIndex > -1) {
            draft[categoryIndex].products = draft[categoryIndex].products.map(
              (p) => (p._id === product._id ? { ...p, comboQty: quantity } : p)
            );
          }
        })
      );
    }
    setQtyEditModal({ show: false, product: null, categoryId: null });
  };

  return (
    <>
      <Offcanvas
        show={show}
        placement="end"
        onHide={closeModal}
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          <Offcanvas.Title>
            <div className="fs-val-lg">Manage Fixed Combo</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {loading ? (
            <PageLoader />
          ) : (
            <>
              <AppCard>
                <div className="row">
                  <div className="col">
                    <Controller
                      name="store"
                      control={control}
                      render={({ field }) => (
                        <EntitySearchInput
                          label="Store"
                          type="franchise"
                          value={field.value}
                          callback={field.onChange}
                          uid="fixed-combo-franchise"
                          filterParams={franchiseFilterParams}
                          isMandatory={true}
                        />
                      )}
                    />
                  </div>
                  <div className="col">
                    <TextInput
                      label="Title"
                      name="title"
                      register={register}
                      isMandatory={true}
                    />
                  </div>
                  <div className="col-2">
                    <TextInput
                      label="Combo Price (₹)"
                      name="combo_price"
                      register={register}
                      type="number"
                      isMandatory={true}
                    />
                  </div>
                </div>
              </AppCard>

              <AppCard>
                <div className="mb-2 fs-val-sm text-secondary">
                  Please select the category for the combo.
                </div>
                <div className="row">
                  <div className="col-6">
                    <Controller
                      name="category"
                      control={control}
                      render={({ field }) => (
                        <EntitySearchInput
                          type="category"
                          value={field.value}
                          callback={onCategoryChange(field.onChange)}
                          uid="fixed-combo-category"
                          isMandatory={true}
                          filterParams={categoryFilterParams}
                          gap={0}
                          placeholder="Search for Category..."
                        />
                      )}
                    />
                  </div>
                  <div className="col-auto ms-auto align-self-end">
                    <HighlightText type="success" template={2}>
                      <i className="bi bi-info-circle me-1"></i>
                      Total Categories: {categories.length}
                    </HighlightText>
                  </div>
                  <div className="col-auto align-self-end">
                    <HighlightText type="success" template={2}>
                      <i className="bi bi-info-circle me-1"></i>
                      Total Products:{" "}
                      {categories.reduce(
                        (acc, curr) => acc + curr.products.length,
                        0
                      )}
                    </HighlightText>
                  </div>
                </div>
              </AppCard>

              {categories.map((category, index) => (
                <AppCard
                  key={category._id}
                  title={
                    <div className="d-flex align-items-center">
                      <div className="card-title flex-grow-1">
                        {index + 1}. {category.name} ({category._id})
                      </div>
                      <div>
                        <button
                          className="btn btn-link text-danger p-0 fs-val-sm text-decoration-none"
                          onClick={() =>
                            onRemoveCategory(category._id, category.name)
                          }
                        >
                          <i className="bi bi-trash"></i>
                          <span className="ms-1">Remove</span>
                        </button>
                      </div>
                    </div>
                  }
                >
                  <FixedComboProductTable
                    products={category.products}
                    categoryId={category._id}
                    categoryName={category.name}
                    callback={productCb}
                    storeId={getValues("store")?.[0]?.value?._id}
                    configId={editId}
                    action={category.productAction}
                  />
                </AppCard>
              ))}

              <div className="bg-white p-3 shadow-sm rounded-3 text-end position-sticky bottom-0 z-100">
                <button
                  className="btn btn-primary"
                  onClick={onSubmit}
                  disabled={submitting}
                >
                  <span>Submit</span>
                  {submitting && <Spinner size="sm" />}
                </button>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <FixedComboProductSelectModal
        show={productSelectModal.show}
        callback={handleProductSelectModalCallback}
        filterData={productSelectModal.filterData}
      />

      <FixedComboQtyEditModal
        productDetails={qtyEditModal.product}
        show={qtyEditModal.show}
        callback={handleQtyEditModalCallback}
      />

      <BusyLoader show={busyLoader.show} message={busyLoader.message} />
    </>
  );
};

export default FixedComboManageModal;
