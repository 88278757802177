import { useAttachAdditionalData } from "@sk/hooks";
import { DealService, FranchiseService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import { orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import KingSlabAuditLog from "../components/KingSlabAuditLog";

const defaultheaders = [
  { label: "Sl No", width: "10%", isCentered: true },
  { label: "Min Qty" },
  { label: "Max Qty" },
  { label: "Discount %" },
];
const defaultfixedPriceheaders = [
  { label: "Sl No", width: "10%", isCentered: true },
  { label: "Min Qty" },
  { label: "Max Qty" },
  { label: "Fixed Price (Rs)" },
];

const franchiseHeader = [
  { label: "Sl No", width: "10%", isCentered: true },
  { label: "Franchise ID" },
  { label: "Franchise Name" },
];

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_lastUpdatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "dealId",
    api: "deal",
    loadingKey: "dealLoading",
    dataKey: "_deal",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "fids",
    api: "franchise",
    loadingKey: "franchiseLoading",
    dataKey: "_franchise",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];
const canvasStyle = { width: "65%" };
const ViewKingSlabModal = ({ show, closeModalCb, id }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();
  const [header, setHeader] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const [mainDealStockDetails, setMainDealStockDetails] = useState({
    loading: false,
    data: {},
  });

  const getKingSlabDetails = useCallback(async () => {
    setLoading(true);
    const r = await DealService.getPriceSlabById(id);

    const d = r.resp || {};
    d.dealLoading = true;
    d.userLoading = true;
    d.franchiseLoading = true;
    if (d.isFixedPrice) {
      setHeader([...defaultfixedPriceheaders]);
    } else {
      setHeader([...defaultheaders]);
    }
    setData(d);
    loadStockDetails(d.dealId, d.fids);
    setLoading(false);

    if (d._id) {
      // Sorting Audit Log
      d.auditLog = orderBy(d.auditLog, "loggedAt", "desc");

      let tmp = [];
      // Attach User Info
      setAdditionalData([d], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    } else {
      Toaster.error("Failed to fetch data, please try again");
      closeModalCb();
    }
  }, [attachAllData, closeModalCb, id, setAdditionalData]);

  const loadStockDetails = useCallback(async (id, fid) => {
    setMainDealStockDetails({ loading: true, data: {} });
    // let r = await DealService.getBulkDealStock(AuthService.getDefaultWhId(), {
    //   dealIds: id,
    // });
    // let d = Array.isArray(r.resp) && r.resp?.length ? r.resp[0] : {};
    let p = {
      groupbycond: "deal",
      groupbycondName: "name",
      dealFilter: { _id: { $in: [id] } },
      filter: { _id: fid[0] },
    };
    let r = await FranchiseService.getFranchiseInventorySummary(p);
    let d = Array.isArray(r.resp) && r.resp?.length ? r.resp[0] : {};

    setMainDealStockDetails({ loading: false, data: d });
  }, []);

  useEffect(() => {
    if (show && id) {
      getKingSlabDetails(id);
    }
  }, [show, id, getKingSlabDetails]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModalCb}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <Offcanvas.Title>
            <div className="page-title"> Kingslab - POS #{data?._id} </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  <AppCard noShadow={true}>
                    <div className="row">
                      <div className="col-6 mb-4">
                        <KeyVal label="Deal Name" template="col">
                          {data.dealLoading ? (
                            <Spinner type="dots" />
                          ) : (
                            data?._deal?.["name"]
                          )}
                        </KeyVal>
                      </div>
                      <div className="col-3 mb-4">
                        <KeyVal template="col" label="Deal ID">
                          {data?.dealId}
                        </KeyVal>
                      </div>

                      <div className="col-3 mb-4">
                        <KeyVal label="Status" template="col">
                          <HighlightText
                            status={data.isActive ? "Active" : "Inactive"}
                            isBadge={true}
                          />
                        </KeyVal>
                      </div>

                      {data?.groupType?.length ? (
                        <div className="col-3 mb-4">
                          <KeyVal label="Group Type" template="col">
                            {data.groupType.length > 0 &&
                              data.groupType.map((g) => (
                                <span
                                  key={g}
                                  className="badge badge-soft-warning text-uppercase fw-bold me-1"
                                >
                                  {g}
                                </span>
                              ))}
                          </KeyVal>
                        </div>
                      ) : null}

                      <div className="col-3 mb-4">
                        {show && data.slab && (
                          <KeyVal label="MRP" template="col">
                            <Amount value={data?.slab[0]?.mrp} />
                          </KeyVal>
                        )}
                      </div>

                      <div className="col-3 mb-4">
                        {show && data.slab && (
                          <KeyVal label="Stock Qty" template="col">
                            {mainDealStockDetails.loading ? (
                              <span>
                                <Spinner isSmall={true} />
                              </span>
                            ) : (
                              <span className="fs-val-md align-top">
                                {/* {mainDealStockDetails?.data?.availableStock ||
                                  0} */}
                                {mainDealStockDetails?.data?._qty || 0}{" "}
                                {mainDealStockDetails?.data?._displayUnitKey}
                              </span>
                            )}
                          </KeyVal>
                        )}
                      </div>

                      <div className="col-12">
                        <div className="row">
                          {/* Created At */}
                          <div className="col-3 mb-4">
                            <KeyVal label="Created At" template="col">
                              <DateFormatter date={data.createdAt} />
                            </KeyVal>
                          </div>

                          {/* Created By */}
                          <div className="col-3 mb-4">
                            <KeyVal label="Created By" template="col">
                              {data.userLoading ? (
                                <Spinner type="dots" />
                              ) : (
                                data?._createdBy?.name
                              )}
                            </KeyVal>
                          </div>

                          {/* Modified At */}
                          <div className="col-3 mb-4">
                            {data.modifiedAt && (
                              <KeyVal label="Last Modified At" template="col">
                                <DateFormatter date={data.modifiedAt} />
                              </KeyVal>
                            )}
                          </div>

                          {/* Modified By */}
                          <div className="col-3 mb-4">
                            {data.modifiedAt && (
                              <KeyVal label="Modified By" template="col">
                                {data?._lastUpdatedBy?.name}
                              </KeyVal>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AppCard>

                  {/* Franchise Details */}
                  {data?.fids?.length ? (
                    <>
                      <div className="fw-semibold fs-val-lg px-4 py-3">
                        Franchise Details
                      </div>

                      <table className="table table-bordered table-sm">
                        <TableHeader data={franchiseHeader} />
                        <tbody>
                          {data.franchiseLoading ? (
                            <TableSkeletonLoader
                              cols={franchiseHeader.length}
                              rows={5}
                            />
                          ) : null}

                          {!data.franchiseLoading &&
                            (data._franchise || []).map((x, index) => (
                              <tr key={x._id} className="fs-val-md">
                                <td className="px-3 py-2 text-center">
                                  {1 + index}
                                </td>
                                <td className="px-3 py-2">{x?._id}</td>
                                <td className="px-3 py-2">{x?.name}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  ) : null}

                  {/* King Slab Table */}
                  <div className="fw-semibold fs-val-lg px-4 py-3">
                    Kingslab
                  </div>

                  <table className="table table-bordered table-sm">
                    <TableHeader data={header} />
                    <tbody>
                      {(data.slab || []).map((x, index) => (
                        <tr key={x._id} className="fs-val-md">
                          <td className="px-3 py-2 text-center">{1 + index}</td>
                          <td className="px-3 py-2">{x?.min}</td>
                          <td className="px-3 py-2">{x?.max}</td>
                          {!data?.isFixedPrice ? (
                            <td className="px-3 py-2">{x?.disc}</td>
                          ) : (
                            <td className="px-3 py-2">
                              <Amount value={x?.price} />
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* Audit Log Table */}
                  <KingSlabAuditLog auditLogs={data.auditLog} />
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewKingSlabModal;
