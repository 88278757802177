import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, WarehouseService } from "@sk/services";
import { AppCard, BusyLoader } from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const defaultFormData = {
  snapshotId: "",
  warehouse: AuthService.getDefaultWhId(),
  createdAt: [],
  referenceType: "",
  status: "",
};

const defaultSortOpt = { key: "mrp", value: "desc" };

const attachAdditionalDataConfig = [
  //   {
  //     key: "createdBy",
  //     api: "user",
  //     loadingKey: "userLoading",
  //     dataKey: "_createdBy",
  //     filter: (ids) => ({
  //       page: 1,
  //       count: ids.length,
  //       filter: { _id: { $in: ids } },
  //       select: "name",
  //     }),
  //   },
];

const prepareFilterParams = (pagination = {}, filter = {}) => {
  let p = {
    page: pagination.activePage,
    limit: pagination.rowsPerPage,
    data: {
      productId: filter.pid,
    },
  };
  if (filter.warehouse) {
    // p.data.warehouse = filter.warehouse;
  }

  if (filter.snapshotId.trim()) {
    p.data.snapShotId = filter.snapshotId;
  }

  if (filter.referenceType) {
    p.data.referenceType = filter.referenceType;
  }
  if (filter.type) {
    p.data.type = filter.type;
  }

  if (filter.createdAt?.length) {
    p.data.createdAt = {
      $gte: set(filter?.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  return p;
};

const getData = async (params) => {
  const r = await WarehouseService.stockLedgerList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const StockLedgerHistory = ({ pId }) => {
  const [data, setData] = useState([]);

  const paginationRef = useRef({ ...defaultPageOpt });

  const sortRef = useRef({ ...defaultSortOpt });

  const filterDataRef = useRef({ ...defaultFormData });

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  useEffect(() => {
    if (pId) {
      filterDataRef.current = {
        ...filterDataRef.current,
        pid: pId,
      };
      applyFilter();
    }
  }, [applyFilter, pId]);

  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(params);
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const applyFilter = useCallback(async () => {
    if (pId) {
      loadList();

      // for total records
      setLoadingTotalRecords(true);
      const p = getFilterParams();

      const c = await getCount(p);
      paginationRef.current.totalRecords = c.count;
      setLoadingTotalRecords(false);
    }
  }, [getCount, getFilterParams, loadList, pId]);

  const getCount = useCallback(async (params) => {
    delete params.limit;
    delete params.page;
    params.count = 1;

    try {
      const r = await WarehouseService.stockLedgerList(params);
      return { count: r.statusCode == 200 && r.resp ? r.resp.count : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = {
        ...filterDataRef.current,
        ...data.formData,
      };
      paginationRef.current = { ...defaultPageOpt };
      applyFilter();
    },
    [applyFilter]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <AppCard>
        <Filter callback={filterFormCb} />
      </AppCard>

      <AppCard>
        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          sort={sortRef.current}
          sortCb={sortCb}
        />
      </AppCard>
      <BusyLoader show={loadingData} />
    </>
  );
};

export default StockLedgerHistory;
