import {
  Amount,
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";
import { memo, useCallback, useRef, useState } from "react";
import PrintLabelModal from "../modals/PrintLabelModal";

const headers = [
  { label: "Sl.no" },
  { label: "Product Name " },
  { label: "Deal ID" },
  { label: "Barcode" },
  { label: "MRP" },
  { label: "Price" },
  { label: "Quantity" },
  { label: "Created At" },
  // { label: "Status" },
  { label: "Action" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  const [showPrintModal, setPrintModal] = useState(false);
  const barcodeVal = useRef(null);
  const printRef = useRef(null);
  const closePrintModal = useCallback(() => setPrintModal(false), []);

  const handlePrint = (data) => {
    printRef.current = data?._id;
    barcodeVal.current = data?.code;
    setPrintModal(true);
  };

  const onPrintHideCb = () => {
    setPrintModal(false);
  };
  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {data.map((x, index) => (
            <tr key={x?._id}>
              <td>{paginationConfig.startSlNo + index}</td>
              <td>{x?.productName}</td>
              <td>{x?.dealId}</td>
              <td>{x?.code}</td>
              <td>
                <Amount value={x?.mrp} decimalPlace="2" />
              </td>
              <td>
                <Amount value={x?.price} decimalPlace="2" />
              </td>
              <td>
                {x?.unit} {x?.unitOfMeasurement}
              </td>
              <td>
                <div>{x?._createdBy?.name}</div>
                <DateFormatter
                  date={x?.createdAt}
                  format="dd MMM yyyy hh:mm a"
                />
              </td>
              {/* <td>
                <HighlightText status={x.status} />
              </td> */}
              <td>
                <button
                  onClick={() => handlePrint(x)}
                  className="btn btn-link px-0"
                >
                  <i className="bi bi-printer"></i> Print
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
      <PrintLabelModal
        show={showPrintModal}
        closePrintModal={closePrintModal}
        id={printRef.current}
        printHideCb={onPrintHideCb}
        barcodeVal={barcodeVal.current}
      />
    </>
  );
};

export default memo(Table);
