import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { Navigate, RouterProvider, createHashRouter } from "react-router-dom";
import Layout from "./pages/Layout";
import ListBrand from "./pages/brand/list/ListBrand";
import ViewBrand from "./pages/brand/view/ViewBrand";
import ListCategory from "./pages/category/list/ListCategory";
import ManageCategory from "./pages/category/manage/ManageCategory";
import ViewCategory from "./pages/category/view/ViewCategory";
import ViewDeal from "./pages/deal/view/ViewDeal";
import FixedComboList from "./pages/fixed-combo/list/FixedComboList";
import OutOfStockRequestList from "./pages/out-of-stock-request/OutOfStockRequestList";
import ListKingSlab from "./pages/priceSlab/pos/list/ListKingSlab";
import ListProduct from "./pages/product/list/ListProduct";
import ManageProduct from "./pages/product/manage/ManageProduct";
import ViewProduct from "./pages/product/view/ViewProduct";
import BarcodeList from "./pages/settings/generate-barcode/list/BarcodeList";
import HsnMasterList from "./pages/settings/hsn-master/list/HsnMasterList";
import ListPosSmartCoins from "./pages/settings/posSmartCoins/list/ListPosSmartCoins";
import RspConfigList from "./pages/settings/rsp-config/list/RspConfigList";
import StoreComboList from "./pages/store-combo/list/StoreComboList";
import CmsOverview from "./pages/overview/CmsOverview";
import ListDeal from "./pages/deal/list/ListDeal";

const roles = {
  brand: ["ViewBrand"],
  category: ["ViewCategory"],
  product: ["EditProduct", "AddProduct"],
  deal: ["EditDeal", "AddDeal"],
  kingSlab: ["POSAddKingSlab", "POSViewKingSlab"],
  outOfStockRequests: ["ViewOutOfStockRequest"],
  barcode: ["GenerateBarcode"],
  rspConfig: ["EditRSPConfig", "ListRSPConfig", "ViewRSPConfig"],
  storeCombo: ["EditStoreCombo", "ListStoreCombo", "AddStoreCombo"],
  posSmartCoins: ["ADDSCCONFIG", "VIEWSCCONFIG", "EDITSCCONFIG"],
};

const router = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "cms",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Navigate to="/cms/category/manage"></Navigate>,
          },

          {
            path: "brand/list",
            element: (
              <ProtectedRoute roles={roles.brand}>
                <ListBrand />
              </ProtectedRoute>
            ),
          },

          {
            path: "out-of-stock-requests",
            element: (
              <ProtectedRoute roles={roles.outOfStockRequests}>
                <OutOfStockRequestList />
              </ProtectedRoute>
            ),
          },

          {
            path: "brand/view",
            element: (
              <ProtectedRoute roles={roles.brand}>
                <ViewBrand />
              </ProtectedRoute>
            ),
          },
          {
            path: "category/list",
            element: (
              <ProtectedRoute roles={roles.category}>
                <ListCategory />
              </ProtectedRoute>
            ),
          },
          {
            path: "category/view",
            element: (
              <ProtectedRoute roles={roles.category}>
                <ViewCategory />
              </ProtectedRoute>
            ),
          },
          {
            path: "category/manage",
            element: (
              <ProtectedRoute roles={roles.category}>
                <ManageCategory />
              </ProtectedRoute>
            ),
          },
          {
            path: "product/manage",
            element: (
              <ProtectedRoute roles={roles.product}>
                <ManageProduct />
              </ProtectedRoute>
            ),
          },
          {
            path: "product/list",
            element: (
              <ProtectedRoute roles={roles.product}>
                <ListProduct />
              </ProtectedRoute>
            ),
          },
          {
            path: "product/view",
            element: (
              <ProtectedRoute roles={roles.product}>
                <ViewProduct />
              </ProtectedRoute>
            ),
          },
          // {
          //   path: "deal/manage",
          //   element: (
          //     <ProtectedRoute roles={roles.deal}>
          //       <ManageDeal />
          //     </ProtectedRoute>
          //   ),
          // },
          {
            path: "deal/list",
            element: (
              <ProtectedRoute roles={roles.deal}>
                <ListDeal />
              </ProtectedRoute>
            ),
          },
          {
            path: "deal/view",
            element: (
              <ProtectedRoute roles={roles.deal}>
                <ViewDeal />
              </ProtectedRoute>
            ),
          },

          {
            path: "settings/generate-barcode",
            element: (
              <ProtectedRoute roles={roles.barcode}>
                <BarcodeList />
              </ProtectedRoute>
            ),
          },
          {
            path: "settings/hsn-master",
            element: (
              <ProtectedRoute roles={roles.barcode}>
                <HsnMasterList />
              </ProtectedRoute>
            ),
          },
          {
            path: "settings/rsp-config",
            element: (
              <ProtectedRoute roles={roles.rspConfig}>
                <RspConfigList />
              </ProtectedRoute>
            ),
          },
          {
            path: "kingslab/pos",
            element: (
              <ProtectedRoute roles={roles.kingSlab}>
                <ListKingSlab />
              </ProtectedRoute>
            ),
          },
          {
            path: "settings/pos-smartcoins",
            element: (
              <ProtectedRoute roles={roles.posSmartCoins}>
                <ListPosSmartCoins />
              </ProtectedRoute>
            ),
          },

          {
            path: "store-combo-config",
            element: (
              <ProtectedRoute roles={roles.storeCombo}>
                <StoreComboList />
              </ProtectedRoute>
            ),
          },

          {
            path: "fixed-combo/list",
            element: (
              <ProtectedRoute>
                <FixedComboList />
              </ProtectedRoute>
            ),
          },

          {
            path: "overview",
            element: (
              <ProtectedRoute>
                <CmsOverview />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
