import {
  Amount,
  AppCard,
  DisplayWarehouseName,
  HighlightText,
  KeyVal,
  NoDataFound,
  TableHeader,
} from "@sk/uis";

const preOrderWhHeader = [
  { label: "Sl.no" },
  { label: "Warehouse" },
  { label: "Quantity" },
  { label: "GroupCode" },
];

const classClassificationHeader = [
  { label: "Sl.no", isCentered: true },
  { label: "Classification" },
  { label: "Warehouse" },
];

const BasicDetails = ({ data }) => {
  const copyCode = () => {
    navigator.clipboard.writeText(data.HSNNumber);
  };

  return (
    <>
      {!data._id && <NoDataFound>No Data Found </NoDataFound>}
      {data._id && (
        <>
          <div className="row">
            <div className="col-7">
              {/* Price Details */}
              <AppCard title="Price & Quantity" noShadow={true}>
                <div className="row">
                  <div className="col-6">
                    <KeyVal label="MRP" labelCol="col-6" contentCol="col-6">
                      : <Amount value={data.mrp} />
                    </KeyVal>

                    <KeyVal
                      label="Dealer Price"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : <Amount value={data.dealer_price} />
                    </KeyVal>

                    <KeyVal
                      label="HSN Number"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      :{" "}
                      <span className="fw-semibold">
                        {data.HSNNumber || "N/A"}{" "}
                        <span
                          onClick={copyCode}
                          className="text-primary cursor-pointer fs-val-sm  ps-2"
                        >
                          <i className="bi bi-file-earmark fs-val-md"></i> copy
                        </span>
                      </span>
                    </KeyVal>

                    <KeyVal label="Tax" labelCol="col-6" contentCol="col-6">
                      : <span className="fw-semibold">{data.tax || 0} % </span>
                    </KeyVal>
                  </div>

                  <div className="col-6">
                    <KeyVal
                      label="Retail Margin"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.retailMargin || 0} %{" "}
                    </KeyVal>

                    <KeyVal
                      label="Inner Pack Qty"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.innerPackQty || 0}
                    </KeyVal>

                    <KeyVal
                      label="Case Qty"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.caseQty || 0}
                    </KeyVal>

                    {data.additionalCessValue > 0 ? (
                      <KeyVal
                        label="Cess Value"
                        labelCol="col-6"
                        contentCol="col-6"
                      >
                        :{" "}
                        {data.additionalCessType == "percentage" ? (
                          <span className="fw-semibold">
                            {data.additionalCessValue || 0} %
                          </span>
                        ) : (
                          <span className="fw-semibold">
                            <Amount value={data.additionalCessValue || 0} />
                          </span>
                        )}
                      </KeyVal>
                    ) : null}
                  </div>
                </div>
              </AppCard>

              {/* Reserve Order */}
              {/* <AppCard title="Reserve Order">
            <KeyVal label="Allow Reserve Order">
              <HighlightText status={data.allowReserveOrder ? "Yes" : "No"} />
            </KeyVal>

            <table className="table table-bordered">
              <TableHeader data={preOrderWhHeader} />
              <tbody>
                {data.preorderWhList.length == 0 && (
                  <tr>
                    <td colSpan={preOrderWhHeader.length}>
                      <NoDataFound>No Data Found</NoDataFound>
                    </td>
                  </tr>
                )}

                {data.preorderWhList.length > 0 &&
                  data.preorderWhList.map((x, i) => (
                    <tr key={i} className="fs-val-md">
                      <td>{i + 1}</td>
                      <td>
                        <DisplayWarehouseName whId={x.whId} />
                      </td>
                      <td className="text-center">{x.preOrderLimit}</td>
                      <td>
                        <span className="badge badge-soft-warning">
                          {x.groupCode || "N/A"}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </AppCard> */}

              <AppCard title="Additional Details">
                <div className="row">
                  <div className="col-6">
                    <KeyVal
                      label="Allow Reserve Order"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      <HighlightText
                        type={data.allowReserveOrder ? "success" : "danger"}
                        template={2}
                      >
                        {data.allowReserveOrder ? "Yes" : "No"}
                      </HighlightText>
                    </KeyVal>

                    <KeyVal
                      label="Is Auto Barcode"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      <HighlightText
                        type={data.isAutoBarcode ? "success" : "danger"}
                        template={2}
                      >
                        {data.isAutoBarcode ? "Yes" : "No"}
                      </HighlightText>
                    </KeyVal>

                    <KeyVal
                      label="Barcode In Product Master"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.barcode || "N/A"}
                    </KeyVal>

                    <KeyVal
                      label="Company Name"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.companyName || "N/A"}
                    </KeyVal>

                    <KeyVal
                      label="Barcode In Inventory"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {"N/A"}
                    </KeyVal>

                    <KeyVal
                      label="Invoice Separately"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      <HighlightText
                        type={data.invoice_seperately ? "success" : "danger"}
                        template={2}
                      >
                        {data.invoice_seperately ? "Yes" : "No"}
                      </HighlightText>
                    </KeyVal>

                    <KeyVal
                      label="Country of Origin"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.originCountry || "N/A"}
                    </KeyVal>

                    {/* <KeyVal label="Pull Days" labelCol="col-6" contentCol="col-6">
                  {data.pullDays || 0}{" "}
                </KeyVal> */}

                    <KeyVal
                      label="SKU Code"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.skuCode || "N/A"}
                    </KeyVal>
                  </div>

                  <div className="col-6">
                    <KeyVal
                      label="Shelf Life"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.shelf_life || 0}
                    </KeyVal>

                    <KeyVal
                      label="Pack Size"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.packSize || "N/A"}
                    </KeyVal>

                    <KeyVal
                      label="Package Type"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      : {data.packagingType || "N/A"}
                    </KeyVal>

                    <KeyVal label="UOM" labelCol="col-6" contentCol="col-6">
                      : {data.UoM || "N/A"}
                    </KeyVal>

                    <KeyVal label="Weight" labelCol="col-6" contentCol="col-6">
                      : {data?.measure?.weight || "N/A"}
                    </KeyVal>

                    <KeyVal
                      label="Is HSN/GST Data Verified"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      <HighlightText
                        type={data.isGSTDataVerified ? "success" : "danger"}
                        template={2}
                      >
                        {data.isGSTDataVerified ? "Yes" : "No"}
                      </HighlightText>
                    </KeyVal>
                    <KeyVal
                      label="Delivery Chalan"
                      labelCol="col-6"
                      contentCol="col-6"
                    >
                      <HighlightText
                        type={data.delivery_chalan ? "success" : "danger"}
                        template={2}
                      >
                        {data.delivery_chalan ? "Yes" : "No"}
                      </HighlightText>
                    </KeyVal>
                  </div>
                </div>
              </AppCard>
            </div>

            <div className="col-5">
              <AppCard title="Class KQAJ Classification">
                <table className="table table-bordered table-sm">
                  <TableHeader data={classClassificationHeader} />
                  <tbody>
                    {!data?.classClassifications?.length ? (
                      <tr className="fs-val-md">
                        <td colSpan={classClassificationHeader?.length}>
                          <NoDataFound> No Classification Found </NoDataFound>
                        </td>
                      </tr>
                    ) : null}

                    {data?.classClassifications?.map((x, i) => (
                      <tr className="fs-val-md" key={"classification" + i}>
                        <td className="text-center">{i + 1}</td>
                        <td> {x.name} </td>
                        <td>
                          <DisplayWarehouseName whId={x.whId} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AppCard>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BasicDetails;
