import { CommonService, DealService } from "@sk/services";
import { useEffect, useState, useRef, useCallback } from "react";
import {
  AppCard,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
  PaginationSummary,
  DateFormatter,
  Spinner,
  HighlightText,
  Amount,
} from "@sk/uis";
import { useAttachAdditionalData } from "@sk/hooks";
import ViewDealWarehousePriceFilter from "./ViewDealWarehousePriceFilter";
import { startOfDay, endOfDay } from "date-fns";

const attachAdditionalDataConfig = [
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedByLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "createdBy",
    api: "user",
    loadingKey: "createdByLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const containerStyle = {
  maxHeight: "calc(100vh - 200px)",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "1800px",
};

const ViewDealWarehousePriceConfig = ({ dealId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });

  const filterRef = useRef({
    search: "",
  });

  const paginationRef = useRef({
    ...defaultPagination,
  });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (dealId) {
      filterRef.current = {
        ...filterRef.current,
        dealId: dealId,
      };
      applyFilter();
    }
  }, [applyFilter, dealId]);

  const loadList = useCallback(async () => {
    const p = prepareParams(filterRef.current, paginationRef.current);
    setLoading(true);
    const response = await getData(p);
    const dataWithLoading = response.data.map((item) => ({
      ...item,
      modifiedByLoading: true,
      createdByLoading: true,
    }));
    setData(dataWithLoading);
    setLoading(false);

    if (dataWithLoading.length > 0) {
      let tmp = [];
      setAdditionalData(dataWithLoading, attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length === attachAdditionalDataConfig.length) {
          const updatedData = [...attachAllData(dataWithLoading, tmp)];
          setData(updatedData);
        }
      });
    }
  }, [setAdditionalData, attachAllData]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };

    loadList();

    const p = prepareParams(filterRef.current, paginationRef.current);
    setRecords({ loading: true, value: 0 });
    const count = await getCount(p);
    setRecords({ loading: false, value: count });
    paginationRef.current.totalRecords = count;
  }, [loadList]);

  const paginationCb = (newPagination) => {
    paginationRef.current = {
      ...paginationRef.current,
      startSlNo: newPagination.startSlNo,
      endSlNo: newPagination.endSlNo,
      activePage: newPagination.activePage,
    };
    loadList();
  };

  const filterCb = (data) => {
    filterRef.current = {
      ...filterRef.current,
      ...data.formData,
    };
    applyFilter();
  };

  return (
    <>
      <AppCard>
        <ViewDealWarehousePriceFilter callback={filterCb} />
      </AppCard>
      <AppCard>
        <PaginationSummary
          loadingTotalRecords={loading}
          paginationConfig={paginationRef.current}
          className="mb-3"
        />
        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table"
          style={containerStyle}
        >
          <table
            className="table table-sm mb-0 bg-white table-hover"
            style={tableStyle}
          >
            <TableHeader data={tableHeader} noBg={true} isSticky={true} />
            <tbody className="fs-val-sm">
              {loading ? (
                <TableSkeletonLoader cols={tableHeader.length} rows={10} />
              ) : data.length === 0 ? (
                <tr>
                  <td colSpan={tableHeader.length} className="text-center">
                    <NoDataFound>No Data Found</NoDataFound>
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr key={item._id || index}>
                    <td>{paginationRef.current.startSlNo + index}</td>
                    <td>{item._id}</td>
                    <td className="text-uppercase">{item.level}</td>
                    <td>{item.whId}</td>
                    <td>{item.type}</td>
                    <td>
                      <Amount value={item.mrp} decimalPlace={2} />
                    </td>
                    <td className="bg-success bg-opacity-10">
                      <Amount value={item.price} decimalPlace={2} />
                    </td>
                    <td>
                      {CommonService.roundedByDecimalPlace(item.margin, 2)}%
                    </td>
                    <td>
                      <HighlightText
                        type={item.isActive ? "success" : "danger"}
                        template={2}
                      >
                        {item.isActive ? "Yes" : "No"}
                      </HighlightText>
                    </td>
                    <td>
                      <DateFormatter date={item.validFrom} />
                    </td>
                    <td>
                      <DateFormatter date={item.validTo} />
                    </td>
                    <td>
                      <DateFormatter date={item.modifiedAt} />
                    </td>
                    <td>
                      {item.modifiedByLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        item._modifiedBy?.name || "--"
                      )}
                    </td>
                    <td>
                      <DateFormatter date={item.createdAt} />
                    </td>
                    <td>
                      {item.createdByLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        item._createdBy?.name || "--"
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <PaginationBlock
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={records.loading}
        />
      </AppCard>
    </>
  );
};

const tableHeader = [
  { label: "Sl.no", width: "5%" },
  { label: "Config ID", width: "10%" },
  { label: "Level", width: "15%" },
  { label: "Warehouse", width: "8%" },
  { label: "Type", width: "10%" },
  { label: "MRP", width: "8%" },
  { label: "Price", width: "8%" },
  { label: "Margin", width: "8%" },
  { label: "Is Active", width: "8%" },
  { label: "Valid From", width: "15%" },
  { label: "Valid To", width: "15%" },
  { label: "Last Updated On", width: "15%" },
  { label: "Last Updated By", width: "15%" },
  { label: "Created On", width: "15%" },
  { label: "Created By", width: "15%" },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params) => {
  const d = await DealService.getWarehouseConfig(params);
  return {
    data: Array.isArray(d.resp) ? d.resp : [],
  };
};

const getCount = async (params) => {
  const p = { ...params };
  delete p.page;
  delete p.count;
  const d = await DealService.getWarehouseConfigCount(p);
  return d.resp;
};

const prepareParams = (filter, pagination) => {
  const { activePage, rowsPerPage } = pagination;
  const { dealId, status } = filter;

  const params = {
    page: activePage,
    count: rowsPerPage,
    filter: {
      dealId,
    },
  };

  if (filter.search) {
    params.filter.$or = [
      { whId: { $regex: filter.search, $options: "i" } },
      { level: { $regex: filter.search, $options: "i" } },
      { _id: { $regex: filter.search, $options: "i" } },
    ];
  }

  if (status) {
    params.filter.isActive = status === "Active" ? true : false;
  }

  if (filter.validityFrom?.length > 0) {
    params.filter.validFrom = { $gte: startOfDay(filter.validityFrom[0]) };
  }

  if (filter.validityTo?.length > 0) {
    params.filter.validTo = { $lte: endOfDay(filter.validityTo[0]) };
  }

  // if (validity?.length > 0) {
  //   params.filter.validFrom = { $gte: startOfDay(validity[0]) };
  //   params.filter.validTo = { $lte: endOfDay(validity[1]) };
  // }

  return params;
};

export default ViewDealWarehousePriceConfig;
