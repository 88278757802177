import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";
import notLinkedImg from "../../assets/imgs/not-linked.svg";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Manage Brands",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active Count",
      value: 0,
      loading: true,
      filter: { status: "Active" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Inactive Count",
      value: 0,
      loading: true,
      filter: { status: "Inactive" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Product Not Linked",
      value: 0,
      type: "productNotLinked",
      loading: true,
      filter: {},
      color: "danger",
      img: notLinkedImg,
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "_masterId",
      api: "brand",
      loadingKey: "masterBrandLoading",
      dataKey: "_masterData",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    isExclusiveType: {
      label: "Is Exclusive Type",
    },
    isMasterBrand: {
      label: "Is Master Brand",
    },
    isValueBrand: {
      label: "Is Value Brand",
    },
    brand: {
      label: "Brand",
    },
    status: {
      label: "Status",
    },
    masterBrand: {
      label: "Master Brand",
      valuePath: "[0].value.name",
    },
    hideUnlinkedBrands: {
      label: "Product Not Linked",
      type: "Boolean",
    },
  },
  // Form Labels Ends Here

  filterFromData: {
    status: "",
    isValueBrand: "",
    isMasterBrand: "",
    brand: "",
    masterBrand: [],
    hideUnlinkedBrands: false,
  },
  // Filter From Data Ends Here
  moreTableColumns: [
    {
      label: "Active Brand",
      key: "activeProduct",
      checked: false,
      priority: 4,
      isCentered: true,
    },
    {
      label: "Inactive Brand",
      key: "inactiveProduct",
      checked: false,
      priority: 5,
      isCentered: true,
    },
    {
      label: "Is Value Brand",
      key: "isValueBrand",
      checked: false,
      priority: 6,
      isCentered: true,
    },
    {
      label: "Is Master Brand",
      key: "isMasterBrand",
      checked: true,
      priority: 7,
      isCentered: true,
    },
    {
      label: "Created By & Date ",
      key: "createdAt",
      checked: false,
      priority: 11,
      isCentered: true,
      enableSort: true,
    },
  ],
  //
};
// LIST VIEW EDS HERE

// FOR DETAIL VIEW START
export const detailsView = {
  tabs: {
    linkedDeals: {
      selectOptions: {
        catalogTypeOptions: [
          { label: "All", value: "" },
          { label: "SK", value: "sk" },
          { label: "Seller", value: "seller" },
        ],
        statusOptions: [
          { label: "All", value: "" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
        ],
      },
      // SelectOptions Ends
      filterFromData: {
        deal: "",
        category: [],
        status: "",
        catalogType: "",
      },
      // Filter Ends Here
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Deal ID" },
        { label: "Name", width: "30%" },
        { label: "Catalog Type", isCentered: true },
        { label: "Products" },
        { label: "Category/ID" },
        { label: "Status" },
        { label: "MRP" },
      ],
      // TableHeader Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 5,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 5,
      },
      // Pagination Ends Here
      additionalTableDataConfig: [
        {
          key: "category",
          api: "category",
          loadingKey: "categoryLoading",
          dataKey: "_category",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // additional Table Data
    },
    // Linked Deals End
    linkedProducts: {
      selectOptions: {
        statusOptions: [
          { label: "All", value: "" },
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
      },
      // SelectOptions Ends
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Product ID" },
        { label: "Name" },
        { label: "Category ID" },
        { label: "Category Name" },
        { label: "Status" },
        { label: "Barcode" },
        { label: "MRP" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        product: "",
        category: [],
        status: "",
      },
      // Filter Form Data ends Here
      additionalTableDataConfig: [
        {
          key: "category",
          api: "category",
          loadingKey: "categoryLoading",
          dataKey: "_category",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // additional Table Data
    },
    // Linked Products Ends Here

    linkedVendors: {
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Vendor ID" },
        { label: "Vendor Name" },
        { label: "Linked Warehouses", width: "20%" },
        { label: "Location" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        vendor: "",
        whId: "",
      },
    },
    // Linked Vendors Here
    linkedOrders: {
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Order ID" },
        { label: "Ordered Date" },
        { label: "Amount", isCentered: true },
        { label: "Franchise ID" },
        { label: "Franchise Name" },
        { label: "Order Status" },
        { label: "Logistics Status" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 5,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 15,
      },
      // Pagination Ends Here
      filterFormData: {
        order: "",
        createdAt: [],
      },
      // Filter Form Ends Here
    },
    // linked Orders Ends Here,
    brandMargin: {
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Vendor ID" },
        { label: "Vendor Name" },
        { label: "Category ID" },
        { label: "Category Name" },
        { label: "Margin", isCentered: true },
        { label: "Start Date", isCentered: true },
        { label: "End Date", isCentered: true },
      ],

      additionalTableDataConfig: [
        {
          key: "_brandMarginCatId",
          api: "category",
          loadingKey: "_brandMarginCatLoading",
          dataKey: "_brandMarginCat",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],

      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        category: [],
        vendor: "",
      },
    },
  },
};
// DETAILS VIEW END

export default listView;
