import { SwitchInput, TextInput } from "@sk/uis";
import { Controller, useFormContext } from "react-hook-form";
import { manageView } from "../../../constantService";

const Setting = () => {
  const { control, errors, register } = useFormContext();
  return (
    <div className="row my-4 p-3">
      {/* Disable For Reserve */}
      <div className="col-2">
        <Controller
          control={control}
          name="disableForReserve"
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label="Disable For Reserved"
              isMandatory={false}
              value={value}
              callback={onChange}
            />
          )}
        />
      </div>

      {/* Disable For PO */}
      <div className="col-2 mb-3">
        <Controller
          control={control}
          name="disableForPo"
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label="Disable For PO"
              isMandatory={false}
              value={value}
              callback={onChange}
            />
          )}
        />
      </div>

      {/* Invoice Separately */}
      <div className="col-2 mb-3">
        <Controller
          control={control}
          name="invoice_seperately"
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label="Invoice Separately"
              isMandatory={false}
              value={value}
              callback={onChange}
            />
          )}
        />
      </div>

      {/* IS HSN/GST */}
      <div className="col-2 mb-3">
        <Controller
          control={control}
          name="isGSTDataVerified"
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label="IS HSN/GST data verified"
              isMandatory={false}
              value={value}
              callback={onChange}
            />
          )}
        />
      </div>

      {/* Permanently Deactivate */}
      <div className="col-2 mb-3">
        <Controller
          control={control}
          name="permanently_deactivate"
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label="Permanently Deactivate"
              isMandatory={false}
              value={value}
              callback={onChange}
            />
          )}
        />
      </div>

      {/* Delivery Chalan */}
      <div className="col-2 mb-3">
        <Controller
          control={control}
          name="delivery_chalan"
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label="Delivery Chalan"
              isMandatory={false}
              value={value}
              callback={onChange}
            />
          )}
        />
      </div>

      {/* Country of Origin */}
      <div className="col-3 mb-3">
        <TextInput
          type="text"
          label="Country of Origin"
          error={errors?.companyName?.message}
          name="originCountry"
          placeholder="Enter Country Name Here"
          isMandatory={false}
          register={register}
          info={manageView.infoContext.originCountry}
        />
      </div>

      {/* SKU Code  */}
      <div className="col-2 mb-3">
        <TextInput
          type="text"
          label="SKU Code"
          error={errors?.skuCode?.message}
          name="skuCode"
          placeholder="Enter HSN Number Here"
          isMandatory={false}
          register={register}
          info={manageView.infoContext.skuCode}
        />
      </div>
    </div>
  );
};

export default Setting;
