import { ProductService } from "@sk/services";
import {
  BooleanCheckboxInput,
  EntitySearchInput,
  SwitchInput,
  TextInput,
  Toaster,
} from "@sk/uis";
import { useCallback } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { manageView } from "../../constantService";

const activeFilter = { filter: { status: "Active" } };

const BasicDetails = ({ setBusyLoader }) => {
  const {
    register,
    control,
    getValues,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  //  To Generate Barcode
  const generateBarcode = async () => {
    setBusyLoader({ msg: "please wait ..", loading: true });
    const r = await ProductService.getBarcode();
    setBusyLoader({ msg: "please wait ..", loading: false });
    if (r.statusCode == 200) {
      setValue("barcode", r?.resp._id + "");
      Toaster.success("Barcode added Successfully");
    } else {
      Toaster.error("Something went wrong..");
    }
  };

  const barcode = useWatch({
    control,
    name: "isAutoBarcode",
  });

  const onCategorySelect = useCallback(
    (chngFn) => async (val) => {
      chngFn(val);
      let attributesList = val.map((x) => {
        let item = getValues("attributesList").find((i) => i.name === x.label);
        if (item) {
          return item;
        } else {
          return {
            name: x.label,
            attributes: x.value.attributes,
          };
        }
      });
      setValue("attributesList", attributesList);
      await trigger("attributesList");
    },
    [setValue, trigger, getValues]
  );

  const onAutoBarcodeChange = (chngFn) => (e) => {
    chngFn(e);
    const isAutoBarCode = getValues("isAutoBarcode");
    if (isAutoBarCode) {
      generateBarcode();
    }
  };

  return (
    <>
      <div className="row mt-3">
        {/* Product Name */}
        <div className="col-6">
          <TextInput
            type="text"
            label="Product Name"
            placeholder="Enter Product Name Here"
            register={register}
            name="name"
            error={errors?.name?.message}
            isMandatory={true}
            info={manageView.infoContext.productName}
          />
        </div>

        {/* Linked Product  */}
        <div className="col-3">
          <Controller
            control={control}
            name="masterProduct"
            key="product"
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type="product"
                label="Link Master Product"
                name="masterProduct"
                placeholder="Search by  Id/Name"
                error={errors?.masterProduct?.message}
                value={value}
                callback={onChange}
                uid="search-product"
                emptyLabel="No Product Found"
              />
            )}
          />
        </div>

        {/* Status */}
        <div className="col-3">
          <Controller
            control={control}
            name="is_active"
            render={({ field: { onChange, value } }) => (
              <SwitchInput
                label="Active"
                isMandatory={false}
                value={value}
                callback={onChange}
              />
            )}
          />
        </div>

        {/* Brand Name  */}
        <div className="col-3">
          <Controller
            control={control}
            name="brand"
            key="brand"
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type="brand"
                label="Brand"
                name="brand"
                error={errors?.brand?.message}
                isMandatory={true}
                placeholder="Search by  Id/Name"
                value={value}
                callback={onChange}
                uid="search-brand"
                emptyLabel="No Brand Found"
                info={manageView.infoContext.brand}
                filterParams={activeFilter}
              />
            )}
          />
        </div>

        {/* Category Name  */}
        <div className="col-3">
          <Controller
            control={control}
            name="category"
            key="category"
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type="category"
                label="Category"
                name="category"
                isMandatory={true}
                error={errors?.category?.message}
                placeholder="Search by  Id/Name"
                value={value}
                callback={onCategorySelect(onChange)}
                uid="search-Category"
                emptyLabel="No Category Found"
                info={manageView.infoContext.category}
                filterParams={activeFilter}
              />
            )}
          />
        </div>

        {/* Barcode */}
        <div className="col-2">
          <Controller
            name="isAutoBarcode"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                label="Is Auto Barcode"
                name="isAutoBarcode"
                error={errors?.hasParentCategory?.message}
                isMandatory={false}
                callback={onAutoBarcodeChange(onChange)}
                value={value}
                gap={0}
              />
            )}
          />
          <TextInput
            type="text"
            error={errors?.barcode?.message}
            name="barcode"
            placeholder="Enter Barcode Here"
            register={register}
            isMandatory={true}
            disabled={barcode}
          />
        </div>

        {/* Company Name  */}
        <div className="col-2">
          <TextInput
            type="text"
            label="Company Name"
            error={errors?.companyName?.message}
            name="companyName"
            placeholder="Enter Company Name Here"
            isMandatory={false}
            register={register}
          />
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
