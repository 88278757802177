import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, CommonService, VendorService } from "@sk/services";
import { PaginationSummary, AppCard } from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { detailsView } from "../../../constantService";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = detailsView.tabs.vendors.pagination;

const formLabelsConfig = detailsView.tabs.vendors.formLabels;

const attachAdditionalDataConfig = [];

const defaultFormData = {
  warehouse: AuthService.getDefaultWhId(),
  vendor: [],
};

const defaultSortOpt = { key: "mrp", value: "desc" };

const prepareFilterParams = (
  pagination = {},
  filter = {},
  sort = {},
  additionalData = {}
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      $or: [
        {
          "margins.brand": additionalData.brand,
          "margins.category": additionalData.category,
          "margins.whId": filter.warehouse,
        },
        {
          "margins.product": additionalData.pId,
          "margins.whId": filter.warehouse,
        },
      ],
    },
  };
  if (filter.vendor?.length) {
    p.filter._id = filter.vendor[0].value._id;
  }
  return p;
};

const getData = async (params) => {
  const r = await VendorService.getVendors(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        return x;
      })
    : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;

  try {
    const r = await VendorService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const Vendors = ({ pId, category = [], brand = [] }) => {
  //  To Store Vendor Data
  const [data, setData] = useState([]);

  //  To Store Pagination Data
  const paginationRef = useRef({ ...defaultPageOpt });

  //  To Store Sort Data
  const sortRef = useRef({ ...defaultSortOpt });

  //  To Store Filter Data
  const filterDataRef = useRef({ ...defaultFormData });

  //  To Store Applied Filter Labels
  const [filterLabels, setFilterLabels] = useState([]);

  //  Loading State While Fetching Vendor Data
  const [loadingData, setLoadingData] = useState(true);

  // Loading State Whicl Fetching Vendor Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  //  Custom Hook To Attach Additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (pId) {
      filterDataRef.current = { ...filterDataRef.current, pId };
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  //  To Get Prepare Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      {
        pId,
        category,
        brand,
      }
    );
  }, [brand, category, pId]);

  //  TO Fetch and Update Vendor Data
  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(params);
    // To attach Additional Info

    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  //  To Apply Filter
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };

    if (pId) {
      loadList();
      prepareFilterLabels();

      // for total records
      setLoadingTotalRecords(true);
      const p = getFilterParams();

      const c = await getCount(p);
      paginationRef.current.totalRecords = c.count;
      setLoadingTotalRecords(false);
    }
  }, [pId, loadList, getFilterParams]);

  //   To Change Pagination and Fetch Vendor
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  //  To get Filter Form Data And Applying Filter
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = data.formData;
      applyFilter();
    },
    [applyFilter]
  );

  //  To Store Sort Data And fetch List
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelsConfig,
      filterDataRef.current || {}
    );
    setFilterLabels(l);
  };

  return (
    <>
      <AppCard>
        <Filter callback={filterFormCb} />
      </AppCard>

      <AppCard>
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          sort={sortRef.current}
          sortCb={sortCb}
        />
      </AppCard>
    </>
  );
};

export default Vendors;
