import { FranchiseService, SellerService } from "@sk/services";
import {
  AppCard,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { debounce, orderBy } from "lodash";

import { useCallback, useEffect, useRef, useState } from "react";

const ViewDealWarehouseVisibility = ({ visibilityByWarehouse = [] }) => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);

  const filterRef = useRef({
    status: "",
    search: "",
  });

  const paginationRef = useRef({
    ...defaultPagination,
    totalRecords: visibilityByWarehouse.length,
  });

  const debounceSearch = useCallback(
    debounce((cb) => {
      cb();
    }, 500),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const d = await getData(
        visibilityByWarehouse,
        filterRef.current,
        paginationRef.current
      );
      setData(d.sellers);
      setLoading(false);
    };
    fetchData();
  }, [visibilityByWarehouse]);

  const loadList = async () => {
    setLoading(true);
    setData([]);
    const d = await getData(
      visibilityByWarehouse,
      filterRef.current,
      paginationRef.current
    );
    setData(d.sellers);
    paginationRef.current.totalRecords = d.totalRecords;
    setLoading(false);
  };

  const handleSearch = async (e) => {
    paginationRef.current = {
      ...defaultPagination,
    };

    filterRef.current = {
      ...filterRef.current,
      search: e.target.value,
    };

    setSearchQuery(e.target.value);

    debounceSearch(loadList);
  };

  const paginationCb = async (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const handleStatusChange = async (e) => {
    setStatus(e.target.value);
    filterRef.current = {
      ...filterRef.current,
      status: e.target.value,
    };
    loadList();
  };

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-4">
            <input
              className="form-control mb-3"
              type="text"
              placeholder="Search by Warehouse ID"
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>

          <div className="col-4">
            <select
              className="form-control mb-3"
              onChange={handleStatusChange}
              value={status}
            >
              <option value="">All Status</option>
              <option value="Publish">Publish</option>
              <option value="Unpublish">Unpublish</option>
            </select>
          </div>
        </div>
        <div
          className="tbl-scroll-container customr-scrollbar thin-scrollbar mb-2"
          style={containerStyle}
        >
          <table className="table table-sm" style={tableStyle}>
            <TableHeader data={tableHeader} noBg={true} isSticky={true} />
            {!loading && data.length == 0 && (
              <tbody>
                <tr>
                  <td colSpan={tableHeader.length}>
                    <NoDataFound>No Data Found</NoDataFound>
                  </td>
                </tr>
              </tbody>
            )}

            {loading && (
              <tbody>
                <TableSkeletonLoader cols={tableHeader.length} rows={10} />
              </tbody>
            )}
            {data.length > 0 && (
              <tbody>
                {data.map((x, i) => (
                  <tr key={x._id || i} className="fs-val-md">
                    <td>{paginationRef.current.startSlNo + i}</td>
                    <td>{x.whId}</td>
                    <td>{x._warehouse?.name || x._seller?.name || "--"}</td>
                    <td>{x.visibility}</td>
                    <td>
                      <HighlightText
                        type={x.liquidateStock ? "success" : "danger"}
                        template={2}
                      >
                        {x.liquidateStock ? "Yes" : "NO"}
                      </HighlightText>
                    </td>
                    <td>
                      <HighlightText
                        type={x.visibleByMinQty ? "success" : "danger"}
                        template={2}
                        size="sm"
                      >
                        {x.visibleByMinQty ? "Yes" : "NO"}
                      </HighlightText>
                    </td>
                    <td>
                      <HighlightText
                        type={x.kingDiscount ? "success" : "danger"}
                        template={2}
                        size="sm"
                      >
                        {x.kingDiscount ? "Yes" : "NO"}
                      </HighlightText>
                    </td>
                    <td>
                      <HighlightText
                        type={x.status == "Publish" ? "success" : "danger"}
                        template={2}
                        size="sm"
                      >
                        {x.status}
                      </HighlightText>
                    </td>
                    <td>
                      <HighlightText
                        type={
                          x.forecastStatus == "Publish" ? "success" : "danger"
                        }
                        template={2}
                        size="sm"
                      >
                        {x.forecastStatus}
                      </HighlightText>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>

        <PaginationBlock
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={false}
        />
      </AppCard>
    </>
  );
};

const tableHeader = [
  { label: "Sl.no" },
  { label: "Warehouse Id" },
  { label: "Warehouse Name", width: "20%" },
  { label: "Visibility" },
  { label: "Stock Liquidation" },
  { label: "Force min Qty" },
  { label: "King Disc" },
  { label: "Status" },
  { label: "Forecast Status" },
];

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const containerStyle = {
  height: "calc(100vh - 300px)",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "100%", // Adjusted width to be responsive
};

const getData = async (visibilityByWarehouse, filter, pagination) => {
  const sortedData = orderBy(visibilityByWarehouse, ["status"], ["asc"]);
  let s = [];
  if (filter.search || filter.status) {
    s = sortedData.filter(({ whId, status }) => {
      const matchesSearch = filter.search
        ? whId.toString().toLowerCase().includes(filter.search.toLowerCase())
        : true;
      const matchesStatus = filter.status ? status === filter.status : true;
      return matchesSearch && matchesStatus;
    });
  } else {
    s = sortedData;
  }

  const finalData = s.slice(pagination.startSlNo - 1, pagination.endSlNo);

  const ids = s.map((x) => x.whId);
  if (ids.length > 0) {
    const whPatt = /^WMF/;
    const sellerPatt = /^SEL/;

    const whIds = ids.filter((x) => whPatt.test(x));
    const sellerIds = ids.filter((x) => sellerPatt.test(x));

    const whs = await FranchiseService.getList({
      page: 1,
      count: whIds.length,
      filter: {
        _id: { $in: whIds },
      },
      select: "name",
    });
    finalData.forEach((x) => {
      const wh = whs?.resp?.find((y) => y._id == x.whId);
      if (wh) {
        x._warehouse = wh;
      }
    });

    if (sellerIds.length > 0) {
      const sellers = await SellerService.getList({
        page: 1,
        count: sellerIds.length,
        filter: { _id: { $in: sellerIds } },
        select: "name",
      });
      finalData.forEach((x) => {
        const seller = sellers?.resp?.sellers?.find((y) => y._id == x.whId);
        if (seller) {
          x._seller = seller;
        }
      });
    }
  }

  return {
    sellers: finalData,
    totalRecords: s.length,
  };
};

export default ViewDealWarehouseVisibility;
