import { TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

const FilterForm = ({ callback, openAdvFilterModal }) => {
  const { register, getValues } = useFormContext();

  const onSearch = useCallback(
    debounce(() => {
      const data = getValues();
      callback({ formData: data });
    }, 700),
    [getValues, callback]
  );

  return (
    <>
      <form autoComplete="off">
        <div className="row">
          {/* Product Search Input */}
          <div className="col-4">
            <TextInput
              name="product"
              register={register}
              type="text"
              placeholder="Search Product by Id/Name or HSN Number"
              callback={onSearch}
              gap={0}
            />
          </div>
          <div className="col-3">
            <TextInput
              placeholder="Search by Barcode "
              register={register}
              callback={onSearch}
              name="barcode"
              isMandatory={false}
              type="text"
              gap={0}
            />
          </div>
          <div className="col-auto">
            <button
              className="btn app-filter-btn"
              type="button"
              onClick={openAdvFilterModal}
            >
              <i className="bi bi-funnel"></i> FILTER
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FilterForm;
