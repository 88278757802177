/* eslint-disable jsx-a11y/label-has-associated-control */
import { FileUpload, ImgRender, InputErrorMsg } from "@sk/uis";
import { memo, useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const Images = () => {
  const {
    formState: { errors },
    getValues,
    setValue,
    trigger,
    control,
  } = useFormContext({});

  const images = useWatch({
    name: "image",
    control,
  });
  const desktopImages = useWatch({ name: "mainDesktopImage", control });
  const mobileImages = useWatch({ name: "mainMobileImage", control });

  const imagesCb = async (e) => {
    // const v = getValues("image") || [];
    setValue("image", [e]);
  };

  const dskImageCb = async (e) => {
    // const v = getValues("mainDesktopImage") || [];
    setValue("mainDesktopImage", [e]);
  };

  const mobImageCb = async (e) => {
    // const v = getValues("mainMobileImage") || [];
    setValue("mainMobileImage", [e]);
  };

  const removeImage = useCallback(
    async (key, index) => {
      let v = getValues(key);
      v.splice(index, 1);
      setValue(key, v);
      await trigger(key);
    },
    [getValues, setValue, trigger]
  );

  const removeImg = useCallback(
    (key) => {
      removeImage(key, 0);
    },
    [removeImage]
  );

  return (
    <>
      {/* Images */}
      <div className="mb-5">
        <div className="row">
          {/* Image rendering */}
          <div className="col-auto">
            {!images.length ? (
              <div className="upload-placeholder-t1">
                <i className="bi bi-image"></i>
              </div>
            ) : (
              <div className="uploaded-t1-img-cnt">
                <i
                  tabIndex={-1}
                  role="button"
                  className="bi bi-x-circle close"
                  onClick={() => {
                    removeImg("image");
                  }}
                ></i>
                <ImgRender
                  assetId={images.length ? images[0]["asset"] : ""}
                  width="100"
                  height="100"
                />
              </div>
            )}
          </div>
          {/* Action button  */}
          <div className="col col-ms-auto align-self-end">
            <label className="col-form-label fs-val-md">Image</label>
            <div>
              <FileUpload template={2} callback={imagesCb} maxSize={10} />
            </div>
          </div>
        </div>

        {errors?.image?.message && (
          <div className="col-12">
            <InputErrorMsg msg={errors?.image?.message} />
          </div>
        )}
      </div>

      {/* Mobile Images */}
      <div className="mb-5">
        <div className="row">
          {/* Image Rendering */}
          <div className="col-auto">
            {!mobileImages.length ? (
              <div className="upload-placeholder-t1">
                <i className="bi bi-image"></i>
              </div>
            ) : (
              <div className="uploaded-t1-img-cnt">
                <i
                  tabIndex={-1}
                  role="button"
                  className="bi bi-x-circle close"
                  onClick={() => {
                    removeImg("mobileImages");
                  }}
                ></i>
                <ImgRender
                  assetId={mobileImages.length ? mobileImages[0]["asset"] : ""}
                  width="100"
                  height="100"
                />
              </div>
            )}
          </div>

          {/* Action button  */}
          <div className="col col-ms-auto align-self-end">
            <label className="col-form-label fs-val-md">Mobile Image</label>
            <div>
              <FileUpload template={2} callback={mobImageCb} />
            </div>
          </div>
        </div>

        {errors?.mainMobileImage?.message && (
          <div className="col-12">
            <InputErrorMsg msg={errors?.image?.message} />
          </div>
        )}
      </div>

      {/* Desktop Images */}
      <div className="mb-3">
        <div className="row">
          {/* Image Rendering */}
          <div className="col-auto">
            {!desktopImages.length ? (
              <div className="upload-placeholder-t1">
                <i className="bi bi-image"></i>
              </div>
            ) : (
              <div className="uploaded-t1-img-cnt">
                <i
                  tabIndex={-1}
                  role="button"
                  className="bi bi-x-circle close"
                  onClick={() => {
                    removeImg("desktopImages");
                  }}
                ></i>
                <ImgRender
                  assetId={
                    desktopImages.length ? desktopImages[0]["asset"] : ""
                  }
                  width="100"
                  height="100"
                />
              </div>
            )}
          </div>

          {/* Action button  */}
          <div className="col col-ms-auto align-self-end">
            <label className="col-form-label fs-val-md">Desktop Image</label>
            <div>
              <FileUpload template={2} callback={dskImageCb} maxSize={10} />
            </div>
          </div>
        </div>
        {errors?.mainDesktopImage?.message && (
          <div className="col-12">
            <InputErrorMsg msg={errors?.image?.message} />
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Images);
