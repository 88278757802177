import { NavService } from "@sk/services";
import {
  Amount,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo } from "react";

const headers = [
  { label: "Sl.No.", width: "5%" },
  { label: "Deal Name", width: "25%" },
  { label: "Deal ID", width: "10%" },
  { label: "MRP", isCentered: true, key: "mrp", enableSort: true },
  { label: "B2B Price ", isCentered: true, key: "b2bPrice", enableSort: true },
  { label: "Product Qty Linked to Deal", isCentered: true },
  { label: "Total Products Linked", isCentered: true },
  { label: "Is Active", isCentered: true },
  { label: "Status", isCentered: true },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const viewDeal = (id) => {
    NavService.openInNewTab("/cms/deal/view", {
      id: id,
    });
  };

  return (
    <>
      <table className="table table-sm bg-white ">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} noBg />
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Linked Deal Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {data.map((x, index) => (
            <tr key={x._id} className="fs-val-md">
              <td>{paginationConfig.startSlNo + index}</td>
              <td>
                <button
                  className="btn btn-link p-0 text-dark fs-val-md text-start"
                  onClick={() => {
                    viewDeal(x._id);
                  }}
                >
                  {x.name}
                </button>
              </td>

              <td>{x._id}</td>

              <td className="text-center">
                <Amount value={x.mrp} />
              </td>
              <td className="text-center">
                <Amount value={x.b2bPrice} />
              </td>
              {/* Linked Quantity we are calculating */}
              <td className="text-center">
                {x.product && x.product.reduce((acc, x) => x.quantity + acc, 0)}
              </td>
              {/* Linked Products */}
              <td className="text-center">{x.product.length}</td>
              <td className="text-center">
                <HighlightText
                  status={x.is_active ? "Yes" : "No"}
                  isBadge={true}
                />
              </td>

              <td className="text-center">
                {<HighlightText status={x.status} isBadge={true} />}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
