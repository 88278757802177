import { AuthService } from "@sk/services";
import { SelectInput } from "@sk/uis";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const createdOnDateConfig = { mode: "range" };

const defaultFromValues = {
  whId: AuthService.getDefaultWhId(),
};

const Filter = ({ callback }) => {
  const { register, getValues } = useForm({
    defaultValues: defaultFromValues,
  });

  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  return (
    <>
      <form autoComplete="off">
        <div className="row">
          <div className="col-3">
            <SelectInput
              label="Warehouse"
              register={register}
              name="whId"
              callback={onFilterChange}
              isMandatory={false}
              options={warehouseOptions}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
