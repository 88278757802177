import * as yup from "yup";

const schema = yup.object().shape({
  // type: yup.string().required("Type is required"),

  // groupType: yup.string().when("type", {
  //   is: (type) => type == "groupType",
  //   then: (schema) => schema.label("Group Type").required(),
  // }),
  // brand: yup.array().when("configType", {
  //   is: (type) => type == "Brand",
  //   then: (schema) =>
  //     schema.label("Brand").min(1, "Brand is Required").required(),
  // }),
  // category: yup.array().when("configType", {
  //   is: (type) => type == "Category",
  //   then: (schema) =>
  //     schema.label("Category").min(1, "Category is Required").required(),
  // }),
  // deal: yup.array().when("configType", {
  //   is: (type) => type == "Deal",
  //   then: (schema) =>
  //     schema.label("Deal").min(1, "Deal is Required").required(),
  // }),

  // franchise: yup.array().when("type", {
  //   is: (type) => type == "franchise",
  //   then: (schema) =>
  //     schema.label("Franchise").min(1, "Must Select Franchise").required(),
  // }),

  // configType: yup.string().required("Config is Required"),

  // New Validation Starts Here
  deal: yup.array().min(1, "Deal is Required").required(),

  franchise: yup.array().min(1, "Must Select Franchise").required(),

  // New Validation End Here

  hasOffer: yup.boolean(),

  validFrom: yup.date().when("hasOffer", {
    is: (hasOffer) => {
      return hasOffer;
    },
    then: (schema) =>
      schema
        .label("Start Date")
        .typeError("Valid From Date is Required")
        .required("Valid From Date is Required "),
  }),

  validTo: yup.date().when("hasOffer", {
    is: (hasOffer) => {
      return hasOffer;
    },
    then: (schema) =>
      schema
        .label("End Date")
        .typeError("Valid to Date is Required")
        .min(yup.ref("validFrom"), "Must be greater than Valid to Date")
        .required("Valid to Date is Required"),
  }),

  additionalDiscount: yup
    .number()
    .transform((val) => (!isNaN(val) ? val : 0))
    .when("hasOffer", {
      is: (hasOffer) => hasOffer,
      then: (schema) =>
        schema
          .label("Additional Discount")
          .typeError("Additional Discount is Required")
          .max(99.99)
          .required()
          .moreThan(0),
    }),

  isFixedPrice: yup.boolean(),

  discount: yup
    .number()
    .transform((val) => (!isNaN(val) ? val : null))
    .when("isFixedPrice", {
      is: (isFixedPrice) => !isFixedPrice,
      then: (schema) => schema.required().min(0),
    })
    .test(
      "max-decimals",
      "Discount cannot have more than 6 decimal places",
      (value) => {
        if (value !== undefined && value !== null) {
          const valueString = value.toString();
          const decimalPart = valueString.split(".")[1];
          if (decimalPart && decimalPart.length > 6) {
            return false;
          }
        }
        return true;
      }
    )
    .max(99.99)
    .label("Discount"),

  // fixedMrp: yup
  //   .number()
  //   .nullable()
  //   .transform((val) => (!isNaN(val) ? val : 0))
  //   .when("isFixedPrice", {
  //     is: (isFixedPrice) => isFixedPrice,
  //     then: (schema) => schema.required().moreThan(0),
  //   })
  //   .max(999999999, "Enter Valid MRP")
  //   .label("MRP"),

  fixedPrice: yup
    .number()
    .nullable()
    .transform((val) => (!isNaN(val) ? val : 0))
    .test(
      "max-decimals",
      "Price cannot have more than 2 decimal places",
      (value) => {
        if (value !== undefined && value !== null) {
          const valueString = value.toString();
          const decimalPart = valueString.split(".")[1];
          if (decimalPart && decimalPart.length > 2) {
            return false;
          }
        }
        return true;
      }
    )
    .when("isFixedPrice", {
      is: (isFixedPrice) => isFixedPrice,
      then: (schema) =>
        schema
          .max(999999, "Max Price Limit Crossed")
          .min(0.1, "Please Enter Price")
          .required(),
    })
    .label("Price"),
});

export default schema;
