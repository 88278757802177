import { EntitySearchInput, RadioInput, TextInput } from "@sk/uis";
import { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";

import listView from "../../constantService";

const yesNoOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const reserveOrderOptions = [
  { label: "All", value: "" },
  { label: "True", value: "yes" },
  { label: "False", value: "no" },
];

const deliveryChalanOptions = [
  { label: "All", value: "" },
  { label: "Available", value: "Available" },
  { label: "Not Available", value: "Not Available" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "In Active" },
];

const defaultFormData = listView.filterFormData;

const AdvFilterModal = ({ callback, resetCb, show, closeModal, formData }) => {
  const { handleSubmit, register, control, setValue, reset } = useFormContext();
  const onSubmit = (data) => {
    callback({ formData: data });
    closeModal();
  };

  const doReset = () => {
    resetCb();
    closeModal();
  };

  useEffect(() => {
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset(defaultFormData);
      }
    }
  }, [show, setValue, formData, reset]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={{ width: "30%" }}
      >
        <Offcanvas.Header closeButton className="bg-light">
          <Offcanvas.Title>Advance Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form>
            <div className="row">
              {/* Permanently_deactivate
              <div className="col-12 mb-3">
                <Controller
                  name="permanently_deactivate"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioInput
                      label="Is Permanently Deactivated ?"
                      register={register}
                      value={value}
                      onChange={onChange}
                      cols={4}
                      name="permanently_deactivate"
                      isMandatory={false}
                      options={yesNoOptions}
                    />
                  )}
                />
              </div> */}

              {/* Brand */}
              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="brand"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="brand"
                      label="Search for Brand"
                      placeholder="Search by Name/ID"
                      value={value}
                      callback={onChange}
                      uid="brand"
                      emptyLabel="No Brand found"
                    />
                  )}
                />
              </div>

              {/* Category */}
              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="category"
                      label="Search for Category"
                      placeholder="Search by Name/ID"
                      value={value}
                      callback={onChange}
                      uid="category"
                      emptyLabel="No Category found"
                    />
                  )}
                />
              </div>

              {/* Menu */}
              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="menu"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="category"
                      label="Search for Menu"
                      placeholder="Search by Name/ID"
                      value={value}
                      callback={onChange}
                      uid="menu"
                      emptyLabel="No Menu found"
                      filterParams={{ filter: { hasParentCategory: false } }}
                    />
                  )}
                />
              </div>

              <div className="col-6 mb-3">
                <TextInput
                  type="text"
                  label="MRP Min-Range"
                  register={register}
                  name="min"
                  placeholder="Rs."
                  isMandatory={false}
                  maxLength={8}
                />
              </div>
              <div className="col-6 mb-3">
                <TextInput
                  type="text"
                  label="MRP Max-Range"
                  register={register}
                  name="max"
                  placeholder="Rs."
                  isMandatory={false}
                  maxLength={8}
                />
              </div>

              {/* Class Classifications */}
              {/* <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="classClassifications"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="classification"
                      label="Search for Classification"
                      placeholder="Search by Name/ID"
                      value={value}
                      callback={onChange}
                      uid="classification"
                      emptyLabel="No classification found"
                    />
                  )}
                />
              </div> */}

              {/* Is Active */}
              <div className="col-12 mb-3">
                <Controller
                  name="is_active"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioInput
                      label="Status"
                      register={register}
                      value={value}
                      onChange={onChange}
                      cols={4}
                      name="status"
                      isMandatory={false}
                      options={statusOptions}
                    />
                  )}
                />
              </div>

              {/* Enabled Reserve Order */}
              <div className="col-12 mb-3">
                <Controller
                  name="allowReserveOrder"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioInput
                      label="Enabled Reserve Order"
                      register={register}
                      value={value}
                      onChange={onChange}
                      cols={4}
                      name="allowReserveOrder"
                      isMandatory={false}
                      options={reserveOrderOptions}
                    />
                  )}
                />
              </div>

              {/* Delivery Chalan */}
              <div className="col-12 mb-3">
                <Controller
                  name="delivery_chalan"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioInput
                      label="Delivery Chalan"
                      register={register}
                      value={value}
                      onChange={onChange}
                      cols={4}
                      name="delivery_chalan"
                      isMandatory={false}
                      options={deliveryChalanOptions}
                    />
                  )}
                />
              </div>
            </div>
            <div className="mb-2 text-end w-100 col-12 mb-3">
              <button
                className="btn btn-warning me-2 "
                type="button"
                onClick={doReset}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-primary me-1"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AdvFilterModal;
