import Highlights from "../Highlights";
import Description from "./Description";

const HighlightsDescriptions = () => {
  return (
    <div className="m-2">
      <Highlights />
      <Description />
    </div>
  );
};

export default HighlightsDescriptions;
