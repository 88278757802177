import { useAttachAdditionalData } from "@sk/hooks";
import { CategoryService, CommonService, NavService } from "@sk/services";
import {
  AppliedFilterLabel,
  BusyLoader,
  PageInfo,
  Rbac,
  SummaryCard,
  TableColumnBtn,
} from "@sk/uis";
import { produce } from "immer";
import merge from "lodash/merge";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import listView from "../constantService";
import FilterForm from "./components/FilterForm";
import Table from "./components/Table";

const getData = async (params) => {
  const r = await CategoryService.getList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await CategoryService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const getProductNotLinkedCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await CategoryService.getProductNotLinkedCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const rbac = {
  addCategory: ["AddCategory", "addCategory"],
};

const ListCategory = () => {
  // For Category List data
  const [data, setData] = useState([]);

  // For Category List Data Loader
  const [loadingData, setLoadingData] = useState(true);

  // For Pagination Count Loader
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Summary Card for Counter display
  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  // Attach additional Info
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Filter data Label displaying
  const [filterLabels, setFilterLabels] = useState([]);

  const [selecteMoreTblColumns, setSelectedMoreTblColumns] = useState({});

  // Filter Data
  const filterDataRef = useRef({});

  //Pagination Data
  const paginationRef = useRef({ ...listView.pagination });

  const router = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    // Category List data
    loadList();
    //load Summary card
    loadSummary();
    // Preparing Filter Label
    prepareFilterLabels();

    // for total Pagination records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [loadList, loadSummary, prepareFilterLabels]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  const loadSummary = useCallback(async () => {
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    const filterParams = getFilterParams();

    let promises = [];
    summaryCard.forEach((e) => {
      if (e.type && e.type == "productNotLinked") {
        promises.push(
          getProductNotLinkedCount(
            merge({}, filterParams, { filter: e.filter })
          )
        );
      } else {
        promises.push(getCount(merge({}, filterParams, { filter: e.filter })));
      }
    });
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [summaryCard]);

  const getFilterParams = () => {
    let p = {
      page: paginationRef.current.activePage,
      count: paginationRef.current.rowsPerPage,
      filter: {},
      select:
        "name,parent,department,invoiceOnOrderAmount,classGroup,stickyOrderPrice,status,focusedCategoryInfo,dailyNeedsInfo,displayDealMrp,noReturnsAllowed,createdAt,isFmcgClassGroup",
    };

    const d = filterDataRef.current || {};

    const category = (d.category || "").trim();

    // CATEGORY FILTER - BASED ON ID AND NAME
    if (category) {
      const catRegex = {
        $regex: category,
        $options: "gi",
      };
      p.filter.$or = [
        {
          _id: catRegex,
        },
        {
          name: catRegex,
        },
      ];
    }

    if (d.classGroup?.length > 0 && d.classGroup[0]["value"]) {
      p["filter"]["classGroup._id"] = d.classGroup[0]["value"];
    }

    if (d.status && d.status != "All") {
      p["filter"]["status"] = d.status;
    }

    if (d?.invoiceOnOrderAmount) {
      p["filter"]["invoiceOnOrderAmount"] =
        d?.invoiceOnOrderAmount.toString() == "Yes" ? true : false;
    }

    if (d?.isDailyNeeds) {
      p["filter"]["dailyNeedsInfo.isDailyNeeds"] =
        d?.isDailyNeeds.toString() == "Yes" ? true : false;
    }

    if (d?.displayDealMrp) {
      p["filter"]["displayDealMrp"] =
        d?.displayDealMrp.toString() == "Yes" ? true : false;
    }

    if (d?.isFocusedCategory) {
      p["filter"]["focusedCategoryInfo.isFocusedCategory"] =
        d?.isFocusedCategory.toString() == "Yes" ? true : false;
    }

    if (d?.noReturnsAllowed) {
      p["filter"]["noReturnsAllowed"] =
        d?.noReturnsAllowed.toString() == "Yes" ? true : false;
    }

    if (d?.hideUnlinkedCategories) {
      p.hideUnlinkedCategories = true;
    }
    return p;
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = { ...data.formData };
      paginationRef.current = { ...listView.pagination };
      init();
    },
    [init]
  );

  const prepareFilterLabels = useCallback(() => {
    const v = { ...filterDataRef.current };
    if (!v.hideUnlinkedCategories) {
      delete v.hideUnlinkedCategories;
    }
    delete v.category;

    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);

    setFilterLabels(l);
  }, []);

  const editCb = useCallback(
    (id) => {
      NavService.openInNewTab("/cms/category/manage", { id: id });
    },
    [router]
  );

  const addCategory = () => {
    NavService.openInNewTab("/cms/category/manage");
  };

  const onMoreTableColApply = useCallback((d) => {
    let t = {};
    (d.selected || []).forEach((x) => {
      t[x.key] = x;
    });
    setSelectedMoreTblColumns({ ...t });
  }, []);

  return (
    <>
      {/* Page title Info */}
      <div className="mt-2">
        <PageInfo
          title="Category List"
          breadcrumbs={listView.breadcrumb}
          navigate={router}
        />
      </div>

      {/* summary */}
      <div className="row align-self-center mb-2">
        {summaryCard.map((e, k) => (
          <div className="col-3" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={2}
            />
          </div>
        ))}
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <FilterForm callback={filterFormCb} />
        </div>

        <div className="col-auto ms-auto align-self-center">
          {/* <button className="btn app-download-btn me-2">Download Report</button> */}

          <Rbac roles={rbac.addCategory}>
            <button className="btn btn-primary me-2" onClick={addCategory}>
              Add Category
            </button>
          </Rbac>
          <TableColumnBtn
            columns={listView.moreTableColumns}
            callback={onMoreTableColApply}
          />
        </div>
      </div>

      {filterLabels.length > 0 ? (
        <div className="mb-3">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null}

      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        editCb={editCb}
        moreColumns={selecteMoreTblColumns}
      />

      <BusyLoader show={loadingData} />
    </>
  );
};

export default memo(ListCategory);
