// import { useAttachAdditionalData } from "@sk/hooks";
import { BrandService, NavService } from "@sk/services";
import {
  EntitySearchInput,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  SelectInput,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { detailsView } from "../../../constantService";
// Default Form Data
const filterFormData = detailsView.tabs.linkedBrands.filterFormData;

// Default Status Options
const statusOptions = detailsView.tabs.linkedBrands.selectOptions.statusOptions;

// Default Table Header
const tableHeaders = detailsView.tabs.linkedBrands.tableHeaders;

const getData = async (params) => {
  const r = await BrandService.getList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.categoryLoading = true;
        return x;
      })
    : [];
};

const prepareFilterParams = (
  pagination = {},
  filter = {},
  additionalData = {}
) => {
  let p = {
    count: pagination.rowsPerPage,
    page: pagination.activePage,
    filter: { category: { $elemMatch: { $eq: additionalData.id } } },
    select: "name,mrp,status,barcode,brand",
  };

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (filter.brand?.length) {
    p.filter._id = filter.brand[0].value._id;
  }
  return p;
};

const LinkedBrands = ({ categoryId }) => {
  // For Filter
  const { register, getValues, control } = useForm({
    defaultValues: filterFormData,
  });

  // Loading State when We are calling API
  const [loading, setLoading] = useState(true);

  // Loading State Form Total Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Data From The API
  const [list, setList] = useState([]);

  // To Show Advance Filer

  // Pagination Config
  const paginationConfigRef = useRef({
    ...detailsView.tabs.linkedDeals.pagination,
  });

  // filer Data Ref
  const filterDataRef = useRef({ ...filterFormData });

  // saving category id from data to idRef
  // const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (categoryId) {
      applyFilter();
    } else {
      setLoading(false);
      setList([]);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, categoryId]);

  const applyFilter = useCallback(() => {
    filterDataRef.current = { ...getValues() };

    // To Get Count
    loadCount();

    // To loadList
    loadBrandList();
  }, [loadCount, loadBrandList, getValues]);

  // To Get the Count of All
  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    let p = getFilterParams();
    delete p.count;
    delete p.page;

    let m = await BrandService.getCount(p);

    // Setting total Count
    paginationConfigRef.current.totalRecords = m.resp ? m.resp : 0;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  // To get List of All Linked Brands
  const loadBrandList = useCallback(async () => {
    setLoading(true);

    let p = getFilterParams();

    let d = await getData(p);
    setList(d);

    setLoading(false);
  }, [getFilterParams]);

  // Pagination Cb
  const paginationCb = useCallback(
    (d) => {
      paginationConfigRef.current.activePage = d.activePage;
      paginationConfigRef.current.startSlNo = d.startSlNo;
      paginationConfigRef.current.endSlNo = d.endSlNo;
      loadBrandList();
    },
    [loadBrandList]
  );

  // navigate To View Product Page
  const viewDetails = (i) => {
    NavService.openInNewTab("/cms/brand/view", { id: i });
  };

  // SearchCb for Brands
  const onBrandChange = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e);
        applyFilter();
      };
    },
    [applyFilter]
  );

  // const resetFromData = () => {
  //   // Filter For Data
  //   reset(filterFormData);
  //   applyFilter();
  // };

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationConfigRef.current,
      filterDataRef.current,
      {
        id: categoryId,
      }
    );
  }, [categoryId]);

  return (
    <>
      <div className="row">
        {/* Brand */}
        <div className="col-3">
          <Controller
            control={control}
            name="brand"
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type={"brand"}
                label="Search for Brand"
                placeholder="Search By Name/ID"
                isMandatory={false}
                value={value}
                callback={onBrandChange(onChange)}
                uid="brand-search"
                isMultiple={false}
              />
            )}
          />
        </div>
        {/* Status */}
        <div className="col-auto">
          <SelectInput
            label="Status"
            register={register}
            name="status"
            options={statusOptions}
            callback={applyFilter}
          />
        </div>

        {/* <div className="col-auto ms-auto align-self-center">
          <button
            className="btn btn-sm btn-primary me-1 fs-val-md"
            onClick={applyFilter}
          >
            Apply
          </button>
          <button
            className="btn btn-sm btn-outline-warning fs-val-md"
            onClick={resetFromData}
          >
            Reset
          </button>
        </div> */}
      </div>
      <div>
        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationConfigRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>

        <table className="table table-bordered">
          <TableHeader data={tableHeaders} />
          <tbody>
            {/* When Page Loading */}

            {loading && (
              <TableSkeletonLoader rows={5} cols={tableHeaders.length} />
            )}

            {/*  When There is No Data */}
            {!loading && !list.length ? (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {/* When there is Data */}
            {!loading &&
              (list || []).map((x, index) => (
                <tr key={index + x._id} className="fs-val-md">
                  <td>{paginationConfigRef.current.startSlNo + index}</td>

                  {/* ID  */}
                  <td>{x?._id}</td>

                  {/* Brand Name */}
                  <td>
                    <div
                      className="text-primary"
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        viewDetails(x._id);
                      }}
                    >
                      {x?.name}
                    </div>
                  </td>

                  {/* Status */}
                  <td>
                    <HighlightText status={x?.status} isBadge={false} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfigRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(LinkedBrands);
