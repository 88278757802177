import { appConfigs, AuthService } from "@sk/services";
import { DateInput, DatePickerInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const createdOnDateConfig = { mode: "range", maxDate: new Date() };

const whOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const defaultFromValues = {
  status: "",
  orderId: "",
  createdAt: [],
  isReserveOrder: "",
  groupCode: "",
  configId: "",
  state: AuthService.getDefaultWhState(),
  warehouse: AuthService.getDefaultWhId(),
};

const statusOptions = [
  { label: "Select", value: "" },
  { label: "Reserve Pending", value: "Pending" },
  { label: "Reserve Active", value: "Started" },
  { label: "Reserve Stopped", value: "Stopped" },
  { label: "Reserve Expired", value: "Ended" },
];

const reserveOrderOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const groupCodeOptions = [
  { label: "Select", value: "" },
  { label: "BPCL", value: "BPCL" },
  { label: "BRAND SF NEW", value: "BRAND SF NEW" },
  { label: "SK", value: "SK" },
  ...appConfigs.DARKSTORE_SUB_TYPES.map((e) => ({
    label: e,
    value: e,
  })),
];

const Filter = ({ callback }) => {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: defaultFromValues,
  });

  const [warehouseOptions, setWarehouseOptions] = useState([...whOptions]);

  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  const onSearch = useCallback(debounce(onFilterChange, 700), [onFilterChange]);

  const onControlledInputChange = useCallback(
    (chngFn) => (e) => {
      chngFn(e);
      onFilterChange();
    },
    [onFilterChange]
  );

  const stateChangeCb = useCallback(
    (val) => {
      // get warehouses based on state
      const whs = AuthService.getWhOnState(val).map((x) => ({
        label: x.name,
        value: x._id,
      }));
      setWarehouseOptions([...whs]);
      setValue("warehouses", whs[0]?._id);
    },
    [setValue]
  );

  return (
    <>
      <form autoComplete="off">
        <div className="row">
          <div className="col-3">
            <TextInput
              type="text"
              name="configId"
              register={register}
              label="Search"
              placeholder="Enter Config Id"
              callback={onSearch}
              gap={1}
              size="sm"
            />
          </div>

          <div className="col-3">
            <SelectInput
              label="Warehouse"
              register={register}
              name="warehouse"
              callback={onFilterChange}
              isMandatory={false}
              options={warehouseOptions}
              gap={1}
              size="sm"
            />
          </div>

          <div className="col-auto">
            <SelectInput
              label="Status"
              register={register}
              name="status"
              callback={onFilterChange}
              isMandatory={false}
              options={statusOptions}
              gap={1}
              size="sm"
            />
          </div>

          {/* CREATED ON DATE FILTER */}
          <div className="col-2">
            <label className="fw-semibold mb-1 fs-val-md"> Created On </label>
            <Controller
              name="createdAt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DateInput
                  placeholder="Choose"
                  value={value}
                  callback={onControlledInputChange(onChange)}
                  config={createdOnDateConfig}
                  gap={1}
                  size="sm"
                  template={2}
                />
              )}
            />
          </div>

          <div className="col-auto">
            <SelectInput
              label="Group Code"
              register={register}
              name="groupCode"
              callback={onFilterChange}
              isMandatory={false}
              options={groupCodeOptions}
              gap={1}
              size="sm"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
