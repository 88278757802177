import { EntitySearchInput } from "@sk/uis";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import listView from "../../constantService";

const defaultFormData = listView.filterFromData;

const AdvanceFilterModal = ({ show, callback, formData = {} }) => {
  const { handleSubmit, reset, control, setValue } = useForm({
    defaultValues: defaultFormData,
  });

  useEffect(() => {
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset(defaultFormData);
      }
    }
  }, [show, setValue, formData, reset]);

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset(defaultFormData);
    callback({ status: "reset", formData: defaultFormData });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            <div className="fs-val-lg"> Advance Filter </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 border-top">
          <>
            <div className="row">
              {/* Brand */}
              <div className="col-12">
                <Controller
                  control={control}
                  name="brand"
                  key="brand"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="brand"
                      label="Search for Brand"
                      name="brand"
                      placeholder="Search by Id/Name"
                      value={value}
                      isMandatory={true}
                      callback={onChange}
                      uid="deal-brand"
                      emptyLabel="No Brand Found"
                      isMultiple={true}
                    />
                  )}
                />
              </div>

              {/* Category */}
              <div className="col-12">
                <Controller
                  control={control}
                  name="category"
                  key="category"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="category"
                      label="Search for Category"
                      name="category"
                      placeholder="Search by Id/Name"
                      value={value}
                      isMandatory={true}
                      callback={onChange}
                      uid="deal-category"
                      emptyLabel="No Category Found"
                      isMultiple={true}
                    />
                  )}
                />
              </div>
            </div>
            {/* Action Button */}
            <div className="text-end w-100 mt-4">
              <button
                className="btn me-1 btn-warning btn-sm"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdvanceFilterModal;
