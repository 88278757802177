import { AuthService } from "@sk/services";
import { DatePickerInput, SelectInput, TextInput } from "@sk/uis";
import { memo } from "react";
import { Controller, useForm } from "react-hook-form";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const createdOnDateConfig = { mode: "range" };

const defaultFromValues = {
  status: "",
  orderId: "",
  createdAt: [],
  warehouse: AuthService.getDefaultWhId(),
};

const statusOptions = [
  { label: "Select", value: "" },
  { label: "Processing", value: "Processing" },
  { label: "Delivered", value: "Delivered" },
  { label: "Shipping", value: "Shipping" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Confirmed", value: "Confirmed" },
  { label: "Partially Shipped", value: "Partially Shipped" },
  { label: "Created", value: "Created" },
];

const Filter = ({ callback }) => {
  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: defaultFromValues,
  });
  const onSubmit = (data) => {
    callback({ formData: data });
  };

  const doReset = () => {
    reset({ ...defaultFromValues });
    callback({ formData: defaultFromValues });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          <div className="col-3">
            <SelectInput
              label="Status"
              register={register}
              name="status"
              isMandatory={false}
              options={statusOptions}
            />
          </div>

          {/* <div className="col-3">
            <SelectInput
              label="Warehouse"
              register={register}
              name="warehouse"
              isMandatory={false}
              options={warehouseOptions}
            />
          </div> */}
          {/* CREATED ON DATE FILTER */}
          <div className="col-3">
            <label className="fw-semibold mb-1 fs-val-md"> Created On </label>
            <Controller
              name="createdAt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerInput
                  placeholder="Choose"
                  value={value}
                  inpChange={onChange}
                  config={createdOnDateConfig}
                />
              )}
            />
          </div>
          <div className="col-3">
            <TextInput
              type="text"
              name="orderId"
              register={register}
              label="Order Id"
              placeholder="Enter Order Id"
            />
          </div>

          <div className="ms-auto col-auto  align-self-center">
            <button className="btn  mt-1 btn-primary btn-sm me-1" type="submit">
              Apply
            </button>

            <button
              className="btn mt-1 btn-outline-warning btn-sm"
              type="button"
              onClick={doReset}
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
