import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, CommonService, WarehouseService } from "@sk/services";
import { PaginationSummary } from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const formLabelsConfig = [
  {
    whId: {
      label: "Warehouse",
    },
  },
];

const attachAdditionalDataConfig = [];

const defaultFormData = {
  whId: AuthService.getDefaultWhId(),
};

const defaultSortOpt = { key: "createdAt", value: "desc" };

//  Prepare Filter Params
const prepareFilterParams = (
  pagination = {},
  filter = {},
  sort = {},
  additionalData
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      productId: additionalData.id,
    },
  };

  if (filter.whId) {
    p.filter.whId = {
      $in: [filter.whId],
    };
  }

  return p;
};

//  To Fetch Data
const getData = async (params) => {
  const r = await WarehouseService.getLocationWiseList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

//  To Get Total Count
const getCount = async (params) => {
  delete params.count;
  delete params.page;

  try {
    const r = await WarehouseService.getLocationWiseListCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const LocationWise = ({ pId }) => {
  // It will have All LocationWise Data
  const [data, setData] = useState([]);

  // To Store Pagination Data
  const paginationRef = useRef({ ...defaultPageOpt });

  // To Store Sort Ref
  const sortRef = useRef({ ...defaultSortOpt });

  // To Store Filter Data Ref
  const filterDataRef = useRef({ ...defaultFormData });

  //  Custom Hook To attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // To Apply Filter Label
  const [filterLabels, setFilterLabels] = useState([]);

  // Loading State While Fetching LocationWise
  const [loadingData, setLoadingData] = useState(true);

  // Loading State While Fetching LocationWise Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  useEffect(() => {
    if (pId) {
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  //  To get prepare Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      {
        id: pId,
      }
    );
  }, [pId]);

  //  To Load List
  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(params);
    // attaching additional Info
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // To Apply Filter
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };

    loadList();
    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();

    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  //  To Change Pagination And Calling API
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  //  To Get Filter From Data And Applying Filter
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = data.formData;
      paginationRef.current = { ...defaultPageOpt };
      applyFilter();
    },
    [applyFilter]
  );

  //  To Sort
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  //  To Prepare Filter Params
  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelsConfig,
      filterDataRef.current || {}
    );
    setFilterLabels(l);
  };

  return (
    <div>
      <Filter callback={filterFormCb} id={pId} />
      {/* 
      {filterLabels.length > 0 ? (
        <div className="mb-2">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null} */}

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      {/* Table */}
      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sort={sortRef.current}
        sortCb={sortCb}
      />
    </div>
  );
};

export default memo(LocationWise);
