import { BooleanCheckboxInput, TextInput, TextareaInput } from "@sk/uis";
import { memo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

const Features = () => {
  const {
    register,
    trigger,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  const handleCheckBox = (chngFn, key) => async (val) => {
    chngFn(val);
    await trigger(key);
  };

  // const periodTypeOptions = [
  //   { value: "Days", label: "Days" },
  //   { value: "Months", label: "Months" },
  // ];
  const noReturnsAllowed = useWatch({ control, name: "noReturnsAllowed" });

  return (
    <div>
      <div className=" fs-val-lg fw-semibold mb-4">Category Flags (FMCG)</div>
      <div className="row fs-val-sm">
        <div className="col-4">
          <Controller
            name="stickyOrderPrice"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Sticky Order Price"
                name="stickyOrderPrice"
                error={errors?.stickyOrderPrice?.message}
                isMandatory={false}
                callback={onChange}
                value={value}
              />
            )}
          />
        </div>

        <div className="col-4">
          <Controller
            name="collectSerialNumber"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Collect Serial Number"
                name="CollectSerialNumber"
                error={errors?.collectSerialNumber?.message}
                isMandatory={false}
                callback={onChange}
                value={value}
              />
            )}
          />
        </div>

        <div className="col-4">
          <Controller
            name="marginDealerPrice"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Margin on Deal Price"
                name="marginDealerPrice"
                error={errors?.marginDealerPrice?.message}
                isMandatory={false}
                callback={onChange}
                value={value}
              />
            )}
          />
        </div>

        <div className="col-4">
          <Controller
            name="invoiceOnOrderAmount"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Invoice On Order Amount"
                name="invoiceOnOrderAmount"
                error={errors?.invoiceOnOrderAmount?.message}
                isMandatory={false}
                callback={onChange}
                value={value}
              />
            )}
          />
        </div>

        <div className="col-4">
          <Controller
            name="refundOnMrpChange"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Refund on Mrp Change"
                name="refundOnMrpChange"
                error={errors?.refundOnMrpChange?.message}
                isMandatory={false}
                callback={onChange}
                value={value}
              />
            )}
          />
        </div>

        <div className="col-4">
          <Controller
            name="displayDealMrp"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Display Deal Mrp"
                name="displayDealMrp"
                error={errors?.dailyNeedsInfo?.message}
                isMandatory={false}
                callback={onChange}
                value={value}
              />
            )}
          />
        </div>

        <div className="col-4">
          <Controller
            name="debitOnMrpChange"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Debit on Mrp Change"
                name="debitOnMrpChange"
                error={errors?.debitOnMrpChange?.message}
                isMandatory={false}
                callback={onChange}
                value={value}
              />
            )}
          />
        </div>

        {/* Return Disabled */}
        <div className="col-4">
          <Controller
            name="noReturnsAllowed"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Return Disabled"
                name="noReturnsAllowed"
                error={errors?.noReturnsAllowed?.message}
                isMandatory={false}
                callback={onChange}
                value={value}
              />
            )}
          />
        </div>

        <div className="col-4">
          <Controller
            name="isFocusedCategory"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Is Focused Category"
                name="isFocusedCategory"
                error={errors?.isFocusedCategory?.message}
                isMandatory={false}
                callback={handleCheckBox(onChange, "isFocusedCategory")}
                value={value}
                gap={0}
              />
            )}
          />
        </div>

        {/* <div className="col-4">
          <Controller
            name="isDailyNeeds"
            render={({ field: { onChange, value } }) => (
              <BooleanCheckboxInput
                labelCol="auto"
                label="Is Daily Needs"
                name="isDailyNeeds"
                error={errors?.isDailyNeeds?.message}
                isMandatory={false}
                callback={handleCheckBox(onChange, "isDailyNeeds")}
                value={value}
                gap={0}
              />
            )}
          />
        </div> */}
      </div>

      {/* Text area For Daily Needs Focused Category */}
      {(!noReturnsAllowed || getValues("isFocusedCategory")) && (
        <div className="row mt-3 mb-2">
          {getValues("isFocusedCategory") && (
            <div className="col-6 pe-3">
              <TextareaInput
                name="focusedCategoryTagLine"
                placeholder=""
                error={errors?.focusedCategoryTagLine?.message}
                label="Focused Category Tag Line"
                register={register}
                isMandatory={true}
                maxLength="50"
                rows="3"
                note="Max 50 Charatcers"
              />
            </div>
          )}
          {/* {getValues("isDailyNeeds") && (
            <div className="col-6 ps-3">
              <TextareaInput
                type="number"
                name="dailyNeedsTagline"
                placeholder=""
                error={errors?.dailyNeedsTagline?.message}
                label="Daily Needs Tag Line"
                register={register}
                isMandatory={true}
                maxLength="50"
                rows="3"
              />
            </div>
          )} */}

          {!noReturnsAllowed ? (
            <div className="col-3 mb-3">
              <TextInput
                type="text"
                label="Return days"
                register={register}
                name="returnDays"
                error={errors.returnDays?.message}
                placeholder="Return days"
                isMandatory={true}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default memo(Features);
