import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, GrnService } from "@sk/services";
import { AppCard, PaginationSummary } from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { detailsView } from "../../../constantService";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = detailsView.tabs.grn.pagination;

const attachAdditionalDataConfig =
  detailsView.tabs.grn.additionalTableDataConfig;

const defaultFormData = {
  vendor: [],
  grnId: "",
  createdAt: [],
  warehouse: AuthService.getDefaultWhId(),
};

const defaultSortOpt = { key: "mrp", value: "desc" };

// Preparing params
const prepareFilterParams = (pagination = {}, filter = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      productDetails: { $elemMatch: { productId: filter.productId } },
    },
  };

  if (filter.vendor?.length) {
    p.filter.vendorId = filter.vendor[0].value._id;
  }
  if (filter.grnId.trim()) {
    p.filter._id = filter.grnId;
  }

  if (filter.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter?.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.warehouse) {
    p.filter.whId = {
      $in: [filter.warehouse],
    };
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  return p;
};

// To Fetch GRN Data
const getData = async (params, pId) => {
  const r = await GrnService.getList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.vendorLoading = true;

        // Calculate invoice quantity and value
        const invoiceQuantity = x.productDetails
          .filter((x) => x.productId == pId)
          .reduce((acc, item) => {
            return acc + item.invoiceQuantity;
          }, 0);

        const invoiceValue = x.invoiceDetails
          .filter((x) => x.productId == pId)
          .reduce((acc, item) => {
            return acc + item.amount;
          }, 0);

        const receivedQuantity = x.productDetails
          .filter((x) => x.productId == pId)
          .reduce((acc, item) => {
            return acc + item.receivedQuantity;
          }, 0);

        // Add calculated values to the item
        x.invoiceQuantity = invoiceQuantity;
        x.invoiceValue = invoiceValue;
        x.receivedQuantity = receivedQuantity;
        return x;
      })
    : [];
};

// To Fetch Count
const getCount = async (params) => {
  delete params.count;
  delete params.page;

  try {
    const r = await GrnService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const Grn = ({ pId }) => {
  // It will have GRN Data
  const [data, setData] = useState([]);

  // To Store Pagination Data
  const paginationRef = useRef({ ...defaultPageOpt });

  // To Store Sorting  Data
  const sortRef = useRef({ ...defaultSortOpt });

  // To Store Filter Data
  const filterDataRef = useRef({ ...defaultFormData });

  // Custom Hook To Attach Additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Loading State While Fetching Grn Data
  const [loadingData, setLoadingData] = useState(true);

  // Loading State While Fetching Total Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  useEffect(() => {
    if (pId) {
      // Assign pId to filterDataRef
      filterDataRef.current = {
        ...filterDataRef.current,
        productId: pId,
      };
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  // To Get Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(paginationRef.current, filterDataRef.current);
  }, []);

  //  To Load List
  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    setData([]);

    const d = await getData(params, filterDataRef.current.productId);

    // attaching additional Info
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  //  To Apply Filter and Fetch the Data
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };

    loadList();

    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();

    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  // To Change Pagination and getting List
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  //  To Get Filter Data And Applying Filter Params
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      applyFilter();
    },
    [applyFilter]
  );

  //  To Sort Cb
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      {/* Wrap Filter with AppCard */}
      <AppCard>
        <Filter callback={filterFormCb} />
      </AppCard>

      {/* Wrap Pagination Summary and Table with AppCard */}
      <AppCard>
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
        {/* Table */}
        <Table
          id={pId}
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          sort={sortRef.current}
          sortCb={sortCb}
        />
      </AppCard>
    </>
  );
};

export default Grn;
