import { Tabs } from "@sk/uis";
import { useState } from "react";
import {
  DocumentWise,
  ImeiWise,
  LocationWise,
  StockLedgerHistory,
  StockSummaryList,
  TierDetails,
} from "../../features";

const tabs = [
  { key: "stockSummary", tabName: "Stock Summary" },
  // { key: "stockLedgerHistory", tabName: "Snapshot Ledger" },
  { key: "tierDetails", tabName: "Tier Details" },
  { key: "document", tabName: "Document Wise" },
  { key: "location", tabName: "Location Wise" },
  { key: "imei", tabName: "IMEI wise" },
];

const StockManagement = ({ data, expiryDateCb }) => {
  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  // Tab Callback to change Tab
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  const expiryModalCb = (d) => {
    expiryDateCb(d);
  };

  return (
    <>
      <div className="mb-3">
        <Tabs data={tabs} activeTab={activeTab} callback={tabCb} template={3} />
      </div>

      {activeTab.key == "stockSummary" && (
        <StockSummaryList
          pId={data._id}
          preorderWhList={data?.preorderWhList || []}
        />
      )}

      {activeTab.key == "tierDetails" && <TierDetails data={data.tierData} />}

      {activeTab.key == "stockLedgerHistory" && (
        <StockLedgerHistory pId={data._id} />
      )}

      {activeTab.key == "location" && <LocationWise pId={data._id} />}
      {activeTab.key == "document" && (
        <DocumentWise pId={data._id} expiryModalCb={expiryModalCb} />
      )}
      {activeTab.key == "imei" && <ImeiWise pId={data._id} />}
    </>
  );
};

export default StockManagement;
