import { AppTitle } from "@sk/uis";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";

const CategoryAttributeInput = ({ data, parentIndex }) => {
  const { name, attributes } = data;
  const { register } = useFormContext();

  return (
    <div className="py-3 card">
      <div className="card-body">
        <AppTitle title={`Category : ${name} Attributes`} type="header" />
        <div className="row">
          {attributes.map((item, index) => (
            <div key={item._id ?? index + "col"} className="mt-2 col-4">
              <div>
                <label className="fs-val-md">
                  {item.name}
                  {item.mandatory && <span className="text-danger"> * </span>}
                </label>
              </div>
              <div>
                {item.type === "Text" && (
                  <input
                    type="text"
                    className="form-control"
                    placeholder={item.name}
                    {...register(
                      `attributesList.${parentIndex}.attributes.${index}.value`
                    )}
                  />
                )}

                {item.type === "TextArea" && (
                  <textarea
                    className="form-control"
                    placeholder={item.name}
                    {...register(
                      `attributesList.${parentIndex}.attributes.${index}.value`
                    )}
                    cols={5}
                    rows={3}
                  ></textarea>
                )}
                {item.type === "Number" && (
                  <input
                    type="number"
                    className="form-control"
                    placeholder={item.name}
                    {...register(
                      `attributesList.${parentIndex}.attributes.${index}.value`
                    )}
                  />
                )}
                {item.type === "Radio" &&
                  item.pattern.split(",").map((i) => (
                    <Fragment key={i}>
                      <label className="me-2">{i}</label>
                      <input
                        type="radio"
                        value={i}
                        className="form-check-input me-2"
                        {...register(
                          `attributesList.${parentIndex}.attributes.${index}.value`
                        )}
                      />
                    </Fragment>
                  ))}

                {item.type === "Select" && (
                  <select
                    className=" form-select"
                    {...register(
                      `attributesList.${parentIndex}.attributes.${index}.value`
                    )}
                  >
                    {item.pattern &&
                      item.pattern.split(",").map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryAttributeInput;
