import { TableHeader, TextInput, TextareaInput, Toaster } from "@sk/uis";
import classNames from "classnames";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { manageView } from "../../constantService";

const tableHeader = [{ label: "Name", width: "60%" }, { label: "Action" }];

const highlightInputValidation = yup.object().shape({
  highlightInput: yup.string().trim().required("must be valid keyword"),
});
const Highlights = () => {
  const {
    register,
    getValues,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [highlightList, setHighlightList] = useState(() =>
    getValues("highlights")
  );
  const [edit, setEdit] = useState({ isEditing: false, index: -1 });

  const submitHighlight = async () => {
    let highlightInput = getValues("highlightInput");
    let errMsg = "";
    try {
      const e = await highlightInputValidation.validate({
        highlightInput,
      });
    } catch (error) {
      errMsg = error.message;
    }

    if (errMsg) {
      Toaster.error(errMsg);
      return;
    }

    const list = getValues("highlights");
    //  if is Editing
    if (edit.isEditing) {
      let newList = list.filter((item, index) => {
        if (index === edit.index) {
          return false;
        }
        return item === highlightInput ? true : false;
      });
      if (!newList.length) {
        list.splice(edit.index, 1, highlightInput);
        setEdit({ isEditing: false, index: -1 });
      } else {
        Toaster.error(highlightInput + " already exists in List ");
      }
    } else {
      let isExist = list.find((item) => item === highlightInput);
      if (!isExist) {
        list.push(highlightInput);
      } else {
        Toaster.error(highlightInput + " already exists in List ");
      }
    }

    setValue("highlights", list);
    setHighlightList(getValues("highlights"));
    setValue("highlightInput", "");
  };

  const deleteHighlight = (index) => {
    const list = getValues("highlights");
    list.splice(index, 1);
    setValue("highlights", list);
    setHighlightList(getValues("highlights"));
  };

  const editHighlight = (id) => {
    setEdit({ isEditing: true, index: id });
    const val = highlightList.find((x, i) => id === i);
    setValue("highlightInput", val);
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <TextareaInput
            label="Short Description"
            error={errors?.shortDescription?.message}
            name="shortDescription"
            errors={errors}
            isMandatory={false}
            register={register}
            info={manageView.infoContext.shortDescription}
          />
        </div>
        <div className="col-6">
          <div className=" my-3 py-4">
            <div className="row">
              <div className="col">
                <TextInput
                  label="Highlights"
                  register={register}
                  name="highlightInput"
                  type="text"
                  error={errors?.highlightInput?.message}
                  placeholder="Enter highlight here... "
                  info={manageView.infoContext.highlightInput}
                />
              </div>
              {/* Add Highlights */}
              <div className="col-auto align-self-center mt-2">
                <button
                  onClick={submitHighlight}
                  type="button"
                  className={classNames({
                    btn: true,
                    "btn-primary": !edit.isEditing,
                    "btn-success": edit.isEditing,
                  })}
                >
                  {edit.isEditing ? "Update" : "Add"}
                </button>
              </div>
            </div>

            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {highlightList.length > 0 && (
                <table
                  className="table table-bordered my-3"
                  style={{
                    overflowY: "scroll",
                    maxHeight: "30vh",
                  }}
                >
                  <TableHeader data={tableHeader} />
                  <tbody>
                    {highlightList.map((item, index) => (
                      <tr key={index} className="fs-val-md">
                        <td>{item}</td>
                        <td>
                          <span
                            className="me-3"
                            role="button"
                            tabIndex={0}
                            onClick={() => editHighlight(index)}
                          >
                            <i className="bi bi-pencil-square text-primary shadow p-1 rounded"></i>
                          </span>
                          <span
                            className="me-5 shadow p-1 rounded"
                            onClick={() => deleteHighlight(index)}
                            role="button"
                            tabIndex={0}
                          >
                            <i className="bi bi-trash text-danger"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Highlights;
