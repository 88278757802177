import { CommonService, DealService } from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  PageInfo,
  Rbac,
  SummaryCard,
} from "@sk/uis";
import React, { useCallback, useEffect, useRef, useState } from "react";
import StoreComboTable from "./components/StoreComboTable";
import StoreComboManageModal from "../modals/StoreComboManageModal";
import StoreComboFilter from "./components/StoreComboFilter";
import { set } from "date-fns";
import StoreComboViewModal from "../modals/StoreComboViewModal";
import { merge } from "lodash";
import produce from "immer";
import StoreComboCloneModal from "../modals/StoreComboCloneModal";

const rbac = {
  addStoreCombo: ["AddStoreCombo"],
};

const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Store Combos Deals",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const defaultFilterData = {
  status: "active",
};

const defaultFilterLabels = {
  status: {
    label: "Status",
  },
  franchise: {
    label: "Store",
    valuePath: "[0].value.name",
  },
  deal: {
    label: "Deal",
    valuePath: "[0].value.name",
  },
  createdAt: {
    label: "Date Range",
    type: "dateRange",
    canRemove: true,
    resetVal: [],
  },
  linkedDeal: {
    label: "Linked Deal",
    valuePath: "[0].value.name",
  },
};

const defaultSummaryData = [
  {
    label: "Total Combos",
    value: 0,
    color: "primary",
    key: "total",
  },
  {
    label: "Active Combos",
    value: 0,
    color: "success",
    key: "approvalPending",
  },
  {
    label: "Inactive Combos",
    value: 0,
    color: "danger",
    key: "pendingPickup",
  },
];

const getData = async (params) => {
  const keys = [
    "_id",
    "franchiseName",
    "isActive",
    "franchiseId",
    "lastUpdated",
    "_linkedDeals",
    "comboDealId",
    "comboDealName",
    "discountValue",
    "discountType",
  ];
  const r = await DealService.getStoreComboDeals(params);
  const d = (Array.isArray(r.resp) ? r.resp : []).map((e) => {
    let t = {};
    keys.forEach((k) => {
      t[k] = e[k];
    });
    return t;
  });
  return { data: d };
};

const getCount = async (params) => {
  const p = { ...params };
  delete p.page;
  delete p.count;
  const r = await DealService.getStoreComboDealsCount(p);
  return { count: r.resp || 0 };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  const s = (filter?.search || "").trim();

  if (s) {
    p.filter._id = { $regex: s, $options: "gi" };
  }

  if (filter?.franchise?.length > 0) {
    const fid = filter.franchise[0].value._id;
    p.filter["franchiseId"] = fid;
  }

  if (filter?.deal?.length > 0) {
    const id = filter.deal[0].value._id;
    p.filter["comboDealId"] = id;
  }

  if (filter?.linkedDeal?.length > 0) {
    const id = filter.linkedDeal[0].value._id;
    p.filter["deals.dealId"] = id;
  }

  if (filter?.status == "active") {
    p.filter.isActive = true;
  }

  if (filter?.status == "inactive") {
    p.filter.isActive = false;
  }

  if (filter?.status == "inactive") {
    p.filter.isActive = false;
  }

  if (filter?.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (!Object.keys(filter).length) {
    delete p.filter;
  }
  return p;
};

const StoreComboList = () => {
  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [manageModal, setManageModal] = useState({ show: false, editId: "" });

  const [viewModal, setViewModal] = useState({ show: false, configId: "" });

  const [cloneModal, setCloneModal] = useState({ show: false, configId: "" });

  const [summary, setSummary] = useState([...defaultSummaryData]);

  const [filterLabels, setFilterLabels] = useState([]);

  const filterDataRef = useRef({ ...defaultFilterData });

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    prepareFilterLabels();

    loadList();

    loadSummary();

    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const d = r.data || [];
    setData(d);
    setLoadingData(false);
  }, []);

  const loadSummary = async () => {
    let p = prepareFilterParams(filterDataRef.current, paginationRef.current);
    delete p.page;
    delete p.count;

    let arr = [
      getCount(merge({}, p, { filter: { isActive: { $in: [true, false] } } })),
      getCount(merge({}, p, { filter: { isActive: true } })),
      getCount(merge({}, p, { filter: { isActive: false } })),
    ];

    const r = await Promise.all(arr);

    setSummary(
      produce((draft) => {
        draft.forEach((e, k) => {
          draft[k].value = r[k]?.count;
        });
      })
    );
  };

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const manageModalCb = (e) => {
    if (e.action == "submitted") {
      applyFilter();
    }
    setManageModal({ show: false, editId: "" });
  };

  const viewModalCb = () => {
    setViewModal({ show: false, configId: "" });
  };

  const cloneModalCb = (e) => {
    if (e.action == "submit") {
      applyFilter();
    }
    setCloneModal({ show: false, configId: "" });
  };

  const tblCb = (e) => {
    if (e.action == "edit") {
      setManageModal({
        show: true,
        editId: e.id,
      });
    }

    if (e.action == "view") {
      setViewModal({ show: true, configId: e.id });
    }

    if (e.action == "clone") {
      setCloneModal({ show: true, configId: e.id });
    }
  };

  const onFilterCb = (e) => {
    if (e.action == "apply" || e.action == "reset") {
      filterDataRef.current = { ...filterDataRef.current, ...e.formData };
      applyFilter();
    }
  };

  const prepareFilterLabels = () => {
    const v = { ...filterDataRef.current };

    if (!v.hideUnlinkedBrands) {
      delete v.hideUnlinkedBrands;
    }

    const l = CommonService.prepareAppliedFilterLabels(defaultFilterLabels, v);

    setFilterLabels(l);
  };

  return (
    <>
      <PageInfo title="Store Combo Deals" breadcrumbs={breadcrumbs} />

      <div className="row">
        {summary.map((e) => (
          <div className="col col-auto" key={e.key}>
            <SummaryCard
              value={e.value}
              title={e.label}
              template={3}
              isValueAmt={e.isValueAmt}
              amtDecimalPlace={0}
              valueColor={e.color}
            />
          </div>
        ))}
        <Rbac roles={rbac.addStoreCombo}>
          <div className="col align-self-center text-end">
            <button
              className="btn btn-primary mb-3"
              onClick={() => setManageModal({ show: true })}
            >
              <i className="bi bi-plus-square bg-lg"></i>
              <span> Create Combo </span>
            </button>
          </div>
        </Rbac>
      </div>

      <div>
        <StoreComboFilter callback={onFilterCb} />
      </div>

      <AppCard>
        {filterLabels.length > 0 ? (
          <div className="mb-3">
            <AppliedFilterLabel labels={filterLabels} />
          </div>
        ) : null}
        <StoreComboTable
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          callback={tblCb}
        />
      </AppCard>

      <StoreComboManageModal
        callback={manageModalCb}
        show={manageModal.show}
        editId={manageModal.editId}
      />

      <StoreComboViewModal
        callback={viewModalCb}
        show={viewModal.show}
        configId={viewModal.configId}
      />

      <StoreComboCloneModal
        callback={cloneModalCb}
        show={cloneModal.show}
        configId={cloneModal.configId}
      />
    </>
  );
};

export default StoreComboList;
