import { KeyVal, Toaster } from "@sk/uis";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const FixedComboQtyEditModal = ({ productDetails, show, callback }) => {
  const [quantity, setQuantity] = useState("");

  useEffect(() => {
    if (show) {
      setQuantity(productDetails?.comboQty);
    }
  }, [productDetails, show]);

  const handleSubmit = () => {
    const qty = Number(quantity);
    if (isNaN(qty) || qty <= 0) {
      Toaster.error("Quantity must be a number greater than zero.");
      return;
    }
    if (qty > productDetails?.qty) {
      Toaster.error(
        `Quantity cannot be greater than stock (${productDetails?.qty}).`
      );
      return;
    }
    callback({
      action: "qtyUpdate",
      data: { quantity: qty, product: productDetails },
    });
  };

  const handleClose = () => {
    callback({ action: "close", data: { product: null, quantity: null } });
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton className="bg-light border-bottom">
        <Modal.Title className="text-dark h5 mb-0">
          <i className="bi bi-pencil-square me-2"></i>
          Edit Combo Quantity
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="mb-4 border-bottom pb-3">
          <KeyVal label="Product ID">: {productDetails?._id}</KeyVal>
          <KeyVal label="Product Name">
            <div className="d-flex">
              <div className="me-1">:</div>
              <div>{productDetails?.name}</div>
            </div>
          </KeyVal>
          <KeyVal label="Category">: {productDetails?.categoryName}</KeyVal>
          <KeyVal label="Stock">
            : {productDetails?._qty}{" "}
            {productDetails?.sellInLooseQty ? "kg" : "units"}
          </KeyVal>
        </div>

        <div>
          <label className="fw-medium mb-2">
            Combo Quantity <span className="text-danger">*</span>{" "}
          </label>
          <input
            type="number"
            className="form-control"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            min={1}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <button
          className="btn btn-primary fw-medium px-4"
          onClick={handleSubmit}
        >
          Update Quantity
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FixedComboQtyEditModal;
