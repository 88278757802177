// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mXWM5KSvQV71tUAqIP31Ew\\=\\={font-size:.75rem;background-color:rgba(0,0,0,0);border:none;border-bottom:1px dashed #ccc;width:auto;padding:.25rem 0;appearance:none;outline:none}.mXWM5KSvQV71tUAqIP31Ew\\=\\=:focus{border-bottom:1px dashed #007bff}", "",{"version":3,"sources":["webpack://./src/pages/overview/CmsOverview.module.scss"],"names":[],"mappings":"AAAA,4BACE,gBAAA,CACA,8BAAA,CACA,WAAA,CACA,6BAAA,CACA,UAAA,CACA,gBAAA,CACA,eAAA,CACA,YAAA,CAIF,kCACE,gCAAA","sourcesContent":[".select-custom {\n  font-size: 0.75rem;\n  background-color: transparent;\n  border: none;\n  border-bottom: 1px dashed #ccc;\n  width: auto;\n  padding: 0.25rem 0;\n  appearance: none;\n  outline: none;\n  // color: var(--bs-secondary);\n}\n\n.select-custom:focus {\n  border-bottom: 1px dashed #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-custom": "mXWM5KSvQV71tUAqIP31Ew=="
};
export default ___CSS_LOADER_EXPORT___;
