import { ProductService } from "@sk/services"; // Assuming a ProductService exists
import { Spinner } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import overviewStyles from "../CmsOverview.module.scss";
import CmsOverviewProductFilterModal from "../modals/CmsOverviewProductFilterModal"; // Import the modal

const CmsOverviewProducts = ({ menuId, categoryId, brandId, callback }) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [records, setRecords] = useState({
    loading: true,
    value: 0,
  });
  const [search, setSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState("Active");
  const [showFilterModal, setShowFilterModal] = useState(false); // State for modal visibility
  const [isFilterApplied, setIsFilterApplied] = useState(false); // New state to track filter application

  const filterRef = useRef({
    status: "Active",
  });
  const paginationRef = useRef({
    page: 1,
    count: 60,
  });
  const selectedProductRef = useRef(null);

  const debouncedSearch = useCallback(
    debounce(() => {
      init();
    }, 500),
    []
  );

  useEffect(() => {
    filterRef.current = {
      ...filterRef.current,
      selectedIds: [],
      menuId,
      categoryId,
      brandId,
    };
    paginationRef.current = {
      ...paginationRef.current,
      page: 1,
    };
    setIsFilterApplied(false);
    init();
  }, [menuId, categoryId, brandId, init]);

  useEffect(() => {
    if (products.length > 0 && !selectedProductRef.current) {
      callback({ action: "select", value: products[0] });
    }
  }, [callback, products]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = {
      ...paginationRef.current,
      page: 1,
    };

    setLoading(true);
    setRecords({
      loading: true,
      value: 0,
    });
    setProducts([]);

    const params = getFilterParams(filterRef.current, paginationRef.current);
    const data = await getData(params);
    const { count } = await getCount(params);
    setProducts(data);
    setLoading(false);
    setRecords({
      loading: false,
      value: count,
    });
  }, []);

  const loadMore = async () => {
    paginationRef.current = {
      ...paginationRef.current,
      page: paginationRef.current.page + 1,
    };
    const params = getFilterParams(filterRef.current, paginationRef.current);
    const data = await getData(params);
    setProducts([...products, ...data]);
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
    filterRef.current = {
      ...filterRef.current,
      search: e.target.value,
    };
    debouncedSearch();
  };

  const onSelect = (product) => {
    selectedProductRef.current = product;
    callback({ action: "select", value: product });
  };

  const onFilterChange = (e) => {
    const selectedValue = e.target.value;
    setFilterStatus(selectedValue);
    filterRef.current = {
      ...filterRef.current,
      status: selectedValue === "all" ? undefined : selectedValue,
    };
    applyFilter();
  };

  // New function to handle modal callback
  const handleModalCallback = ({ action, formData }) => {
    setShowFilterModal(false); // Close the modal
    if (action === "reset" || action === "apply") {
      filterRef.current = {
        ...filterRef.current,
        selectedIds: formData?.selectedIds || [],
      };
      setIsFilterApplied(formData?.selectedIds?.length > 0); // Update filter applied state
      applyFilter();
    }
  };

  return (
    <div className="p-2">
      <div className="d-flex mb-2">
        <input
          placeholder="Search"
          className="form-control form-control-sm me-2"
          value={search}
          onChange={onSearch}
        />

        <button
          className="btn btn-sm btn-outline-secondary position-relative"
          onClick={() => setShowFilterModal(true)} // Open the filter modal
        >
          <i className="bi bi-funnel"></i>
          {isFilterApplied && (
            <i className="bi bi-dot fs-val-lg text-danger position-absolute top-0 end-0"></i>
          )}
        </button>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-1">
        <div className="fs-val-sm text-muted flex-grow-1">
          Showing <span className="text-dark">{records.value}</span> records
        </div>
        <select
          className={overviewStyles["select-custom"]}
          onChange={onFilterChange}
          value={filterStatus}
        >
          <option value="all">All</option>
          <option value="Active">Only Active</option>
          <option value="Inactive">Only Inactive</option>
        </select>
      </div>

      <div className="custom-scrollbar thin-scrollbar" style={styles.container}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : products.length === 0 ? (
          <div className="text-center fs-val-sm">No data found</div>
        ) : null}

        {products.length > 0 ? (
          <>
            {products.map((product) => (
              <div
                key={product._id}
                className="fs-val-sm py-2 border-bottom cursor-pointer d-flex align-items-center"
                onClick={() => onSelect(product)}
              >
                <i
                  className={`bi bi-circle-fill fs-val-xs me-2 mt-1 align-self-start ${
                    product.status === "Active" ? "text-success" : "text-danger"
                  }`}
                ></i>
                <div className="flex-grow-1">
                  <div className="fw-semibold mb-1">{product.name}</div>
                  <div className="d-flex justify-content-between mt-1 fs-val-xs text-black-50">
                    <span>ID: {product._id}</span>
                    <span>
                      Available Stock: {product.availableStock || 0} units
                    </span>
                  </div>
                </div>
                <i className="bi bi-chevron-right fs-val-xs mx-2"></i>
              </div>
            ))}
            {records.value >= paginationRef.current.count ? (
              <div className="text-center mt-2">
                <button className="btn btn-sm btn-light" onClick={loadMore}>
                  Load More
                </button>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      {/* Modal for filtering products */}
      <CmsOverviewProductFilterModal
        show={showFilterModal}
        callback={handleModalCallback} // Use the new callback function
        brandId={brandId}
        categoryId={categoryId || menuId}
      />
    </div>
  );
};

const styles = {
  container: {
    height: "calc(100vh - 200px)",
    overflowY: "auto",
  },
};

const getData = async (params) => {
  const response = await ProductService.getList(params);
  return response.resp;
};

const getCount = async (params) => {
  const p = { ...params };
  delete p.page;
  delete p.count;
  delete p.sort;
  const response = await ProductService.getCount(p);
  return { count: response.resp };
};

const getFilterParams = (filter, pagination) => {
  const params = {
    page: pagination.page,
    count: pagination.count,
    sort: "name",
    filter: {},
    select: "name,status,original_creator_type",
  };

  if (filter.menuId) {
    params.filter.category = filter.menuId;
  }

  if (filter.categoryId) {
    params.filter.category = filter.categoryId;
  }

  if (filter.brandId) {
    params.filter.brand = filter.brandId;
  }

  if (filter.selectedIds && filter.selectedIds.length > 0) {
    if (filter.brandId) {
      params.filter.category = {
        $in: filter.selectedIds.map((e) => e.id),
      };
    } else if (filter.categoryId || filter.menuId) {
      params.filter.brand = {
        $in: filter.selectedIds.map((e) => e.id),
      };
    }
  }

  if (filter.search) {
    params.filter.$or = [
      { _id: filter.search },
      { name: { $regex: filter.search, $options: "i" } },
    ];
  }

  if (filter.status) {
    params.filter.status = filter.status;
  }

  if (Object.keys(params.filter).length === 0) {
    delete params.filter;
  }

  return params;
};
export default CmsOverviewProducts;
