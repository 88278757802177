/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from "@hookform/resolvers/yup";
import { AjaxService, CommissionService, CommonService } from "@sk/services";
import {
  Alert,
  AppTitle,
  DatePickerInput,
  EntitySearchInput,
  InputErrorMsg,
  PageLoader,
  SelectInput,
  Spinner,
  SwitchInput,
  TextInput,
  Toaster,
} from "@sk/uis";
import { differenceInMinutes } from "date-fns";
import produce from "immer";
import { uniqBy } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import ExcludeForTable from "../components/ExcludeForTable";

const searchTypeOptions = [
  { label: "Deal", value: "deal" },
  {
    label: "Category",
    value: "category",
  },
  { label: "Brand", value: "brand" },
];

// const extraRewardOptions = [
//   { label: "Yes", value: "yes" },
//   { label: "No", value: "no" },
// ];

// Validation Schema For Brand
const managePosSmartCoinsValidationSchema = yup.object({
  status: yup.string().required("Status is required"),
  ref: yup.string().required("Classification is required"),
  minValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, "Cannot Be less than 1")
    .required("Min Value is Required"),
  maxValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(yup.ref("minValue"), "Cannot be less Than Min Value")
    .required("Max is Required"),
  level: yup.string().required("Store Type is Required"),
  rewardType: yup.string().required("Reward Type is Required"),
  maxCoins: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("rewardType", {
      is: "Percentage",
      then: (schema) =>
        schema
          .min(1, "Cannot Be less than one")
          .required("MaxCoins is Required"),
    }),
  multipleValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .when("extraReward", {
      is: (extraReward) => extraReward,
      then: (schema) =>
        schema
          .label("Coin Multiplier")
          .min(1, "Coin Multiplier Are required")
          .max(10, "cannot be greater than 10")
          .required(),
    }),

  validFrom: yup.date().when("extraReward", {
    is: (extraReward) => {
      return extraReward;
    },
    then: (schema) =>
      schema
        .label("Start Date")
        .typeError("Valid From Date is Required")
        .required("Valid From Date is Required "),
  }),

  validTo: yup.date().when("extraReward", {
    is: (extraReward) => {
      return extraReward;
    },
    then: (schema) =>
      schema
        .label("End Date")
        .typeError("Valid to Date is Required")
        .min(yup.ref("validFrom"), "Must be greater than Valid to Date")
        .required("Valid to Date is Required"),
  }),
  rewardValue: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(1, "Cannot be less than 1")
    .when("rewardType", {
      is: (rewardType) => rewardType == "Percentage",
      then: (schema) =>
        schema
          .label("Reward Value")
          .min(0.1, "Cannot Be less than 0.1")
          .max(100, "Cannot Greater than 100"),
    })
    .required("Reward Value is Required"),
});

// default From Values
const defaultFromData = {
  ref: "",
  disableStartDate: false,
  status: true,
  rewardValue: "",
  extraReward: false,
  maxValue: "",
  minValue: "",
  level: "",
  rewardType: "",
  maxCoins: "",
  multipleValue: "",
  excludeFor: {
    brand: [],
    category: [],
    menu: [],
    deal: [],
  },
  brand: [],
  category: [],
  menu: [],
  deal: [],
  searchType: "brand",
};

const rewardTypeOptions = [
  { label: "Select", value: "" },
  { label: "Percentage", value: "Percentage" },
  { label: "Fixed", value: "Fixed" },
];

const storeTypeOptions = [
  { label: "Select", value: "" },
  { label: "COCO", value: "COCO" },
  { label: "FOFO", value: "FOFO" },
];

let startDateConfig = {
  minDate: new Date(),
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

let endDateConfig = {
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "55%",
  },
};

// MasterBrand Entity Search Input Filter
const ManagePosSmartCoinsModal = ({
  show,
  closeModal,
  mode,
  id,
  classificationOpt,
}) => {
  // To show Spinner on Submit Button While Submitting Form
  const [submitting, setSubmitting] = useState(false);

  // Page Loader
  const [pageLoading, setPageLoading] = useState(false);

  // To store Exclude For Data in

  const [excludeForData, setExcludeForData] = useState([]);

  // React Hook Form
  const {
    formState: { errors },
    setValue,
    getValues,
    control,
    register,
    trigger,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(managePosSmartCoinsValidationSchema),
    defaultValues: { ...defaultFromData },
  });

  const classificationOptions = useMemo(
    () =>
      classificationOpt.map((x) => {
        if (x.label == "All") {
          return {
            label: "Select",
            value: "",
          };
        } else {
          return {
            label: x.label,
            value: x.id,
          };
        }
      }),
    [classificationOpt]
  );

  useEffect(() => {
    if (show) {
      // when the modal get opened clear the form data
      reset(defaultFromData);

      if (mode == "edit") {
        if (id) {
          loadEditData(id);
        } else {
          Toaster.error("Invalid ID");
          triggerCloseModal();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id, show]);

  // To Prepare Form Values From LoadEditData
  const prepareFromValue = async (r) => {
    // Fields
    [
      "rewardValue",
      "extraReward",
      "maxValue",
      "excludeFor",
      "minValue",
      "level",
      "rewardType",
      "maxCoins",
    ].forEach((x) => setValue(x, r[x] || ""));

    // For Master Brand
    if (r.extraReward) {
      ["validFrom", "validTo", "multipleValue"].forEach((x) =>
        setValue(x, r[x] || "")
      );

      // update start date min value - start date is older than today date
      const startDate = new Date(r.validFrom);

      const isStartDateDisabled =
        differenceInMinutes(startDate, new Date()) <= 0;

      setValue(disableStartDate, isStartDateDisabled);

      if (isStartDateDisabled) {
        startDateConfig = { ...startDateConfig, minDate: startDate };
      }
    }
    if (r.status) {
      setValue("status", r.status == "Active");
    }
    if (r.refId) {
      setValue("ref", r.refId);
    }
    if (r.excludeFor) {
      Object.keys(r.excludeFor).forEach((key) => {
        const list = r.excludeFor[key].map((x) => ({ ...x, type: key }));
        setValue(`excludeFor${key}`, list);
      });

      Object.keys(r.excludeFor).forEach((key) => {
        const list = getValues(`excludeFor${key}`);
        setExcludeForData(
          produce((draft) => {
            draft.push(...list);
          })
        );
      });
    }
    // getting Master Brand
  };

  // To get Edit Data When Id Is There
  const loadEditData = async (id) => {
    setPageLoading(true);
    const r = await CommissionService.getPosConfigById(id);
    const d = r.resp || {};

    if (!d._id) {
      Toaster.error("Failed to fetch details, please try again");
      triggerCloseModal();
      setPageLoading(false);
      return;
    }
    await prepareFromValue(d);
    setPageLoading(false);
  };

  // Preparing Payload While Hitting API
  const preparePayload = (data) => {
    let p = {
      refId: data.ref,
      rewardType: data.rewardType,
      status: data.status == "true" ? "Active" : "Inactive",
      rewardValue: data.rewardValue,
      minValue: data.minValue,
      maxValue: data.maxValue,
      level: data.level,
      extraReward: data.extraReward,
    };
    if (data.ref) {
      p.refType = classificationOptions.find((x) => {
        return data.ref == x.value;
      }).label;
    }
    if (data.extraReward) {
      p.validFrom = data.validFrom;
      p.validTo = data.validTo;
      p.multipleValue = data.multipleValue;
    }
    if (data.rewardType == "Percentage") {
      p.maxCoins = data.maxCoins;
    }
    if (data.excludeFor) {
      Object.keys(data.excludeFor).forEach((key) => {
        data.excludeFor[key] = data.excludeFor[key].map((x) => ({
          name: x.name,
          id: x.id,
        }));
      });

      p.excludeFor = data.excludeFor;
    }
    return p;
  };

  // for resetting the form data and its dependent state
  const resetFormData = () => {
    reset();
  };

  const onNumberInputChange = (e) => {
    const v = CommonService.roundedByDecimalPlace(1 * e.target.value, 0);
    e.target.value = v;
  };

  const onRewardValueChange = (e) => {
    const v = CommonService.roundedByDecimalPlace(
      1 * e.target.value,
      rewardType == "Percentage" ? 1 : 0
    );
    e.target.value = v;
  };

  const onRewardTypeChange = () => {
    setValue("rewardValue", "");
  };

  const onSubmit = async (data) => {
    if (data.minValue >= data.maxValue) {
      Toaster.error("Min value Cannot be less or Smaller than Max Value");
      return;
    }
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: `Do you want to ${mode == "edit" ? "update " + id : "proceed"} ? `,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    const p = preparePayload(data);
    setSubmitting(true);

    let r = {};

    if (mode == "add") {
      r = await CommissionService.createPosReward(p);
    } else {
      r = await CommissionService.updatePosRewards(id, p);
    }

    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg || "Failed to update");
      return;
    }

    resetFormData();

    Toaster.success(`${mode == "add" ? "Added" : "Updated"} Successfully`);

    triggerCloseModal("submit");
  };

  // To Close Modal
  const triggerCloseModal = (status = "cancel") => {
    closeModal({ status });
    setExcludeForData([]);
  };

  // To check Weather Is isExtra Reward is checked or Not
  const isExtraReward = useWatch({
    name: "extraReward",
    control: control,
  });

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      chngFn(val);
      setValue(key, val[0]);
      if (key == "validFrom") {
        endDateConfig = { ...endDateConfig, minDate: val[0] };
      }
      trigger("validTo");
    };
  };

  const searchType = useWatch({
    control: control,
    name: "searchType",
  });
  const excludeFor = useWatch({
    control: control,
    name: "excludeFor",
  });

  const addExcludeFor = () => {
    const data = getValues(searchType);

    // Preparing Values
    const tempValues = {
      name: data?.[0]?.value?.name,
      id: data?.[0]?.value?._id,
      type: searchType,
    };

    const excludeForValidation = validateExcludeFor(data, tempValues);

    if (!excludeForValidation.valid) {
      Toaster.error(excludeForValidation.msg);
      return;
    }

    setExcludeForData(
      produce((draft) => {
        draft.unshift(tempValues);
      })
    );

    const oldValues = getValues(`excludeFor.${searchType}`);

    const list = uniqBy([...oldValues, tempValues], "id");

    //setting unique Values
    setValue(`excludeFor.${searchType}`, list);

    // reset current
    ["brand", "category", "deal"].forEach((x) => setValue(x, []));
    // const res = await  trigger("excludeFor");

    Toaster.success(
      `Successfully added  : ${tempValues.name} - ${tempValues.id} `
    );
  };

  const validateExcludeFor = (data, tempValues) => {
    let msg = "";
    const isItemAlreadyExists = excludeForData.some(
      (x) => tempValues.id == x.id
    );

    if (!data.length) {
      msg = "Please select at least One " + searchType;
    } else if (isItemAlreadyExists) {
      msg = "This " + searchType + " already exists";
    } else {
      msg = "";
    }
    return {
      msg,
      valid: !msg,
    };
  };

  const removeFromExclude = async (item) => {
    let list = getValues(`excludeFor.${item.type}`);

    // asking confirm to submit
    let res = await Alert.confirm({
      title: `<p class="fs-val-lg p-0 mb-0" > Please confirm </p>`,
      text: `<p class ="fs-val-normal p-0 mt-0"> Do you want to delete ${item.name} - ${item.id} ? </p>`,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    // Setting Values in Exclude For
    list = list.filter((x) => x.id != item.id);
    setValue(`excludeFor.${item.type}`, list);

    // Setting Values for Display Purpose
    const excludeForList = excludeForData.filter((x) => x.id != item.id);
    setExcludeForData(excludeForList);
  };

  const rewardType = useWatch({
    control: control,
    name: "rewardType",
  });

  const disableStartDate = useWatch({
    control: control,
    name: "disableStartDate",
  });

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title={mode === "edit" ? "Edit Config" + `  #${id}` : "Add  Config"}
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <>
              <div>
                <form autoComplete="off">
                  <div className="row px-4 py-3 border-bottom mb-2 mx-0">
                    <div className="col-4 mb-2">
                      <SelectInput
                        label="Store Type"
                        register={register}
                        name="level"
                        error={errors?.level?.message}
                        options={storeTypeOptions}
                        isMandatory={true}
                      />
                    </div>

                    <div className="col-4 mb-2">
                      <SelectInput
                        label="Classification"
                        register={register}
                        name="ref"
                        error={errors?.ref?.message}
                        options={classificationOptions}
                        isMandatory={true}
                      />
                    </div>

                    <div className="col-4 ">
                      <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, value } }) => (
                          <SwitchInput
                            label="Active"
                            name="status"
                            callback={onChange}
                            value={value}
                          />
                        )}
                      />
                    </div>

                    <div className="col-4">
                      <SelectInput
                        label="Reward Type"
                        register={register}
                        name="rewardType"
                        error={errors?.rewardType?.message}
                        options={rewardTypeOptions}
                        callback={onRewardTypeChange}
                        isMandatory={true}
                      />
                    </div>

                    <div className="col-4">
                      <TextInput
                        name="minValue"
                        label="Min Value Rs"
                        callback={onNumberInputChange}
                        type="number"
                        register={register}
                        error={errors?.minValue?.message}
                        placeholder="Enter Min Value"
                        isMandatory={true}
                        info={{
                          content: "Single deal price in cart",
                          placement: "right",
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <TextInput
                        name="maxValue"
                        label="Max Values Rs"
                        callback={onNumberInputChange}
                        type="number"
                        error={errors?.maxValue?.message}
                        register={register}
                        placeholder="Enter Max Value"
                        isMandatory={true}
                        info={{
                          content: "Single  price in cart",
                          placement: "right",
                        }}
                      />
                    </div>

                    <div className="col-4">
                      <TextInput
                        name="rewardValue"
                        label={`Reward Values  ${
                          rewardType == "Percentage" ? "%" : ""
                        } `}
                        type="number"
                        callback={onRewardValueChange}
                        error={errors?.rewardValue?.message}
                        register={register}
                        placeholder="Enter Reward Value"
                        isMandatory={true}
                      />
                    </div>

                    {rewardType != "Fixed" && (
                      <div className="col-4">
                        <TextInput
                          name="maxCoins"
                          label="Max Coins"
                          type="number"
                          callback={onNumberInputChange}
                          error={errors?.maxCoins?.message}
                          register={register}
                          placeholder="Enter Max Coins"
                          isMandatory={true}
                        />
                      </div>
                    )}
                  </div>

                  <div className="row px-4 py-3 border-bottom mb-2 mx-0">
                    <div className="col-12 align-self-end">
                      <Controller
                        control={control}
                        name="extraReward"
                        render={({ field: { onChange, value } }) => (
                          <SwitchInput
                            label="Reward during specific period ?"
                            name="extraReward"
                            callback={onChange}
                            value={value}
                          />
                        )}
                      />
                    </div>

                    {isExtraReward && (
                      <>
                        <div className="col-4">
                          <TextInput
                            name="multipleValue"
                            callback={onNumberInputChange}
                            label="Coin Multiplier"
                            type="number"
                            error={errors?.multipleValue?.message}
                            register={register}
                            placeholder="Enter Multiple Values Coins"
                            isMandatory={true}
                            info={{
                              content:
                                "If you want to give double coins (2X)then give 2 , for 3X 3",
                              placement: "right",
                            }}
                          />
                        </div>

                        <div className="col-4 mb-3">
                          <label className=" mb-0 fs-val-md">
                            Offer Start Date
                            <span className="text-danger">*</span>
                          </label>

                          <Controller
                            control={control}
                            name="validFrom"
                            render={({ field: { onChange, value } }) => {
                              return (
                                <DatePickerInput
                                  placeholder="Choose"
                                  value={[value]}
                                  disabled={disableStartDate}
                                  inpChange={dateChangeCb(
                                    onChange,
                                    "validFrom"
                                  )}
                                  config={startDateConfig}
                                />
                              );
                            }}
                          />
                          <InputErrorMsg msg={errors?.validFrom?.message} />
                        </div>
                        <div className="col-4 mb-3">
                          <label className="mb-0 fs-val-md">
                            Offer End Date
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            control={control}
                            name="validTo"
                            render={({ field: { onChange, value } }) => (
                              <DatePickerInput
                                placeholder="Choose"
                                value={[value]}
                                inpChange={dateChangeCb(onChange, "validTo")}
                                config={endDateConfig}
                              />
                            )}
                          />
                          <InputErrorMsg msg={errors?.validTo?.message} />
                        </div>
                      </>
                    )}
                  </div>

                  {/* Exclude For */}

                  <div className="px-4">
                    <AppTitle title="Add Exclusions" />
                  </div>
                  <div className="row px-4 mx-0">
                    <div className="col-3 ps-0">
                      <SelectInput
                        name="searchType"
                        label="Type"
                        register={register}
                        options={searchTypeOptions}
                        gap={0}
                      />
                    </div>

                    <div className="col-6">
                      <Controller
                        key={searchType}
                        control={control}
                        name={searchType}
                        render={({ field: { onChange, value } }) => (
                          <EntitySearchInput
                            type={searchType}
                            placeholder="Search by Name/ID"
                            value={value}
                            label={"Search For " + searchType}
                            callback={onChange}
                            uid={searchType}
                            emptyLabel={"No " + searchType.label + " found"}
                            gap={0}
                          />
                        )}
                      />
                    </div>
                    <div className="col-3 align-self-end">
                      <button
                        type="button"
                        className="btn btn-sm  btn-primary fs-val-md"
                        onClick={addExcludeFor}
                      >
                        Add
                      </button>
                      {/* <button
                              type="button"
                              className="btn btn-sm  btn-outline-primary fs-val-md mt-1"
                              onClick={addExcludeFor}
                            >
                              Add Bulk
                            </button> */}
                    </div>
                  </div>

                  <div className="border-bottom py-2"></div>

                  <div className="px-4">
                    <AppTitle title="Excluded Items List" />
                  </div>

                  <ExcludeForTable
                    excludeForData={excludeForData}
                    removeFromExclude={removeFromExclude}
                    mode="edit"
                  />
                </form>
              </div>
            </>
          )}
        </Offcanvas.Body>
        <Offcanvas.Header>
          <div className="my-3 w-100 row justify-content-between">
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-outline-dark me-1"
                onClick={triggerCloseModal}
              >
                Cancel
              </button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                // type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={submitting}
              >
                Submit {submitting ? <Spinner isSmall={true} /> : null}
              </button>
            </div>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default memo(ManagePosSmartCoinsModal);
