import { Amount, DateFormatter, KeyVal } from "@sk/uis";
import { useEffect, useState } from "react";
import { GrnService, NavService, OmsService, PoService } from "@sk/services";

const ProductOverview = ({ pId }) => {
  const [grn, setGrn] = useState({ loading: true, date: "", qty: 0, value: 0 });
  const [po, setPo] = useState({ loading: true, date: "", qty: 0, value: 0 });
  const [order, setOrder] = useState({
    loading: true,
    date: "",
    qty: 0,
    value: 0,
  });

  useEffect(() => {
    const fetchGrnData = async () => {
      if (pId) {
        try {
          const grnDetails = await GrnService.getLastGrnDetailsByProductId(pId);
          if (grnDetails) {
            setGrn({
              loading: false,
              id: grnDetails.id,
              date: grnDetails.date,
              qty: grnDetails.qty,
              invoiceQty: grnDetails.invoiceQty,
              value: grnDetails.value,
            });
          } else {
            setGrn({ loading: false, date: "", qty: 0, value: 0, id: "" });
          }
        } catch (error) {
          console.error("Error fetching GRN data:", error);
          setGrn({ loading: false, date: "", qty: 0, value: 0, id: "" });
        }
      } else {
        setGrn({ loading: false, date: "", qty: 0, value: 0, id: "" });
      }
    };

    const fetchPoData = async () => {
      if (pId) {
        try {
          const poDetails = await PoService.getLastPoDetailsByProductId(pId);
          setPo({
            id: poDetails.id,
            loading: false,
            date: poDetails.date,
            qty: poDetails.requestedQty,
            value: poDetails.value,
          });
        } catch (error) {
          console.error("Error fetching PO data:", error);
          setPo({ loading: false, date: "", qty: 0, value: 0, id: "" });
        }
      } else {
        setPo({ loading: false, date: "", qty: 0, value: 0, id: "" });
      }
    };

    const fetchOrder = async () => {
      if (pId) {
        try {
          const orderDetails =
            await OmsService.getLatestOrderDetailsByProductId(pId);
          setOrder({
            id: orderDetails.id,
            loading: false,
            date: orderDetails.date,
            qty: orderDetails.qty,
            value: orderDetails.value,
          });
        } catch (error) {
          console.error("Error fetching Order data:", error);
          setOrder({ loading: false, date: "", qty: 0, value: 0 });
        }
      } else {
        setOrder({ loading: false, date: "", qty: 0, value: 0 });
      }
    };

    fetchOrder();
    fetchGrnData();
    fetchPoData();
  }, [pId]);

  const viewOrder = () => {
    NavService.openInNewTab("/oms/orders/details", { id: order.id });
  };

  return (
    <div className="row">
      <div className="col">
        <div className="border rounded p-2 px-4 bg-white">
          <div className="fs-val-lg fw-semibold mb-2">
            Last Purchase Order Details
          </div>

          <KeyVal label="ID">
            : {po.loading ? "Loading..." : po.id ? po.id : "N/A"}
          </KeyVal>

          <KeyVal label="Date">
            :{" "}
            {po.loading ? (
              "Loading..."
            ) : po.date ? (
              <DateFormatter date={po.date} />
            ) : (
              "N/A"
            )}
          </KeyVal>

          <KeyVal label="Quantity">
            : {po.loading ? "Loading..." : po.qty ? po.qty : "N/A"}
            {po.requestedQty && (
              <span className="text-muted fs-val-sm ms-1">
                (Requested Qty: {po.requestedQty})
              </span>
            )}
          </KeyVal>

          <KeyVal label="Value">
            :{" "}
            {po.loading ? (
              "Loading..."
            ) : po.value ? (
              <Amount value={po.value} />
            ) : (
              "N/A"
            )}
          </KeyVal>
        </div>
      </div>

      <div className="col">
        <div className="border rounded p-2 px-4 bg-white">
          <div className="fs-val-lg fw-semibold mb-2">Last GRN Information</div>
          <KeyVal label="ID">
            : {grn.loading ? "Loading..." : grn.id ? grn.id : "N/A"}
          </KeyVal>

          <KeyVal label="Date">
            :{" "}
            {grn.loading ? (
              "Loading..."
            ) : grn.date ? (
              <DateFormatter date={grn.date} />
            ) : (
              "N/A"
            )}
          </KeyVal>

          <KeyVal label="Quantity">
            : {grn.loading ? "Loading..." : grn.qty ? grn.qty : "N/A"}{" "}
            {grn.invoiceQty && (
              <span className="text-muted fs-val-sm">
                (Invoice Qty: {grn.invoiceQty})
              </span>
            )}
          </KeyVal>

          <KeyVal label="Value">
            :{" "}
            {grn.loading ? (
              "Loading..."
            ) : grn.value ? (
              <Amount value={grn.value} />
            ) : (
              "N/A"
            )}
          </KeyVal>
        </div>
      </div>

      <div className="col">
        <div className="border rounded p-2 px-4 bg-white">
          <div className="fs-val-lg fw-semibold mb-2">
            Last Order Information
          </div>

          <KeyVal label="ID">
            :{" "}
            {order.loading ? (
              "Loading..."
            ) : order.id ? (
              <button
                className="btn btn-link p-0 text-dark fs-val-md"
                onClick={() => viewOrder()}
              >
                {order.id}
              </button>
            ) : (
              "N/A"
            )}
          </KeyVal>

          <KeyVal label="Date">
            :{" "}
            {order.loading ? (
              "Loading..."
            ) : order.date ? (
              <DateFormatter date={order.date} />
            ) : (
              "N/A"
            )}
          </KeyVal>

          <KeyVal label="Quantity">
            : {order.loading ? "Loading..." : order.qty ? order.qty : "N/A"}
          </KeyVal>

          <KeyVal label="Value">
            :{" "}
            {order.loading ? (
              "Loading..."
            ) : order.value ? (
              <Amount value={order.value} />
            ) : (
              "N/A"
            )}
          </KeyVal>
        </div>
      </div>
    </div>
  );
};

export default ProductOverview;
