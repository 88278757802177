import { useState, useEffect } from "react";
import {
  AppCard,
  ImgPreviewModal,
  ImgRender,
  NoDataFound,
  Spinner,
} from "@sk/uis";

const Images = ({ images }) => {
  const [showImgPreviewModal, setShowImgPreviewModal] = useState({
    show: false,
    data: images,
    imageIndex: -1,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate image loading
    const fetchImages = async () => {
      setLoading(true);
      // Simulate a delay for image loading
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };
    fetchImages();
  }, [images]);

  const openImgPreviewModal = (index) => {
    setShowImgPreviewModal({
      show: true,
      data: images.map((e) => {
        return { id: e };
      }),
      imageIndex: index,
    });
  };

  const closeImgPreviewModal = () => {
    setShowImgPreviewModal({ show: false, data: [], imageIndex: -1 });
  };

  if (loading) {
    return (
      <>
        <Spinner />
      </>
    ); // Render the loader while images are being loaded
  }

  return (
    <>
      <AppCard>
        {images.length === 0 ? (
          <NoDataFound>Images Not Found</NoDataFound>
        ) : (
          <div className="row">
            {images.map((x, i) => (
              <div
                className="col-2 cursor-pointer"
                key={i}
                onClick={() => {
                  openImgPreviewModal(i);
                }}
              >
                <ImgRender assetId={x} />
              </div>
            ))}
          </div>
        )}
      </AppCard>

      <ImgPreviewModal
        assets={showImgPreviewModal.data}
        handleClose={closeImgPreviewModal}
        show={showImgPreviewModal.show}
      />
    </>
  );
};

export default Images;
