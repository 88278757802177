import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import {
  AuthService,
  CommonService,
  NavService,
  ProductService,
} from "@sk/services";
import {
  Alert,
  AppCard,
  AuditLogTable,
  DateFormatter,
  ImgPreviewModal,
  KeyVal,
  NoDataFound,
  PageInfo,
  PageLoader,
  Tabs,
  Toaster,
  Rbac,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  OrderManagement,
  Procurement,
  ProductInformation,
  StockManagement,
} from "./Tabs";
import MoreAboutProduct from "./Tabs/MoreAboutProduct/MoreAboutProduct";
import ProductBasic from "./components/basic/ProductBasic";

// To Attach Additional Config
// masterProductId
const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "masterProductId",
    api: "product",
    loadingKey: "masterProductLoading",
    dataKey: "_masterProduct",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedLoading",
    dataKey: "_lastUpdatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "category",
    api: "category",
    loadingKey: "categoryLoading",
    dataKey: "_category",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "brand",
    api: "brand",
    loadingKey: "brandLoading",
    dataKey: "_brand",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const rbac = {
  editProduct: ["EditProduct"],
  procurementTab: ["ProductProcurementTab"],
};

// BreadCrumb Config
const breadcrumbs = [
  {
    name: "Manage Products",
    link: "/cms/product/list",
  },
  {
    name: "Product Details",
  },
];

// These are Feature
const tabs = [
  { key: "stockManagement", tabName: "Stock Management" },
  { key: "procurement", tabName: "Procurement" },
  { key: "orders", tabName: "Orders Management" },
  { key: "productInfo", tabName: "Product Information" },
  { key: "moreAboutProduct", tabName: "More About Product" },
  { key: "auditLog", tabName: "Audit Log" },
].filter((tab) => {
  if (tab.key === "procurement") {
    return AuthService.isValidUserRole(rbac.procurementTab);
  }
  return true;
});

const ViewProduct = () => {
  const [searchParams] = useSearchParams();

  const [display, setDisplay] = useState("loading");

  const [showImgPreviewModal, setShowImgPreviewModal] = useState(false);

  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  // to Store Complete Data
  const [data, setData] = useState({});

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [publishStatus, setPublishStatus] = useState({
    loading: false,
    status: "",
  });

  // to Fetch QueryString
  const query = useFetchUrlQueryString(searchParams);

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(() => {
    if (query.id) {
      loadProductDetails(query.id);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadProductDetails, query.id]);
  // get Product Details
  const loadProductDetails = useCallback(async () => {
    setDisplay("loading");
    const r = await ProductService.getProduct(query.id);
    const d = r.resp || {};
    if (d._id) {
      setDisplay("details");
    } else {
      setDisplay("noDataFound");
    }

    //  adding Loading Key for Attach additional Info
    d.userLoading = true;
    d.brandLoading = true;
    d.categoryLoading = true;
    d.masterProductLoading = true;
    let perc = (1 - d.ptrByBrands / d.mrp) * 100;
    d._ptrPrecentage = CommonService.roundedByDecimalPlace(perc, 2);

    setData(d);
    setPublishStatus({
      loading: false,
      status: d.is_active ? "Active" : "Inactive",
    });

    if (d._id) {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setData((v) => ({ ...v, ...t }));
        }
      });
    }
  }, [attachAllData, query.id, setAdditionalData]);

  // Tab Callback to change Tab
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  const editProduct = () => {
    NavService.openInNewTab("/cms/product/manage", { id: query.id });
  };

  const openImgPreviewModal = () => {
    setShowImgPreviewModal(true);
  };

  const closeImgPreviewModal = () => {
    setShowImgPreviewModal(false);
  };

  const images = (data.images || []).map((x) => ({ id: x }));

  const viewCategory = () => {
    NavService.openInNewTab("/cms/category/view", {
      id: data._category[0]._id,
    });
  };

  const viewBrand = () => {
    NavService.openInNewTab("/cms/brand/view", {
      id: data.brand[0]._id,
    });
  };

  const viewProduct = () => {
    NavService.openInNewTab("/cms/product/view", {
      id: data.masterProductId,
    });
  };

  const changePublishStatus = async () => {
    let text =
      publishStatus.status == "Active"
        ? "Do you want to Inactive the product"
        : "Do you want to Active the product";

    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: text,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    let p = {
      is_active: publishStatus.status == "Active" ? false : true,
      _id: data._id,
    };

    setPublishStatus({ loading: true, status: publishStatus.status });

    let d = await ProductService.update(data._id, p);

    if (d?.resp?._id) {
      setPublishStatus({
        loading: false,
        status: publishStatus.status == "Active" ? "Inactive" : "Active",
      });
      Toaster.success("Status update Successfully");
    } else {
      Toaster.error("Sorry, Unable to update Status");
      setPublishStatus({
        loading: false,
        status: publishStatus.status,
      });
    }
  };

  const expiryDateCb = useCallback((d) => {
    if (d.status == "submit") {
      init();
    }
  }, []);

  return (
    <>
      <PageInfo
        title="Product Details"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {display == "loading" && <PageLoader />}
      {display == "noDataFound" && <NoDataFound> No Data Found</NoDataFound>}
      {display == "details" && (
        <>
          <ProductBasic data={data} />

          <div className="mb-3">
            <Tabs
              data={tabs}
              activeTab={activeTab}
              callback={tabCb}
              template={4}
              shadow={true}
            />
          </div>
          {/* Tabs */}

          {activeTab.key == "productInfo" && <ProductInformation data={data} />}
          {activeTab.key == "stockManagement" && (
            <StockManagement data={data} expiryDateCb={expiryDateCb} />
          )}

          {activeTab.key == "orders" && <OrderManagement data={data} />}

          {activeTab.key == "procurement" && (
            <Rbac roles={rbac.procurementTab}>
              <Procurement data={data} />
            </Rbac>
          )}

          {activeTab.key == "auditLog" && (
            <>
              <AppCard>
                <div className="row g-2 fs-val-md">
                  <div className="col">
                    <KeyVal
                      label="Created By"
                      labelCol="col-auto"
                      contentCol="col"
                      noBottomSpace={true}
                    >
                      : {data._createdBy?.name || "N/A"}
                    </KeyVal>
                  </div>
                  <div className="col">
                    <KeyVal
                      label="Created On"
                      labelCol="col-auto"
                      contentCol="col"
                      noBottomSpace={true}
                    >
                      : <DateFormatter date={data.createdAt} />
                    </KeyVal>
                  </div>
                  {data.lastUpdated ? (
                    <>
                      <div className="col">
                        <KeyVal
                          label="Updated By"
                          labelCol="col-auto"
                          contentCol="col"
                          noBottomSpace={true}
                        >
                          : {data._lastUpdatedBy?.name || "N/A"}
                        </KeyVal>
                      </div>
                      <div className="col">
                        <KeyVal
                          label="Updated On"
                          labelCol="col-auto"
                          contentCol="col"
                          noBottomSpace={true}
                        >
                          : <DateFormatter date={data.lastUpdated} />
                        </KeyVal>
                      </div>
                    </>
                  ) : null}
                </div>
              </AppCard>
              <AuditLogTable entityType="Product" entityId={data._id} />
            </>
          )}

          {activeTab.key == "moreAboutProduct" && (
            <MoreAboutProduct data={data} />
          )}

          <ImgPreviewModal
            assets={images}
            handleClose={closeImgPreviewModal}
            show={showImgPreviewModal}
          />
        </>
      )}
    </>
  );
};

export default ViewProduct;
