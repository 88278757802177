import { DealService } from "@sk/services";
import { Amount, AppCard, KeyVal, PageLoader, Tabs } from "@sk/uis";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import FixedComboProductTable from "../manage-modal/components/FixedComboProductTable";

const canvasStyle = {
  width: "90%",
};

const tabs = [
  { tabName: "Products", key: "products" },
  { tabName: "Orders", key: "orders" },
];

const FixedComboViewModal = ({ show = false, callback, configId }) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (show && configId) {
      const fetchData = async () => {
        setLoading(true);
        const res = await DealService.getFixedCombo({
          filter: {
            _id: configId,
          },
        });
        if (res.statusCode === 200) {
          const d = res.resp?.[0] || {};

          d.cartDetails = d.cartDetails.map((c) => ({
            ...c,
            category: {
              ...c.category,
              products: c.dealsList.map((d) => ({
                ...d,
                _id: d.dealId,
                comboQty: d.qty,
              })),
            },
          }));

          console.log(d);

          setDetails(d);
        } else {
          setDetails({});
        }
        setLoading(false);
      };
      fetchData();
    }
  }, [show, configId]);

  const closeModal = () => {
    callback({ action: "close" });
  };

  const onTabChange = ({ value }) => {
    setActiveTab(value);
  };

  return (
    <>
      <Offcanvas
        show={show}
        placement="end"
        onHide={closeModal}
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          <Offcanvas.Title>
            <div className="fs-val-lg">Fixed Combo Details - {details._id}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {loading ? (
            <PageLoader />
          ) : (
            <>
              <AppCard>
                <div className="row">
                  <div className="col">
                    <KeyVal label="Store" template="col">
                      {details.franchiseName}
                    </KeyVal>
                  </div>
                  <div className="col">
                    <KeyVal label="Title" template="col">
                      {details.title}
                    </KeyVal>
                  </div>
                  <div className="col-2">
                    <KeyVal label="Combo Price (₹)" template="col">
                      <Amount value={details.offerAmount} />
                    </KeyVal>
                  </div>
                </div>
              </AppCard>

              <div className="mb-3">
                <Tabs
                  template={4}
                  data={tabs}
                  activeTab={activeTab}
                  callback={onTabChange}
                />
              </div>

              {details?.cartDetails?.map((category, index) => (
                <AppCard
                  key={category?.category?.id}
                  title={
                    <div className="d-flex align-items-center">
                      <div className="card-title flex-grow-1">
                        {index + 1}. {category?.category?.name} (
                        {category?.category?.id})
                      </div>
                    </div>
                  }
                >
                  <FixedComboProductTable
                    products={category?.category?.products}
                    categoryId={category?.category?.id}
                    categoryName={category?.category?.name}
                    storeId={details.franchiseId}
                    configId={details._id}
                    mode="view"
                  />
                </AppCard>
              ))}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FixedComboViewModal;
