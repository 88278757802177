import {
  Amount,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo, useCallback, useRef, useState } from "react";
import ViewBarcodeModal from "../modal/ViewBarcodeModal";

const headers = [
  { label: "Sl.No." },
  { label: "location" },
  { label: "Area" },
  { label: "Rack" },
  { label: "Bin" },
  { label: "IMEI/Serial No", isCentered: true },
  { label: "MRP" },
  { label: "GOOD" },
  { label: "Quantity" },
  { label: "onHold" },
  { label: "Damage" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const [showViewBarcodeModal, setShowViewBarcodeModal] = useState(false);

  const viewBarcodeModalRef = useRef({ data: "" });

  const openViewBarcodeModal = useCallback((data) => {
    viewBarcodeModalRef.current.data = data;
    setShowViewBarcodeModal(true);
  }, []);

  const closeViewBarcodeModal = useCallback(() => {
    viewBarcodeModalRef.current.data = "s";
    setShowViewBarcodeModal(false);
  }, []);

  return (
    <>
      <table className="table table-bordered">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When There is No Data */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/* When There is Data */}
          {!loading &&
            data.map((x, i) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + i}</td>
                <td>{x.area}</td>
                <td>{x.area}</td>
                <td>{x.rackId}</td>
                <td>{x.binId}</td>
                <td>
                  <button
                    className="btn btn-link fs-val-md"
                    onClick={() => openViewBarcodeModal(x.barcodes)}
                  >
                    View Barcode
                  </button>
                </td>
                <td>
                  <Amount value={x.mrp} decimalPlace={2} />
                </td>
                <td>
                  <HighlightText
                    status={x.isGoodStock ? "YES" : "NO"}
                    isBadge={false}
                  />
                </td>
                <td>{x.quantity}</td>
                <td>{x.onHold}</td>
                <td>{x.damaged ?? 0}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <ViewBarcodeModal
        closeModal={closeViewBarcodeModal}
        show={showViewBarcodeModal}
        data={viewBarcodeModalRef.current.data}
      />
    </>
  );
};

export default memo(Table);
