import { AppliedFilterLabel, PageInfo, TextInput } from "@sk/uis";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import OutOfStockRequestTable from "./list/components/OutOfStockRequestTable";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { CommonService, DealService } from "@sk/services";
import OutOfStockAdvanceFilterModal from "./list/modals/OutOfStockAdvanceFilterModal";
import { set } from "date-fns";

const breadcrumb = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Out of Stock Request List ",
  },
];

const formLabels = {
  deal: {
    label: "Deal",
    valuePath: "[0].value.name",
  },
  franchise: {
    label: "Store",
    valuePath: "[0].value.name",
  },
  customer: {
    label: "Customer",
    valuePath: "[0].value.fName",
  },
  createdAt: {
    label: "Date",
    type: "dateRange",
  },
};

const defaultSortOpt = { key: "createdAt", value: "desc" };

const getData = async (params) => {
  const r = await DealService.getOutofStockRequestList(params);
  const d = Array.isArray(r.resp) ? r.resp : [];
  return {
    data: d,
  };
};

const getCount = async (params) => {
  try {
    const r = await DealService.getOutofStockRequestListCount(params);
    return { count: r.resp || 0 };
  } catch (error) {
    return { count: 0 };
  }
};

const prepareFilterParams = (filter = {}, pagination = {}, sort = {}) => {
  const { activePage, rowsPerPage } = pagination;
  const { search = "", deal, franchise, customer, createdAt, status } = filter;

  let p = {
    page: activePage,
    count: rowsPerPage,
    filter: { $or: [] },
  };

  const searchTerm = search.trim();
  const dealId = deal?.[0]?.value?._id?.trim();
  const franchiseId = franchise?.[0]?.value?._id;
  const customerId = customer?.[0]?.value?._id;

  // Add search term filter
  if (searchTerm) {
    p.filter.$or.push({ _id: { $regex: searchTerm, $options: "gi" } });
  }

  // Add deal filter
  if (dealId) {
    p.filter.$or.push({ "deal.id": dealId });
  }

  // Add franchise filter
  if (franchiseId) {
    p.filter.$or.push({ "franchiseInfo.id": franchiseId });
  }

  // Add customer filter
  if (customerId) {
    p.filter.$or.push({ "customerInfo.id": customerId });
  }

  // Add createdAt filter
  if (createdAt?.length) {
    const [startDate, endDate] = createdAt;
    p.filter.createdAt = {
      $gte: set(new Date(startDate), { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(new Date(endDate), { hours: 23, minutes: 59, seconds: 59 }),
    };
  }

  // Add sort option
  if (sort?.key) {
    p.sort = sort.value === "desc" ? "-createdAt" : "createdAt";
  }

  // Add status filter
  if (status) {
    p.filter.status = status;
  }

  // Clean up empty or unnecessary properties
  if (!p.filter.$or.length) delete p.filter.$or;
  if (!Object.keys(p.filter).length) delete p.filter;

  return p;
};

const OutOfStockRequestList = () => {
  const { register, reset } = useForm({
    defaultValues: { search: "", status: "", id: "" },
  });

  const router = useNavigate();

  const [data, setData] = useState([]);

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [filterLabels, setFilterLabels] = useState([]);

  const sortRef = useRef({ ...defaultSortOpt });

  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  });

  const filterDataRef = useRef({
    search: "",
    status: "",
  });

  const advanceFilterRef = useRef({});

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);

    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );

    const d = r.data || [];

    setData(d);

    setLoadingData(false);
  }, []);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...filterDataRef.current };

    // const modify = { ...formLabels };
    // let temp = modify.date;
    // delete modify.date;
    // modify.createdAt = temp;

    const l = CommonService.prepareAppliedFilterLabels({ ...formLabels }, v);

    setFilterLabels(l);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current.activePage = 1;
    paginationRef.current.startSlNo = 1;

    loadList();
    // for total records

    prepareFilterLabels();

    setLoadingTotalRecords(true);

    const c = await getCount(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    const t = c.count;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList, prepareFilterLabels]);

  const onAdvanceFilterCb = useCallback(
    (r) => {
      const status = r.status;
      if (status == "reset") {
        filterDataRef.current.search = "";

        reset();
      }

      if (status == "applied" || status == "reset") {
        advanceFilterRef.current = { ...r.formData };
        filterDataRef.current = {
          ...filterDataRef.current,

          ...r.formData,
        };

        setShowAdvanceFilter(false);
        applyFilter();
      }
      if (status == "closed") {
        setShowAdvanceFilter(false);
      }
    },
    [applyFilter, reset]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchCb = useCallback(
    debounce((e) => {
      const v = e.target.value;
      filterDataRef.current.search = v;
      applyFilter();
    }, 800),
    []
  );
  const onAdvanceFilterClickCb = useCallback(() => {
    setShowAdvanceFilter(true);
  }, []);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <div className="row my-2 align-items-center">
        <div className="col">
          <PageInfo
            title="Out Of Stock Request List"
            breadcrumbs={breadcrumb}
            navigate={router}
          />
        </div>
      </div>
      <div className="row p-1 mt-3">
        <div className="col-3">
          <TextInput
            register={register}
            placeholder="Search by ID"
            isMandatory={false}
            name="id"
            callback={searchCb}
          />
        </div>
        <div className="col-auto">
          <button
            className="btn app-filter-btn"
            type="button"
            onClick={onAdvanceFilterClickCb}
          >
            <i className="bi bi-funnel"></i> FILTER
          </button>
        </div>
        {filterLabels.length > 0 ? (
          <div className="mb-3">
            <AppliedFilterLabel labels={filterLabels} />
          </div>
        ) : null}
      </div>

      <OutOfStockRequestTable
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sort={sortRef.current}
        sortCb={sortCb}
      />
      <OutOfStockAdvanceFilterModal
        callback={onAdvanceFilterCb}
        show={showAdvanceFilter}
        formData={filterDataRef.current}
      />
    </>
  );
};
export default OutOfStockRequestList;
