import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrandService } from "@sk/services"; // Assuming you need to fetch brands similarly
import { debounce } from "lodash";
import { Spinner } from "@sk/uis";
import overviewStyles from "../CmsOverview.module.scss";

const CmsOverviewBrands = ({ callback }) => {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [records, setRecords] = useState({
    loading: true,
    value: 0,
  });

  const [search, setSearch] = useState("");
  const [filterStatus, setFilterStatus] = useState("Active");

  const filterRef = useRef({
    status: "Active",
  });
  const paginationRef = useRef({
    page: 1,
    count: 60,
  });

  const debouncedSearch = useCallback(
    debounce(() => {
      init();
    }, 500),
    []
  );

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    paginationRef.current = {
      ...paginationRef.current,
      page: 1,
    };

    setLoading(true);
    setRecords({
      loading: true,
      value: 0,
    });
    setBrands([]);

    const params = getFilterParams(filterRef.current, paginationRef.current);
    const data = await getData(params);
    const { count } = await getCount(params);
    setBrands(data);
    setLoading(false);
    setRecords({
      loading: false,
      value: count,
    });
  };

  const loadMore = async () => {
    paginationRef.current = {
      ...paginationRef.current,
      page: paginationRef.current.page + 1,
    };
    const params = getFilterParams(filterRef.current, paginationRef.current);
    const data = await getData(params);
    setBrands([...brands, ...data]);
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
    filterRef.current = {
      ...filterRef.current,
      search: e.target.value,
    };
    debouncedSearch();
  };

  const onFilterChange = (e) => {
    const selectedValue = e.target.value;
    setFilterStatus(selectedValue);
    filterRef.current = {
      ...filterRef.current,
      status: selectedValue === "all" ? undefined : selectedValue,
    };
    init();
  };

  return (
    <div className="p-2">
      <input
        placeholder="Search"
        className="form-control mb-2 form-control-sm"
        value={search}
        onChange={onSearch}
      />
      <div className="fs-val-sm mb-1 text-muted d-flex justify-content-between align-items-center">
        <span>
          Showing <span className="text-dark">{records.value}</span> records
        </span>
        <select
          className={overviewStyles["select-custom"]}
          onChange={onFilterChange}
          value={filterStatus}
        >
          <option value="all">All</option>
          <option value="Active">Only Active</option>
          <option value="Inactive">Only Inactive</option>
        </select>
      </div>

      <div className="custom-scrollbar thin-scrollbar" style={styles.container}>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : null}

        {brands.length > 0 ? (
          <>
            {brands.map((brand) => (
              <div
                key={brand._id}
                className="fs-val-sm py-1 border-bottom cursor-pointer d-flex align-items-center"
                onClick={() => callback({ action: "select", value: brand })}
              >
                <i
                  className={`bi bi-circle-fill fs-val-xs me-2 ${
                    brand.status === "Active" ? "text-success" : "text-danger"
                  }`}
                ></i>
                <div className="flex-grow-1">{brand.name}</div>
                <i className="bi bi-chevron-right fs-val-xs ms-2 me-1"></i>
              </div>
            ))}
            {records.value >= paginationRef.current.count ? (
              <div className="text-center">
                <button className="btn btn-sm btn-light" onClick={loadMore}>
                  Load More
                </button>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: "calc(100vh - 200px)",
    overflowY: "auto",
  },
};

const getData = async (params) => {
  const response = await BrandService.getList(params);
  return response.resp;
};

const getCount = async (params) => {
  const p = { ...params };
  delete p.page;
  delete p.count;
  delete p.sort;
  const response = await BrandService.getCount(p);
  return { count: response.resp };
};

const getFilterParams = (filter, pagination) => {
  const params = {
    page: pagination.page,
    count: pagination.count,
    sort: "name",
    filter: {},
    select: "name,status",
  };

  if (filter.search) {
    params.filter.name = { $regex: filter.search, $options: "i" };
  }

  if (filter.status) {
    params.filter.status = filter.status;
  }

  if (Object.keys(params.filter).length === 0) {
    delete params.filter;
  }

  return params;
};

export default CmsOverviewBrands;
