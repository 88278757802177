import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";
import notLinkedImg from "../../assets/imgs/not-linked.svg";

const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Manage Products",
    },
  ],
  // Breadcrumbs Ends Here
  summaryData: [
    {
      label: "Total Products",
      value: 0,
      key: "overall",
      loading: true,
      filter: {},
      filterParams: {},
      defaultFilterParams: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Active Products",
      value: 0,
      loading: true,
      filter: {},
      filterParams: {},
      defaultFilterParams: { is_active: true },
      color: "success",
      img: activeImg,
    },
    {
      label: "Inactive Products",
      value: 0,
      loading: true,
      filter: { status: "Inactive" },
      color: "danger",
      defaultFilterParams: { is_active: false },
      filterParams: {},
      img: inactiveImg,
    },
    {
      label: "Reserve Order Products",
      value: 0,
      loading: true,
      filter: {},
      defaultFilterParams: { allowReserveOrder: true },
      filterParams: {},
      color: "warning",
      img: notLinkedImg,
    },
  ],
  //   Summary Card Data Ends Here
  additionalTableDataConfig: [
    {
      key: "brand",
      api: "brand",
      loadingKey: "brandLoading",
      dataKey: "_brand",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "category",
      api: "category",
      loadingKey: "categoryLoading",
      dataKey: "_category",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  //   Additional Table Data Config

  pagination: {
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  },
  // Pagination Ends Here

  formLabels: {
    product: {
      label: "Product",
      valuePath: "[0].value.name",
    },
    barcode: {
      label: "Barcode",
    },

    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },
    brand: {
      label: "Brand",
      valuePath: "[0].value.name",
    },
    classification: {
      label: "Franchise",
      valuePath: "[0].value.name",
    },
    classGroup: {
      label: "Deal",
      valuePath: "[0].value.name",
    },
    is_active: {
      label: "Status",
    },
    allowReserveOrder: {
      label: "Allow Pre-Order",
    },
    min: {
      label: "MRP Min",
    },
    max: {
      label: "MRP Max",
    },
    delivery_chalan: {
      label: "Delivery Chalan",
    },
    permanently_deactivate: {
      label: "Permanently Decativate",
    },
    classClassifications: {
      label: "Class Classification",
      valuePath: "[0].value.name",
    },

    menu: {
      label: "Menu",
      valuePath: "[0].value.name",
    },
  },
  //   Form Labels Ends Here
  filterFormData: {
    barcode: "",
    product: "",
    brand: [],
    category: [],
    classClassifications: [],
    is_active: "",
    delivery_chalan: "",
    allowReserveOrder: "",
    permanently_deactivate: "",
    min: "",
    max: "",
  },
  moreTableColumns: [
    // {
    //   label: "SKU Code",
    //   width: "8%",
    //   priority: 7,
    //   key: "skuCode",
    //   checked: false,
    // },
    // {
    //   label: "HSN & Tax ",
    //   width: "8%",
    //   priority: 7,
    //   key: "hsn",
    //   checked: true,
    // },
    {
      label: "Created On",
      key: "createdAt",
      checked: false,
      enableSort: true,
      width: "14%",
      priority: 12,
    },
  ],
};

export const detailsView = {
  tabs: {
    linkedDeals: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      formLabels: {
        deal: {
          label: "Deal",
          valuePath: "[0].value.name",
        },
        status: {
          label: "Status",
        },
        is_active: {
          label: "Active",
        },
      },
      // Form Label Ends Here
      filterFormData: {
        deal: "",
        status: "",
        is_active: "",
      },
    },
    // Linked Deal Ends Here
    purchaseOrder: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination ends Here
      formLabels: {
        po: {
          label: "Po ID",
        },
        warehouse: {
          label: "Warehouse",
        },
      },
      // Form Label Ends Here
      additionalTableDataConfig: [
        {
          key: "createdBy",
          api: "user",
          loadingKey: "userLoading",
          dataKey: "_createdBy",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // Additional Table Data Ends Here
    },
    // Purchase Order Ends Here
    grn: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends here
      formLabels: {
        vendor: {
          label: "vendor",
          valuePath: "[0].value.name",
        },
        warehouse: {
          warehouse: "",
        },
        createdAt: {
          label: "Created on",
          type: "dateRange",
        },
        grnId: {
          label: "Grn Id",
        },
      },
      // FormLabels
      additionalTableDataConfig: [
        {
          key: "vendorId",
          api: "vendor",
          loadingKey: "vendorLoading",
          dataKey: "_vendor",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // Ends Here
    },
    // GRN End Here
    orders: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      formLabels: {
        status: {
          label: "Status",
        },
        warehouse: {
          warehouse: "",
        },
        createdAt: {
          label: "Created on",
          type: "dateRange",
        },
        orderId: {
          label: "OrderId",
        },
      },
      // Form Label Config
      additionalTableDataConfig: [
        {
          key: "createdBy",
          api: "user",
          loadingKey: "userLoading",
          dataKey: "_createdBy",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
    },
    // Orders Ends Here
    reserveOrderConfig: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },

      formLabels: {
        status: {
          label: "Status",
        },
        warehouse: {
          warehouse: "",
        },
        createdAt: {
          label: "Created on",
          type: "dateRange",
        },
        orderId: {
          label: "OrderId",
        },
      },
      // Form Label Config Ends Here
      additionalTableDataConfig: [
        {
          key: "createdBy",
          api: "user",
          loadingKey: "userLoading",
          dataKey: "_createdBy",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
        {
          key: "whId",
          api: "franchise",
          loadingKey: "warehouseLoading",
          dataKey: "_warehouse",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // Additional Table Config Ends Here
    },
    // Reserve Order Config Ends Here
    reserveOrderQty: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      additionalTableDataConfig: [
        {
          key: "whId",
          api: "franchise",
          loadingKey: "warehouseLoading",
          dataKey: "_warehouse",
          valuePath: "username",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // additional data Ends here
    },
    // Reserve Order Qty Ends Here
    stockLedger: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      formLabels: {
        createdAt: {
          label: "Created At",
          type: "dateRange",
        },
        warehouse: {
          label: "Warehouse",
        },
      },
      //  Form labels Ends Here
      additionalTableDataConfig: [
        {
          key: "whId",
          api: "franchise",
          loadingKey: "warehouseLoading",
          dataKey: "_warehouse",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
        {
          key: "createdBy",
          api: "user",
          loadingKey: "userLoading",
          dataKey: "_createdBy",
          matchKey: "username",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { username: { $in: ids } },
            // select: "name",
          }),
        },
      ],
      //  Additional Table Data Ends Here
    },
    //  StocK Ledger Ends Here
    vendors: {
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      formLabels: {
        vendor: {
          label: "vendor",
          valuePath: "[0].value.name",
        },
        warehouse: {
          label: "Warehouse",
        },
      },
      //  Form Labels Ends
    },
    // Vendors Ends Here
  },
};

export const manageView = {
  infoContext: {
    productName: "Product Name",
    brand: "Brand",
    category: "category",
    shortDescription: "shortDescription",
    highlightInput: "highlightInput",
    hsnNumber: "hsnNumber",
    dealerPrice: "dealerPrice",
    fullDescription: "fullDescription",
    originCountry: "originCountry",
    skuCode: "skuCode",
    mrp: "mrp",
    ptrByBrands: "ptrByBrands",
    gstTax: "gstTax",
    additionalCessType: "additionalCessType",
    additionalCessValue: "additionalCessValue",
    tierName: "tierName",
    weight: "weight",
    caseQty: "caseQty",
    innerPackQty: "innerPackQty",
    packagingType: "packagingType",
    brandKVIStatus: "brandKVIStatus",
    shelfLife: "shelfLife",
    uom: "UOM",
    length: "length",
    breadth: "breadth",
    height: "height",
    keyFeatureInput: "keyFeatureInput",
  },
};
export default listView;
