import { BooleanCheckboxInput, EntitySearchInput, RadioInput } from "@sk/uis";
import { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import listView from "../../constantService";

const defaultFormData = listView.filterFromData;

const yesNoOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const canvasStyle = { width: "30%" };

const masterBrandInputFilter = { filter: { "masterData.isMaster": true } };

const AdvanceFilterModal = ({ show, callback, formData = {} }) => {
  // React Hook Form To Handle Advance Filter Label
  const { register, handleSubmit, reset, control, setValue } = useForm({
    defaultValues: defaultFormData,
  });

  useEffect(() => {
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset(defaultFormData);
      }
    }
  }, [show, setValue, formData, reset]);

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset(defaultFormData);
    callback({ status: "reset", formData: defaultFormData });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row">
              {/* Status */}
              <div className="-12">
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioInput
                      label="Status"
                      register={register}
                      value={value}
                      onChange={onChange}
                      cols={4}
                      name="status"
                      isMandatory={false}
                      options={statusOptions}
                    />
                  )}
                />
              </div>
              <div className="col-12 mb-3">
                {/* IS Value Brand */}
                <Controller
                  name="isValueBrand"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioInput
                      label="Is Value Brand "
                      register={register}
                      value={value}
                      onChange={onChange}
                      name="isValueBrand"
                      isMandatory={false}
                      isMultiple={false}
                      cols={4}
                      options={yesNoOptions}
                    />
                  )}
                />
              </div>

              <div className="col-12 mb-3">
                {/* Is Master Brand  */}
                <Controller
                  name="isMasterBrand"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioInput
                      label="Is Master Brand "
                      register={register}
                      value={value}
                      onChange={onChange}
                      name="isMasterBrand"
                      isMandatory={false}
                      cols={4}
                      isMultiple={false}
                      options={yesNoOptions}
                    />
                  )}
                />
              </div>

              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="masterBrand"
                  key="brand"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type="brand"
                      label="Search for Master Brand"
                      name="masterBrand"
                      placeholder="Search by  Id/Name"
                      value={value}
                      isMandatory={true}
                      callback={onChange}
                      uid="deal-brand"
                      emptyLabel="No Brand Found"
                      filterParams={masterBrandInputFilter}
                    />
                  )}
                />
              </div>
              <div className="col-12 mb-3">
                <Controller
                  control={control}
                  name="hideUnlinkedBrands"
                  render={({ field: { onChange, value } }) => (
                    <BooleanCheckboxInput
                      label="Show Only Not Linked Products?"
                      name="hideUnlinkedBrands"
                      isMandatory={false}
                      callback={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            </div>
            {/*  Action Button */}
            <div className="text-end w-100 mt-4">
              <button
                className="btn me-1 btn-warning btn-sm"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AdvanceFilterModal;
