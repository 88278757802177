import { NavService } from "@sk/services";
import { KeyVal, NoDataFound, TableHeader } from "@sk/uis";
import { sortBy } from "lodash";
import { memo, useMemo } from "react";
import { Modal } from "react-bootstrap";

const headers = [
  { label: "Sl.No" },
  { label: "Product Name" },
  { label: "ID", isCentered: true },
];

const style = {
  modalHeaderStyle: {
    backgroundColor: "#e4edff",
  },
};

const ViewLinkedProductModal = ({ show, closeModal, modalData = {} }) => {
  const productsList = useMemo(() => {
    return sortBy(modalData.product, ["name"]) ?? [];
  }, [modalData?.product]);

  // navigate To View Product Page
  const viewProduct = (id) => {
    NavService.openInNewTab("/cms/product/view", { id });
  };

  return (
    <Modal
      onHide={closeModal}
      size="lg"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={style.modalHeaderStyle}
      >
        Linked Product
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row p-2 border-bottom">
          <div className="col-9">
            <KeyVal template="col" label="Deal Name">
              <span className="fs-val-normal">{modalData.dealName}</span>
            </KeyVal>
          </div>
          <div className="col-3">
            <KeyVal template="col" label="Deal ID">
              {modalData.id}
            </KeyVal>
          </div>
        </div>
        <div className="fs-val-md my-1 px-2"> Product Linked to Deal </div>
        <table className="table table-bordered">
          <TableHeader data={headers} />
          <tbody>
            {!modalData?.product?.length ? (
              <NoDataFound>No Product Linked Found</NoDataFound>
            ) : (
              productsList.map((rowData, i) => (
                <tr key={i} className="fs-val-md">
                  <td>{i + 1}</td>
                  <td>
                    <span
                      role="button"
                      tabIndex={-1}
                      onClick={() => viewProduct(rowData.id)}
                      className="cursor-pointer"
                    >
                      {rowData.name}
                    </span>
                  </td>
                  <td>
                    <span
                      className="text-center cursor-pointer"
                      role="button"
                      tabIndex={-1}
                      onClick={() => viewProduct(rowData.id)}
                    >
                      {rowData.id}
                    </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ViewLinkedProductModal);
