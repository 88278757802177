import { AppCard, EntitySearchInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import StoreComboAdvanceFilterModal from "../modals/StoreComboAdvanceFilterModal";

const defaultFormData = {
  search: "",
  franchise: [],
  deal: [],
};

const franFilter = {
  filter: {
    groupType: "COCO",
  },
};

const dealFilter = {
  // filter: {
  //   dealType: "Combo",
  // },
  select: "name",
};

function StoreComboFilter({ callback }) {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const [advFilterModal, setAdvFilterModal] = useState({ show: false });

  const onSearch = useCallback(
    debounce(() => {
      triggerCb();
    }, 800),
    []
  );

  const advFilterCb = (e) => {
    setAdvFilterModal({ show: false });
    callback(e);
  };

  const onFranChange = (e) => {
    setValue("franchise", e);
    triggerCb();
  };

  const onDealChange = (e) => {
    setValue("deal", e);
    triggerCb();
  };

  const triggerCb = () => {
    callback({ action: "apply", formData: getValues() });
  };

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-3">
            <TextInput
              label="Search by ID"
              placeholder="Search..."
              register={register}
              name="search"
              callback={onSearch}
            />
          </div>
          <div className="col-3">
            <Controller
              name="franchise"
              control={control}
              render={({ field: { value } }) => (
                <EntitySearchInput
                  callback={onFranChange}
                  value={value}
                  label="Search by Store"
                  type="franchise"
                  uid="sr-fran"
                  filterParams={franFilter}
                />
              )}
            />
          </div>
          <div className="col-3">
            <Controller
              name="deal"
              control={control}
              render={({ field: { value } }) => (
                <EntitySearchInput
                  callback={onDealChange}
                  value={value}
                  label="Search by Deal"
                  type="deal"
                  uid="sr-deal"
                  filterParams={dealFilter}
                />
              )}
            />
          </div>
          <div className="col-auto align-self-end">
            <button
              className="btn app-filter-btn mb-3"
              type="button"
              onClick={() => setAdvFilterModal({ show: true })}
            >
              <i className="bi bi-funnel"></i> FILTER
            </button>
          </div>
        </div>
      </AppCard>

      <StoreComboAdvanceFilterModal
        show={advFilterModal.show}
        callback={advFilterCb}
      />
    </>
  );
}

export default StoreComboFilter;
