import { AuthService, CommonService, WarehouseService } from "@sk/services";
import { PaginationSummary, TableColumnBtn } from "@sk/uis";
import { set } from "date-fns";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const defaultFormData = {
  status: "",
  barcode: "",
  grn: "",
  createdAt: [],
  whId: AuthService.getDefaultWhId(),
};

const defaultSortOpt = {};

const moreTableColumns = [
  { label: "Offer", key: "offer", checked: false, priority: 6 },
  {
    label: "Reference Type",
    key: "referenceType",
    checked: false,
    priority: 7,
  },
  { label: "Ref ID", key: "refId", checked: false, priority: 7.5 },
  { label: "Created On", key: "createdOn", checked: false, priority: 8 },
  {
    label: "Manufacture Date",
    key: "manufactureDate",
    checked: false,
    priority: 9,
  },
  { label: "Expiry", key: "expiryDate", checked: false, priority: 10 },
  { label: "Rack", key: "rack", checked: false, priority: 13 },
  { label: "Bin", key: "bin", checked: false, priority: 14 },
];

const getCount = async (id, params) => {
  delete params.count;
  delete params.page;

  try {
    const r = await WarehouseService.getDocumentWiseListCount(id, params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Prepare Filter Prams
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };

  if (filter.status) {
    p.filter.status = filter.status;
  }
  const barcode = filter.barcode.trim();
  const grn = filter.grn.trim();

  if (barcode || grn) {
    p.filter.$or = [{ barcode }, { ["ref.grn"]: grn }];
  }

  if (filter.whId) {
    p.filter.whId = filter.whId;
  }

  if (filter?.createdAt?.length > 1) {
    let s = filter.createdAt[0];
    let e = filter.createdAt[1];
    p.filter.createdAt = {
      $gte: set(s, { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(e, {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  return p;
};

const formLabelsConfig = [
  {
    barcode: {
      label: "Barcode",
    },

    grn: {
      label: "GRN",
    },

    WhId: {
      label: "Barcode",
    },

    createdAt: {
      label: "Created At",
      type: "dateRange  ",
    },
  },
];

// To Get Data
const getData = async (id, params) => {
  const r = await WarehouseService.getDocumentWiseList(id, params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const DocumentWise = ({ pId, expiryModalCb }) => {
  // It Will have all List Data
  const [data, setData] = useState([]);

  // To Store Pagination State
  const paginationRef = useRef({ ...defaultPageOpt });

  // To store Sorting Data
  const sortRef = useRef({ ...defaultSortOpt });

  // To Store Filter Data Ref
  const filterDataRef = useRef({ ...defaultFormData });

  // To Store Applied Filter Information
  const [filterLabels, setFilterLabels] = useState([]);

  // Loading State While Fetching List
  const [loadingData, setLoadingData] = useState(true);

  // Loading State While Fetching count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // To select More Columns
  const [selectedMoreTblColumns, setSelectedMoreTblColumns] = useState(() => {
    let t = {};
    moreTableColumns
      .filter((x) => x.checked)
      .forEach((x) => {
        t[x.key] = x;
      });
    return t;
  });

  useEffect(() => {
    if (pId) {
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  //  To Get Filter Params Which We are Preparing
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  // To load the list
  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(pId, params);
    d.forEach((v) => {
      if (v.ref.grn) {
        v.ref._grn = v.ref.grn.substring(0, 3);
      }
      if (v.ref.correction) {
        v.ref._correction = v.ref.correction.substring(0, 7);
      }
      if (v.ref.movement) {
        v.ref._movement = v.ref.movement.substring(0, 4);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [getFilterParams, pId]);

  //  To Apply Filter according to Which Filter We Will Select
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };

    loadList();
    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();

    const c = await getCount(pId, p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, pId, loadList]);

  //  To Change Page And Load List
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // To Get Filter Form Data and Apply Filter
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = data.formData;
      applyFilter();
    },
    [applyFilter]
  );

  // To Sort the Table
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // To Prepare Filter Labels according to Selected Filter
  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelsConfig,
      filterDataRef.current || {}
    );
    setFilterLabels(l);
  };

  // To apply More Columns on Table
  const onMoreTableColApply = useCallback((d) => {
    let t = {};
    (d.selected || []).forEach((x) => {
      t[x.key] = x;
    });
    setSelectedMoreTblColumns({ ...t });
  }, []);

  const expiryDateCb = useCallback((d) => {
    if (d?.status == "submit") {
      expiryModalCb(d);
    }
  }, []);
  return (
    <>
      {/* Filter */}

      {/* Table  */}
      <div className="text-end my-2">
        <TableColumnBtn
          columns={moreTableColumns}
          callback={onMoreTableColApply}
        />
      </div>

      <Filter callback={filterFormCb} id={pId} />

      {/* Applied Filter label */}

      {/* {filterLabels.length > 0 ? (
        <div className="mb-2">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null} */}

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      {/* Table  */}
      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sort={sortRef.current}
        sortCb={sortCb}
        moreColumns={selectedMoreTblColumns}
        expiryDateCb={expiryDateCb}
      />
    </>
  );
};

export default memo(DocumentWise);
