import { CommonService, DealService } from "@sk/services";
import { PaginationSummary } from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { detailsView } from "../../../constantService";
import Filter from "./components/Filter";
import Table from "./components/Table";
import { AppCard } from "@sk/uis";

const defaultPageOpt = detailsView.tabs.linkedDeals.pagination;

const formLabelsConfig = detailsView.tabs.linkedDeals.formLabels;

const defaultFormData = detailsView.tabs.linkedDeals.filterFormData;

const defaultSortOpt = { key: "mrp", value: "desc" };

const getCount = async (params) => {
  delete params.count;
  delete params.page;

  try {
    const r = await DealService.getDealsCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Prepare Filter Prams
const prepareFilterParams = (
  pagination = {},
  filter = {},
  sort = {},
  additionalData = {}
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      product: { $elemMatch: { id: additionalData.id } },
    },
  };

  if (filter.status) {
    p.filter.status = filter.status;
  }
  if (filter.is_active) {
    p.filter.is_active = filter.is_active;
  }
  const deal = filter?.deal?.trim();

  const dealRegex = { $regex: deal, $options: "gi" };

  if (deal) {
    p.filter.$or = [{ _id: dealRegex }, { name: dealRegex }];
  }

  if (sort.key) {
    p.sort = sort.value == "desc" ? "-" + [sort.key] : [sort.key];
  }
  return p;
};

// To Get Deal Data
const getData = async (params) => {
  const r = await DealService.getDeals(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const Deal = ({ pId }) => {
  // It Will have all List Data
  const [data, setData] = useState([]);

  // To Store Pagination State
  const paginationRef = useRef({ ...defaultPageOpt });

  // To store Sorting Data
  const sortRef = useRef({ ...defaultSortOpt });

  // To Store Filter Data Ref
  const filterDataRef = useRef({ ...defaultFormData });

  // To Store Applied Filter Information
  const [filterLabels, setFilterLabels] = useState([]);

  // Loading State While Fetching List
  const [loadingData, setLoadingData] = useState(true);

  // Loading State While Fetching count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  useEffect(() => {
    if (pId) {
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  //  To Get Filter Params Which We are Preparing
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      {
        id: pId,
      }
    );
  }, [pId]);

  // To load the list
  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(params);
    setData(d);
    setLoadingData(false);
  }, [getFilterParams]);

  //  To Apply Filter according to Which Filter We Will Select
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };

    loadList();
    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();

    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  //  To Change Page And Load List
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // To Get Filter Form Data and Apply Filter
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = data.formData;
      applyFilter();
    },
    [applyFilter]
  );

  // To Sort the Table
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  // To Prepare Filter Labels according to Selected Filter
  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelsConfig,
      filterDataRef.current || {}
    );
    setFilterLabels(l);
  };

  return (
    <>
      {/* Filter */}
      <AppCard>
        <Filter callback={filterFormCb} id={pId} />
      </AppCard>

      {/* Applied Filter label */}

      {/* {filterLabels.length > 0 ? (
        <div className="mb-2">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null} */}

      {/* PAGINATION SUMMARY */}
      <AppCard>
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>

        {/* Table  */}

        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          sort={sortRef.current}
          sortCb={sortCb}
        />
      </AppCard>
    </>
  );
};

export default memo(Deal);
