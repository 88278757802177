import totalImg from "../../../../assets/imgs/total.svg";
import activeImg from "../../../../assets/imgs/active.svg";
import inactiveImg from "../../../../assets/imgs/inactive.svg";
import hasOffer from "../../../../assets/imgs/not-linked.svg";

const listView = {
  breadcrumbs: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "RSP-Config List",
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  //  Pagination Ends Here
  summaryCardData: [
    {
      label: "Total Count",
      key: "overall",
      filterParams: {},
      color: "primary",
      icon: "bi-check-all",
      img: totalImg,
    },
    {
      label: "Total Active",
      key: "active",
      color: "success",
      filterParams: {},
      defaultFilterParams: { status: "Active" },
      icon: "bi-check-circle",
      img: activeImg,
    },
    {
      label: "Total In-Active",
      key: "Inactive",
      color: "danger",
      defaultFilterParams: { status: "Inactive" },
      filterParams: {},
      icon: "bi-x-circle",
      img: inactiveImg,
    },
    {
      label: "Active Offers",
      key: "hasOffer",
      color: "warning",
      defaultFilterParams: {
        hasOffer: true,
      },
      filterParams: {},
      icon: "bi-percent",
      img: hasOffer,
    },
  ],
  // Summary Card Data ends Here
  formLabels: {
    createdAt: {
      label: "Created At",
      type: "dateRange",
    },
    hasOffer: {
      label: "Has Offer",
      type: "Boolean",
    },
    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },
    brand: {
      label: "Brand",
      valuePath: "[0].value.name",
    },
    franchise: {
      label: "Franchise",
      valuePath: "[0].value.name",
    },
    deal: {
      label: "Deal",
      valuePath: "[0].value.name",
    },
    validityPeriod: {
      label: "Offer Validity Period",
      type: "dateRange",
    },
    status: {
      label: "Status",
    },
    groupType: {
      label: "Group Type",
    },
    isFixedPrice: {
      label: "Is Fixed Price",
      type: "Boolean",
    },
  },
  //   Form Labels ends Here
  filterFormData: {
    createdAt: [],
    hasOffer: "",
    category: "",
    brand: "",
    franchise: [],
    deal: "",
    validityPeriod: [],
    groupType: "",
    status: "",
  },
};

export default listView;
