import {
  DateFormatter,
  KeyVal,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
} from "@sk/uis";
import React from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "ID" },
  { label: "Deal Name", width: "20%" },
  { label: "Deal ID" },
  { label: "Category" },
  { label: "Brand" },
  { label: "Customer" },
  { label: "Store" },
  { label: "Requested on", width: "13%", enableSort: true, key: "createdAt" },
];

const OutOfStockRequestTable = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  return (
    <>
      <PaginationSummary
        paginationConfig={paginationConfig}
        loadingTotalRecords={loadingTotalRecords}
      />
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No data to display</NoDataFound>
              </td>
            </tr>
          ) : null}
          {(data || []).map((x, index) => (
            <tr key={x._id} className="fs-val-md">
              <td>{paginationConfig.startSlNo + index}</td>
              <td>{x._id}</td>
              <td>
                <span className="text-primary">{x.deal?.name || "--"}</span>
              </td>
              <td>{x.deal?.id || "--"}</td>
              <td>
                <div>{x.deal?.categoryName || "--"}</div>
                <KeyVal
                  label={x.deal?.categoryId ? `ID:${x.deal?.categoryId}` : "--"}
                  template="col"
                ></KeyVal>
              </td>
              <td>
                <div>{x.deal?.brandName || "--"}</div>
                <KeyVal
                  label={x.deal.brandId ? `ID:${x.deal?.brandId}` : "--"}
                  template="col"
                ></KeyVal>
              </td>

              <td>
                <div className="text-primary">
                  {x.customerInfo?.name || "--"}
                </div>
                <KeyVal
                  className="text-primary"
                  label={x.customerInfo?.id ? `ID:${x.customerInfo?.id}` : "--"}
                  template="col"
                ></KeyVal>
              </td>
              <td>
                <div className="text-primary">
                  {x.franchiseInfo?.name || "--"}
                </div>
                <KeyVal
                  label={
                    x.franchiseInfo?.id ? `ID:${x.franchiseInfo?.id}` : "--"
                  }
                  template="col"
                ></KeyVal>
              </td>

              <td>
                {x.createdAt ? <DateFormatter date={x.createdAt} /> : "--"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default OutOfStockRequestTable;
