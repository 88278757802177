import { useAttachAdditionalData } from "@sk/hooks";
import { CommissionService } from "@sk/services";
import {
  Amount,
  AppTitle,
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  Toaster,
} from "@sk/uis";
import { format } from "date-fns";
import produce from "immer";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { AuditLogPosSmartCoins } from "../components/AuditLogPosSmartcoins";
import ExcludeForTable from "../components/ExcludeForTable";

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_lastUpdatedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "55%",
  },
};

const ViewPosSmartCoinModal = ({ show, closeModal, modalData }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({});

  const [excludeForData, setExcludeForData] = useState([]);

  useEffect(() => {
    if (details.excludeFor) {
      Object.keys(details.excludeFor).forEach((key) => {
        const list = details.excludeFor[key].map((x) => ({ ...x, type: key }));
        setExcludeForData(
          produce((draft) => {
            draft.push(...list);
          })
        );
      });
    }
  }, [details.excludeFor]);

  const triggerCloseModal = useCallback(() => {
    closeModal();
    setExcludeForData([]);
  }, [closeModal]);

  const isValidDate = (dateString) => {
    // testing Both date String and ISO Stirng
    return /\(IST\)$/gi.test(dateString) || /000Z/.test(dateString);
  };

  useEffect(() => {
    if (show) {
      if (modalData._id) {
        const fetchDetails = async () => {
          setLoading(true);
          const r = await CommissionService.getPosConfigById(modalData._id);

          let d = r.resp || {};

          // for formatting the audit log date values
          if (d.auditLog) {
            (d.auditLog || []).forEach((x) => {
              try {
                if (isValidDate(x.oldData)) {
                  x.oldData = format(
                    new Date(x.oldData),
                    "dd MMM yyyy hh:mm a"
                  );
                }
                if (isValidDate(x.newData)) {
                  x.newData = format(
                    new Date(x.newData),
                    "dd MMM yyyy hh:mm a"
                  );
                }
              } catch (error) {}
            });
          }

          setDetails(d);

          setLoading(false);

          if (d._id) {
            let tmp = [];
            // Attach User Info
            setAdditionalData([d], attachAdditionalDataConfig, (x) => {
              tmp.push(x);
              if (tmp.length == attachAdditionalDataConfig.length) {
                const t = [...attachAllData([d], tmp)][0];
                setDetails((v) => ({ ...v, ...t }));
              }
            });
          } else {
            Toaster.error("Failed to fetch data, please try again");
            triggerCloseModal();
          }
        };

        fetchDetails();
      }
    }
  }, [
    modalData._id,
    show,
    triggerCloseModal,
    setAdditionalData,
    attachAllData,
  ]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          style={style.offCanvasHeaderStyle}
          closeVariant="white"
        >
          <div className="fs-val-lg text-dark px-2">
            {" Smart Coin Config" + " - " + modalData?._id}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          {show ? (
            <>
              {loading ? (
                <PageLoader />
              ) : (
                <>
                  <div className="row px-4 py-3 bg-light g-0">
                    <div className="col-12">
                      <AppTitle title="Basic Details" />
                    </div>
                    <div className="col-3 mb-4">
                      <KeyVal label="Classification" template="col">
                        <span className="badge badge-soft-primary text-uppercase fw-bold">
                          {details.refType}
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4">
                      <KeyVal label="Status" template="col">
                        <HighlightText status={details.status} />
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4">
                      <KeyVal label="Store Type" template="col">
                        <span className="badge badge-soft-primary text-uppercase fw-bold">
                          {details.level}
                        </span>
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4">
                      <KeyVal label="Min Value" template="col">
                        <Amount value={details.minValue} />
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4">
                      <KeyVal label="Max Value" template="col">
                        <Amount value={details.maxValue} />
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4">
                      <KeyVal label="Reward Type" template="col">
                        {details.rewardType}
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4">
                      <KeyVal label="Reward Value" template="col">
                        {details.rewardValue}{" "}
                        {details.rewardType == "Percentage" ? "%" : ""}
                      </KeyVal>
                    </div>

                    <div className="col-3 mb-4">
                      <KeyVal label="Max Coins" template="col">
                        {details.rewardType == "Percentage"
                          ? details.maxCoins
                          : "N/A"}
                      </KeyVal>
                    </div>

                    <div className="col-3">
                      <KeyVal label="Created At" template="col">
                        <DateFormatter date={details.createdAt} />
                      </KeyVal>
                    </div>

                    <div className="col-3">
                      <KeyVal label="Created By" template="col">
                        <span> {details._createdBy?.name}</span>
                      </KeyVal>
                    </div>

                    <div className="col-3">
                      <KeyVal label="Last Updated At" template="col">
                        <DateFormatter date={details.lastUpdated} />
                      </KeyVal>
                    </div>

                    <div className="col-3">
                      <KeyVal label="Last Updated By" template="col">
                        {details._lastUpdatedBy?.name ? (
                          <span>{details._lastUpdatedBy.name}</span>
                        ) : null}
                      </KeyVal>
                    </div>
                  </div>

                  <div className="row px-4 py-3 border-bottom g-0">
                    <div className="col-3">
                      <KeyVal label="Extra Reward" template="col">
                        <HighlightText
                          isBadge={false}
                          status={details.extraReward ? "YES" : "NO"}
                        />
                      </KeyVal>
                    </div>
                    {details.extraReward ? (
                      <>
                        <div className="col-3">
                          <KeyVal label="Coin Multiplier" template="col">
                            {details.multipleValue}
                          </KeyVal>
                        </div>
                        <div className="col-3">
                          <KeyVal label="Start Date" template="col">
                            <div className="d-inline-block">
                              <span>
                                <DateFormatter
                                  date={details.validFrom}
                                  format="dd MMM yyyy"
                                />
                                ,
                              </span>
                              <span className="mx-1">
                                <DateFormatter
                                  date={details.validFrom}
                                  format="hh:mm a"
                                />
                              </span>
                            </div>
                          </KeyVal>
                        </div>

                        <div className="col-3">
                          <KeyVal label="End Date" template="col">
                            <div className="d-inline-block">
                              <span>
                                <DateFormatter
                                  date={details.validTo}
                                  format="dd MMM yyyy"
                                />
                                ,
                              </span>
                              <span className="mx-1">
                                <DateFormatter
                                  date={details?.validTo}
                                  format="hh:mm a"
                                />
                              </span>
                            </div>
                          </KeyVal>
                        </div>
                      </>
                    ) : null}
                  </div>

                  {excludeForData.length ? (
                    <div className="border-bottom">
                      <div className="px-4">
                        <AppTitle title="Excluded" />
                      </div>
                      <ExcludeForTable
                        mode="view"
                        excludeForData={excludeForData}
                      />
                    </div>
                  ) : null}

                  <AuditLogPosSmartCoins auditLogs={details.auditLog || []} />
                </>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ViewPosSmartCoinModal);
