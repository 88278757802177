import { SelectInput, TextInput } from "@sk/uis";
import debounce from "lodash/debounce";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";

const statusOptions = [
  { label: "Select Status", value: "" },
  { label: "Publish", value: "Publish" },
  { label: "Un-publish", value: "Unpublish" },
  { label: "Drafted", value: "Draft" },
];

const isActiveOptions = [
  { label: "Select", value: "" },
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const defaultFromValues = {
  deal: "",
  status: "",
  is_active: "",
};

const Filter = ({ callback, id }) => {
  const { register, getValues } = useForm({
    defaultValues: defaultFromValues,
  });
  const onSearch = useCallback(
    debounce(() => {
      const data = getValues();
      callback({ formData: data });
    }, 700),
    [getValues, callback]
  );

  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  const dealSearchFilter = {
    filter: { product: { $elemMatch: { id: id } } },
  };

  return (
    <>
      <form>
        <div className="row">
          <div className="col-4">
            <TextInput
              name="deal"
              type="Text"
              register={register}
              label="Search for Deal  "
              placeholder="Search by Id/Name"
              callback={onSearch}
              filterParams={dealSearchFilter}
              gap={1}
            />
          </div>

          <div className="col-auto">
            <SelectInput
              label="Status"
              register={register}
              name="status"
              callback={onFilterChange}
              isMandatory={false}
              options={statusOptions}
              gap={1}
            />
          </div>

          <div className="col-auto me-auto">
            <SelectInput
              label="Is Active"
              register={register}
              name="is_active"
              callback={onFilterChange}
              isMandatory={false}
              options={isActiveOptions}
              gap={1}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
