import { TextEditorInput } from "@sk/uis";
import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { manageView } from "../../../constantService";

const Description = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const onDescChange = useCallback(
    (chngFn) => (e) => {
      chngFn(e.cnt || "");
    },
    []
  );

  return (
    <div className="m-2">
      <Controller
        name="fullDescription"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextEditorInput
            name="fullDescription"
            control={control}
            label="Description"
            error={errors?.fullDescription?.message}
            isMandatory={false}
            value={value}
            onChangeCb={onDescChange(onChange)}
            info={manageView.infoContext.fullDescription}
          />
        )}
      />
    </div>
  );
};

export default Description;
