import { AppCard, DateInput, EntitySearchInput, SelectInput } from "@sk/uis";
import { Offcanvas, OffcanvasBody } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const canvasStyle = { width: "30%" };

const defaultFormData = {
  status: "active",
  createdAt: [],
  linkedDeal: [],
};

const statusOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "InActive",
    value: "inactive",
  },
];

const dtConfig = {
  mode: "range",
};

function StoreComboAdvanceFilterModal({ show = false, callback }) {
  const { register, control, reset, getValues } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const onHide = () => {
    callback({ action: "close" });
  };

  const onApply = () => {
    callback({ action: "apply", formData: getValues() });
  };

  const onReset = () => {
    reset({ ...defaultFormData });
    callback({ action: "reset", formData: getValues() });
  };

  return (
    <Offcanvas show={show} placement="end" style={canvasStyle} onHide={onHide}>
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Advance Filter</Offcanvas.Title>
      </Offcanvas.Header>
      <OffcanvasBody className="modal-bg">
        <AppCard>
          <SelectInput
            label="Choose Status"
            register={register}
            name="status"
            options={statusOptions}
          />

          <Controller
            name="createdAt"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DateInput
                label="Created On"
                value={value}
                callback={onChange}
                config={dtConfig}
              />
            )}
          />

          <Controller
            name="linkedDeal"
            control={control}
            render={({ field: { value, onChange } }) => (
              <EntitySearchInput
                callback={onChange}
                value={value}
                label="Linked Deals"
                type="deal"
                uid="sr-adv-deal"
              />
            )}
          />
        </AppCard>

        <div className="position-absolute bottom-0 left-0 right-0 text-end p-4 w-100">
          <button className="btn btn-warning me-2" onClick={onReset}>
            Reset
          </button>
          <button className="btn btn-primary" onClick={onApply}>
            Apply
          </button>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
}

export default StoreComboAdvanceFilterModal;
