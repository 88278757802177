import { CommonService, NavService } from "@sk/services";
import {
  Alert,
  Amount,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  SelectInput,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  Toaster,
} from "@sk/uis";
import { memo } from "react";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object({
  configData: yup.array(
    yup.object({
      rsp: yup
        .number()
        .nullable()
        .transform((v) => (isFinite(v) ? v : null))
        .min(0.1),
      sk: yup
        .number()
        .nullable()
        .transform((v) => (isFinite(v) ? v : null)),
      store: yup
        .number()
        .nullable()
        .transform((v) => (isFinite(v) ? v : null)),
    })
  ),
});

const rspRefTypeOptions = [
  { label: "Select By RSP", value: "" },
  { label: "Set MRP as RSP", value: "mrp" },
  { label: "Set B2B as RSP", value: "b2bPrice" },
];

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  {
    label: "Deal Name",
    // key: "deal.name", enableSort: true
  },
  {
    label: "Brand",
    width: "16%",
    // key: "deal.brand.name", enableSort: true
  },
  {
    label: "Category",
    width: "16%",
    // key: "deal.category.name",
    // enableSort: true,
  },
  {
    label: "MRP",
    width: "auto",
    isCentered: true,
    key: "mrp",
    enableSort: true,
  },
  {
    label: "B2B",
    width: "auto",
    isCentered: true,
    key: "b2bprice",
    enableSort: true,
  },
  { label: "RSP (%)", width: "7%", isCentered: true },
  { label: "Sk Profit", isCentered: true },
  { label: "Store Prfit", isCentered: true },
];

const navigate = (type, id) => {
  const path = `/cms/${type}/view`;
  // NavService.openInNewTab(path, { id });
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort = {},
  sortCb,
  callback,
  busyLoader,
}) => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: { configData: [], rspRefType: "" },
  });

  const [configData, rspRefType] = useWatch({
    control,
    name: ["configData", "rspRefType"],
  });

  const onSubmit = async () => {
    let list = configData.map((e, index) => {
      let d = data.filter((e, i) => {
        return index == i;
      });
      return { id: d._id, rspDisc: e.rsp, mrp: d.mrp };
    });

    let isRspMoreThanMrp = false;
    let isNegativeValue = false;
    let isDiscMore = false;

    list.forEach((e) => {
      if (e.rspDisc > e.mrp) {
        isRspMoreThanMrp = true;
      }
      if (e.rspDisc <= 0) {
        isNegativeValue = true;
      }
      if (e.rspDisc > 100) {
        isDiscMore == true;
      }
    });

    if (isRspMoreThanMrp) {
      Toaster.error("RSP Should not be More than MRP");
      return;
    }
    if (isNegativeValue) {
      Toaster.error("RSP Should be more than 0");
      return;
    }

    if (isDiscMore) {
      Toaster.error("RSP Should be less than 100");
      return;
    }

    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (res.isDismissed) {
      return;
    }

    let p = {};

    if (rspRefType?.length) {
      p = {
        rule: rspRefType == "mrp" ? "SET-RSP-AS-MRP" : "SET-RSP-AS-B2B",
        noOfRows: paginationConfig.totalRecords,
      };
    } else {
      p = {
        rule: "BYDEAL",
        deals: list.filter((e) => {
          return e.rspDisc >= 0;
        }),
      };
    }

    callback({ status: "submit", payload: p });
  };

  const onBulkRspChange = (v) => {
    let val = getValues("rspRefType");
    if (val.length) {
      [...data].forEach((e, index) => {
        setValue(`configData.${index}.rsp`, e[v]);
      });
    } else {
      resetInputData();
    }
  };

  const resetInputData = () => {
    [...data].forEach((e, index) => {
      setValue(`configData.${index}.rsp`, null);
    });
    setValue("rspRefType", "");
  };

  const discountCb = (e, index, dId) => {
    const v = getValidDiscountVal(e.target.value);
    setValue(`configData.${index}.rsp`, v, { shouldValidate: true });
    let d = [...data].find((e) => {
      return e._id == dId;
    });

    let mrp = d?.mrp;

    let rspPrice = mrp - mrp * (v / 100);

    let sk = rspPrice - (d?.slc || 0);
    let store = rspPrice - (d?.b2bPrice || 0);
    setValue(`configData.${index}.sk`, sk);
    setValue(`configData.${index}.store`, store);
  };

  const getValidDiscountVal = (val) => {
    if (val > 100) {
      return 100;
    }
    return val ? CommonService.roundedByDecimalPlace(val, 6) : val;
  };

  const NavToBrand = (id) => {
    return NavService.openInNewTab("/cms/brand/view", {
      id,
    });
  };

  const NavToCategory = (id) => {
    return NavService.openInNewTab("/cms/category/view", {
      id,
    });
  };

  const NavToDeal = (id) => {
    return NavService.openInNewTab("/cms/deal/view", {
      id,
    });
  };

  return (
    <>
      <div className="row mb-2 mt-3">
        <div className="col pt-2">
          <PaginationSummary
            paginationConfig={paginationConfig}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
        {rspRefType ? (
          <div className="col-auto text-danger fs-val-sm pt-3 fw-semibold">
            Note : If you select{" "}
            {rspRefType == "mrp" ? "Set MRP as RSP" : "Set RSP as MRP"} this
            will aplly to all {paginationConfig.totalRecords} deals
          </div>
        ) : null}
        <div className="col-auto ms-auto">
          <SelectInput
            register={register}
            options={rspRefTypeOptions}
            name="rspRefType"
            callback={onBulkRspChange}
          />
        </div>
        <div className="col-auto ms-auto ps-3">
          <button className="btn  btn-outline-danger" onClick={resetInputData}>
            Reset All RSP
          </button>
        </div>
      </div>
      <table className="table table-bordered bg-white fs-val-sm">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {loading ? (
            <TableSkeletonLoader
              cols={headers.length}
              rows={paginationConfig.rowsPerPage}
            />
          ) : null}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound> No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {!loading &&
            data.map((x, index) => (
              <tr key={index} className="fs-val-md">
                {/* serial no. */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                <td>
                  <div
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      NavToDeal(x?._id);
                    }}
                  >
                    {x?.deal?.name} - {x?.deal?._id}
                  </div>
                </td>

                <td>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      NavToBrand(x?.brandId);
                    }}
                  >
                    {x?.brandName} - {x?.brandId}
                  </div>
                </td>

                <td>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      NavToCategory(x?.categoryId);
                    }}
                  >
                    {x?.categoryName} - {x?.categoryId}
                  </div>
                </td>

                <td className="text-center">
                  <Amount value={x?.mrp} decimalPlace={2} />
                </td>

                <td className="text-center">
                  <Amount value={x?.b2bPrice} decimalPlace={2} />
                </td>

                <td className="text-center">
                  <TextInput
                    type="number"
                    error={errors?.[`configData.${index}.rsp`]?.message}
                    register={register}
                    placeholder="RSP (%)"
                    name={`configData.${index}.rsp`}
                    min={0}
                    disabled={rspRefType?.length}
                    callback={(e) => {
                      discountCb(e, index, x?._id);
                    }}
                  />
                </td>

                <td className="text-center">
                  <Amount
                    value={getValues(`configData.${index}.sk`) || 0}
                    decimalPlace={2}
                  />
                </td>
                <td className="text-center">
                  <Amount
                    value={getValues(`configData.${index}.store`) || 0}
                    decimalPlace={2}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* pagination block */}
      {!loading && data.length > 0 && (
        <PaginationBlock
          paginationConfig={paginationConfig}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      )}
      <div className="text-end">
        <button
          className="btn btn-primary"
          onClick={onSubmit}
          disabled={busyLoader}
        >
          Submit {busyLoader ? <Spinner isSmall={true} /> : null}
        </button>
      </div>
    </>
  );
};

export default memo(Table);
