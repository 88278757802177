import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, CommonService, OmsService } from "@sk/services";
import { PaginationSummary } from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { detailsView } from "../../../constantService";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = detailsView.tabs.orders.pagination;

const formLabelsConfig = detailsView.tabs.orders.formLabels;

const attachAdditionalDataConfig =
  detailsView.tabs.orders.additionalTableDataConfig;

const defaultFormData = {
  status: "",
  orderId: "",
  createdAt: [],
  warehouse: AuthService.getDefaultWhId(),
};

const defaultSortOpt = { key: "createdAt", value: "desc" };

//  Prepare Filter Params
const prepareFilterParams = (
  pagination = {},
  filter = {},
  sort = {},
  additionalData
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      ["subOrders.products"]: { $elemMatch: { id: additionalData.id } },
    },
  };

  if (filter.createdAt?.length) {
    p.filter.date = {
      $gte: set(filter?.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  if (filter.orderId.trim()) {
    p.filter._id = filter.orderId;
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (filter.warehouse) {
    p.filter.source = {
      $in: [filter.warehouse],
    };
  }

  if (sort.key) {
    p.sort = {
      createdAt: sort.value == "desc" ? -1 : 1,
    };
  }

  return p;
};

//  To Fetch Data
const getData = async (params) => {
  const r = await OmsService.getOrderList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.userLoading = true;
        return x;
      })
    : [];
};

//  To Get Total Count
const getCount = async (params) => {
  delete params.count;
  delete params.page;

  try {
    const r = await OmsService.getOrderCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const Orders = ({ pId }) => {
  // It will have All Orders Data
  const [data, setData] = useState([]);

  // To Store Pagination Data
  const paginationRef = useRef({ ...defaultPageOpt });

  // To Store Sort Ref
  const sortRef = useRef({ ...defaultSortOpt });

  // To Store Filter Data Ref
  const filterDataRef = useRef({ ...defaultFormData });

  //  Custom Hook To attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // To Apply Filter Label
  const [filterLabels, setFilterLabels] = useState([]);

  // Loading State While Fetching Orders
  const [loadingData, setLoadingData] = useState(true);

  // Loading State While Fetching Orders Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  useEffect(() => {
    if (pId) {
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  //  To get prepare Filter Params
  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      {
        id: pId,
      }
    );
  }, [pId]);

  //  To Load List
  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(params);
    // attaching additional Info
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // To Apply Filter
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };

    loadList();
    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();

    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  //  To Change Pagination And Calling API
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  //  To Get Filter From Data And Applying Filter
  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = data.formData;
      paginationRef.current = { ...defaultPageOpt };
      applyFilter();
    },
    [applyFilter]
  );

  //  To Sort
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  //  To Prepare Filter Params
  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelsConfig,
      filterDataRef.current || {}
    );
    setFilterLabels(l);
  };

  return (
    <>
      <Filter callback={filterFormCb} id={pId} />
      {/* 
      {filterLabels.length > 0 ? (
        <div className="mb-2">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null} */}

      {/* Table */}
      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sort={sortRef.current}
        sortCb={sortCb}
      />
    </>
  );
};

export default Orders;
