import {
  Amount,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "ID", isCentered: true },
  { label: "Classification", isCentered: true },
  { label: "Store Type", isCentered: true },
  { label: "Min", isCentered: true },
  { label: "Max", isCentered: true },
  { label: "Reward Type", isCentered: true },
  { label: "Reward Value", isCentered: true },
  { label: "Extra Rewards", isCentered: true },
  { label: "Max Coins", isCentered: true },
  { label: "Status", isCentered: true },
  { label: "Actions", isCentered: true },
];

const rbac = {
  editButton: ["EDITSCCONFIG"],
  viewButton: ["VIEWSCCONFIG"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  openViewModal,
  editCb,
  sortCb,
  markAsActive,
  markAsInActive,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white mt-4">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Smart Coin Config Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {/* When Not Loading and Data is there  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* Serial Number */}
                <td>{paginationConfig.startSlNo + index}</td>
                <td>
                  {" "}
                  <span
                    role="button"
                    tabIndex={-1}
                    onClick={() => openViewModal(x)}
                    className="btn btn-link fs-val-md"
                  >
                    {x._id}
                  </span>
                </td>
                <td className="text-center">{x.refType}</td>
                <td className="text-center">
                  <span className="fs-val-sm badge badge-soft-primary text-uppercase">
                    {x.level ?? "N/A"}
                  </span>
                </td>
                <td className="text-center">
                  <Amount value={x.minValue} />
                </td>
                <td className="text-center">
                  <Amount value={x.maxValue} />
                </td>
                <td className="text-center">{x.rewardType} </td>
                <td className="text-center">
                  {x.rewardValue} {x.rewardType == "Percentage" ? "%" : ""}{" "}
                </td>
                <td className="text-center">
                  <HighlightText
                    status={x.extraReward ? "Yes" : "No"}
                    isBadge={false}
                  />
                </td>
                <td className="text-center">
                  {x.rewardType == "Percentage" ? x.maxCoins : "N/A"}{" "}
                </td>
                <td className="text-center">
                  <HighlightText status={x.status} isBadge={false} />

                  <Rbac roles={rbac.editButton}>
                    {/* FOR MARK AS IN-ACTIVE BUTTON  */}
                    {x.status == "Active" ? (
                      <div>
                        <button
                          className="btn btn-link text-danger fs-val-xs p-0"
                          onClick={() => markAsInActive(x)}
                        >
                          Mark as Inactive
                        </button>
                      </div>
                    ) : null}

                    {/* FOR MARK AS ACTIVE BUTTON  */}
                    {x.status == "Inactive" ? (
                      <button
                        className="btn btn-link text-success fs-val-xs p-0"
                        onClick={() => markAsActive(x)}
                      >
                        Mark as Active
                      </button>
                    ) : null}
                  </Rbac>
                </td>
                <td className="text-center">
                  <Rbac roles={rbac.viewButton}>
                    <button
                      onClick={() => openViewModal(x)}
                      className="btn btn-sm fs-val-md btn-outline-primary me-1"
                    >
                      View
                    </button>
                  </Rbac>
                  <Rbac roles={rbac.editButton}>
                    <button
                      className="btn btn-sm btn-outline-warning  fs-val-md"
                      onClick={() => editCb(x._id)}
                    >
                      Edit
                    </button>
                  </Rbac>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
