/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from "@hookform/resolvers/yup";
import { AjaxService, BrandService, CategoryService } from "@sk/services";
import {
  Alert,
  AppTitle,
  BooleanCheckboxInput,
  EntitySearchInput,
  PageLoader,
  RadioInput,
  SelectInput,
  Spinner,
  TextInput,
  Toaster,
} from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import Images from "./components/Images";

// Validation Schema For Brand
const manageBrandValidationSchema = yup.object({
  name: yup.string().trim().required("Brand Name is Required"),

  brandType: yup.string().trim().required("Brand Type is Required"),

  status: yup.string().required("Status is required"),

  isMasterBrand: yup.boolean(),

  masterBrand: yup.array(),
  // .when("isMasterBrand", {
  //   is: (masterBrand) => {
  //     return masterBrand ? false : true;
  //   },
  //   then: (schema) =>
  //     schema.label("Master Brand").min(1, "Master Brand is Required"),
  // }),

  image: yup.array(),
  // .min(1, "Image is Required"),

  category: yup.array(),
});

// default From Values
const defaultFromData = {
  brand: [],
  image: [],
  isMasterBrand: false,
  mainMobileImage: [],
  mainDesktopImage: [],
  masterBrand: [],
  category: [],
};
// Status Options
const statusOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

// Brand Type Option
const BrandTypeOption = [
  { label: "Select", value: "" },
  { label: "GM", value: "GM" },
  { label: "REGULAR", value: "REGULAR" },
  { label: "FRIENDLY", value: "FRIENDLY" },
  { label: "THIRD PARTY", value: "THIRD PARTY" },
];

// Canvas Style
const style = {
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
  offCanvasStyle: {
    width: "35%",
  },
};

//  To Get Master brand Details
const getMasterBrandDetails = async (id) => {
  const r = await BrandService.getDetailsById(id);
  return {
    data: ([r.resp] || []).map((x) => ({
      label: x.name + " (" + x._id + ") ",
      value: x,
    })),
  };
};

const getCategoryDetails = async (ids) => {
  let p = { filter: { _id: { $in: ids } } };
  const r = await CategoryService.getList(p);
  return {
    data: (r.resp || []).map((x) => ({
      label: x.name + " (" + x._id + ") ",
      value: x,
    })),
  };
};

// MasterBrand Entity Search Input Filter
const masterBrandInputFilter = { filter: { "masterData.isMaster": true } };

const ManageBrandModal = ({ show, closeModal, mode, id }) => {
  // To show Spinner on Submit Button While Submitting Form
  const [submitting, setSubmitting] = useState(false);

  // Page Loader
  const [pageLoading, setPageLoading] = useState(false);

  // React Hook Form
  const methods = useForm({
    resolver: yupResolver(manageBrandValidationSchema),
    defaultValues: { ...defaultFromData },
  });

  useEffect(() => {
    if (show) {
      // when the modal get opened clear the form data
      methods.reset();

      if (mode == "edit") {
        if (id) {
          loadEditData(id);
        } else {
          Toaster.error("Invalid ID");
          triggerCloseModal();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, id, show]);

  // To Prepare Form Values From LoadEditData
  const prepareFromValue = async (r) => {
    // Fields
    ["name", "brandType", "status", "isValueBrand"].forEach((x) =>
      methods.setValue(x, r[x] || "")
    );

    // Images
    ["image", "mainDesktopImage", "mainMobileImage"].forEach((x) => {
      if (r[x].length) {
        methods.setValue(
          x,
          r[x].map((e) => ({ asset: e }))
        );
      }
    });

    // For Master Brand
    if (r.masterData) {
      methods.setValue("isMasterBrand", r.masterData.isMaster);
    }
    // getting Master Brand
    if (r.masterData && r.masterData.masterId) {
      const br = await getMasterBrandDetails(r?.masterData?.masterId);
      methods.setValue("masterBrand", br.data);
    }

    if (r.category && r.category.length) {
      const c = await getCategoryDetails(r.category);
      methods.setValue("category", c.data);
    }
  };

  // To get Edit Data When Id Is There
  const loadEditData = async (id) => {
    setPageLoading(true);
    const r = await BrandService.getDetailsById(id);
    const d = r.resp || {};

    if (!d._id) {
      Toaster.error("Failed to fetch details, please try again");
      triggerCloseModal();
      setPageLoading(false);
      return;
    }
    await prepareFromValue(d);
    setPageLoading(false);
  };

  // Preparing Payload While Hitting API
  const preparePayload = (data) => {
    let p = {
      classification: "B2B",
      name: data.name,
      brandType: data.brandType,
      image: data.image.map((x) => x.asset),
      status: data.status,
      isValueBrand: data.isValueBrand ? true : false,
      masterData: {
        masterId: data.masterBrand?.length ? data.masterBrand[0].value._id : "",
        isMaster: data.isMasterBrand ? true : false,
      },
    };

    if (data.mainMobileImage.length) {
      p.mainMobileImage = data.mainMobileImage.map((x) => x.asset);
    }
    if (data.mainDesktopImage.length) {
      p.mainDesktopImage = data.mainDesktopImage.map((x) => x.asset);
    }

    if (data?.category?.length) {
      p.category = data.category.map((e) => {
        return e.value._id;
      });
    }

    return p;
  };

  // for resetting the form data and its dependent state
  const resetFormData = () => {
    // reset();
  };

  const onSubmit = async (data) => {
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    const p = preparePayload(data);

    setSubmitting(true);

    let r = {};

    if (mode == "add") {
      r = await BrandService.create(p);
    } else {
      r = await BrandService.update(id, p);
    }

    setSubmitting(false);

    if (r.statusCode != 200) {
      let error = AjaxService.parseError(r.resp);
      Toaster.error(error.msg[0] || "Failed to update");
      return;
    }

    resetFormData();

    Toaster.success(`${mode == "add" ? "Added" : "Updated"} Successfully`);

    triggerCloseModal("submit");
  };

  // To Close Modal
  const triggerCloseModal = (status = "cancel") => {
    closeModal({ status });
  };

  // To check Weather Is Master Brand is checked or Not
  const isMasterBrand = useWatch({
    name: "isMasterBrand",
    control: methods.control,
  });

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          closeVariant="white"
          style={style.offCanvasHeaderStyle}
        >
          {/* Modal Title */}
          <AppTitle
            title={mode === "edit" ? "Edit Brand" + `  #${id}` : "Add Brand"}
            className="fs-val-lg text-dark px-2"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="p-4">
          {pageLoading ? (
            <PageLoader />
          ) : (
            <>
              <div>
                <FormProvider
                  {...methods}
                  // onSubmit={methods.handleSubmit(onSubmit)}
                >
                  <form autoComplete="off">
                    <div className="row">
                      {/* Brand Name  */}
                      <div className="col-12 mb-2">
                        <TextInput
                          type="text"
                          label="Brand Name"
                          register={methods.register}
                          name="name"
                          error={methods?.formState?.errors?.["name"]?.message}
                          placeholder="Enter Name"
                          isMandatory={true}
                          // info={{
                          //   header: "",
                          //   placement: "right",
                          //   content: "Lorem Ipsum",
                          // }}
                        />
                      </div>

                      {/* Brand Type */}
                      <div className="col-12 mb-2">
                        <SelectInput
                          type="text"
                          label="Brand Type"
                          register={methods.register}
                          name="brandType"
                          error={methods?.formState?.errors?.brandType?.message}
                          options={BrandTypeOption}
                          isMandatory={true}
                        />
                      </div>

                      {/* Status */}
                      <div className="col-12 mb-2">
                        <Controller
                          name="status"
                          render={({ field: { value, onChange } }) => (
                            <RadioInput
                              label="Status"
                              register={methods.register}
                              name="status"
                              value={value}
                              onChange={onChange}
                              error={
                                methods?.formState?.errors?.status?.message
                              }
                              isMandatory={true}
                              options={statusOptions}
                            />
                          )}
                        />
                      </div>

                      {/*  Is Value Brand */}
                      <div className="col-6">
                        <Controller
                          control={methods.control}
                          name="isValueBrand"
                          render={({ field: { onChange, value } }) => (
                            <BooleanCheckboxInput
                              label="Is Value Brand?"
                              name="isValueBrand"
                              error={
                                methods?.formState?.errors?.isValueBrand
                                  ?.message
                              }
                              isMandatory={false}
                              callback={onChange}
                              value={value}
                            />
                          )}
                        />
                      </div>

                      {/*  Is Master Brand */}
                      <div className="col-6">
                        <Controller
                          name="isMasterBrand"
                          control={methods.control}
                          render={({ field: { onChange, value } }) => (
                            <BooleanCheckboxInput
                              label="Is Master Brand?"
                              name="isMasterBrand"
                              error={
                                methods.formState?.errors?.isMasterBrand
                                  ?.message
                              }
                              isMandatory={false}
                              callback={onChange}
                              value={value}
                            />
                          )}
                        />
                      </div>

                      {/* Master Brand */}
                      <div className="col-12 my-2">
                        {!isMasterBrand && (
                          <Controller
                            control={methods.control}
                            name="masterBrand"
                            key="brand"
                            render={({ field: { onChange, value } }) => (
                              <EntitySearchInput
                                type="brand"
                                label="Search for Master Brand"
                                name="masterBrand"
                                placeholder="Search by  Id/Name"
                                value={value}
                                error={
                                  methods?.formState?.errors?.masterBrand
                                    ?.message
                                }
                                isMandatory={false}
                                callback={onChange}
                                uid="deal-brand"
                                emptyLabel="No Brand Found"
                                filterParams={masterBrandInputFilter}
                              />
                            )}
                          />
                        )}
                      </div>

                      <div className="col-12 my-2">
                        <Controller
                          control={methods.control}
                          name="category"
                          key="category"
                          render={({ field: { onChange, value } }) => (
                            <EntitySearchInput
                              type="category"
                              label="Category"
                              name="category"
                              placeholder="Search by  Id/Name"
                              value={value}
                              error={
                                methods?.formState?.errors?.category?.message
                              }
                              isMandatory={false}
                              callback={onChange}
                              uid="category"
                              emptyLabel="No category Found"
                              isMultiple={true}
                            />
                          )}
                        />
                      </div>

                      {/* Images */}
                      <div className="col-12   border-top py-3">
                        <div className="fs-val-lg mb-2">Upload Images</div>
                        <Images />
                      </div>
                    </div>

                    <div className="my-3 text-end">
                      <button
                        type="button"
                        className="btn btn-outline-dark me-1"
                        onClick={triggerCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        // type="submit"
                        onClick={methods.handleSubmit(onSubmit)}
                        disabled={submitting}
                      >
                        Submit {submitting ? <Spinner isSmall={true} /> : null}
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ManageBrandModal);
