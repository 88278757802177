import { CommonService, NavService, VendorService } from "@sk/services";
import {
  AppliedFilterLabel,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";

const canvasStyle = { width: "60%" };

const tableHeader = [
  { label: "Sl.No", width: "5%" },
  { label: "ID", width: "auto" },
  { label: "Name" },
  { label: "GST No.", width: "10%" },
  { label: "Address", width: "25%" },
];

const defaultFormDataOpt = {
  vendor: "",
};

const defaultPaginationOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const formLabelConfig = {
  vendor: {
    label: "Vendor",
    valuePath: "[0].value.name",
  },
};

const prepareFilterParams = (
  pagination = {},
  filter = {},
  additionalData = {}
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      margins: { $elemMatch: { brand: additionalData.id } },
    },
  };

  if (filter.vendor.trim()) {
    const vendorRegex = { $regex: filter.vendor.trim(), $options: "gi" };
    p.filter["$or"] = [{ name: vendorRegex }, { _id: vendorRegex }];
  }

  return p;
};

const getData = async (params) => {
  const r = await VendorService.getVendors(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;

  try {
    const r = await VendorService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const ViewVendorModal = ({ show, closeModal, brandId }) => {
  const { getValues, register, reset } = useForm();

  const [data, setData] = useState([]);

  const [loading, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [filterLabels, setFilterLabels] = useState([]);

  const paginationRef = useRef({ ...defaultPaginationOpt });

  const filterData = useRef({ ...defaultFormDataOpt });

  useEffect(() => {
    if (show && brandId) {
      filterData.current = { ...defaultFormDataOpt };
      reset();
      init();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [init, show, brandId]);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(paginationRef.current, filterData.current, {
      id: brandId,
    });
  }, [brandId]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();

    const d = await getData(p);

    setData(d);

    setLoadingData(false);
  }, [getFilterParams]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationOpt };

    loadList();

    prepareFilterLabels();

    // for loading Records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelConfig,
      filterData.current || {}
    );
    setFilterLabels(l);
  };

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const onVendorNameSearch = useCallback(
    debounce(() => {
      filterData.current = {
        vendor: getValues("vendor"),
      };
      applyFilter();
    }, 700),
    [brandId]
  );

  return (
    <Offcanvas
      placement="end"
      style={canvasStyle}
      show={show}
      onHide={closeModal}
    >
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Vendor Details #{brandId}</Offcanvas.Title>
      </Offcanvas.Header>

      {/* Page Loader */}
      <Offcanvas.Body className="border-top">
        {/* Filter Section */}
        <form>
          <div className="row">
            <div className="col-6 me-auto">
              <TextInput
                name="vendor"
                type="text"
                register={register}
                callback={onVendorNameSearch}
                placeholder="Search By Vendor Name /ID"
              />
            </div>
          </div>
        </form>

        {/* Table Section  */}
        {filterLabels.length > 0 ? (
          <div className="mb-2">
            <AppliedFilterLabel labels={filterLabels} />
          </div>
        ) : null}

        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>

        <table className="table table-bordered bg-white">
          <TableHeader data={tableHeader} />
          <tbody>
            {/* When Loading */}
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={tableHeader.length}
                height={40}
              />
            )}

            {/* When No Data */}
            {!loading && data.length == 0 && (
              <tr>
                <td colSpan={tableHeader.length}>
                  <NoDataFound> No Vendor Found </NoDataFound>
                </td>
              </tr>
            )}

            {/* When Data is There */}
            {!loading &&
              data.length > 0 &&
              data.map((x, i) => (
                <tr key={i} className="fs-val-md">
                  <td>{paginationRef.current.startSlNo + i}</td>
                  <td
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      NavService.navToVendor({ id: x?._id });
                    }}
                  >
                    {x._id}
                  </td>
                  <td
                    className="text-primary cursor-pointer"
                    onClick={() => {
                      NavService.navToVendor({ id: x?._id });
                    }}
                  >
                    {x.name}
                  </td>
                  <td>{x.gst_no}</td>
                  <td>{x.address.line1}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <PaginationBlock
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewVendorModal;
