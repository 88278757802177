import { useAttachAdditionalData } from "@sk/hooks";
import { BrandService, CommonService } from "@sk/services";
import {
  AppliedFilterLabel,
  BusyLoader,
  PageInfo,
  PaginationSummary,
  SummaryCard,
  TableColumnBtn,
  TextInput,
  Toaster,
  Rbac,
} from "@sk/uis";

import { produce } from "immer";

import debounce from "lodash/debounce";

import merge from "lodash/merge";

import { memo, useCallback, useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import Table from "./components/Table";

import ManageBrandModal from "../modals/ManageBrandModal";

import listView from "../constantService";

import AdvanceFilterModal from "./modals/AdvanceFilterModal";

// To Get The Brand List Data
const getData = async (params) => {
  const r = await BrandService.getList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x._masterId = x?.masterData?.masterId ?? "";
        x.userLoading = true;
        x.modifiedLoading = true;
        x.masterBrandLoading = true;
        return x;
      })
    : [];
};

// To Get Count
const getCount = async (params) => {
  try {
    const r = await BrandService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};
const getProductNotLinkedCount = async (params) => {
  try {
    const r = await BrandService.getProductNotLinkedCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
    select:
      "name,masterData,isValueBrand,status,createdAt,createdBy,lastUpdated,modifiedBy",
  };

  let d = filter || {};

  const brand = d.brand.trim();

  // Brand FILTER - BASED ON ID AND NAME
  if (brand) {
    const brandRegex = { $regex: brand, $options: "gi" };
    p.filter["$or"] = [{ name: brandRegex }, { _id: brandRegex }];
  }

  if (d.status && d.status != "All") {
    p["filter"]["status"] = d.status;
  }

  if (d.isValueBrand) {
    p["filter"]["isValueBrand"] = d.isValueBrand == "Yes";
  }

  if (d.isMasterBrand) {
    p["filter"]["masterData.isMaster"] = d.isMasterBrand == "Yes";
  }
  if (d.masterBrand.length) {
    p["filter"]["masterData.masterId"] = d.masterBrand[0].value._id;
  }
  if (sort.key) {
    p.sort = sort.value == "desc" ? `-${sort.key}` : sort.key;
  }
  if (d.hideUnlinkedBrands) {
    p["hideUnlinkedBrands"] = true;
  }

  return p;
};

const rbac = {
  addButton: ["AddBrand"],
};

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const ListBrand = () => {
  const [data, setData] = useState([]);

  // loading state for List data
  const [loadingData, setLoadingData] = useState(true);

  // loading State for total count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // To Show Manage Modal
  const [showManageModal, setShowManageModal] = useState(false);

  // Setting  Summary Card Data
  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  // For Applied Filter Label
  const [filterLabels, setFilterLabels] = useState([]);

  // To Attach additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // To select More Columns
  const [selectedMoreTblColumns, setSelectedMoreTblColumns] = useState(() => {
    let t = {};
    listView.moreTableColumns
      .filter((x) => x.checked)
      .forEach((x) => {
        t[x.key] = x;
      });
    return t;
  });

  // To Store Filter Data
  const filterDataRef = useRef({ ...listView.filterFromData });

  // To Store Pagination Data
  const paginationRef = useRef({ ...listView.pagination });

  // To Store ManageBrand Modal Data
  const manageModalDataRef = useRef({ id: "", mode: "add" });

  // To Store Sort Ref
  const sortRef = useRef({ ...defaultSortOpt });

  // To Show Advance Filter Modal
  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  // To Download Report
  const [busyLoader, setBusyLoader] = useState(false);

  // React Hook From For Handling Filter Form Data
  const { register, getValues, setValue } = useForm({
    defaultValues: listView.filterFromData,
  });

  // Navigation Object For Routing
  const router = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const applyFilter = useCallback(
    async (isSummaryRequired = false) => {
      // Resetting pagination
      paginationRef.current = { ...listView.pagination };

      loadList();

      if (isSummaryRequired) {
        loadSummary();
      }

      prepareFilterLabels();

      // for total records
      setLoadingTotalRecords(true);

      const p = getFilterParams();
      const c = await getCount(p);
      paginationRef.current.totalRecords = c.count;

      setLoadingTotalRecords(false);
    },
    [loadList, loadSummary, prepareFilterLabels]
  );

  // Init
  const init = useCallback(async () => {
    applyFilter(true);
  }, [applyFilter]);

  // Fetching List Data
  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);

    // To Attach Additional Data
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, setAdditionalData]);

  // Preparing Filter Params
  const getFilterParams = () => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  };

  // Summary Count card
  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    // getting Filter Params
    const filterParams = getFilterParams();
    let promises = [];

    // Calling  Count API To Summary Card
    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter }); // preparing params for Count
      // Removing unnecessary
      delete params.count;
      delete params.page;
      delete params.select;

      // when type of summary Card is Product Not Link then only we are calling productNot Linked Api
      if (e.type == "productNotLinked") {
        promises.push(getProductNotLinkedCount(params));
      } else {
        promises.push(getCount(params));
      }
    });
    // Get Result of all Api and Setting count Value for Each Card
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [summaryCard]);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // For Searching via Brand
  const filterCb = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        brand: getValues("brand"),
      };

      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  // To Handle Advance Filter
  const advFilterCb = (data) => {
    if (data.status == "applied" || data.status == "reset") {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };

      paginationRef.current = { ...listView.pagination };
      applyFilter();
    }
    if (data.status == "reset") {
      setValue("brand", "");
    }
    closeAdvFilterModal(false);
  };

  // For Applying Filter Params
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.brand;

    if (!v.hideUnlinkedBrands) {
      delete v.hideUnlinkedBrands;
    }
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filer Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Close Manage Modal
  const closeManageModal = useCallback(
    (r) => {
      setShowManageModal(false);
      if (r.status == "submit") {
        applyFilter();
      }
    },
    [applyFilter]
  );

  // To Open Create Modal
  const openCreateModal = useCallback(() => {
    manageModalDataRef.current.mode = "add";
    manageModalDataRef.current.id = "";
    setShowManageModal(true);
  }, []);

  // To Open Edit Modal
  const openEditModal = useCallback((id) => {
    manageModalDataRef.current.mode = "edit";
    manageModalDataRef.current.id = id;
    setShowManageModal(true);
  }, []);

  //

  // To apply More Columns on Table
  const onMoreTableColApply = useCallback((d) => {
    let t = {};
    (d.selected || []).forEach((x) => {
      t[x.key] = x;
    });
    setSelectedMoreTblColumns({ ...t });
  }, []);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      {/* header with add Brand Button */}
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Manage Brands"
            breadcrumbs={listView.breadcrumb}
            navigate={router}
          />
        </div>
      </div>

      {/* Summary card */}
      <div className="row align-self-center mb-3">
        {summaryCard.map((e, k) => (
          <div className="col-3" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={2}
            />
          </div>
        ))}
      </div>

      {/* Filter  Block  */}
      <div className="row align-items-center">
        <div className="col-6">
          <div className="row">
            {/* Brand Search Input */}
            <div className="col-8 align-self-center">
              <TextInput
                name="brand"
                type="text"
                register={register}
                callback={filterCb}
                placeholder="Search By Brand Name /ID"
              />
            </div>

            {/* Filter Button */}
            <div className="col-auto">
              <button
                className="btn app-filter-btn"
                type="button"
                onClick={openAdvFilterModal}
              >
                <i className="bi bi-funnel"></i> FILTER
              </button>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="col-auto ms-auto align-self-center">
          {/* Download button is Not Required yet  */}

          {/* <button
            className="btn app-download-btn me-2"
            onClick={downloadReport}
          >
            Download Report
          </button> */}
          <Rbac roles={rbac.addButton}>
            <button
              className="btn btn-primary btn-sm fs-val-md me-1"
              onClick={openCreateModal}
            >
              Add Brand
            </button>
          </Rbac>

          <TableColumnBtn
            columns={listView?.moreTableColumns}
            callback={onMoreTableColApply}
          />
        </div>
      </div>

      {/* Advance Filter Modal  */}
      <AdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      {/* Table */}

      {/* PAGINATION SUMMARY */}
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <Table
        data={data}
        sort={sortRef.current}
        sortCb={sortCb}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        editCb={openEditModal}
        moreColumns={selectedMoreTblColumns}
      />

      {/* FORM */}
      <ManageBrandModal
        show={showManageModal}
        closeModal={closeManageModal}
        id={manageModalDataRef.current.id}
        mode={manageModalDataRef.current.mode}
      />

      <BusyLoader show={busyLoader} />
    </>
  );
};

export default memo(ListBrand);
