import { useAttachAdditionalData } from "@sk/hooks";
import { CommonService, DealService, NavService } from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  Rbac,
} from "@sk/uis";
import { debounce } from "lodash";

import { memo, useCallback, useEffect, useRef, useState } from "react";

import listView from "../constantService";

import SummaryCardBox from "./components/SummaryCardBox";

import Table from "./components/Table";

import AdvanceFilterModal from "./modals/AdvanceFilterModal";

import { useForm } from "react-hook-form";

import DealFilter from "./components/DealFilter";

const breadcrumbs = listView.breadcrumb;

const defaultFilterFormData = listView.filterFromData;

const defaultSummaryData = listView.summaryData;

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const attachAdditionalDataConfig = listView.additionalTableDataConfig;

const rbac = {
  createDeal: ["AddDeal"],
};

const defaultPaginationData = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

// To get Deals List
const getData = async (params) => {
  const r = await DealService.getDeals(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.categoryLoading = true;
        x.brandLoading = true;
        return x;
      })
    : [];
};

// Preparing Filter Params
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let d = filter || {};

  console.log("d", d);

  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      dealsFor: "erp",
    },
  };

  if (d.activeStatus) {
    p["filter"]["is_active"] = d.activeStatus === "Active" ? true : false;
  }

  if (d.dealStatus) {
    p["filter"]["status"] = d.dealStatus;
  }

  // Handle deal type
  if (d.dealType && d.dealType !== "all") {
    p["filter"]["dealType"] = d.dealType;
  }

  // Handle owned by
  if (d.ownedBy && d.ownedBy !== "all") {
    if (d.ownedBy === "thirdparty") {
      p["filter"]["isOwnedByThirdparty"] = true; // Set isOwnedByThirdparty to true
    } else {
      p["filter"]["isOwnedByThirdparty"] = false; // Set to false for SK
    }
  }

  const deal = d.dealSearch?.trim();
  const dealRegex = { $regex: deal, $options: "gi" };
  if (deal) {
    p["filter"]["$or"] = [{ _id: dealRegex }, { name: dealRegex }];
  }

  if (sort.key) {
    p.sort = sort.value === "desc" ? `-${sort.key}` : sort.key;
  }

  if (d.brand.length) {
    p.filter.brand = { $in: [d.brand[0].value._id] };
  }

  if (d.category.length) {
    p.filter.category = { $in: [d.category[0].value._id] };
  }

  return p;
};

const ListDeal = () => {
  // Custom Hook To Attach Additional Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [filterLabels, setFilterLabels] = useState([]);

  const [summaryCard, setSummaryCard] = useState(
    [...defaultSummaryData].map((x) => {
      let p = prepareFilterParams(
        defaultPaginationData,
        defaultFilterFormData,
        defaultSortOpt
      );
      if (p.filter) {
        delete p.filter?.status;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.defaultFilterParams },
      };
    })
  );

  // To select More Columns
  const [selectedMoreTblColumns, setSelectedMoreTblColumns] = useState(() => {
    let t = {};
    listView.moreTableColumns
      .filter((x) => x.checked)
      .forEach((x) => {
        t[x.key] = x;
      });
    return t;
  });

  // To apply More Columns on Table
  const onMoreTableColApply = useCallback((d) => {
    let t = {};
    (d.selected || []).forEach((x) => {
      t[x.key] = x;
    });
    setSelectedMoreTblColumns({ ...t });
  }, []);

  const { register, getValues } = useForm({
    defaultValues: listView.filterFromData,
  });

  //  REFs To Store Data
  const filterDataRef = useRef({ ...defaultFilterFormData });

  const paginationRef = useRef({ ...defaultPaginationData });

  const sortRef = useRef({ ...defaultSortOpt });

  useEffect(() => {
    init();
  }, [init]);

  // For Opening Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  // For Closing Advance Filter Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvFilterModal(false),
    []
  );

  // To Apply Filter
  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationData };

    loadList();
    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);

    const p = getFilterParams();
    delete p.filter.dealsFor;
    const c = await getCount(p);

    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  // Run Only For First Time
  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  //  To Load  List  and To attach Additional data
  const loadList = useCallback(async () => {
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);
    setLoadingData(false);
    setData(d);

    let tmp = [];
    // Attach User Info
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // Pagination Callback
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const getCount = async (params) => {
    delete params.count;
    delete params.page;
    try {
      const r = await DealService.getDealsCount(params);
      return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  };

  const filterFormCb = useCallback(() => {
    filterDataRef.current = { ...getValues() };
    paginationRef.current = { ...defaultPaginationData };
    applyFilter();
  }, [applyFilter, getValues]);

  const addDeal = () => {
    NavService.openInNewTab("/cms/deal/manage");
  };

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const onSearch = debounce(() => {
    filterFormCb();
  }, 700);

  const advFilterCb = (data) => {
    if (data.status == "applied" || data.status == "reset") {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      applyFilter();
    }
    closeAdvFilterModal(false);
  };

  const dealFilterCb = (data) => {
    if (data.action === "apply") {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      applyFilter();
    }
  };

  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.deal;

    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    console.log("l", l);
    setFilterLabels(l);
  }, []);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <div className="row my-3">
        <div className="col align-self-center">
          <PageInfo
            title="Deal List"
            breadcrumbs={breadcrumbs}
            noMargin={true}
          />
        </div>
        {/* <Rbac roles={rbac.createDeal}>
          <div className="col-auto">
            <button
              className="btn btn-primary btn-sm fs-val-md me-1"
              onClick={addDeal}
            >
              <span className="bi bi-plus-square me-1"></span>
              Create Deal
            </button>
          </div>
        </Rbac> */}
      </div>

      {/* Summary Card Overview */}
      <div className="row mb-2">
        {summaryCard.map((card, i) => (
          <div className="col-3" key={i}>
            <SummaryCardBox
              label={card.label}
              color={card.color}
              filterParams={card.filterParams}
              icon={card.icon}
            />
          </div>
        ))}
      </div>

      <DealFilter callback={dealFilterCb} filterData={filterDataRef.current} />

      {/* Advance Filter Modal */}
      <AdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />

      {/* Filter selected Label */}
      <AppCard>
        <div className="mb-3">
          {filterLabels.length > 0 ? (
            <AppliedFilterLabel labels={filterLabels} />
          ) : null}
        </div>

        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
        <Table
          data={data}
          loading={loadingData}
          sort={sortRef.current}
          sortCb={sortCb}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          moreColumns={selectedMoreTblColumns}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>
      {/* <BusyLoader show={loadingData} /> */}
    </>
  );
};

export default memo(ListDeal);

// <div className="col-auto my-2">
//   <button className="btn btn-primary btn-sm" onClick={addDeal}>
//     <span className="bi bi-plus-square me-1"></span>
//     Create Deal
//   </button>
// </div>;
