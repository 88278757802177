import { AuthService } from "@sk/services";

// LIST VIEW START
const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Deal List",
    },
  ],
  // Breadcrumbs Ends Here

  summaryData: [
    {
      label: "Total Count",
      value: 0,
      loading: true,
      filter: {},
      filterParams: {},
      defaultFilterParams: {},
      color: "info", // Updated color for total count
      icon: "bi bi-list", // Bootstrap icon for total count
    },
    {
      label: "Published Deals",
      value: 0,
      loading: true,
      filter: { status: "Publish" },
      filterParams: {},
      defaultFilterParams: { status: "Publish" },
      color: "success",
      icon: "bi bi-check-circle", // Bootstrap icon for published deals
    },
    {
      label: "Unpublished Deals",
      value: 0,
      loading: true,
      filter: { status: "Unpublish" },
      filterParams: {},
      defaultFilterParams: { status: "Unpublish" },
      color: "danger", // Updated color for unpublished deals
      icon: "bi bi-x-circle", // Bootstrap icon for unpublished deals
    },
    {
      label: "Draft Deals",
      value: 0,
      loading: true,
      filter: { status: "Draft" },
      filterParams: {},
      defaultFilterParams: { status: "Draft" },
      color: "warning",
      icon: "bi bi-file-earmark-text", // Bootstrap icon for draft deals
    },
  ],
  //Summary Card Data Ends Here

  additionalTableDataConfig: [
    {
      key: "brand",
      api: "brand",
      loadingKey: "brandLoading",
      dataKey: "_brand",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "category",
      api: "category",
      loadingKey: "categoryLoading",
      dataKey: "_category",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "_id",
      api: "dealStock",
      loadingKey: "stockLoading",
      dataKey: "dealStock",
      whId: AuthService.getDefaultWhId(),
      filter: (ids) => ({
        dealIds: ids.join(","),
      }),
      valuePath: "availableStock",
    },
    {
      key: "sourcingPartner",
      api: "seller",
      loadingKey: "sellerLoading",
      dataKey: "sellerName",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  // Additional Table Data Ends Here

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  // Pagination Ends Here

  formLabels: {
    status: {
      label: "Status",
    },
    is_active: {
      label: "Is Deal Active ?",
    },
    isOwnedByThirdparty: {
      label: "Is Own By SK ?",
    },
    brand: {
      label: "Brand",
      valuePath: "[0].value.name",
    },
    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },
    deal: {
      label: "Deal",
    },
    dealStatus: {
      label: "Deal Status",
    },
    dealType: {
      label: "Deal Type",
    },
    activeStatus: {
      label: "Active Status",
    },
    ownedBy: {
      label: "Owned By",
    },
  },
  // Form Labels Ends Here

  filterFromData: {
    deal: "",
    dealStatus: "Publish",
    is_active: "",
    isOwnedByThirdparty: "",
    brand: [],
    category: [],
    dealType: "",
    activeStatus: "Active",
  },
  // Filter From Data Ends Here
  moreTableColumns: [
    {
      label: "Category",
      checked: true,
      priority: 4,
      key: "category",
    },
    { label: "Brands", checked: true, priority: 5, key: "brand" },
    {
      label: "B2B Price",
      checked: false,
      priority: 7,
      key: "b2bPrice",
    },
    {
      label: "Quantity",
      isCentered: true,
      checked: false,
      priority: 8,
      key: "quantity",
    },
    {
      label: "MRP",
      isCentered: true,
      checked: false,
      priority: 9,
      key: "price",
    },
    {
      label: "Ships In",
      isCentered: true,
      checked: false,
      priority: 10,
      key: "shipIn",
    },
    {
      label: "Created on",
      isCentered: true,
      key: "createdAt",
      enableSort: true,
      checked: false,
      priority: 13,
    },
    {
      label: "Last Updated On",
      key: "lastUpdated",
      enableSort: true,
      checked: false,
      priority: 14,
    },
  ],
  //
};
// LIST VIEW EDS HERE

// FOR DETAIL VIEW START
export const detailsView = {
  tabs: {
    linkedDeals: {
      selectOptions: {
        catalogTypeOptions: [
          { label: "All", value: "" },
          { label: "SK", value: "sk" },
          { label: "Seller", value: "seller" },
        ],
        statusOptions: [
          { label: "All", value: "" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
        ],
      },
      // SelectOptions Ends
      filterFromData: {
        deal: "",
        category: [],
        status: "",
        catalogType: "",
      },
      // Filter Ends Here
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Deal ID" },
        { label: "Name", width: "30%" },
        { label: "Catalog Type", isCentered: true },
        { label: "Products" },
        { label: "Category/ID" },
        { label: "Status" },
        { label: "MRP" },
      ],
      // TableHeader Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 5,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 5,
      },
      // Pagination Ends Here
      additionalTableDataConfig: [
        {
          key: "category",
          api: "category",
          loadingKey: "categoryLoading",
          dataKey: "_category",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // additional Table Data
    },
    // Linked Deals End
    linkedProducts: {
      selectOptions: {
        statusOptions: [
          { label: "All", value: "" },
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
      },
      // SelectOptions Ends
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Product ID" },
        { label: "Name" },
        { label: "Category/ID" },
        { label: "Status" },
        { label: "Barcode" },
        { label: "MRP" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        product: "",
        category: [],
        status: "",
      },
      // Filter Form Data ends Here
      additionalTableDataConfig: [
        {
          key: "category",
          api: "category",
          loadingKey: "categoryLoading",
          dataKey: "_category",
          filter: (ids) => ({
            page: 1,
            count: ids.length,
            filter: { _id: { $in: ids } },
            select: "name",
          }),
        },
      ],
      // additional Table Data
    },
    // Linked Products Ends Here

    linkedVendors: {
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Vendor ID" },
        { label: "Vendor Name" },
        { label: "Linked Warehouses", width: "20%" },
        { label: "Location" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        vendor: "",
        whId: "",
      },
    },
    // Linked Vendors Here
    linkedOrders: {
      tableHeaders: [
        { label: "Sl No", width: "5%" },
        { label: "Order ID" },
        { label: "Ordered Date" },
        { label: "Amount", isCentered: true },
        { label: "Franchise Details" },
        { label: "Order Status" },
        { label: "Logistics Status" },
      ],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 5,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 15,
      },
      // Pagination Ends Here
      filterFormData: {
        order: "",
        createdAt: [],
      },
      // Filter Form Ends Here
    },
    // linked Orders Ends Here,
    brandMargin: {
      tableHeaders: [{ label: "" }],
      // Table Header Ends Here
      pagination: {
        totalRecords: 0,
        rowsPerPage: 10,
        activePage: 1,
        startSlNo: 1,
        endSlNo: 10,
      },
      // Pagination Ends Here
      filterFormData: {
        category: [],
      },
    },
  },
};
// DETAILS VIEW END

export const warehouseWiseView = {
  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "createdByLoading",
      dataKey: "_createdBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "modifiedBy",
      api: "user",
      loadingKey: "modifiedByLoading",
      dataKey: "_modifiedBy",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },

    {
      key: "whId",
      api: "franchise",
      loadingKey: "whLoading",
      dataKey: "_wh",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },

  formLabels: {
    dealType: {
      label: "Deal Type",
    },
    status: {
      label: "Status",
    },
    is_active: {
      label: "Is Deal Active ?",
    },
    isOwnedByThirdparty: {
      label: "Is Own By SK ?",
    },
    brand: {
      label: "Brand",
      valuePath: "[0].value.name",
    },
    category: {
      label: "Category",
      valuePath: "[0].value.name",
    },
  },
  // Form Labels Ends Here

  filterFromData: {
    deal: "",
    status: "",
    is_active: "",
    isOwnedByThirdparty: "",
    brand: [],
    category: [],
    dealType: "",
  },
};

export default listView;
