import { NoDataFound, TableHeader } from "@sk/uis";
import { memo } from "react";
import Modal from "react-bootstrap/Modal";
const style = {
  modalHeaderStyle: {
    backgroundColor: "#e4edff",
  },
};

const tableHeader = [{ label: "Sl.No" }, { label: "barcode" }];
const ViewBarcodeModal = ({ show, data = [], closeModal }) => {
  return (
    <Modal size="md" centered onHide={closeModal} show={show}>
      <Modal.Header
        closeButton
        closeVariant="white"
        style={style.modalHeaderStyle}
      >
        <Modal.Title className="fs-val-lg text-dark px-2">
          Barcode List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered bg-white">
          <TableHeader data={tableHeader} />

          <tbody>
            {data.length == 0 && (
              <tr>
                <td colSpan={tableHeader.length}>
                  <NoDataFound>No Barcode Found</NoDataFound>
                </td>
              </tr>
            )}
            {show &&
              data.length > 0 &&
              data.map((x, i) => (
                <tr key={i} className="fs-val-md">
                  <td>{i + 1}</td>
                  <td>{x}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ViewBarcodeModal);
