import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const rbac = {
  editStoreCombo: ["EditStoreCombo"],
  viewStoreCombo: ["ViewStoreCombo"],
  cloneStoreCombo: ["CloneStoreCombo"],
};

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "ID", width: "8%" },
  { label: "Store Details", width: "20%" },
  { label: "Deal Details", width: "15%" },
  { label: "Linked Deals", width: "8%" },
  { label: "Combo Price" },
  { label: "Status" },
  { label: "Last Updated On" },
  { label: "Action" },
];

function StoreComboTable({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  callback,
}) {
  const view = (id) => {
    callback({ action: "view", id });
  };

  const edit = (id) => {
    callback({ action: "edit", id });
  };

  const clone = (id) => {
    callback({ action: "clone", id });
  };

  return (
    <>
      <div className="mb-1">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {loading ? (
            <TableSkeletonLoader cols={headers.length} rows={10} height={40} />
          ) : null}

          {data.map((x, index) => (
            <tr key={x._id} className="fs-val-md">
              <td>{paginationConfig.startSlNo + index}</td>
              <td>{x._id}</td>
              <td>
                <div className="text-primary mb-1">{x.franchiseName}</div>
                <div className="fs-val-sm text-muted">
                  <span>ID:</span>
                  <span> {x.franchiseId} </span>
                </div>
              </td>
              <td>
                <div className="mb-1">{x.comboDealName}</div>
                <div className="fs-val-sm text-muted">
                  <span>ID:</span>
                  <span> {x.comboDealId} </span>
                </div>
              </td>
              <td>
                {x._linkedDeals || 0} <span className="text-muted">deals</span>
              </td>
              <td className="text-danger">
                {x.discountType == "Fixed" ? (
                  <Amount value={x.discountValue || 0} decimalPlace={2} />
                ) : (
                  <>{x.discountValue || 0}%</>
                )}
              </td>
              <td>
                <HighlightText status={x.isActive ? "Active" : "InActive"} />
              </td>
              <td>
                <DateFormatter date={x.lastUpdated} />
              </td>
              <td>
                <Rbac roles={rbac.editStoreCombo}>
                  <button
                    className="btn btn-outline-danger text-uppercase btn-sm me-2 fs-val-sm"
                    onClick={() => edit(x._id)}
                  >
                    Edit
                  </button>
                </Rbac>

                <Rbac roles={rbac.viewStoreCombo}>
                  <button
                    className="btn btn-outline-primary text-uppercase btn-sm me-2 fs-val-sm"
                    onClick={() => view(x._id)}
                  >
                    View
                  </button>
                </Rbac>

                <Rbac roles={rbac.cloneStoreCombo}>
                  <button
                    className="btn btn-outline-info text-uppercase btn-sm fs-val-sm"
                    onClick={() => clone(x._id)}
                  >
                    Clone
                  </button>
                </Rbac>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
}

export default StoreComboTable;
