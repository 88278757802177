import { BooleanCheckboxInput, RadioInput } from "@sk/uis";
import { memo } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const canvasStyle = { width: "30%" };

const yesNoOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const AdvanceFilterModal = ({ show, closeModal }) => {
  const { handleSubmit, reset, control, getValues } = useForm({
    defaultValues: {
      invoiceOnOrderAmount: "",
      isExclusiveType: "",
      status: "",
      classGroup: [],
      displayDealMrp: "",
      isFocusedCategory: "",
      noReturnsAllowed: "",
      isDailyNeeds: "",
      hideUnlinkedCategories: false,
    },
  });

  const onSubmit = (d) => {
    closeModal({ formData: d, status: "submit" });
  };

  const resetForm = () => {
    reset();
    closeModal({ formData: getValues(), status: "reset" });
  };

  // const onClassGroupSearch = useCallback(async (val, callback) => {
  //   let filter = {};

  //   if (val) {
  //     filter = { name: { $regex: val, $options: "i" }, deleted: false };
  //   }

  //   let p = {
  //     page: 1,
  //     count: 10,
  //     select: "name,_id",
  //     filter: filter,
  //   };

  //   const r = await UtilityService.getClassGroupList(p);
  //   callback((r?.resp || []).map((x) => ({ label: x.name, value: x._id })));
  // }, []);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => {
          closeModal({ status: "close" });
        }}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>
            <div className="page-title"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-4  border-top">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {/* STATUS */}
              <div className="col-12 mb-4">
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Status"
                      gap={1}
                      name="status"
                      options={statusOptions}
                      isMandatory={false}
                      value={value}
                      cols="3"
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              {/* STICK ORDER */}
              <div className="col-12 mb-4 pt-1">
                <Controller
                  control={control}
                  name="invoiceOnOrderAmount"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Invoice On Order Amount"
                      gap={1}
                      name="invoiceOnOrderAmount"
                      options={yesNoOptions}
                      isMandatory={false}
                      value={value}
                      cols="3"
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              {/* Is Focused Category */}
              <div className="col-12 mb-4 pt-1">
                <Controller
                  control={control}
                  name="isFocusedCategory"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Is Focused Category"
                      gap={1}
                      name="isFocusedCategory"
                      options={yesNoOptions}
                      isMandatory={false}
                      value={value}
                      onChange={onChange}
                      cols="3"
                    />
                  )}
                />
              </div>

              {/* Is Daily Needs */}
              <div className="col-12 mb-4 pt-1">
                <Controller
                  control={control}
                  name="isDailyNeeds"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Is Daily Needs"
                      gap={1}
                      name="isDailyNeeds"
                      options={yesNoOptions}
                      isMandatory={false}
                      value={value}
                      onChange={onChange}
                      cols="3"
                    />
                  )}
                />
              </div>

              {/* Display Deal Mrp */}
              <div className="col-12 mb-4 pt-1">
                <Controller
                  control={control}
                  name="displayDealMrp"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Display Deal MRP"
                      gap={1}
                      name="displayDealMrp"
                      options={yesNoOptions}
                      isMandatory={false}
                      value={value}
                      onChange={onChange}
                      cols="3"
                    />
                  )}
                />
              </div>

              {/* Return Allowed */}
              <div className="col-12 mb-4 pt-1">
                <Controller
                  control={control}
                  name="noReturnsAllowed"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Returns Allowed "
                      gap={1}
                      name="noReturnsAllowed"
                      options={yesNoOptions}
                      isMandatory={false}
                      value={value}
                      onChange={onChange}
                      cols="3"
                    />
                  )}
                />
              </div>

              {/* Show Only Unlinked Categories */}
              <div className="col-12">
                <Controller
                  control={control}
                  name="hideUnlinkedCategories"
                  render={({ field: { onChange, value } }) => (
                    <BooleanCheckboxInput
                      label="Show Only Not Linked Products?"
                      name="hideUnlinkedCategories"
                      isMandatory={false}
                      callback={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            </div>
          </form>
          {/* Action Buttons */}
          <div className="row mt-3">
            <div className="col-auto ms-auto">
              <button
                className="btn btn-warning me-3 px-4 fw-semibold"
                onClick={resetForm}
              >
                Reset
              </button>

              <button
                className="btn btn-primary px-4 fw-semibold"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(AdvanceFilterModal);
