import { BrandService, VendorService } from "@sk/services";
import {
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import debounce from "lodash/debounce";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { detailsView } from "../../../constantService";

// Default Form Data
const filterFormData = detailsView.tabs.linkedVendors.filterFormData;

// Default Table Header
const tableHeaders = detailsView.tabs.linkedVendors.tableHeaders;

const getData = async (params) => {
  const r = await VendorService.getVendors(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.categoryLoading = true;
        return x;
      })
    : [];
};

const prepareFilterParams = (
  pagination = {},
  filter = {},
  additionalData = {},
  brandData = []
) => {
  let p = {
    count: pagination.rowsPerPage,
    page: pagination.activePage,
    filter: {
      "margins.category": additionalData.id,
      "margins.brand": { $in: brandData.map((e) => e._id) },
    },
  };

  const vendor = filter.vendor.trim();

  const vendorRegex = { $regex: vendor, $options: "gi" };

  if (vendor) {
    p.filter["$or"] = [{ name: vendorRegex }, { _id: vendorRegex }];
  }
  return p;
};

const LinkedVendors = ({ categoryId }) => {
  // For Filter
  const { register, getValues } = useForm({
    defaultValues: filterFormData,
  });

  // Loading State when We are calling API
  const [loading, setLoading] = useState(true);

  // Loading State Form Total Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Data From The API
  const [list, setList] = useState([]);

  // To Show Advance Filer

  // Pagination Config
  const paginationConfigRef = useRef({
    ...detailsView.tabs.linkedVendors.pagination,
  });

  // filer Data Ref
  const filterDataRef = useRef({ ...filterFormData });

  // Brand Ids
  const brandsRef = useRef([]);

  useEffect(() => {
    if (categoryId) {
      loadBrands();
    } else {
      setLoading(false);
      setList([]);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, categoryId, loadBrands]);

  // Fetching Brand  List to get brand IDs for Vendor API
  const loadBrands = useCallback(async () => {
    setLoading(true);
    let p = {
      page: 1,
      count: 100,
      filter: { category: { $elemMatch: { $eq: categoryId } } },
      select: "name",
    };
    let s = await BrandService.getList(p);
    brandsRef.current = Array.isArray(s.resp) ? s.resp : [];
    applyFilter();
  }, [applyFilter, categoryId]);

  // Apply Filter Call
  const applyFilter = useCallback(() => {
    filterDataRef.current = { ...getValues() };

    // To Get Count
    loadCount();

    // To loadList
    loadDealList();
  }, [loadCount, loadDealList, getValues]);

  // To Get the Count of All
  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    let p = getFilterParams();
    delete p.count;
    delete p.page;
    delete p.select;
    delete p.filter.dealsFor;

    let m = await VendorService.getCount(p);

    // Setting total Count
    paginationConfigRef.current.totalRecords = m.resp ? m.resp : 0;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  // To get List of All Lined Deals
  const loadDealList = useCallback(async () => {
    setLoading(true);

    let p = getFilterParams();

    let d = await getData(p);
    setList(d);

    setLoading(false);
  }, [getFilterParams]);

  // Pagination Cb
  const paginationCb = useCallback(
    (d) => {
      paginationConfigRef.current.activePage = d.activePage;
      paginationConfigRef.current.startSlNo = d.startSlNo;
      paginationConfigRef.current.endSlNo = d.endSlNo;
      loadDealList();
    },
    [loadDealList]
  );

  // // navigate To View Deal Page
  const viewVendor = (i) => {
    // NavService.openInNewTab("/cms/deal/view", { id: i });
  };

  // SearchCb for Deal
  const searchCb = debounce(() => {
    applyFilter();
  }, 700);

  // const resetFromData = () => {
  //   // Filter For Data
  //   reset(filterFormData);
  //   applyFilter();
  // };

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationConfigRef.current,
      filterDataRef.current,
      {
        id: categoryId,
      },
      brandsRef.current
    );
  }, [categoryId]);

  return (
    <>
      <div className="row">
        {/* Deal Name */}
        <div className="col-3">
          <TextInput
            type="text"
            name="vendor"
            label="Search for Vendor"
            register={register}
            callback={searchCb}
            placeholder="Search By Vendor Name /ID"
          />
        </div>
        {/* <div className="col-auto ms-auto align-self-center">
          <button
            className="btn btn-sm btn-primary me-1 fs-val-md"
            onClick={applyFilter}
          >
            Apply
          </button>
          <button
            className="btn btn-sm btn-outline-warning fs-val-md"
            onClick={resetFromData}
          >
            Reset
          </button>
        </div> */}
      </div>
      <div>
        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationConfigRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>

        <table className="table table-bordered">
          <TableHeader data={tableHeaders} />
          <tbody>
            {/* When Page Loading */}

            {loading && (
              <TableSkeletonLoader rows={5} cols={tableHeaders.length} />
            )}

            {/*  When There is No Data */}
            {!loading && !list.length ? (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {/* When there is Data */}
            {!loading &&
              (list || []).map((x, index) => (
                <tr key={index + x._id} className="fs-val-md">
                  <td>{paginationConfigRef.current.startSlNo + index}</td>

                  {/* ID  */}
                  <td>{x?._id}</td>

                  {/* Deal Name */}
                  <td>
                    <div
                      className="text-primary"
                      role="button"
                      tabIndex={-1}
                      onClick={() => {
                        viewVendor(x?._id);
                      }}
                    >
                      {x?.name}
                    </div>
                  </td>
                  {/* Address  */}
                  <td>
                    {x?.city}, {x?.district}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfigRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(LinkedVendors);
