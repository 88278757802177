import { NavService } from "@sk/services";
import {
  Amount,
  BtnLink,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

const headers = [
  { label: "Sl.No", width: "3%", priority: 1 },
  { label: "ID", width: "8%", priority: 2 },
  { label: "Name", width: "20%", priority: 3 },
  { label: "Status", width: "8%", priority: 4 },
  { label: "Is Active", width: "8%", priority: 4.1 },
  { label: "Type", width: "6%", priority: 4.2 },
  { label: "Brand", width: "10%", priority: 5 },
  { label: "Category", width: "10%", priority: 6 },
  { label: "MRP", width: "6%", priority: 9, enableSort: true, key: "mrp" },
  {
    label: "B2B Price",
    width: "7%",
    priority: 7,
    enableSort: true,
    key: "b2bPrice",
  },
  {
    label: "Max. Qty",
    width: "6%",
    priority: 8,
    enableSort: true,
    key: "maxB2BQty",
  },
  { label: "Ships In", width: "6%", priority: 10 },
  {
    label: "Seller",
    width: "10%",
    priority: 11,
  },
  {
    label: "Created At",
    width: "12%",
    priority: 13,
    enableSort: true,
    key: "createdAt",
  },
  {
    label: "Last Updated",
    width: "12%",
    priority: 14,
    enableSort: true,
    key: "lastUpdated",
  },
  { label: "Actions", width: "10%", priority: 15 },
];

const containerStyle = {
  maxHeight: "600px",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "2000px",
};

const Table = ({
  data,
  sort,
  sortCb,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  const navigate = useNavigate();

  const viewDetails = (id) => {
    NavService.openInNewTab("/cms/deal/view", { id: id });
  };

  const editDetails = (id) => {
    NavService.openInNewTab("/cms/deal/manage", { id: id });
  };

  return (
    <>
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table mb-3"
        style={containerStyle}
      >
        <table className="table table-sm table-hover" style={tableStyle}>
          <TableHeader
            data={headers}
            sort={sort}
            sortCb={sortCb}
            noBg
            isSticky={true}
          />
          <tbody>
            {loading && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound> No Deals Found </NoDataFound>
                </td>
              </tr>
            ) : null}
            {!loading &&
              data.map((x, index) => (
                <tr key={index} className="fs-val-md">
                  <td className="text-center">
                    {paginationConfig.startSlNo + index}
                  </td>
                  <td>{x._id}</td>
                  <td>
                    <BtnLink
                      className="fs-val-md text-wrap"
                      onClick={() => {
                        viewDetails(x?._id);
                      }}
                    >
                      {x?.name || "N/A"}
                    </BtnLink>
                  </td>
                  <td>
                    <HighlightText
                      status={x.status}
                      type={x.status === "Publish" ? "success" : "danger"}
                      template={2}
                    >
                      {x.status}
                    </HighlightText>
                  </td>
                  <td>
                    <HighlightText
                      type={x.is_active ? "success" : "danger"}
                      template={2}
                    >
                      {x.is_active ? "Active" : "Inactive"}
                    </HighlightText>
                  </td>
                  <td>{x.dealType || "--"}</td>
                  <td>
                    {x.brandLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      <BtnLink
                        className="fs-val-md text-wrap"
                        onClick={() =>
                          NavService.navToBrand(navigate, {
                            id: x._brand?.[0]._id,
                          })
                        }
                      >
                        {x?._brand?.[0]?.name || "N/A"}
                      </BtnLink>
                    )}
                  </td>
                  <td>
                    {x.categoryLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      <BtnLink
                        className="fs-val-md text-wrap"
                        onClick={() =>
                          NavService.navToCategory(navigate, {
                            id: x._category?.[0]._id,
                          })
                        }
                      >
                        {x?._category?.[0]?.name || "N/A"}
                      </BtnLink>
                    )}
                  </td>
                  <td>{<Amount value={x.mrp} />}</td>
                  <td>{<Amount value={x.b2bPrice} decimalPlace={2} />}</td>
                  <td>{x.maxB2BQty || 0}</td>
                  <td>{x.shipsIn}</td>
                  <td>
                    <div className="text-wrap">
                      {x?.thirdPartySellerCreatorName
                        ? x?.thirdPartySellerCreatorName
                        : x?.sourcingPartner == "MPS0"
                        ? "Storeking"
                        : "NA"}
                    </div>
                  </td>
                  <td>
                    <DateFormatter date={x.createdAt} />
                  </td>
                  <td>
                    <DateFormatter date={x.lastUpdated} />
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-outline-primary me-1 fs-val-xs"
                      onClick={() => {
                        viewDetails(x?._id);
                      }}
                    >
                      View
                    </button>
                    {/* <button
                      className="btn btn-sm btn-outline-warning me-1 fs-val-xs"
                      onClick={() => {
                        editDetails(x?._id);
                      }}
                    >
                      Edit
                    </button> */}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default memo(Table);
