import { yupResolver } from "@hookform/resolvers/yup";
import { CommonService, ProductService } from "@sk/services";
import { Spinner, TextInput, Toaster } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const style = {
  modalHeaderStyle: {
    backgroundColor: "#e4edff",
  },
};

const defaultFormData = {
  productBarcode: "",
  mrp: "",
};

const barcodeValidationSchema = yup.object({
  productBarcode: yup.string().trim().required("Barcode is required"),
  mrp: yup
    .number()
    .typeError("Mrp is Requred")
    .required("Mrp is Required")
    .min(0, "Mrp cannot be Less then 0"),
});

const ManageBarcodeModal = ({ show, data, mode, closeModal, pId }) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormData,
    resolver: yupResolver(barcodeValidationSchema),
  });

  useEffect(() => {
    if (!show) {
      resetFormData();
      return;
    }

    if (show && !pId) {
      Toaster.error("Something Went Wrong Please Try again");
      triggerCloseModal();
      return;
    }

    if (show && mode == "edit") {
      loadFormValues();
      return;
    }
  }, [show, pId, resetFormData, triggerCloseModal, loadFormValues, mode]);

  const onSubmit = async (d) => {
    setLoading(true);
    const p = preparePayload(d);
    let r = {};
    if (mode == "add") {
      r = await ProductService.createMasterBarcode(p);
    } else {
      r = await ProductService.updateMasterBarcode(data._id, p);
    }

    setLoading(false);

    // If Something Went Wrong
    if (r.statusCode !== 200) {
      Toaster.error(r.resp.message || "Something Went Wrong");
      return;
    }
    Toaster.success("Barcode added Successfully");

    triggerCloseModal("submit");
  };

  const preparePayload = (d) => {
    const p = {
      ...d,
      productId: pId,
    };
    if (mode == "add") {
      p._id = data._id;
      p.status == data.status;
    }
    return p;
  };

  const resetFormData = useCallback(() => {
    reset(defaultFormData);
  }, [reset]);

  const onInputChange = useCallback(
    debounce((name) => {
      let value = getValues(name) * 1;

      //    If It is MRP we want it to be In decimal
      if (name == "mrp") {
        value = CommonService.roundedByDecimalPlace(value, 2);
      }
      if (value < 0) {
        value = 0;
      }

      setValue(name, value);
    }, 700),
    [setValue, getValues]
  );

  const triggerCloseModal = useCallback(
    (status) => {
      closeModal({ status });
    },
    [closeModal]
  );

  const loadFormValues = useCallback(() => {
    Object.keys(defaultFormData).forEach((key) => {
      setValue(key, data[key]);
    });
  }, [data, setValue]);

  return (
    <Modal
      backdrop="static"
      size="md"
      show={show}
      onHide={triggerCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={style.modalHeaderStyle}
      >
        <Modal.Title className="fs-val-lg text-dark px-2">
          {mode === "edit"
            ? "Edit Master Barcode" + `  #${data._id}`
            : "Add Master Barcode"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <TextInput
                register={register}
                error={errors?.productBarcode?.message}
                label="Barcode"
                name="productBarcode"
                placeholder="Enter Barcode here"
              />
            </div>
            <div className="col-12">
              <TextInput
                register={register}
                callback={() => onInputChange("mrp")}
                label="MRP"
                error={errors?.mrp?.message}
                name="mrp"
                placeholder="Enter MRP Here"
              />
            </div>
            <div className="col-12 text-end">
              <button className="btn btn-primary fs-val-md" disabled={loading}>
                Submit {loading && <Spinner isSmall={loading} />}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ManageBarcodeModal);
