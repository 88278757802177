import { useAttachAdditionalData } from "@sk/hooks";
import { NavService, VendorService } from "@sk/services";
import {
  DateFormatter,
  EntitySearchInput,
  NoDataFound,
  PageLoader,
  PaginationBlock,
  PaginationSummary,
  Spinner,
  TableHeader,
  TextInput,
} from "@sk/uis";
import debounce from "lodash/debounce";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { detailsView } from "../../../constantService";

// Default Form Data
const filterFormData = detailsView.tabs.brandMargin.filterFromData;

// Default Table Header
const tableHeaders = detailsView.tabs.brandMargin.tableHeaders;

const getData = async (params) => {
  const r = await VendorService.getVendors(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        return x;
      })
    : [];
};

const prepareFilterParams = (
  pagination = {},
  filter = {},
  additionalData = {}
) => {
  let p = {
    count: pagination.rowsPerPage,
    page: pagination.activePage,
    filter: {
      margins: {
        $elemMatch: { brand: additionalData.id },
      },
    },
  };

  const vendor = filter?.vendor?.length ? filter.vendor.trim() : "";

  const vendorRegex = { $regex: vendor, $options: "gi" };

  if (vendor) {
    p.filter["$or"] = [{ name: vendorRegex }, { _id: vendorRegex }];
  }

  if (filter.status) {
    p.filter.is_active = filter.status == "active";
  }

  if (filter.catalogType) {
    p.filter.isOwnedByThirdparty = filter.catalogType == "sk" ? false : true;
  }

  if (filter.category?.length) {
    p.filter.margins.$elemMatch.category = filter.category[0].value._id;
  }
  return p;
};

const BrandMargin = ({ brandId }) => {
  // For Filter
  const { register, getValues, control, reset } = useForm({
    defaultValues: filterFormData,
  });

  // Loading State when We are calling API
  const [loading, setLoading] = useState(true);

  // Loading State Form Total Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Data From The API
  const [list, setList] = useState([]);

  // To Show Advance Filer

  // Pagination Config
  const paginationConfigRef = useRef({
    ...detailsView.tabs.brandMargin.pagination,
  });

  // filer Data Ref
  const filterDataRef = useRef({ ...filterFormData });

  // saving category id from data to idRef
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (brandId) {
      applyFilter();
    } else {
      setLoading(false);
      setList([]);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, brandId]);

  const applyFilter = useCallback(() => {
    filterDataRef.current = { ...getValues() };

    // To Get Count
    loadCount();

    // To loadList
    loadList();
  }, [loadCount, loadList, getValues]);

  // To Get the Count of All
  const loadCount = useCallback(async () => {
    setLoadingTotalRecords(true);
    let p = getFilterParams();
    delete p.count;
    delete p.page;
    delete p.select;

    let m = await VendorService.getCount(p);

    // Setting total Count
    paginationConfigRef.current.totalRecords = m.resp ? m.resp : 0;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  // To get List of All Lined vendors
  const loadList = useCallback(async () => {
    setLoading(true);

    let p = getFilterParams();

    let d = await getData(p);

    d.forEach((e) => {
      let bm = e.margins.filter((k) => {
        return k.brand == brandId;
      });
      e._brandMargin = bm.length ? bm[0] : {};
      e._brandMarginCatLoading = true;
      e._brandMarginCatId = bm.length ? bm[0]?.category : "";
    });

    let tmp = [];
    setAdditionalData(
      d,
      detailsView.tabs.brandMargin.additionalTableDataConfig,
      (x) => {
        tmp.push(x);
        if (
          tmp.length ==
          detailsView.tabs.brandMargin.additionalTableDataConfig.length
        ) {
          setList([...attachAllData(d, tmp)]);
        }
      }
    );

    setList(d);

    setLoading(false);
  }, [getFilterParams]);

  // Pagination Cb
  const paginationCb = useCallback(
    (d) => {
      paginationConfigRef.current.activePage = d.activePage;
      paginationConfigRef.current.startSlNo = d.startSlNo;
      paginationConfigRef.current.endSlNo = d.endSlNo;
      loadList();
    },
    [loadList]
  );

  // SearchCb for vendor
  const searchCb = debounce(() => {
    applyFilter();
  }, 700);

  const resetFromData = () => {
    // Filter For Data
    reset(filterFormData);
    applyFilter();
  };

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationConfigRef.current,
      filterDataRef.current,
      {
        id: brandId,
      }
    );
  }, [brandId]);

  // navigate To View Deal Page
  const viewVendor = (id) => {
    NavService.navToVendor({ id: id });
  };

  const viewCategory = (id) => {
    NavService.openInNewTab("/cms/category/view", { id });
  };

  return (
    <>
      <div className="row">
        {/* vendor Name */}
        <div className="col-3">
          <TextInput
            type="text"
            name="vendor"
            label="Search for Vendor"
            register={register}
            callback={searchCb}
            placeholder="Search By Vendor Name /ID"
          />
        </div>

        {/* Category */}
        <div className="col-3">
          <Controller
            control={control}
            name="category"
            render={({ field: { onChange, value } }) => (
              <EntitySearchInput
                type={"category"}
                label="Search for Category"
                placeholder="Search By Name/ID"
                isMandatory={false}
                value={value}
                callback={onChange}
                uid="category-search"
                isMultiple={false}
              />
            )}
          />
        </div>
        <div className="col-auto ms-auto align-self-center">
          <button
            className="btn btn-sm btn-primary me-1 fs-val-md"
            onClick={applyFilter}
          >
            Apply
          </button>
          <button
            className="btn btn-sm btn-outline-warning fs-val-md"
            onClick={resetFromData}
          >
            Reset
          </button>
        </div>
      </div>

      <div>
        {/* PAGINATION SUMMARY */}
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationConfigRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>

        <table className="table table-bordered">
          <TableHeader data={tableHeaders} />
          <tbody>
            {/* When Page Loading */}

            {loading && (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <PageLoader />
                </td>
              </tr>
            )}

            {/*  When There is No Data */}
            {!loading && !list.length ? (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {/* When there is Data */}
            {!loading &&
              (list || []).map((x, index) => (
                <tr key={index + x._id} className="fs-val-md">
                  <td>{paginationConfigRef.current.startSlNo + index}</td>

                  {/* ID  */}
                  <td>
                    <div
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        viewVendor(x._id);
                      }}
                    >
                      {x?._id}
                    </div>
                  </td>

                  <td>
                    <div
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        viewVendor(x._id);
                      }}
                    >
                      {x?.name}
                    </div>
                  </td>

                  {/* Category  */}
                  <td>
                    <div>
                      {x?._brandMarginCatLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        <div
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            viewCategory(x?._brandMarginCat?._id);
                          }}
                        >
                          {x?._brandMarginCat?._id}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>
                      {x?._brandMarginCatLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        <div
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            viewCategory(x?._brandMarginCat?._id);
                          }}
                        >
                          {x?._brandMarginCat?.name}
                        </div>
                      )}
                    </div>
                  </td>

                  {/* Margin */}
                  <td className="text-center">
                    {x?._brandMargin?.margin || "N/A"}
                  </td>

                  {/* Start Date */}
                  <td className="text-center">
                    <DateFormatter
                      date={x?._brandMargin?.start_date}
                      format="dd MMM yyyy"
                    />
                  </td>

                  {/* End Date */}
                  <td className="text-center">
                    <DateFormatter
                      date={x?._brandMargin?.end_date}
                      format="dd MMM yyyy"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfigRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(BrandMargin);
