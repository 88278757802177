import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";

import { memo } from "react";

const headers = [
  { label: "Sl.No." },
  { label: "Ordered On" },
  { label: "Order ID" },
  // { label: "Franchise" },
  { label: "Order Amount " },
  { label: "MRP" },
  { label: "B2BPrice" },
  { label: "Shipping Charge" },
  { label: "Quantity" },
  // { label: "Payment Details" },
  { label: "Status" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
  dId,
}) => {
  return (
    <>
      <table className="table table-bordered">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {data.map((x, i) => (
            <tr key={x._id} className="fs-val-normal">
              <td>{paginationConfig.startSlNo + i}</td>
              <td>
                <DateFormatter date={x.date} />
              </td>
              <td>
                <div className=" fw-semibold">{x._id}</div>
                <div className="fs-val-sm text-muted">
                  Type : {x.typeOfOrder}
                </div>
              </td>
              {/* <td>
                <div className=" fw-semibold">{x.franchise.name}</div>
                <div className=" fs-val-sm text-muted">
                  {" "}
                  ID : {x.franchise.name}
                </div>
              </td> */}
              <td>
                <Amount value={x.orderAmount} />
                <div className="text-muted fs-val-sm">{x.paymentMode}</div>
              </td>
              {/* calculation MRP Price */}
              <td className="text-center">
                <Amount
                  value={x.subOrders[0].products.reduce(
                    (acc, x) => (x.id == x.dId ? acc + x.mrp : acc),
                    0
                  )}
                />
              </td>

              {/* Calculate B2B Price */}
              <td className="text-center">
                <Amount
                  value={x.subOrders[0].products.reduce(
                    (acc, x) => (x.id == dId ? acc + x.price : acc),
                    0
                  )}
                />
              </td>
              <td> 0</td>

              {/* calculation Total Quantity */}
              <td className="text-center">
                {x.subOrders[0].products.reduce(
                  (acc, x) => acc + x.quantity,
                  0
                )}
              </td>
              {/* <td>
                <HighlightText status={x.paymentStatus} />
              </td> */}
              <td>
                <HighlightText status={x.status} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
