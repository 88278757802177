import {
  Amount,
  DateFormatter,
  NoDataFound,
  PaginationBlock,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import each from "lodash/each";
import orderBy from "lodash/orderBy";

import { memo, useEffect, useState } from "react";

import UpdateExpiryModal from "../modals/UpdateExpiryModal";
import ViewImeiNoModal from "../modals/ViewImeiNoModal";

const defaultHeaders = [
  { label: "Sl.No.", width: "5%", priority: 1 },
  { label: "Stock", key: "stock", checked: true, priority: 2 },
  { label: "On Hold", key: "onHold", checked: true, priority: 3 },
  { label: "Mrp", key: "mrp", checked: true, priority: 4 },
  { label: "Cost", key: "cost", checked: true, priority: 5 },
  { label: "Location", key: "location", checked: true, priority: 11 },
  { label: "Area", key: "area", checked: true, priority: 12 },
  { label: "IMEI/SlNo", key: "imei", checked: true, priority: 15 },
  { label: "remarks", key: "remarks", checked: true, priority: 16 },
  { label: "Action", key: "action", checked: true, priority: 17 },
];

const rbac = {
  editButton: ["EditProduct"],
};

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
  moreColumns = {},
  expiryDateCb,
}) => {
  const [headers, setHeaders] = useState([...defaultHeaders]);

  const [updateExpiryModal, setUpdateExpiryModal] = useState({
    show: "",
    data: {},
  });

  const [viewImeiModal, setViewImeiModal] = useState({
    show: "",
    data: {},
  });

  useEffect(() => {
    let t = [];
    each(moreColumns || {}, (x) => {
      t.push(x);
    });
    setHeaders(orderBy([...defaultHeaders, ...t], ["priority"], ["asc"]));
  }, [moreColumns]);

  const addExpiry = (x) => {
    setUpdateExpiryModal({ show: true, data: x });
  };

  const expiryModalCb = (d) => {
    setUpdateExpiryModal({ show: false, data: {} });
    if (d?.status == "submit") {
      expiryDateCb(d);
    }
  };

  const viewImeiNoCb = (x) => {
    setViewImeiModal({ show: true, data: x });
  };

  const closeImeiNoCb = () => {
    setViewImeiModal({ show: false, data: {} });
  };

  return (
    <>
      <table className="table table-bordered bg-white ">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, i) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + i}</td>
                <td>{x.quantity}</td>
                <td>{x.onHold}</td>
                <td>
                  <Amount value={x.mrp} decimalPlace={2} />
                </td>
                <td>
                  <Amount value={x.purchasePrice} decimalPlace={2} />
                </td>
                {moreColumns?.offer?.checked && <td>{x.offer || "N/A"}</td>}

                {moreColumns?.referenceType?.checked && (
                  <td>{Object.keys(x.ref)}</td>
                )}

                {moreColumns?.refId?.checked && (
                  <td>{x.ref[Object.keys(x.ref)]}</td>
                )}

                {moreColumns?.createdOn?.checked && (
                  <td>
                    <DateFormatter date={x.createdAt} />
                  </td>
                )}

                {moreColumns?.manufactureDate?.checked && (
                  <td>
                    <DateFormatter date={x.manufactureDate} />
                  </td>
                )}

                {moreColumns?.expiryDate?.checked && (
                  <td>
                    <DateFormatter date={x.shelfLife} />
                  </td>
                )}

                <td>{x.location}</td>

                <td>{x.area}</td>

                {moreColumns?.rack?.checked && <td>{x.rackId}</td>}

                {moreColumns?.bin?.checked && <td>{x.binId}</td>}

                <td>
                  {x?.serialNo?.length > 0 ? (
                    <div className="mb-2">
                      <button
                        className="btn btn-sm btn-primary fs-val-xs"
                        onClick={() => {
                          viewImeiNoCb(x);
                        }}
                      >
                        View IMEI No
                      </button>
                    </div>
                  ) : null}
                  {x.barcode}
                </td>

                <td>{x.remarks || "N/A"}</td>
                <td>
                  {x?.quantity > 0 ? (
                    <Rbac roles={rbac.editButton}>
                      <button
                        className="btn btn-sm btn-outline-warning me-1 fs-val-xs"
                        onClick={() => {
                          addExpiry(x);
                        }}
                      >
                        Update Expiry
                      </button>
                    </Rbac>
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <UpdateExpiryModal
        data={updateExpiryModal.data}
        show={updateExpiryModal.show}
        closeModal={expiryModalCb}
      />

      <ViewImeiNoModal
        data={viewImeiModal.data}
        show={viewImeiModal.show}
        closeModal={closeImeiNoCb}
      />
    </>
  );
};

export default memo(Table);
