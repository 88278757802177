import {
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo } from "react";

const headers = [
  { label: "Sl.No.", width: "5%" },
  { label: "Warehouse" },
  { label: "Product Name" },
  { label: "Open Order Qty" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  return (
    <>
      <table className="table table-bordered">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {!loading &&
            data.map((x, index) => (
              <tr key={index} className="fs-val-normal">
                <td>{paginationConfig.startSlNo + index}</td>
                <td>
                  {x.warehouseLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    x._warehouse.name
                  )}
                </td>
                <td>{x.productName}</td>
                <td>{x.openOrderQty}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
