import {
  NoDataFound,
  PaginationBlock,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

import { memo } from "react";

const headers = [
  { label: "Sl.No.", width: "5%", priority: 1 },
  { label: "IMEI", key: "stock", checked: true, priority: 2 },
  { label: "Invoice No.", key: "onHold", checked: true, priority: 3 },
  { label: "Rack", key: "mrp", checked: true, priority: 4 },
  { label: "Bin", key: "cost", checked: true, priority: 5 },
  { label: "Location", key: "location", checked: true, priority: 6 },
  { label: "Status", key: "area", checked: true, priority: 7 },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  return (
    <>
      <table className="table table-bordered bg-white ">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading &&
            data.map((x, i) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationConfig.startSlNo + i}</td>
                <td>{x.imeiNo}</td>
                <td>{x.InvoiceNo ?? "N/A"}</td>
                <td>{x.rackId || "N/A"}</td>
                <td>{x.binId || "N/A"}</td>
                <td>{x.location || "N/A"}</td>
                <td>{x.status}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
