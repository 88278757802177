import {
  appConfigs,
  CommonService,
  DealService,
  FranchiseService,
} from "@sk/services";
import {
  Alert,
  Amount,
  AppCard,
  AppTitle,
  BooleanCheckboxInput,
  EntitySearchInput,
  HighlightText,
  KeyVal,
  PageLoader,
  SelectInput,
  Spinner,
  TableHeader,
  TextInput,
  Toaster,
} from "@sk/uis";
import { cloneDeep, debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
const statusOptions = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const getDeals = async (id) => {
  const r = await DealService.getDetailsById(id);
  return {
    data: ([r.resp] || []).map((x) => ({
      label: `${x.name} (${x._id})`,
      value: x,
    })),
  };
};
const getFranchises = async (ids) => {
  const r = await FranchiseService.getList({
    filter: {
      _id: { $in: ids },
      "sk_franchise_details.franchise_sub_type": {
        $in: appConfigs.DARKSTORE_SUB_TYPES,
      },
    },
    select: "name",
  });
  return {
    data: (r.resp || []).map((x) => ({
      label: `${x.name} (${x._id})`,
      value: x,
    })),
  };
};

const canvasStyle = { width: "45%" };

let defaultTableHeader = [
  { label: "Sl.no.", isCentered: true },
  { label: "Min Qty" },
  { label: "Max Qty" },
  { label: "Discount %" },
  { label: "Action", width: "20%", isCentered: true },
];

let defaultFixedPriceTableHeader = [
  { label: "Sl.no.", isCentered: true },
  { label: "Min Qty" },
  { label: "Max Qty" },
  { label: "Fixed Price (Rs)" },
  { label: "Action", width: "20%", isCentered: true },
];

const configOptions = [
  { label: "Select", value: "" },
  { label: "Group Type", value: "groupType" },
  { label: "Smart Store", value: "franchise" },
];

const franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: appConfigs.DARKSTORE_SUB_TYPES,
    },
  },
};

const ManageKingSlabModal = ({ show, closeModal, mode, modalData }) => {
  const [pageLoading, setPageLoading] = useState(false);

  const [editSlabData, setEditSlabData] = useState({});

  const [submitting, setSubmitting] = useState(false);

  const [dealData, setDealData] = useState({});

  const [tableHeader, setTableHeader] = useState([...defaultTableHeader]);

  const [reserveQtyDetails, setReserveQtyDetails] = useState({
    loading: false,
    data: {},
  });

  const dataRef = useRef({});

  // form configuration
  const {
    reset,
    getValues,
    setValue,
    register,
    handleSubmit,
    control,
    trigger,
  } = useForm({
    defaultValues: {
      deal: [],
      isActive: true,
      slab: [],
      COCO: false,
      FOFO: false,
      ALL: false,
      configType: "",
      franchise: [],
      isFixedPrice: "",
    },
  });

  const [configType, slab, isFixedPrice, franchise] = useWatch({
    control,
    name: ["configType", "slab", "isFixedPrice", "franchise"],
  });

  useEffect(() => {
    // when the modal get opened clear the form data
    if (show) {
      resetFormData();
    }

    if (mode == "edit") {
      dataRef.current = modalData;
      if (modalData.dealId && show) {
        loadEditData(modalData);
      }
      if (!modalData.dealId && show) {
        Toaster.error("Invalid ID");
        triggerCloseModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, modalData, show, loadEditData]);

  const preparePayload = (data) => {
    data.slab.forEach((x) => {
      delete x.isEdit;
    });
    const p = {
      slab: data.slab,
      isActive: data.isActive == "false" ? false : true,
      dealId: data.deal[0].value._id,
      isFixedPrice: data.isFixedPrice,
    };
    if (data.configType == "groupType") {
      p.groupType = ["COCO", "FOFO"].filter((x) => data[x]);
    }
    if (data.configType == "franchise") {
      p.fids = data.franchise.map((x) => x.value._id);
    }

    return p;
  };

  // for reseting the form data and its dependent state
  const resetFormData = () => {
    setDealData({});
    reset();
    setSubmitting(false);
  };

  const loadEditData = async (modalData) => {
    setPageLoading(true);
    // Setting Deal
    const r = await getDeals(modalData.dealId);

    // to make Object extensible
    let list = cloneDeep(modalData.slab);

    // adding is Edit key for editing of slab
    list.forEach((x) => {
      x.isEdit = false;
    });

    setValue("deal", r.data);
    setValue("isFixedPrice", modalData.isFixedPrice);
    if (modalData.isFixedPrice) {
      setTableHeader([...defaultFixedPriceTableHeader]);
    }
    // Adding it to Response
    setDealData(r.data[0].value);
    setValue("isActive", modalData.isActive);
    setValue("slab", list);
    // Setting Values
    if (modalData?.groupType?.length) {
      setValue("configType", "groupType");
      modalData.groupType.forEach((x) => {
        setValue(x, true);
      });
      //    to check All are Selected Or Not
      let allChecked = ["COCO", "FOFO"].every((x) =>
        modalData.groupType.includes(x)
      );

      setValue("ALL", allChecked);
    }
    if (modalData?.fids?.length) {
      setValue("configType", "franchise");
      const r = await getFranchises(modalData?.fids);
      setValue("franchise", r.data);
      loadReserveQtyDetails(
        { value: { _id: modalData?.dealId } },
        modalData.fids[0]
      );
    }
    setPageLoading(false);
  };

  const validate = (data) => {
    const isGroupTypeSelected = ["COCO", "FOFO"].some((x) => data[x]);
    const isEditEnable = getValues("slab").some((x) => x.isEdit);

    let errorMsg = "";
    if (isEditEnable) {
      errorMsg = "Before Submit, Please save all changes";
    } else if (!data.deal.length) {
      errorMsg = "Please enter Deal Name/ID";
    } else if (!data.configType) {
      errorMsg = "Please select config type";
    } else if (data.configType == "franchise" && !data.franchise.length) {
      errorMsg = "Please enter Smart Store Name/ID";
    } else if (data.configType == "groupType" && !isGroupTypeSelected) {
      errorMsg = "Please select Group Type";
    } else if (!data.slab.length) {
      errorMsg = "Please add at least one Slab";
    }
    if (errorMsg) {
      Toaster.error(errorMsg);
      return false;
    }
    return true;
  };

  const onSubmit = async (data) => {
    if (!validate(data)) {
      return;
    }

    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (res.isDismissed) {
      return;
    }

    const p = preparePayload(data);
    setSubmitting(true);

    let r = {};
    //  API will Go here
    if (mode == "add") {
      r = await DealService.createPriceSlab(p);
    } else {
      r = await DealService.updatePriceSlab(modalData._id, p);
    }

    // After api call removed loader
    setSubmitting(false);

    if (r.statusCode !== 200) {
      Toaster.error(r.resp.message || "Failed to update");
      return;
    }

    resetFormData();

    Toaster.success(`${mode == "add" ? "Created" : "Updated"} Successfully`);

    triggerCloseModal("submit");
  };

  const triggerCloseModal = (status = "cancel") => {
    closeModal({ status });
  };

  const searchCb = (chngFn) => {
    return (val) => {
      chngFn(val);
      if (val.length) {
        setDealData(val[0].value);
        if (configType == "franchise") {
          let fid = getValues("franchise")[0]?.value?._id || "";
          loadReserveQtyDetails(val[0], fid);
        } else {
          setReserveQtyDetails({ loading: false, data: {} });
        }
      } else {
        setDealData({});
        setReserveQtyDetails({ loading: false, data: {} });
      }
    };
  };

  const onFranchiseCb = (chngFn) => {
    return (val) => {
      chngFn(val);
      setValue("deal", []);
      setDealData({});
      setReserveQtyDetails({ loading: false, data: {} });
    };
  };

  const onFixedPriceChange = (chngFn) => {
    return (val) => {
      chngFn(val);
      if (val) {
        let l = [];
        if (dataRef.current.isFixedPrice) {
          l = dataRef.current.slab?.length ? [...dataRef.current.slab] : [];
          // } else {
          //   l = [...getValues("slab")];
          //   l.forEach((e) => {
          //     e.disc = 0;
          //     e.price = 0;
          //   });
        }
        setValue("slab", [...l]);
        setTableHeader([...defaultFixedPriceTableHeader]);
      } else {
        let l = [];
        if (!dataRef.current.isFixedPrice) {
          l = dataRef.current.slab?.length ? [...dataRef.current.slab] : [];
          // } else {
          //   l = [...getValues("slab")];
          //   l.forEach((e) => {
          //     e.disc = 0;
          //     e.price = 0;
          //   });
        }
        setValue("slab", [...l]);
        setTableHeader([...defaultTableHeader]);
      }
    };
  };

  const loadReserveQtyDetails = useCallback(async (data, fid) => {
    setReserveQtyDetails({ loading: true, data: {} });
    let p = {
      groupbycond: "deal",
      groupbycondName: "name",
      dealFilter: { _id: { $in: [data?.value?._id] } },
      filter: { _id: fid },
    };
    let r = await FranchiseService.getFranchiseInventorySummary(p);
    let d = Array.isArray(r.resp) && r.resp?.length ? r.resp[0] : {};
    setReserveQtyDetails({ loading: false, data: d });
  }, []);

  const addSlab = async () => {
    //  to check some edit is enable or not
    const isEditEnable = getValues("slab").some((x) => x.isEdit);

    const deal = getValues("deal");

    let min = Number(getValues("min"));

    let max = Number(getValues("max"));

    let disc = Number(getValues("disc"));

    let price = Number(getValues("price"));

    let maxMrp = reserveQtyDetails.data.mrp || dealData.mrp;

    let slab = getValues("slab");

    let isFallBtw = false;

    let isValidDisc = false;

    let isValidPrice = false;

    if (slab.length > 0) {
      for (let s of slab) {
        if (
          (min >= s.min && min <= s.max) ||
          (max >= s.min && max <= s.max) ||
          (min <= s.min && max >= s.max)
        ) {
          isFallBtw = true;
          break;
        }
      }
      for (let s of slab) {
        if (
          (min < s.min && max < s.max && disc >= s.disc) ||
          (min > s.min && max > s.max && disc <= s.disc)
        ) {
          isValidDisc = true;
        }
      }

      for (let s of slab) {
        if (
          (min < s.min && max < s.max && price <= s.price) ||
          (min > s.min && max > s.max && price >= s.price)
        ) {
          isValidPrice = true;
        }
      }
    }
    let errorMsg = "";
    if (isEditEnable) {
      errorMsg = "Please save all changes";
    } else if (!deal.length) {
      errorMsg = "Please select deal";
    } else if (!min) {
      errorMsg = "Please provide Min Qty";
    } else if (min <= 0) {
      errorMsg = "Min Qty must be greater than 0";
    } else if (!max) {
      errorMsg = "please provide Max Qty";
    } else if (!isFixedPrice && (!disc || disc <= 0)) {
      errorMsg = "Please provide Discount";
    } else if (isFixedPrice && (!price || price <= 0)) {
      errorMsg = "Please provide Fixed Price";
    } else if (isFixedPrice && price > maxMrp) {
      errorMsg = "Fixed Price Should not be More Than MRP " + maxMrp;
    } else if (min >= max) {
      errorMsg = "Min Qty should be smaller then Max Qty";
    } else if (slab.length > 0 && isFallBtw) {
      errorMsg = "Min Qty and Max Qty already lies in slab";
    } else if (!isFixedPrice && isValidDisc) {
      errorMsg = "Please provide valid Discount";
    } else if (isFixedPrice && isValidPrice) {
      errorMsg = "Please provide valid Fixed Price";
    }

    if (errorMsg) {
      Toaster.error(errorMsg);
      return;
    }
    slab.push({
      min,
      max,
      isEdit: false, // to make it editable
      disc: isFixedPrice ? 0 : Number(disc),
      mrp: 1 * dealData.mrp,
      price: isFixedPrice ? price : 1 * dealData.b2bPrice,
    });
    slab.sort((a, b) => a.min - b.min);
    setValue("slab", slab);
    ["min", "max", "disc", "price"].forEach((x) => setValue(x, ""));
    await trigger("slab");
  };

  const textInputCb = useCallback(
    debounce((type, mode, e, i) => {
      let value =
        mode == "edit" ? Number(e.target.value) : Number(getValues(type));
      if (["disc", "price"].indexOf(type) == -1) {
        value = Math.ceil(value);
      }
      if (type == "disc" && value >= 100) {
        value = 99.99;
      }

      // if (type == "price" ) {
      //   price = 99.99;
      //   setValue(`slab[${i}][${type}]`, value);
      // }

      if (value < 0) {
        value = 1;
      }
      value = CommonService.roundedByDecimalPlace(value, 5);

      if (mode == "edit") {
        setValue(`slab[${i}][${type}]`, value);
      } else {
        setValue(type, value);
      }
    }, 1000),
    [setValue, getValues]
  );

  const deleteSlab = async (i) => {
    // asking confirm to submit
    const list = getValues("slab");
    const slab = list[i];
    let res = await Alert.confirm({
      title: "Please confirm",
      text: `
        <p class="fs-val-lg p-0" > Do you want  to delete? </p>
        <span class="me-2 fs-val-sm p-0" >Min : ${slab.min} </span>
        <span class="fs-val-sm p-0" >Max : ${slab.max} </span>
       `,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (res.isDismissed) {
      return;
    }

    list.splice(i, 1);
    setValue("slab", list);
    await trigger("slab");
  };

  const editSlabRow = async (i) => {
    const list = getValues("slab");
    setEditSlabData({ ...getValues(`slab[${i}]`), isEdit: false });
    let isNotEditable = list.some((x) => x.isEdit);
    if (isNotEditable) {
      Toaster.error("Please first update current slab");
      return;
    }
    setValue(`slab[${i}.isEdit]`, true);
    await trigger("slab");
  };

  const cancelEditSlab = async (i) => {
    setValue(`slab[${i}]`, editSlabData);
    setEditSlabData({});
    await trigger("slab");
  };

  const onEditSlabSave = async (i) => {
    const list = getValues("slab");
    const prevSlab = list[i - 1];
    const currentSlab = list[i];
    const nextSlab = list[i + 1];
    let maxMrp = reserveQtyDetails.data.mrp || dealData.mrp;
    // let canSave = false;
    let errorMsg = "";
    if (list.length == 1 && currentSlab.min >= currentSlab.max) {
      errorMsg = "Min Qty must be smaller than Max Qty ";
    } else if (currentSlab.min <= 0) {
      errorMsg = "Min Qty cannot be 0";
    } else if (currentSlab.min >= currentSlab.max) {
      errorMsg = "Min Qty must be smaller than Max Qty ";
    } else if (isFixedPrice && currentSlab.price > maxMrp) {
      errorMsg = "Fixed Price should not be More than MRP " + maxMrp;
    } else if (
      !isFixedPrice &&
      prevSlab &&
      (currentSlab.min <= prevSlab.max ||
        currentSlab.min <= prevSlab.min ||
        currentSlab.max <= prevSlab.min ||
        currentSlab.max <= prevSlab.max ||
        currentSlab.disc <= prevSlab.disc)
    ) {
      errorMsg = "Current Slab cannot be less than above Slab";
    } else if (
      isFixedPrice &&
      prevSlab &&
      (currentSlab.min <= prevSlab.max ||
        currentSlab.min <= prevSlab.min ||
        currentSlab.max <= prevSlab.min ||
        currentSlab.max <= prevSlab.max ||
        currentSlab.price >= prevSlab.price)
    ) {
      errorMsg = "Current Price should not be greater than above Slab";
    } else if (
      !isFixedPrice &&
      nextSlab &&
      (currentSlab.max >= nextSlab.min ||
        currentSlab.max >= nextSlab.max ||
        currentSlab.min >= nextSlab.min ||
        currentSlab.min >= nextSlab.max ||
        currentSlab.disc >= nextSlab.disc)
    ) {
      errorMsg = "Current Values cannot be greater than below Slab";
    } else if (
      isFixedPrice &&
      nextSlab &&
      (currentSlab.max >= nextSlab.min ||
        currentSlab.max >= nextSlab.max ||
        currentSlab.min >= nextSlab.min ||
        currentSlab.min >= nextSlab.max ||
        currentSlab.price <= nextSlab.price)
    ) {
      errorMsg = "Current Price should be less than below Slab";
    } else if (!isFixedPrice && !currentSlab.disc) {
      errorMsg = "Please, Add valid discount";
    } else {
      errorMsg = "";
    }

    if (!errorMsg) {
      setValue(`slab[${i}.isEdit]`, false);
      await trigger("slab");
      Toaster.success("Slab updated successfully");
      return;
    }
    Toaster.error(errorMsg);
  };
  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        placement="end"
        backdrop="static"
        keyboard={false}
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <AppTitle
            title={
              (mode === "edit" ? "Edit" : "Add") +
              " Kingslab - POS " +
              (mode === "edit" ? "  " + "#" + modalData._id : "")
            }
          />
        </Offcanvas.Header>
        {pageLoading && <PageLoader />}
        {!pageLoading && (
          <>
            <Offcanvas.Body>
              <div className="col-6">
                <SelectInput
                  label="Config Type"
                  register={register}
                  name="configType"
                  isMandatory={true}
                  options={configOptions}
                  disabled={mode == "edit"}
                />
              </div>

              {/* GroupType Block */}
              {configType == "groupType" && (
                <div className="col-4">
                  <label className="mb-1 fs-val-md">
                    {mode == "add" ? "Select Group Type" : "Group Type"}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="row mt-1">
                    {/* COCO */}
                    <div className="col-auto">
                      <Controller
                        name="COCO"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <BooleanCheckboxInput
                            label="COCO"
                            name="COCO"
                            isMandatory={false}
                            callback={onChange}
                            value={value}
                            disabled={mode == "edit"}
                          />
                        )}
                      />
                    </div>
                    {/* FOFO */}
                    <div className="ms-2 col-4">
                      <Controller
                        name="FOFO"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <BooleanCheckboxInput
                            label="FOFO"
                            name="FOFO"
                            isMandatory={false}
                            callback={onChange}
                            value={value}
                            disabled={mode == "edit"}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}

              {configType == "franchise" && (
                <div className="col-12 ">
                  <Controller
                    control={control}
                    name="franchise"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type="franchise"
                        label="Search for Smart Stores"
                        placeholder="Search by Name/ID"
                        value={value}
                        callback={onFranchiseCb(onChange)}
                        uid="franchise"
                        isMultiple={false}
                        isMandatory={true}
                        disabled={mode == "edit"}
                        filterParams={franchiseSearchFilter}
                        emptyLabel="No Smart Stores found"
                      />
                    )}
                  />
                </div>
              )}

              <div className="mb-3 border-bottom">
                <Controller
                  control={control}
                  name={"deal"}
                  key="deal"
                  render={({ field: { onChange, value } }) => (
                    <EntitySearchInput
                      type={"deal"}
                      label={`${mode == "add" ? "Search for" : ""} Deal`}
                      placeholder="Search By Deal Name / ID"
                      isMandatory={true}
                      value={value}
                      callback={searchCb(onChange)}
                      uid="deal-search"
                      disabled={
                        mode == "edit" ||
                        !configType ||
                        (configType == "franchise" && !franchise.length)
                      }
                      isMultiple={false}
                      emptyLabel="No Deal Found"
                    />
                  )}
                />

                {dealData._id && (
                  <div className="row mb-3">
                    <div className="col-auto me-4">
                      <KeyVal label="Deal ID" template="col">
                        {dealData._id}
                      </KeyVal>
                    </div>
                    <div className="col-auto me-4">
                      <KeyVal label="MRP" template="col">
                        {reserveQtyDetails.loading ? (
                          <Spinner type="dots" />
                        ) : (
                          <span className="fw-semibold text-primary ps-1">
                            <Amount
                              value={
                                reserveQtyDetails?.data?.mrp || dealData?.mrp
                              }
                              decimalPlace={2}
                            />
                          </span>
                        )}
                      </KeyVal>
                    </div>

                    {configType == "franchise" ? (
                      <>
                        <div className="col-auto me-4">
                          <KeyVal label="Stock Qty" template="col">
                            {reserveQtyDetails.loading ? (
                              <Spinner type="dots" />
                            ) : (
                              <span className="fw-semibold text-primary ps-1">
                                {reserveQtyDetails?.data?._qty || 0}{" "}
                                {reserveQtyDetails?.data?._displayUnitKey}
                              </span>
                            )}
                          </KeyVal>
                        </div>
                        <div className="col-auto me-4">
                          <KeyVal label="Reserve Qty" template="col">
                            {reserveQtyDetails.loading ? (
                              <Spinner type="dots" />
                            ) : (
                              <span className="fw-semibold text-primary ps-1">
                                {reserveQtyDetails?.data?.reserveConfig
                                  ?._available || 0}{" "}
                                {reserveQtyDetails?.data?._displayUnitKey}
                              </span>
                            )}
                          </KeyVal>
                        </div>
                        <div className="col-auto me-4">
                          <KeyVal label="Is RSP Fixed Price" template="col">
                            {reserveQtyDetails.loading ? (
                              <Spinner type="dots" />
                            ) : (
                              <HighlightText
                                status={
                                  reserveQtyDetails?.data?.isFixedPrice
                                    ? "Yes"
                                    : "No"
                                }
                              />
                            )}
                          </KeyVal>
                        </div>

                        {!reserveQtyDetails.loading &&
                        reserveQtyDetails?.data?.isFixedPrice ? (
                          <div className="col-auto me-4">
                            <KeyVal label="RSP Fixed Price" template="col">
                              <span className="fw-semibold text-primary ps-1">
                                <Amount
                                  value={
                                    reserveQtyDetails?.data
                                      ?.retailerSellingPrice
                                  }
                                />
                              </span>
                            </KeyVal>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-6">
                  <SelectInput
                    label="Status"
                    register={register}
                    name="isActive"
                    isMandatory={true}
                    options={statusOptions}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <AppCard title="Kingslab Configuration" noShadow={true}>
                    <div className="col-auto mb-3 mt-2">
                      <Controller
                        name="isFixedPrice"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <BooleanCheckboxInput
                            label="IS Fixed Price"
                            name="isFixedPrice"
                            isMandatory={false}
                            callback={onFixedPriceChange(onChange)}
                            value={value}
                            // disabled={mode == "edit"}
                          />
                        )}
                      />
                    </div>
                    <table className="table">
                      <TableHeader data={tableHeader} />
                      <tbody>
                        {slab.length > 0 &&
                          slab.map((s, i) => (
                            <tr key={i} className="fs-val-md">
                              <td>
                                <div className="mb-3 text-center">{i + 1}</div>
                              </td>
                              <td>
                                <TextInput
                                  name={`slab[${i}].min`}
                                  register={register}
                                  type="number"
                                  callback={(e) =>
                                    textInputCb("min", "edit", e, i)
                                  }
                                  disabled={!s.isEdit}
                                  gap={0}
                                />
                              </td>
                              <td>
                                <TextInput
                                  name={`slab[${i}].max`}
                                  register={register}
                                  gap={0}
                                  type="number"
                                  callback={(e) =>
                                    textInputCb("max", "edit", e, i)
                                  }
                                  disabled={!s.isEdit}
                                />
                              </td>
                              {isFixedPrice ? (
                                <td>
                                  <TextInput
                                    name={`slab.${i}.price`}
                                    register={register}
                                    type="number"
                                    gap={0}
                                    callback={(e) =>
                                      textInputCb("price", "edit", e, i)
                                    }
                                    disabled={!s.isEdit}
                                  />
                                </td>
                              ) : (
                                <td>
                                  <TextInput
                                    name={`slab.${i}.disc`}
                                    register={register}
                                    type="number"
                                    gap={0}
                                    callback={(e) =>
                                      textInputCb("disc", "edit", e, i)
                                    }
                                    disabled={!s.isEdit}
                                  />
                                </td>
                              )}

                              <td>
                                {!slab.some((x) => x.isEdit) && (
                                  <>
                                    {/* Edit Button */}
                                    <button
                                      className="btn fs-val-md bg-white px-1 fw-bold py-0 text-warning border shadow-sm me-2"
                                      type="button"
                                      onClick={() => editSlabRow(i)}
                                    >
                                      <i className="bi bi-pencil-fill"></i>
                                    </button>
                                    {/* Delete Button */}
                                    <button
                                      className="btn fs-val-md bg-white px-1 fw-bold py-0 text-danger border shadow-sm me-2"
                                      type="button"
                                      onClick={() => deleteSlab(i)}
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  </>
                                )}
                                {/* While Editing particular Slab  */}
                                {s.isEdit && (
                                  <>
                                    {/* Save Button */}
                                    <button
                                      className="btn fs-val-md bg-white px-1 fw-bold py-0 text-success border shadow-sm me-2"
                                      type="button"
                                      onClick={() => onEditSlabSave(i)}
                                    >
                                      <i className="bi bi-check"></i>
                                    </button>
                                    {/* Cancel Button */}
                                    <button
                                      className="btn fs-val-md bg-white px-1 fw-bold py-0 text-danger border shadow-sm me-2"
                                      onClick={() => cancelEditSlab(i)}
                                      type="button"
                                    >
                                      <i className="bi bi-x"></i>
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        {/* Input Row */}
                        <tr>
                          <td>{}</td>
                          <td>
                            <div>
                              <TextInput
                                name="min"
                                type="number"
                                register={register}
                                callback={() => textInputCb("min", "add")}
                              />
                            </div>
                          </td>
                          <td>
                            <div>
                              <TextInput
                                name="max"
                                type="number"
                                register={register}
                                callback={() => textInputCb("max", "add")}
                              />
                            </div>
                          </td>
                          <td>
                            {isFixedPrice ? (
                              <div>
                                <TextInput
                                  name="price"
                                  type="number"
                                  register={register}
                                  callback={() => textInputCb("price", "add")}
                                />
                              </div>
                            ) : (
                              <div>
                                <TextInput
                                  name="disc"
                                  type="number"
                                  register={register}
                                  callback={() => textInputCb("disc", "add")}
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            <div>
                              <button
                                className="btn btn-success  btn-sm mb-3 w-100"
                                type="button"
                                onClick={addSlab}
                              >
                                <i className="bi bi-plus-circle me-1"></i>
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </AppCard>
                </div>
              </div>
            </Offcanvas.Body>
            <Offcanvas.Header className="bg-light">
              <div className="w-100">
                <div className="text-end">
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                  >
                    Submit {submitting ? <Spinner isSmall={true} /> : null}
                  </button>
                </div>
              </div>
            </Offcanvas.Header>
          </>
        )}
      </Offcanvas>
    </>
  );
};

export default ManageKingSlabModal;
