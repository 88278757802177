import { AppCard, Spinner } from "@sk/uis";
import classNames from "classnames";
import { memo } from "react";

const SummaryCard = ({ value, label, loading, color }) => {
  return (
    <AppCard>
      <div className="row">
        <div className="col">
          <div className="fs-val-normal fw-semibold">{label}</div>
        </div>
        <div className="col-auto">
          <div
            className={classNames("fs-val-lg fw-bold", {
              ["text-" + color]: true,
            })}
          >
            {loading ? <Spinner type="dots" /> : value}
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default memo(SummaryCard);
