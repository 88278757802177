import { ProductService } from "@sk/services";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { TextInput, SelectInput } from "@sk/uis";
import { useForm } from "react-hook-form";
import produce from "immer";
import { debounce } from "lodash";

const CmsOverviewProductFilterModal = ({
  show,
  callback,
  brandId,
  categoryId,
}) => {
  const { register, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const selectedItemsRef = useRef([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const paginationRef = useRef({ page: 1, count: 20 });
  const filterRef = useRef({});

  useEffect(() => {
    if (show && (categoryId || brandId)) {
      paginationRef.current.page = 1;
      filterRef.current = {
        categoryId,
        brandId,
      };
      setSearchTerm("");
      setHasMoreData(true);
      applyFilter();
    }
  }, [applyFilter, show, categoryId, brandId]);

  useEffect(() => {
    selectedItemsRef.current = [];
  }, [categoryId, brandId]);

  const handleClose = () => {
    callback({ action: "close" });
  };

  const loadMore = async () => {
    setLoadingMore(true);
    paginationRef.current = {
      ...paginationRef.current,
      page: paginationRef.current.page + 1,
    };
    const params = prepareFilterParams(
      filterRef.current,
      paginationRef.current,
      {}
    );
    const newData = await getData(categoryId ? "brand" : "category", params);
    const updatedData = updateResponseWithChecked(
      newData.data,
      selectedItemsRef.current
    );
    setData((prevData) => [...prevData, ...updatedData]);
    setHasMoreData(newData.data.length > 0);
    setLoadingMore(false);
  };

  const handleCheckboxChange = (item) => {
    if (selectedItemsRef.current.some((selected) => selected.id === item.id)) {
      selectedItemsRef.current = selectedItemsRef.current.filter(
        (i) => i.id !== item.id
      );
    } else {
      selectedItemsRef.current.push(item);
    }
    setData(
      produce((draft) => {
        const dataItem = draft.find((e) => e.id === item.id);
        if (dataItem) {
          dataItem.checked = !dataItem.checked;
        }
      })
    );
  };

  const handleReset = () => {
    selectedItemsRef.current = [];
    callback({ action: "reset" });
  };

  const applyFilter = useCallback(async () => {
    paginationRef.current.page = 1;
    const params = prepareFilterParams(
      filterRef.current,
      paginationRef.current,
      {}
    );

    try {
      setLoading(true);
      const results = await getData(categoryId ? "brand" : "category", params);
      const updatedData = updateResponseWithChecked(
        results.data,
        selectedItemsRef.current
      );
      setData(updatedData);
      setLoading(false);

      const countResult = await getCount(
        categoryId ? "brand" : "category",
        params
      );
      setCount(countResult);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  }, [categoryId]);

  const handleApply = () => {
    applyFilter();
    const formData = {
      sort: getValues("sortOptions"),
      selectedIds: selectedItemsRef.current,
    };
    callback({ action: "apply", formData });
  };

  const searchPlaceholder = categoryId
    ? "Search for Brand"
    : "Search for Category";

  const debouncedSearchTerm = useCallback(
    debounce((callback) => {
      callback();
    }, 500),
    [applyFilter]
  );

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    filterRef.current.searchTerm = value;
    debouncedSearchTerm(() => {
      applyFilter();
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Filter Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectInput
            label="Sort Options"
            register={register}
            name="sortOptions"
            options={[
              { label: "Choose", value: "choose" },
              { label: "Sort on Recent PO", value: "recent" },
            ]}
            callback={() => {}}
          />

          {loading ? (
            <p className="text-center">Loading...</p>
          ) : (
            <>
              {brandId || categoryId ? (
                <>
                  <TextInput
                    type="text"
                    label="Search"
                    placeholder={searchPlaceholder}
                    value={searchTerm}
                    callback={handleSearchChange}
                    register={register}
                    name="search"
                  />
                  <p className="fs-val-sm">
                    Showing {count} {categoryId ? "brands" : "categories"}
                  </p>
                  <div className="row">
                    {data.map((item, index) => (
                      <div
                        key={index}
                        className="col-6 d-flex align-items-center mb-2"
                      >
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(item)}
                          checked={item.checked}
                        />
                        <div className="ms-2 fs-val-sm">
                          {item.name} -{" "}
                          <small className="text-muted">({item.id})</small>
                        </div>
                      </div>
                    ))}
                  </div>
                  {hasMoreData && (
                    <button
                      type="button"
                      className="btn btn-light btn-sm mx-auto d-block mt-2"
                      onClick={loadMore}
                      disabled={loadingMore}
                    >
                      {loadingMore ? "Loading..." : "Load More"}
                    </button>
                  )}
                </>
              ) : null}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleReset}
            disabled={loading}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleApply}
            disabled={loading}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination.page,
    count: pagination.count,
  };

  if (filter.brandId) {
    p.brandIds = [filter.brandId];
    p.sort = {
      categoryName: 1,
    };
    if (filter.searchTerm) {
      p.projectFilter = {
        brandName: { $regex: filter.searchTerm, $options: "i" },
      };
    }
  }

  if (filter.categoryId) {
    p.categoryIds = [filter.categoryId];
    p.sort = {
      brandName: 1,
    };
    if (filter.searchTerm) {
      p.projectFilter = {
        categoryName: { $regex: filter.searchTerm, $options: "i" },
      };
    }
  }

  return p;
};

const getData = async (type, params) => {
  let r;
  switch (type) {
    case "brand":
      r = await ProductService.getProductBrands(params);
      return {
        data: r?.resp.map((item) => ({
          ...item,
          name: item.brandName,
          id: item.brandId,
        })),
      };
    case "category":
      r = await ProductService.getProductCategories(params);
      return {
        data: r?.resp.map((item) => ({
          ...item,
          name: item.categoryName,
          id: item.categoryId,
        })),
      };
    default:
      throw new Error("Invalid type");
  }
};

const updateResponseWithChecked = (response, selectedItems) => {
  return response.map((item) => ({
    ...item,
    checked: selectedItems.some((selected) => selected.id === item.id),
  }));
};

const getCount = async (type, params) => {
  let r;
  switch (type) {
    case "brand":
      r = await ProductService.getProductBrands({
        ...params,
        outputType: "count",
      });
      return r?.resp?.[0]?.total;
    case "category":
      r = await ProductService.getProductCategories({
        ...params,
        outputType: "count",
      });
      return r?.resp?.[0]?.total;
    default:
      throw new Error("Invalid type");
  }
};

export default CmsOverviewProductFilterModal;
