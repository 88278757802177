import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import { DealService } from "@sk/services";
import {
  Alert,
  AuditLogTable,
  NoDataFound,
  PageInfo,
  PageLoader,
  Tabs,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DealInfo from "./components/deal-info/DealInfo";
import ViewDealLinkedSellers from "./components/linked-sellers/ViewDealLinkedSellers";
import ViewDealOrders from "./components/orders/ViewDealOrders";
import ViewDealPricingTier from "./components/pricing-tier/ViewDealPricingTier";
import ViewDealMainInfo from "./components/ViewDealMainInfo";
import ViewDealWarehouse from "./components/warehouse/ViewDealWarehouse";

const breadcrumbs = [
  {
    name: "Home",
    link: "/builder/config/pages",
  },

  {
    name: "Deal List",
    link: "/cms/deal/list",
  },
  {
    name: "Deal Details",
  },
];

const attachAdditionalDataConfig = [
  {
    key: "category",
    api: "category",
    loadingKey: "categoryLoading",
    dataKey: "_category",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "brand",
    api: "brand",
    loadingKey: "brandLoading",
    dataKey: "_brand",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

// New mainTabs array
const mainTabs = [
  { key: "dealInfo", tabName: "Deal Info" },
  { key: "pricing", tabName: "Pricing and Tiers" },
  { key: "warehouseInventory", tabName: "Warehouse" },
  { key: "orders", tabName: "Orders" },
  { key: "linkedSellers", tabName: "Linked Sellers" },
  { key: "logs", tabName: "Audit Logs" },
];

const ViewDeal = () => {
  const [data, setData] = useState({});

  const [display, setDisplay] = useState("loading");

  const [searchParams] = useSearchParams();

  const router = useNavigate();

  const query = useFetchUrlQueryString(searchParams);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [publishStatus, setPublishStatus] = useState({
    loading: false,
    status: "",
  });

  const [activeMainTab, setActiveMainTab] = useState(mainTabs[0]);

  useEffect(() => {
    if (query.id) {
      loadDealDetails(query.id);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadDealDetails, query.id]);

  //  Fetching
  const loadDealDetails = useCallback(
    async (id) => {
      setDisplay("loading");
      let m = await DealService.getDetailsById(id);
      const d = m?.resp?._id ? m.resp : {};
      if (d._id) {
        setDisplay("details");
      } else {
        setDisplay("noDataFound");
      }

      // adding Loading Key for Attack additional Info
      d.categoryLoading = true;
      d.brandLoading = true;
      setData(d);
      setPublishStatus({
        loading: false,
        status: d.status,
      });
      if (d._id) {
        let tmp = [];
        // Attach User Info
        setAdditionalData([d], attachAdditionalDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length == attachAdditionalDataConfig.length) {
            const a = [...attachAllData([d], tmp)][0];
            setData((v) => ({ ...v, ...a }));
          }
        });
      }
    },
    [attachAllData, setAdditionalData]
  );

  const mainTabCb = (data) => {
    setActiveMainTab(data.value);
  };

  const changePublishStatus = async () => {
    let text =
      publishStatus.status == "Publish"
        ? "Do you want to Unpublish the deal"
        : "Do you want to Publish the deal";

    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: text,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (!res.isConfirmed) {
      return;
    }

    let p = {
      manualUnpublish: true,
      status: publishStatus.status == "Publish" ? "Unpublish" : "Publish",
      _id: data._id,
    };
    setPublishStatus({ loading: true, status: publishStatus.status });

    let d = await DealService.bulkUpdate(data._id, p);

    if (Array.isArray(d?.resp) && d?.resp?.length > 0) {
      setPublishStatus({
        loading: false,
        status: publishStatus.status == "Publish" ? "Unpublish" : "Publish",
      });
      Toaster.success("Status update Successfully");
    } else {
      Toaster.error("Sorry, Unable to update Status");
      setPublishStatus({
        loading: false,
        status: publishStatus.status,
      });
    }
  };

  return (
    <>
      <PageInfo
        title="Deal Details"
        breadcrumbs={breadcrumbs}
        navigate={router}
      />

      {/* Page Loader For First Time Loading */}
      {display == "loading" && <PageLoader />}

      {/* When There is No Data or Id */}
      {display == "noDataFound" && (
        <NoDataFound>No Deal Detials Found</NoDataFound>
      )}

      {display == "details" && (
        <>
          <ViewDealMainInfo dealId={data._id} data={data} />

          {/* New Tabs component using mainTabs */}
          <div className="mb-3">
            <Tabs
              data={mainTabs}
              callback={mainTabCb}
              activeTab={activeMainTab}
              template={4}
              shadow={true}
            />
          </div>

          {activeMainTab.key === "dealInfo" && <DealInfo data={data} />}

          {activeMainTab.key === "pricing" && (
            <ViewDealPricingTier tierData={data.tierData} dealId={data._id} />
          )}

          {activeMainTab.key === "warehouseInventory" && (
            <ViewDealWarehouse
              visibilityByWarehouse={data.visibilityByWh}
              dealId={data._id}
            />
          )}

          {activeMainTab.key === "orders" && (
            <ViewDealOrders dealId={data._id} />
          )}

          {activeMainTab.key === "linkedSellers" && (
            <ViewDealLinkedSellers sellers={data.sellers} />
          )}

          {activeMainTab.key === "logs" && (
            <AuditLogTable
              entityId={data._id}
              entityType="Deal"
              withCard={true}
              hideTitle={true}
            />
          )}
        </>
      )}
    </>
  );
};

export default ViewDeal;
