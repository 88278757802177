import { AppCard, ImgRender } from "@sk/uis";

const ViewDealImages = ({ images }) => {
  return (
    <AppCard>
      {images?.length ? (
        <div className="row">
          {(images || []).map((imageId) => (
            <div className="col-auto" key={imageId}>
              <ImgRender
                template="2"
                assetId={imageId}
                width="200"
                height="200"
              />
            </div>
          ))}
        </div>
      ) : (
        "NA"
      )}
    </AppCard>
  );
};

export default ViewDealImages;
