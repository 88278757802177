import { AppCard, SelectInput, TextInput } from "@sk/uis";
import { memo, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { debounce } from "lodash";
import listView from "../../constantService";
import AdvFilterModal from "../modals/AdvanceFilterModal";

const dealStatusOptions = [
  { label: "All", value: "" },
  { label: "Draft", value: "Draft" },
  { label: "Publish", value: "Publish" },
  { label: "Unpublish", value: "Unpublish" },
];

const activeStatusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const dealTypeOptions = [
  { label: "All", value: "" },
  { label: "Normal", value: "Normal" },
  { label: "Packed", value: "Packed" },
  { label: "Combo", value: "Combo" },
  { label: "Group", value: "Group" },
];

const ownedByOptions = [
  { label: "All", value: "" },
  { label: "SK", value: "sk" },
  { label: "Third Party", value: "thirdparty" },
];

const DealFilter = ({ callback }) => {
  const { register, getValues } = useForm({
    defaultValues: { ...listView.filterFromData },
  });

  const [showAdvFilter, setShowAdvFilter] = useState(false);
  const [filterData, setFilterData] = useState({});

  const debounceSearch = useCallback(
    debounce(() => {
      callback({ action: "apply", formData: getValues() });
    }, 500),
    [callback, getValues]
  );

  const onSearchInputChange = () => {
    debounceSearch();
  };

  const onInputChange = () => {
    callback({ action: "apply", formData: getValues() });
  };

  const handleAdvFilterSubmit = (data) => {
    if (data.status === "applied" || data.status === "reset") {
      setFilterData(data.formData);
      callback({
        action: "apply",
        formData: { ...getValues(), ...data.formData },
      });
    }
    setShowAdvFilter(false);
  };

  return (
    <AppCard>
      <div className="row align-items-center">
        <div className="col-3">
          <TextInput
            name="dealSearch"
            register={register}
            callback={onSearchInputChange}
            label="Search Deal"
            size="sm"
            gap={0}
            placeholder="Search Deal"
          />
        </div>

        <div className="col">
          <SelectInput
            name="activeStatus"
            options={activeStatusOptions}
            register={register}
            callback={onInputChange}
            label="Active Status"
            size="sm"
            gap={0}
          />
        </div>

        <div className="col">
          <SelectInput
            name="dealStatus"
            options={dealStatusOptions}
            register={register}
            callback={onInputChange}
            label="Deal Status"
            size="sm"
            gap={0}
          />
        </div>

        <div className="col">
          <SelectInput
            name="dealType"
            options={dealTypeOptions}
            register={register}
            callback={onInputChange}
            label="Deal Type"
            size="sm"
            gap={0}
          />
        </div>

        <div className="col">
          <SelectInput
            name="ownedBy"
            options={ownedByOptions}
            register={register}
            callback={onInputChange}
            label="Owned By"
            size="sm"
            gap={0}
          />
        </div>

        <div className="col-auto align-self-end">
          <button
            className="btn app-filter-btn"
            type="button"
            onClick={() => setShowAdvFilter(true)}
          >
            <i className="bi bi-funnel"></i> More Filters
          </button>
        </div>
      </div>

      <AdvFilterModal
        show={showAdvFilter}
        closeModal={() => setShowAdvFilter(false)}
        callback={handleAdvFilterSubmit}
        formData={filterData}
      />
    </AppCard>
  );
};

export default memo(DealFilter);
