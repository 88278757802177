import { AppCard, NoDataFound } from "@sk/uis";

const Description = ({ shortDescription, fullDescription, highlights }) => {
  return (
    <>
      <div className="row">
        <div className="col-4">
          <AppCard title="Short Description">
            {!shortDescription ? (
              <NoDataFound>No Short Description Found</NoDataFound>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: shortDescription,
                }}
                className="fs-val-md"
              ></div>
            )}
          </AppCard>
          <AppCard title="Highlights">
            {!highlights.length ? (
              <NoDataFound>No Highlights There</NoDataFound>
            ) : (
              <>
                {highlights.map((x, i) => (
                  <div className="row fs-val-md" key={i}>
                    <div className="col-auto">
                      <i className="bi bi-check-circle text-success fs-val-normal"></i>
                    </div>
                    <div className="col">{x}</div>
                  </div>
                ))}
              </>
            )}
          </AppCard>
        </div>
        <div className="col-8">
          <AppCard title="Full Description">
            {!fullDescription ? (
              <NoDataFound>No Full Description Found</NoDataFound>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: fullDescription,
                }}
                className="fs-val-md"
              ></div>
            )}
          </AppCard>
        </div>
      </div>
    </>
  );
};

export default Description;
