import { CommonService, ProductService } from "@sk/services";
import {
  AppCard,
  AppTitle,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PageLoader,
  Toaster,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const ViewHsnMasterModal = ({ show, hsnId, closeModal }) => {
  const [display, setDisplay] = useState("loading");
  const [data, setData] = useState({});

  useEffect(() => {
    const getHsnDetails = async (id) => {
      let m = await ProductService.getHsnById(id);
      if (m?.resp?._id) {
        const d = await CommonService.attachUserInfo(
          [m?.resp || {}],
          ["createdBy", "modifiedBy"]
        );
        const r = d[0] || {};
        setData(r);
        setDisplay("details");
      } else {
        Toaster.error("Invalid Id");
        setDisplay("notFound");
      }
    };
    if (hsnId && show) {
      setDisplay("loading");
      getHsnDetails(hsnId);
    } else {
      setDisplay("details");
    }
  }, [hsnId, show]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={{ width: "45%" }}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <AppTitle title={`HSN Code Details - ${data?._id}`} />
        </Offcanvas.Header>

        <Offcanvas.Body className="p-3">
          {show ? (
            <>
              {display == "loading" ? <PageLoader animation="border" /> : null}

              {/* DETAILS */}
              {display == "details" ? (
                <AppCard>
                  <div className="row">
                    {/* HSN Code */}
                    <div className="col-8">
                      <div className="key-lbl">HSN Code</div>
                      <div className="text-primary key-val fw-bold  mb-4">
                        {data?.hsn || "-"}
                      </div>
                    </div>

                    {/* Status */}
                    <div className="col-4 ">
                      <div className="key-lbl">Status</div>
                      <div className="fw-bold  key-val mb-4">
                        <HighlightText status={data?.status} />
                      </div>
                    </div>

                    {/* Hsn Description */}
                    <div className="col-12 py-2">
                      <div className="key-lbl">HSN Description</div>
                      <div className="mb-4 key-val ">
                        {data?.hsndesc || "-"}
                      </div>
                    </div>

                    {/* GST */}
                    <div className="col-4">
                      <div className="key-lbl">GST</div>
                      <div className="fw-semibold key-val">
                        {data?.gst ? data?.gst + "%" : "-"}
                      </div>
                    </div>

                    {/* Cess value */}
                    <div className="col-4">
                      <div className="key-lbl">Cess</div>
                      <div className="key-val fw-semibold">
                        {data?.cess ? data?.cess + "%" : "-"}
                      </div>
                    </div>

                    {/* Additional Cess Value */}
                    <div className="col-4">
                      <div className="key-lbl">Additional Cess Value</div>
                      <div className="fw-bold  key-val mb-4">
                        {data?.addCessValue
                          ? data?.addCessValue + " " + data?.addCessValueType
                          : "-"}
                      </div>
                    </div>

                    {/* Linked Categories */}
                    <div className="col-12 py-2">
                      <div className="key-lbl">Linked Categories</div>
                      <div className=" key-val mb-4">
                        {data?.linkedCategories?.length
                          ? (data.linkedCategories || [])
                              .filter((i) => i.id && i.name)
                              .map((e, ind) => (
                                <span
                                  className="px-2 me-2 mb-1 badge bg-soft-primary fw-semibold"
                                  key={ind}
                                >
                                  {e?.name}
                                </span>
                              ))
                          : "-"}
                      </div>
                    </div>

                    {/* created By */}
                    <div className="col-4">
                      <div className="key-lbl">Created By</div>
                      <div className="key-val">
                        <div>{data?._createdBy?.name}</div>
                      </div>
                    </div>

                    {/* created At */}
                    <div className="col-4">
                      <div className="key-lbl">Created At</div>
                      <div className="key-val">
                        <DateFormatter
                          date={data.createdAt}
                          format="dd MMM yyyy hh:mm a"
                        />
                      </div>
                    </div>

                    {/* Modified By */}
                    <div className="col-4">
                      <div className="key-lbl">Modified By</div>
                      {data?.modifiedAt ? (
                        <div className=" mb-4  key-val">
                          <div>{data?._modifiedBy?.name || "NA"}</div>
                        </div>
                      ) : null}
                    </div>

                    {/* Modified At */}
                    <div className="col-4">
                      <div className="key-lbl">Modified At</div>
                      {data?.modifiedAt ? (
                        <div className=" mb-4  key-val">
                          <div className="fs-6">
                            <DateFormatter
                              date={data?.modifiedAt}
                              format="dd MMM yyyy hh:mm a"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </AppCard>
              ) : null}

              {/* NO RECORD */}
              {display === "notFound" && (
                <NoDataFound> No Data Found </NoDataFound>
              )}
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewHsnMasterModal;
