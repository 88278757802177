import { TextInput, SelectInput, DateInput } from "@sk/uis";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "lodash";

// Move options outside the component
const statusOptions = [
  { value: "", label: "All" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const ViewDealWarehousePriceFilter = ({ callback }) => {
  const { register, control, getValues } = useForm();

  const triggerCallback = () => {
    if (callback) {
      callback({ action: "submit", formData: getValues() });
    }
  };

  const onSearch = debounce(() => {
    triggerCallback();
  }, 800);

  const onDateChange = (chngFn) => (val) => {
    chngFn(val);
    triggerCallback();
  };

  return (
    <>
      <div className="row">
        <div className="col-3">
          <TextInput
            callback={onSearch}
            label="Search"
            name="search"
            register={register}
            placeholder="Search by WH ID, Level, or ID"
          />
        </div>

        <div className="col-3">
          <SelectInput
            label="Status"
            name="status"
            register={register}
            options={statusOptions}
            defaultValue=""
            callback={triggerCallback}
          />
        </div>

        <div className="col-3">
          <Controller
            control={control}
            name="validityFrom"
            render={({ field }) => (
              <DateInput
                label="Validity From"
                callback={onDateChange(field.onChange)}
                value={field.value}
                gap={0}
              />
            )}
          />
        </div>

        <div className="col-3">
          <Controller
            control={control}
            name="validityTo"
            render={({ field }) => (
              <DateInput
                label="Validity To"
                callback={onDateChange(field.onChange)}
                value={field.value}
                gap={0}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ViewDealWarehousePriceFilter;
