import {
  AuthService,
  DealService,
  FranchiseService,
  appConfigs,
} from "@sk/services";
import { AutoCompleteInput, EntitySearchInput, Toaster } from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import Table from "./components/Table";

const defaultFormData = {
  category: "",
  brand: "",
  franchise: [],
  deal: "",
};

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: appConfigs.DARKSTORE_SUB_TYPES,
    },
  },
};

const canViewSkLandingCost = AuthService.isValidUserRole(["ViewSkLandingCost"])
  ? true
  : false;

const defaultSortOpt = { key: "name", value: "asc" };

// To Prepare params from API
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: { deal: {} },
    sort: {},
    slc: canViewSkLandingCost,
  };
  if (filter.deal.length) {
    p.filter.deal = {
      id: {
        $in: [filter.deal[0].value?._id],
      },
    };
  }

  if (filter?.brand?.length) {
    p.filter.deal["brand.id"] = { $in: [filter?.brand[0]?.value?._id] };
  }

  if (filter?.category?.length) {
    p.filter.deal["category.id"] = { $in: [filter?.category[0]?.value?._id] };
  }

  if (filter?.franchise?.length) {
    p["franchiseInfo.id"] = filter?.franchise[0].value._id;
  }
  if (sort?.key) {
    p.sort = {
      [sort.key]: sort.value == "asc" ? 1 : -1,
    };
  }
  if (!p.filter.deal.length) {
    delete p.filter.deal;
  }
  return p;
};

const BulkRspUpdateModal = ({ show, callback }) => {
  const { register, getValues, control, reset } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(false);

  const filterDataRef = useRef(defaultFormData);

  const sortRef = useRef({ ...defaultSortOpt });

  const paginationRef = useRef({ ...defaultPageOpt });

  const [franchise] = useWatch({ control, name: "franchise" });

  useEffect(() => {
    if (show) {
      filterDataRef.current = defaultFormData;
      paginationRef.current = { ...defaultPageOpt };
      setData([]);
      reset();
      //   init();
    }
  }, [show]);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const applyFilter = useCallback(
    async (resetPagination = true) => {
      if (resetPagination) {
        paginationRef.current = { ...defaultPageOpt };
      }
      loadList();
      loadCount();
    },
    [loadList, loadCount]
  );

  const loadCount = useCallback(async () => {
    const params = getFilterParams();
    // for total records
    setLoadingTotalRecords(true);

    const c = await getCount(params);

    // Setting Total Count
    const t = c.statusCode == 200 ? c.resp : 0;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, []);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(getFilterParams());
    if (r.statusCode !== 200) {
      Toaster.error("Failed to fetch list,Please Reload");
      setData([]);
      setLoadingData(false);
      return;
    }
    const d = r.resp || [];
    setData(d);
    setLoadingData(false);
  }, [getFilterParams]);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const paginationCb = useCallback((data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (params) => {
    return DealService.getCustomerCatalogPriceConfig(params);
  };

  const getCount = (params) => {
    return DealService.customerCatalogPriceConfigCount({
      params,
      outputType: "count",
    });
  };

  const filterCb = useCallback(() => {
    // filterDataRef.current = {
    //   ...formMethods.getValues(),
    //   ...advanceFilterRef.current,
    // };
    applyFilter();
  }, [applyFilter]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const triggerCloseModal = () => {
    callback({ status: "close" });
  };

  const searchCb = useCallback(
    (chngFn) => {
      return (e) => {
        chngFn(e);
        filterDataRef.current = getValues();
        applyFilter();
      };
    },
    [applyFilter]
  );

  const rspSubmitCb = useCallback(async (d) => {
    if (d.status == "submit") {
      let f = getValues();

      let p = { ...d.payload, franchiseId: f.franchise[0].value._id };

      if (p.rule != "BYDEAL") {
        if (f.deal.length) {
          p.criteria.deal = [f.deal[0].value?._id];
        }

        if (f?.brand?.length) {
          p.criteria.brand = [f.brand[0].value?._id];
        }

        if (f?.category?.length) {
          p.criteria.category = [f.category[0].value?._id];
        }
      }

      setBusyLoader({ show: true, msg: "" });
      let r = await DealService.bulkUpdateCustomerCatalogPriceConfig(p);

      if (r.statusCode !== 200) {
        setBusyLoader({ show: false, msg: "" });
        callback({ status: "submit" });
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      Toaster.success("Bulk RSP Config Updated Successfully");

      setBusyLoader({ show: true, msg: "" });

      callback({ status: "submit" });
    }
  }, []);

  const onBrandSearch = useCallback(async (val, callback) => {
    let filter = {};
    let f = getValues();
    if (val) {
      let v = "/" + val + "/";
      filter = {
        $or: [
          { brandName: { $regex: val, $options: "gi" } },
          { brandId: { $regex: val, $options: "gi" } },
        ],
      };
    }

    let p = {
      page: 1,
      count: 10,
      select: "name,_id",
      brandFilter: filter,
      groupbycond: "brand",
      groupbycondName: "name",
      franchiseId: f.franchise[0].value._id,
      filter: { _id: f.franchise[0].value._id },
      slc: canViewSkLandingCost,
    };

    const r = await FranchiseService.getFranchiseInventorySummary(p);
    callback(
      (r?.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  }, []);

  const onCategorySearch = useCallback(async (val, callback) => {
    let filter = {};
    let f = getValues();
    if (val) {
      let v = "/" + val + "/";
      filter = {
        $or: [
          { categoryName: { $regex: val, $options: "gi" } },
          { categoryId: { $regex: val, $options: "gi" } },
        ],
      };
    }

    let p = {
      page: 1,
      count: 10,
      select: "name,_id",
      brandFilter: filter,
      groupbycond: "category",
      groupbycondName: "name",
      franchiseId: f.franchise[0].value._id,
      filter: { _id: f.franchise[0].value._id },
      slc: canViewSkLandingCost,
    };

    const r = await FranchiseService.getFranchiseInventorySummary(p);
    callback(
      (r?.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  }, []);

  const onDealSearch = useCallback(async (val, callback) => {
    let filter = {};
    let f = getValues();
    if (val) {
      let v = "/" + val + "/";
      filter = {
        $or: [
          { _id: { $regex: val, $options: "gi" } },
          { name: { $regex: val, $options: "gi" } },
        ],
      };
    }

    let p = {
      page: 1,
      count: 10,
      select: "name,_id",
      brandFilter: filter,
      groupbycond: "deal",
      groupbycondName: "name",
      franchiseId: f.franchise[0].value._id,
      filter: { _id: f.franchise[0].value._id },
      slc: canViewSkLandingCost,
    };

    const r = await FranchiseService.getFranchiseInventorySummary(p);
    callback(
      (r?.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  }, []);

  return (
    <Modal
      show={show}
      backdrop="static"
      dialogClassName="modal-xxxl"
      onHide={triggerCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className="mb-0 pb-0">
          Bulk RSP Update Config Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {/* Filter Block  */}

          <div className="row">
            <div className="col-3 mb-3">
              <Controller
                control={control}
                name="franchise"
                render={({ field: { onChange, value } }) => (
                  <EntitySearchInput
                    type="franchise"
                    label="Search for Smart Stores"
                    placeholder="Search by Name/ID"
                    value={value}
                    callback={searchCb(onChange)}
                    uid="franchise"
                    filterParams={franchiseSearchFilter}
                    emptyLabel="No Smart Stores found"
                  />
                )}
              />
            </div>

            <div className="col-3 mb-2">
              <Controller
                control={control}
                name="brand"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    placeholder="Search By ID/Name"
                    value={value}
                    onSearch={onBrandSearch}
                    label="Search By Brand"
                    callback={searchCb(onChange)}
                    uid="brand"
                    disabled={!franchise}
                  />
                )}
              />
            </div>

            <div className="col-3 mb-2">
              <Controller
                control={control}
                name="category"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    placeholder="Search By ID/Name"
                    value={value}
                    onSearch={onCategorySearch}
                    label="Search By Category"
                    callback={searchCb(onChange)}
                    uid="category"
                    disabled={!franchise}
                  />
                )}
              />
            </div>

            <div className="col-3 mb-2">
              <Controller
                control={control}
                name="deal"
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    placeholder="Search By ID/Name"
                    value={value}
                    onSearch={onDealSearch}
                    label="Search By Deal"
                    callback={searchCb(onChange)}
                    uid="deal"
                    disabled={!franchise}
                  />
                )}
              />
            </div>
          </div>

          <Table
            data={data}
            loading={loadingData}
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
            paginationCb={paginationCb}
            sort={sortRef.current}
            sortCb={sortCb}
            callback={rspSubmitCb}
            busyLoader={busyLoader.show}
          />
        </div>

        {/* <BusyLoader show={busyLoader.show} /> */}
      </Modal.Body>
    </Modal>
  );
};

export default BulkRspUpdateModal;
