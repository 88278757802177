import {
  DateFormatter,
  DisplayWarehouseName,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";

import { memo } from "react";

const headers = [
  { label: "Sl.No.", width: "5%" },
  { label: "Snapshot ID", width: "15%" },
  { label: "Warehouse", width: "20%" },
  { label: "Reference Type", isCentered: true, width: "10%" },
  { label: "Opening Stock", isCentered: true, width: "8%" },
  { label: "Requested", isCentered: true, width: "8%" },
  { label: "Closing Stocks", isCentered: true, width: "8%" },
  { label: "Doc Status", isCentered: true, width: "8%" },
  { label: "Status", isCentered: true, width: "10%" },
  // { label: "Stock Transaction /Position", width: "10%" },
  { label: "Created On", width: "12%" },
  { label: "Remarks", width: "25%" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  return (
    <>
      <div className="tbl-scroll-container fixed-width-table">
        <table className="table table-bordered table-sm">
          <TableHeader data={headers} sort={sort} sortCb={sortCb} />
          <tbody>
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length} className="p-1">
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : null}
            {data.map((x, i) => (
              <tr key={x._id} className="fs-val-sm">
                <td>{paginationConfig.startSlNo + i}</td>
                <td>{x.snapShotId}</td>
                <td>
                  <DisplayWarehouseName whId={x.warehouse} />
                </td>
                <td className="text-center">
                  <HighlightText type="primary" isSoft={true} template={2}>
                    {x.referenceType}
                  </HighlightText>
                </td>
                <td className="text-center">{x.openingStock}</td>
                <td className="text-center">{x.requestQty}</td>
                <td className="text-center">{x.after}</td>
                <td className="text-center">
                  <HighlightText type={x.docStatusColor} template={2}>
                    {x.docStatus}
                  </HighlightText>
                </td>
                <td className="text-center">
                  <HighlightText type={x.statusColor} template={2}>
                    {x.status}
                  </HighlightText>
                </td>
                <td>
                  <DateFormatter date={x.createdAt} />
                </td>
                <td>
                  <div style={{ maxWidth: "200px" }} className="text-wrap">
                    {x.log}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
