import { ProductService } from "@sk/services";
import { Spinner } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";

const ProductCount = ({ type = "all", brandId }) => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    loadProductCount();
  }, [loadProductCount]);

  //   Prepare Filter According to Type
  const getFilter = useCallback(() => {
    const p = {
      brand: [brandId],
    };
    if (type == "active") {
      p.status = "Active";
    } else if (type == "inactive") {
      p.status = "Inactive";
    }
    return p;
  }, [brandId, type]);

  //   To load Product Count
  const loadProductCount = useCallback(async () => {
    const filter = getFilter();

    const r = await ProductService.getCount({ filter });

    setCount(Number.isNaN(r.resp) ? 0 : r.resp);

    setLoading(false);
  }, [getFilter]);

  return <>{loading ? <Spinner type="dots" /> : count}</>;
};

export default memo(ProductCount);
