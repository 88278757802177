import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, CommonService, OmsService } from "@sk/services";
import { AppCard, AppliedFilterLabel, BusyLoader } from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};

const formLabelsConfig = {
  status: {
    label: "Status",
  },
  warehouse: {
    warehouse: "",
  },
  createdAt: {
    label: "Created on",
    type: "dateRange",
  },
  orderId: {
    label: "OrderId",
  },
};

const defaultFormData = {
  status: "",
  orderId: "",
  createdAt: [],
  warehouse: AuthService.getDefaultWhId(),
};

const defaultSortOpt = { key: "createdAt", value: "desc" };

const prepareFilterParams = (
  pagination = {},
  filter = {},
  sort = {},
  additionalData = {}
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      "deals.id": additionalData.dId,
    },
  };

  if (filter.createdAt?.length) {
    p.filter.date = {
      $gte: set(filter?.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  if (filter.orderId.trim()) {
    p.filter._id = filter.orderId;
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (filter.warehouse) {
    p.filter.source = [filter.warehouse];
  }

  if (sort.key) {
    p.sort = {
      createdAt: sort.value == "desc" ? -1 : 1,
    };
  }

  return p;
};

const attachAdditionalDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const getData = async (params) => {
  const r = await OmsService.getOrderList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.userLoading = true;
        return x;
      })
    : [];
};

const Orders = ({ dId }) => {
  const [data, setData] = useState([]);

  const paginationRef = useRef({ ...defaultPageOpt });

  const sortRef = useRef({ ...defaultSortOpt });

  const filterDataRef = useRef({ ...defaultFormData });

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [filterLabels, setFilterLabels] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      {
        dId,
      }
    );
  }, [dId]);

  useEffect(() => {
    if (dId) {
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, dId]);

  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(params);
    // attaching additional Info
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const applyFilter = useCallback(async () => {
    loadList();
    prepareFilterLabels();

    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();

    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getCount, getFilterParams, loadList]);

  const getCount = useCallback(async (params) => {
    delete params.count;
    delete params.page;

    try {
      const r = await OmsService.getOrderCount(params);
      return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = data.formData;
      paginationRef.current = { ...defaultPageOpt };
      applyFilter();
    },
    [applyFilter]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const prepareFilterLabels = () => {
    const l = CommonService.prepareAppliedFilterLabels(
      formLabelsConfig,
      filterDataRef.current || {}
    );
    setFilterLabels(l);
  };

  return (
    <>
      {dId && (
        <AppCard>
          <Filter callback={filterFormCb} id={dId} />
        </AppCard>
      )}

      {filterLabels.length > 0 ? (
        <div className="mb-2">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null}

      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sort={sortRef.current}
        sortCb={sortCb}
        dId={dId}
      />

      <BusyLoader show={loadingData} />
    </>
  );
};

export default Orders;
