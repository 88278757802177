import {
  HighlightText,
  NoDataFound,
  PaginationBlock,
  TableHeader,
} from "@sk/uis";
import { memo } from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "HSN", width: "10%" },
  { label: "HSN Description", width: "35%" },
  { label: "GST (%)", width: "5%" },
  { label: "Cess (%)", width: "5%" },
  { label: "Additional Cess", width: "10%" },
  { label: "Linked Category", width: "13%" },
  { label: "Status", width: "7%" },
  { label: "Action", width: "10%" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  editCb,
  viewCb,
}) => {
  return (
    <>
      <table className="table  table-bordered bg-white">
        <TableHeader data={headers} />
        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {data.map((x, index) => (
            <tr key={x._id} className="key-val">
              <td>{paginationConfig.startSlNo + index}</td>
              <td>{x.hsn}</td>
              <td>{x.hsndesc || "NA"}</td>
              <td>{x.gst ? x.gst + "%" : "-"}</td>
              <td>{x.cess ? x.cess + "%" : "-"}</td>
              <td>
                {x.addCessValue
                  ? x.addCessValue + " " + x.addCessValueType
                  : "-"}
              </td>
              <td>
                <div>
                  {x.linkedCategories?.length
                    ? (x.linkedCategories || [])
                        .filter((k) => k.id && k.name)
                        .map((e, i) => (
                          <span
                            className="px-2 me-2 mb-1 badge bg-soft-primary fw-semibold"
                            key={i}
                          >
                            {e?.name}
                          </span>
                        ))
                    : "-"}
                </div>
              </td>
              <td>
                <HighlightText status={x.status} />
              </td>
              <td>
                <div>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => viewCb(x?._id)}
                  >
                    View
                  </button>
                  <button
                    className="btn btn-outline-warning ms-2 btn-sm"
                    onClick={() => editCb(x?._id)}
                  >
                    Edit
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
