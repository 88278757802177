import { AuthService, GrnService } from "@sk/services";
import { DateInput, EntitySearchInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const createdOnDateConfig = { mode: "range" };

const defaultFromValues = {
  vendor: [],
  grnId: "",
  createdAt: [],
  warehouse: AuthService.getDefaultWhId(),
  status: "",
};

const statusOptions = [
  { label: "All", value: "" },
  ...GrnService.getGrnStatuses().map((status) => ({
    label: status.label,
    value: status.value,
  })),
];

const Filter = ({ callback }) => {
  const { control, register, getValues } = useForm({
    defaultValues: defaultFromValues,
  });
  const onControlledInputChange = useCallback(
    (chngFn) => (e) => {
      chngFn(e);
      onFilterChange();
    },
    [onFilterChange]
  );

  const onSearch = useCallback(
    debounce(() => {
      onFilterChange();
    }, 700),
    [onFilterChange]
  );

  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  return (
    <>
      <form autoComplete="off">
        <div className="row">
          <div className="col-2">
            <TextInput
              type="text"
              name="grnId"
              callback={onSearch}
              register={register}
              label="Search By GRN ID"
              placeholder="Enter GRN ID"
              gap={1}
              size="sm"
            />
          </div>

          <div className="col">
            <Controller
              control={control}
              name="vendor"
              key="vendor"
              render={({ field: { onChange, value } }) => (
                <EntitySearchInput
                  type="vendor"
                  label="Search For Vendor"
                  placeholder="Search by Id/Name"
                  value={value}
                  callback={onControlledInputChange(onChange)}
                  uid="vendor"
                  emptyLabel="No Vendor Found"
                  gap={1}
                  size="sm"
                />
              )}
            />
          </div>

          <div className="col">
            <SelectInput
              label="Warehouse"
              register={register}
              callback={onFilterChange}
              name="warehouse"
              isMandatory={false}
              options={warehouseOptions}
              gap={1}
              size="sm"
            />
          </div>

          {/* CREATED ON DATE FILTER */}
          <div className="col-2">
            <label className="fw-semibold mb-1 fs-val-md"> Created On </label>
            <Controller
              name="createdAt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DateInput
                  placeholder="Choose"
                  value={value}
                  inpChange={onControlledInputChange(onChange)}
                  config={createdOnDateConfig}
                  template={2}
                  gap={1}
                  size="sm"
                />
              )}
            />
          </div>

          {/* GRN STATUS FILTER */}
          <div className="col-2">
            <SelectInput
              label="GRN Status"
              register={register}
              callback={onFilterChange}
              name="status"
              isMandatory={false}
              options={statusOptions}
              gap={1}
              size="sm"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
