import { appConfigs, PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  NoDataFound,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Tabs,
  Toaster,
} from "@sk/uis";
import produce from "immer";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

const containerStyle = {
  maxHeight: "calc(100vh - 350px)",
  overflowY: "auto",
};

const tabData = [
  { tabName: "All", key: "all" },
  { tabName: "Top Moving", key: "topMoving" },
  { tabName: "Slow Moving", key: "slowMoving" },
  { tabName: "Non Moving", key: "nonMoving" },
  { tabName: "Top Purchases", key: "topPurchases" },
];

const tableStyle = {
  minWidth: "1600px",
};

const stickyColumnStyle = {
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 1,
};

const headers = [
  {
    label: "",
    key: "select",
    isCentered: true,
    width: "3%",
    hasCustomTemplate: true,
    template: () => {
      return <input type="checkbox" className="align-middle" />;
    },
  },
  {
    label: "#",
    key: "slNo",
    isCentered: true,
    width: "3%",
  },
  {
    label: "ID",
    key: "productId",
    enableSort: true,
    width: "10%",
  },
  {
    label: "Name",
    key: "name",
    enableSort: true,
    isSticky: true,
    width: "25%",
  },
  {
    label: "Combo Qty",
    key: "comboQty",
    width: "10%",
  },
  {
    label: "MRP",
    key: "mrp",
    isCentered: true,
    enableSort: true,
    width: "5%",
  },
  {
    label: "RSP",
    key: "rsp",
    isCentered: true,
    enableSort: true,
    width: "10%",
  },
  {
    label: "SK Landing Price",
    key: "slc",
    isCentered: true,
    enableSort: true,
    width: "8%",
  },
  {
    label: "Store Landing Price",
    key: "b2bPrice",
    isCentered: true,
    enableSort: true,
    width: "8%",
  },
  {
    label: "Stock in Store",
    key: "qty",
    isCentered: true,
    enableSort: true,
    width: "8%",
  },
  {
    label: "Sold in Last 7d",
    key: "salesLast7Days",
    isCentered: true,
    width: "10%",
  },
  {
    label: "Sold in Last 15d",
    key: "salesLast15Days",
    isCentered: true,
    width: "10%",
  },
  {
    label: "Sold in Last 30d",
    key: "salesLast30Days",
    isCentered: true,
    width: "10%",
  },
  {
    label: "Sold in Last 90d",
    key: "salesLast90Days",
    isCentered: true,
    width: "10%",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params, type, signal) => {
  let dealIdsFromAnalytics = [];
  let dealsData = [];
  let onlyAnalyticsData = false;
  let rawResponse = null;

  let p = { ...params };
  delete p.sellerId;

  if (type === "topMoving" || type === "topPurchases") {
    const response = await PosService.getTopSellingPosStock(
      {
        page: params.page,
        count: params.count,
        filter: { _id: params.filter._id },
        sellerId: params.sellerId,
      },
      signal
    );

    dealIdsFromAnalytics = (response.resp || []).map((item) => item._id);
    onlyAnalyticsData = true;
  }

  if (onlyAnalyticsData) {
    const analyticsParams = {
      page: 1,
      count: dealIdsFromAnalytics.length,
      filter: {
        _id: params.filter._id,
      },
      dealId: { $in: dealIdsFromAnalytics },
    };

    const response = await PosService.getDealsStock(analyticsParams, signal);
    rawResponse = response.resp;
    dealsData = Array.isArray(response.resp) ? response.resp : [];
  } else {
    const response = await PosService.getDealsStock(p, signal);
    rawResponse = response.resp;
    dealsData = Array.isArray(response.resp) ? response.resp : [];
  }

  return {
    data: dealsData.map((item) => ({
      ...item,
      isSelected: false,
      comboQty: "",
    })),
    rawResponse,
  };
};

const getCount = async (params, type, signal) => {
  let p = { ...params, showCount: true };

  delete p.page;
  delete p.count;

  if (type === "topMoving" || type === "topPurchases") {
    const response = await PosService.getTopSellingPosStock(
      {
        filter: { _id: params.filter._id },
        sellerId: params.sellerId,
        outputType: "count",
      },
      signal
    );

    return {
      count:
        Array.isArray(response.resp) && response.resp.length > 0
          ? response.resp[0].total
          : 0,
    };
  } else {
    const r = await PosService.getDealsStock(p, signal); // Pass the signal to the service
    return {
      count:
        Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0].totalSku : 0,
    };
  }
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    dealFilter: {},
    inventoryType: "Available",
    filter: {
      _id: filter.storeId,
    },
    sellerId: filter.sellerId,
  };

  if (filter.pids?.length > 0) {
    p.dealFilter._id = { $nin: filter.pids.map((p) => p.id) };
  }

  const stockTypeMapping = {
    slowMoving: "slowMoving",
    nonMoving: "unSoldDeals",
    outOfStock: "NotAvailable", // Ensure this matches your backend logic
  };

  // Include the stockType key from the filter
  if (filter.stockType && stockTypeMapping[filter.stockType]) {
    p.stockType = stockTypeMapping[filter.stockType]; // Add stockType to params
  }

  // Check if selectedIds and tabKey are present
  if (filter.categoryId) {
    p.categoryFilter = {
      categoryId: filter.categoryId,
    };
  }

  if (filter.showInStockOnly && filter.type !== "outOfStock") {
    p.inventoryType = "Available";
  }

  if (filter.search) {
    p.dealFilter.$or = [
      { name: { $regex: filter.search, $options: "i" } },
      { _id: filter.search },
    ];
  }

  if (sort.key) {
    p.dealsort = {
      [sort.key]: sort.value == "asc" ? 1 : -1,
    };
  }

  if (Object.keys(p.dealFilter).length === 0) {
    delete p.dealFilter;
  }

  return p;
};

const FixedComboProductTable = ({ filterData, callback }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState(tabData[0]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const filterRef = useRef(filterData);
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortDataRef = useRef({ key: "productName", value: "asc" });

  const loadList = useCallback(async () => {
    setLoading(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(
        filterRef.current,
        paginationRef.current,
        sortDataRef.current
      ),
      activeTab.key
    );
    if (r.raw?.code == appConfigs.AJAX_REQ_CANCEL) {
      return;
    }
    setData(
      r.data.map((e) => {
        const existing = filterRef.current.pids.find((f) => f.id === e._id);
        return {
          ...e,
          comboQty: existing?.qty || "",
          isSelected: existing?.qty > 0,
        };
      })
    );
    setLoading(false);
  }, [activeTab.key]);

  const updateSelectedProducts = (item, isSelected) => {
    setData((prevData) =>
      produce(prevData, (draft) => {
        const index = draft.findIndex((e) => e._id === item._id);
        if (index !== -1) {
          draft[index].isSelected = isSelected;
          draft[index].comboQty = isSelected ? draft[index].comboQty : "";
        }
      })
    );
  };

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    setRecords({ loading: true, value: 0 });
    const countResponse = await getCount(
      prepareFilterParams(
        filterRef.current,
        paginationRef.current,
        sortDataRef.current
      )
    );
    setRecords({ loading: false, value: countResponse.count });
    paginationRef.current.totalRecords = countResponse.count;
  }, [loadList]);

  const searchDebounceCb = useCallback(
    debounce(() => {
      applyFilter();
    }, 500),
    []
  );

  const onSearch = useCallback(
    (e) => {
      filterRef.current = {
        ...filterRef.current,
        search: e.target.value,
      };
      setSearch(e.target.value);
      searchDebounceCb();
    },
    [searchDebounceCb]
  );

  useEffect(() => {
    filterRef.current = filterData;
    if (filterData?.storeId) {
      applyFilter();
    }
    setSearch("");
  }, [filterData, applyFilter]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortDataRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const handleProductSelect = useCallback((product) => {
    setData(
      produce((draft) => {
        const item = draft.find((item) => item._id === product._id);
        if (item) {
          item.isSelected = !item.isSelected;
          if (!item.isSelected) {
            item.comboQty = "";
          }
          updateSelectedProducts(product, item.isSelected);
        }
      })
    );
  }, []);

  const tabCb = (e) => {
    setActiveTab(e.value);
    applyFilter();
  };

  const handleComboQtyChange = (item, value) => {
    setData(
      produce((draft) => {
        const i = draft.find((e) => e._id === item._id);
        if (i) {
          i.comboQty = value;
          i.isSelected = i.comboQty !== "";
          updateSelectedProducts({ ...item, comboQty: value }, i.isSelected);
        }
      })
    );
  };

  const onProceed = () => {
    const selectedProducts = data.filter(
      (item) => item.isSelected && item.comboQty > 0
    );

    if (selectedProducts.length === 0) {
      Toaster.error("Please select at least one product with a valid quantity");
      return;
    }
    callback({
      action: "proceed",
      data: selectedProducts.map((e) => ({
        _id: e._id,
        name: e.name,
        mrp: e.mrp,
        comboQty: e.comboQty,
        allowedUnitTypes: e.allowedUnitTypes,
        sellInLooseQty: e.sellInLooseQty,
        images: e.images,
      })),
    });
  };

  const loadMore = async () => {
    setLoadingMore(true);
    paginationRef.current.activePage++;
    try {
      const r = await getData(
        prepareFilterParams(
          filterRef.current,
          paginationRef.current,
          sortDataRef.current
        ),
        activeTab.key
      );
      setData((prevData) => [...prevData, ...r.data]);
      setHasMoreData(r.data.length >= paginationRef.current.rowsPerPage);
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  return (
    <>
      <div className="mb-3 text-danger fs-val-sm">
        Please note already selected products are not shown in the list.
      </div>
      <AppCard noPad>
        <div className="border-bottom">
          <Tabs
            data={tabData}
            activeTab={activeTab}
            template={4}
            callback={tabCb}
          />
        </div>

        <div className="p-4">
          <div className="row mb-3 align-items-center">
            <div className="col">
              <PaginationSummary
                loadingTotalRecords={records.loading}
                paginationConfig={paginationRef.current}
                fwSize="sm"
              />
            </div>
            <div className="col-md-4 ms-auto">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Product Name/ID/Brand"
                  value={search}
                  onChange={onSearch}
                />
              </div>
            </div>
          </div>

          <div
            className="tbl-scroll-container custom-scrollbar thin-scrollbar border rounded"
            style={containerStyle}
          >
            <table
              className="table table-sm table-hover table-striped"
              style={tableStyle}
            >
              <TableHeader
                data={headers}
                sortCb={sortCb}
                sort={sortDataRef.current}
                isSticky={true}
                noBg
              />
              <tbody className="fs-val-sm">
                {loading ? (
                  <TableSkeletonLoader cols={headers.length} rows={10} />
                ) : data.length === 0 ? (
                  <tr>
                    <td colSpan={headers.length} className="text-center">
                      <NoDataFound>
                        <p>No products found</p>
                      </NoDataFound>
                    </td>
                  </tr>
                ) : (
                  data.map((item, index) => (
                    <tr key={item.productId}>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          className="align-middle"
                          onChange={() => handleProductSelect(item)}
                          checked={item.isSelected}
                        />
                      </td>
                      <td className="text-center">
                        {paginationRef.current.startSlNo + index}
                      </td>
                      <td>{item._id}</td>
                      <td style={stickyColumnStyle}>
                        <div className="text-wrap" style={{ width: "300px" }}>
                          {item.name}
                        </div>
                      </td>
                      <td className="text-center">
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          value={item.comboQty}
                          onChange={(e) =>
                            handleComboQtyChange(item, e.target.value)
                          }
                        />
                      </td>
                      <td className="text-center">
                        <Amount value={item.mrp} decimalPlace={2} />
                      </td>
                      <td className="text-center">
                        <Amount
                          value={item.retailerSellingPrice}
                          decimalPlace={2}
                        />
                      </td>
                      <td className="text-center">
                        <Amount value={item.slc} decimalPlace={2} />
                      </td>

                      <td className="text-center">
                        <Amount value={item.b2bPrice || 0} decimalPlace={2} />
                      </td>
                      <td className="text-center">{item.qty}</td>
                      <td className="text-center">
                        {item.salesData?.sevenday?._qty || 0}{" "}
                        {item.sellInLooseQty ? "kg" : "unit"}
                      </td>
                      <td className="text-center">
                        {item.salesData?.fifteenday?._qty || 0}{" "}
                        {item.sellInLooseQty ? "kg" : "unit"}
                      </td>
                      <td className="text-center">
                        {item.salesData?.thirtyday?._qty || 0}{" "}
                        {item.sellInLooseQty ? "kg" : "unit"}
                      </td>
                      <td className="text-center">
                        {item.salesData?.ninetyday?._qty || 0}{" "}
                        {item.sellInLooseQty ? "kg" : "unit"}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          <div className="text-center mt-3">
            {hasMoreData && !loading ? (
              <button
                onClick={loadMore}
                disabled={loadingMore}
                className="btn btn-sm btn-light fs-val-sm"
              >
                {loadingMore ? "Loading..." : "Load More"}
              </button>
            ) : null}
          </div>
        </div>
      </AppCard>

      <div className=" d-flex align-items-center bg-white shadow-sm p-4 z-100">
        <div className="flex-grow-1">
          Total products: <b>{data.filter((item) => item.isSelected).length}</b>
        </div>
        <div className="flex-shrink-0">
          <button className="btn btn-primary" onClick={onProceed}>
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default FixedComboProductTable;
