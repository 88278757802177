import { AuthService, ProductService } from "@sk/services";
import { SelectInput, TextInput } from "@sk/uis";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { manageView } from "../../../constantService";
import CategoryAttributeInput from "../CategoryAttributeInput";
import WhClassificationGrouping from "../WhClassificationGrouping";

const rbac = {
  editHsnRoles: ["AddProduct"],
};

const Categories = ({ mode }) => {
  const [hsnList, setHsnList] = useState([
    { label: "Please Select Category First", value: "" },
  ]);

  const isEditHsnEnabled = AuthService.isValidUserRole(rbac.editHsnRoles);

  const {
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const [attributesList, category] = useWatch({
    control,
    name: ["attributesList", "category"],
  });

  const categoryData = useMemo(() => category?.[0]?.value ?? {}, [category]);

  const loadHSNList = useCallback(
    async (ids) => {
      const r = await ProductService.getHsnMasters({
        select: "hsn",
        filter: {
          ["linkedCategories.id"]: {
            $in: [ids],
          },
        },
      });
      const d =
        Array.isArray(r.resp) && r.resp.length
          ? r.resp.map((x) => ({ label: x.hsn, value: x.hsn }))
          : [{ label: "No HSN Found", value: "" }];

      d.unshift({ label: "Please Select HSN", value: "" });

      setHsnList(d);
      setValue("hsnList", d);
    },
    [setValue]
  );

  useEffect(() => {
    if (categoryData._id) {
      loadHSNList(categoryData._id);
    }
  }, [categoryData._id, loadHSNList]);

  return (
    <>
      <div className="row p-1">
        {/* HSN Number */}
        <div className="col-6">
          {/* <div className="col-6">
            <SelectInput
              label="HSN Number"
              error={errors?.HSNNumber?.message}
              name="HSNNumber"
              options={getValues("hsnList") || []}
              register={register}
              info={manageView.infoContext.hsnNumber}
              disabled={mode == "Edit" ? !isEditHsnEnabled : false}
            />
          </div> */}
          {/* <div className="col-6">
            <TextInput
              label="HSN Number"
              error={errors?.HSNNumber?.message}
              name="HSNNumber"
              register={register}
              info={manageView.infoContext.hsnNumber}
              disabled={mode == "Edit" ? !isEditHsnEnabled : false}
            />
          </div> */}

          <div className="col-6">
            {categoryData?.categoryType == "Electronics" && (
              <>
                {/* Dealer Pice */}
                <TextInput
                  type="number"
                  label="Dealer Price"
                  error={errors?.dealer_price?.message}
                  name="dealer_price"
                  isMandatory={true}
                  register={register}
                  info={manageView.infoContext.dealerPrice}
                />
              </>
            )}
          </div>
          <div className="col-12">
            {attributesList?.[0]?.attributes?.length > 0 &&
              attributesList.map((item, index) => (
                <CategoryAttributeInput
                  key={index}
                  data={item}
                  parentIndex={index}
                />
              ))}
          </div>
        </div>

        <div className="col-6">
          {categoryData?.classifications?.length > 0 && (
            <WhClassificationGrouping
              classifications={categoryData.classifications}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Categories);
