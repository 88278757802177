import { useAttachAdditionalData } from "@sk/hooks";
import { DealService, FranchiseService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  KeyVal,
  NoDataFound,
  PageLoader,
} from "@sk/uis";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import StoreComboAuditLog from "../components/StoreComboAuditLog";
import StoreComboLinkedDealTable from "../components/StoreComboLinkedDealTable";

const canvasStyle = { width: "70%" };

const additionalTableDataConfig = [
  {
    key: "createdBy",
    api: "user",
    loadingKey: "createdByLoading",
    dataKey: "_createdBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "modifiedBy",
    api: "user",
    loadingKey: "modifiedByLoading",
    dataKey: "_modifiedBy",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
];

const attachStoreDealData = async (fid, deals) => {
  let data = [...deals];

  const dIds = deals.map((e) => e._id);

  if (dIds.length > 0) {
    const sr = await FranchiseService.getFranchiseInventorySummary({
      page: 1,
      count: dIds.length,
      franchiseId: fid,
      groupbycondName: "name",
      groupbycond: "deal",
      dealFilter: { _id: { $in: dIds } },
      filter: { _id: fid },
    });

    const srDeals = Array.isArray(sr.resp) ? sr.resp : [];

    data = produce(data, (draft) => {
      draft.forEach((e) => {
        const t = srDeals.find((x) => x._id == e._id) || {};
        e._hasPurchased = t._id ? true : false;
        e._mrp = t.mrp || e.mrp;
        e._rsp = t._finalSellingPrice || e._mrp;
        e._stock = t.qty || 0;
        e._purchasePrice = t?._recentPurcahsePrice || 0;
        e._slc = t?.slc || 0;
      });
    });
  }

  return { data };
};

const StoreComboViewModal = ({ show = false, callback, configId = "" }) => {
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [loading, setLoading] = useState(false);

  const [deals, setDeals] = useState([]);

  const [details, setDetails] = useState({});

  useEffect(() => {
    if (show) {
      if (configId) {
        loadData(configId);
      } else {
        setDetails({});
      }
    }
  }, [show, configId, loadData]);

  const loadData = useCallback(
    async (id) => {
      let details = {};

      setLoading(true);
      const r = await DealService.getStoreComboDeals({
        filter: { _id: id },
      });
      details = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};

      // fetch the franchise
      if (details.franchiseId) {
        const f = await FranchiseService.getFranchise(details.franchiseId, {
          select: "name,town,district,state,pincode",
        });
        if (f.resp?._id) {
          details._fran = f.resp;
        }
      }

      const deals = (details.deals || []).map((e) => ({
        _id: e.dealId,
        name: e.name,
        _pid: e.productId,
        mrp: e.mrp,
        _discount: e.discountValue,
        _qty: e.qty,
        sellInLooseQty: e.sellInLooseQty,
      }));

      const rs = await attachStoreDealData(details.franchiseId, deals);

      setDeals(
        FranchiseService.calculateStoreComboPerc(
          rs.data,
          details.discountValue,
          details.discountType === "Fixed" ? "price" : "percentage"
        )
      );

      let tmp = [];
      // Attach User Info
      setAdditionalData([{ ...details }], additionalTableDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == additionalTableDataConfig.length) {
          setDetails([...attachAllData([{ ...details }], tmp)][0]);
        }
      });

      setDetails({ ...details });

      setLoading(false);
    },
    [attachAllData, setAdditionalData]
  );

  const closeModal = () => {
    callback({ action: "close" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        placement="end"
        onHide={closeModal}
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          <Offcanvas.Title>
            <div className="fs-val-lg">
              View Combo Deal Config {configId ? " #" + configId : ""}{" "}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {loading ? <PageLoader /> : null}

          {!loading && !details?._id ? (
            <NoDataFound> No data to display </NoDataFound>
          ) : null}

          {!loading && details?._id ? (
            <>
              <AppCard>
                <div className="row">
                  <div className="col-6 mb-4">
                    <KeyVal label="Store Details" template="col">
                      <div className="mb-1">
                        {details._fran?.name} - {details._fran?._id}
                      </div>
                      <div className="fs-val-sm text-muted">
                        {details._fran?.town}, {details._fran?.district},
                        {details._fran?.state} - {details._fran?.pincode}
                      </div>
                    </KeyVal>
                  </div>

                  <div className="col">
                    <KeyVal label="Combo Deal" template="col">
                      <div className="mb-1">{details.comboDealName}</div>
                      <div className="fs-val-sm text-muted">
                        ID: {details.comboDealId}
                      </div>
                    </KeyVal>
                  </div>

                  <div className="col-auto">
                    <KeyVal label="Is Active" template="col">
                      <HighlightText
                        status={details.isActive ? "Yes" : "No"}
                        isBadge={true}
                      />
                    </KeyVal>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3 mb-4">
                    <KeyVal label="Combo Price" template="col">
                      <div className="text-danger">
                        {details.discountType == "Fixed" ? (
                          <Amount
                            value={details.discountValue || 0}
                            decimalPlace={2}
                          />
                        ) : (
                          <>{details.discountValue}%</>
                        )}
                      </div>
                    </KeyVal>
                  </div>

                  <div className="col-3 mb-4">
                    <KeyVal label="Created On" template="col">
                      <DateFormatter date={details.createdAt} />
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Created By" template="col">
                      {details._createdBy?.name || "--"}
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Last Updated On" template="col">
                      <DateFormatter date={details.createdAt} />
                    </KeyVal>
                  </div>

                  <div className="col-3">
                    <KeyVal label="Last Updated By" template="col">
                      {details._modifiedBy?.name || "--"}
                    </KeyVal>
                  </div>
                </div>
              </AppCard>

              <AppCard>
                {deals.length > 0 ? (
                  <>
                    <div className="fs-val-md mb-1 text-muted">
                      List of linked deals for the above selected combo deal
                    </div>
                    <StoreComboLinkedDealTable deals={deals} />
                  </>
                ) : null}
              </AppCard>

              <AppCard title="Audit Log">
                <StoreComboAuditLog configId={configId} />
              </AppCard>
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default StoreComboViewModal;
