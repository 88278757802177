import { AuthService } from "@sk/services";
import { DateInput, DatePickerInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const warehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const createdOnDateConfig = { mode: "range" };

const defaultFromValues = {
  reference: "",
  warehouse: AuthService.getDefaultWhId(),
  createdAt: [],
  referenceType: "",
  type: "",
};

const referenceTypeOptions = [
  { label: "All Types", value: "" },
  { label: "GRN", value: "GRN" },
  { label: "GRN Put Away", value: "GRN Put Away" },
  { label: "GRN Dock", value: "GRN Dock" },
  { label: "HPB Release", value: "HPB Release" },
  { label: "Invoice Cancellation", value: "Invoice Cancellation" },
  { label: "KPB Put Away", value: "KPB Put Away" },
  { label: "Release", value: "Release" },
  { label: "Return", value: "Return" },
  { label: "Stock Correction", value: "Stock Correction" },
  { label: "Stock Movement", value: "Stock Movement" },
];

const typeOptions = [
  { label: "All Types", value: "" },
  { label: "In", value: "IN" },
  { label: "Out", value: "OUT" },
];

const Filter = ({ callback }) => {
  const { control, register, getValues } = useForm({
    defaultValues: defaultFromValues,
  });

  const onFilterChange = useCallback(() => {
    const data = getValues();
    callback({ formData: data });
  }, [callback, getValues]);

  const onSearch = useCallback(debounce(onFilterChange, 700), [onFilterChange]);

  const onControlledInputChange = useCallback(
    (chngFn) => (e) => {
      chngFn(e);
      onFilterChange();
    },
    [onFilterChange]
  );

  return (
    <>
      <form autoComplete="off">
        <div className="row">
          <div className="col-3">
            <TextInput
              register={register}
              name="reference"
              callback={onSearch}
              type="text"
              label="Reference ID"
              placeholder="Enter Reference ID"
              gap={1}
              size="sm"
            />
          </div>

          <div className="col-3 ">
            <SelectInput
              label="Warehouse"
              register={register}
              callback={onFilterChange}
              name="warehouse"
              isMandatory={false}
              options={warehouseOptions}
              gap={1}
              size="sm"
            />
          </div>

          <div className="col-2 me-auto">
            <SelectInput
              label="Type"
              register={register}
              name="type"
              callback={onFilterChange}
              isMandatory={false}
              options={typeOptions}
              gap={1}
              size="sm"
            />
          </div>

          <div className="col-2">
            <SelectInput
              label="Reference Type"
              register={register}
              callback={onFilterChange}
              name="referenceType"
              isMandatory={false}
              options={referenceTypeOptions}
              gap={1}
              size="sm"
            />
          </div>

          {/* CREATED ON DATE FILTER */}
          <div className="col-2">
            <label className="fw-semibold mb-1 fs-val-md"> Created On </label>
            <Controller
              name="createdAt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DateInput
                  placeholder="Choose"
                  value={value}
                  callback={onControlledInputChange(onChange)}
                  config={createdOnDateConfig}
                  gap={1}
                  size="sm"
                  template={2}
                />
              )}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default memo(Filter);
