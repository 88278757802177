import { MarketingDashboardNavComponent } from "@sk/features";
import { useIsFirstRender } from "@sk/hooks";
import {
  AuthService,
  CommonService,
  DealService,
  appConfigs,
} from "@sk/services";
import {
  Alert,
  AppCard,
  AppliedFilterLabel,
  AppSlide,
  AppSwiper,
  BusyLoader,
  PageInfo,
  PaginationSummary,
  Rbac,
  Toaster,
} from "@sk/uis";
import { set } from "date-fns";
import { produce } from "immer";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FilterForm from "./components/FilterForm";
import SummaryCardBox from "./components/SummaryCardBox";
import Table from "./components/Table";
import listView from "./constantService";
import AdvanceFilterModal from "./modals/AdvanceFilterModal";
import BulkRspConfigModal from "./modals/bulk/BulkRspConfigModal";
import ManageRspConfigModal from "./modals/ManageRspConfigModal";
import ViewRspConfigModal from "./modals/ViewRspConfigModal";
import BulkRspUpdateModal from "./modals/bulk-update/BulkRspUpdateModal";

const rbac = {
  addButton: ["AddRSPConfig"],
  bulkConfig: ["AddBulkRSPConfig"],
};

const canViewSkLandingCost = AuthService.isValidUserRole(["ViewSkLandingCost"])
  ? true
  : false;

const breadcrumbs = listView.breadcrumbs;

const defaultFormData = listView.filterFormData;

const defaultPageOpt = listView.pagination;

const defaultSortOpt = { key: "createdAt", value: "dec" };

const formLabelsConfig = listView.formLabels;

const defaultSummaryCardData = listView.summaryCardData;

// To Prepare params from API
const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      "franchiseInfo.subType": { $in: appConfigs.DARKSTORE_SUB_TYPES },
    },
    slc: canViewSkLandingCost,
  };

  if (sort?.key) {
    p.sort = {
      [sort.key]: sort.value == "asc" ? 1 : -1,
    };
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (filter.validityPeriod.length) {
    p.filter["validityPeriod.startDate"] = {
      $gte: set(filter.validityPeriod[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    };

    p.filter["validityPeriod.endDate"] = {
      $lte: set(filter.validityPeriod[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.isFixedPrice) {
    p.filter["isFixedPrice"] = filter.isFixedPrice == "YES" ? true : false;
  }

  if (filter.createdAt.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.hasOffer) {
    p.filter.hasOffer = filter.hasOffer == "YES";
  }

  if (filter.groupType && filter.groupType != "ALL") {
    p.filter.groupType = filter.groupType;
  }

  ["brand", "category", "deal"].forEach((x) => {
    // getting the value of brand Cate
    const type = filter[x].trim();

    const typeRegex = { $regex: type, $options: "gi" };
    if (type) {
      p.filter.$or = [{ [`${x}.id`]: typeRegex }, { [`${x}.name`]: typeRegex }];
    }
  });
  if (filter?.franchise?.length) {
    p.filter["franchiseInfo.id"] = filter.franchise[0].value._id;
  }
  return p;
};

const RspConfigList = () => {
  const isFirstRender = useIsFirstRender();

  const formMethods = useForm({
    defaultValues: defaultFormData,
  });

  const [franchise] = useWatch({
    control: formMethods.control,
    name: ["franchise"],
  });

  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showManageModal, setShowManageModal] = useState(false);

  const [showViewModal, setShowViewModal] = useState(false);

  const [showBulkRspConfigModal, setShowBulkRspConfigModal] = useState(false);

  const [showBulkRspUpdateModal, setShowBulkRspUpdateModal] = useState(false);

  const [filterLabels, setFilterLabels] = useState([]);

  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const [summaryCards, setSummaryCards] = useState(() =>
    [...defaultSummaryCardData].map((x) => {
      let p = prepareFilterParams(
        defaultPageOpt,
        defaultFormData,
        defaultSortOpt
      );
      if (p.filter) {
        delete p.filter.status;
      }
      return {
        ...x,
        filterParams: { ...p.filter, ...x.defaultFilterParams },
      };
    })
  );

  const manageModalDataRef = useRef({ data: {}, id: "", mode: "add" });

  const viewModalDataRef = useRef({});

  const filterDataRef = useRef(defaultFormData);

  const sortRef = useRef({ ...defaultSortOpt });

  const paginationRef = useRef({ ...defaultPageOpt });

  const advanceFilterRef = useRef({});

  const navigate = useNavigate();

  const closeManageModal = useCallback(
    (r) => {
      setShowManageModal(false);
      if (r.status == "submit") {
        formMethods.setValue("groupType", "");
        formMethods.setValue("createdAt", []);
        applyFilter(false);
      }
    },
    [applyFilter, formMethods]
  );

  const openCreateModal = useCallback(() => {
    manageModalDataRef.current.mode = "add";
    setShowManageModal(true);
  }, []);

  const openCreateBulkModal = useCallback(() => {
    setShowBulkRspConfigModal(true);
  }, []);

  const onBulkRspConfigModalCb = useCallback(
    (d) => {
      setShowBulkRspConfigModal(false);
      if (d.status == "submit") {
        init();
      }
    },
    [init]
  );

  const openUpdateBulkModal = useCallback(() => {
    setShowBulkRspUpdateModal(true);
  }, []);

  const onBulkRspUpdateModalCb = useCallback(
    (d) => {
      setShowBulkRspUpdateModal(false);
      if (d.status == "submit") {
        init();
      }
    },
    [init]
  );

  const openEditModal = useCallback((id) => {
    manageModalDataRef.current.mode = "edit";
    manageModalDataRef.current.id = id;
    setShowManageModal(true);
  }, []);

  const closeViewModal = useCallback(() => {
    viewModalDataRef.current = {};
    setShowViewModal(false);
  }, []);

  const openRspViewModal = useCallback((x) => {
    viewModalDataRef.current = x;
    setShowViewModal(true);
  }, []);

  const applyFilter = useCallback(
    async (resetPagination = true) => {
      if (resetPagination) {
        paginationRef.current = { ...defaultPageOpt };
      }

      //  Preparing Label Filter
      prepareFilterLabels();

      loadSummary();

      loadList();

      loadCount();
    },
    [loadList, prepareFilterLabels, loadSummary, loadCount]
  );

  const loadCount = useCallback(async () => {
    const params = getFilterParams();
    // for total records
    setLoadingTotalRecords(true);

    const c = await getCount(params);

    // Setting Total Count
    const t = c.statusCode == 200 && c.resp ? c.resp : 0;

    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, []);

  const loadSummary = useCallback(() => {
    // setting summary card filterParams
    const params = getFilterParams();
    if (!isFirstRender) {
      setSummaryCards(
        produce((draft) => {
          draft.forEach((x) => {
            x.filterParams = { ...params.filter, ...x.defaultFilterParams };
            if (x.key == "hasOffer" || x.key == "overall") {
              delete x.filterParams.status;
            }
          });
        })
      );
    }
  }, [getFilterParams, isFirstRender]);

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const r = await getData(getFilterParams());
    if (r.statusCode !== 200) {
      Toaster.error("Failed to fetch list,Please Reload");
      setData([]);
      setLoadingData(false);
      return;
    }
    const d = r.resp || [];
    d.forEach((e) => {
      if (e?.isFixedPrice) {
        let rspPrice = e.fixedPrice;
        e._rspPrice = rspPrice;
        e._skProfit = rspPrice - e.slc;
        e._storeProfit = rspPrice - e.b2bPrice;
        e._skProfitPerc = CommonService.roundedByDecimalPlace(
          (e._skProfit / e.slc) * 100,
          2
        );
        e._storeProfitPerc = CommonService.roundedByDecimalPlace(
          (e._storeProfit / e.b2bPrice) * 100,
          2
        );
      } else {
        const v = e.hasOffer ? e.specialDiscount : e.discount;
        let mrp = e?.mrp;
        let rspPrice = mrp - mrp * (v / 100);
        e._rspPrice = rspPrice;
        e._skProfit = rspPrice - e.slc;
        e._storeProfit = rspPrice - e.b2bPrice;
        e._skProfitPerc = CommonService.roundedByDecimalPlace(
          (e._skProfit / e.slc) * 100,
          2
        );
        e._storeProfitPerc = CommonService.roundedByDecimalPlace(
          (e._storeProfit / e.b2bPrice) * 100,
          2
        );
      }

      // Rounding special discount
      if (e._id) {
        e.discount = CommonService.roundedByDecimalPlace(e.discount, 3);
      }

      if (e.hasOffer) {
        e.specialDiscount = CommonService.roundedByDecimalPlace(
          e.specialDiscount,
          3
        );
      }
    });

    const u = await CommonService.attachUserInfo(d, ["modifiedBy"]);
    setData(u);
    setLoadingData(false);
  }, [getFilterParams]);

  const getFilterParams = useCallback(() => {
    filterDataRef.current = Object.assign(
      {},
      { ...advanceFilterRef.current },
      { ...formMethods.getValues() }
    );
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  const paginationCb = useCallback((data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (params) => {
    return DealService.getCustomerCatalogPriceConfig(params);
  };

  const getCount = (params) => {
    let filter = params.filter;
    return DealService.customerCatalogPriceConfigCount({
      filter,
      outputType: "count",
    });
  };

  const filterCb = useCallback(() => {
    filterDataRef.current = Object.assign(
      {},
      { ...advanceFilterRef.current },
      { ...formMethods.getValues() }
    );
    applyFilter();
  }, [applyFilter, formMethods]);

  const sortCb = useCallback(
    (data) => {
      sortRef.current = data;
      applyFilter();
    },
    [applyFilter]
  );

  const prepareFilterLabels = useCallback(() => {
    const v = filterDataRef.current;
    if (v?.franchise?.length) {
      delete v.franchise;
    }
    const l = CommonService.prepareAppliedFilterLabels(formLabelsConfig, v);
    setFilterLabels(l);
  }, []);

  const onAdvanceFilterClickCb = useCallback(() => {
    setShowAdvanceFilter(true);
  }, []);

  const onAdvanceFilterCb = useCallback(
    (r) => {
      const status = r.status;
      if (status == "applied" || status == "reset") {
        advanceFilterRef.current = { ...r.formData };
        filterDataRef.current = { ...formMethods.getValues(), ...r.formData };
        setShowAdvanceFilter(false);
        applyFilter();
      }
      if (status == "closed") {
        setShowAdvanceFilter(false);
      }
    },
    [applyFilter, formMethods]
  );

  const markAsActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to mark as Active?",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = { ...item };
      p.franchiseId = p.franchiseInfo?.id || "";
      p.status = "Active";
      if (p.hasOffer) {
        p.startDate = p.validityPeriod?.startDate;
        p.endDate = p.validityPeriod?.endDate;
      }
      const r = await DealService.updateCustomerCatalogPriceConfig(p);
      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "Active";
          }
        })
      );

      Toaster.success("Updated Successfully");
    }
  }, []);

  const markAsInActive = useCallback(async (item) => {
    const r = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to mark as In-Active?",
    });
    if (r.isConfirmed) {
      setBusyLoader({ show: true, msg: "" });
      const p = { ...item };
      p.franchiseId = p.franchiseInfo?.id || "";
      p.status = "Inactive";
      if (p.hasOffer) {
        p.startDate = p.validityPeriod?.startDate;
        p.endDate = p.validityPeriod?.endDate;
      }
      const r = await DealService.updateCustomerCatalogPriceConfig(p);

      setBusyLoader({ show: false });

      if (r.statusCode != 200) {
        Toaster.error(r.resp.message || "Failed to update");
        return;
      }

      setData(
        produce((draft) => {
          const i = draft.findIndex((e) => e._id === item._id);
          if (i != -1) {
            draft[i].status = "Inactive";
          }
        })
      );
      Toaster.success("Updated Successfully");
    }
  }, []);

  const onClickDownload = async () => {
    if (!franchise?.length) {
      Toaster.error("Please Choose Franchise for Download ");
      return;
    }

    setBusyLoader({ show: true, msg: "" });
    let p = { ...getFilterParams(), outputType: "download" };

    let r = await getData(p);

    if (r.statusCode != 200) {
      Toaster.error("Unable to Download Report,Please try again ");
      setBusyLoader({ show: false, msg: "" });
      return;
    }

    const url = appConfigs.ASSET + "/" + r.resp.downloadLink;
    window.open(url, "_blank");
    setBusyLoader({ show: false, msg: "" });
  };

  const onClickEditCb = (index) => {
    let l = [...data];
    l.forEach((e, i) => {
      if (index == i) {
        e._edit = true;
      }
    });
    setData([...l]);
  };

  const onClickCancelEditCb = (index) => {
    let l = [...data];
    l.forEach((e, i) => {
      if (index == i) {
        e._edit = false;
      }
    });
    setData([...l]);
  };

  const onRspConfigCb = (d) => {
    if (d.status && d.status == "submit") {
      let l = [...data];
      let r = l.map((e, index) => {
        if (index == d.index) {
          let raw = { ...d.data, _updated: true, _edit: false };
          if (raw?.isFixedPrice) {
            let rspPrice = raw.fixedPrice;
            raw._rspPrice = rspPrice;
            raw._skProfit = rspPrice - raw.slc;
            raw._storeProfit = rspPrice - raw.b2bPrice;
            raw._skProfitPerc = CommonService.roundedByDecimalPlace(
              (raw._skProfit / raw.slc) * 100,
              2
            );
            raw._storeProfitPerc = CommonService.roundedByDecimalPlace(
              (raw._storeProfit / raw.b2bPrice) * 100,
              2
            );
          } else {
            const v = raw.hasOffer ? raw.specialDiscount : raw.discount;
            let mrp = raw?.mrp;
            let rspPrice = mrp - mrp * (v / 100);
            raw._rspPrice = rspPrice;
            raw._skProfit = rspPrice - raw.slc;
            raw._storeProfit = rspPrice - raw.b2bPrice;
            raw._skProfitPerc = CommonService.roundedByDecimalPlace(
              (raw._skProfit / raw.slc) * 100,
              2
            );
            raw._storeProfitPerc = CommonService.roundedByDecimalPlace(
              (raw._storeProfit / raw.b2bPrice) * 100,
              2
            );
          }
          return raw;
        } else {
          return e;
        }
      });
      setData([...r]);
    }
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <PageInfo
            title="Marketing Dashboard - Retailer Selling Price - POS"
            breadcrumbs={breadcrumbs}
            navigate={navigate}
          />
        </div>
      </div>

      <MarketingDashboardNavComponent
        activeTab="rsp"
        router={navigate}
        subTypeKey="config"
      />

      <div className="mt-3">
        {/* SUMMARY OVERVIEW */}
        <AppSwiper config={{ slidesPerView: 4, spaceBetween: 10 }}>
          {summaryCards.map((card) => (
            <AppSlide key={card.key}>
              <SummaryCardBox
                label={card.label}
                color={card.color}
                filterParams={card.filterParams}
                img={card.img}
              />
            </AppSlide>
          ))}
        </AppSwiper>

        <AppCard>
          <div className="row justify-content-between">
            <div className="col-6">
              <FormProvider {...formMethods}>
                <FilterForm
                  callback={filterCb}
                  openAdvFilterModal={onAdvanceFilterClickCb}
                  isDirty={formMethods.formState.isDirty}
                />
              </FormProvider>
            </div>
            <div className="col-auto">
              <Rbac roles={rbac.addButton}>
                <button
                  className="btn btn-primary me-2"
                  onClick={openCreateModal}
                >
                  <span className="bi bi-plus-square me-1"></span>
                  Create
                </button>
              </Rbac>
              <Rbac roles={rbac.bulkConfig}>
                <button
                  className="btn btn-primary me-2"
                  onClick={openCreateBulkModal}
                >
                  <span className="bi bi-plus-square me-1"></span>
                  Create Bulk
                </button>
              </Rbac>

              {/* <Rbac roles={rbac.addButton}>
                <button
                  className="btn btn-outline-primary me-2"
                  onClick={openUpdateBulkModal}
                >
                  <span className="bi bi-plus-square me-1"></span>
                  Update Bulk
                </button>
              </Rbac> */}

              <button
                className="btn btn-outline-warning me-2 ms-2"
                onClick={onClickDownload}
              >
                <span className="bi bi-download me-1"></span>
                Download
              </button>
            </div>
          </div>
        </AppCard>

        <AppCard>
          {filterLabels.length > 0 ? (
            <div className="mb-3">
              <AppliedFilterLabel
                labels={filterLabels}
                callback={onAdvanceFilterCb}
              />
            </div>
          ) : null}

          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />

          <Table
            data={data}
            loading={loadingData}
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
            paginationCb={paginationCb}
            openEditModal={openEditModal}
            openViewModal={openRspViewModal}
            sort={sortRef.current}
            sortCb={sortCb}
            markAsActive={markAsActive}
            markAsInActive={markAsInActive}
            onClickEditCb={onClickEditCb}
            onRspConfigCb={onRspConfigCb}
            onClickCancelEditCb={onClickCancelEditCb}
          />
        </AppCard>

        <BusyLoader show={busyLoader.show} />
      </div>

      {/* DETAILS MODAL */}
      <ViewRspConfigModal
        show={showViewModal}
        closeModal={closeViewModal}
        modalData={viewModalDataRef.current}
      />

      {/* FORM */}
      <ManageRspConfigModal
        show={showManageModal}
        closeModal={closeManageModal}
        id={manageModalDataRef.current.id}
        mode={manageModalDataRef.current.mode}
      />

      {/* ADVANCE FILTER */}
      <AdvanceFilterModal
        show={showAdvanceFilter}
        callback={onAdvanceFilterCb}
        formData={advanceFilterRef.current}
      />

      <BulkRspConfigModal
        show={showBulkRspConfigModal}
        fData={franchise}
        callback={onBulkRspConfigModalCb}
      />

      <BulkRspUpdateModal
        show={showBulkRspUpdateModal}
        callback={onBulkRspUpdateModalCb}
      />
    </>
  );
};

export default memo(RspConfigList);
