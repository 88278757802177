import { HighlightText, TableHeader } from "@sk/uis";
import React from "react";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "ID", width: "8%" },
  { label: "Name", width: "20%" },
  { label: "Pincode", width: "10%" },
  { label: "Town" },
  { label: "District" },
  { label: "State" },
  { label: "Status", width: "20%" },
];

const StoreCloneResult = ({ data }) => {
  return (
    <>
      <table className="table table-bordered table-sm">
        <TableHeader data={headers} fwSize="sm" />
        <tbody>
          {data.map((e, k) => (
            <tr key={e.fran?._id} className="fs-val-sm">
              <td>{k + 1}</td>
              <td>{e.fran?._id}</td>
              <td>{e.fran?.name}</td>
              <td>{e.fran?.pincode}</td>
              <td>{e.fran?.town}</td>
              <td>{e.fran?.district}</td>
              <td>{e.fran?.state}</td>
              <td>
                <div className="text-uppercase fw-semibold">
                  <HighlightText status={e.isSuccess ? "Success" : "Error"} />
                </div>
                {!e.isSuccess && e.errMsg ? (
                  <div className="text-danger mt-2">{e.errMsg}</div>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default StoreCloneResult;
