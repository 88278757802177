import { NavService } from "@sk/services";
import { KeyVal, NoDataFound, TableHeader } from "@sk/uis";
import { sortBy } from "lodash";
import { memo, useMemo } from "react";
import { Modal } from "react-bootstrap";

const headers = [
  { label: "Sl.No" },
  { label: "Product Name" },
  // { label: "Product ID", isCentered: true },
];

const style = {
  modalHeaderStyle: {
    backgroundColor: "#e4edff",
  },
};

const ViewLinkedProductModal = ({ show, closeModal, modalData = {} }) => {
  const productsList = useMemo(() => {
    return sortBy(modalData.product, ["name"]) ?? [];
  }, [modalData?.product]);

  // navigate To View Product Page
  const viewProduct = (id) => {
    NavService.openInNewTab("/cms/product/view", { id });
  };

  return (
    <Modal
      onHide={closeModal}
      size="lg"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        style={style.modalHeaderStyle}
      >
        Linked Product
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row p-2 border-bottom">
          <div className="col-9">
            <KeyVal template="col" label="Deal Name">
              <span className="fs-val-normal">{modalData.dealName}</span>
            </KeyVal>
          </div>
          <div className="col-3">
            <KeyVal template="col" label="Deal ID">
              {modalData.id}
            </KeyVal>
          </div>
        </div>
        <div className="fs-val-md my-1 p-3 pb-1">
          Product Linked to Deals List
        </div>
        <div className="p-3 py-1">
          <table className="table table-bordered">
            <TableHeader data={headers} />
            <tbody>
              {!modalData?.product?.length ? (
                <NoDataFound>No Product Linked Found</NoDataFound>
              ) : (
                productsList.map((rowData, i) => (
                  <tr key={i} className="fs-val-md">
                    <td>{i + 1}</td>
                    <td>
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => viewProduct(rowData.id)}
                        className="cursor-pointer text-primary"
                      >
                        {rowData.name} - {rowData.id}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(ViewLinkedProductModal);
