import {
  Amount,
  AppCard,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
} from "@sk/uis";
import { orderBy } from "lodash";

import { useEffect, useRef, useState } from "react";

const tableHeader = [
  { label: "Sl.no" },
  { label: "Seller Id" },
  { label: "Name", width: "15%" },
  { label: "MRP", isCentered: true },
  { label: "Offer Price", isCentered: true },
  { label: "Shipping Price", isCentered: true },
  { label: "Check Price", isCentered: true },
  { label: "Check Quantity", isCentered: true },
  { label: "B2B", isCentered: true },
  { label: "SF Min Qty", isCentered: true },
  { label: "SF Max Qty", isCentered: true },
  { label: "Shipping Time (day)", isCentered: true },
];

const ViewDealLinkedSellers = ({ sellers = [] }) => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const paginationRef = useRef({
    ...defaultPagination,
    totalRecords: sellers.length,
  });

  useEffect(() => {
    setData(getData(sellers, "", paginationRef.current).sellers);
  }, [sellers]);

  const handleSearch = (e) => {
    paginationRef.current = {
      ...defaultPagination,
    };
    setSearchQuery(e.target.value);
    const d = getData(sellers, e.target.value, paginationRef.current);
    setData(d.sellers);
    paginationRef.current.totalRecords = d.totalRecords;
  };

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    const d = getData(sellers, searchQuery, paginationRef.current);
    setData(d.sellers);
  };

  return (
    <AppCard>
      <div className="row">
        <div className="col-4">
          <input
            className="form-control mb-3"
            type="text"
            placeholder="Search by Seller ID or Name"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      </div>

      <PaginationSummary
        loadingTotalRecords={false}
        paginationConfig={paginationRef.current}
        className="mb-3"
      />

      <div
        className="tbl-scroll-container customr-scrollbar thin-scrollbar mb-2"
        style={containerStyle}
      >
        <table className="table table-sm" style={tableStyle}>
          <TableHeader data={tableHeader} noBg={true} isSticky={true} />
          {data.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={tableHeader.length}>
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            </tbody>
          )}
          {data.length > 0 && (
            <tbody>
              {data.map((x, i) => (
                <tr key={x._id || i} className="fs-val-md">
                  <td>{paginationRef.current.startSlNo + i}</td>
                  <td>{x.id}</td>
                  <td>{x.name}</td>
                  <td className="text-center">
                    <Amount value={x.mrp} />
                  </td>
                  <td className="text-center">
                    <Amount value={x.b2b.offerPrice} />
                  </td>
                  <td className="text-center">
                    <Amount value={x.b2b.shippingPrice} />
                  </td>
                  <td className="text-center">
                    <HighlightText
                      template={2}
                      type={x.b2b.checkPrice ? "success" : "danger"}
                    >
                      {x.b2b.checkPrice ? "Yes" : "No"}
                    </HighlightText>
                  </td>
                  <td className="text-center">
                    <HighlightText
                      template={2}
                      type={x.b2b.checkQuantity ? "success" : "danger"}
                    >
                      {x.b2b.checkQuantity ? "Yes" : "No"}
                    </HighlightText>
                  </td>
                  <td className="text-center">{x.b2b.quantity}</td>
                  <td className="text-center">{x.sfMinQuantity}</td>
                  <td className="text-center">{x.sfMaxQuantity}</td>
                  <td className="text-center">{x.b2b.shippingTime}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={false}
        size="sm"
      />
    </AppCard>
  );
};

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const containerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "100%", // Adjusted width to be responsive
};

const getData = (sellers, search, pagination) => {
  const sortedSellers = orderBy(sellers, ["name"], ["asc"]);
  let s = [];
  if (search) {
    s = sortedSellers.filter(
      (seller) =>
        seller.id.toString().includes(search) ||
        seller.name.toLowerCase().includes(search.toLowerCase())
    );
  } else {
    s = sortedSellers;
  }
  return {
    sellers: s.slice(pagination.startSlNo - 1, pagination.endSlNo),
    totalRecords: s.length,
  };
};

export default ViewDealLinkedSellers;
