import { AppCard, NoDataFound, TableHeader } from "@sk/uis";
// import { TierConfig } from "../../manage/components"; // Removed unused import
const tierDataTableHeader = [
  { label: "Sl.no", width: "3%" },
  { label: "Name" },
  { label: "Min" },
  { label: "Max" },
];
const rfHeader = [{ label: "Sl.no" }, { label: "Key" }, { label: "Formula" }];

const rfTableData = [
  { key: "Min", value: "Tier Min * Inner Case Qty" },
  { key: "Per Day", value: "Max / 2" },
  { key: "Per Month (Max)", value: "TierMax * CaseQty" },
];

const sfTableData = [
  { key: "Min", value: "Tier Min * Inner Case Qty", remarks: [] },
  { key: "Per Day", value: "Max / 2", remarks: [] },
  {
    key: "Per Month (Max)",
    value: "TierMax * CaseQty * 5",
    remarks: [
      "Active Retailer count is taken as 5 if SF has More than 5 Retailers",
      "If SF has lower than 5, then available retailer count will be taken ",
    ],
  },
];

const TierDetails = ({ data }) => {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <AppCard title="Tier Details">
            <table className="table table-bordered table-sm">
              <TableHeader data={tierDataTableHeader} />
              <tbody>
                {data.length == 0 && (
                  <tr>
                    <td colSpan={tierDataTableHeader.length}>
                      <NoDataFound>No Data Found</NoDataFound>
                    </td>
                  </tr>
                )}
                {data.map((x, i) => (
                  <tr key={x._id} className="fs-val-md">
                    <td>{i + 1}</td>
                    <td>{x.name}</td>
                    <td>{x.min}</td>
                    <td>{x.max}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AppCard>
        </div>

        <div className="col-6">
          {/* RF Calculation */}
          <AppCard title="RF Calculation">
            <table className="table table-bordered table-sm">
              <TableHeader data={rfHeader} />
              <tbody>
                {rfTableData.map((x, i) => (
                  <tr key={i} className="fs-val-md">
                    <td>{x.key}</td>
                    <td>{x.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AppCard>

          {/* SF Calculation */}
          <AppCard title="SF Calculation">
            <table className="table table-bordered table-sm">
              <TableHeader data={rfHeader} />
              <tbody>
                {sfTableData.map((x, i) => (
                  <tr key={i} className="fs-val-md">
                    <td>{x.key}</td>
                    <td>{x.value}</td>
                    <td>
                      {x.remarks.length
                        ? x.remarks.map((r, i) => (
                            <div key={i}>
                              {i + 1}. {r}
                            </div>
                          ))
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AppCard>
        </div>
      </div>
    </>
  );
};

export default TierDetails;
