import { useState } from "react";
import { Tabs } from "@sk/uis";
import Basic from "./basic/Basic";
import ViewDealProducts from "./basic/ViewDealProducts";
import ViewDealImages from "./basic/ViewDealImages";
import ViewDealDescription from "./basic/ViewDealDescription";

const tabs = [
  { key: "basic", tabName: "Basic" },
  { key: "description", tabName: "Description" },
  { key: "images", tabName: "Images" },
  { key: "products", tabName: "Products" },
];

const DealInfo = ({ data }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const tabCb = (data) => {
    setActiveTab(data.value);
  };

  return (
    <div>
      <div className="mb-3">
        <Tabs data={tabs} activeTab={activeTab} callback={tabCb} template={3} />
      </div>
      <div>
        {activeTab.key === "basic" && <Basic data={data} />}
        {activeTab.key === "description" && (
          <ViewDealDescription
            shortDescription={data.shortDescription}
            fullDescription={data.fullDescription}
            highlights={data.highlights}
          />
        )}
        {activeTab.key === "images" && <ViewDealImages images={data.images} />}
        {activeTab.key === "products" && (
          <ViewDealProducts products={data.product} />
        )}
      </div>
    </div>
  );
};

export default DealInfo;
