import { Tabs } from "@sk/uis";
import { useState } from "react";
import {
  Grn,
  PurchaseOrder,
  ReservedOrderQty,
  ReserveOrderConfig,
  StockLedger,
  Vendors,
} from "../../features";

const tabs = [
  { key: "vendor", tabName: "Vendors" },
  { key: "purchaseOrder", tabName: "Purchase Order" },
  { key: "grn", tabName: "GRN" },
  { key: "reserveOrderConfig", tabName: "Reserve Order Config" },
  { key: "stockLedger", tabName: "Stock Ledger" },
];

const Procurement = ({ data }) => {
  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });

  // Tab Callback to change Tab
  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };
  return (
    <>
      <div className="mb-3">
        <Tabs data={tabs} activeTab={activeTab} callback={tabCb} template={3} />
      </div>

      {activeTab.key == "purchaseOrder" && <PurchaseOrder pId={data._id} />}

      {activeTab.key == "vendor" && (
        <Vendors pId={data._id} category={data.category} brand={data.brand} />
      )}

      {activeTab.key == "grn" && <Grn pId={data._id} />}

      {activeTab.key == "stockLedger" && <StockLedger pId={data._id} />}

      {activeTab.key == "reserveOrderQty" && (
        <ReservedOrderQty pId={data._id} />
      )}

      {activeTab.key == "reserveOrderConfig" && (
        <ReserveOrderConfig pId={data._id} />
      )}
    </>
  );
};

export default Procurement;
