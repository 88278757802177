import { yupResolver } from "@hookform/resolvers/yup";
import {
  AuthService,
  BrandService,
  CategoryService,
  CommonService,
  DealService,
  FranchiseService,
  appConfigs,
} from "@sk/services";
import {
  Alert,
  Amount,
  AppTitle,
  AutoCompleteInput,
  BooleanCheckboxInput,
  DatePickerInput,
  EntitySearchInput,
  InputErrorMsg,
  KeyVal,
  NoDataFound,
  PageLoader,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  Toaster,
} from "@sk/uis";
import classNames from "classnames";
import { differenceInMinutes } from "date-fns";
import { maxBy } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import formValidation from "../validations/manageFormValidation";

// const configTypeOptions = [
//   { label: "Choose", value: "" },
//   { label: "Deal", value: "Deal" },
//   { label: "Brand", value: "Brand" },
//   { label: "Category", value: "Category" },
// ];

// const groupTypeOptions = [
//   { label: "Choose", value: "" },
//   { label: "FOFO", value: "FOFO" },
//   { label: "COCO", value: "COCO" },
// ];

// const typeOptions = [
//   { label: "Smart Stores", value: "franchise" },
//   { label: "Retailer Group", value: "groupType" },
// ];

const canViewSkLandingCost = AuthService.isValidUserRole(["ViewSkLandingCost"])
  ? true
  : false;

const tableHeaders = [{ label: "Sl.no" }, { label: "MRP" }, { label: "Stock" }];

let startDateConfig = {
  minDate: new Date(),
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

let endDateConfig = {
  enableTime: true,
  dateFormat: "d M Y G:i K",
};

const franchiseSearchFilter = {
  groupType: "COCO",
  filter: {
    "sk_franchise_details.franchise_sub_type": {
      $in: appConfigs.DARKSTORE_SUB_TYPES,
    },
  },
};

const getConfigTypeDetails = async (configOnType, id) => {
  const c = configOnType.toLowerCase();
  if (c === "category") {
    const r = await CategoryService.getList({
      filter: {
        _id: id,
      },
    });
    return { data: (r.resp || []).map((x) => ({ label: x.name, value: x })) };
  }
  if (c === "brand") {
    const r = await BrandService.getList({
      filter: {
        _id: id,
      },
    });
    return { data: (r.resp || []).map((x) => ({ label: x.name, value: x })) };
  }
  if (c === "deal") {
    const r = await DealService.getDeals({
      filter: {
        _id: id,
      },
    });
    return { data: (r.resp || []).map((x) => ({ label: x.name, value: x })) };
  }
  if (c === "franchise") {
    const r = await FranchiseService.getList({
      filter: {
        _id: id,
        "sk_franchise_details.franchise_sub_type": {
          $in: appConfigs.DARKSTORE_SUB_TYPES,
        },
      },
    });
    return { data: (r.resp || []).map((x) => ({ label: x.name, value: x })) };
  }
};

const getValidDiscountVal = (val) => {
  if (val > 100) {
    return 100;
  }
  return val ? CommonService.roundedByDecimalPlace(val, 6) : val;
};

const canvasStyle = {
  width: "35%",
};

const ManageRspConfigModal = ({ show, closeModal, mode, id }) => {
  const [submitting, setSubmitting] = useState(false);

  const [pageLoading, setPageLoading] = useState(false);

  const [profit, setProfit] = useState({
    sk: 0,
    store: 0,
    rsp: 0,
    skProfitPerc: 0,
    storeProfitPerc: 0,
  });

  const [mrpTable, setMrpTable] = useState({
    loading: false,
    data: [],
    maxMrp: 0,
  });

  const [dealStockDetails, setDealStockDetails] = useState({
    loading: false,
    data: {},
  });

  const editRespRef = useRef({});

  // form configuration
  const {
    reset,
    getValues,
    setValue,
    register,
    formState: { errors },
    handleSubmit,
    control,
    trigger,
  } = useForm({
    defaultValues: {
      // brand: [],
      // category: [],
      // type: "franchise",
      disableStartDate: false,
      deal: [],
      franchise: [],
      hasOffer: false,
      status: "Active",
      fixedMrp: null,
      fixedPrice: null,
      isFixedPrice: false,
      discount: null,
    },
    resolver: yupResolver(formValidation),
  });

  const [hasOfferSelected, isFixedPrice, deal] = useWatch({
    control,
    name: ["hasOffer", "isFixedPrice", "deal"],
  });

  useEffect(() => {
    if (show) {
      setMrpTable({ loading: false, data: [], maxMrp: 0 });
      // when the modal get opened clear the form data
      resetFormData();
      setProfit({
        sk: 0,
        store: 0,
        rsp: 0,
        skProfitPerc: 0,
        storeProfitPerc: 0,
      });

      if (mode == "edit") {
        if (id) {
          loadEditData(id);
        } else {
          Toaster.error("Invalid ID");
          triggerCloseModal();
        }
      }
    }
  }, [mode, id, show, loadEditData]);

  const prepareFromValue = useCallback(
    async (r) => {
      // update start date min value - start date is older than today date

      if (r.franchiseInfo?.id) {
        setValue("type", "franchise");
        // const res = await getConfigTypeDetails("franchise", r.franchiseInfo?.id);
        // console.log("res", res.data);
        // setValue("franchise", [
        //   {
        //     label: r?.franchiseInfo?.name,
        //     value: { _id: r?.franchiseInfo?.id, name: r?.franchiseInfo?.name },
        //   },
        // ]);
      }

      if (r.groupType && r.groupType != "ALL") {
        setValue("type", "groupType");
        setValue("groupType", r.groupType);
      }

      if (r.configOnType) {
        // to fetch the details of category/brand/deal
        setValue("configType", r.configOnType);
        const res = await getConfigTypeDetails(r.configOnType, r.id);
        setValue(r.configOnType.toLowerCase(), res.data);
      }

      loadFranchise(r.franchiseInfo?.id, r.deal.id);

      if (r.discount && !r.isFixedPrice) {
        setValue("discount", r.discount);
      } else {
        setValue("discount", 0);
      }

      if (r.status) {
        setValue("status", r.status);
      }

      if (r.hasOffer) {
        setValue("hasOffer", r.hasOffer);
        setValue("validFrom", r?.validityPeriod?.startDate);

        setValue("validTo", r?.validityPeriod?.endDate);
        setValue("additionalDiscount", r?.specialDiscount);

        // update start date min value - start date is older than today date
        const startDate = new Date(r?.validityPeriod?.startDate);

        const isStartDateDisabled =
          differenceInMinutes(startDate, new Date()) <= 0;

        setValue("disableStartDate", isStartDateDisabled);

        if (isStartDateDisabled) {
          startDateConfig = { ...startDateConfig, minDate: startDate };
        }
      }

      setValue("isFixedPrice", r.isFixedPrice);

      if (r.isFixedPrice) {
        setValue("fixedPrice", r.fixedPrice);
      }
    },
    [loadFranchise]
  );

  const loadFranchise = useCallback(
    async (id, dealId) => {
      const r = await FranchiseService.getFranchise(id);
      let s = [];
      if (r.resp._id) {
        s = [{ label: r.resp.name, value: r.resp }];
      }
      setValue("franchise", s);
      loadMrpTableData();
      loadStockDetails({ value: { _id: dealId } });
    },
    [loadMrpTableData, loadStockDetails]
  );

  const loadEditData = useCallback(
    async (id) => {
      setPageLoading(true);
      const r = await DealService.getCustomerCatalogPriceConfig({
        filter: {
          _id: id,
        },

        slc: canViewSkLandingCost,
      });
      const d = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};
      editRespRef.current = {};
      if (!d._id) {
        Toaster.error("Failed to fetch details, please try again");
        triggerCloseModal();
        setPageLoading(false);
        return;
      }
      editRespRef.current = d;
      if (d?.isFixedPrice) {
        let rspPrice = d.fixedPrice;
        setProfit({
          sk: rspPrice - d.slc,
          rsp: rspPrice,
          store: rspPrice - d.b2bPrice,
          storeProfitPerc: CommonService.roundedByDecimalPlace(
            ((rspPrice - d.b2bPrice) / d.b2bPrice) * 100,
            2
          ),
          skProfitPerc: CommonService.roundedByDecimalPlace(
            ((rspPrice - d.slc) / d.slc) * 100,
            2
          ),
        });
      } else {
        const v = d.hasOffer ? d?.specialDiscount || 0 : d?.discount || 0;
        let mrp = d?.mrp;
        let rspPrice = mrp - mrp * (v / 100);
        setProfit({
          sk: rspPrice - d.slc,
          store: rspPrice - d.b2bPrice,
          rsp: rspPrice,
          skProfitPerc: CommonService.roundedByDecimalPlace(
            ((rspPrice - d.slc) / d.slc) * 100,
            2
          ),
          storeProfitPerc: CommonService.roundedByDecimalPlace(
            ((rspPrice - d.b2bPrice) / d.b2bPrice) * 100,
            2
          ),
        });
      }
      prepareFromValue(editRespRef.current);
    },
    [prepareFromValue]
  );

  const loadMrpTableData = useCallback(async () => {
    let f = { ...getValues() };
    setMrpTable({ loading: true, data: [], maxMrp: 0 });
    let sellerId = f.franchise?.length
      ? f.franchise[0].value.sk_franchise_details?.linkedSellerId
      : "";
    let dealId = f.deal?.length ? f.deal[0].value._id : "";
    if (sellerId && dealId) {
      let params = {
        page: 1,
        count: 100,
      };
      const r = await DealService.getSellerDealStockDetails(
        sellerId,
        dealId,
        params
      );
      let d = (Array.isArray(r.resp) ? r.resp : []).filter((e) => {
        return e?.location?.name != "Non-Sellable";
      });
      let res = Object.values(
        d.reduce((acc, item) => {
          if (!acc[item.mrp]) {
            acc[item.mrp] = { mrp: item.mrp, quantity: 0 };
          }
          let q =
            item?.uom?.length && item?.uom[0] == "gm"
              ? item.quantity / 1000
              : item.quantity;
          let u = item?.uom?.length
            ? item.uom[0] == "gm"
              ? "kg"
              : item.uom[0]
            : "Units";

          acc[item.mrp].quantity += q;
          acc[item.mrp].unit = u;
          return acc;
        }, {})
      );
      if (res.length) {
        setMrpTable({
          loading: false,
          data: res,
          maxMrp: maxBy(res, "mrp")?.mrp,
        });

        setPageLoading(false);
      } else {
        updateDealMrp();
      }
    } else {
      setMrpTable({
        loading: false,
        data: [],
        maxMrp: 0,
      });

      setPageLoading(false);
    }
  }, [updateDealMrp, setPageLoading, setMrpTable]);

  const updateDealMrp = useCallback(() => {
    let f = { ...getValues() };
    setMrpTable({
      loading: false,
      data: [
        {
          mrp: f.deal?.length ? f?.deal[0].value.mrp : 0,
          quantity: 0,
          unit: f.deal[0]?.value?.sellInLooseQty ? "gm" : "Units",
        },
      ],
      maxMrp: f.deal?.length ? f.deal[0].value.mrp : 0,
    });
    setPageLoading(false);
  }, [mrpTable]);

  // const onConfigTypeChangeCb = (val) => {
  //   const item = configTypeOptions.find((x) => x.value == val) || {};
  //   setConfigType(item);
  //   ["brand", "category", "deal"].forEach((x) => {
  //     setValue(x, []);
  //   });
  // };

  // const onTypeChangeCb = (val) => {
  //   setType(val);
  //   setValue("franchise", []);
  //   setValue("groupType", "");
  //   setValue("franchiseType", "");
  //   setShowHasOffer(true);
  // };

  const searchCb = useCallback((chngFn) => {
    return (val) => {
      chngFn(val);
      setValue("deal", []);
      setMrpTable({
        loading: false,
        data: [],
        maxMrp: 0,
      });
    };
  }, []);

  const onDealChange = useCallback(
    (chngFn) => {
      return (val) => {
        chngFn(val);
        setProfit({
          sk: 0,
          store: 0,
          rsp: 0,
          skProfitPerc: 0,
          storeProfitPerc: 0,
        });
        setValue("discount", null);
        setValue("fixedPrice", null);
        loadMrpTableData();
        if (val?.length) {
          loadStockDetails(val[0]);
        } else {
          setDealStockDetails({ loading: false, data: {} });
        }
      };
    },
    [loadStockDetails, loadMrpTableData]
  );

  const loadStockDetails = useCallback(async (data) => {
    setDealStockDetails({ loading: true, data: {} });
    let p = {
      franchiseId: getValues("franchise")[0]?.value?._id,
      excludeOutOfStock: false,
      groupbycondName: "deal",
      groupbycond: "deal",
      filter: { _id: getValues("franchise")[0]?.value?._id },
      dealFilter: { _id: { $in: [data?.value?._id] } },
      slc: canViewSkLandingCost,
    };
    let r = await FranchiseService.getFranchiseInventorySummary(p);
    let d = Array.isArray(r.resp) && r.resp?.length ? r.resp[0] : {};
    setDealStockDetails({ loading: false, data: d });
  }, []);

  const onFixedPriceChange = (chngFn) => {
    return async (val) => {
      chngFn(val);
      setValue("fixedPrice", null);
      // setValue("fixedMrp", null);
      setValue("discount", 0);
      setValue("hasOffer", false);
      setProfit({
        sk: 0,
        store: 0,
        rsp: 0,
        skProfitPerc: 0,
        storeProfitPerc: 0,
      });
      await trigger("isFixedPrice");
    };
  };

  // const groupTypeCb = () => {
  //   let groupType = getValues("groupType");

  //   reset({
  //     groupType: groupType,
  //     discount: "",
  //     status: "Active",
  //   });

  //   setShowHasOffer(groupType == "FOFO" ? false : true);
  // };

  const preparePayload = (data) => {
    let p = {
      configOnType: "Deal", // Old Value => data.configType
      hasOffer: false,
      status: data.status,
      isFixedPrice: data.isFixedPrice,
    };

    p.franchiseId = "";

    if (mode == "edit" && id) {
      p._id = id;
    }

    // if (data.groupType) {
    //   p.groupType = data.groupType;
    // }

    if (data.franchise?.length) {
      p.franchiseId = data.franchise[0].value._id;
    }

    if (data.hasOffer) {
      p.hasOffer = data.hasOffer;
      p["startDate"] = data.validFrom;
      p["endDate"] = data.validTo;
      p.specialDiscount = data.additionalDiscount;
    }
    let deal = data.deal[0]?.value;
    // if (data.configType) {
    p.id = deal._id; // Old Value =>[data.configType.toLowerCase()]
    p.menu = { id: deal.menuId, name: deal.menuName };
    p.category = { id: deal.categoryId, name: deal.categoryName };
    p.brand = { id: deal.brandId, name: deal.brandName };
    // }

    if (data.isFixedPrice) {
      // p.fixedMrp = data.fixedMrp;
      p.fixedPrice = data.fixedPrice;
    } else {
      p.discount = data.discount;
    }
    if (mode != "add") {
      p.updateData = true;
    }
    return p;
  };

  // for reseting the form data and its dependent state
  const resetFormData = () => {
    reset();
    // setConfigType({});
    // setType("franchise");
    // setShowHasOffer(true);
    // setValue("groupType", "");
  };

  const onSubmit = async (data) => {
    let f = getValues();

    // check if mrptable length > 0, then match fixed price with higest mrp , if greater than throw the error.
    if (mrpTable.maxMrp > 0 && f.fixedPrice > mrpTable.maxMrp) {
      Toaster.error(
        "Price should not be greater than MRP Rs." + mrpTable.maxMrp
      );
      return;
    }

    // if mrptable == 0, check fixed price > deal mrp , if yes then throw the error.
    if (
      mrpTable.maxMrp == 0 &&
      f.deal[0]?.value?.mrp &&
      f.fixedPrice > f?.deal[0]?.value?.mrp
    ) {
      Toaster.error(
        "Price should not be greater than MRP Rs." + f?.deal[0]?.value?.mrp
      );
      return;
    }

    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to proceed?",
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    if (res.isDismissed) {
      return;
    }

    const p = preparePayload(data);

    setSubmitting(true);

    let r = {};

    if (mode == "add") {
      r = await DealService.createCustomerCatalogPriceConfig(p);
    } else {
      r = await DealService.updateCustomerCatalogPriceConfig(p);
    }

    setSubmitting(false);

    if (r.statusCode !== 200) {
      Toaster.error(r.resp.message || "Failed to update");
      return;
    }

    resetFormData();

    Toaster.success("Updated Successfully");

    triggerCloseModal("submit");
  };

  const dateChangeCb = (chngFn, key) => {
    return (val) => {
      chngFn(val);
      setValue(key, val[0]);
      if (key == "validFrom") {
        endDateConfig = { ...endDateConfig, minDate: val[0] };
      }
      trigger("validTo");
    };
  };

  const discountCb = useCallback(
    (e) => {
      const v = getValidDiscountVal(e.target.value);
      setValue("discount", v, { shouldValidate: true });
      let disc = v;

      if (hasOfferSelected) {
        disc = getValues("additionalDiscount");
      }

      let mrp = dealStockDetails?.data?.mrp;

      let rspPrice = mrp - mrp * (disc / 100);

      setProfit({
        sk: rspPrice - (dealStockDetails?.data?.slc || 0),
        store: rspPrice - (dealStockDetails?.data?.b2bprice || 0),
        rsp: rspPrice,
        skProfitPerc: CommonService.roundedByDecimalPlace(
          ((rspPrice - dealStockDetails?.data.slc) /
            dealStockDetails?.data.slc) *
            100,
          2
        ),
        storeProfitPerc: CommonService.roundedByDecimalPlace(
          ((rspPrice - dealStockDetails?.data.b2bprice) /
            dealStockDetails?.data.b2bprice) *
            100,
          2
        ),
      });
    },
    [dealStockDetails]
  );

  const priceCb = useCallback(
    (e) => {
      const v = e.target.value;
      setValue("fixedPrice", v);
      let rspPrice = v;
      setProfit({
        sk: rspPrice - (dealStockDetails?.data?.slc || 0),
        store: rspPrice - (dealStockDetails?.data?.b2bprice || 0),
        rsp: rspPrice,
        skProfitPerc: CommonService.roundedByDecimalPlace(
          ((rspPrice - dealStockDetails?.data.slc) /
            dealStockDetails?.data.slc) *
            100,
          2
        ),
        storeProfitPerc: CommonService.roundedByDecimalPlace(
          ((rspPrice - dealStockDetails?.data?.b2bprice) /
            dealStockDetails?.data?.b2bprice) *
            100,
          2
        ),
      });
      // let mrp = deal.length ? deal[0]?.value?.mrp || 0 : 0;
      // let d = Math.round(((mrp - v) / mrp) * 100, 2);
      // setValue("discount", getValidDiscountVal(d));
    },
    [dealStockDetails, setValue, setProfit]
  );

  const additionalDiscountCb = (e) => {
    const v = getValidDiscountVal(e.target.value);
    setValue("additionalDiscount", v, { shouldValidate: true });
    if (hasOfferSelected) {
      let mrp = dealStockDetails?.data?.mrp;

      let rspPrice = mrp - mrp * (v / 100);

      setProfit({
        sk: rspPrice - (dealStockDetails?.data?.slc || 0),
        store: rspPrice - (dealStockDetails?.data?.b2bprice || 0),
        rsp: rspPrice,
        skProfitPerc: CommonService.roundedByDecimalPlace(
          ((rspPrice - dealStockDetails?.data.slc) /
            dealStockDetails?.data.slc) *
            100,
          2
        ),
        storeProfitPerc: CommonService.roundedByDecimalPlace(
          ((rspPrice - dealStockDetails?.data.b2bprice) /
            dealStockDetails?.data.b2bprice) *
            100,
          2
        ),
      });
    }
  };

  const onHasOfferchange = (chnfFn) => {
    return (val) => {
      chnfFn(val);
      if (val) {
        let v = 0;
        let mrp = dealStockDetails?.data?.mrp;

        let rspPrice = mrp - mrp * (v / 100);

        setProfit({
          sk: rspPrice - (dealStockDetails?.data?.slc || 0),
          store: rspPrice - (dealStockDetails?.data?.b2bprice || 0),
          rsp: rspPrice,
          skProfitPerc: CommonService.roundedByDecimalPlace(
            ((rspPrice - dealStockDetails?.data.slc) /
              dealStockDetails?.data.slc) *
              100,
            2
          ),
          storeProfitPerc: CommonService.roundedByDecimalPlace(
            ((rspPrice - dealStockDetails?.data.b2bprice) /
              dealStockDetails?.data.b2bprice) *
              100,
            2
          ),
        });
      } else {
        let v = getValues("discount");

        let mrp = dealStockDetails?.data?.mrp;

        let rspPrice = mrp - mrp * (v / 100);

        setProfit({
          sk: rspPrice - (dealStockDetails?.data?.slc || 0),
          store: rspPrice - (dealStockDetails?.data?.b2bprice || 0),
          rsp: rspPrice,
          skProfitPerc: CommonService.roundedByDecimalPlace(
            ((rspPrice - dealStockDetails?.data.slc) /
              dealStockDetails?.data.slc) *
              100,
            2
          ),
          storeProfitPerc: CommonService.roundedByDecimalPlace(
            ((rspPrice - dealStockDetails?.data.b2bprice) /
              dealStockDetails?.data.b2bprice) *
              100,
            2
          ),
        });
      }
    };
  };

  const triggerCloseModal = (status = "cancel") => {
    closeModal({ status });
  };

  // const mrpCb = (e) => {
  //   const v = e.target.value;
  //   setValue("fixedMrp", v);
  //   let price = getValues("fixedPrice");
  //   let d = Math.round(((v - price) / v) * 100, 2);
  //   setValue("discount", getValidDiscountVal(d));
  // };

  const franchiseSearchTemplate = useCallback((e) => {
    return (
      <div>
        <div>
          {e.label} - {e.value._id}
        </div>
        <div className="text-muted">
          <small>
            {e.value.district}, {e.value.state} - {e.value.pincode}
          </small>
        </div>
      </div>
    );
  }, []);

  const [disableStartDate, franchiseDetails] = useWatch({
    control: control,
    name: ["disableStartDate", "franchise"],
  });

  const onDealSearch = async (val, callback) => {
    const franchiseId = franchiseDetails?.[0]?.value?._id;
    let params = {
      franchiseId: franchiseId,
      excludeOutOfStock: false,
      groupbycondName: "deal",
      groupbycond: "deal",
      filter: {
        _id: franchiseId,
      },
      slc: canViewSkLandingCost,
      dealFilter: {},
      isRSPDefined: "No",
    };

    const search = val.trim();
    const searchString = CommonService.sanitizeRegex(search);
    const searchRegex = { $regex: searchString, $options: "gi" };
    if (search) {
      params.dealFilter = {
        $or: [{ _id: searchRegex }, { name: searchRegex }],
      };
    }
    const r = await FranchiseService.getFranchiseInventorySummary(params);

    callback(
      (r.resp || []).map((x) => ({
        label: x.name + " (" + x._id + ")",
        value: x,
      }))
    );
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={triggerCloseModal}
        backdrop="static"
        keyboard={false}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-primary">
          <AppTitle
            title={
              (mode === "edit" ? "Edit" : "Add") + "  Retailer Selling Price "
            }
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          {pageLoading ? (
            <PageLoader />
          ) : (
            <>
              <div>
                {mode == "edit" ? (
                  <div className="fs-val-md text-grey mb-3 text-muted">
                    Reference ID: {id}
                  </div>
                ) : null}

                <form>
                  {/* Choose Type  */}
                  {/* <div>
                    <SelectInput
                      label="Choose Type"
                      register={register}
                      name="type"
                      error={errors?.type?.message}
                      isMandatory={true}
                      options={typeOptions}
                      callback={onTypeChangeCb}
                      disabled={mode == "edit" && !franchiseDetails?.length}
                    />
                  </div> */}

                  {/* {type === "franchise" && ( */}
                  <div className="mb-3">
                    <Controller
                      control={control}
                      name="franchise"
                      render={({ field: { onChange, value } }) => (
                        <EntitySearchInput
                          type="franchise"
                          label="Search for Smart Stores"
                          error={errors?.franchise?.message}
                          placeholder="Search by Name/ID"
                          isMandatory={true}
                          value={value}
                          callback={searchCb(onChange)}
                          uid="franchise"
                          disabled={mode == "edit"}
                          template={franchiseSearchTemplate}
                          filterParams={franchiseSearchFilter}
                          emptyLabel="No Smart Stores found"
                        />
                      )}
                    />
                  </div>

                  {/* )} */}

                  {/* {type == "groupType" && (
                    <div>
                      <SelectInput
                        label="Retailer Group Type"
                        register={register}
                        name="groupType"
                        error={errors?.groupType?.message}
                        isMandatory={true}
                        options={groupTypeOptions}
                        callback={groupTypeCb}
                        disabled={mode == "edit" && !franchiseDetails?.length}
                      />
                    </div>
                  )} */}

                  {/* CONFIG TYPE */}
                  {/* <div>
                    <SelectInput
                      label="Choose Config Type"
                      register={register}
                      name="configType"
                      error={errors?.configType?.message}
                      isMandatory={true}
                      options={configTypeOptions}
                      callback={onConfigTypeChangeCb}
                      disabled={mode == "edit" && !franchiseDetails?.length}
                    />
                  </div> */}

                  {/* Catalog search */}
                  {/* {configType.value && ( */}
                  <div className="mb-3">
                    {/* <Controller
                      control={control}
                      name="deal" // Old Value =>  configType.value?.toLowerCase()
                      key="deal" // Old Value => configType.value
                      render={({ field: { onChange, value } }) => (
                        <EntitySearchInput
                          type="deal" // Old Value  =>  configType.value.toLowerCase()
                          label="Search for Deal" //Old Value  {`Search for ${configType.label}`}
                          error={errors?.deal?.message} // Old Value => errors?.[configType.value.toLowerCase()]?.message
                          placeholder="Search by Name/ID"
                          isMandatory={true}
                          value={value}
                          callback={onDealChange(onChange)}
                          uid="search"
                          disabled={mode == "edit" || !franchiseDetails?.length}
                          emptyLabel="No Deal Found" //{"No " + configType.label + " found"}
                        />
                      )}
                    /> */}
                    <Controller
                      control={control}
                      name="deal"
                      render={({ field: { onChange, value } }) => (
                        <AutoCompleteInput
                          label="Search For Deal"
                          placeholder="Search By Name /ID "
                          value={value}
                          onSearch={onDealSearch}
                          callback={onDealChange(onChange)}
                          uid="deal-search"
                          isMultiple={false}
                          emptyLabel="No Deal Found"
                          error={errors?.deal?.message}
                          gap={0}
                          isMandatory={true}
                          disabled={mode == "edit" || !franchiseDetails?.length}
                        />
                      )}
                    />

                    {deal.length ? (
                      <div className="row mb-4 mt-2">
                        <div className="col-4 mb-3">
                          <KeyVal label="MRP" template="col">
                            {dealStockDetails.loading ? (
                              <Spinner />
                            ) : (
                              <span className="fs-val-md text-primary">
                                <Amount
                                  value={dealStockDetails?.data?.mrp || 0}
                                  decimalPlace={2}
                                />
                              </span>
                            )}
                          </KeyVal>
                        </div>
                        <div className="col-4 mb-3">
                          <KeyVal label="Store Landing" template="col">
                            {dealStockDetails.loading ? (
                              <Spinner />
                            ) : (
                              <span className="fs-val-md">
                                <Amount
                                  value={dealStockDetails?.data?.b2bprice || 0}
                                  decimalPlace={2}
                                />
                              </span>
                            )}
                          </KeyVal>
                        </div>
                        {canViewSkLandingCost ? (
                          <div className="col-4 mb-3">
                            <KeyVal label="Sk Landing Cost" template="col">
                              {dealStockDetails.loading ? (
                                <Spinner />
                              ) : (
                                <span className="fs-val-md">
                                  <Amount
                                    value={dealStockDetails?.data?.slc || 0}
                                    decimalPlace={2}
                                  />
                                </span>
                              )}
                            </KeyVal>
                          </div>
                        ) : null}

                        <div className="col-4 mb-3">
                          <KeyVal label="RSP" template="col">
                            <Amount value={profit.rsp || 0} decimalPlace={2} />
                          </KeyVal>
                        </div>

                        <div className="col-4 mb-3">
                          <KeyVal label="Store Profit" template="col">
                            <span
                              className={classNames({
                                "fs-val-md": true,
                                "text-success": profit.store > 0,
                                "text-danger": profit.store < 0,
                                "text-primary": profit.store == 0,
                              })}
                            >
                              <Amount
                                value={profit.store || 0}
                                decimalPlace={2}
                              />
                              ({profit?.storeProfitPerc || 0}%)
                            </span>
                          </KeyVal>
                        </div>

                        {canViewSkLandingCost ? (
                          <div className="col-4 mb-3">
                            <KeyVal label="Sk Profit" template="col">
                              <span
                                className={classNames({
                                  "fs-val-md": true,
                                  "text-success": profit.sk > 0,
                                  "text-danger": profit.sk < 0,
                                  "text-primary": profit.sk == 0,
                                })}
                              >
                                <Amount
                                  value={profit.sk || 0}
                                  decimalPlace={2}
                                />
                                ({profit.skProfitPerc || 0}%)
                              </span>
                            </KeyVal>
                          </div>
                        ) : null}

                        <div className="col-4 mb-2">
                          <KeyVal label="Available Stock" template="col">
                            {dealStockDetails.loading ? (
                              <Spinner />
                            ) : (
                              <span className="fs-val-md">
                                {dealStockDetails?.data?._qty || 0}{" "}
                                {dealStockDetails?.data?._displayUnitKey}
                              </span>
                            )}
                          </KeyVal>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {/* Is Fixed Price */}
                  <div className="align-self-center">
                    <Controller
                      name="isFixedPrice"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <BooleanCheckboxInput
                          label="Is Fixed Price"
                          name="isFixedPrice"
                          error={errors?.isFixedPrice?.message}
                          layout="verticals"
                          isMandatory={false}
                          callback={onFixedPriceChange(onChange)}
                          value={value}
                        />
                      )}
                    />
                  </div>

                  {/* MRP */}
                  {/* {isFixedPrice ? (
                    <div>
                      <TextInput
                        label="MRP"
                        type="number"
                        register={register}
                        name="fixedMrp"
                        error={errors?.fixedMrp?.message}
                        placeholder="Enter MRP in Rs."
                        isMandatory={true}
                        callback={mrpCb}
                      />
                    </div>
                  ) : null} */}

                  {/* Price */}
                  {isFixedPrice ? (
                    <div>
                      <TextInput
                        label="Price"
                        type="number"
                        register={register}
                        name="fixedPrice"
                        error={errors?.fixedPrice?.message}
                        placeholder="Enter Price in Rs."
                        isMandatory={true}
                        callback={priceCb}
                        disabled={!franchiseDetails?.length || !deal.length}
                      />
                    </div>
                  ) : null}

                  {/* DISCOUNT */}
                  {!isFixedPrice ? (
                    <div>
                      <TextInput
                        label="Discount (%)"
                        type="number"
                        register={register}
                        name="discount"
                        error={errors?.discount?.message}
                        placeholder="Enter Discount in %"
                        isMandatory={true}
                        callback={discountCb}
                        disabled={isFixedPrice}
                      />
                    </div>
                  ) : null}

                  {/* HAS OFFER CHECKBOX */}
                  {!isFixedPrice ? (
                    <div className="align-self-center">
                      <Controller
                        name="hasOffer"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <BooleanCheckboxInput
                            label="Has Offer ?"
                            name="hasOffer"
                            error={errors?.hasOffer?.message}
                            layout="verticals"
                            isMandatory={false}
                            callback={onHasOfferchange(onChange)}
                            value={value}
                          />
                        )}
                      />
                    </div>
                  ) : null}

                  {/* OFFER DATE BLOCK */}
                  {hasOfferSelected && (
                    <div className="row">
                      <div className="col-6 mb-3">
                        <label className="form-label mb-0">
                          Offer Start Date
                          <span className="text-danger">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="validFrom"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <DatePickerInput
                                placeholder="Choose"
                                value={[value]}
                                disabled={disableStartDate}
                                inpChange={dateChangeCb(onChange, "validFrom")}
                                config={startDateConfig}
                              />
                            );
                          }}
                        />
                        <InputErrorMsg msg={errors?.validFrom?.message} />
                      </div>
                      <div className="col-6 mb-3">
                        <label className="form-label mb-0">
                          Offer End Date
                          <span className="text-danger">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="validTo"
                          render={({ field: { onChange, value } }) => (
                            <DatePickerInput
                              placeholder="Choose"
                              value={[value]}
                              inpChange={dateChangeCb(onChange, "validTo")}
                              config={endDateConfig}
                            />
                          )}
                        />
                        <InputErrorMsg msg={errors?.validTo?.message} />
                      </div>

                      {/* ADDITIONAL DISCOUNT */}
                      <div className="col-12">
                        <TextInput
                          label="Additional Discount (%)"
                          type="number"
                          register={register}
                          name="additionalDiscount"
                          error={errors?.additionalDiscount?.message}
                          placeholder="Enter Discount in %"
                          isMandatory={true}
                          callback={additionalDiscountCb}
                        />
                      </div>
                    </div>
                  )}

                  <div>
                    <div className="mb-3 fw-semibold"> MRP Table</div>
                    <table className="table table-bordered bg-white">
                      <TableHeader data={tableHeaders} />
                      <tbody>
                        {mrpTable.loading ? (
                          <TableSkeletonLoader
                            rows={5}
                            cols={tableHeaders.length}
                            height={40}
                          />
                        ) : null}
                        {!mrpTable.loading && !mrpTable.data.length ? (
                          <tr>
                            <td colSpan={tableHeaders.length}>
                              <NoDataFound>No Data Found</NoDataFound>
                            </td>
                          </tr>
                        ) : null}
                        {(mrpTable.data || []).map((x, index) => (
                          <tr key={index}>
                            <td>{1 + index}</td>
                            <td>
                              <Amount value={x?.mrp} />
                            </td>
                            <td>
                              {x?.quantity || 0} {x?.unit}
                              {!x?.unit && x?.quantity > 0 ? "Units" : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="my-3 text-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleSubmit(onSubmit)}
                      disabled={submitting}
                    >
                      Submit {submitting ? <Spinner isSmall={true} /> : null}
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(ManageRspConfigModal);
