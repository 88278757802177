import { useAttachAdditionalData, useIsFirstRender } from "@sk/hooks";
import { CommonService, NavService, ProductService } from "@sk/services";
import {
  AppCard,
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  Rbac,
} from "@sk/uis";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import listView from "../constantService";
import FilterForm from "./components/FilterForm";
import SummaryCardBox from "./components/SummaryCardBox";
import Table from "./components/Table";
import AdvFilterModal from "./modals/AdvFilterModal";

//  Default Values all are Declared in constantService of product
const formLabelsConfig = listView.formLabels;

const defaultSummaryData = listView.summaryData;

const attachAdditionalDataConfig = listView.additionalTableDataConfig;

const defaultFormData = listView.filterFormData;

const defaultPaginationData = listView.pagination;

const breadcrumb = listView.breadcrumb;

const defaultSortOpt = { key: "lastUpdated", value: "desc" };

const rbac = {
  addProduct: ["AddProduct"],
};

// To Prepare Params for Filter
const prepareFilterParams = (pagination = {}, sort = {}, filter = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };
  let d = filter || {};

  if (d.barcode) {
    p["filter"]["barcode"] = "/" + d.barcode + "/";
  }
  const product = d.product.trim();
  const productRegex = { $regex: product, $options: "gi" };

  //  To Search Product According to Id or Name
  if (product) {
    p["filter"]["$or"] = [
      { _id: productRegex },
      { name: productRegex },
      { HSNNumber: productRegex },
    ];
  }

  if (sort?.key) {
    p.sort = sort.value == "asc" ? sort.key : "-" + sort.key;
  }

  if (d.allowReserveOrder) {
    p.filter.allowReserveOrder = d.allowReserveOrder == "yes";
  }

  if (d.delivery_chalan) {
    p.filter.delivery_chalan = d.delivery_chalan == "Available";
  }

  if (d.is_active) {
    p.filter.is_active = d.is_active == "Active";
  }
  if (d?.brand?.length) {
    p.filter.brand = {
      $in: [d.brand[0].value._id],
    };
  }

  if (d?.category?.length) {
    p.filter.category = {
      $in: [d.category[0].value._id],
    };
  }

  if (d.classClassifications?.length) {
    p.filter["classClassifications.id"] = {
      $in: [d.classClassifications[0].value._id],
    };
  }

  if (d?.min || d?.max) {
    p.filter.mrp = { $gte: d.min, $lte: d.max };
    !d.min ? delete p.filter.mrp.$gte : "";
    !d.max ? delete p.filter.mrp.$lte : "";
  }

  return p;
};

// To Get Data for Product List
const getData = async (params) => {
  const r = await ProductService.getList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        // Attaching loading state for Attached User Info
        x.userLoading = true;
        x.brandLoading = true;
        x.categoryLoading = true;
        return x;
      })
    : [];
};

const ListProduct = () => {
  // To Store Table Data
  const [data, setData] = useState([]);

  // Loading Table Data
  const [loadingData, setLoadingData] = useState(true);

  // To Load Summary Card Count
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // To Check is First Render or Not
  const isFirstRender = useIsFirstRender();

  // To Show What filters are Applied
  const [filterLabels, setFilterLabels] = useState([]);

  // To Show Advance Filter Modal
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  // To Store More Columns
  const [selectedMoreTblColumns, setSelectedMoreTblColumns] = useState(() => {
    let t = {};
    listView.moreTableColumns
      .filter((x) => x.checked)
      .forEach((x) => {
        t[x.key] = x;
      });
    return t;
  });

  // To Store the data of Summary Card
  const [summaryCard, setSummaryCard] = useState(
    [...defaultSummaryData].map((x) => {
      // Passing All Filter to get the count Properly
      let p = prepareFilterParams(
        defaultPaginationData,
        defaultSortOpt,
        defaultFormData
      );

      // deleting status from filter as card already have specific status
      if (p.filter) {
        delete p.filter.status;
      }
      return {
        ...x,
        // Attaching all filter we get to default filter of card
        filterParams: { ...p.filter, ...x.defaultFilterParams },
      };
    })
  );

  // Custom Hook To Attach additional Data to Table Data
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // To Store Current filter Data
  const filterDataRef = useRef({ ...defaultFormData });

  // To Store Current State of Pagination
  const paginationRef = useRef({ ...defaultPaginationData });

  // To store Current State of Sort
  const sortRef = useRef({ ...defaultSortOpt });

  // React-hook-form to handle filter Form
  const methods = useForm({
    defaultValues: defaultFormData,
  });

  // To navigate
  const navigate = useNavigate();

  // Inital Load
  useEffect(() => {
    init();
  }, [init]);

  //  To Get Data According To What Filter We Applied
  const applyFilter = useCallback(async () => {
    // Resetting Pagination
    paginationRef.current = { ...defaultPaginationData };

    //This will set Filter For Summary Card
    // if (!isFirstRender) {
    //   setSummaryCard(
    //     produce((draft) => {
    //       draft.forEach((x) => {
    //         x.filterParams = { ...p.filter, ...x.defaultFilterParams };
    //         if (x.key == "overall") {
    //           delete x.filterParams.status;
    //         }
    //       });
    //     })
    //   );
    // }

    // There Will be prepare label Filters
    prepareFilterLabels();

    //  loading Table Data
    loadList();

    // for total records
    setLoadingTotalRecords(true);

    const p = getFilterParams();

    // This is to Get Total Count
    const c = await getCount(p);
    // setting count
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams, isFirstRender, loadList, prepareFilterLabels]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  const loadList = useCallback(async () => {
    // for list
    setLoadingData(true);
    const p = getFilterParams();
    const d = await getData(p);

    setData(d);
    // Attaching Additional Data
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  // Navigate to Manage Product
  const addProduct = () => {
    NavService.openInNewTab("/cms/product/manage");
  };

  // When You Change Page We will Call List
  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  // to Get Count
  const getCount = async (params) => {
    try {
      const r = await ProductService.getCount(params);
      return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  };

  // To get The Data From Filter Form And Applying Filter
  const filterFormCb = (data) => {
    filterDataRef.current = { ...data.formData };
    applyFilter();
  };

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      sortRef.current,
      filterDataRef.current
    );
  }, []);

  // Resetting Filter
  const resetCb = useCallback(() => {
    filterDataRef.current = { ...defaultFormData };

    sortRef.current = { ...defaultSortOpt };

    methods.reset({ ...defaultFormData });
    applyFilter();
  }, [applyFilter, methods]);

  // To Close Filter Modal
  const closeAdvFilterModal = useCallback(
    () => setShowAdvanceFilter(false),
    []
  );

  // To Open Advance Filter Modal
  const openAdvFilterModal = useCallback(() => setShowAdvanceFilter(true), []);

  const prepareFilterLabels = useCallback(() => {
    const v = filterDataRef.current;
    const l = CommonService.prepareAppliedFilterLabels(formLabelsConfig, v);
    setFilterLabels(l);
  }, []);

  //  When Someone Select more Column
  const onMoreTableColApply = useCallback((d) => {
    let t = {};
    (d.selected || []).forEach((x) => {
      t[x.key] = x;
    });
    setSelectedMoreTblColumns({ ...t });
  }, []);

  return (
    <>
      {/* Page Title */}

      <PageInfo
        title="Manage Products"
        breadcrumbs={breadcrumb}
        navigate={navigate}
      />

      {/* Summary Card Overview */}
      <div className="row mb-2">
        {summaryCard.map((card, i) => (
          <div className="col-3" key={i}>
            <SummaryCardBox
              label={card.label}
              color={card.color}
              filterParams={card.filterParams}
              img={card.img}
            />
          </div>
        ))}
      </div>

      <AppCard>
        {/* Filter */}
        <FormProvider {...methods}>
          <div className="row">
            <div className="col">
              <FilterForm
                callback={filterFormCb}
                resetCb={resetCb}
                openAdvFilterModal={openAdvFilterModal}
              />
            </div>
            <div className="col-auto ms-auto">
              <Rbac roles={rbac.addProduct}>
                <button className="btn btn-primary me-2" onClick={addProduct}>
                  Add Product
                </button>
              </Rbac>
            </div>
          </div>

          {/* Advance Filter */}
          <AdvFilterModal
            closeModal={closeAdvFilterModal}
            show={showAdvanceFilter}
            resetCb={resetCb}
            formData={filterDataRef.current}
            callback={filterFormCb}
          />
        </FormProvider>
      </AppCard>

      {/* Filter Labels */}
      {filterLabels.length > 0 ? (
        <div className="mb-3">
          <AppliedFilterLabel labels={filterLabels} callback={filterFormCb} />
        </div>
      ) : null}

      {/* Table */}

      {/* PAGINATION SUMMARY */}
      <AppCard>
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
        <Table
          data={data}
          sort={sortRef.current}
          sortCb={sortCb}
          moreColumns={selectedMoreTblColumns}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>
    </>
  );
};

export default memo(ListProduct);

/*
<div className="col-auto">
          <button className="btn btn-primary" onClick={addProduct}>
            <span className="bi bi-plus-square me-1"></span>
            Create Product
          </button>
        </div>
*/
