import { DealService, FranchiseService } from "@sk/services";
import {
  Alert,
  Amount,
  AppCard,
  KeyVal,
  NoDataFound,
  PageLoader,
  Spinner,
  TableHeader,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import StoreCloneResult from "../components/StoreCloneResult";
import StoreCloneTable from "../components/StoreCloneTable";

const canvasStyle = { width: "70%" };

const tableHeaders = [
  {
    label: "Sl No",
    width: "5%",
  },
  {
    label: "Deal Details",
    width: "30%",
  },
  {
    label: "Deal ID",
  },
  {
    label: "Product ID",
  },
  {
    label: "Combo Qty",
  },
];

const StoreComboCloneModal = ({ show = false, callback, configId }) => {
  const [display, setDisplay] = useState("loading");

  const [configDetails, setConfigDetails] = useState({});

  const [clonedFids, setClonedFids] = useState([]);

  const [result, setResult] = useState({ data: [], show: false });

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setClonedFids([]);
      setResult({ show: false, data: [] });
      setDisplay("loading");
      if (configId) {
        loadData(configId);
      } else {
        setDisplay("error");
      }
    }
  }, [show, configId, loadData]);

  const loadData = useCallback(async (id) => {
    let details = {};

    setDisplay("loading");

    const r = await DealService.getStoreComboDeals({
      filter: { _id: id },
    });

    details = Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {};

    const deals = (details.deals || []).map((e) => ({
      _id: e.dealId,
      name: e.name,
      _pid: e.productId,
      mrp: e.mrp,
      _discount: e.discountValue,
      _qty: e.qty,
      sellInLooseQty: e.sellInLooseQty,
    }));

    setConfigDetails({
      ...details,
      deals,
      dataCheckerFilter: { filter: { comboDealId: details.comboDealId } },
    });

    setDisplay("data");
  }, []);

  const closeModal = () => {
    callback({ action: result.show ? "submit" : "close" });
  };

  const cloneCb = (e) => {
    if (e.action == "selected") {
      setClonedFids(e.data.selected);
    }
  };

  const onSubmit = async () => {
    if (!clonedFids.length) {
      Toaster.error("Please choose Store to clone");
      return;
    }

    const cnrf = await Alert.confirm({
      title: "Please confirm",
      text: "Do you want to clone?",
    });

    if (cnrf.isConfirmed) {
      const c = configDetails;
      let p = {
        title: "",
        comboDealId: c.comboDealId,
        comboDealName: c.comboDealName,
        discountType: c.discountType,
        discountValue: c.discountValue,
        deals: [],
        franchiseId: "",
        franchiseName: "",
        isActive: true,
        sellerId: "",
      };

      const dIds = c.deals.map((e) => e._id);

      let result = [];

      setSubmitting(true);

      const looper = async (index) => {
        const fid = clonedFids[index];

        const fr = await FranchiseService.getFranchise(fid, {
          select: "name,state,district,town,sk_franchise_details,pincode",
        });

        let sellerId = fr?.resp?.sk_franchise_details?.linkedSellerId;

        // fetch the store inventory
        const d = await FranchiseService.getFranchiseInventorySummary({
          page: 1,
          count: dIds.length,
          franchiseId: fid,
          groupbycondName: "name",
          groupbycond: "deal",
          dealFilter: { _id: { $in: dIds } },
          filter: { _id: fid },
        });

        const dr = Array.isArray(d.resp) ? d.resp : [];

        let notPurchasedDeals = "";

        let isSuccess = false;

        let errMsg = "";

        // first attach the store mrp and rsp, collect not purchased deal ids, then calculate the combo price related discount
        p.deals = FranchiseService.calculateStoreComboPerc(
          [...c.deals].map((e) => {
            const p = dr.find((x) => x._id == e._id);
            if (p != null) {
              e._mrp = p?.mrp;
              e._rsp = p?.retailerSellingPrice;
            } else {
              notPurchasedDeals += e._id + ", ";
              e._rsp = e.mrp;
            }
            return { ...e };
          }),
          configDetails.discountValue,
          configDetails.discountType
        ).map((e) => {
          return {
            dealId: e._id,
            productId: e._pid,
            name: e.name,
            discountValue: e._discount,
            discountType: "Percentage",
            mrp: e._mrp,
            price: e._rsp,
            qty: e._qty,
            sellInLooseQty: e.sellInLooseQty,
          };
        });

        if (!notPurchasedDeals.length) {
          const cr = await DealService.createStoreComboDeals({
            ...p,
            sellerId,
            franchiseId: fr.resp?._id,
            franchiseName: fr?.resp?.name,
          });
          if (cr.statusCode == 200) {
            isSuccess = true;
          } else {
            errMsg = cr.resp?.message || "Failed to create";
          }
        } else {
          errMsg = notPurchasedDeals + " not purchased by the store";
        }

        result.push({
          fran: fr.resp || {},
          isSuccess,
          errMsg,
        });

        const next = index + 1;
        if (next < clonedFids.length) {
          looper(next);
        } else {
          setSubmitting(false);
          setResult({ show: true, data: result });
        }
      };

      looper(0);
    }
  };

  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={closeModal}
      style={canvasStyle}
      keyboard={submitting ? false : true}
    >
      <Offcanvas.Header closeButton className="bg-light">
        <Offcanvas.Title>
          <div className="fs-val-lg">Clone Combo Deal Config #{configId}</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {display == "loading" ? <PageLoader /> : null}

        {display == "error" ? (
          <NoDataFound> No data to display </NoDataFound>
        ) : null}

        {display == "data" ? (
          <>
            <AppCard>
              <div className="row">
                <div className="col-8 mb-3">
                  <KeyVal label="Combo Deal Details" template="col">
                    <div className="mb-1">
                      {configDetails.comboDealName} -{" "}
                      {configDetails.comboDealId}
                    </div>
                  </KeyVal>
                </div>

                <div className="col-4 mb-3">
                  <KeyVal label="Combo Price" template="col">
                    <div className="text-danger">
                      {configDetails.discountType == "Fixed" ? (
                        <Amount
                          value={configDetails.discountValue || 0}
                          decimalPlace={2}
                        />
                      ) : (
                        <>{configDetails.discountValue}%</>
                      )}
                    </div>
                  </KeyVal>
                </div>

                {/* linked deals start */}
                <div className="col-12">
                  <div className="fs-val-md mb-2 text-muted">
                    List of linked deals for the above combo deal
                  </div>
                  <table className="table table-bordered table-sm">
                    <TableHeader data={tableHeaders} fwSize="sm" />
                    <tbody className="fs-val-sm">
                      {configDetails?.deals.map((e, k) => (
                        <tr key={e._id}>
                          <td>{k + 1}</td>
                          <td>{e.name}</td>
                          <td>{e._id}</td>
                          <td>{e._pid}</td>
                          <td>
                            {e._qty} {e.sellInLooseQty ? "kg" : "units"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* linked deals end */}
              </div>
            </AppCard>

            {result.show ? (
              <AppCard title="Clone Result">
                <StoreCloneResult data={result.data} />
              </AppCard>
            ) : (
              <>
                {submitting ? (
                  <div className="text-center">
                    <div>Please wait, cloning....</div>
                    <Spinner />
                  </div>
                ) : (
                  <AppCard>
                    <div className="fs-val-md mb-2 text-muted">
                      Please choose the Stores to clone the config
                    </div>
                    <StoreCloneTable
                      callback={cloneCb}
                      selectedIds={clonedFids}
                      dataCheckerFilter={configDetails.dataCheckerFilter}
                    />
                  </AppCard>
                )}
              </>
            )}
          </>
        ) : null}
      </Offcanvas.Body>
      {display == "data" && !result.show ? (
        <Offcanvas.Header>
          <div className="text-end w-100">
            <button
              className="btn btn-primary"
              onClick={onSubmit}
              disabled={submitting}
            >
              Submit {submitting ? <Spinner isSmall={true} /> : null}
            </button>
          </div>
        </Offcanvas.Header>
      ) : null}
    </Offcanvas>
  );
};

export default StoreComboCloneModal;
