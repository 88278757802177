import { ImgRender } from "@sk/uis";
import React, { memo } from "react";

const Images = ({ data, viewImgPreviewModalCb }) => {
  return (
    <>
      <div className="row">
        <div className="col-3">
          <div className="fw-semibold mb-2">Main Images</div>
          {data?.mainImages?.length ? (
            <div className="row">
              {(data?.mainImages || []).map((x) => (
                <div
                  className="col-auto"
                  key={x}
                  onClick={() => {
                    viewImgPreviewModalCb("mainImages");
                  }}
                >
                  <ImgRender
                    template="2"
                    assetId={x}
                    width="200"
                    height="200"
                  />
                </div>
              ))}
            </div>
          ) : (
            "NA"
          )}
        </div>

        <div className="col-3">
          <div className="fw-semibold mb-2">Images</div>
          {data?.images?.length ? (
            <div className="row">
              {(data?.images || []).map((x) => (
                <div
                  className="col-auto"
                  key={x}
                  onClick={() => {
                    viewImgPreviewModalCb("images");
                  }}
                >
                  <ImgRender assetId={x.image} width="200" height="200" />
                </div>
              ))}
            </div>
          ) : (
            "NA"
          )}
        </div>

        <div className="col-3">
          <div className="fw-semibold mb-2">Kingclub Main Images</div>
          {data?.kingclubMainImages?.length ? (
            <div className="row">
              {(data?.kingclubMainImages || []).map((x) => (
                <div
                  className="col-auto"
                  key={x}
                  onClick={() => {
                    viewImgPreviewModalCb("kingclubMainImages");
                  }}
                >
                  <ImgRender assetId={x.image} width="200" height="200" />
                </div>
              ))}
            </div>
          ) : (
            "NA"
          )}
        </div>

        <div className="col-3">
          <div className="fw-semibold mb-2">Kingclub Icon Images</div>
          {data?.kingclubImages?.length ? (
            <div className="row">
              {(data?.kingclubImages || []).map((x) => (
                <div
                  className="col-auto"
                  key={x}
                  onClick={() => {
                    viewImgPreviewModalCb("kingclubImages");
                  }}
                >
                  <ImgRender assetId={x.image} width="200" height="200" />
                </div>
              ))}
            </div>
          ) : (
            "NA"
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Images);
