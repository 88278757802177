import { OmsService } from "@sk/services";
import { AppCard } from "@sk/uis";
import { set, sub } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import ViewDealOrderTable from "./components/ViewDealOrderTable";
import ViewDealOrderTableFilter from "./components/ViewDealOrderTableFilter";

const ViewDealOrders = ({ dealId }) => {
  const [orders, setOrders] = useState([]);

  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const filterRef = useRef({
    orderedOn: [sub(new Date(), { days: 90 }), new Date()],
  });
  const paginationRef = useRef({ ...defaultPagination });

  const abortCtrl = useRef(new AbortController());

  const sortRef = useRef({
    key: "createdAt",
    value: "desc",
  });

  const applyFilter = useCallback(async () => {
    abortCtrl.current.abort();
    abortCtrl.current = new AbortController();

    paginationRef.current = {
      ...defaultPagination,
    };

    const p = getFilterParams(
      filterRef.current,
      paginationRef.current,
      sortRef.current
    );

    loadList(abortCtrl.current);

    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(p, {
      signal: abortCtrl.current.signal,
    });
    const t = c || 0;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterRef.current = {
      ...filterRef.current,
      dealId,
    };
    init();
  }, [init, dealId]);

  const loadList = useCallback(async (abortCtrl) => {
    setLoadingData(true);
    setOrders([]);
    const r = await getData(
      getFilterParams(
        filterRef.current,
        paginationRef.current,
        sortRef.current
      ),
      {
        signal: abortCtrl.signal,
      }
    );
    const d = r.resp || [];
    setOrders(d);
    setLoadingData(false);
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList(abortCtrl.current);
    },
    [loadList]
  );

  const filterCb = useCallback(
    (filter) => {
      const p = { ...filter.formData };

      filterRef.current = {
        ...filterRef.current,
        ...p,
      };

      applyFilter();
    },
    [applyFilter]
  );

  const sortCb = useCallback(
    (sort) => {
      sortRef.current = sort;
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <AppCard>
        <ViewDealOrderTableFilter callback={filterCb} />
      </AppCard>
      <AppCard>
        <ViewDealOrderTable
          data={orders}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          sortCb={sortCb}
          sort={sortRef.current}
        />
      </AppCard>
    </>
  );
};

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params) => {
  const r = await OmsService.getOrderList(params);
  if (r.resp && Array.isArray(r.resp)) {
    r.resp = (r.resp || []).map((e) => OmsService.formatOrderResponse(e));
  }
  return r;
};

const getCount = async (params) => {
  delete params.page;
  delete params.count;
  const response = await OmsService.getOrderCount({
    ...params,
  });
  return response.resp || 0;
};

const getFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      "subOrders.id": filter.dealId,
    },
    sort: { [sort.key]: sort.value == "asc" ? 1 : -1 },
  };

  if (filter?.orderedOn?.[0] && filter?.orderedOn?.[1]) {
    p.filter.createdAt = {};
    p.filter.createdAt.$gte = set(new Date(filter.orderedOn[0]), {
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
    p.filter.createdAt.$lte = set(new Date(filter.orderedOn[1]), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    });
  }

  if (filter?.status && filter.status != "All") {
    p.filter.status = filter.status;
  }

  if (filter?.openSearch) {
    p.filter._id = filter.openSearch;
  }

  if (filter?.franchise?.length) {
    p.filter["franchise.id"] = {
      $in: filter.franchise.map((f) => f.value?._id),
    };
  }

  if (filter?.paymentStatus) {
    p.filter.paymentStatus = filter.paymentStatus;
  }

  if (filter?.showOnlyNoStock) {
    p.filter.stockAllocation = {
      $in: ["PartiallyAllocated", "NotAllocated"],
    };
  }

  return p;
};

export default ViewDealOrders;
