import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, WarehouseService } from "@sk/services";
import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

const ProductLedger = ({
  pId,
  filter,
  tblHeaderSticky,
  tblContainerStyle,
  tblStyle,
}) => {
  const [data, setData] = useState([]);

  const paginationRef = useRef({ ...defaultPageOpt });

  const sortRef = useRef({ ...defaultSortOpt });

  const filterDataRef = useRef({ ...defaultFormData });

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  useEffect(() => {
    if (pId) {
      console.log("filter", filter);
      filterDataRef.current = {
        ...filterDataRef.current,
        ...filter,
      };
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId, filter]);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current,
      { id: pId }
    );
  }, [pId]);

  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    const d = await getData(params);
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };
    if (pId) {
      loadList();

      setLoadingTotalRecords(true);
      const p = getFilterParams();

      const c = await getCount(p);
      paginationRef.current.totalRecords = c.count;
      setLoadingTotalRecords(false);
    }
  }, [getFilterParams, loadList, pId]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
          fwSize="sm"
        />
      </div>

      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table"
        style={tblContainerStyle}
      >
        <table className="table table-sm" style={tblStyle}>
          <TableHeader
            data={headers}
            sort={sortRef.current}
            sortCb={sortCb}
            isSticky={tblHeaderSticky}
            noBg={true}
          />

          <tbody>
            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length} className="p-1">
                  <NoDataFound>No Data Found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {/*  When Table is Loading  */}
            {loadingData && (
              <TableSkeletonLoader
                rows={10}
                cols={headers.length}
                height={40}
              />
            )}

            {!loadingData &&
              data.map((x, i) => (
                <tr key={x._id} className="fs-val-sm">
                  <td>{paginationRef.current.startSlNo + i}</td>
                  <td>
                    <div className="text-wrap">
                      {x.warehouseLoading ? (
                        <Spinner type="dots" />
                      ) : (
                        x._warehouse.name
                      )}
                    </div>
                  </td>
                  <td
                    className={classNames("fw-bold bg-light text-center", {
                      "text-danger": x.type == "OUT",
                      "text-success": x.type == "IN",
                    })}
                  >
                    {x.quantity}
                  </td>
                  <td>
                    <HighlightText
                      type={x.type == "OUT" ? "danger" : "success"}
                      template={2}
                      size="xs"
                    >
                      {x.type}
                    </HighlightText>
                  </td>
                  <td>{x.reference}</td>
                  <td>
                    <HighlightText
                      type="primary"
                      template={2}
                      size="sm"
                      isSoft={true}
                    >
                      {x.referenceType}
                    </HighlightText>
                  </td>
                  <td>{x.openingStock}</td>
                  <td>{x.closingStock}</td>
                  <td>
                    <Amount value={x.avgMrp} />
                  </td>
                  <td>
                    <Amount value={x.totalMrp} />
                  </td>
                  <td>
                    <Amount value={x.avgPurchasePrice} />
                  </td>
                  <td>
                    <Amount value={x.totalPurchasePrice} />
                  </td>
                  <td>
                    <DateFormatter date={x.createdAt} />
                  </td>
                  <td className="text-wrap">
                    {x?._createdBy?.name || x.createdBy}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

const headers = [
  { label: "Sl.No.", width: "4%" },
  { label: "Warehouse", width: "17%" },
  { label: "Qty", width: "5%", isCentered: true },
  { label: "Type", width: "5%" },
  { label: "Reference", width: "10%" },
  { label: "Reference Type", width: "12%" },
  { label: "Open Stock", width: "10%" },
  { label: "Closing Stock", width: "10%" },
  { label: "Avg. MRP", width: "8%" },
  { label: "Total MRP", width: "8%" },
  { label: "Avg. Pur. Price", width: "10%" },
  { label: "Total Pur. Price", width: "10%" },
  { label: "Created On", width: "12%" },
  { label: "Created By", width: "18%" },
];

const defaultPageOpt = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const attachAdditionalDataConfig = [
  {
    key: "whId",
    api: "franchise",
    loadingKey: "warehouseLoading",
    dataKey: "_warehouse",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { _id: { $in: ids } },
      select: "name",
    }),
  },
  {
    key: "createdBy",
    api: "user",
    loadingKey: "userLoading",
    dataKey: "_createdBy",
    matchKey: "username",
    filter: (ids) => ({
      page: 1,
      count: ids.length,
      filter: { username: { $in: ids } },
      // select: "name",
    }),
  },
];

const defaultFormData = {
  reference: "",
  warehouse: AuthService.getDefaultWhId(),
  createdAt: [],
  referenceType: "",
  type: "",
};

const defaultSortOpt = { key: "mrp", value: "desc" };

const prepareFilterParams = (
  pagination = {},
  filter = {},
  sort = {},
  additionalData = {}
) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      productId: additionalData.id,
    },
  };

  if (filter.warehouse) {
    p.filter.whId = filter.warehouse;
  }

  if (filter.reference.trim()) {
    p.filter.reference = filter.reference;
  }

  if (filter.referenceType) {
    p.filter.referenceType = filter.referenceType;
  }
  if (filter.type) {
    p.filter.type = filter.type;
  }

  if (filter.createdAt?.length) {
    p.filter.createdAt = {
      $gte: set(filter?.createdAt[0], { hours: 0, minutes: 0, seconds: 0 }),
      $lte: set(filter?.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  return p;
};

const getData = async (params) => {
  const r = await WarehouseService.stockTransactionSummaryList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.warehouseLoading = true;
        return x;
      })
    : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await WarehouseService.stockTransactionSummaryCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

export default ProductLedger;
