import { FileUpload, ImgSlider, InfoPopover, InputErrorMsg } from "@sk/uis";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

const swiperConfig = {
  slidesPerView: 6,
  spaceBetween: 20,
};

const ProductImages = () => {
  const {
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();

  const imagesCb = async (e) => {
    const v = getValues("images") || [];
    setValue("images", [...v, ...e.result]);
    await trigger("images");
  };

  const removeImage = useCallback(
    async (key, index) => {
      let v = getValues(key);
      v.splice(index, 1);
      setValue(key, v);
      await trigger(key);
    },
    [getValues, setValue, trigger]
  );

  const imagesRemove = useCallback(
    (asset, index) => {
      removeImage("images", index);
    },
    [removeImage]
  );

  const removeAllImages = useCallback(async () => {
    setValue("images", []);
    await trigger("images");
  }, [setValue, trigger]);

  return (
    <div className="p-1">
      <label className="col-form-label" htmlFor="image">
        Product Image
        <span className="text-danger"> * </span>
      </label>

      <FileUpload callback={imagesCb} maxFiles={15} allowMultiple={true} />
      {getValues("images").length > 0 ? (
        <div className="my-3">
          <ImgSlider
            assets={getValues("images")}
            onRemove={imagesRemove}
            swiperConfig={swiperConfig}
            // width="150"
            // height="130"
          />
        </div>
      ) : null}
      <InputErrorMsg msg={errors?.images?.message} />

      {getValues("images").length > 0 && (
        <button
          type="button"
          onClick={removeAllImages}
          className="btn btn-danger"
        >
          Remove All Images
        </button>
      )}
    </div>
  );
};

export default ProductImages;
