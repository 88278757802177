import { PoListSummary } from "@sk/features";
import { useAttachAdditionalData } from "@sk/hooks";
import { AuthService, PoService } from "@sk/services";
import { AppCard, PaginationSummary } from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { detailsView } from "../../../constantService";
import Filter from "./components/Filter";
import Table from "./components/Table";

const defaultPageOpt = detailsView.tabs.purchaseOrder.pagination;
const attachAdditionalDataConfig =
  detailsView.tabs.purchaseOrder.additionalTableDataConfig;

const defaultFormData = {
  po: "",
  warehouse: AuthService.getDefaultWhId(),
};

const defaultSortOpt = { key: "mrp", value: "desc" };

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      ["products.productId"]: filter.productId,
    },
  };

  if (sort) {
    p.sort = `${sort.value == "asc" ? "" : "-"}${sort.key}`;
  }

  if (filter.po) {
    p.filter._id = filter.po + "";
  }

  if (filter.warehouse) {
    p.filter.whId = {
      $in: [filter.warehouse],
    };
  }

  if (filter.status) {
    p.filter.status = {
      $in: [filter.status],
    };
  }

  return p;
};

const getData = async (params) => {
  const r = await PoService.getList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.userLoading = true;
        return x;
      })
    : [];
};

const getCount = async (params) => {
  delete params.count;
  delete params.page;
  try {
    const r = await PoService.getCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const PurchaseOrder = ({ pId }) => {
  const [data, setData] = useState([]);
  const paginationRef = useRef({ ...defaultPageOpt });
  const sortRef = useRef({ ...defaultSortOpt });
  const filterDataRef = useRef({ ...defaultFormData });
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();
  const [summaryParams, setSummaryParams] = useState(null);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      sortRef.current
    );
  }, []);

  useEffect(() => {
    if (pId) {
      filterDataRef.current = {
        ...filterDataRef.current,
        productId: pId,
      };
      applyFilter();
    } else {
      setLoadingData(false);
      setLoadingTotalRecords(false);
    }
  }, [applyFilter, pId]);

  const loadList = useCallback(async () => {
    const params = getFilterParams();

    setLoadingData(true);
    setData([]);
    const d = await getData(params);
    let tmp = [];
    setAdditionalData(d, attachAdditionalDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == attachAdditionalDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });

    setData(d);
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPageOpt };

    loadList();

    setLoadingTotalRecords(true);
    const p = getFilterParams();

    setSummaryParams(p);

    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;
    setLoadingTotalRecords(false);
  }, [getFilterParams, loadList]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = { ...filterDataRef.current, ...data.formData };
      applyFilter();
    },
    [applyFilter]
  );

  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <AppCard>
        <Filter callback={filterFormCb} />
      </AppCard>

      <PoListSummary filterParams={summaryParams} />

      <AppCard>
        <div className="mb-3">
          <PaginationSummary
            paginationConfig={paginationRef.current}
            loadingTotalRecords={loadingTotalRecords}
          />
        </div>
        <Table
          data={data}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          sort={sortRef.current}
          sortCb={sortCb}
        />
      </AppCard>
    </>
  );
};

export default PurchaseOrder;
