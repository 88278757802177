import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";

import { memo } from "react";

const headers = [
  { label: "Sl.No." },
  { label: "Warehouse" },
  { label: "Qty", isCentered: true },
  { label: "Type", isCentered: true },
  { label: "Reference", isCentered: true },
  { label: "Reference Type", isCentered: true },
  { label: "Open Stock", isCentered: true },
  { label: "Closing Stock", isCentered: true },
  { label: "Avg. MRP", isCentered: true },
  { label: "Total MRP", isCentered: true },
  { label: "Avg. Purchase Price", isCentered: true },
  { label: "Total Purchase Price", isCentered: true },
  { label: "Created On" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  return (
    <>
      <table className="table table-bordered table-sm table-responsive-lg">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        <tbody>
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length} className="p-1">
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}

          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {!loading &&
            data.map((x, i) => (
              <tr key={x._id} className="fs-val-sm">
                <td>{paginationConfig.startSlNo + i}</td>
                <td>
                  {x.warehouseLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    x._warehouse.name
                  )}
                </td>
                <td
                  className={classNames("text-center fw-bold", {
                    "text-danger": x.type == "OUT",
                    "text-success": x.type == "IN",
                  })}
                >
                  {x.quantity}
                </td>
                <td className="text-center">
                  <HighlightText
                    type={x.type == "OUT" ? "danger" : "success"}
                    template={2}
                    size="sm"
                  >
                    {x.type}
                  </HighlightText>
                </td>
                <td className="text-center">{x.reference}</td>
                <td className="text-center">
                  <HighlightText
                    type="primary"
                    template={2}
                    size="sm"
                    isSoft={true}
                  >
                    {x.referenceType}
                  </HighlightText>
                </td>
                <td className="text-center">{x.openingStock}</td>
                <td className="text-center">{x.closingStock}</td>
                <td className="text-center">
                  <Amount value={x.avgMrp} />
                </td>
                <td className="text-center">
                  <Amount value={x.totalMrp} />
                </td>
                <td className="text-center">
                  <Amount value={x.avgPurchasePrice} />
                </td>
                <td className="text-center">
                  <Amount value={x.totalPurchasePrice} />
                </td>
                <td>
                  <DateFormatter date={x.createdAt} />
                  <div>
                    {" "}
                    by{" "}
                    {x.userLoading ? (
                      <Spinner type={"dots"} />
                    ) : (
                      x?._createdBy?.name || x.createdBy
                    )}{" "}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
